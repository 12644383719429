/* eslint-disable prettier/prettier */
import { html, LitElement, render } from 'lit';
import { guard } from 'lit/directives/guard.js';
import { customElement, state } from 'lit/decorators.js';
import Swal from 'sweetalert2';
import '@ui5/webcomponents/dist/Tree.js';
import '@ui5/webcomponents/dist/Icon.js';
import '@ui5/webcomponents-icons/dist/it-instance.js';
import '@ui5/webcomponents-icons/dist/group-2.js';
import '@ui5/webcomponents-icons/dist/alert.js';
import '@ui5/webcomponents-icons/dist/cart.js';
import '@ui5/webcomponents-icons/dist/bell.js';
import '@material/mwc-icon';
import '@material/mwc-icon-button';
import '@vaadin/vaadin-grid'; 
import { State } from '../../redux/types';
//import './TerminalList.css';
import {
  Grid,
  GridActiveItemChangedEvent,
  GridItemModel,
} from '@vaadin/vaadin-grid';
import { AxiosResponse } from 'axios';

import '../../components/modals/EditOrganizationModal';
import router from '../../router/router.js';
import {
  CommunicationTerminalApi,
  CommunicationTerminalApiRequestCommandRequest,
  TerminalApi,
  FirmwareApi,
  OrganizationApi,
} from '../../../api/api.js';
import {
    ConfigurationApi,
    ConfigurationApiCreateConfigurationRequest,
    ConfigurationApiUpdateConfigurationRequest,
    AppApi,
    AppList,
    TemplateApi,
    Firmware
  } from '../../../api';
import {
  TerminalList,
  FirmwareList
} from '../../../api/index.js';
import store from '../../redux/store.js';
import { ConfigConsole,PageFolio } from '../../redux/actions';
import { getFirmwares } from '../../redux/actions/firmware';
import '../../components/modals/PushConfigurationsDialog';
import { UbicacionTerminalMapa } from '../../components/modals/UbicacionTerminalMapa';
import '../../components/modals/ListaAppModal';
import { CrearFolio } from '../../components/modals/CrearFolio';
import '../../components/modals/CrearFolio';
import { ListadoApp } from '../../components/modals/ListadoApp';
//import { CambiarMaster } from '../../components/modals/CambiarMaster';
import { InstalarFirmwareModal } from '../../components/modals/InstalarFirmwareModal';
import '../../components/modals/InstalarFirmwareModal'; 
import '../../components/modals/ListadoApp';
import { ListadoFolio } from '../../components/modals/ListadoFolio';
import '../../components/modals/ListadoFolio';
import { ConfigTerminalFolio } from '../../components/ConfigTerminalFolio';
import '../../components/ConfigTerminalFolio';
import '../../components/modals/UbicacionTerminalMapa';
import { EditOrganizationModal } from '../../components/modals/EditOrganizationModal.js';
import { apisConfigurator } from '../../utils/axios';
import { EnviarMensajeModal } from '../../components/modals/EnviarMensajeModal';
import '../../components/modals/EnviarMensajeModal';
import { AgregarInstaladorFirmware } from '../../components/modals/AgregarInstaladorFirmware';
import '../../components/modals/AgregarInstaladorFirmware';

import {
  errorNotification,
  succesNotification,
} from '../../services/notifications';
import { getTerminals } from '../../redux/actions/terminals';
import { addFirmware } from '../../redux/actions/firmware';
import { Unsubscribe } from '@reduxjs/toolkit';

@customElement('firmware-list')
export class FirmwareListt extends LitElement {
  private firmwareApi: FirmwareApi;
  private organizationApi!: OrganizationApi;

  private storeUnsuscriber?: Unsubscribe;

  /*get cambiarMaster() {
    return document.getElementById('cambiarMaster') as CambiarMaster;
  }*/

  get agregarInstaladorFirmware() {
    return document.getElementById(
      'agregarInstaladorFirmware'
    )! as AgregarInstaladorFirmware;
  }

  get instalarFirmwareModal() {
    return document.getElementById('instalarFirmwareModal')! as InstalarFirmwareModal;
  }

  @state()
  private terminals: TerminalList[] = [];

  private constructor() {
    super();
    this.firmwareApi = new FirmwareApi(...apisConfigurator('Firmware'));
  }


  @state()
  app?: any;

  @state()
  firmware?: any;


  @state()
  detail = false;

  @state()
  firmwares:any = [];

  @state()
  private loading = false;

  @state()
  organizacion = '';

  @state()
  private detailsOpenedItem: FirmwareList[] = [];

  @state()
  loader = true;

  @state()
  brand = '';

  @state()
  serial = '';

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  disconnectedCallback(): void {
    this.storeUnsuscriber && this.storeUnsuscriber();
  } 

  protected firstUpdated(): void {
    //this.getTerminals();
    //this.getApps();
    this.getFirmwares();
  }

  private async handleDeleteTerminal(firmware: any) {
    console.log(firmware)
    try {
      const res = await this.firmwareApi.deleteFirmware({
        id: firmware.id
      });
      console.log(res)
      if(res.status==200){
        await Swal.fire('Eliminado!', 'El Firmware ha sido eliminado', 'success');
      }
      this.firmwares = this.firmwares.filter((t:any,inde:number) => t.id !== firmware.id);

    } catch (err) {
      console.log(err)
    }
  }
 
  async getFirmwares() {
    this.loader =true;
    try {
      const res = await this.firmwareApi.listFirmware();
      console.log("firmwares",res)
      this.firmwares = res.data;
    } catch (error) {
      console.log(error)
    }finally{
      this.loader = false;
    }

    store.dispatch(getFirmwares());
    this.storeUnsuscriber = store.subscribe(() => {
      const { list, loading, listApps } = store.getState();
      console.log(loading);
      console.log({ list });
      this.firmwares = list as FirmwareList[];
      this.loader = loading;
    });
  }

  render() {
    return html`
      <main
        class="w-full h-full FondoColor flex  ${!this.loader
          ? ''
          : 'items-center justify-center'}"
      >
        ${this.loader !== true
          ? html`
              <section
                class="w-3/4 relative flex flex-wrap p-4 SectionList rounded-l-lg"
              >
                <!-- <div id='editorHolder'></div> -->
                <vaadin-grid
                  class="w-screen h-full"
                  .items="${this.firmwares}"
                  .detailsOpenedItems="${this.detailsOpenedItem}"
                  @active-item-changed="${(
                    e: GridActiveItemChangedEvent<FirmwareList>
                  ) => {
                    this.firmware = e.detail.value;
                    this.detailsOpenedItem = [e.detail.value];
                    if(e.detail.value) {
                      if (this.detail === false) {
                        this.detail = true;
                      } else if (this.detail === true) {
                        this.detail = false;
                      }
                    }else{
                      this.detail = false;
                    }

                    //this.ObtenerFoliosPorUsar(e.detail.value)
                    //this.ObtenerFolios(e.detail.value)
                  }}"
                  .rowDetailsRenderer="${guard(
                    [],
                    () =>
                      (
                        root: HTMLElement,
                        _: Grid,
                        model: GridItemModel<FirmwareList>
                      ) => {
                        render(
                          html`<vaadin-form-layout
                            .responsiveSteps=" ${[
                              { minWidth: '0', columns: 3 },
                            ]}"
                          >
                            <div
                            style="background-color:#F0EFFF;"
                              class="w-full h-14 flex items-center mt-5 justify-center flex-row "
                            >
                               <!-- <div
                                class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                                @click=${() => {
                                  //console.log('enviar mensaje', this.termi);

                                }}
                              >
                                <div>
                                  <mwc-icon-button icon="save">
                                  </mwc-icon-button>
                                </div>
                                <div>
                                  <p class="text-center">Crear</p>
                                </div>
                              </div>  -->
                              <div
                                class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                                @click=${() => {
                                  //console.log('enviar mensaje', this.termi);
                                  //this.CrearFolio.open(this.app);
                                  store.dispatch(
                                    addFirmware(this.firmware)
                                  );
                                  router.cambiarSeccion('edit-firmware-page');
                                }}
                              >
                                <div>
                                  <mwc-icon-button icon="update">
                                  </mwc-icon-button>
                                </div>
                                <div>
                                  <p class="text-center">Actualizar</p>
                                </div>
                              </div>   
                              <div
                                class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                                @click=${() => {
                                  //console.log('enviar mensaje', this.termi);
                                  //this.CrearFolio.open(this.app);
                                  this.agregarInstaladorFirmware.open(this.firmware.id)
                                }}
                              >
                                <div>
                                  <mwc-icon-button icon="system_update">
                                  </mwc-icon-button>
                                </div>
                                <div>
                                  <p class="text-center">Instalador</p>
                                </div>
                              </div> 
                              <div
                                class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                                @click=${() => {
                                  //console.log('enviar mensaje', this.termi);
                                  //this.CrearFolio.open(this.app);
                                  this.instalarFirmwareModal.open(this.firmware)
                                }}
                              >
                                <div>
                                  <mwc-icon-button icon="system_update">
                                  </mwc-icon-button>
                                </div>
                                <div>
                                  <p class="text-center">Instalar</p>
                                </div>
                              </div>                           
                            </div>
                          </vaadin-form-layout>`,
                          root
                        );
                      }
                  )}"
                > 
                  <vaadin-grid-selection-column></vaadin-grid-selection-column>
                  <vaadin-grid-sort-column
                    path="name"
                  ></vaadin-grid-sort-column>
                  <vaadin-grid-sort-column
                    path="brand"
                  ></vaadin-grid-sort-column>
                  <vaadin-grid-sort-column
                    header="Brief Description"
                    path="briefDescription"
                  ></vaadin-grid-sort-column>
                  <vaadin-grid-sort-column
                    header="Descripcion"
                    path="description"
                  ></vaadin-grid-sort-column>
                  <vaadin-grid-sort-column
                    header="Developer"
                    path="developer"
                  ></vaadin-grid-sort-column>
                  <vaadin-grid-sort-column
                    header="Model"
                    path="model"
                  ></vaadin-grid-sort-column>
                  <vaadin-grid-sort-column
                    header="OS"
                    path="os"
                  ></vaadin-grid-sort-column>
                  <!-- <vaadin-grid-sort-column
                    path="status"
                    header="Estatus"
                    .renderer="${guard(
                      [],
                      () => (root: HTMLElement, _: any, model: any) => {
                        const bg =
                          model.item.status == `online`
                          ? `text-green-500`
                          : model.item.status == `created`
                          ? `text-orange-500`
                          : `text-red-500`;
                        render(
                          html`<p
                            class="py-1 capitalize px-4 w-fit ${bg} rounded font-bold"
                          >
                            ${model.item.status}
                          </p>`,
                          root
                        );
                      }
                    )}"
                  ></vaadin-grid-sort-column> -->
                </vaadin-grid>
                <mwc-icon-button 
                  icon="autorenew"
                  class="absolute top-0 right-0"
                  @click=${() => {
                    this.getFirmwares()
                  }}
                ></mwc-icon-button>
                <edit-organization-modal
                  id="edit-organization-modal"
                ></edit-organization-modal>
              </section>
              <section class="w-1/4  SectionDetail justify-center scroll">
                <div class="w-full flex justify-center">
                  <mwc-button
                    raised
                    icon="smartphone"
                    class="rounded w-4/5 mx-auto mt-4 mb-2"
                    style="--mdc-theme-primary: #2E3EAE; --mdc-theme-on-primary: white;"
                    @click="${() => {
                      router.cambiarSeccion('create-firmware-page');
                    }}"
                  >
                    Crear Firmware
                  </mwc-button>
                </div>
                <div class=" pt-3 flex items-center ">
              <!-- <p class="text-base	text-blue-500	ml-3 ">Folios Disponibles</p> -->
              <!-- <mwc-icon-button icon="settingsremoteoutlined">
              </mwc-icon-button> -->
            </div>
                ${this.detail === true 
                  ? html`
                      <div class="w-full py-3 border-b-2 border-grey-100 px-4">
                      <div class="pt-1 ml-5">
            <ul class="list-disc pl-2">

            </ul>
          </div>
          </div>
          <!-- <div>
            <mwc-button
              class="rounded w-4/5 mx-auto mt-4 mb-2"
              style="--mdc-theme-primary: #2E3EAE;"
              raised
              icon="add_circle"
              @click=${() => {
                const { configConsole } = store.getState() as State;
                store.dispatch(
                  ConfigConsole({
                    page:1,
                    ...configConsole,
                  })
                );

              }}
            >
              Ver mas</mwc-button
            >
          </div> -->
                        <!-- <div>
                          <h1 class="text-2xl	text-blue-900 ml-3 mb-8	">
                           
                          </h1>
                        </div>

                        <div class="flex flex-row items-center">
                          <p class="text-base	font-bold	text-blue-500	ml-3">
                            Modelo:
                          </p>
                          <p class="text-base		text-blue-500	ml-3">
                            
                          </p>
                        </div>
                        <div class="flex flex-row items-center">
                          <p class="text-base	font-bold	text-blue-500	ml-3">
                            Marca:
                          </p>
                          <p class="text-base		text-blue-500	ml-3">
                            
                          </p>
                        </div>
                        <div class="flex flex-row items-center">
                          <p class="text-base	font-bold	text-blue-500	ml-3">
                            Firmware:
                          </p>
                          <p class="text-base	text-blue-500	ml-3">
                            
                          </p>
                        </div>
                        <div class="flex flex-row pt-5 pl-3">
                          <mwc-icon class="text-blue-900"
                            >battery_charging_full</mwc-icon
                          >
                          <p class="text-lg	text-blue-900 mr-2">
                            
                              
                          </p>
                          <mwc-icon class="text-blue-900">memory</mwc-icon>
                          <p class="text-lg	text-blue-900 mr-2">
                           
                          </p>
                          <mwc-icon class="text-blue-900">storage</mwc-icon>
                          <p class="text-lg	text-blue-900">
                            
                          </p>
                        </div> -->
                        <!-- <div class="flex flex-row pt-3"> 
                          <div
                            class="btnBloquear items-center content-center cursor-pointer flex  flex-row"
                            @click=${() => {
                              //this.historialComandosModal.open(this.termi!);
                            }} 
                          >

                            <mwc-icon-button icon="block">
                            </mwc-icon-button>
                            <p class="text-center ">Bloquear</p>
                          </div>
                        </div>
                        <div class="flex flex-row pt-3"> 
                          <div
                            class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                            @click=${() => {
                              
                            }} 
                          >

                              <mwc-icon-button icon="receipt">
                              </mwc-icon-button>
                              <p class="text-center ">Historial Comandos</p>
                          </div>
                        </div> -->
                        <!-- <div class="flex flex-row pt-3 ">
                          <div
                            class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                            @click=${() => {

                            }}
                          >
                            <mwc-icon-button icon="place"> </mwc-icon-button>

                            <p class="text-center ">Ver en mapa</p>
                          </div>
                        </div> -->
                        <!-- <div class="flex flex-row pt-3 ">
                          <div
                            class="btnMessage w-44 items-center content-center cursor-pointer flex  flex-row"
                            @click=${() => {
                              
                            }}
                          >
                            <mwc-icon-button icon="build"> </mwc-icon-button>

                            <p class="text-center ">Config avanzada</p>
                          </div>
                        </div> -->
                        <!-- <div class=" pt-3 flex items-center  justify-between">
                          <p
                            class="text-base	font-bold	text-blue-500	ml-3 text-base"
                          >
                            Organizaciones
                          </p>
                        </div> -->
                        <!-- <div class="pt-1 ml-5">
                          <ul class="list-disc pl-2">
                            <li>
                              <p class="text-base	text-blue-800	">
                                ${this.organizacion}
                              </p>
                            </li>
                          </ul>
                        </div> -->

                      <!-- <div class="w-full py-3 pl-5 border-b-2 border-grey-100">
                        <div
                          class="group_icon items-center p-2  flex content-center  flex-row "
                        >
                          <div class="text-blue-500">
                            <mwc-icon-button icon="wifi"> </mwc-icon-button>
                          </div>
                          <div class="flex justify-between w-full">
                            <h1 class="text-1xl	text-blue-900 ml-3 	">Wifi</h1>

                            <mwc-switch
                              slot="meta"
                              .selected=${true}
                              @click=${() => {

                            }}
                            ></mwc-switch>
                          </div>
                        </div> -->

                        <!-- <div
                          class=" group_icon items-center p-2   flex  flex-row cursor-default"
                        >
                          <div class="text-blue-500 cursor-default">
                            <mwc-icon-button icon="bluetooth">
                            </mwc-icon-button>
                          </div>
                          <div class="flex justify-between w-full">
                            <h1 class="text-1xl	text-blue-900 ml-3 	">
                              Bluetooth
                            </h1>

                            <mwc-switch
                              slot="meta"
                              .selected=${true}
                              @click=${() => {
                              
                            }}
                            ></mwc-switch>
                          </div>
                        </div>
                      </div> -->
                      <!-- <div
                        class="w-full mt-5 flex-col flex justify-center items-center"
                      >
                        <mwc-button
                          raised
                          style="--mdc-theme-primary: #E3E1EC; --mdc-theme-on-primary: #46464F"
                          class="mb-2 rounded w-3/4"
                          icon="edit"
                          @click="${() => {

                          }}"
                          >Editar</mwc-button
                        >-->
                        <mwc-button
                          raised
                          style="--mdc-theme-primary: tomato; --mdc-theme-on-primary: white"
                          class="mb-2 rounded w-3/4"
                          icon="delete"
                          @click="${() => {
                            this.handleDeleteTerminal(this.firmware);
                          }}"
                          >Eliminar</mwc-button
                        > 
                      </div> 
                    `
                  : html``}
              </section>
            `
          : html`
              <mwc-circular-progress
                indeterminate
                density=${20}
              ></mwc-circular-progress>
            `}
      </main>
      <listado-folio id="listadoFolio"></listado-folio>
      <crear-folio id="crearFolio"></crear-folio>
      <listado-app id="listadoApp"></listado-app>
      <!-- <cambiar-master id="cambiarMaster"></cambiar-master> -->
      <ubicacion-terminal-mapa
        id="ubicacionTerminalMapa"
      ></ubicacion-terminal-mapa>
      <lista-app-modal id="listaAppModal"></lista-app-modal>
      <push-config-modal id="pushConfigModal"></push-config-modal>
      <enviar-mensaje-modal id="enviarMensajeModal"></enviar-mensaje-modal>
      <agregar-instalador-firmware
        id="agregarInstaladorFirmware"
      ></agregar-instalador-firmware>
      <instalar-firmware-modal id="instalarFirmwareModal"></instalar-firmware-modal>
    `;
  }
} 