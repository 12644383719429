/* eslint-disable prettier/prettier */

import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '@ui5/webcomponents/dist/Dialog.js';
import '@vaadin/vaadin-select';
import Swal from 'sweetalert2';
import { errorNotification } from '../../services/notifications';
import { apisConfigurator } from '../../utils/axios';
import '../CustomSelect/CustomSelect';
import {
  App,
  BrandList,
  ModelApi,
  VersionApi,
  VersionApiAddInstallerRequest,
  FirmwareApi,
  FirmwareApiAddFirmwareInstallerRequest
} from '../../../api';
import store from '../../redux/store';
import { State } from '../../redux/types';
import { flatMarcas, modelosPorMaca } from '../../utils/functions';
import { CustomMultiSelect } from '../CustomMultiSelect/CustomMultiSelect';
import { CustomSelect } from '../CustomSelect/CustomSelect';

@customElement('agregar-instalador-firmware')
export class AgregarInstaladorFirmware extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private modelApi: ModelApi;
  private firmwareApi: FirmwareApi;
  private versionApi: VersionApi;

  constructor() {
    super();
    this.firmwareApi = new FirmwareApi(...apisConfigurator('Firmware'));
    this.modelApi = new ModelApi(...apisConfigurator('Agregar instalador'));
    this.versionApi = new VersionApi(...apisConfigurator('Agregar instalador'));
  }

  @property({ type: Object })
  app?: App;

  @state()
  private brandSelected?: string;

  @state()
  private FirmwareId?: any;

  @state()
  private loading = false;

  @state()
  private file?: File;

  @state()
  private modelosMarcas: BrandList[] = [];

  private get dialog() {
    return document.getElementById('dialog-agregar-instalador-firmware')! as any;
  }

  private get botonInstalador() {
    return document.querySelector(
      '#seleccionar-instalador'
    )! as HTMLInputElement;
  }

  private get version() {
    return document.getElementById('versionSelect') as CustomSelect;
  }

  private get sistemaOperativo() {
    return document.getElementById('sistemaOperativo') as CustomSelect;
  }

  private get brand() {
    return document.getElementById('brand') as CustomSelect;
  }

  private get models() {
    return document.getElementById('models') as CustomMultiSelect<string>;
  }

  protected firstUpdated(): void {}

  private async getMarcas() {
    const resModel = await this.modelApi.modelList({});
    this.modelosMarcas = resModel.data;
  }

  async open(FirmwareId?: string) {
    if (!FirmwareId?.length) {
      errorNotification('Error al agregar instalador');
      return;
    }

    //this.getMarcas();

    this.FirmwareId = FirmwareId;
    this.dialog.open = true;
  }

  close() {
    this.dialog.open = false;
    this.app = undefined;
  }

  private async handleSubmit(e: Event) {
    e.preventDefault();
 
    //const { verAppId } = store.getState() as State;

    this.file = this.botonInstalador!.files![0];

    if (!this.file) {
      console.log(this.file)
      //errorNotification('Error al agregar instalador');
      return;
    }

    const body: FirmwareApiAddFirmwareInstallerRequest = {
        id:this.FirmwareId,
        installer: this.file
    };
    console.log(this.version);
    console.log(body);

    try {
      this.loading = true;
      const res = await this.firmwareApi.addFirmwareInstaller(body);

      this.loading = false;
      if(res.status == 200){
        await Swal.fire('Agregado!', 'El Instalador ha sido cargado', 'success');
      }
      
      this.close();
    } catch (error: any) {
      this.close();
      this.loading = false;
      console.log(error);
      console.log(error.message);
    }
  }

  protected render() {
    return html` <ui5-dialog
      id="dialog-agregar-instalador-firmware"
      header-text="Agregar instalador"
      class="w-4/12 h-80"
    > 
      <form
        class="px-4 flex flex-col items-center h-full"
        @submit=${this.handleSubmit}
      >
        <div class="mx-2 w-full flex flex-col items-center relative">
          <div class="flex">
            <!-- <div class="w-[210px] h-16 relative mx-2 z-10">
              <custom-select
                label="Version"
                id="versionSelect"
                .options=${this.app?.version?.map(v => ({
                  value: v.version,
                  label: v.version,
                }))}
              ></custom-select>
            </div> -->
            <!-- <div class="w-[210px] h-16 relative mx-2">
              <custom-select
                label="Marca"
                id="brand"
                .options=${flatMarcas(this.modelosMarcas).map(m => ({
                  label: m,
                  value: m,
                }))}
                @change=${(ev: CustomEvent) => {
                  this.brandSelected = ev.detail;
                }}
              ></custom-select>
            </div> -->
          </div>
          <div class="flex">
            <!-- <div class="w-[210px] h-16 relative mx-2">
              <custom-select
                label="Sistema Operativo"
                id="sistemaOperativo"
                .options=${[
                  { label: 'Todos', value: '*' },
                  { label: 'Android', value: 'Android' },
                  { label: 'Linux', value: 'Linux' },
                ]}
              ></custom-select>
            </div> -->
            <div class="w-[210px] h-16 relative mx-2">
              <!-- <custom-multi-select 
                label="Modelos"
                id="models"
                .options=${this.brandSelected
                  ? [
                      { label: 'Cualquiera', value: '*' },
                      ...modelosPorMaca(
                        this.modelosMarcas,
                        this.brandSelected
                      ).map(m => ({
                        label: m.model,
                        value: m.model,
                      })),
                    ]
                  : []}
              ></custom-multi-select> -->
            </div>
          </div>
          <input
            id="seleccionar-instalador"
            type="file"
            hidden
            @change=${(ev: InputEvent) => {}}
          />
          <mwc-button
            class="rounded"
            label="Seleccionar instalador"
            icon="publish"
            outlined
            .disabled=${this.loading}
            @click=${() => {
              this.botonInstalador.click();
            }}
          >
          </mwc-button>
        </div>

        <div slot="footer" class="dialog-footer mt-auto">
          <button
            class="text-red-600 p-4 rounded-md uppercase"
            id="closeDialogButton"
            @click=${() => {
              this.close();
            }}
          >
            Cerrar
          </button>
          <button
            class="text-blue-600 p-4 rounded-md uppercase"
            id="closeDialogButton"
            type="submit"
            .disabled=${this.loading}
          >
            Guardar
          </button>
        </div>
      </form>
    </ui5-dialog>`;
  }
}
