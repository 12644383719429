/* eslint-disable prettier/prettier */
import { LitElement, html, css, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';

@customElement('card-b')
export class CardB extends LitElement {
  @property({ type: Object })
  customSlot?: TemplateResult<1>;

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  protected firstUpdated(): void {
    this.classList.add('w-4/12');
  }

  render() {
    return html`
      <div class="p-2">
        <div class="w-full h-40 p-4 rounded-3xl shadow-3xl bg-white">
          ${this.customSlot}
        </div>
      </div>
    `;
  }
}
