import { put, takeLatest } from 'redux-saga/effects';
import { OrganizationApi } from '../../../api';
import { apisConfigurator } from '../../utils/axios';
import {
  GET_ORGANIZACIONES,
  ORGANIZACIONES_RECEIVED,
} from '../actions/organizaciones';

export function* getOrganizaciones(): Generator {
  const organizacionApi = new OrganizationApi(...apisConfigurator(''));
  console.log('getOrganizaciones');
  const data = yield organizacionApi.organizationList().then(res => res.data);

  console.log({ data });
  yield put({ type: ORGANIZACIONES_RECEIVED, organizaciones: data });
}

export function* organizacionesWatcher() {
  yield takeLatest(GET_ORGANIZACIONES, getOrganizaciones);
}
