/* eslint-disable prettier/prettier */
import { html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import '@material/mwc-textfield';
import {
  RequestLogin,
  SecurityApi,
  UserApi,
  UserApiCreateRecoverPasswordTokenRequest,
  UserApiUpdateUserPasswordWithTokenRequest,
  RequestUpdatePasswordWithToken,
} from '../../../api';
import { iniciarSesion } from '../../redux/actions';
import store from '../../redux/store';
import { Loader } from '../../components/loader/loader.js';
import '../../components/loader/loader.js';
import {
  errorNotification,
  succesNotification,
} from '../../services/notifications';
import '../../styles.css';
import router from '../../router/router';
import { apisConfigurator } from '../../utils/axios';
@customElement('login-page')
export class LoginPage extends LitElement {
  email = '';
  emailRecovery = '';
  passwordRecovery = '';
  passwordRecoveryRepeat = ' ';
  password = '';
  token = '';
  regex = new RegExp(
    '[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{2,5}'
  );

  private securityApi!: SecurityApi;
  private userApi!: UserApi;

  @state()
  mensajeError = '';
  Error = false;
  mensajeErrorPassword = '';
  ErrorPassword = false;
  Validate = false;

  @state()
  loader = false;

  @state()
  page = 1;

  get editOrganizationModal() {
    return document.getElementById('modal-loader') as Loader;
  }
  protected firstUpdated(): void {
    this.securityApi = new SecurityApi(...apisConfigurator('Iniciar sesión'));
    this.userApi = new UserApi(...apisConfigurator('User'));
  }

  private async enviarToken(e?: Event) {
    const email = this.emailRecovery;

    if (!this.regex.test(email)) {
      errorNotification('Formato correo invalido!');
      return false;
    }
    const request: UserApiCreateRecoverPasswordTokenRequest = {
      idUser: email,
    };
    try {
      const response = await this.userApi.createRecoverPasswordToken(request);
      console.log(response.data);
      succesNotification('Token Enviado al correo!');
      this.page = 3;
    } catch (error) {
      errorNotification('Error al enviar token al correo!');
    }
  }

  private async validarToken(e?: Event) {
    const email = this.emailRecovery;
    const token = this.token;
    const password = this.passwordRecovery;
    const passwordRepeat = this.passwordRecoveryRepeat;
    if (!this.regex.test(email)) {
      errorNotification('Formato correo invalido!');
      return false;
    }
    if (!(password.localeCompare(passwordRepeat) == 0)) {
      errorNotification('Las contraseñas no son iguales!');
      /*this.ErrorPassword = true;
      this.mensajeErrorPassword = 'Las contraseñas no son iguales!';*/
      return false;
    }
    if (!(token.localeCompare('') != 0)) {
      errorNotification('Digite el campo token enviado al correo!');
      return false;
    }
    const requestUpdatePasswordWithToken: RequestUpdatePasswordWithToken = {
      token,
      password,
    };
    const request: UserApiUpdateUserPasswordWithTokenRequest = {
      idUser: email,
      requestUpdatePasswordWithToken,
    };
    try {
      const response = await this.userApi.updateUserPasswordWithToken(request);
      console.log(response.data);
      succesNotification('Actualizacion contraseña completada');
      this.page = 1;
    } catch (error) {
      errorNotification('Error al validar los datos!');
    }
  }

  private async handleSubmit(e?: Event) {
    e?.preventDefault();
    if (this.email === '' || this.password === '') {
      this.Error = true;
      this.mensajeError = 'No puede dejar campos vacios';
    } else {
      this.mensajeError = '';
      this.Error = false;

      this.editOrganizationModal.open();
      this.loader = true;
      if (!this.validateLogin()) return;
      const requestLogin: RequestLogin = {
        user: this.email,
        password: this.password,
      };
      try {
        const response = await this.securityApi.securityLogin({ requestLogin });
        console.log(response.data);
        if (!response) {
          this.editOrganizationModal.open();
        } else {  
          this.editOrganizationModal.close();
          localStorage.setItem('token', response.data.token);
          //let timer = new Date()
          /*localStorage.setItem('timer', JSON.stringify({value:timer}));
          console.log(response.data)*/
          store.dispatch( 
            iniciarSesion( 
              response.data.token,
              response.data.authorizedFunctions,
              response.data.name,
              response.data.activeMaster,
              this.password,
              this.email
            )
          );
          if(response.data.passwordChangeRequired){
            localStorage.setItem('cambioPassword', "true");
            router.cambiarSeccion('profile');
          }else{
            localStorage.setItem('cambioPassword', "false");
            router.cambiarSeccion('');
          }

          /* if (response.status === 200)  */
        }
      } catch (error) {
        this.editOrganizationModal.open();
        this.Error = true;
        if (error) {
          this.editOrganizationModal.close();
          this.Error = true;
          this.mensajeError = 'Usuario o contraseña invalida';
        }
      }
    }
  }
  inputEmail(e: any) {
    if (this.mensajeError != '') {
      this.mensajeError = '';
      this.Error = false;
    }
    this.email = e;
  }
  inputEmailRecovery(e: any) {
    if (this.mensajeError != '') {
      this.mensajeError = '';
      this.Error = false;
    }
    this.emailRecovery = e;
  }
  inputPasswordRecovery(e: any) {
    const regex = new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9]).{12,}$");
    if (this.mensajeError != '') {
      this.mensajeError = '';
      this.Error = false;
    }
    if(regex.test(this.password)){
      this.Error = false;
      this.mensajeError = '';
    }else{
      this.Error = true; 
      this.mensajeError ='Debe contener al menos 1 letra, 1 numero y ser mayor a 12 caracteres';
    }
    this.passwordRecovery = e;
  }
  inputPasswordRecoveryRepeat(e: any) {
    if (this.mensajeError != '') {
      this.mensajeError = '';
      this.Error = false;
    }
    this.passwordRecoveryRepeat = e;
  }
  inputToken(e: any) {
    if (this.mensajeError != '') {
      this.mensajeError = '';
      this.Error = false;
    }
    this.token = e;
  }
  inputPassw(e: any) {

    this.password = e;
    if (this.password.length <= 5) {
      this.Error = true;
      this.mensajeError = 'Su contraseña debe ser igual o mayor a 6 digitos';
      return;
    } else {
      this.Error = false;
      this.mensajeError = '';
    }

  }
  validateLogin() {
    const form = document.getElementById('login-form') as HTMLFormElement;
    const email = document.getElementById('email') as HTMLInputElement;
    const password = document.getElementById('password') as HTMLInputElement;
    if (email.value === '' || password.value === '') {
      this.Validate = true;
    } else {
      this.Validate = false;
      this.email = email.value;
      this.password = password.value;
      return form.checkValidity();
    }
  }

  render() {
    return html`
      <main class="w-full h-full  flex">
        <div class="w-3/5	 hidden md:block login-banner"></div>
        <div
          class="flex flex-col justify-center items-center w-2/5 mx-auto mt-6 py-6 pl-28 px-8"
        >
          <img class="mt-8 mb-24" src="./Logo_IO.png" style="width: 280px;" />
          ${
            this.page == 1
              ? html`
          <h1 class="text-5xl text-bold">
            Hola, nos alegra<br />
            verte de nuevo
          </h1>
          <form 
            id="login-form"
            action="#"
            class="mt-12 login-form w-full flex flex-col"
            @submit=${(e: Event) => this.handleSubmit(e)}
          >
            <p class="text-gray-400 text-md">Inicia sesión</p>

            <mwc-textfield
              class="rounded ${
                this.mensajeError !== '' ? 'redondeado' : ''
              }  mt-1test w-10/12"
              label="Correo"
              type="email"
              .value="${this.email}"
              id="email"
              outlined
              @input="${(e: any) => {
                this.inputEmail(e.target.value);
              }}"
              @keypress="${(ev: KeyboardEvent) => {
                if (ev.key === 'Enter') {
                  (
                    document.getElementById('password') as HTMLInputElement
                  ).focus();
                }
              }}"
            >
            </mwc-textfield>
            <style>
              mwc-textfield.redondeado {
                --mdc-text-field-outlined-idle-border-color: red;
              }
            </style>
            <mwc-textfield
              class="rounded ${
                this.mensajeError !== '' ? 'redondeado' : ''
              }  mt-8 w-10/12"
              label="Contraseña"
              type="password"
              .value="${this.password}"
              id="password"
              aria-errormessage="$1"
              outlined
              @input="${(e: any) => this.inputPassw(e.target.value)}"
              @keypress="${(ev: KeyboardEvent) => {
                if (ev.key === 'Enter') {
                  this.handleSubmit(ev);
                }
              }}"
            >
            </mwc-textfield>

            <div class="Error">
              <p>
                ${this.Error === true ? html`${this.mensajeError}` : html``}
              </p>
            </div>

            <div class="my-4  mr-16 text-right text-gray-400"
              @click="${(e: Event) => {
                //router.cambiarSeccion('recovery-password');
                this.page = 2;
              }}">
              <a href="#" onclick="return false;"> ¿Olvidaste tu contraseña? </a>
            </div>

            <mwc-button
              @click="${(e: Event) => {
                this.handleSubmit(e);
              }}"
              class="mb-4 mt-2 rounded w-10/12"
              fullwidth
              raised
              >Iniciar Sesión</mwc-button
            >
          </form> 
        </div>`
              : html`
                  <h1 class="text-5xl text-bold">
                    Recuperacion Contraseña<br />
                  </h1>
                  <form
                    id="login-form"
                    action="#"
                    class="mt-12 login-form w-full flex flex-col"
                    @submit=${(e: Event) => this.handleSubmit(e)}
                  >
                    <p class="text-gray-400 text-md">Indique su correo</p>

                    <mwc-textfield
                      class="rounded ${this.mensajeError !== ''
                        ? 'redondeado'
                        : ''}  mt-1test w-10/12"
                      label="Correo"
                      type="email"
                      .value="${this.emailRecovery}"
                      id="email"
                      outlined
                      @input="${(e: any) => {
                        this.inputEmailRecovery(e.target.value);
                      }}"
                      @keypress="${(ev: KeyboardEvent) => {
                        if (ev.key === 'Enter') {
                          (
                            document.getElementById(
                              'password'
                            ) as HTMLInputElement
                          ).focus();
                        }
                      }}"
                    >
                    </mwc-textfield>
                    <style>
                      mwc-textfield.redondeado {
                        --mdc-text-field-outlined-idle-border-color: red;
                      }
                    </style>
                    <div class="Error">
                      <p>
                        ${this.Error === true
                          ? html`${this.mensajeError}`
                          : html``}
                      </p>
                    </div>

                    ${this.page == 3
                      ? html` <mwc-textfield
                            class="rounded ${this.mensajeError !== ''
                              ? 'redondeado'
                              : ''}  mt-1test w-10/12"
                            label="Token"
                            .value="${''}"
                            id="email"
                            outlined
                            @input="${(e: any) => {
                              this.inputToken(e.target.value);
                            }}"
                            @keypress="${(ev: KeyboardEvent) => {
                              if (ev.key === 'Enter') {
                                (
                                  document.getElementById(
                                    'password'
                                  ) as HTMLInputElement
                                ).focus();
                              }
                            }}"
                          >
                          </mwc-textfield>
                          <style>
                            mwc-textfield.redondeado {
                              --mdc-text-field-outlined-idle-border-color: red;
                            }
                          </style>
                          <div class="Error">
                            <p>
                              ${this.Error === true
                                ? html`${this.mensajeError}`
                                : html``}
                            </p>
                          </div>
                          <mwc-textfield
                            required
                            minLength="8"
                            autoValidate
                            type="password"
                            validationMessage="La contraseña debe tener al menos 8 caracteres"
                            class="rounded ${this.mensajeError !== ''
                              ? 'redondeado'
                              : ''}  mt-1test w-10/12"
                            label="Nueva contraseña"
                            .value="${''}"
                            id="PasswordRecovery"
                            outlined
                            @input="${(e: any) => {
                              this.inputPasswordRecovery(e.target.value);
                            }}"
                            @keypress="${(ev: KeyboardEvent) => {
                              if (ev.key === 'Enter') {
                                ( 
                                  document.getElementById(
                                    'password'
                                  ) as HTMLInputElement
                                ).focus();
                              }
                            }}"
                          >
                          </mwc-textfield>
                          <style>
                            mwc-textfield.redondeado {
                              --mdc-text-field-outlined-idle-border-color: red;
                            }
                          </style>
                          <div class="Error">
                            <p>
                              ${this.ErrorPassword === true
                                ? html`${this.mensajeErrorPassword}`
                                : html``}
                            </p>
                          </div>
                          <mwc-textfield
                            required
                            minLength="8"
                            autoValidate
                            type="password"
                            validationMessage="La contraseña debe tener al menos 8 caracteres"
                            class="rounded ${this.mensajeError !== ''
                              ? 'redondeado'
                              : ''}  mt-1test w-10/12"
                            label="Repetir nueva contraseña"
                            .value="${''}"
                            id="PasswordRecoveryRepeat"
                            outlined
                            @input="${(e: any) => {
                              this.inputPasswordRecoveryRepeat(e.target.value);
                            }}"
                            @keypress="${(ev: KeyboardEvent) => {
                              if (ev.key === 'Enter') {
                                (
                                  document.getElementById(
                                    'password'
                                  ) as HTMLInputElement
                                ).focus();
                              }
                            }}"
                          >
                          </mwc-textfield>
                          <style>
                            mwc-textfield.redondeado {
                              --mdc-text-field-outlined-idle-border-color: red;
                            }
                          </style>
                          <div class="Error">
                            <p>
                              ${this.ErrorPassword === true
                                ? html`${this.mensajeErrorPassword}`
                                : html``}
                            </p>
                          </div>
                          <div
                            class="my-4  mr-16 text-right text-gray-400"
                            @click="${(e: Event) => {
                              //router.cambiarSeccion('recovery-password');
                              this.enviarToken(e);
                            }}"
                          >
                            <a href="#" onclick="return false;">
                              reenviar token
                            </a>
                          </div>`
                      : html``}

                    <div
                      class="my-4  mr-16 text-right text-gray-400"
                      @click="${(e: Event) => {
                        //router.cambiarSeccion('recovery-password');
                        this.page = 1;
                      }}"
                    >
                      <a href="#" onclick="return false;"> Regresar Login </a>
                    </div>
                    ${this.page == 2
                      ? html` <mwc-button
                          @click="${(e: Event) => {
                            this.enviarToken(e);
                          }}"
                          class="mb-4 mt-2 rounded w-10/12"
                          fullwidth
                          raised
                          >Enviar Token</mwc-button
                        >`
                      : this.page == 3
                      ? html` <mwc-button
                          @click="${(e: Event) => {
                            this.validarToken(e);
                          }}"
                          class="mb-4 mt-2 rounded w-10/12"
                          fullwidth
                          raised
                          >Validar Token</mwc-button
                        >`
                      : html``}
                  </form>
                `
          }
        <loader-general id="modal-loader"></loader-general>
      </main>
    `;
  }

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }
}
