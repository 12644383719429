/* tslint:disable */
/* eslint-disable */
/**
 * TMS Agnostiko
 * Tiene la definición de todos los servicios de TMS a Agnostic (para uso en la web o externo)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { Functionality } from '../models';
// @ts-ignore
import { Message } from '../models';
// @ts-ignore
import { RequestLogin } from '../models';
// @ts-ignore
import { ResponseLogin } from '../models';
/**
 * SecurityApi - axios parameter creator
 * @export
 */
export const SecurityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary devuelve la lista de funcionalidades activas
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        securityFunctionalities: async (acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/security/functionalities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Realiza login en la aplicación y se valida el token enviado y se crea o indicar un error especifico
         * @param {RequestLogin} requestLogin 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        securityLogin: async (requestLogin: RequestLogin, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestLogin' is not null or undefined
            assertParamExists('securityLogin', 'requestLogin', requestLogin)
            const localVarPath = `/security/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestLogin, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Realiza el logout de la aplicación, y destruye el token en el back
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        securityLogout: async (acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/security/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SecurityApi - functional programming interface
 * @export
 */
export const SecurityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SecurityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary devuelve la lista de funcionalidades activas
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async securityFunctionalities(acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Functionality>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.securityFunctionalities(acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SecurityApi.securityFunctionalities']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Realiza login en la aplicación y se valida el token enviado y se crea o indicar un error especifico
         * @param {RequestLogin} requestLogin 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async securityLogin(requestLogin: RequestLogin, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseLogin>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.securityLogin(requestLogin, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SecurityApi.securityLogin']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Realiza el logout de la aplicación, y destruye el token en el back
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async securityLogout(acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.securityLogout(acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SecurityApi.securityLogout']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * SecurityApi - factory interface
 * @export
 */
export const SecurityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SecurityApiFp(configuration)
    return {
        /**
         * 
         * @summary devuelve la lista de funcionalidades activas
         * @param {SecurityApiSecurityFunctionalitiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        securityFunctionalities(requestParameters: SecurityApiSecurityFunctionalitiesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<Functionality>> {
            return localVarFp.securityFunctionalities(requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Realiza login en la aplicación y se valida el token enviado y se crea o indicar un error especifico
         * @param {SecurityApiSecurityLoginRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        securityLogin(requestParameters: SecurityApiSecurityLoginRequest, options?: AxiosRequestConfig): AxiosPromise<ResponseLogin> {
            return localVarFp.securityLogin(requestParameters.requestLogin, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Realiza el logout de la aplicación, y destruye el token en el back
         * @param {SecurityApiSecurityLogoutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        securityLogout(requestParameters: SecurityApiSecurityLogoutRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.securityLogout(requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for securityFunctionalities operation in SecurityApi.
 * @export
 * @interface SecurityApiSecurityFunctionalitiesRequest
 */
export interface SecurityApiSecurityFunctionalitiesRequest {
    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof SecurityApiSecurityFunctionalities
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for securityLogin operation in SecurityApi.
 * @export
 * @interface SecurityApiSecurityLoginRequest
 */
export interface SecurityApiSecurityLoginRequest {
    /**
     * 
     * @type {RequestLogin}
     * @memberof SecurityApiSecurityLogin
     */
    readonly requestLogin: RequestLogin

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof SecurityApiSecurityLogin
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for securityLogout operation in SecurityApi.
 * @export
 * @interface SecurityApiSecurityLogoutRequest
 */
export interface SecurityApiSecurityLogoutRequest {
    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof SecurityApiSecurityLogout
     */
    readonly acceptLanguage?: string
}

/**
 * SecurityApi - object-oriented interface
 * @export
 * @class SecurityApi
 * @extends {BaseAPI}
 */
export class SecurityApi extends BaseAPI {
    /**
     * 
     * @summary devuelve la lista de funcionalidades activas
     * @param {SecurityApiSecurityFunctionalitiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityApi
     */
    public securityFunctionalities(requestParameters: SecurityApiSecurityFunctionalitiesRequest = {}, options?: AxiosRequestConfig) {
        return SecurityApiFp(this.configuration).securityFunctionalities(requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Realiza login en la aplicación y se valida el token enviado y se crea o indicar un error especifico
     * @param {SecurityApiSecurityLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityApi
     */
    public securityLogin(requestParameters: SecurityApiSecurityLoginRequest, options?: AxiosRequestConfig) {
        return SecurityApiFp(this.configuration).securityLogin(requestParameters.requestLogin, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Realiza el logout de la aplicación, y destruye el token en el back
     * @param {SecurityApiSecurityLogoutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityApi
     */
    public securityLogout(requestParameters: SecurityApiSecurityLogoutRequest = {}, options?: AxiosRequestConfig) {
        return SecurityApiFp(this.configuration).securityLogout(requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}

