import { ActionTypes } from '../types';

export const OBJECT_JSON = 'OBJECT_JSON';
export const TYPE_JSON_SCHEMA = 'TYPE_JSON_SCHEMA';

export function ObjectJson(objectJson: Object): ActionTypes {
  return { type: OBJECT_JSON, objectJson };
}

export function TypeJsonSchema(typeJsonSchema: string): ActionTypes {
  return { type: TypeJsonSchema, typeJsonSchema };
}