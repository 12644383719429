import { Terminal, TerminalList } from '../../../api';
import { ActionTypes } from '../types';

export const CLEAN_TERMINAL = 'CLEAN_TERMINAL';
export const EDITAR_TERMINAL = 'EDITAR_TERMINAL';
export const GET_TERMINALS = 'GET_TERMINALS';
export const TERMINALS_RECEIVED = 'TERMINALS_RECEIVED';
export const TERMINAL_ADDED = 'TERMINAL_ADDED';

export function clearTerminal(): ActionTypes {
  return { type: CLEAN_TERMINAL };
}

export function editarTerminal(terminal: Terminal): ActionTypes {
  return { type: EDITAR_TERMINAL, terminal };
}

export function getTerminals(): ActionTypes {
  return { type: GET_TERMINALS, loading: true };
}

export function terminalAdded(newTerminal: TerminalList): ActionTypes {
  return { type: TERMINAL_ADDED, newTerminal };
}
