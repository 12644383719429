/* tslint:disable */
/* eslint-disable */
/**
 * TMS Agnostiko
 * Tiene la definición de todos los servicios de TMS a Agnostic (para uso en la web o externo)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AffiliationInfo } from '../models';
// @ts-ignore
import { AffiliationInfoList } from '../models';
// @ts-ignore
import { Message } from '../models';
// @ts-ignore
import { RequestAffiliationBatch } from '../models';
// @ts-ignore
import { RequestAffiliationCreate } from '../models';
// @ts-ignore
import { RequestAffiliationUpdate } from '../models';
// @ts-ignore
import { RequestUpdateConfigurations } from '../models';
// @ts-ignore
import { ResponseAffiliationBatch } from '../models';
// @ts-ignore
import { ResponseCreateAffiliation } from '../models';
/**
 * AffiliationApi - axios parameter creator
 * @export
 */
export const AffiliationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Realiza la creación de múltiples afiliaciones a la vez
         * @param {RequestAffiliationBatch} requestAffiliationBatch 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliationBatch: async (requestAffiliationBatch: RequestAffiliationBatch, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestAffiliationBatch' is not null or undefined
            assertParamExists('affiliationBatch', 'requestAffiliationBatch', requestAffiliationBatch)
            const localVarPath = `/affiliation/batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestAffiliationBatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Realiza la creación de una afiliación asociada a una aplicación
         * @param {RequestAffiliationCreate} requestAffiliationCreate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAffiliation: async (requestAffiliationCreate: RequestAffiliationCreate, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestAffiliationCreate' is not null or undefined
            assertParamExists('createAffiliation', 'requestAffiliationCreate', requestAffiliationCreate)
            const localVarPath = `/affiliation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestAffiliationCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary elimina una afiliación
         * @param {string} appId 
         * @param {string} affiliationId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAffiliation: async (appId: string, affiliationId: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('deleteAffiliation', 'appId', appId)
            // verify required parameter 'affiliationId' is not null or undefined
            assertParamExists('deleteAffiliation', 'affiliationId', affiliationId)
            const localVarPath = `/affiliation/{appId}/{affiliationId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"affiliationId"}}`, encodeURIComponent(String(affiliationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Obtener la  información de una afiliacion
         * @param {string} appId 
         * @param {string} affiliationId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAffiliation: async (appId: string, affiliationId: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAffiliation', 'appId', appId)
            // verify required parameter 'affiliationId' is not null or undefined
            assertParamExists('getAffiliation', 'affiliationId', affiliationId)
            const localVarPath = `/affiliation/{appId}/{affiliationId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"affiliationId"}}`, encodeURIComponent(String(affiliationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Listado de afiliaciones asociada a una aplicación
         * @param {string} appId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAffiliation: async (appId: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('listAffiliation', 'appId', appId)
            const localVarPath = `/affiliation/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (appId !== undefined) {
                localVarQueryParameter['appId'] = appId;
            }

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary actualiza la información general de una afiliación
         * @param {string} appId 
         * @param {string} affiliationId 
         * @param {RequestAffiliationUpdate} requestAffiliationUpdate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAffiliation: async (appId: string, affiliationId: string, requestAffiliationUpdate: RequestAffiliationUpdate, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('updateAffiliation', 'appId', appId)
            // verify required parameter 'affiliationId' is not null or undefined
            assertParamExists('updateAffiliation', 'affiliationId', affiliationId)
            // verify required parameter 'requestAffiliationUpdate' is not null or undefined
            assertParamExists('updateAffiliation', 'requestAffiliationUpdate', requestAffiliationUpdate)
            const localVarPath = `/affiliation/{appId}/{affiliationId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"affiliationId"}}`, encodeURIComponent(String(affiliationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestAffiliationUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary reemplaza las configuraciones de una afiliación
         * @param {string} appId 
         * @param {string} affiliationId 
         * @param {RequestUpdateConfigurations} requestUpdateConfigurations 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConfigurations: async (appId: string, affiliationId: string, requestUpdateConfigurations: RequestUpdateConfigurations, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('updateConfigurations', 'appId', appId)
            // verify required parameter 'affiliationId' is not null or undefined
            assertParamExists('updateConfigurations', 'affiliationId', affiliationId)
            // verify required parameter 'requestUpdateConfigurations' is not null or undefined
            assertParamExists('updateConfigurations', 'requestUpdateConfigurations', requestUpdateConfigurations)
            const localVarPath = `/affiliation/configuration/{appId}/{affiliationId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"affiliationId"}}`, encodeURIComponent(String(affiliationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestUpdateConfigurations, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AffiliationApi - functional programming interface
 * @export
 */
export const AffiliationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AffiliationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Realiza la creación de múltiples afiliaciones a la vez
         * @param {RequestAffiliationBatch} requestAffiliationBatch 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async affiliationBatch(requestAffiliationBatch: RequestAffiliationBatch, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseAffiliationBatch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.affiliationBatch(requestAffiliationBatch, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AffiliationApi.affiliationBatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Realiza la creación de una afiliación asociada a una aplicación
         * @param {RequestAffiliationCreate} requestAffiliationCreate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAffiliation(requestAffiliationCreate: RequestAffiliationCreate, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCreateAffiliation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAffiliation(requestAffiliationCreate, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AffiliationApi.createAffiliation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary elimina una afiliación
         * @param {string} appId 
         * @param {string} affiliationId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAffiliation(appId: string, affiliationId: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAffiliation(appId, affiliationId, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AffiliationApi.deleteAffiliation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Obtener la  información de una afiliacion
         * @param {string} appId 
         * @param {string} affiliationId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAffiliation(appId: string, affiliationId: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AffiliationInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAffiliation(appId, affiliationId, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AffiliationApi.getAffiliation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Listado de afiliaciones asociada a una aplicación
         * @param {string} appId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAffiliation(appId: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AffiliationInfoList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAffiliation(appId, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AffiliationApi.listAffiliation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary actualiza la información general de una afiliación
         * @param {string} appId 
         * @param {string} affiliationId 
         * @param {RequestAffiliationUpdate} requestAffiliationUpdate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAffiliation(appId: string, affiliationId: string, requestAffiliationUpdate: RequestAffiliationUpdate, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAffiliation(appId, affiliationId, requestAffiliationUpdate, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AffiliationApi.updateAffiliation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary reemplaza las configuraciones de una afiliación
         * @param {string} appId 
         * @param {string} affiliationId 
         * @param {RequestUpdateConfigurations} requestUpdateConfigurations 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConfigurations(appId: string, affiliationId: string, requestUpdateConfigurations: RequestUpdateConfigurations, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConfigurations(appId, affiliationId, requestUpdateConfigurations, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AffiliationApi.updateConfigurations']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AffiliationApi - factory interface
 * @export
 */
export const AffiliationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AffiliationApiFp(configuration)
    return {
        /**
         * 
         * @summary Realiza la creación de múltiples afiliaciones a la vez
         * @param {AffiliationApiAffiliationBatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliationBatch(requestParameters: AffiliationApiAffiliationBatchRequest, options?: AxiosRequestConfig): AxiosPromise<ResponseAffiliationBatch> {
            return localVarFp.affiliationBatch(requestParameters.requestAffiliationBatch, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Realiza la creación de una afiliación asociada a una aplicación
         * @param {AffiliationApiCreateAffiliationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAffiliation(requestParameters: AffiliationApiCreateAffiliationRequest, options?: AxiosRequestConfig): AxiosPromise<ResponseCreateAffiliation> {
            return localVarFp.createAffiliation(requestParameters.requestAffiliationCreate, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary elimina una afiliación
         * @param {AffiliationApiDeleteAffiliationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAffiliation(requestParameters: AffiliationApiDeleteAffiliationRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.deleteAffiliation(requestParameters.appId, requestParameters.affiliationId, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Obtener la  información de una afiliacion
         * @param {AffiliationApiGetAffiliationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAffiliation(requestParameters: AffiliationApiGetAffiliationRequest, options?: AxiosRequestConfig): AxiosPromise<AffiliationInfo> {
            return localVarFp.getAffiliation(requestParameters.appId, requestParameters.affiliationId, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Listado de afiliaciones asociada a una aplicación
         * @param {AffiliationApiListAffiliationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAffiliation(requestParameters: AffiliationApiListAffiliationRequest, options?: AxiosRequestConfig): AxiosPromise<Array<AffiliationInfoList>> {
            return localVarFp.listAffiliation(requestParameters.appId, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary actualiza la información general de una afiliación
         * @param {AffiliationApiUpdateAffiliationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAffiliation(requestParameters: AffiliationApiUpdateAffiliationRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.updateAffiliation(requestParameters.appId, requestParameters.affiliationId, requestParameters.requestAffiliationUpdate, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary reemplaza las configuraciones de una afiliación
         * @param {AffiliationApiUpdateConfigurationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConfigurations(requestParameters: AffiliationApiUpdateConfigurationsRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.updateConfigurations(requestParameters.appId, requestParameters.affiliationId, requestParameters.requestUpdateConfigurations, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for affiliationBatch operation in AffiliationApi.
 * @export
 * @interface AffiliationApiAffiliationBatchRequest
 */
export interface AffiliationApiAffiliationBatchRequest {
    /**
     * 
     * @type {RequestAffiliationBatch}
     * @memberof AffiliationApiAffiliationBatch
     */
    readonly requestAffiliationBatch: RequestAffiliationBatch

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof AffiliationApiAffiliationBatch
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for createAffiliation operation in AffiliationApi.
 * @export
 * @interface AffiliationApiCreateAffiliationRequest
 */
export interface AffiliationApiCreateAffiliationRequest {
    /**
     * 
     * @type {RequestAffiliationCreate}
     * @memberof AffiliationApiCreateAffiliation
     */
    readonly requestAffiliationCreate: RequestAffiliationCreate

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof AffiliationApiCreateAffiliation
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteAffiliation operation in AffiliationApi.
 * @export
 * @interface AffiliationApiDeleteAffiliationRequest
 */
export interface AffiliationApiDeleteAffiliationRequest {
    /**
     * 
     * @type {string}
     * @memberof AffiliationApiDeleteAffiliation
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof AffiliationApiDeleteAffiliation
     */
    readonly affiliationId: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof AffiliationApiDeleteAffiliation
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for getAffiliation operation in AffiliationApi.
 * @export
 * @interface AffiliationApiGetAffiliationRequest
 */
export interface AffiliationApiGetAffiliationRequest {
    /**
     * 
     * @type {string}
     * @memberof AffiliationApiGetAffiliation
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof AffiliationApiGetAffiliation
     */
    readonly affiliationId: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof AffiliationApiGetAffiliation
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for listAffiliation operation in AffiliationApi.
 * @export
 * @interface AffiliationApiListAffiliationRequest
 */
export interface AffiliationApiListAffiliationRequest {
    /**
     * 
     * @type {string}
     * @memberof AffiliationApiListAffiliation
     */
    readonly appId: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof AffiliationApiListAffiliation
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateAffiliation operation in AffiliationApi.
 * @export
 * @interface AffiliationApiUpdateAffiliationRequest
 */
export interface AffiliationApiUpdateAffiliationRequest {
    /**
     * 
     * @type {string}
     * @memberof AffiliationApiUpdateAffiliation
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof AffiliationApiUpdateAffiliation
     */
    readonly affiliationId: string

    /**
     * 
     * @type {RequestAffiliationUpdate}
     * @memberof AffiliationApiUpdateAffiliation
     */
    readonly requestAffiliationUpdate: RequestAffiliationUpdate

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof AffiliationApiUpdateAffiliation
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateConfigurations operation in AffiliationApi.
 * @export
 * @interface AffiliationApiUpdateConfigurationsRequest
 */
export interface AffiliationApiUpdateConfigurationsRequest {
    /**
     * 
     * @type {string}
     * @memberof AffiliationApiUpdateConfigurations
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof AffiliationApiUpdateConfigurations
     */
    readonly affiliationId: string

    /**
     * 
     * @type {RequestUpdateConfigurations}
     * @memberof AffiliationApiUpdateConfigurations
     */
    readonly requestUpdateConfigurations: RequestUpdateConfigurations

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof AffiliationApiUpdateConfigurations
     */
    readonly acceptLanguage?: string
}

/**
 * AffiliationApi - object-oriented interface
 * @export
 * @class AffiliationApi
 * @extends {BaseAPI}
 */
export class AffiliationApi extends BaseAPI {
    /**
     * 
     * @summary Realiza la creación de múltiples afiliaciones a la vez
     * @param {AffiliationApiAffiliationBatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AffiliationApi
     */
    public affiliationBatch(requestParameters: AffiliationApiAffiliationBatchRequest, options?: AxiosRequestConfig) {
        return AffiliationApiFp(this.configuration).affiliationBatch(requestParameters.requestAffiliationBatch, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Realiza la creación de una afiliación asociada a una aplicación
     * @param {AffiliationApiCreateAffiliationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AffiliationApi
     */
    public createAffiliation(requestParameters: AffiliationApiCreateAffiliationRequest, options?: AxiosRequestConfig) {
        return AffiliationApiFp(this.configuration).createAffiliation(requestParameters.requestAffiliationCreate, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary elimina una afiliación
     * @param {AffiliationApiDeleteAffiliationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AffiliationApi
     */
    public deleteAffiliation(requestParameters: AffiliationApiDeleteAffiliationRequest, options?: AxiosRequestConfig) {
        return AffiliationApiFp(this.configuration).deleteAffiliation(requestParameters.appId, requestParameters.affiliationId, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Obtener la  información de una afiliacion
     * @param {AffiliationApiGetAffiliationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AffiliationApi
     */
    public getAffiliation(requestParameters: AffiliationApiGetAffiliationRequest, options?: AxiosRequestConfig) {
        return AffiliationApiFp(this.configuration).getAffiliation(requestParameters.appId, requestParameters.affiliationId, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Listado de afiliaciones asociada a una aplicación
     * @param {AffiliationApiListAffiliationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AffiliationApi
     */
    public listAffiliation(requestParameters: AffiliationApiListAffiliationRequest, options?: AxiosRequestConfig) {
        return AffiliationApiFp(this.configuration).listAffiliation(requestParameters.appId, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary actualiza la información general de una afiliación
     * @param {AffiliationApiUpdateAffiliationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AffiliationApi
     */
    public updateAffiliation(requestParameters: AffiliationApiUpdateAffiliationRequest, options?: AxiosRequestConfig) {
        return AffiliationApiFp(this.configuration).updateAffiliation(requestParameters.appId, requestParameters.affiliationId, requestParameters.requestAffiliationUpdate, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary reemplaza las configuraciones de una afiliación
     * @param {AffiliationApiUpdateConfigurationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AffiliationApi
     */
    public updateConfigurations(requestParameters: AffiliationApiUpdateConfigurationsRequest, options?: AxiosRequestConfig) {
        return AffiliationApiFp(this.configuration).updateConfigurations(requestParameters.appId, requestParameters.affiliationId, requestParameters.requestUpdateConfigurations, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}

