/* eslint-disable prettier/prettier */
import { LitElement, html, render } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '@ui5/webcomponents/dist/Dialog.js';
import '@vaadin/vaadin-select';
import '@vaadin/vaadin-grid';
import {
  AppApi,
  AppList,
  CommunicationTerminalApi,
  CommunicationTerminalApiRequestCommandRequest,
  ConfigurationApi,
  ConfigurationInfo,
  RequestCommandTypeCommandEnum,
  Terminal,
} from '../../../api';
import {
  succesNotification,
} from '../../services/notifications';
import { errorNotification } from '../../services/notifications';
import { apisConfigurator } from '../../utils/axios';

@customElement('push-config-modal')
export class PushConfigModal extends LitElement {
  private appApi: AppApi;
  private configApi: ConfigurationApi;
  private communicationApi: CommunicationTerminalApi;

  @state()
  private apps: AppList[] = [];

  @state()
  private configs: ConfigurationInfo[] = [];

  @state()
  private detailsOpenedItem: AppList[] = [];

  private get dialog() {
    return document.getElementById('dialog-push-config')! as any;
  }

  @state()
  private loading = false;

  constructor() {
    super();
    this.appApi = new AppApi(...apisConfigurator('Aplicaciones'));
    this.configApi = new ConfigurationApi(...apisConfigurator('Aplicaciones'));
    this.communicationApi = new CommunicationTerminalApi(
      ...apisConfigurator('Comunicación')
    );
  }

  @property({ type: Object })
  terminal?: Terminal;

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  protected firstUpdated(): void {}

  async initialGet() {
    this.loading = true;
    try {
      await this.getApps();
      await this.getConfigs();

      this.apps = this.apps.filter(
        app =>
          this.configs.find(
            conf =>
              conf.appId === app.id && conf.serial === this.terminal!.serial
          ) !== null
      );
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  async getApps() {
    const res = await this.appApi.listApp();

    this.apps = res.data;
  }

  async getConfigs() {
    const res = await this.configApi.listConfiguration();

    this.configs = res.data;
  }

  async sendPushAppConfiguration(app?: AppList) {
    //console.log('terminal', this.terminal);
    if (!this.terminal) return;
    this.loading = true;

    console.log(app);

    const requestCommand: CommunicationTerminalApiRequestCommandRequest = {
      brand: this.terminal.brand,
      serial: this.terminal.serial,
      requestCommand: {
        commandId: `updateAppConfig-${this.terminal.serial}-${Date.now()}`,
        typeCommand: RequestCommandTypeCommandEnum.UpdateAppConfig,
        parameters: app ? [app.packageName, app.packageName] : [],
      },
    };

    console.log(requestCommand);

    try {
      const res = await this.communicationApi.requestCommand(requestCommand);
      this.close();
      //console.log('envio mensaje res', res);
      succesNotification('Comando enviado!');
    } catch (err: any) {
      console.log('envio mensaje err', err);
    } finally {
      this.loading = false;
    }
  }

  async open(terminal?: Terminal) {
    if (!terminal) {
      errorNotification('Error al enviar configuraciones');
      return;
    } 

    this.loading = true;
    this.terminal = terminal;
    await this.initialGet();
    this.dialog.open = true;
  }

  close() {
    this.dialog.open = false;
    this.terminal = undefined;
    this.apps = [];
  }

  render() {
    return html` <ui5-dialog
      id="dialog-push-config"
      header-text="Enviar Configuración"
      class="w-8/12"
    >
      <vaadin-grid
        class="mx-2"
        .items="${this.apps}"
        .detailsOpenedItems="${this.detailsOpenedItem}"
      >
        <vaadin-grid-sort-column path="name"></vaadin-grid-sort-column>
        <vaadin-grid-sort-column path="description"></vaadin-grid-sort-column>
        <vaadin-grid-column
          header=""
          .headerRenderer="${(root: HTMLElement) => {
            render(
              html``,
              root
            );
          }}"
          .renderer="${(root: HTMLElement, _: any, item: any) => {
            console.log(item);
            render(
              html`<mwc-button
                class="rounded"
                type="submit"
                @click=${() => {
                  this.sendPushAppConfiguration(item.item);
                }}
                raised
                .disabled=${this.loading}
              >
                Enviar
              </mwc-button>`,
              root
            );
          }}"
        >
        </vaadin-grid-column>
      </vaadin-grid>
      <div slot="footer" class="dialog-footer">
        <button
          class="text-red-600 p-4 mb-2 rounded-md uppercase"
          id="closeDialogButton"
          @click=${() => {
            this.close();
          }}
        >
          Cerrar
        </button>
      </div>
    </ui5-dialog>`;
  }
}
