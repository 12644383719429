import { LitElement, html, render } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
// import { guard } from 'lit/directives/guard.js';
import '@ui5/webcomponents/dist/Dialog.js';
import '@vaadin/vaadin-select';
import Swal from 'sweetalert2';
import '@vaadin/vaadin-grid';
//import { TerminalApi, TerminalList } from '../../../api';
//import { AppApi, AppList, CommunicationTerminalApi,  } from '../../../api';
import {
  AppApi,
  AppList,
  CommunicationTerminalApi,
  CommunicationTerminalApiRequestCommandGroupRequest,
  CommunicationTerminalApiRequestCommandRequest,
  RequestCommandTypeCommandEnum,
  Terminal,
  FirmwareList,
  FirmwareApi
} from '../../../api';
import { errorNotification } from '../../services/notifications';
import { apisConfigurator } from '../../utils/axios';

@customElement('lista-firmware-modal')
export class ListaFirmwareModal extends LitElement {
  private appApi: AppApi;
  private firmwareApi: FirmwareApi;
  private communicationApi: CommunicationTerminalApi;

  @state()
  private apps: AppList[] = [];

  @state()
  private app:any = [];

  @state()
  private versions:any = [];

  @state()
  firmware?: any;

  @state()
  firmwares?: any;

  @state()
  private detailsOpenedItem: AppList[] = [];

  private get dialog() {
    return document.getElementById('dialog-instalar-firmware-lista')! as any;
  }

  @state()
  private loading = false;

  constructor() {
    super();
    this.appApi = new AppApi(...apisConfigurator('Aplicaciones'));
    this.communicationApi = new CommunicationTerminalApi(
      ...apisConfigurator('Comunicación')
    );
    this.firmwareApi = new FirmwareApi(...apisConfigurator('Firmware'));
  }

  @property({ type: Object })
  terminal?: any;

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  protected firstUpdated(): void {

  } 

  async getFirmwares() {
    /*try {
      const res = await this.appApi.listApp();

      this.apps = res.data;
    } catch (err) { 
      console.log(err);
    } finally {
      this.loading = false;
    }*/
    try {
        const res = await this.firmwareApi.listFirmware();
        console.log("firmwares",res)
        this.firmwares = res.data;
      } catch (error) {
        console.log(error)
      }finally{
        //this.loader = false;
      }
  }

  async open(terminal: any) {
    if (!terminal) {
      errorNotification('Error al instalar la app');
      return;
    }

    this.loading = true;
    await this.getFirmwares();

    this.terminal = terminal;
    console.log(this.terminal);
    //await this.getApps();
    this.dialog.open = true;
  }

  async getApp(appId: any) {

    try {
      const res = await this.appApi.getApp({ id: appId });
      this.app = res.data;
      let datos= {}
      this.app.version.map((camp:any,index:number)=> {
        Object.assign(datos, {
          [camp.version]: camp.version,
        });
      })
      this.versions = datos;
    } catch (err) {
      //errorNotification('Error al obtener la app');
      console.log(err);
    } finally {
      //this.loading = false;
    } 
  }
 
  private async instalarApp(aplicacion: any) {
    console.log('terminal', this.terminal);
    console.log('aplication', this.versions);
    await this.getApp(aplicacion.item.id)
    if(Object.keys(this.versions).length === 0) {
      errorNotification('sin version, no se puede instalar la app');
      return;
    }
    if (!this.terminal) return; 
    const { value: version } = await Swal.fire({
      title: 'Selecciona una version a instalar',
      input: 'select',
      inputOptions: this.versions,
      inputPlaceholder: 'Seleccionar',
      showCancelButton: true,
    })
    try {
      if (this.terminal?.id) {
        const requestCommand: CommunicationTerminalApiRequestCommandGroupRequest =
          {
            group: this.terminal.id,
            requestCommand: {
              commandId: `2022-12-20`,
              typeCommand: `UPDATE_APP`,
              parameters: [aplicacion.item.id, version],
            },
          };
        //console.log(requestCommand);
        this.close();
        const res = await this.communicationApi.requestCommandGroup(
          requestCommand
        );
        //console.log('envio mensaje res grupo', res);
      } else {
        const requestCommand: CommunicationTerminalApiRequestCommandRequest = {
          brand: this.terminal.brand,
          serial: this.terminal.serial,
          requestCommand: {
            commandId: `2022-12-20`,
            typeCommand: `UPDATE_APP`,
            parameters: [aplicacion.item.id, version],
          },
        };        
        if(version) {
          const res = await this.communicationApi.requestCommand(requestCommand);
          console.log('envio mensaje res terminal', res);
          this.close();
        }

      }
    } catch (err: any) {
      console.log('envio mensaje err', err);
    }
  }

  close() {
    this.dialog.open = false;
    this.terminal = undefined;
    this.apps = [];
  }
 
  render() {
    return html` <ui5-dialog
      id="dialog-instalar-firmware-lista"
      header-text="Instalar firmware "
      class="w-8/12"
    >
      <vaadin-grid
        class="mx-2"
        .items="${this.firmwares}"
        .detailsOpenedItems="${this.detailsOpenedItem}"
      >
        <vaadin-grid-sort-column path="name"></vaadin-grid-sort-column>
        <vaadin-grid-sort-column path="brand"></vaadin-grid-sort-column>
        <vaadin-grid-sort-column path="briefDescription"></vaadin-grid-sort-column>
        <vaadin-grid-sort-column path="description"></vaadin-grid-sort-column>
        <vaadin-grid-sort-column path="developer"></vaadin-grid-sort-column>
        <vaadin-grid-sort-column path="model"></vaadin-grid-sort-column>
        <vaadin-grid-column
          header=""
          .renderer="${(root: HTMLElement, _: any, item: any) => {
            render(
              html`<mwc-button
                class="rounded"
                type="submit"
                @click=${() => {
                  this.instalarApp(item);
                }}
                raised
              >
                Instalar
              </mwc-button>`,
              root
            );
          }}"
        ></vaadin-grid-column>
      </vaadin-grid>
      <div slot="footer" class="dialog-footer">
        <button
          class="text-red-600 p-4 mb-2 rounded-md uppercase"
          id="closeDialogButton"
          @click=${() => {
            this.close();
          }}
        >
          Cerrar
        </button>
      </div>
    </ui5-dialog>`;
  }
}
