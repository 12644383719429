/* eslint-disable prettier/prettier */
import { Console } from 'console';
import { BrandList, ModelList, OrganizationName } from '../../api';
import {
  errorNotification,
  succesNotification,
} from '../services/notifications';

export function delay(milliseconds: number) {
  return new Promise(resolve => {
    setTimeout(resolve, milliseconds);
  }); 
}

export function eliminarCaracteresMayusculas(texto:any) {
  // Eliminar caracteres especificados
  const caracteresAEliminar = [".", "#", " ","*"];
  const regex = new RegExp(`[${caracteresAEliminar.join('')}]`, "g");
  texto = texto.replace(regex, "");

  // Convertir a mayúsculas
  texto = texto.toUpperCase();

  return texto;
}

export function flatOrganizations(
  organizaciones: OrganizationName[]
): OrganizationName[] {
  if (organizaciones.length === 0) return [];
  return [
    ...organizaciones,
    ...flatOrganizations(
      organizaciones
        .map(org => org.sons)
        .reduce((prev, curr) => [...prev, ...curr], [])
    ),
  ];
}

export function searchCampObjectParameters(
  ObjectData: any,
  ArrayData: Object[]
): any {
  let valores = Object.values(ObjectData);
  let claves = Object.keys(ObjectData);
  ArrayData.map((camp: any, index: number) => {
    var indice = claves.indexOf(camp.name);
    if (indice >= 0) {
      console.log(`camp ${camp} indice ${indice}`);
      camp.value = valores[indice];
    }
  });
  return ArrayData;
}

export function insertParametersPreConfiguration(ObjectJson: Object, parameter: Object) : any {

  let claves = Object.keys(ObjectJson); 
  let valores = Object.values(ObjectJson);

  let clavesParameter = Object.keys(parameter); 
  let valoresParameter = Object.values(parameter); 
  let nuevo = {}

  claves.map((camp:any, index:number) =>{
    let indice = clavesParameter.indexOf(camp);
    console.log(indice)
    console.log(valoresParameter[index])
    if(indice >= 0) {
      valores[index].default = valoresParameter[indice];
      Object.assign(nuevo, {
        [camp]: valores[index],
      });
    }else {
      Object.assign(nuevo, {
        [camp]: valores[index],
      });
    }
  })
  return nuevo;
}

export function ObjectToArrayIndexString(ObjectJson: any, campo: string) : any {
  //console.log(ObjectJson)
  //console.log(campo)
  let claves = Object.keys(ObjectJson);
  let valores = Object.values(ObjectJson);

  const indice = claves.indexOf(campo);
  //console.log(claves)
  return valores[indice];
}

export function existenciaConfiguracionTemplate(
  ObjectJson: any,
  template_id: string,
){
  const result = ObjectJson.filter((element: any, index: number) => {
    if (
      element.templateId == template_id 
    ) {
      return true;
    }
  });
  return result.length == 0 ? false : true;
}

export function existenciaConfiguracion(
  ObjectJson: any,
  app_id: string,
  template_id: string,
  brand: string,
  serial: string
) {
  const result = ObjectJson.filter((element: any, index: number) => {
    if (
      element.appId == app_id &&
      element.templateId == template_id &&
      element.brand == brand &&
      element.serial == serial
    ) {
      return true;
    }
  });
  return result.length == 0 ? false : true;
}

export function idConfiguracion(
  ObjectJson: any,
  app_id: string,
  template_id: string,
  brand: string,
  serial: string
) {
  const result = ObjectJson.filter((element: any, index: number) => {
    if (
      element.appId == app_id &&
      element.templateId == template_id &&
      element.brand == brand &&
      element.serial == serial
    ) {
      console.log(element)
      return true; 
    }
  });
  return result.length == 0 ? false : true;
}

export function iterarObjetoJson(ObjectJson: Object[]) {
  let properties = {};
  let properties2 = {};
  ObjectJson.map((usuario, index) => {
    console.log('raiz:', properties2);
    properties2 = Object.assign({}, properties2, iterarCampoJson(usuario, {}));
    //properties = iterarCampoJson(usuario,properties)
    const wladimir = {};
    //properties2 = {...properties2, ...iterarCampoJson(usuario,{})}
    //console.log(properties2)
  });
  return properties2;
}

export function validacionRequired(ObjectJson: Object) {
  if (!ObjectJson) {
    return `campo(s) son requerido(s)`;
  }
  let claves = Object.keys(ObjectJson); // claves = ["nombre", "color", "macho", "edad"]

  let valores = Object.values(ObjectJson);
  for (let i = 0; i < valores.length; i++) {
    let valor = valores[i];
    if (typeof valor == 'number' && isNaN(valor)) {
      return `el campo ${claves[i]} es requerido`;
    } else if (
      typeof valor == 'string' &&
      (valor == undefined || valor == '')
    ) {
      return `el campo ${claves[i]} es requerido`;
    } else if (valor == undefined) {
      return `el campo ${claves[i]} es requerido`;
    }
  }

  return false;
}

export function ordenarAsc(p_array_json: Object[]) {
  p_array_json.sort(function (N1:any, N2:any) {
    if (N1.name < N2.name) {
      return -1;
    }
    if (N1.name > N2.name) {
      return 1;
    }
    return 0;
  });
}

export function addDefault(properties: Object, parameter: Object) {
  let claves = Object.keys(parameter);
  let valores = Object.values(parameter);

  Object.entries(properties).forEach(([key, value]) => {
    const indice = claves.indexOf(key);
    const valor = valores[indice];
    value.default = valor;
  });
  return properties;
}

export function deleteCamp(properties: Object) {
  const propiedad = properties;
  Object.entries(propiedad).forEach(([key, value]) => {
      value.title = value.description
      delete value.description;
    if(value.type == "string") {
      if(value.maximum==0) {
        value.maxLength=0;
        value.minLength=0;
        delete value.maximum;
        delete value.minimum;
      }
      if(!value.enum){
        value.maxLength = value.maxLength.toString(); 
        //value.minLength= value.minLength.toString(); 
        delete value.maximum;
        delete value.minimum;
      }
    }else if(value.type=="integer" || value.type=="number" ){
      if(value.maxLength==0) {
        value.maximum=0;
        value.minimum=0;
        delete value.maxLength;
        delete value.minLength;
      }
      value.maximum= value.maximum.toString();
      //value.minimum= value.minimum.toString();
      delete value.maxLength;
    } else if (value.type == 'boolean') {
      delete value.maxLength;
      delete value.maximum;
      delete value.minLength;
      delete value.minimum;
    }
    delete value.pattern;
  });
  return propiedad;
}

export function iterarCampoJson2(ObjectJson: Object, required: string[]) {
  var valores = Object.values(ObjectJson);
  //console.log(valores)
  let cont = 0;
  for (let prop in ObjectJson) {
    valores[cont].name = prop;
    valores[cont].id = cont + 1;
    valores[cont].lengthMaximo =
      valores[cont].maximum > 0
        ? valores[cont].maximum
        : valores[cont].maxLength;
    valores[cont].lengthMinimo =
      valores[cont].minimum > 0
        ? valores[cont].minimum
        : valores[cont].minLength;
    valores[cont].required = false;
    required.forEach(element => {
      if (element == prop) {
        valores[cont].required = true;
      }
    });
    cont++;
  }
  return valores;
}

// Get binary without ugly callbacks using ES7
export async function getBinaryFromFile(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener('load', () => resolve(reader.result));
    reader.addEventListener('error', err => reject(err));

    reader.readAsBinaryString(file);
  });
}

export function typeDataObject(type: string, value: string) {
  if (type == 'string') {
    return value;
  } else if (type == 'boolean') {
    return value === 'true';
  } else if (type == 'number') {
    return parseInt(value);
  } else if (type == 'integer') {
    return parseFloat(value);
  }
}

export function iterarCampoJson(ObjectJson: Object, properties: Object) {
  let nombre: string;
  var valores = Object.values(ObjectJson);

  for (let prop in ObjectJson) {
    for (let i = 0; i < valores[0].length; i++) {
      nombre = valores[0][i].campo;
      Object.assign(properties, {
        [nombre]: {
          length: valores[0][i].length,
          type: valores[0][i].type,
          default: valores[0][i].default,
          validator: valores[0][i].validator,
        },
      });
    }
  }
  return properties;
}

export function InsertarPropiedad(ObjectJson: Object) {
  let arbol = {
    id: 'http://json-schema.org/draft-04/schema#',
    $schema: 'http://json-schema.org/draft-04/schema#',
    description: 'Core schema meta-schema',
    type: 'object',
    title: 'json-structura',
    properties: {},
    required: [''],
  };
  var properties = {};
  arbol.required = [];
  let nombre: string;

  var valores = Object.values(ObjectJson);

  for (let i = 0; i < valores[0].length; i++) {
    nombre = valores[0][i].name;
    let maximun = 0;
    let minimun = 0;
    let lengthMaximo = 0;
    let lengthMinimo = 0;
    if (valores[0][i].type == 'string') {
      lengthMaximo = parseInt(valores[0][i].lengthMaximo);
      lengthMinimo = parseInt(valores[0][i].lengthMinimo);
    } else if (
      valores[0][i].type == 'number' ||
      valores[0][i].type == 'integer'
    ) {
      maximun = parseInt(valores[0][i].lengthMaximo);
      minimun = parseInt(valores[0][i].lengthMinimo);
    }
    //console.log(valores[0][i])
    if (valores[0][i].required) arbol.required.push(nombre);
    if (valores[0][i].enum) {
      Object.assign(properties, {
        [nombre]: {
          enum: valores[0][i].enum,
          type: valores[0][i].type,
          description: valores[0][i].description,
          pattern: valores[0][i].pattern,
        },
      });
    } else if (
      valores[0][i].type == 'number' ||
      valores[0][i].type == 'integer'
    ) {
      Object.assign(properties, {
        [nombre]: {
          maximum: maximun,
          minimum: minimun,
          type: valores[0][i].type,
          description: valores[0][i].description,
          pattern: valores[0][i].pattern,
        },
      });
    } else if (valores[0][i].type == 'string') {
      Object.assign(properties, {
        [nombre]: {
          maxLength: lengthMaximo,
          minLength: lengthMinimo,
          type: valores[0][i].type,
          description: valores[0][i].description,
          pattern: valores[0][i].pattern,
        },
      });
    } else if (valores[0][i].type == 'boolean') {
      Object.assign(properties, {
        [nombre]: {
          type: valores[0][i].type,
          description: valores[0][i].description,
          pattern: valores[0][i].pattern,
          format: 'checkbox',
        },
      });
    }
    //console.log(valores[0][i]);
    //if (valores[0][i].required) arbol.required.push(nombre);
  }

  arbol.properties = properties;

  return arbol;
}

export async function generate_table(arrayTable: [string[]]): Promise<string> {
  return `
    <table class="table table-striped">
      <thead>
        ${arrayTable[0]
          .map(val => {
            return `
              <th scope="col">${val}</th>
            `;
          })
          .join('')}
      </thead>
      <tbody>
        ${arrayTable
          .map((val, index) => {
            if (index === 0) return;
            return `
            <tr>
              ${val
                .map(sub_val => {
                  return `
                  <td>${sub_val}</td>
                `;
                })
                .join('')}
            </tr>
          `;
          })
          .join('')}
      </tbody>
    </table>
  `;
}

export function validar_campos_grupos(arrayTable: string[]): any {
  arrayTable.forEach((val, index) => {
    let caracter= val.replace("\r", "");
    arrayTable[index] = caracter.toUpperCase();
  });
  console.log(arrayTable)
  console.log(arrayTable.includes('SERIAL'))
  
  if (
    arrayTable.includes('SERIAL') &&
    arrayTable.includes('MARCA') &&
    arrayTable.length == 2
  ) {
    return true;
  }

  return false;
}

export function validar_campos_carga_masiva(arrayTable: string[]): any {
  arrayTable.forEach((val, index) => {
    let caracter= val.replace("\r", "");
    arrayTable[index] = caracter.toUpperCase();
  });  
  if (
    arrayTable.includes('AFILIATIONID') &&
    arrayTable.includes('DESCRIPTION') &&
    arrayTable.includes('TEMPLATE') &&
    arrayTable.includes('ITEM')

  ) {
    return true;
  }

  return false;
}

export function validar_campos_carga_masiva2(arrayTable: string[]): any {
  arrayTable.forEach((val, index) => {
    let caracter= val.replace("\r", "");
    arrayTable[index] = caracter.toUpperCase();
  });  
  if (
    arrayTable.includes('AFILIATIONID') &&
    arrayTable.includes('FOLIOID') &&
    arrayTable.includes('TEMPLATE') &&
    arrayTable.includes('ITEM')

  ) {
    return true;
  }

  return false;
}

export function validar_campos_carga_masiva_business(arrayTable: string[]): any {
  arrayTable.forEach((val, index) => {
    let caracter= val.replace("\r", "");
    arrayTable[index] = caracter.toUpperCase();
  });  
  if (
    arrayTable.includes('BUSINESSID') &&
    arrayTable.includes('DESCRIPTION') &&
    arrayTable.includes('CONTACTPHONE') &&
    arrayTable.includes('CONTACTEMAIL') &&
    arrayTable.includes('CONTACTNAME') &&
    arrayTable.includes('RFC') 
  ) {
    return true;
  }

  return false;
}

export function validar_campos_carga_affiliation(arrayTable: string[]): any {
  arrayTable.forEach((val, index) => {
    let caracter= val.replace("\r", "");
    arrayTable[index] = caracter.toUpperCase();
  });  
  if (
    arrayTable.includes('BUSINESSID') &&
    arrayTable.includes('AFFILIATIONID') &&
    arrayTable.includes('NAME') &&
    arrayTable.includes('CONTACTPHONE') &&
    arrayTable.includes('CONTACTEMAIL') &&
    arrayTable.includes('CONTACTNAME') 
  ) {
    return true;
  }

  return false;
}


export function verificarTemplates(arrayTable: string[], templatesVerificar: any[],titulos: any[]): any {
  const elementoEncontrado:any = []
  let band = true;
  let mensaje = "plantilla no encontrada:\n"
  arrayTable.forEach((val:any, index:number) => {
    if(index>1) {
      if(titulos[index]==="template") {
        band = false;
        templatesVerificar.map((item:any,index:number) =>{
          if (item.name === val) {
            band=true
          } 
        })
        if(!band){
          mensaje = mensaje+`${val}\n`
        }
      }
    }
  });
return band ? "" : mensaje;
}

export function parameteArraysBusiness(arrayTable: string[], indices: any[],titulos: any[],templates: string[],templatesVerificar:string[]): any {
  let objeto:any= {};
  let cont = 0;
  let numeroItem:number = 0;
  let configuration:any = []
  let band:boolean = false;
  let template2:string[] = []
  let template:string = ""
  let parametersArray:any = []
  let parameters:any = []
  arrayTable.forEach((val:any, index:number) => {
    if(index>5) {
    let valor:any = ""
    if (!isNaN(val)) {
      // Es un número
      const regexDecimal = /\d+\.\d{2}$/;
      const regexZeros = /^0+/;
      if (regexDecimal.test(val)) {
        valor = val
      }else if (regexZeros.test(val)) {
        valor = val
      }else{
        valor = Number(val);
      }
    } else if (val === "true" || val === "false") {
      // Es un booleano
      valor = Boolean(val);
    } else if (/\d{4}-\d{2}-\d{2}/.test(val)) {
      // Es una fecha
      valor = new Date(val);
    } else {
      // Es un string
      valor = val;
    }
    objeto[titulos[index]] = valor;
  }
  });
  console.log(objeto)


  return objeto;
}

export function parameteArraysAfiliation(arrayTable: string[], indices: any[],titulos: any[],templates: string[],templatesVerificar:string[]): any {
  let objeto:any= {};
  let cont = 0;
  let numeroItem:number = 0;
  let configuration:any = []
  let band:boolean = false;
  let template2:string[] = []
  let template:string = ""
  let parametersArray:any = []
  let parameters:any = []
  arrayTable.forEach((val:any, index:number) => {
    console.log(val);
    if(index>1) {
    let valor:any = ""
    if (!isNaN(val)) {
      // Es un número
      const regexDecimal = /\d+\.\d{2}$/;
      const regexZeros = /^0+/;
      if (regexDecimal.test(val)) {
        valor = val
      }else if (regexZeros.test(val)) {
        valor = val
      }
    } else if (val === "true" || val === "false") {
      // Es un booleano
      valor = Boolean(val);
    } else if (/\d{4}-\d{2}-\d{2}/.test(val)) {
      // Es una fecha
      valor = new Date(val);
    } else {
      // Es un string
      valor = val;
    }
    objeto[titulos[index]] = valor;
  }
  });
  console.log(objeto)


  return objeto;
}

export function parameteArrays(arrayTable: string[], indices: any[],titulos: any[],templates: string[],templatesVerificar:string[], plantillas:any[]): any {
  let objeto:any= {};
  let cont = 0;
  let numeroItem:number = 0;
  let configuration:any = []
  let band:boolean = false;
  let template2:string[] = []
  let template:string = ""
  let parametersArray:any = []
  let parameters:any = []
  arrayTable.forEach((val:any, index:number) => {
    //console.log("VAL PRUEBAS",val)
    //console.log("indices pruebas",indices)
    //console.log("titulos pruebas",titulos)
    //console.log("index pruebas",index)
    if(index>1) { // verifica que el puntero este ubicado despues de la casilla "description"
      //console.log("POLICIA 1")
      if(titulos[index]==="item"){
        numeroItem=parseInt(val);
      }
      if(titulos[index]==="template"){ // verifica si en el archivo esta la casilla item
        if(band) {
          if(parseInt(arrayTable[index+1])>1) {
            parametersArray.push(objeto)
            objeto = {}
          }else{
            if(numeroItem){
              parametersArray.push(objeto);
              configuration.push({
                "templateId":template,
                "parametersArray":parametersArray
              })
              objeto = {}
              parametersArray = []
            }else{
              parameters=objeto;
              configuration.push({
                "templateId":template,
                "parameters":parameters
              })
              objeto = {}
              parameters = {}
            }
          }
        }
        let plantilla = "-----"
        templatesVerificar.map((item:any,index:number) =>{

          if (item.name === val) {
            plantilla = item.id;
          } 
        })
        template = plantilla;
        band= true;

        
      }else if(arrayTable.length == index+1){
        if(numeroItem){
          parametersArray.push(objeto);
          configuration.push({
            "templateId":template,
            "parametersArray":parametersArray
          })
          objeto = {}
          parametersArray = []
        }else{
          parameters=objeto;
          configuration.push({
            "templateId":template,
            "parameters":parameters
          })
          objeto = {}
          parameters = {}
        }
      }else{ 
        if(titulos[index]!=="item"){
          let valor:any = ""
          if (plantillas[titulos[index]].type==="number") {
            // Es un número
            valor = Number(val);
          } else if (plantillas[titulos[index]].type==="boolean") {
            // Es un booleano
            if(val === "TRUE") {
              valor = true;
            }else{
              valor = false;
            }
            console.log(valor)
          } else {
            // Es un string
            valor = val;
          }
          objeto[titulos[index]] = valor;
        }
      }
    }
  });
  //console.log(configuration)


  return configuration;
}

export function parameteArrays2(arrayTable: string[], indices: any[],titulos: any[],templates: string[],templatesVerificar:string[],plantillas:any[]): any {
  let objeto:any= {};
  let cont = 0;
  let indiceBand = 0;
  let indexx=0;
  let numeroItem:number = 0;
  let configuration:any = []
  let band:boolean = false;
  let template2:string[] = []
  let template:string = ""
  let parametersArray:any = []
  let parameters:any = []
  arrayTable.forEach((val:any, index:number) => {
    if(index>2) { // verifica que el puntero este ubicado despues de la casilla "description"
      if(titulos[index]==="item"){
        numeroItem=parseInt(val);
        indexx = parseInt(val);
      }
      if(titulos[index]==="template"){ // verifica si en el archivo esta la casilla item
        if(band) {
          if(parseInt(arrayTable[index+1])>1) {
            if(indexx-1 < 0){
              errorNotification('Los indices no son correctos');
              indiceBand = 1;
            }
            objeto.index=indexx-1;
            console.log(objeto)
            parametersArray.push(objeto)
            objeto = {}
            indexx = 0
          }else{
            if(numeroItem){
              if(indexx-1 < 0){
                errorNotification('Los indices no son correctos');
                indiceBand = 1;
              }
              objeto.index=indexx-1;
              console.log(objeto)
              parametersArray.push(objeto);
              configuration.push({
                "templateId":template,
                "parametersArray":parametersArray
              })
              objeto = {}
              indexx = 0;
              parametersArray = []
            }else{
              parameters=objeto;
              configuration.push({
                "templateId":template,
                "parameters":parameters
              })
              objeto = {}
              parameters = {}
            }
          }
        }
        let plantilla = "-----"
        templatesVerificar.map((item:any,index:number) =>{

          if (item.name === val) {
            plantilla = item.id;
          } 
        })
        template = plantilla;
        band= true;

        
      }else if(arrayTable.length == index+1){
        if(numeroItem){
          parametersArray.push(objeto);
          configuration.push({
            "templateId":template,
            "parametersArray":parametersArray
          })
          objeto = {}
          parametersArray = []
        }else{
          parameters=objeto;
          configuration.push({
            "templateId":template,
            "parameters":parameters
          })
          objeto = {}
          parameters = {}
        }
      }else if(titulos[index]==="index"){
      }else{
        if(titulos[index]!=="item"){
          console.log("index",index)
          let valor:any = ""
          if (plantillas[titulos[index]].type==="number") {
            // Es un número
            valor = Number(val);
          } else if (plantillas[titulos[index]].type==="boolean") {
            // Es un booleano
            if(val === "TRUE") {
              valor = true;
            }else{
              valor = false;
            }
            console.log(valor)
          } else {
            // Es un string
            valor = val;
          }
          objeto[titulos[index]] = valor;
        }
      }
    }
  });
  console.log(configuration)


  return indiceBand ? 0 : configuration;
}


export function validar_campos(arrayTable: string[]): any {
  arrayTable.forEach((val, index) => {
    let caracter= val.replace("\r", "");
    arrayTable[index] = caracter.toUpperCase();
  });
  console.log(arrayTable)
  console.log(arrayTable.includes('SERIAL'))
  
  if (
    arrayTable.includes('SERIAL') &&
    arrayTable.includes('MARCA') &&
    arrayTable.includes('MODELO') &&
    arrayTable.includes('ORGANIZACION') &&
    arrayTable.includes('DUENO') &&
    arrayTable.includes('DESCRIPCION') &&
    arrayTable.length == 6
  ) {
    return true;
  }

  return false;
}

export function modelosPorMaca(
  modelosMarcas: BrandList[],
  marca: string
): ModelList[] {
  return modelosMarcas.filter(m => m.brand === marca)[0]?.models ?? [];
}

export function flatMarcas(marcas: BrandList[]): string[] {
  return marcas.map(m => m.brand);
}
