/* eslint-disable prettier/prettier */
import { html, LitElement, render } from 'lit';
import { guard } from 'lit/directives/guard.js';
import { customElement, state } from 'lit/decorators.js';

import '@ui5/webcomponents/dist/Tree.js';
import '@ui5/webcomponents/dist/Icon.js';
import '@ui5/webcomponents-icons/dist/it-instance.js';
import '@ui5/webcomponents-icons/dist/group-2.js';
import '@ui5/webcomponents-icons/dist/alert.js';
import '@ui5/webcomponents-icons/dist/cart.js';
import '@ui5/webcomponents-icons/dist/bell.js';
import '@material/mwc-icon';
import '@material/mwc-icon-button';
import '@vaadin/vaadin-grid';  
import Swal from 'sweetalert2';
import './TerminalList.css';
import {
  Grid,
  GridActiveItemChangedEvent,
  GridItemModel,
} from '@vaadin/vaadin-grid';
import { AxiosResponse } from 'axios';

import '../../components/modals/EditOrganizationModal';
import router from '../../router/router.js';
import {
  CommunicationTerminalApi,
  CommunicationTerminalApiRequestCommandRequest,
  TerminalApi,
  OrganizationApi,
} from '../../../api/api.js';
import {
  RequestCommandTypeCommandEnum,
  Terminal,
  TerminalList,
} from '../../../api/index.js';
import store from '../../redux/store.js';
import { ConfigConsole } from '../../redux/actions';
import { editarTerminal } from '../../redux/actions/index.js';
import { ListaAppModal } from '../../components/modals/ListaAppModal';
import { ListaFirmwareModal } from '../../components/modals/ListaFirmwareModal';
import { PushConfigModal } from '../../components/modals/PushConfigurationsDialog';
import '../../components/modals/PushConfigurationsDialog';
import { UbicacionTerminalMapa } from '../../components/modals/UbicacionTerminalMapa';
import '../../components/modals/ListaAppModal';
import '../../components/modals/ListaFirmwareModal';
import { ListadoApp } from '../../components/modals/ListadoApp';
import '../../components/modals/ListadoApp';
import { UpdateHoraFecha } from '../../components/modals/UpdateHoraFecha';
import '../../components/modals/UpdateHoraFecha';
import '../../components/modals/UbicacionTerminalMapa';
import { EditOrganizationModal } from '../../components/modals/EditOrganizationModal.js';
import { apisConfigurator } from '../../utils/axios';
import { EnviarMensajeModal } from '../../components/modals/EnviarMensajeModal';
import '../../components/modals/EnviarMensajeModal';
import { HistorialComandosModal } from '../../components/modals/HistorialComandosModal';
import '../../components/modals/HistorialComandosModal';
import { UltimosComandosModal } from '../../components/modals/UltimosComandosModal';
import '../../components/modals/UltimosComandosModal';

import {
  errorNotification,
  succesNotification,
} from '../../services/notifications';
import { getTerminals } from '../../redux/actions/terminals';
import { Unsubscribe } from '@reduxjs/toolkit';

@customElement('terminal-list-page')
export class TerminalListPage extends LitElement {
  private terminalApi: TerminalApi;
  private communicationApi: CommunicationTerminalApi;
  private organizationApi!: OrganizationApi;

  private storeUnsuscriber?: Unsubscribe;

  get enviarMensajeModal() {
    return document.getElementById('enviarMensajeModal') as EnviarMensajeModal;
  }

  get listadoApps() {
    return document.getElementById('listadoApp') as ListadoApp;
  }

  get updateHoraFecha() {
    return document.getElementById('updateHoraFecha') as UpdateHoraFecha;
  }

  /*get cambiarMaster() {
    return document.getElementById('cambiarMaster') as CambiarMaster;
  }*/

  get historialComandosModal() {
    return document.getElementById(
      'historialComandosModal'
    ) as HistorialComandosModal;
  }

  get ultimosComandosModal() {
    return document.getElementById(
      'ultimosComandosModal'
    ) as UltimosComandosModal;
  }

  @state()
  private terminals: TerminalList[] = [];

  private constructor() {
    super();
    this.terminalApi = new TerminalApi(...apisConfigurator('Terminales'));
    this.communicationApi = new CommunicationTerminalApi(
      ...apisConfigurator('Terminales')
    );
    this.organizationApi = new OrganizationApi(
      ...apisConfigurator('Organización')
    );
  }
  @state()
  termi?: Terminal;

  @state()
  dataFiltre: any = [];

  @state()
  filter: any = "";

  @state()
  connection?: any = {value:""};

  @state()
  NameBtn = 'Crear Terminal';

  @state()
  detail = false;

  @state()
  organizacion = '';

  @state()
  organizaciones:any = '';

  @state()
  private detailsOpenedItem: Terminal[] = [];

  @state()
  loader = true;

  @state()
  ultimaConexion:any = "";

  @state()
  brand = '';

  @state()
  serial = '';

  @state()
  loadingSidebar = false;

  get editOrganizationModal() {
    return document.getElementById(
      'edit-organization-modal'
    ) as EditOrganizationModal;
  }

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  disconnectedCallback(): void {
    this.storeUnsuscriber && this.storeUnsuscriber();
  }

  private async handleDeleteTerminal(terminal: Terminal) {
    try {
      const res = await this.terminalApi.terminalDelete({
        brand: terminal.brand,
        serial: terminal.serial,
      });
      this.terminals = this.terminals.filter(t => t.serial !== terminal.serial);
      if (res.status == 200) {
        //router.cambiarSeccion('plantilla-page');
        await Swal.fire('Eliminado!', 'El Terminal ha sido eliminado', 'success');
        //window.location.reload()
        router.cambiarSeccion('terminal-list');
      } 
      this.termi = undefined;
    } catch (err) {}
  }

  protected firstUpdated(): void {
    this.getTerminals();
    this.ObtenerOrganizaciones();
  }
 
  async getTerminals() {
    store.dispatch(getTerminals());
    this.storeUnsuscriber = store.subscribe(() => {
      const { list, loading, listApps } = store.getState();
      console.log(loading);
      console.log({ list });
      this.terminals = list as TerminalList[];
      this.loader = loading;
    });
  }

  private actionRenderer = (root: HTMLElement, _: any, item: any) => {
    render(
      html`
        <mwc-icon-button
          @click=${() => router.cambiarSeccion('terminal')}
          icon="visibility"
        ></mwc-icon-button>
        <mwc-icon-button
          icon="edit"
          @click="${() => {
            store.dispatch(editarTerminal(item.item));
            router.cambiarSeccion('edit-terminal');
          }}"
        ></mwc-icon-button>
        <mwc-icon-button
          icon="corporate_fare"
          @click="${() => {
            store.dispatch(editarTerminal(item.item));
            this.editOrganizationModal.open();
          }}"
        ></mwc-icon-button>
        <mwc-icon-button
          icon="delete"
          @click="${() => {
            this.handleDeleteTerminal(item.item);
          }}"
        ></mwc-icon-button>
        <mwc-icon-button icon="more_vert"></mwc-icon-button>
      `,
      root
    );
  };

  edit(e: any) {
    store.dispatch(editarTerminal(e));
    router.cambiarSeccion('edit-terminal');
  }

  get ListarAplicacionesModal() {
    return document.getElementById('listaAppModal')! as ListaAppModal;
  }

  get ListarFirmwareModal() {
    return document.getElementById('listaFirmwareModal')! as ListaFirmwareModal;
  }


  get pushConfigModal() {
    return document.getElementById('pushConfigModal')! as PushConfigModal;
  }

  get UbicarTerminalMapa() {
    return document.getElementById(
      'ubicacionTerminalMapa'
    )! as UbicacionTerminalMapa;
    //return document.getElementById('listaAppModal')! as ListaAppModal;
  }

  async rebootTerminal(terminal: Terminal) {
    const request: CommunicationTerminalApiRequestCommandRequest = {
      brand: terminal.brand,
      serial: terminal.serial,
      requestCommand: {
        commandId: `reboot-${terminal.serial}-${Date.now()}`,
        typeCommand: RequestCommandTypeCommandEnum.Reset,
      },
    };

    const res = await this.communicationApi.requestCommand(request);

    //console.log(res);
  }

  async ObtenerOrganizaciones() {
    await this.organizationApi
      .organizationList()
      .then(response => response)
      .then(data => {
          console.log("ORGANIZACIONES",data)
        this.organizaciones = data.data;
      });
  }
 
  async ObtenerOrganizacion(id: string) {
    const found = this.organizaciones.find((element:any) => element.id == id);
    await this.organizationApi
      .organizationGet({ id })
      .then(response => response)
      .then(data => {
        this.organizacion = data.data.hasOwnProperty("name") ? data.data.name : found.name;
      });
  }

  handleChange = (e: any) => {
    console.log(e.target.value)
    this.filter = e.target.value.toLowerCase();
    this.getDataPerPage()
  };

  getDataPerPage = () => {
    let dataAux = this.terminals;
    if (this.dataFiltre !== "") {
      dataAux = dataAux.filter(
        (row:any) =>
          JSON.stringify(row.serial).includes(this.filter) ||
          row.brand.toLowerCase().includes(this.filter) ||
          row.model.toLowerCase().includes(this.filter) ||
          //row.owner.toLowerCase().includes(this.filter) ||
          row.status.toLowerCase().includes(this.filter)
      );
    }else{
      this.getTerminals()
    }
    this.terminals = dataAux;
  }

  private async refrescarInfo(terminal: any) {
    console.log('terminal', terminal);
    if (!terminal) return;
    const requestCommand: CommunicationTerminalApiRequestCommandRequest = {
      brand: terminal.brand,
      serial: terminal.serial,
      requestCommand: {
        commandId: `displayMessage-${terminal.serial}-${Date.now()}`,
        typeCommand: RequestCommandTypeCommandEnum.SendInformation,
        parameters: [],
      },
    };
    console.log(requestCommand);
    try {
      console.log('empezar');
      const res = await this.communicationApi.requestCommand(requestCommand);
      console.log('envio PETICION res', res);
      succesNotification('Comando ejecutado con exito!');
    } catch (err: any) {
      console.log('envio mensaje err', err);
    }
  }

  async toggleBluetooth() {
    const request: CommunicationTerminalApiRequestCommandRequest = {
      brand: this.termi!.brand,
      serial: this.termi!.serial,
      requestCommand: {
        commandId: `bluetooth-${this.termi?.serial}-${Date.now()}`,
        typeCommand:
          this.termi?.generalInformation?.bluetoothEnable.value === '1'
            ? RequestCommandTypeCommandEnum.DeactivateBluetooth
            : RequestCommandTypeCommandEnum.ActivateBluetooth,
      },
    };

    const res = await this.communicationApi.requestCommand(request);
    if (!this.termi) return;
    if (this.termi.generalInformation.bluetoothEnable.value === '1') {
      this.termi.generalInformation.bluetoothEnable.value = '0';
    } else {
      this.termi.generalInformation.bluetoothEnable.value = '1';
    }
    //console.log(res);
  }

  async updateTimeDate() {
    let date = new Date()
    let fecha = ""
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    if(month < 10){
      fecha =`${day}-0${month}-${year}`
    }else{
      fecha = `${day}-${month}-${year}`
    }

    var horas = date.getHours();
    var minutos:any = date.getMinutes();
    if(date.getMinutes() < 10) minutos = "0" + date.getMinutes();
    const event = new Date(Date.UTC(2012, 11, 20, 3, 0, 0));
    const options:any = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
 
    const request: CommunicationTerminalApiRequestCommandRequest = {
      brand: this.termi!.brand,
      serial: this.termi!.serial,
      requestCommand: {
        commandId: `updateDateTime-${this.termi!.serial}-${Date.now()}`,
        typeCommand: `UPDATE_DATETIME`,
        parameters: [fecha,`${horas}:${minutos}`,event.toLocaleDateString(undefined, options)],
      },
    };


    const res = await this.communicationApi.requestCommand(request);
    console.log(res)
    if(res.status == 200) {
      Swal.fire(
        'Completado!',
        'El comando e ha sido enviado.',
        'success'
      )
    }
    if (!this.termi) return;
    if (this.termi.generalInformation.wifiEnable.value === '1') {
      this.termi.generalInformation.wifiEnable.value = '0';
    } else {
      this.termi.generalInformation.wifiEnable.value = '1';
    }

    //console.log(res);
  }

  async toggleWifi() {
    const request: CommunicationTerminalApiRequestCommandRequest = {
      brand: this.termi!.brand,
      serial: this.termi!.serial,
      requestCommand: {
        commandId: `wifi-${this.termi?.serial}-${Date.now()}`,
        typeCommand:
          this.termi?.generalInformation?.wifiEnable.value === '1'
            ? RequestCommandTypeCommandEnum.DeactivateWifi
            : RequestCommandTypeCommandEnum.ActivateWifi,
      },
    };

    const res = await this.communicationApi.requestCommand(request);
    console.log(res)
    if (!this.termi) return;
    if (this.termi.generalInformation.wifiEnable.value === '1') {
      this.termi.generalInformation.wifiEnable.value = '0';
    } else {
      this.termi.generalInformation.wifiEnable.value = '1';
    }

    //console.log(res);
  }

  async getTerminalDetail(terminal: TerminalList) {
    this.loadingSidebar = true;
    const res = await this.terminalApi.terminalGet({
      brand: terminal.brand,
      serial: terminal.serial,
    });
    this.termi = res.data;
    if(this.termi?.lastActivity) {
      const fecha = new Date(this.termi?.lastActivity);
      const año = fecha.getFullYear();
      const mes = fecha.getMonth() + 1; // Los meses en JavaScript van de 0 a 11
      const dia = fecha.getDate();
      const hora = fecha.getHours();
      const minutos = fecha.getMinutes();
      const segundos = fecha.getSeconds();
      this.ultimaConexion = `${año}-${mes.toString().padStart(2, '0')}-${dia.toString().padStart(2, '0')} ${hora.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}:${segundos.toString().padStart(2, '0')}`;
    }else{
      this.ultimaConexion = "Sin Informacion";
    } 
    console.log("PUNTO",res.data);
    this.connection = Object.entries(res.data.generalInformation).length === 0 ? {value:"sin informacion"} : res.data.generalInformation.networkType;
    this.loadingSidebar = false;
  }

  render() {
    return html`
      <main
        class="w-full h-full FondoColor flex  ${!this.loader
          ? ''
          : 'items-center justify-center'}"
      >
        ${this.loader !== true
          ? html`
              <section
                class="w-3/4 relative flex flex-wrap p-4 SectionList rounded-l-lg"
              >
              <div class="row-buscador py-4">
                  <!-- <input
                    type="search"
                    placeholder="Buscar"
                    id="gsearch"
                    style=":hover {background: white; border: 1px solid gainsboro;}"
                    class="w-full border-0 rounded-xl bg-gray-300 h-10"
                    name="gsearch"
                    @keyup="${async(ev: Event) => {
                      this.handleChange(ev)
                    }}"
                  /> -->
                </div>
                <!-- <div id='editorHolder'></div> -->
                <vaadin-grid
                  @click=${(e:any) => {
                    console.log(e.target.innerText)
                  }}
                  class="w-screen h-full"
                  .items="${this.terminals}"
                  .detailsOpenedItems="${this.detailsOpenedItem}"
                  @active-item-changed="${(
                    e: GridActiveItemChangedEvent<Terminal>
                  ) => {
                    this.detailsOpenedItem = [e.detail.value];
                    let organization = '';
                    if (!e.detail.value) return false;
                    //console.log(e.detail)
                    //if(e.detail.value.hasOwnProperty("organization"))
                    organization = e.detail.value.organization;

                    this.ObtenerOrganizacion(organization);
                    console.log(e.detail.value);

                    const brand = e.detail.value?.brand;
                    const serial = e.detail.value?.serial;
                    //const objecto1 = configConsole;
                    //const objecto2 = { appId, app };
                    //Object.assign(objecto1, objecto2);
                    store.dispatch(
                      ConfigConsole({
                        brand,
                        serial,
                      })
                    );
                    this.brand = e.detail.value?.brand;
                    console.log("TERMINAL",e.detail.value)
                    //this.terminal = e.detail.value?.id;
                    this.serial = e.detail.value?.serial;
                    if (!e.detail.value) return;

                    this.communicationApi.requestCommand({
                      brand: e.detail.value.brand,
                      serial: e.detail.value.serial,
                      requestCommand: {
                        commandId: `getInformation-${
                          e.detail.value.serial
                        }-${Date.now()}`,
                        typeCommand:
                          RequestCommandTypeCommandEnum.SendInformation,
                      },
                    });

                    setTimeout(() => {
                      this.getTerminalDetail(e.detail.value);
                    }, 1000);
                  }}"
                  .rowDetailsRenderer="${guard(
                    [],
                    () => (root: HTMLElement, _: Grid, model: GridItemModel<Terminal>) => {
                        this.termi = model.item;
                        if (this.detail === false) {
                          this.detail = true;
                        } else if (this.detail === true) {
                          this.detail = false;
                        }
                        render(
                          html`<vaadin-form-layout
                            .responsiveSteps=" ${[
                              { minWidth: '0', columns: 3 },
                            ]}"
                          >
                            <div
                            style="background-color:#F0EFFF;"
                              class="w-full h-14 flex items-center mt-5 justify-center flex-row "
                            >
                              <div
                                class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                                @click=${() => {
                                  //console.log('enviar mensaje', this.termi);
                                  this.enviarMensajeModal.open(this.termi!);
                                }}
                              >
                                <div>
                                  <mwc-icon-button icon="chat">
                                  </mwc-icon-button>
                                </div>
                                <div>
                                  <p class="text-center">Enviar mensaje</p>
                                </div>
                              </div>
                              <div
                                
                                class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                                @click=${() => {
                                  this.pushConfigModal.open(this.termi);
                                }} 
                              >
                                <div>
                                  <mwc-icon-button icon="autorenew">
                                  </mwc-icon-button>
                                </div>
                                <div>
                                  <p class="text-center  ">Obtener Config</p>
                                </div>
                              </div>
                              <div
                                class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                                @click=${() => {
                                  //console.log('enviar mensaje', this.termi);
                                  this.refrescarInfo(this.termi);
                                }}
                              >
                                <div>
                                  <mwc-icon-button icon="autorenew">
                                  </mwc-icon-button>
                                </div>
                                <div>
                                  <p class="text-center  ">Refrescar Info</p>
                                </div>
                              </div>
                              <div
                                class="btnMessage items-center content-center cursor-pointer flex  flex-row cursor-pointer"
                                @click=${() => {
                                  //console.log('enviar mensaje', this.termi);
                                  this.ListarAplicacionesModal.open(
                                    this.termi!
                                  );
                                }}
                              >
                                <div>
                                  <mwc-icon-button icon="widgets">
                                  </mwc-icon-button>
                                </div>
                                <div>
                                  <p class="text-center  ">Instalar apps</p>
                                </div>
                              </div>
                              <div
                                class="btnReset items-center content-center cursor-pointer flex  flex-row cursor-pointer"
                                @click=${() => {
                                  //console.log('enviar mensaje', this.termi);
                                  this.rebootTerminal(this.termi!);
                                }}
                              >
                                <div>
                                  <mwc-icon-button icon="autorenew">
                                  </mwc-icon-button>
                                </div>
                                <div>
                                  <p class="text-center  ">Reiniciar</p>
                                </div>
                              </div>

                              <!-- <div
                                class="bg-gray-200 rounded-full mx-2 items-center content-center cursor-pointer flex flex-row"
                                @click=${() => {
                                  this.historialComandosModal.open(this.termi!);
                                }} 
                              >
                                <div>
                                  <mwc-icon-button icon="receipt">
                                  </mwc-icon-button>
                                </div> 
                              </div> -->
                              <!-- <div
                                class="btnBloquear items-center content-center  flex  flex-row cursor-pointer"
                              >
                                <div>
                                  <mwc-icon-button icon="block">
                                  </mwc-icon-button>
                                </div>
                                <div>
                                  <p class="text-center  ">Bloquear</p>
                                </div>
                              </div> -->
                            </div>
                          </vaadin-form-layout>`,
                          root
                        );
                      }
                  )}"
                >
                  <vaadin-grid-selection-column></vaadin-grid-selection-column>
                  <vaadin-grid-filter-column
                    
                    path="serial" 
                  ></vaadin-grid-filter-column>
                  <vaadin-grid-filter-column
                    header="Marca"
                    path="brand"
                  ></vaadin-grid-filter-column>
                  <vaadin-grid-filter-column
                    path="model"
                    header="Modelo"
                  ></vaadin-grid-filter-column>
                  <vaadin-grid-filter-column
                    path="owner"
                    header="Dueño"
                  ></vaadin-grid-filter-column>
                  <vaadin-grid-filter-column
                    path="status"
                    header="Estatus"
                    .renderer="${guard(
                      [],
                      () => (root: HTMLElement, _: any, model: any) => {
                        const bg =
                          model.item.status == `online`
                          ? `text-green-500`
                          : model.item.status == `created`
                          ? `text-orange-500`
                          : `text-red-500`;
                        render(
                          html`<p 
                            class="py-1 capitalize px-4 w-fit ${bg} rounded font-bold"
                          >
                            ${model.item.status}
                          </p>`,
                          root
                        );
                      }
                    )}"
                  ></vaadin-grid-filter-column>
                  <!-- <vaadin-grid-column
                    .headerRenderer="${(root: HTMLElement) => {
                    render(html``, root);
                  }}"
                    text-align="end"
                    .renderer="${this.actionRenderer}"
                  >
                  </vaadin-grid-column> -->
                </vaadin-grid>
                <mwc-icon-button
                  icon="autorenew"
                  class="absolute top-0 right-0"
                  @click=${() => {
                    this.getTerminals();
                  }}
                ></mwc-icon-button>
                <edit-organization-modal
                  id="edit-organization-modal"
                ></edit-organization-modal>
              </section>
              <section class="w-1/4  SectionDetail justify-center scroll">
                <div class="w-full flex justify-center">
                ${this.loadingSidebar !== true
                ? html`
                      `
                : html`
                    <mwc-circular-progress
                      indeterminate
                      density=${20}
                    ></mwc-circular-progress>
                  `}
                  <mwc-button
                    raised
                    icon="smartphone"
                    class="rounded w-4/5 mx-auto mt-4 mb-2"
                    style="--mdc-theme-primary: #2E3EAE; --mdc-theme-on-primary: white;"
                    @click="${() => {
                      router.cambiarSeccion('create-terminal');
                    }}"
                  >
                    Crear Terminal
                  </mwc-button>
                </div>
                <div class="w-full flex justify-center">
                  <mwc-button
                      raised
                      icon="dvr"
                      class="rounded w-4/5 mx-auto mt-4 mb-2"
                      style="--mdc-theme-primary: #2E3EAE; --mdc-theme-on-primary: white;"
                      @click="${() => {
                        //router.cambiarSeccion('create-terminal');
                        this.ultimosComandosModal.open();
                      }}"
                    >
                      Ultimos Comandos
                  </mwc-button>
                </div>
                ${this.detail === true && this.NameBtn === 'Crear Terminal'
                  ? html`
                      <div class="w-full py-3 border-b-2 border-grey-100 px-4">
                        <div>
                          <h1 class="text-2xl	text-blue-900 ml-3 mb-8	">
                            ${this.termi?.serial}
                          </h1>
                        </div>
                        <div class="flex flex-row items-center">
                          <p class="text-base	font-bold	text-blue-500	ml-3">
                            Medio Conexion:
                          </p>
                          <p class="text-base		text-blue-500	ml-3">
                            ${this.connection.value}
                          </p>
                        </div> 
                        <div class="flex flex-row items-center">
                          <p class="text-base	font-bold	text-blue-500	ml-3">
                            Ultima Conexion:
                          </p>
                          <p class="text-base		text-blue-500	ml-3">
                            ${this.ultimaConexion}
                          </p>
                        </div> 
                        <div class="flex flex-row items-center">
                          <p class="text-base	font-bold	text-blue-500	ml-3">
                            Modelo:
                          </p>
                          <p class="text-base		text-blue-500	ml-3">
                            ${this.termi?.model}
                          </p>
                        </div>
                        <div class="flex flex-row items-center">
                          <p class="text-base	font-bold	text-blue-500	ml-3">
                            Marca:
                          </p>
                          <p class="text-base		text-blue-500	ml-3">
                            ${this.termi?.brand}
                          </p>
                        </div>
                        <div class="flex flex-row items-center">
                          <p class="text-base	font-bold	text-blue-500	ml-3">
                            Firmware:
                          </p>
                          <p class="text-base	text-blue-500	ml-3">
                            ${this.termi?.generalInformation?.firmwareVersion
                              ?.value}
                          </p>
                        </div>
                        <div class="flex flex-row pt-5 pl-3">
                          <mwc-icon class="text-blue-900"
                            >battery_charging_full</mwc-icon
                          >
                          <p class="text-lg	text-blue-900 mr-2">
                            ${this.termi?.generalInformation?.batteryPercentage
                              ?.value}%
                          </p>
                          <mwc-icon class="text-blue-900">memory</mwc-icon>
                          <p class="text-lg	text-blue-900 mr-2">
                            ${this.termi?.generalInformation?.memoryAvailable
                              ?.value}MB
                          </p>
                          <mwc-icon class="text-blue-900">storage</mwc-icon>
                          <p class="text-lg	text-blue-900">
                            ${this.termi?.generalInformation?.storageAvailable
                              ?.value}MB
                          </p>
                        </div>
                        <div class="flex flex-row pt-3"> 
                          <div
                            class="btnBloquear items-center content-center cursor-pointer flex  flex-row"
                            @click=${() => {
                              //this.historialComandosModal.open(this.termi!);
                            }} 
                          >

                            <mwc-icon-button icon="block">
                            </mwc-icon-button>
                            <p class="text-center ">Bloquear</p>
                          </div>
                        </div>
                        <div class="flex flex-row pt-3"> 
                          <div
                            class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                            @click=${() => {
                              this.historialComandosModal.open(this.termi!);
                            }} 
                          >

                              <mwc-icon-button icon="receipt">
                              </mwc-icon-button>
                              <p class="text-center ">Historial Comandos</p>
                          </div>
                        </div>
                        <div class="flex flex-row pt-3"> 
                          <div
                            class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                            @click=${() => {
                              this.ListarFirmwareModal.open(this.termi!);
                            }} 
                          >

                              <mwc-icon-button icon="memory">
                              </mwc-icon-button>
                              <p class="text-center ">Instalar Firmware</p>
                          </div>
                        </div>
                        <div class="flex flex-row pt-3 ">
                          <!-- <a href="https://www.w3schools.com/" target="_blank"> -->
                          <div
                            class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                            @click=${() => {
                              console.log(this.termi);
                              const obj = this.termi?.generalInformation;
                              if (
                                JSON.stringify(obj) == '{}' ||
                                obj === undefined
                              ) {
                                errorNotification(
                                  'Terminal sin data geolocalizacion!'
                                );
                              } else {
                                this.UbicarTerminalMapa.open(this.termi!);
                              }
                            }}
                          >
                            <mwc-icon-button icon="place"> </mwc-icon-button>

                            <p class="text-center ">Ver en mapa</p>
                          </div>
                          <!-- </a> -->
                        </div>
                        <div class="flex flex-row pt-3 ">
                          <!-- <a href="https://www.w3schools.com/" target="_blank"> -->
                          <div
                            class="btnMessage w-44 items-center content-center cursor-pointer flex  flex-row"
                            @click=${() => {
                              //console.log(this.termi);
                              this.listadoApps.open(this.brand, this.serial);
                            }}
                          >
                            <mwc-icon-button icon="build"> </mwc-icon-button>

                            <p class="text-center ">Config avanzada</p>
                          </div>
                          <!-- </a> -->
                        </div>
                        <div class="flex flex-row pt-3 ">
                          <!-- <a href="https://www.w3schools.com/" target="_blank"> -->
                          <div
                            class="btnMessage w-44 items-center content-center cursor-pointer flex  flex-row"
                            @click=${() => {
                              this.updateHoraFecha.open(this.termi,true,"");
                            }}
                          >
                            <mwc-icon-button icon="update"> </mwc-icon-button>

                            <p class="text-center ">Actualizar Hora y Fecha</p>
                          </div>
                          <!-- </a> -->
                        </div>
                        <div class=" pt-3 flex items-center  justify-between">
                          <p
                            class="text-base	font-bold	text-blue-500	ml-3 text-base"
                          >
                            Organizaciones
                          </p>
                          <!-- <mwc-icon-button
                            style="color: #2E3EAE"
                            icon="settingsremoteoutlined"
                          >                         
                          </mwc-icon-button> -->
                        </div>
                        <div class="pt-1 ml-5">
                          <ul class="list-disc pl-2">
                            <li>
                              <p class="text-base	text-blue-800	"
                              @click=${() => { 
                                localStorage.setItem('organizaciones',"1");
                                router.cambiarSeccion('hierarchy');
                              }}
                            >
                                ${this.organizacion}
                              </p>
                            </li>
                          </ul>
                        </div>
                        <!-- <div
                          class="pt-3 flex flex-row items-center justify-between"
                        >
                          <p class="text-base	font-bold 	text-blue-500	ml-3">
                            Apps instaladas
                          </p>
                          <mwc-icon-button
                            style="color: #2E3EAE"
                            icon="settingsremoteoutlined"
                          >
                          </mwc-icon-button>
                        </div>
                        <div class="pt-1 ml-5">
                          <div class="flex my-2 items-center">
                            <div
                              class="bg-gray-200 rounded-full w-8 h-8 mx-2"
                            ></div>
                            <p class="text-base	text-blue-800	">Nombre de apps</p>
                          </div>
                          <div class="flex my-2 items-center">
                            <div
                              class="bg-gray-200 rounded-full w-8 h-8 mx-2"
                            ></div>
                            <p class="text-base	text-blue-800	">Nombre de apps</p>
                          </div>
                          <div class="flex my-2 items-center">
                            <div
                              class="bg-gray-200 rounded-full w-8 h-8 mx-2"
                            ></div>
                            <p class="text-base	text-blue-800	">Nombre de apps</p>
                          </div>
                        </div>
                      </div> -->
                      <div class="w-full py-3 pl-5 border-b-2 border-grey-100">
                        <!-- <div
                          class="group_icon items-center p-2  flex content-center  flex-row cursor-pointer"
                        >
                          <div class="text-blue-500">
                            <mwc-icon-button icon="print"> </mwc-icon-button>
                          </div>
                          <div class="flex justify-between w-full">
                            <h1 class="text-1xl	text-blue-900 ml-3 	">
                              Impresora
                            </h1>

                            <mwc-switch slot="meta"></mwc-switch>
                          </div>
                        </div> -->
                        <div
                          class="group_icon items-center p-2  flex content-center  flex-row "
                        >
                          <div class="text-blue-500">
                            <mwc-icon-button icon="wifi"> </mwc-icon-button>
                          </div>
                          <div class="flex justify-between w-full">
                            <h1 class="text-1xl	text-blue-900 ml-3 	">Wifi</h1>

                            <mwc-switch
                              slot="meta"
                              .selected=${this.termi?.generalInformation
                              ?.wifiEnable?.value === '1'}
                              @click=${() => {
                              this.toggleWifi();
                            }}
                            ></mwc-switch>
                          </div>
                        </div>

                        <div
                          class=" group_icon items-center p-2   flex  flex-row cursor-default"
                        >
                          <div class="text-blue-500 cursor-default">
                            <mwc-icon-button icon="bluetooth">
                            </mwc-icon-button>
                          </div>
                          <div class="flex justify-between w-full">
                            <h1 class="text-1xl	text-blue-900 ml-3 	">
                              Bluetooth
                            </h1>

                            <mwc-switch
                              slot="meta"
                              .selected=${this.termi?.generalInformation
                              ?.bluetoothEnable?.value === '1'}
                              @click=${() => {
                              //console.log('click');
                              this.toggleBluetooth();
                            }}
                            ></mwc-switch>
                          </div>
                        </div>
                        <!-- <div
                          class="group_icon  items-center p-2 flex flex-row cursor-pointer"
                        >
                          <div class="text-blue-500">
                            <mwc-icon-button icon="place"> </mwc-icon-button>
                          </div>
                          <div class="flex justify-between w-full">
                            <h1 class="text-1xl	text-blue-900 ml-3 ">
                              Ubicación
                            </h1>
                            <mwc-switch slot="meta"></mwc-switch>
                          </div>
                        </div> -->
                      </div>
                      <div
                        class="w-full mt-5 flex-col flex justify-center items-center"
                      >
                        <!-- <mwc-button
                          raised
                          style="--mdc-theme-primary: #DFE0FF; --mdc-theme-on-primary: #000C61"
                          class="mb-3 rounded w-3/4"
                          icon="settings_remote"
                          >Enlace remoto</mwc-button
                        > -->
                        <mwc-button
                          raised
                          style="--mdc-theme-primary: #E3E1EC; --mdc-theme-on-primary: #46464F"
                          class="mb-2 rounded w-3/4"
                          icon="edit"
                          @click="${() => {
                            store.dispatch(editarTerminal(this.termi!));
                            router.cambiarSeccion('edit-terminal');
                          }}"
                          >Editar</mwc-button
                        >
                        <mwc-button
                          raised
                          style="--mdc-theme-primary: tomato; --mdc-theme-on-primary: white"
                          class="mb-2 rounded w-3/4"
                          icon="delete"
                          @click="${() => {
                            this.handleDeleteTerminal(this.termi!);
                          }}"
                          >Eliminar</mwc-button
                        >
                      </div>
                    `
                  : html``}
              </section>
            `
          : html`
              <mwc-circular-progress
                indeterminate
                density=${20}
              ></mwc-circular-progress>
            `}
      </main>
      <listado-app id="listadoApp"></listado-app>
      <actualizar-hora-fecha id="updateHoraFecha"></actualizar-hora-fecha>
      <!-- <cambiar-master id="cambiarMaster"></cambiar-master> -->
      <ubicacion-terminal-mapa
        id="ubicacionTerminalMapa"
      ></ubicacion-terminal-mapa>
      <lista-app-modal id="listaAppModal"></lista-app-modal>
      <lista-firmware-modal id="listaFirmwareModal"></lista-firmware-modal>
      <push-config-modal id="pushConfigModal"></push-config-modal>
      <enviar-mensaje-modal id="enviarMensajeModal"></enviar-mensaje-modal>
      <historial-comandos-modal
        id="historialComandosModal"
      ></historial-comandos-modal>
      <ultimos-comandos-modal
        id="ultimosComandosModal"
      ></ultimos-comandos-modal>
    `;
  }
}
