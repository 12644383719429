/* eslint-disable prettier/prettier */

import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '@ui5/webcomponents/dist/Dialog.js';
import '@vaadin/vaadin-select';
import { getCookie } from '../../../src/services/cookie';
import { errorNotification } from '../../services/notifications';
import { apisConfigurator } from '../../utils/axios';
import '../CustomSelect/CustomSelect';
import {
  App, 
  BrandList,
  ModelApi,
  VersionApi,
  VersionApiAddInstallerRequest,
  Version,
  DownloadApi
} from '../../../api';
import store from '../../redux/store';
import { State } from '../../redux/types';
import { flatMarcas, modelosPorMaca } from '../../utils/functions';
import { CustomMultiSelect } from '../CustomMultiSelect/CustomMultiSelect';
import { CustomSelect } from '../CustomSelect/CustomSelect';

@customElement('descargar-instalador-modal')
export class DescargarInstaladorModal extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private modelApi: ModelApi;
  private versionApi: VersionApi;
  private downloadApi: DownloadApi;

  constructor() {
    super();
    this.modelApi = new ModelApi(...apisConfigurator('Agregar instalador'));
    this.downloadApi = new DownloadApi(...apisConfigurator('DEscargar instalador'));
    this.versionApi = new VersionApi(...apisConfigurator('Agregar instalador'));
  }

  @property({ type: Object })
  app?: App;

  @state()
  private brandSelected?: string;

  @state()
  private loading = false;

  @state()
  private version:any = "";

  @state()
  private appId:any = "";

  @state()
  private url:any = [""];

  @state()
  private file?: File;

  @state()
  private modelosMarcas: BrandList[] = [];

  private get dialog() {
    return document.getElementById('dialog-descargar-instalador')! as any;
  }

  private get botonInstalador() {
    return document.querySelector(
      '#seleccionar-instalador'
    )! as HTMLInputElement;
  }


  private get sistemaOperativo() {
    return document.getElementById('sistemaOperativo') as CustomSelect;
  }

  private get brand() {
    return document.getElementById('brand') as CustomSelect;
  }

  private get models() {
    return document.getElementById('models') as CustomMultiSelect<string>;
  }

  protected firstUpdated(): void {}

  private async getLink() {
    console.log(this.appId)
    const resVersion = await this.versionApi.getVersion({
      id:this.appId,
      version:this.version.version
    });
    //console.log(resVersion)
    this.url = resVersion.data.installers;
    console.log(this.url)
  }

  async open(version?: any, appId?:any) {
    if (!version) {
      errorNotification('Error al agregar instalador');
      return;
    } 
    console.log(version)
    this.appId = appId;
    this.version = version;
    this.getLink();
    this.dialog.open = true;
  }

  close() {
    this.dialog.open = false;
    this.app = undefined;
  }

  private async handleSubmit(e: Event) {
    e.preventDefault();
 
    const { verAppId } = store.getState() as State;

    this.file = this.botonInstalador!.files![0];

    if (!this.file || !verAppId ) {
      console.log(this.file, verAppId)
      //errorNotification('Error al agregar instalador');
      return;
    }

    const body: VersionApiAddInstallerRequest = {
      version: this.version.selected,
      brand: this.brand.selected,
      id: verAppId,
      os: this.sistemaOperativo.selected,
      installer: this.file,
      firmwares: ['*'],
      models: this.models.selected,
    };
    console.log(this.version);
    console.log(body);

    try {
      this.loading = true;
      const res = await this.versionApi.addInstaller(body);

      this.loading = false;
      console.log(res);

      this.close();
    } catch (error: any) {
      this.close();
      this.loading = false;
      console.log(error);
      console.log(error.message);
    }
  }
  async donwloadInstaller(camp:any){
    /*let id:string = camp.id;
      const res:any = await this.downloadApi.downloadInstaller({id})
    console.log(res.blob())*/
    //console.log(`https://tms-server-demo.apps2go.tech/download/installer/${camp.url}`)
    const userCookie:any = getCookie('tms_user');
    const parseToken = JSON.parse(userCookie);
    console.log(parseToken)
    fetch(`https://tms-server-demo.apps2go.tech/download/installer/${camp.url}`, {
      mode: "cors",
      headers: {
        'accept': 'application/*',
        'Authorization': `Bearer ${parseToken.token}`
      } 
    }).then(function (respuesta) {
      return respuesta.blob(); 
    })
    .then(function (blob) {
      console.log(blob)
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // the filename you want
      a.download = `${camp.brand}.apk`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }

  protected render() {
    return html` <ui5-dialog
      id="dialog-descargar-instalador"
      header-text="Descargar instalador a ${this.version?.briefDescription}"
      class="w-4/12 h-80"
    > 
      <form
        class="px-4 flex flex-col items-center h-full"
        @submit=${this.handleSubmit}
      >
        <div class="mx-2 w-full flex flex-col items-center relative">
          <div class="flex">
          </div>
          ${this.url.map( 
            (camp:any, index:number) => html`
              <input
                id="seleccionar-instalador"
                type="file"
                hidden
                @change=${(ev: InputEvent) => {}}
              />
              <mwc-button
                class="rounded"
                label="${camp.brand}"
                icon="download"
                outlined
                .disabled=${this.loading}
                @click=${async () => {
                  //this.botonInstalador.click();
                  this.donwloadInstaller(camp)
                }}
              >
              </mwc-button>
            `
          )}
        </div>

        <div slot="footer" class="dialog-footer mt-auto">
          <button
            class="text-red-600 p-4 rounded-md uppercase"
            id="closeDialogButton"
            @click=${() => {
              this.close();
            }}
          >
            Cerrar
          </button>
          <button
            class="text-blue-600 p-4 rounded-md uppercase"
            id="closeDialogButton"
            type="submit"
            .disabled=${this.loading}
          >
            Guardar
          </button>
        </div>
      </form>
    </ui5-dialog>`;
  }
}
