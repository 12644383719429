/* eslint-disable prettier/prettier */

import { LitElement, html, render } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { guard } from 'lit/directives/guard.js';
import '@vaadin/vaadin-grid';
import '@ui5/webcomponents/dist/Dialog.js';
import { connect } from 'pwa-helpers';
import '@vaadin/vaadin-select';
import {  
  ConfigurationApi,
  ConfigurationApiCreateConfigurationRequest,
  ConfigurationApiUpdateConfigurationRequest,
  AppApi,
  AppList,
  TemplateApi,
  FolioApi,
  FolioApiListFolioRequest,
  FolioApiUpdateFolioConfigurationRequest,
  RequestFolioConfigurationUpdate,
  FolioApiCleanFolioRequest,
  RequestFolioPatch,
} from '../../../api';
import {
  Grid,
  GridActiveItemChangedEvent,
  GridItemModel,
} from '@vaadin/vaadin-grid';
import { ConfigConsole, PageFolio } from '../../redux/actions';
import store from '../../redux/store';
import { State } from '../../redux/types';
import { AxiosResponse } from 'axios';
import { iterarCampoJson2, validacionRequired } from '../../utils/functions';
import {
  errorNotification,
  succesNotification,
} from '../../services/notifications';
import { apisConfigurator } from '../../utils/axios';

@customElement('listado-folio-afiliaciones')
export class ListadoFolioAfiliaciones extends connect(store)(LitElement) {
  private appApi: AppApi;
  private configurationApi: ConfigurationApi;
  private templateApi: TemplateApi;
  private folio: FolioApi;

  constructor() {
    super();
    this.appApi = new AppApi(...apisConfigurator('Aplicaciones'));
    this.configurationApi = new ConfigurationApi(...apisConfigurator(''));
    this.templateApi = new TemplateApi(...apisConfigurator(''));
    this.folio = new FolioApi(
        ...apisConfigurator('Folio')
      );
  }

  @state()
  private apps: AppList[] = [];

  @state()
  private folioList: any = [];

  @state()
  plantillas: Object[] = [];

  @state()
  private detailsOpenedItem: AppList[] = [];

  @state()
  loader = true;

  @state()
  private page = 2;

  @state()
  private AppId: any;

  @state()
  private app: any;

  @state()
  private DataJson: any;

  @state()
  private ObjectJson: any;

  @state()
  private loading = false;

  @state()
  private habilitarButton = true;

  @state()
  brand = '';

  @state()
  serial = '';

  @state()
  plantillaId = '';

  @state()
  existConfig: boolean = false;

  @state()
  JsonSchema: any;

  @state()
  nameTemplate: any;

  @state()
  band: any;

  @state()
  editor?: any;

  async stateChanged(state: any) {
    console.log(this.page)
    if(state.configConsole.hasOwnProperty('editor')){
      this.editor = state.configConsole.editor;
    }

    if(state.hasOwnProperty('objectJson')){
      this.ObjectJson = state.objectJson;
    }  

    if(state.configConsole.hasOwnProperty('existConfig')){
      this.existConfig = state.configConsole.existConfig;
    }
    console.log("STATE",state)
    if(state.configConsole.page == 1 ){ 
      this.habilitarButton = false;
      console.log("state folio",state.page_folio)
      this.page=1;
      console.log("this.page",this.page)
    }else if(state.configConsole.page == 2){
      this.page=2;
    }else if(state.configConsole.hasOwnProperty('page')) {
      this.page=3;
      this.nameTemplate = state.configConsole.templateName;
    }

    console.log("pagina",this.page)
  }

  protected firstUpdated(): void {

  }

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private get dialog() {
    return document.getElementById('dialog-listado-app2')! as any;
  }

  /*async step2(app: any, folio:any){
    await this.getTemplatesFolio(folio,app);
    this.loading = true;
    await this.ObtenerFolios(app);
    this.page = 2;
    console.log("ANTES")
    //this.dialog.open = true;
  }*/

  async open(app: any) {
    const { configConsole } = store.getState() as State;
    if (!app) {
      //errorNotification('wError al listar las app para configuracion');
      return;
    } 

    this.app = app;

    //this.page = 1;
    console.log("control 1",this.page)
    this.getTemplates();
    console.log("control 2",this.page)
    this.loading = true;
    this.ObtenerFolios(app);
    console.log("control 3",this.page)
    console.log(configConsole)
    this.dialog.open = true;
  }

  close() {
    this.dialog.open = false;
    //this.terminal = undefined;
  }

  async getApp(appId: any) {
    const { configConsole } = store.getState() as State;
    try {
      const res = await this.appApi.getApp({ id: appId });
      const app = res.data;
      const objecto1 = configConsole;
      const objecto2 = { appId, app };
      configConsole.page=2;
      Object.assign(objecto1, objecto2);
      store.dispatch(
        ConfigConsole({
          appId: objecto1,
          ...configConsole,
        })
      );
    } catch (err) {
      //errorNotification('Error al obtener la app');
      console.log(err);
    } finally {    
      this.band = -1;
    }
  }



  async getApps() {
    const { configConsole } = store.getState() as State;
    console.log(configConsole);
    try {
      const res = await this.appApi.listApp();

      this.apps = res.data;
      const apps = res.data;
      this.detailsOpenedItem = this.apps;
      store.dispatch(
        ConfigConsole({
          apps,
          ...configConsole,
        })
      );
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false; 
    }
  }

  async ObtenerFolios(app: any) {
    let data:FolioApiListFolioRequest = {
      appId: app.id ?? app,
    }
    await this.folio
      .listFolio(data)
      .then(response => response.data)
      .then(data => {
        this.folioList = data;
        console.log("folioList",data)
      });
  }

  async VaciarFolio(folio: any) {
    let requestFolioPatch:RequestFolioPatch = {
      clean:true
    }
    let data:FolioApiCleanFolioRequest = {
      appId: folio.appId,
      folioId: folio.folioId,
      requestFolioPatch
    }
    await this.folio
      .cleanFolio(data)
      .then(response => response.data)
      .then(data => {
        console.log(data)
        succesNotification('Folio Vaciado!')
        /*if(data.data.status == 200){
          succesNotification('Folio Vaciado!');
        }*/
      });
  }

  async submitUpdateConfiguration() {
    const { configConsole } = store.getState() as State;
    console.log("configConsole ",configConsole )
    const editor = this.editor;


    let datos = editor.getValue() as Object;
    console.log("DATOS GETVALUE ANTES DE GUARDAR",datos)
    const requerido = validacionRequired(datos);

    if (requerido) {
      errorNotification(requerido);
      return;
    }
    let requestConfigurationUpdate = {}
    let type = configConsole.JsonSchema.type;
    if(type == "array"){
      requestConfigurationUpdate= {
        parametersArray:datos
      }
    }else{
      requestConfigurationUpdate= {
        parameters:datos
      }
    }

    const data: ConfigurationApiUpdateConfigurationRequest = {
      brand: configConsole.brand,
      serial: configConsole.serial,
      appId: configConsole.appId,
      templateId: configConsole.templateId,
      requestConfigurationUpdate

    };
    
    console.log(data)

    /*try { 
      const res = await this.configurationApi.updateConfiguration(data);

      this.close();
      this.page = 1;
      succesNotification('Datos guardados con exito!');
      this.ObjectJson = {};
    } catch (error:any) {
      console.log(error);
      errorNotification(error.toString());
    } finally {
      this.loading = false;
    }*/
  }

  async submitConfiguration() {
    const { configConsole } = store.getState() as State;

    console.log("configConsole ",configConsole )
    const editor = this.editor;
 
    let datos:any = editor.getValue() as Object;
    console.log("DATOS GETVALUE ANTES DE GUARDAR",datos)
    const requerido = validacionRequired(datos);

    if (requerido) {
      errorNotification(requerido);
      return; 
    }
    let requestFolioConfigurationUpdate: RequestFolioConfigurationUpdate;
    let type = configConsole.JsonSchema.type;
    
    if(type == "array"){

      requestFolioConfigurationUpdate = {
        parametersArray: datos
      }
      
    }else{
      requestFolioConfigurationUpdate = {
        parameters:datos, 
      }
    }
    const parameters = datos;

    const data: ConfigurationApiCreateConfigurationRequest = {
      requestConfigurationCreate: {
        brand: configConsole.brand,
        serial: configConsole.serial,
        appId: configConsole.appId,
        templateId: configConsole.templateId,
        parameters,
      },
    };
    let requestParameters:FolioApiUpdateFolioConfigurationRequest = {
      appId:configConsole.appId,
      folioId:configConsole.folioId,
      templateId:configConsole.templateId,
      requestFolioConfigurationUpdate
    }

    console.log(requestParameters);
    try {
      const res = await this.folio.updateFolioConfiguration(requestParameters)
      this.close();
      const { configConsole } = store.getState() as State;
      configConsole.page=1;
      store.dispatch(
        ConfigConsole({
          ...configConsole,
        })
      );
      succesNotification('Datos guardados con exito!');
      console.log(res);
      this.ObjectJson = {};
    } catch (error:any) {
      console.log(error);
      errorNotification(error.toString());
    } finally {
      this.loading = false;
    }
  }

  async getTemplatesFolio(folio:any,app:any) {
    const { configConsole } = store.getState() as State;
    this.loader = true;
    var resTer: AxiosResponse;
    try {
      resTer = await this.templateApi.listTemplate({});
      //console.log(resTer)
      this.plantillas = resTer.data;
      const templates = resTer.data;
      store.dispatch(
        ConfigConsole({
          app,
          folio,
          templates,
          ...configConsole,
        })
      );
    } catch (error) {
    } finally {
      if (!this.plantillas)
        this.plantillas = [
          {
            name: 'plantilla-1',
            id: '12',
            description: 'prueba1',
          },
        ];
      this.loader = false;
    }
  }

  async getTemplates() {
    const { configConsole } = store.getState() as State;
    this.loader = true;
    var resTer: AxiosResponse;
    try {
      resTer = await this.templateApi.listTemplate({});
      //console.log(resTer)
      this.plantillas = resTer.data;
      const templates = resTer.data;
      store.dispatch(
        ConfigConsole({
          templates,
          ...configConsole,
        })
      );
    } catch (error) {
    } finally {
      if (!this.plantillas)
        this.plantillas = [
          {
            name: 'plantilla-1',
            id: '12',
            description: 'prueba1',
          },
        ];
      this.loader = false;
    }
  }

  prueba(index: number) {
    this.band = index;
  }

  protected render() {
    return html` 
      <ui5-dialog
        id="dialog-listado-app2" 
        class="w-8/12"
        style="height:600px;border-radius: 15px;"
      >
        <main class="w-full h-full flex FondoColor ">
          <section class="w-full flex flex-col pl-6  max-h-full">
            <section class="w-full flex my-2">
              <div class="container ml-4 w-full flex flex-row justify-between">
                <div class="nameApp">
                  <h2 class="text-3xl">${this.page == 3 ? html`${this.nameTemplate}` : html`Configuracion de Folio`}</h2>
                </div>
                ${this.page !== 3
                  ? html` 

                    `
                  : ``}
              </div>
            </section>
            ${this.page == 1
              ? html`
                  <!-- <div
                    class="w-full mb-2 flex h-24 items-center bg-white rounded-l-lg content-center SectionDetail"
                  > -->
                    <!-- <div
                      class="bg-blue-600 h-11	ml-10 text-white py-2 rounded-3xl px-3 rounded-3xl mr-2"
                    >
                      Todas
                    </div>
                    <div
                      class="border items-center 	 content-center	 h-11	 border-slate-500 text-slate-500 py-2 px-3 rounded-3xl mr-2"
                    >
                      Finanzas
                    </div>
                    <div
                      class="border items-center		 content-center	 h-11	 border-slate-500 text-slate-500 py-2 px-3 rounded-3xl mr-2"
                    >
                      Inventario
                    </div>
                    <div
                      class="border items-center 	 content-center	 h-11	 border-slate-500 text-slate-500 py-2 px-3 rounded-3xl mr-2"
                    >
                      Finanzas
                    </div>
                    <div
                      class="border items-center		 content-center	 h-11	 border-slate-500 text-slate-500 py-2 px-3 rounded-3xl mr-2"
                    >
                      Inventario
                    </div>
                    <div
                      class="border items-center 	 content-center	 h-11	 border-slate-500 text-slate-500 py-2 px-3 rounded-3xl mr-2"
                    >
                      Finanzas
                    </div>
                    <div
                      class="border items-center		 content-center	 h-11	 border-slate-500 text-slate-500 py-2 px-3 rounded-3xl mr-2"
                    >
                      Inventario
                    </div> -->
                  <!-- </div> -->
                  <div class="flex flex-wrap overflow-y-auto">
                    <!-- ${this.apps.map(
                      (app, index) =>
                        html`  
                          <app-card2
                            @click=${() => this.prueba(index)}
                            .band=${this.band}
                            .index=${index}
                            .prueba=${this.page}
                            .app=${app}
                          ></app-card2>
                        `
                    )} -->
                    <vaadin-grid class="mx-2" .items="${this.folioList}"
                              .detailsOpenedItems="${this.detailsOpenedItem}"
                              @active-item-changed="${(
                    e: GridActiveItemChangedEvent<FolioApi>
                  ) => {
   
                    
                  }}">
              <vaadin-grid-sort-column
              .renderer="${(root: HTMLElement, _: any, item: any) => {
                render( 
                  html`
                    <input 
                      class="" 
                      type="radio"
                      name="platform"
                      @change="${async(ev: InputEvent) => {
                         //this.habilitarButton = false;
                         //this.getApp(item.item.appId)
                      }}"
                    />
                  `,
                  root
                );
              }}" 
            ></vaadin-grid-sort-column>
            <vaadin-grid-filter-column path="folioId"></vaadin-grid-filter-column>
                        <!-- <vaadin-grid-sort-column path="folioId"></vaadin-grid-sort-column> -->
                        <!-- <vaadin-grid-sort-column path="description"></vaadin-grid-sort-column> -->
                        <vaadin-grid-filter-column path="description"></vaadin-grid-filter-column>
                        <vaadin-grid-filter-column path="serial"></vaadin-grid-filter-column>
                        <!-- <vaadin-grid-sort-column path="serial"></vaadin-grid-sort-column> -->
                        <vaadin-grid-filter-column path="brand"></vaadin-grid-filter-column>
                        <!-- <vaadin-grid-sort-column path="brand"></vaadin-grid-sort-column> -->
                        <vaadin-grid-filter-column
                    header="Estatus"
                    .renderer="${guard(
                      [],
                      () => (root: HTMLElement, _: any, model: any) => {
                        const bg =
                          model.item.hasOwnProperty("serial")
                          ? `text-blue-500`
                          : `text-green-500`;
                        render(
                          html`<p
                            class="py-1 capitalize px-4 w-fit ${bg} rounded font-bold"
                          >
                            ${
                                model.item.hasOwnProperty("serial")
                                ? "En uso"
                                : "Disponible"
                            }
                          </p>`,
                          root
                        );
                      }
                    )}"
                        ></vaadin-grid-filter-column>
                        <vaadin-grid-sort-column
            header="Acciones"
            .renderer="${(root: HTMLElement, _: any, item: any) => {
              render(html`
                <div 
                  class="flex items-center  ml-2"
                > 
                <mwc-icon-button
                    class="ml-1"
                    icon="edit" 
                    ariaLabel="Actualizar"
                    @click=${async() => {
                      const { configConsole } = store.getState() as State;
                      console.log(item)
                      this.habilitarButton = false;
                      this.getApp(item.item.appId)
                    }}
                  ></mwc-icon-button>
                  <mwc-icon-button
                    class="ml-1"
                    icon="cleaning_services"
                    ariaLabel="Eliminar"
                    @click=${() => {
                      //this.comprobarConfigurations(item.item.id)
                      this.VaciarFolio(item.item)
                    }}
                  ></mwc-icon-button>
                </div>
              
              `, root); 
            }}"
          ></vaadin-grid-filter-column>
                    </vaadin-grid> 
                    <mwc-icon-button
                  icon="autorenew"
                  class="absolute top-0 right-0"
                  @click=${() => {
                    //this.getApps();
                    this.ObtenerFolios(this.app);
                  }}
                ></mwc-icon-button>
                  </div>
                `
              : this.page == 2
              ? html`
                  <listado-plantilla-folio-afiliaciones></listado-plantilla-folio-afiliaciones>
                ` 
              : html`
                  <config-terminal-folio-afiliaciones .DataJson=${this.DataJson}></config-terminal-folio-afiliaciones>
                `}

            <div class="flex flex-row-reverse">
              ${this.page == 1 || this.page == 2
                ? html`
                    ${this.page == 1 
                        ? html`
                        <!-- <button
                          .disabled=${this.habilitarButton}
                          @click=${() => {
                            this.page++;
                            this.band = -1;
                          }} 
                          class="rounded-3xl mx-3 my-3 w-28 InstalarApp	"
                        >
                          Continuar
                        </button> -->
                        `: html``
                    }

                  `
                : html`
                    <button
                      .disabled=${false}
                      @click=${() => {
                        this.submitConfiguration();
                        /*if (this.existConfig) {
                          this.submitUpdateConfiguration();
                        } else {
                          this.submitConfiguration();
                        }*/
                      }}
                      class="rounded-3xl mx-3 my-3 w-28 InstalarApp	"
                    >
                      Guardar
                    </button>
                  `}
              ${this.page == 1
                ? html`
                    <button
                      @click=${() => {
                        this.habilitarButton = true;
                        this.close();
                        this.band = -1;
                      }}
                      class="rounded-3xl mx-3 my-3 mr-2 border w-28  border-indigo-500 text-blue-500  CancelarG  hover:shadow-md hover:bg-slate-300 text-xs	"
                    >
                      Cancelar
                    </button>
                  `
                : html`
                    <button
                      @click=${() => {
                        this.page == 2 ? (this.habilitarButton = true) : null;
                        this.page == 2 ? this.close() : null;
                        const { configConsole } = store.getState() as State;
                        configConsole.page=this.page-1;
                        store.dispatch(
                          ConfigConsole({
                            ...configConsole,
                          })
                        );
                      }}
                      class="rounded-3xl mx-3 my-3 mr-2 border w-28  border-indigo-500 text-blue-500  CancelarG  hover:shadow-md hover:bg-slate-300 text-xs	"
                    >
                      Atras
                    </button>
                  `}
            </div>
          </section>
        </main>

      </ui5-dialog>
    `;
  }
}
