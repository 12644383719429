/* eslint-disable prettier/prettier */

import { LitElement, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '@ui5/webcomponents/dist/Dialog.js';
import '@vaadin/vaadin-select';
import '../CustomSelect/CustomSelect';
import store from '../../redux/store';
import { Enumm } from '../../redux/actions';

@customElement('enum-modal')
export class EnumModal extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  @property({ type: String })
  dialogTitle = 'Ingrese los valores predeterminados';

  @property({ type: String })
  dialogBody = '';

  @property({ type: String })
  acceptButtonMessage = 'Aceptar'; //${this.extraer(this.usuario,"enum",this.index)}

  @state()
  private index: any;

  @state()
  private cadena: string = '';

  @state()
  private usuario: any;

  @property({ type: String })
  cancelButtonMessage = 'Cancelar';

  private get dialog() {
    return document.getElementById('dialog-confirm')! as any;
  }

  private get containerInput() {
    return document.getElementById('container-input')! as any;
  }

  private get prueba() {
    return document.getElementById('prueba') as any;
  }

  protected firstUpdated(): void {}

  async open(index: number, usuario: any) {
    this.dialog.open = true;
    this.index = index;
    this.usuario = usuario;

    this.cadena = this.extraer(this.usuario, 'enum', this.index);

    /*var newInput = document.createElement("input");
    newInput.setAttribute("type", "text");
    newInput.setAttribute("id", "prueba");
    newInput.setAttribute("value", `${this.cadena}`);
    newInput.setAttribute("class", "style-select overflow-auto h-full"); 
    newInput.setAttribute("data-role", "taginput");
    const div = this.containerInput;
    div.appendChild(newInput);*/
    const prueba = this.prueba;
    prueba.setAttribute('value', this.cadena);
  }

  close() {
    /*const div = this.containerInput;
    const prueba = this.prueba;
    prueba.remove();
    prueba.value=""
    console.log(div.childNodes[0])
    console.log(prueba)*/

    /*store.dispatch(Enumm({
      cancelar:true
    }));*/
    const prueba = this.prueba;
    prueba.setAttribute('value', ' ');

    this.dialog.open = false;
  }

  private onAccept() {
    let prueba = this.prueba.value;
    let arr = prueba.split(',');
    store.dispatch(
      Enumm({
        id: this.index,
        data: arr,
      })
    );
    /*console.log({
      id:this.index, 
      data:arr 
    })*/

    prueba = this.prueba;
    this.cadena = '';
    prueba.setAttribute('value', '');

    this.close();
  }

  extraer(obj: any, index: string, requiredIndex: any) {
    if (!obj) return '';
    if (obj.enum) {
      let li = obj.enum;
      let cadena = '';
      li.map((camp: any, index: number) => {
        cadena = cadena + `${camp},`;
      });
      cadena = cadena.substring(0, cadena.length - 1);
      console.log(cadena);
      return cadena;
    }
    console.log('');
    return '';
  }

  protected render() {
    return html` <ui5-dialog
      id="dialog-confirm"
      header-text=${this.dialogTitle}
    >
      <div
        class="flex justify-center"
        id="container-input"
        style="height: 150px;"
      >
        <input
          type="text"
          id="prueba"
          class="style-select overflow-auto h-full"
          data-role="taginput"
        />
      </div>
      <div class="dialog-footer mt-auto w-full text-center">
        <button
          class="text-red-600 p-4 rounded-md uppercase"
          id="closeDialogButton"
          @click=${() => {
            const prueba = this.prueba;
            this.cadena = '';
            prueba.setAttribute('value', '');
            this.close();
          }}
        >
          ${this.cancelButtonMessage}
        </button>
        <button
          class="text-blue-600 p-4 rounded-md uppercase"
          id="closeDialogButton"
          @click=${() => {
            this.onAccept();
          }}
        >
          ${this.acceptButtonMessage}
        </button>
      </div>
    </ui5-dialog>`;
  }

  static styles = css`
    .dialogBody {
      text-align: center;
    }

    #ui5-popup-header-text {
      font-weight: bold;
      margin-top: 2px;
    }
  `;
}
