/* eslint-disable prettier/prettier */
import { LitElement, html, css } from 'lit';
import '@ui5/webcomponents/dist/Dialog.js';
import { customElement } from 'lit/decorators.js';
import '../../styles.css';
@customElement('loader-general')
export class Loader extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }
  static styles = [
    css`
      :host {
        display: block;
        height: 40px;
        width: 100%;
      }
    `,
  ];
  private get dialog() {
    return document.getElementById('dialog-loader')! as any;
  }

  async open() {
    this.dialog.open = true;
  }
  async close() {
    this.dialog.open = false;
  }

  render() {
    return html`<ui5-dialog id="dialog-loader" class="loaderprinC">
      <main class="w-full h-full flex">
        <div class="loader">
          <mwc-circular-progress
            indeterminate
            density=${20}
          ></mwc-circular-progress>
        </div>
      </main>
    </ui5-dialog>`;
  }
}
