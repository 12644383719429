/* eslint-disable prettier/prettier */

import { LitElement, html, render } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '@ui5/webcomponents/dist/Dialog.js';
import '@vaadin/vaadin-select';
import '@vaadin/vaadin-grid';
import { connect } from 'pwa-helpers';
import { ConfigConsole,PageFolio } from '../../redux/actions';
import { 
  CommunicationTerminalApi,
  FolioApi,
  TemplateApi,
  CommunicationTerminalApiRequestCommandRequest,
  RequestCommandTypeCommandEnum,
  Terminal,
  RequestFolioConfiguration,
  RequestFolioCreate,
  FolioApiCreateFolioRequest
} from '../../../api';
import Swal from 'sweetalert2';
import store from '../../redux/store';
import { State } from '../../redux/types';
import { nombrePlantilla, descripcionPlantilla } from '../../redux/actions';
import { errorNotification } from '../../services/notifications';
import { apisConfigurator } from '../../utils/axios';

@customElement('crear-folio')
export class CrearFolio extends connect(store)(LitElement) {

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private communicationApi: CommunicationTerminalApi;
  private templateApi: TemplateApi;

  private folio: FolioApi; 

  constructor() {
    super();

    this.communicationApi = new CommunicationTerminalApi(
      ...apisConfigurator('Comunicación')
    );
    this.templateApi = new TemplateApi(...apisConfigurator(''));

    this.folio = new FolioApi(
      ...apisConfigurator('Folio')
    );
  }

  @property({ type: Object })
  terminal?: Terminal;

  @state()
  private titulo = '';

  @state()
  private configuration:RequestFolioConfiguration[] = [];

  @state() 
  private preConfig: any[] = []
 
  @state()
  private appId:any;

  @state()
  private vacio:any = "";

  @state()
  private afiliacion:any;

  @state()
  private folioItem:any;

  private get elistadoLi() {
    return document.querySelectorAll('li[id="mi-id"]') as any;
  }


  private get inputText() {
    return document.getElementById('descriptionn') as any;
  }



  @state()
  private description = '';

  private get dialog() {
    return document.getElementById('crear-folio-descripcion')! as any;
  }

  protected firstUpdated(): void {}

  updated() {
    let prueba = this.elistadoLi;
    let inputText = this.inputText;
    console.log(inputText)
    console.log(prueba)
    if(prueba.length>3) {
      const nuevaLista = prueba[0]
      nuevaLista.innerHTML = ""
      const nuevaLista1 = prueba[1]
      nuevaLista1.innerHTML = ""
      const nuevaLista2 = prueba[2]
      nuevaLista2.innerHTML = ""
      inputText.value = "";
    }

  }


  async getOrganizaciones() {} 

  async open(app: any,probar:any,afiliacion:any) {
    if (!app) { 
      errorNotification('Error al obtener la app ');
      return;
    }
    this.appId = app;
    this.afiliacion="";
    this.folioItem=afiliacion;
    this.preConfig = probar;
    //await this.getOrganizaciones();
    this.dialog.open = true;

  }

  async close() {
    this.vacio = "";
    this.dialog.open = false;
    this.terminal = undefined;
  }

  async changeData(id: any) {
    const indiceEncontrado = this.preConfig.findIndex(
      (elemento) => elemento.templateId === id
    );
    let item = this.preConfig[indiceEncontrado]
    console.log(item)
    if(item.configuration.hasOwnProperty("parametersArray")){
      this.configuration.push({
        templateId: item.templateId,
        parametersArray:item.configuration.parametersArray
      })
    }else{
      this.configuration.push({
        templateId: item.templateId,
        parameters:item.configuration.parameters
      })
    }

    console.log(this.afiliacion)

  }

  private async handleSubmit(e: Event) {

    e.preventDefault();

    let requestFolioCreate: RequestFolioCreate = {
      appId: this.appId,
      description: this.description, 
      affiliationId:this.folioItem?.affiliationId ?? "",
      configurations:this.configuration
    }

    let data:FolioApiCreateFolioRequest = {
      requestFolioCreate 
    } 
    console.log(data) 
    const res:any = await this.folio.createFolio(data);
    console.log(res.data)
    this.vacio = "";
    if(res.status == 200){
     /*Swal.fire(  
        'Completado!', 
        'El mensaje ha sido enviado.',
        'success' 
      )*/
      const { configConsole } = store.getState() as State;
      store.dispatch(
        ConfigConsole({
          band:true,
          crearFolio:res.data.id,
          folioId:res.data.id,
          ...configConsole,
        })
      );
    }
    //location.reload();
    this.close();
  }

  protected render() {
    return html` <ui5-dialog
      id="crear-folio-descripcion"
      header-text="Crear Folio"
      class="w-3/12"
    >
      <div
        class="px-4 flex flex-col items-center"
      >
        <div class="mx-2 w-full flex flex-col items-center">

          <mwc-textfield
            class="my-2 w-3/4"
            outlined
            id="descriptionn"
            .value=${this.vacio ?? ''}
            @change=${(e: any) => (this.description = e.target.value)}
            label="Descripcion"
          ></mwc-textfield>
        </div>

        <div class="mx-2 w-full flex flex-col items-center">
        <div class=" pt-3 flex items-center mb-5 ">
              <p class="text-base	text-blue-500	ml-3 ">Plantilas Disponibles</p>
        </div>
        <ul class="list-disc pl-2" id="agregar">
              ${this.preConfig.map(
                (folio:any, index:number) => html`
                  <li
                    class="flex items-center gap-2 ml-2" id="mi-id"
                  >
                  <p class="text-slate-500 hover:text-blue-600 text-lg "></p>
                    <p class="text-slate-500 hover:text-blue-600 text-lg ">${folio.templateInfo}</p>
                    <select name="cars" class="w-[210px] mx-1 mb-3" id="appSelect"
                    @change=${(ev: any) => {
                      console.log(this.preConfig)
                      console.log(folio)
                      this.changeData(ev.target.value)
                      console.log(ev.target.value)
                    }}>
                      <option disabled selected></option>
                      <option value="${folio.templateId}">${folio.configuration.name}</option>
                      
                    </select> 
                  </li>
                `
              )}
            </ul>
            <!-- <mwc-textfield
            class="my-2 w-3/4"
            outlined
            @change=${(e: any) => (this.description = e.target.value)}
            label="Razon Social"
            ></mwc-textfield> -->
        </div>

        <div slot="footer" class="dialog-footer">
          <button
            class="text-red-600 p-4 rounded-md uppercase"
            id="closeDialogButton"
            @click=${() => {
              this.close();
            }}
          >
            Cerrar
          </button>
          <button
            class="text-blue-600 p-4 rounded-md uppercase"
            id="closeDialogButton"
            @click=${(e: Event) => {
              this.handleSubmit(e);
            }}
          >
            Guardar
          </button>
        </div>
      </div>
    </ui5-dialog>`;
  }
}
