/* eslint-disable prettier/prettier */

export interface Route {
  path: string;
  action?: () => void;
  component: string;
}

export const PATHS: Route[] = [
  { 
    /* Crear Afiliacion */
    path: '/carga-masiva-banorte',
    component: 'carga-masiva-banorte',
    action: async () => {
      await import('../pages/CargaMasivaBanorte/CargaMasivaBanorte');
    },
  },
  { 
    /* Crear Afiliacion */
    path: '/create-afiliation-page',
    component: 'create-afiliation-page',
    action: async () => {
      await import('../pages/CrearAfiliacion/CrearAfiliacion');
    },
  },
  {
    /* Crear Razon Social */
    path: '/create-razon-social-page',
    component: 'create-razon-social-page',
    action: async () => {
      await import('../pages/CrearRazonSocial/CrearRazonSocial');
    },
  },
  {
    /* Editar Razon Social */
    path: '/edit-razon-social-page',
    component: 'edit-razon-social-page',
    action: async () => {
      await import('../pages/EditarRazonSocial/EditarRazonSocial');
    },
  },
  {
    /* Creacion Masiva */
    path: '/edit-masiva-page',
    component: 'edit-masiva-page',
    action: async () => {
      await import('../pages/EditMasiva/EditMasiva');
    },
  },
  {
    path: '/create-masiva-afiliation-page',
    component: 'create-masiva-afiliation-page',
    action: async () => {
      await import('../pages/CargaMasivaAfiliacion/CargaMasivaAfiliacion');
    },
  },
  {
    path: '/carga-masiva-business-page',
    component: 'carga-masiva-business-page',
    action: async () => {
      await import('../pages/CargaMasivaBusiness/CargaMasivaBusiness');
    },
  },
  {
    path: '/exportacion-masiva-page',
    component: 'exportacion-masiva-page',
    action: async () => {
      await import('../pages/ExportacionMasiva/ExportacionMasiva');
    }
  },
  {
    path: '/exportacion-masiva-banorte-page',
    component: 'exportacion-masiva-banorte-page',
    action: async () => {
      await import('../pages/ExportacionMasivaBanorte/ExportacionMasivaBanorte');
    }
  },
  {
    path: '/create-file-page',
    component: 'create-file-page',
    action: async () => {
      await import('../pages/CrearFile/CrearFile');
    }
  },
  {
    path: '/edit-file-page',
    component: 'edit-file-page',
    action: async () => {
      await import('../pages/EditarFile/EditarFile');
    },
  },
  {
    /* Creacion Masiva */
    path: '/carga-masiva-page',
    component: 'carga-masiva-page',
    action: async () => {
      await import('../pages/CargaMasiva/CargaMasiva');
    },
  },
  {
    /* Editar Afiliacion */
    path: '/edit-afiliation-page',
    component: 'edit-afiliation-page',
    action: async () => {
      await import('../pages/EditarAfiliacion/EditarAfiliacion');
    },
  },
  {
    /* Folio list */
    path: '/folio-list',
    component: 'folio-list',
    action: async () => {
      await import('../pages/FolioList/FolioList_new');
    },
  },
  {
    /* File list */
    path: '/file-list',
    component: 'files-list-page',
    action: async () => {
      await import('../pages/FilesList/FilesList');
    },
  },
  {
    /* Role List */
    path: '/role-list',
    component: 'roles-list-page',
    action: async () => {
      await import('../pages/RolesList/RolesList');
    },
  },
  {
    /* Role Edit */
    path: '/edit-rol-page',
    component: 'edit-rol-page',
    action: async () => {
      await import('../pages/EditarRol/EditarRol');
    },
  },
  {
    /* Afiliaciones list */
    path: '/afiliaciones-list',
    component: 'afiliaciones-list-page',
    action: async () => {
      await import('../pages/AfiliacionesList/AfiliacionesList');
    },
  },
  {
    /* Razon Social list */
    path: '/razon-social-list',
    component: 'razon-social-list-page',
    action: async () => {
      await import('../pages/RazonSocialList/RazonSocialList');
    },
  },
  {
    /* lista firmware */
    path: '/firmware-list',
    component: 'firmware-list',
    action: async () => {
      await import('../pages/FirmwareList/FirmwareListt');
    },
  },
  {
    /* Editar firmware */
    path: '/edit-firmware-page',
    component: 'edit-firmware-page',
    action: async () => {
      await import('../pages/EditFirmware/EditFirmware');
    },
  },  
  {
    /* crear firmware */
    path: '/create-firmware-page',
    component: 'create-firmware-page',
    action: async () => {
      await import('../pages/CrearFirmware/CrearFirmware');
    },
  },
  {
    /* crear pre-configuraciones */
    path: '/create-pre-configuration',
    component: 'create-pre-configuracion',
    action: async () => {
      await import('../pages/CrearPreConfiguracion/CrearPreConfiguracion');
    },
  },
  {
    /* Listado de pre-configuraciones */
    path: '/preconfiguration-page',
    component: 'pre-configuracion',
    action: async () => {
      await import('../pages/PreConfiguracion/PreConfiguracion');
    },
  },
  {
    /* Listado de Plantillas */
    path: '/plantilla-page',
    component: 'plantilla-page',
    action: async () => {
      await import('../pages/Plantillas/Plantillas');
    },
  },
  {
    /* Edicion de Plantillas */
    path: '/edit-template',
    component: 'edit-template-page',
    action: async () => {
      await import('../pages/EditarPlantilla/EditarPlantilla');
    },
  },
  {
    /* Pagina de Creación de Plantillas */
    path: '/create-template',
    component: 'create-template-page',
    action: async () => {
      await import('../pages/CrearPlantilla/CreateTemplate');
    },
  },
  {
    /* Pagina principal Dashboard */
    path: '/',
    component: 'dashboard-page',
    action: async () => {
      await import('../pages/Dashboard/Dashboard');
    },
  },
  {
    /* Pagina de Jerarquia de terminales */
    path: '/hierarchy',
    component: 'hierarchy-page',
    action: async () => {
      await import('../pages/Hierarchy/Hierarchy');
    },
  },
  {
    /* Pagina de recuperacion passwor */
    path: 'recovery-password',
    component: 'recovery-password',
    action: async () => {
      await import('../pages/RecoveryPassword/RecoveryPassword');
    },
  },
  {
    /* Pagina de Login */
    path: 'login',
    component: 'login-page',
    action: async () => {
      await import('../pages/Login/Login');
    },
  },
  {
    /* Pagina de Perfil de usuarios */
    path: 'profile',
    component: 'profile-page',
    action: async () => {
      await import('../pages/Profile/Profile');
    },
  },
  {
    /* Pagina de Creación de Usuarios */
    path: 'create-user',
    component: 'create-user-page',
    action: async () => {
      await import('../pages/CrearUsuario/CreateUser');
    },
  },
  /* {
    /* Pagina de Edicion de Usuario 
    path: 'edit-user',
    component: 'edit-user-page',
    action: async () => {
      await import('../pages/EditarUsuario/EditUser');
    },
  }, */
  {
    /* Paginal para administrar Permisos de Usuarios */
    path: 'users',
    component: 'users-page',
    action: async () => {
      await import('../pages/PermisosUsuarios/PermisosUsers');
    },
  },
  {
    /* Paginal para administrar Usuarios */
    path: 'users-list',
    component: 'users-list-page',
    action: async () => {
      await import('../pages/UsersList/UsersList');
    },
  },
  {
    /* Paginal para administrar Usuarios Master */
    path: 'masters-list',
    component: 'masters-list-page',
    action: async () => {
      await import('../pages/MasterUsers/MasterUsers');
    },
  },
  {
    /* Paginal de creación de Grupos */
    path: 'create-group',
    component: 'create-group-page',
    action: async () => {
      await import('../pages/CrearGrupo/CrearGrupo');
    },
  },
  {
    /* Paginal de información de un Terminal */
    path: 'terminal',
    component: 'terminal-page',
    action: async () => {
      await import('../pages/Terminal/Terminal');
    },
  },
  {
    /* Paginal de lista de Terminales */
    path: 'terminal-list',
    component: 'terminal-list-page',
    action: async () => {
      await import('../pages/TerminalList/TerminalList');
    },
  },
  {
    /* Paginal de Creacion de roles */
    path: 'create-rol',
    component: 'create-rol-page',
    action: async () => {
      await import('../pages/CrearRol/CreateRol');
    },
  },
  {
    /* Paginal de Creacion de roles */
    path: 'create-organization',
    component: 'create-organization-page',
    action: async () => {
      await import('../pages/CrearOrganizacion/CreateOrganization');
    },
  },
  {
    /* Paginal de creación de Terminales */
    path: 'create-terminal',
    component: 'create-terminal-page',
    action: async () => {
      await import('../pages/CrearTerminal/CrearTerminal');
    },
  },
  {
    /* Paginal de creación/registro de Aplicaciones */
    path: 'create-app',
    component: 'create-app-page',
    action: async () => {
      await import('../pages/CrearApp/CrearApp');
    },
  },
  {
    /* Paginal de información detallada de una App */
    path: 'view-app',
    component: 'view-app-page',
    action: async () => {
      await import('../pages/ViewApp/ViewApp');
    },
  },
  {
    /* Paginal general de Apps */
    path: 'apps-list',
    component: 'apps-list-page',
    action: async () => {
      await import('../pages/AppsList/AppsList');
    },
  },
  {
    /* Paginal de visualización de grupos y envio masivo a grupos */
    path: 'group-list',
    component: 'group-list-page',
    action: async () => {
      await import('../pages/GroupList/GroupsList');
    },
  },
  {
    /* Paginal de edicion de terminal */
    path: 'edit-terminal',
    component: 'edit-terminal-page',
    action: async () => {
      await import('../pages/EditTerminal/EditTerminal');
    },
  },
  {
    /* Paginal de edicion de terminal */
    path: 'edit-app',
    component: 'edit-app-page',
    action: async () => {
      await import('../pages/EditApp/EditApp');
    },
  },
  {
    /* Paginal de edicion de grupo */
    path: 'edit-group',
    component: 'edit-group-page',
    action: async () => {
      await import('../pages/EditarGrupo/EditarGrupo');
    },
  },
  {
    /* Paginal de edicion de usuario */
    path: 'edit-user',
    component: 'create-user-page',
    action: async () => {
      await import('../pages/CrearUsuario/CreateUser');
    },
  },
];
