/* tslint:disable */
/* eslint-disable */
/**
 * TMS Agnostiko
 * Tiene la definición de todos los servicios de TMS a Agnostic (para uso en la web o externo)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { Master } from '../models';
// @ts-ignore
import { Message } from '../models';
// @ts-ignore
import { RequestMasterChangeStatus } from '../models';
// @ts-ignore
import { RequestMasterConfigChange } from '../models';
// @ts-ignore
import { RequestMasterCreate } from '../models';
// @ts-ignore
import { ResponseMasterConfigGet } from '../models';
/**
 * MasterApi - axios parameter creator
 * @export
 */
export const MasterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cambia el estado (Activo o Bloqueado) de un Master
         * @param {string} name 
         * @param {RequestMasterChangeStatus} requestMasterChangeStatus 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        masterChangeStatus: async (name: string, requestMasterChangeStatus: RequestMasterChangeStatus, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('masterChangeStatus', 'name', name)
            // verify required parameter 'requestMasterChangeStatus' is not null or undefined
            assertParamExists('masterChangeStatus', 'requestMasterChangeStatus', requestMasterChangeStatus)
            const localVarPath = `/master/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestMasterChangeStatus, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cambia la configuración de un master
         * @param {string} name 
         * @param {RequestMasterConfigChange} requestMasterConfigChange 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        masterConfigChange: async (name: string, requestMasterConfigChange: RequestMasterConfigChange, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('masterConfigChange', 'name', name)
            // verify required parameter 'requestMasterConfigChange' is not null or undefined
            assertParamExists('masterConfigChange', 'requestMasterConfigChange', requestMasterConfigChange)
            const localVarPath = `/master/config/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestMasterConfigChange, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Vuelve la configuración a su valores por defecto
         * @param {string} name 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        masterConfigDelete: async (name: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('masterConfigDelete', 'name', name)
            const localVarPath = `/master/config/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary obtener la configuración actual
         * @param {string} name 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        masterConfigGet: async (name: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('masterConfigGet', 'name', name)
            const localVarPath = `/master/config/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Realiza las creación de un nuevo master, El usuario es el primero que se asigna Este usuario se le coloca permisos para realizar todas las operaciones en un rol base inicial Si el usuario no existe debe colocarse el password inicial
         * @param {RequestMasterCreate} requestMasterCreate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        masterCreate: async (requestMasterCreate: RequestMasterCreate, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestMasterCreate' is not null or undefined
            assertParamExists('masterCreate', 'requestMasterCreate', requestMasterCreate)
            const localVarPath = `/master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestMasterCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Obtener la lista de master
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        masterList: async (acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/master/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MasterApi - functional programming interface
 * @export
 */
export const MasterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MasterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cambia el estado (Activo o Bloqueado) de un Master
         * @param {string} name 
         * @param {RequestMasterChangeStatus} requestMasterChangeStatus 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async masterChangeStatus(name: string, requestMasterChangeStatus: RequestMasterChangeStatus, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.masterChangeStatus(name, requestMasterChangeStatus, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterApi.masterChangeStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Cambia la configuración de un master
         * @param {string} name 
         * @param {RequestMasterConfigChange} requestMasterConfigChange 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async masterConfigChange(name: string, requestMasterConfigChange: RequestMasterConfigChange, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.masterConfigChange(name, requestMasterConfigChange, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterApi.masterConfigChange']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Vuelve la configuración a su valores por defecto
         * @param {string} name 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async masterConfigDelete(name: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.masterConfigDelete(name, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterApi.masterConfigDelete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary obtener la configuración actual
         * @param {string} name 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async masterConfigGet(name: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseMasterConfigGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.masterConfigGet(name, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterApi.masterConfigGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Realiza las creación de un nuevo master, El usuario es el primero que se asigna Este usuario se le coloca permisos para realizar todas las operaciones en un rol base inicial Si el usuario no existe debe colocarse el password inicial
         * @param {RequestMasterCreate} requestMasterCreate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async masterCreate(requestMasterCreate: RequestMasterCreate, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.masterCreate(requestMasterCreate, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterApi.masterCreate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Obtener la lista de master
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async masterList(acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Master>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.masterList(acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterApi.masterList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * MasterApi - factory interface
 * @export
 */
export const MasterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MasterApiFp(configuration)
    return {
        /**
         * 
         * @summary Cambia el estado (Activo o Bloqueado) de un Master
         * @param {MasterApiMasterChangeStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        masterChangeStatus(requestParameters: MasterApiMasterChangeStatusRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.masterChangeStatus(requestParameters.name, requestParameters.requestMasterChangeStatus, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cambia la configuración de un master
         * @param {MasterApiMasterConfigChangeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        masterConfigChange(requestParameters: MasterApiMasterConfigChangeRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.masterConfigChange(requestParameters.name, requestParameters.requestMasterConfigChange, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Vuelve la configuración a su valores por defecto
         * @param {MasterApiMasterConfigDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        masterConfigDelete(requestParameters: MasterApiMasterConfigDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.masterConfigDelete(requestParameters.name, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary obtener la configuración actual
         * @param {MasterApiMasterConfigGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        masterConfigGet(requestParameters: MasterApiMasterConfigGetRequest, options?: AxiosRequestConfig): AxiosPromise<ResponseMasterConfigGet> {
            return localVarFp.masterConfigGet(requestParameters.name, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Realiza las creación de un nuevo master, El usuario es el primero que se asigna Este usuario se le coloca permisos para realizar todas las operaciones en un rol base inicial Si el usuario no existe debe colocarse el password inicial
         * @param {MasterApiMasterCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        masterCreate(requestParameters: MasterApiMasterCreateRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.masterCreate(requestParameters.requestMasterCreate, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Obtener la lista de master
         * @param {MasterApiMasterListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        masterList(requestParameters: MasterApiMasterListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<Master>> {
            return localVarFp.masterList(requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for masterChangeStatus operation in MasterApi.
 * @export
 * @interface MasterApiMasterChangeStatusRequest
 */
export interface MasterApiMasterChangeStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof MasterApiMasterChangeStatus
     */
    readonly name: string

    /**
     * 
     * @type {RequestMasterChangeStatus}
     * @memberof MasterApiMasterChangeStatus
     */
    readonly requestMasterChangeStatus: RequestMasterChangeStatus

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof MasterApiMasterChangeStatus
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for masterConfigChange operation in MasterApi.
 * @export
 * @interface MasterApiMasterConfigChangeRequest
 */
export interface MasterApiMasterConfigChangeRequest {
    /**
     * 
     * @type {string}
     * @memberof MasterApiMasterConfigChange
     */
    readonly name: string

    /**
     * 
     * @type {RequestMasterConfigChange}
     * @memberof MasterApiMasterConfigChange
     */
    readonly requestMasterConfigChange: RequestMasterConfigChange

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof MasterApiMasterConfigChange
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for masterConfigDelete operation in MasterApi.
 * @export
 * @interface MasterApiMasterConfigDeleteRequest
 */
export interface MasterApiMasterConfigDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof MasterApiMasterConfigDelete
     */
    readonly name: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof MasterApiMasterConfigDelete
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for masterConfigGet operation in MasterApi.
 * @export
 * @interface MasterApiMasterConfigGetRequest
 */
export interface MasterApiMasterConfigGetRequest {
    /**
     * 
     * @type {string}
     * @memberof MasterApiMasterConfigGet
     */
    readonly name: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof MasterApiMasterConfigGet
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for masterCreate operation in MasterApi.
 * @export
 * @interface MasterApiMasterCreateRequest
 */
export interface MasterApiMasterCreateRequest {
    /**
     * 
     * @type {RequestMasterCreate}
     * @memberof MasterApiMasterCreate
     */
    readonly requestMasterCreate: RequestMasterCreate

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof MasterApiMasterCreate
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for masterList operation in MasterApi.
 * @export
 * @interface MasterApiMasterListRequest
 */
export interface MasterApiMasterListRequest {
    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof MasterApiMasterList
     */
    readonly acceptLanguage?: string
}

/**
 * MasterApi - object-oriented interface
 * @export
 * @class MasterApi
 * @extends {BaseAPI}
 */
export class MasterApi extends BaseAPI {
    /**
     * 
     * @summary Cambia el estado (Activo o Bloqueado) de un Master
     * @param {MasterApiMasterChangeStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterApi
     */
    public masterChangeStatus(requestParameters: MasterApiMasterChangeStatusRequest, options?: AxiosRequestConfig) {
        return MasterApiFp(this.configuration).masterChangeStatus(requestParameters.name, requestParameters.requestMasterChangeStatus, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cambia la configuración de un master
     * @param {MasterApiMasterConfigChangeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterApi
     */
    public masterConfigChange(requestParameters: MasterApiMasterConfigChangeRequest, options?: AxiosRequestConfig) {
        return MasterApiFp(this.configuration).masterConfigChange(requestParameters.name, requestParameters.requestMasterConfigChange, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Vuelve la configuración a su valores por defecto
     * @param {MasterApiMasterConfigDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterApi
     */
    public masterConfigDelete(requestParameters: MasterApiMasterConfigDeleteRequest, options?: AxiosRequestConfig) {
        return MasterApiFp(this.configuration).masterConfigDelete(requestParameters.name, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary obtener la configuración actual
     * @param {MasterApiMasterConfigGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterApi
     */
    public masterConfigGet(requestParameters: MasterApiMasterConfigGetRequest, options?: AxiosRequestConfig) {
        return MasterApiFp(this.configuration).masterConfigGet(requestParameters.name, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Realiza las creación de un nuevo master, El usuario es el primero que se asigna Este usuario se le coloca permisos para realizar todas las operaciones en un rol base inicial Si el usuario no existe debe colocarse el password inicial
     * @param {MasterApiMasterCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterApi
     */
    public masterCreate(requestParameters: MasterApiMasterCreateRequest, options?: AxiosRequestConfig) {
        return MasterApiFp(this.configuration).masterCreate(requestParameters.requestMasterCreate, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Obtener la lista de master
     * @param {MasterApiMasterListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterApi
     */
    public masterList(requestParameters: MasterApiMasterListRequest = {}, options?: AxiosRequestConfig) {
        return MasterApiFp(this.configuration).masterList(requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}

