/* eslint-disable prettier/prettier */

import { LitElement, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import '@ui5/webcomponents/dist/Dialog.js';
import '@vaadin/vaadin-select';
import router from '../../router/router';
import { errorNotification } from '../../services/notifications';
import { apisConfigurator } from '../../utils/axios';
import '../CustomSelect/CustomSelect';
import {
  AppApi, 
  AppList,
  License,
  LicenseApi,
  Master,
  RequestCreateLicense,
  RequestUpdateLicense,
  GroupApiGroupCreateRequest,
  GroupApi,
  RequestGroupCreate
} from '../../../api';
import {
  succesNotification,
} from '../../services/notifications';
import Swal from 'sweetalert2';
import store from '../../redux/store';
import { grupoAdded } from '../../redux/actions/groups';
import {
  validar_campos_grupos,
  } from '../../utils/functions';
  import { generate_table, InsertarPropiedad } from '../../utils/functions';
import { TextField } from '@material/mwc-textfield';
import { CustomSelect } from '../CustomSelect/CustomSelect';

@customElement('crear-grupos')
export class CrearGrupos extends LitElement {
  private groupApi: GroupApi;
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  @state()
  final_vals: any[] = [];

  @state()
  private loading = false;

  @state()
  private name:string = "";

  @state()
  private licenseUpdate?: License;

  @state()
  private appName?: string;

  @state()
  private packageName?: string;
 
  @state()
  private user?: Master;

  @state()
  private apps: AppList[] = [];

  private licenseApi: LicenseApi;
  private appApi: AppApi;

  private get nameInput() {
    return document.getElementById('group-name')! as HTMLInputElement;
  }

  get serial() {
    return document.getElementById('serial') as TextField;
  }

  get model() {
    return document.getElementById('model') as TextField;
  }

  get brand() {
    return document.getElementById('brand') as TextField;
  }

  get description() {
    return document.getElementById('description') as TextField;
  }

  get priority() {
    return document.getElementById('priority') as TextField;
  }

  get renewalTime() {
    return document.getElementById('renewalTime') as TextField;
  }

  get endDate() {
    return document.getElementById('endDate') as TextField;
  }

  get terminalLimit() {
    return document.getElementById('terminalLimit') as TextField;
  }

  get appSelected() {
    return document.getElementById('appSelect') as any;
  }

  get appOnUpdate() {
    return document.getElementById('app') as TextField;
  }

  constructor() {
    super();
    this.groupApi = new GroupApi(...apisConfigurator('Grupo'));
    this.licenseApi = new LicenseApi(...apisConfigurator('Licencias'));
    this.appApi = new AppApi(...apisConfigurator('Licencias'));
  }

  private get dialog() {
    return document.getElementById('crear-grupos-csv')! as any;
  }

  private get seleccionarArchivo() {
    return document.getElementById('seleccionar-archivo') as any;
  }

  private get botonCsv() {
    return document.querySelector('#seleccionar-archivo')! as HTMLInputElement;
  }

  private get displayArea() {
    return document.getElementById('displayArea') as any;
  }

  private get disabledButton() {
    return document.getElementById('crearTerminal') as any;
  }

  private get alertCampo() {
    return document.getElementById('alertCampo') as any;
  }

  cvsForm() {
    let final_vals: any[] = [];
    let grupos:any = [];
    let data: RequestGroupCreate[] = [];
    let csvReader = new FileReader(); // generate a filereader from the JS API
    const alertCampo = this.alertCampo;
    const area = this.displayArea;
    console.log(area);
    const disabledButton = this.disabledButton;
    const input = this.seleccionarArchivo.files[0]; // grab the first (only) file from the input

    csvReader.onload = function (evt) {
      const text = evt?.target?.result; // this is the data generated from the csvReader reading the information in the file
      if (typeof text === 'string' || text instanceof String) {
        const values = text.split(/[\n]+/); // group the information by the CSV breakpoint \n is a new line
 
        values.forEach((val, index) => {
          // further split by each section by the CSV
          if (index == 0) {
            if (!validar_campos_grupos(val.split(/;|,/))) { 
              //console.log(validar_campos(val.split(/;|,/)))
              area.style.border = '4px solid #b92326';
              alertCampo.style.visibility = 'visible';
              alertCampo.style.color = '#b92326';
              disabledButton.disabled = true;
            } else {
              //console.log(validar_campos(val.split(/;|,/)))
              area.style.border = '';
              alertCampo.style.visibility = 'hidden';
              disabledButton.disabled = false;
              //alertCampo.style.color = "#b92326";
            }
          } else if (index != values.length - 1) {
            grupos.push({ 
              brand: val.split(/;|,/)[1],
              serial: val.split(/;|,/)[0],
            })
            //console.log(grupos)
          }
          final_vals.push(val.split(/;|,/));
        });
        let limpiar:any =[]
        final_vals.map((item,index) => {
          if(item[0] != ''){
            limpiar.push(item)
          }
        })
        //this.final_vals = data;
        //console.log(final_vals)
        // create form
        generate_table(<[string[]]>final_vals).then(result => {
          // async function is used to ensure the formatting does not try to occur after the table is created

          area.innerHTML = result;

          const th_values = document.getElementsByTagName('th');
          const td_values = document.getElementsByTagName('td');

          const capitilize_table_column = (table_el: HTMLElement) => {
            table_el.innerHTML =
              table_el.innerHTML[0].toUpperCase() + table_el.innerHTML.slice(1);
          };

          for (const th_val of th_values) {
            capitilize_table_column(th_val);
          }
          for (const td_val of td_values) {
            capitilize_table_column(td_val);
          }
        });
      }
    };
    this.final_vals = grupos;
    console.log("final_vals",this.final_vals)
    //this.datos = this.final_vals;
    // this runs the above action
    csvReader.readAsText(input);
  }

  protected firstUpdated(): void {}

  async open() {
    this.dialog.open = true;
  }


  close() {
    this.dialog.open = false;
    this.user = undefined;
    this.apps = [];
    this.licenseUpdate = undefined;
  }
 
  private async handleSubmit(e: Event) {
    if (!this.name.length) {
      //this.loading = false;
      return;
    }
    const requestGroupCreate: RequestGroupCreate = {
      terminals:this.final_vals,
      name:this.name
    };
    try {
      const res = await this.groupApi.groupCreate({ requestGroupCreate });
      console.log(res) 
      succesNotification('Datos guardados con exito!'); 
      if (res.status == 200) {
        //router.cambiarSeccion('plantilla-page');
        await Swal.fire('Agregado!', 'El tipo de dato registrado', 'success');
        //window.location.reload()
      } 
      store.dispatch(
        grupoAdded({
          name: requestGroupCreate.name,
          id: res.data.id,
          terminals: this.final_vals.length,
        })
      );
    } catch (err) {
    } finally {
      this.loading = false;
    }
    e.preventDefault();
  }

  private async onSubmit() {
    /*if (!this.validate()) return;
    console.log("DATOS LICENCIA",this.buildLicense())
    if (this.licenseUpdate) {
      const res = await this.licenseApi.updateLicense({
        requestUpdateLicense: this.buildLicense(),
        id: this.licenseUpdate.id,
      });
      this.dispatchLicenseUpdate();
      this.close();
      return;
    } 

    const res = await this.licenseApi.licenseCreate({
      requestCreateLicense: this.buildLicense(),
    });
    this.dispatchLicenseUpdate();
    this.close();
    return;*/
  }




  protected render() {
    return html` <ui5-dialog
      id="crear-grupos-csv"
      header-text="
        Agregar Grupos"
      class="w-6/12 h-6/12"
    >
    <div class="my-4 ml-8">
      <p> 
      sube un archivo CSV con la cantidad de grupos que desees 
      </p>
      <span id="alertCampo">Formato de campos incorrectos</span>
    </div>
    <div class="w-4/12 TexField">
      <mwc-textfield
        label="Nombre grupo"
        class="w-full "
        required
        outlined
        iconTrailing="close"
        id="group-name"
        @keyup=${(ev: any) => {
          this.name = ev.target.value;
        }}
      >
      </mwc-textfield>
    </div>
    <div class="w-full flex flex-row my-4 ml-8">
        <div class="CajonXlm w-3/5" id="displayArea"></div>
        <div class="w-2/5	ml-10 CrearTerminal normal-case 	flex flex-col">
        <div class="w-40	 min-w-full">
            <mat-form-field>
            <!-- <input type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" placeholder="label" value="value"> -->
            </mat-form-field>
            <input
            id="seleccionar-archivo"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            placeholder="label"
            type="file"
            @change=${(ev: InputEvent) => {
                this.cvsForm();
            }}
            hidden
            />
            <mwc-button
            class="rounded-3xl ml-4 mb-2"
            @click=${() => {
                this.botonCsv.click();
            }}
            }}
            >
            <p class="normal-case text-xs">Explorar</p>
            </mwc-button>
        </div>
        <div class="w-40	 min-w-full">
            <mwc-button
            type="submit"
            class="rounded-3xl ml-4 mt-4  bg-yellow-500 text-yellow-700	normal-case	"
            @click=${() => {
              let area = this.displayArea;
                const alertCampo = this.alertCampo;
                area.innerHTML = ``;
                area.style.border = '';
                alertCampo.style.visibility = 'hidden'; 
            }}
            >
            <p class="normal-case	 text-xs">Eliminar</p>
            </mwc-button>
        </div>
        <div class="w-40	 min-w-full">
            <mwc-button
            type="submit"
            class="rounded-3xl ml-4 mt-4  bg-red-500 text-red-700	normal-case	"
            @click=${() => {
              this.close();
            }}
            >
            <p class="normal-case	 text-xs">Cancelar</p>
            </mwc-button>
        </div>
        <div class="w-40	 min-w-full">
        <mwc-button
                id="crearTerminal"
                class="rounded-3xl ml-4 mt-4  bg-green-500 text-green-700	normal-case"
                @click="${(e: Event) => this.handleSubmit(e)}"
                .disabled=${this.loading}
              >
                <p class="normal-case	text-xs ">Registrar</p>
              </mwc-button>
        </div>
        </div>
    </div>
    </ui5-dialog>`;
  }
}
