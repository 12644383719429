/* tslint:disable */
/* eslint-disable */
/**
 * TMS Agnostiko
 * Tiene la definición de todos los servicios de TMS a Agnostic (para uso en la web o externo)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { Message } from '../models';
// @ts-ignore
import { Request2FACode } from '../models';
// @ts-ignore
import { RequestChangeDoubleFAStatus } from '../models';
// @ts-ignore
import { RequestResetAttempts } from '../models';
// @ts-ignore
import { RequestUpdatePasswordWithToken } from '../models';
// @ts-ignore
import { RequestUserCreate } from '../models';
// @ts-ignore
import { RequestUserMasterChange } from '../models';
// @ts-ignore
import { RequestUserPassword } from '../models';
// @ts-ignore
import { RequestUserUpdate } from '../models';
// @ts-ignore
import { RequestUserUpdateOrganization } from '../models';
// @ts-ignore
import { ResponseDoubleFactorRegister } from '../models';
// @ts-ignore
import { ResponseUserGet } from '../models';
// @ts-ignore
import { ResponseUserInfo } from '../models';
// @ts-ignore
import { ResponseUserMasterChange } from '../models';
// @ts-ignore
import { ResponseVerificationCode } from '../models';
// @ts-ignore
import { User } from '../models';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cambio de 2FA por usuario
         * @param {RequestChangeDoubleFAStatus} requestChangeDoubleFAStatus 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        change2FAByUser: async (requestChangeDoubleFAStatus: RequestChangeDoubleFAStatus, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestChangeDoubleFAStatus' is not null or undefined
            assertParamExists('change2FAByUser', 'requestChangeDoubleFAStatus', requestChangeDoubleFAStatus)
            const localVarPath = `/user/change2FAStatusByUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestChangeDoubleFAStatus, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Activa o desactiva 2FA
         * @param {Request2FACode} request2FACode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStatus2FA: async (request2FACode: Request2FACode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request2FACode' is not null or undefined
            assertParamExists('changeStatus2FA', 'request2FACode', request2FACode)
            const localVarPath = `/user/activate2FA`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request2FACode, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Crea token para recuperación de contraseña
         * @param {string} idUser 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRecoverPasswordToken: async (idUser: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idUser' is not null or undefined
            assertParamExists('createRecoverPasswordToken', 'idUser', idUser)
            const localVarPath = `/user/password/token/{idUser}`
                .replace(`{${"idUser"}}`, encodeURIComponent(String(idUser)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generar clave y QR
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerDoubleFactor: async (acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/register2FA`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cambio de contraseña de usuario validando el token
         * @param {string} idUser 
         * @param {RequestUpdatePasswordWithToken} requestUpdatePasswordWithToken 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPasswordWithToken: async (idUser: string, requestUpdatePasswordWithToken: RequestUpdatePasswordWithToken, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idUser' is not null or undefined
            assertParamExists('updateUserPasswordWithToken', 'idUser', idUser)
            // verify required parameter 'requestUpdatePasswordWithToken' is not null or undefined
            assertParamExists('updateUserPasswordWithToken', 'requestUpdatePasswordWithToken', requestUpdatePasswordWithToken)
            const localVarPath = `/user/password/token/{idUser}`
                .replace(`{${"idUser"}}`, encodeURIComponent(String(idUser)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestUpdatePasswordWithToken, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Crea o modifica un usuario de la aplicación
         * @param {RequestUserCreate} requestUserCreate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCreate: async (requestUserCreate: RequestUserCreate, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestUserCreate' is not null or undefined
            assertParamExists('userCreate', 'requestUserCreate', requestUserCreate)
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestUserCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary obtiene la información de un usuario de la aplicación
         * @param {string} user 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGet: async (user: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('userGet', 'user', user)
            const localVarPath = `/user/{user}`
                .replace(`{${"user"}}`, encodeURIComponent(String(user)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Envía la información del usuario
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInfo: async (acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Devuelve la lista de usuario, de master actual
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userList: async (acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Realiza el cambio del master actual (no el defecto) del usuario
         * @param {RequestUserMasterChange} requestUserMasterChange 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userMasterChange: async (requestUserMasterChange: RequestUserMasterChange, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestUserMasterChange' is not null or undefined
            assertParamExists('userMasterChange', 'requestUserMasterChange', requestUserMasterChange)
            const localVarPath = `/user/master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestUserMasterChange, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Elimina un usuario del master actual
         * @param {string} user 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userMasterDelete: async (user: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('userMasterDelete', 'user', user)
            const localVarPath = `/user/master/{user}`
                .replace(`{${"user"}}`, encodeURIComponent(String(user)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Devuelve la lista de master del usuario actual
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userMasterList: async (acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/get/master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Realiza el cambio de password de la aplicación
         * @param {RequestUserPassword} requestUserPassword 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPasswordChange: async (requestUserPassword: RequestUserPassword, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestUserPassword' is not null or undefined
            assertParamExists('userPasswordChange', 'requestUserPassword', requestUserPassword)
            const localVarPath = `/user/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestUserPassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Realiza el cambio de status del usuario
         * @param {RequestResetAttempts} requestResetAttempts 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userResetFailedAttempts: async (requestResetAttempts: RequestResetAttempts, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestResetAttempts' is not null or undefined
            assertParamExists('userResetFailedAttempts', 'requestResetAttempts', requestResetAttempts)
            const localVarPath = `/user/resetFailedAttempts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestResetAttempts, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Realiza el cambio de status de usuario inactivo
         * @param {RequestResetAttempts} requestResetAttempts 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userResetLastLoggedIn: async (requestResetAttempts: RequestResetAttempts, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestResetAttempts' is not null or undefined
            assertParamExists('userResetLastLoggedIn', 'requestResetAttempts', requestResetAttempts)
            const localVarPath = `/user/resetLastLoggedIn`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestResetAttempts, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary modifica un usuario de la aplicación
         * @param {string} user 
         * @param {RequestUserUpdate} requestUserUpdate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdate: async (user: string, requestUserUpdate: RequestUserUpdate, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('userUpdate', 'user', user)
            // verify required parameter 'requestUserUpdate' is not null or undefined
            assertParamExists('userUpdate', 'requestUserUpdate', requestUserUpdate)
            const localVarPath = `/user/{user}`
                .replace(`{${"user"}}`, encodeURIComponent(String(user)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestUserUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary modifica las organizaciones sobre las que tiene permiso el usuario de la aplicación
         * @param {string} user 
         * @param {RequestUserUpdateOrganization} requestUserUpdateOrganization 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdateOrganization: async (user: string, requestUserUpdateOrganization: RequestUserUpdateOrganization, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('userUpdateOrganization', 'user', user)
            // verify required parameter 'requestUserUpdateOrganization' is not null or undefined
            assertParamExists('userUpdateOrganization', 'requestUserUpdateOrganization', requestUserUpdateOrganization)
            const localVarPath = `/user/{user}/organizations`
                .replace(`{${"user"}}`, encodeURIComponent(String(user)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestUserUpdateOrganization, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cambio de 2FA por usuario
         * @param {RequestChangeDoubleFAStatus} requestChangeDoubleFAStatus 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async change2FAByUser(requestChangeDoubleFAStatus: RequestChangeDoubleFAStatus, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.change2FAByUser(requestChangeDoubleFAStatus, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.change2FAByUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Activa o desactiva 2FA
         * @param {Request2FACode} request2FACode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeStatus2FA(request2FACode: Request2FACode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseVerificationCode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeStatus2FA(request2FACode, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.changeStatus2FA']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Crea token para recuperación de contraseña
         * @param {string} idUser 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRecoverPasswordToken(idUser: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRecoverPasswordToken(idUser, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.createRecoverPasswordToken']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Generar clave y QR
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerDoubleFactor(acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDoubleFactorRegister>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerDoubleFactor(acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.registerDoubleFactor']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Cambio de contraseña de usuario validando el token
         * @param {string} idUser 
         * @param {RequestUpdatePasswordWithToken} requestUpdatePasswordWithToken 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserPasswordWithToken(idUser: string, requestUpdatePasswordWithToken: RequestUpdatePasswordWithToken, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserPasswordWithToken(idUser, requestUpdatePasswordWithToken, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.updateUserPasswordWithToken']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Crea o modifica un usuario de la aplicación
         * @param {RequestUserCreate} requestUserCreate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userCreate(requestUserCreate: RequestUserCreate, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userCreate(requestUserCreate, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.userCreate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary obtiene la información de un usuario de la aplicación
         * @param {string} user 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGet(user: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUserGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGet(user, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.userGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Envía la información del usuario
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userInfo(acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userInfo(acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.userInfo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Devuelve la lista de usuario, de master actual
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userList(acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userList(acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.userList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Realiza el cambio del master actual (no el defecto) del usuario
         * @param {RequestUserMasterChange} requestUserMasterChange 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userMasterChange(requestUserMasterChange: RequestUserMasterChange, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUserMasterChange>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userMasterChange(requestUserMasterChange, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.userMasterChange']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Elimina un usuario del master actual
         * @param {string} user 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userMasterDelete(user: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userMasterDelete(user, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.userMasterDelete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Devuelve la lista de master del usuario actual
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userMasterList(acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userMasterList(acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.userMasterList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Realiza el cambio de password de la aplicación
         * @param {RequestUserPassword} requestUserPassword 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPasswordChange(requestUserPassword: RequestUserPassword, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPasswordChange(requestUserPassword, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.userPasswordChange']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Realiza el cambio de status del usuario
         * @param {RequestResetAttempts} requestResetAttempts 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userResetFailedAttempts(requestResetAttempts: RequestResetAttempts, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userResetFailedAttempts(requestResetAttempts, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.userResetFailedAttempts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Realiza el cambio de status de usuario inactivo
         * @param {RequestResetAttempts} requestResetAttempts 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userResetLastLoggedIn(requestResetAttempts: RequestResetAttempts, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userResetLastLoggedIn(requestResetAttempts, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.userResetLastLoggedIn']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary modifica un usuario de la aplicación
         * @param {string} user 
         * @param {RequestUserUpdate} requestUserUpdate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUpdate(user: string, requestUserUpdate: RequestUserUpdate, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userUpdate(user, requestUserUpdate, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.userUpdate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary modifica las organizaciones sobre las que tiene permiso el usuario de la aplicación
         * @param {string} user 
         * @param {RequestUserUpdateOrganization} requestUserUpdateOrganization 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUpdateOrganization(user: string, requestUserUpdateOrganization: RequestUserUpdateOrganization, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userUpdateOrganization(user, requestUserUpdateOrganization, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.userUpdateOrganization']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Cambio de 2FA por usuario
         * @param {UserApiChange2FAByUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        change2FAByUser(requestParameters: UserApiChange2FAByUserRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.change2FAByUser(requestParameters.requestChangeDoubleFAStatus, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Activa o desactiva 2FA
         * @param {UserApiChangeStatus2FARequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStatus2FA(requestParameters: UserApiChangeStatus2FARequest, options?: AxiosRequestConfig): AxiosPromise<ResponseVerificationCode> {
            return localVarFp.changeStatus2FA(requestParameters.request2FACode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Crea token para recuperación de contraseña
         * @param {UserApiCreateRecoverPasswordTokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRecoverPasswordToken(requestParameters: UserApiCreateRecoverPasswordTokenRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.createRecoverPasswordToken(requestParameters.idUser, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generar clave y QR
         * @param {UserApiRegisterDoubleFactorRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerDoubleFactor(requestParameters: UserApiRegisterDoubleFactorRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ResponseDoubleFactorRegister> {
            return localVarFp.registerDoubleFactor(requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cambio de contraseña de usuario validando el token
         * @param {UserApiUpdateUserPasswordWithTokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPasswordWithToken(requestParameters: UserApiUpdateUserPasswordWithTokenRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.updateUserPasswordWithToken(requestParameters.idUser, requestParameters.requestUpdatePasswordWithToken, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Crea o modifica un usuario de la aplicación
         * @param {UserApiUserCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCreate(requestParameters: UserApiUserCreateRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.userCreate(requestParameters.requestUserCreate, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary obtiene la información de un usuario de la aplicación
         * @param {UserApiUserGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGet(requestParameters: UserApiUserGetRequest, options?: AxiosRequestConfig): AxiosPromise<ResponseUserGet> {
            return localVarFp.userGet(requestParameters.user, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Envía la información del usuario
         * @param {UserApiUserInfoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInfo(requestParameters: UserApiUserInfoRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ResponseUserInfo> {
            return localVarFp.userInfo(requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Devuelve la lista de usuario, de master actual
         * @param {UserApiUserListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userList(requestParameters: UserApiUserListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<User>> {
            return localVarFp.userList(requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Realiza el cambio del master actual (no el defecto) del usuario
         * @param {UserApiUserMasterChangeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userMasterChange(requestParameters: UserApiUserMasterChangeRequest, options?: AxiosRequestConfig): AxiosPromise<ResponseUserMasterChange> {
            return localVarFp.userMasterChange(requestParameters.requestUserMasterChange, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Elimina un usuario del master actual
         * @param {UserApiUserMasterDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userMasterDelete(requestParameters: UserApiUserMasterDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.userMasterDelete(requestParameters.user, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Devuelve la lista de master del usuario actual
         * @param {UserApiUserMasterListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userMasterList(requestParameters: UserApiUserMasterListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.userMasterList(requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Realiza el cambio de password de la aplicación
         * @param {UserApiUserPasswordChangeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPasswordChange(requestParameters: UserApiUserPasswordChangeRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.userPasswordChange(requestParameters.requestUserPassword, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Realiza el cambio de status del usuario
         * @param {UserApiUserResetFailedAttemptsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userResetFailedAttempts(requestParameters: UserApiUserResetFailedAttemptsRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.userResetFailedAttempts(requestParameters.requestResetAttempts, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Realiza el cambio de status de usuario inactivo
         * @param {UserApiUserResetLastLoggedInRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userResetLastLoggedIn(requestParameters: UserApiUserResetLastLoggedInRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.userResetLastLoggedIn(requestParameters.requestResetAttempts, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary modifica un usuario de la aplicación
         * @param {UserApiUserUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdate(requestParameters: UserApiUserUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.userUpdate(requestParameters.user, requestParameters.requestUserUpdate, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary modifica las organizaciones sobre las que tiene permiso el usuario de la aplicación
         * @param {UserApiUserUpdateOrganizationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdateOrganization(requestParameters: UserApiUserUpdateOrganizationRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.userUpdateOrganization(requestParameters.user, requestParameters.requestUserUpdateOrganization, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for change2FAByUser operation in UserApi.
 * @export
 * @interface UserApiChange2FAByUserRequest
 */
export interface UserApiChange2FAByUserRequest {
    /**
     * 
     * @type {RequestChangeDoubleFAStatus}
     * @memberof UserApiChange2FAByUser
     */
    readonly requestChangeDoubleFAStatus: RequestChangeDoubleFAStatus

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof UserApiChange2FAByUser
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for changeStatus2FA operation in UserApi.
 * @export
 * @interface UserApiChangeStatus2FARequest
 */
export interface UserApiChangeStatus2FARequest {
    /**
     * 
     * @type {Request2FACode}
     * @memberof UserApiChangeStatus2FA
     */
    readonly request2FACode: Request2FACode
}

/**
 * Request parameters for createRecoverPasswordToken operation in UserApi.
 * @export
 * @interface UserApiCreateRecoverPasswordTokenRequest
 */
export interface UserApiCreateRecoverPasswordTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiCreateRecoverPasswordToken
     */
    readonly idUser: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof UserApiCreateRecoverPasswordToken
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for registerDoubleFactor operation in UserApi.
 * @export
 * @interface UserApiRegisterDoubleFactorRequest
 */
export interface UserApiRegisterDoubleFactorRequest {
    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof UserApiRegisterDoubleFactor
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateUserPasswordWithToken operation in UserApi.
 * @export
 * @interface UserApiUpdateUserPasswordWithTokenRequest
 */
export interface UserApiUpdateUserPasswordWithTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiUpdateUserPasswordWithToken
     */
    readonly idUser: string

    /**
     * 
     * @type {RequestUpdatePasswordWithToken}
     * @memberof UserApiUpdateUserPasswordWithToken
     */
    readonly requestUpdatePasswordWithToken: RequestUpdatePasswordWithToken

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof UserApiUpdateUserPasswordWithToken
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for userCreate operation in UserApi.
 * @export
 * @interface UserApiUserCreateRequest
 */
export interface UserApiUserCreateRequest {
    /**
     * 
     * @type {RequestUserCreate}
     * @memberof UserApiUserCreate
     */
    readonly requestUserCreate: RequestUserCreate

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof UserApiUserCreate
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for userGet operation in UserApi.
 * @export
 * @interface UserApiUserGetRequest
 */
export interface UserApiUserGetRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiUserGet
     */
    readonly user: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof UserApiUserGet
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for userInfo operation in UserApi.
 * @export
 * @interface UserApiUserInfoRequest
 */
export interface UserApiUserInfoRequest {
    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof UserApiUserInfo
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for userList operation in UserApi.
 * @export
 * @interface UserApiUserListRequest
 */
export interface UserApiUserListRequest {
    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof UserApiUserList
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for userMasterChange operation in UserApi.
 * @export
 * @interface UserApiUserMasterChangeRequest
 */
export interface UserApiUserMasterChangeRequest {
    /**
     * 
     * @type {RequestUserMasterChange}
     * @memberof UserApiUserMasterChange
     */
    readonly requestUserMasterChange: RequestUserMasterChange

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof UserApiUserMasterChange
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for userMasterDelete operation in UserApi.
 * @export
 * @interface UserApiUserMasterDeleteRequest
 */
export interface UserApiUserMasterDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiUserMasterDelete
     */
    readonly user: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof UserApiUserMasterDelete
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for userMasterList operation in UserApi.
 * @export
 * @interface UserApiUserMasterListRequest
 */
export interface UserApiUserMasterListRequest {
    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof UserApiUserMasterList
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for userPasswordChange operation in UserApi.
 * @export
 * @interface UserApiUserPasswordChangeRequest
 */
export interface UserApiUserPasswordChangeRequest {
    /**
     * 
     * @type {RequestUserPassword}
     * @memberof UserApiUserPasswordChange
     */
    readonly requestUserPassword: RequestUserPassword

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof UserApiUserPasswordChange
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for userResetFailedAttempts operation in UserApi.
 * @export
 * @interface UserApiUserResetFailedAttemptsRequest
 */
export interface UserApiUserResetFailedAttemptsRequest {
    /**
     * 
     * @type {RequestResetAttempts}
     * @memberof UserApiUserResetFailedAttempts
     */
    readonly requestResetAttempts: RequestResetAttempts

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof UserApiUserResetFailedAttempts
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for userResetLastLoggedIn operation in UserApi.
 * @export
 * @interface UserApiUserResetLastLoggedInRequest
 */
export interface UserApiUserResetLastLoggedInRequest {
    /**
     * 
     * @type {RequestResetAttempts}
     * @memberof UserApiUserResetLastLoggedIn
     */
    readonly requestResetAttempts: RequestResetAttempts

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof UserApiUserResetLastLoggedIn
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for userUpdate operation in UserApi.
 * @export
 * @interface UserApiUserUpdateRequest
 */
export interface UserApiUserUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiUserUpdate
     */
    readonly user: string

    /**
     * 
     * @type {RequestUserUpdate}
     * @memberof UserApiUserUpdate
     */
    readonly requestUserUpdate: RequestUserUpdate

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof UserApiUserUpdate
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for userUpdateOrganization operation in UserApi.
 * @export
 * @interface UserApiUserUpdateOrganizationRequest
 */
export interface UserApiUserUpdateOrganizationRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiUserUpdateOrganization
     */
    readonly user: string

    /**
     * 
     * @type {RequestUserUpdateOrganization}
     * @memberof UserApiUserUpdateOrganization
     */
    readonly requestUserUpdateOrganization: RequestUserUpdateOrganization

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof UserApiUserUpdateOrganization
     */
    readonly acceptLanguage?: string
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Cambio de 2FA por usuario
     * @param {UserApiChange2FAByUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public change2FAByUser(requestParameters: UserApiChange2FAByUserRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).change2FAByUser(requestParameters.requestChangeDoubleFAStatus, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Activa o desactiva 2FA
     * @param {UserApiChangeStatus2FARequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public changeStatus2FA(requestParameters: UserApiChangeStatus2FARequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).changeStatus2FA(requestParameters.request2FACode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Crea token para recuperación de contraseña
     * @param {UserApiCreateRecoverPasswordTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createRecoverPasswordToken(requestParameters: UserApiCreateRecoverPasswordTokenRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).createRecoverPasswordToken(requestParameters.idUser, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generar clave y QR
     * @param {UserApiRegisterDoubleFactorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public registerDoubleFactor(requestParameters: UserApiRegisterDoubleFactorRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).registerDoubleFactor(requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cambio de contraseña de usuario validando el token
     * @param {UserApiUpdateUserPasswordWithTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserPasswordWithToken(requestParameters: UserApiUpdateUserPasswordWithTokenRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUserPasswordWithToken(requestParameters.idUser, requestParameters.requestUpdatePasswordWithToken, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Crea o modifica un usuario de la aplicación
     * @param {UserApiUserCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userCreate(requestParameters: UserApiUserCreateRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userCreate(requestParameters.requestUserCreate, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary obtiene la información de un usuario de la aplicación
     * @param {UserApiUserGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGet(requestParameters: UserApiUserGetRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userGet(requestParameters.user, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Envía la información del usuario
     * @param {UserApiUserInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userInfo(requestParameters: UserApiUserInfoRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userInfo(requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Devuelve la lista de usuario, de master actual
     * @param {UserApiUserListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userList(requestParameters: UserApiUserListRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userList(requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Realiza el cambio del master actual (no el defecto) del usuario
     * @param {UserApiUserMasterChangeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userMasterChange(requestParameters: UserApiUserMasterChangeRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userMasterChange(requestParameters.requestUserMasterChange, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Elimina un usuario del master actual
     * @param {UserApiUserMasterDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userMasterDelete(requestParameters: UserApiUserMasterDeleteRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userMasterDelete(requestParameters.user, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Devuelve la lista de master del usuario actual
     * @param {UserApiUserMasterListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userMasterList(requestParameters: UserApiUserMasterListRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userMasterList(requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Realiza el cambio de password de la aplicación
     * @param {UserApiUserPasswordChangeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userPasswordChange(requestParameters: UserApiUserPasswordChangeRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userPasswordChange(requestParameters.requestUserPassword, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Realiza el cambio de status del usuario
     * @param {UserApiUserResetFailedAttemptsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userResetFailedAttempts(requestParameters: UserApiUserResetFailedAttemptsRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userResetFailedAttempts(requestParameters.requestResetAttempts, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Realiza el cambio de status de usuario inactivo
     * @param {UserApiUserResetLastLoggedInRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userResetLastLoggedIn(requestParameters: UserApiUserResetLastLoggedInRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userResetLastLoggedIn(requestParameters.requestResetAttempts, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary modifica un usuario de la aplicación
     * @param {UserApiUserUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userUpdate(requestParameters: UserApiUserUpdateRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userUpdate(requestParameters.user, requestParameters.requestUserUpdate, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary modifica las organizaciones sobre las que tiene permiso el usuario de la aplicación
     * @param {UserApiUserUpdateOrganizationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userUpdateOrganization(requestParameters: UserApiUserUpdateOrganizationRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userUpdateOrganization(requestParameters.user, requestParameters.requestUserUpdateOrganization, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}

