/* tslint:disable */
/* eslint-disable */
/**
 * TMS Agnostiko
 * Tiene la definición de todos los servicios de TMS a Agnostic (para uso en la web o externo)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { Message } from '../models';
// @ts-ignore
import { TokenByLicenseItem } from '../models';
// @ts-ignore
import { TokenItem } from '../models';
/**
 * TokenApi - axios parameter creator
 * @export
 */
export const TokenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Eliminar un token
         * @param {string} brand 
         * @param {string} serial 
         * @param {number} version 
         * @param {string} app 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteToken: async (brand: string, serial: string, version: number, app: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brand' is not null or undefined
            assertParamExists('deleteToken', 'brand', brand)
            // verify required parameter 'serial' is not null or undefined
            assertParamExists('deleteToken', 'serial', serial)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('deleteToken', 'version', version)
            // verify required parameter 'app' is not null or undefined
            assertParamExists('deleteToken', 'app', app)
            const localVarPath = `/token/{brand}/{serial}`
                .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
                .replace(`{${"serial"}}`, encodeURIComponent(String(serial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (app !== undefined) {
                localVarQueryParameter['app'] = app;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Obtener un token
         * @param {string} brand 
         * @param {string} serial 
         * @param {number} version 
         * @param {string} app 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getToken: async (brand: string, serial: string, version: number, app: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brand' is not null or undefined
            assertParamExists('getToken', 'brand', brand)
            // verify required parameter 'serial' is not null or undefined
            assertParamExists('getToken', 'serial', serial)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('getToken', 'version', version)
            // verify required parameter 'app' is not null or undefined
            assertParamExists('getToken', 'app', app)
            const localVarPath = `/token/{brand}/{serial}`
                .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
                .replace(`{${"serial"}}`, encodeURIComponent(String(serial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (app !== undefined) {
                localVarQueryParameter['app'] = app;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Obtener token generados
         * @param {string} master 
         * @param {string} [app] 
         * @param {string} [brand] 
         * @param {string} [model] 
         * @param {string} [serial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listToken: async (master: string, app?: string, brand?: string, model?: string, serial?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'master' is not null or undefined
            assertParamExists('listToken', 'master', master)
            const localVarPath = `/token/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (master !== undefined) {
                localVarQueryParameter['master'] = master;
            }

            if (app !== undefined) {
                localVarQueryParameter['app'] = app;
            }

            if (brand !== undefined) {
                localVarQueryParameter['brand'] = brand;
            }

            if (model !== undefined) {
                localVarQueryParameter['model'] = model;
            }

            if (serial !== undefined) {
                localVarQueryParameter['serial'] = serial;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Obtener la cantidad tokens generados por licencia
         * @param {string} master 
         * @param {string} [app] 
         * @param {string} [brand] 
         * @param {string} [model] 
         * @param {string} [serial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTokenByLicense: async (master: string, app?: string, brand?: string, model?: string, serial?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'master' is not null or undefined
            assertParamExists('listTokenByLicense', 'master', master)
            const localVarPath = `/token/list/license`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (master !== undefined) {
                localVarQueryParameter['master'] = master;
            }

            if (app !== undefined) {
                localVarQueryParameter['app'] = app;
            }

            if (brand !== undefined) {
                localVarQueryParameter['brand'] = brand;
            }

            if (model !== undefined) {
                localVarQueryParameter['model'] = model;
            }

            if (serial !== undefined) {
                localVarQueryParameter['serial'] = serial;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TokenApi - functional programming interface
 * @export
 */
export const TokenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TokenApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Eliminar un token
         * @param {string} brand 
         * @param {string} serial 
         * @param {number} version 
         * @param {string} app 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteToken(brand: string, serial: string, version: number, app: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteToken(brand, serial, version, app, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TokenApi.deleteToken']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Obtener un token
         * @param {string} brand 
         * @param {string} serial 
         * @param {number} version 
         * @param {string} app 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getToken(brand: string, serial: string, version: number, app: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getToken(brand, serial, version, app, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TokenApi.getToken']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Obtener token generados
         * @param {string} master 
         * @param {string} [app] 
         * @param {string} [brand] 
         * @param {string} [model] 
         * @param {string} [serial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listToken(master: string, app?: string, brand?: string, model?: string, serial?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TokenItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listToken(master, app, brand, model, serial, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TokenApi.listToken']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Obtener la cantidad tokens generados por licencia
         * @param {string} master 
         * @param {string} [app] 
         * @param {string} [brand] 
         * @param {string} [model] 
         * @param {string} [serial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTokenByLicense(master: string, app?: string, brand?: string, model?: string, serial?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TokenByLicenseItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTokenByLicense(master, app, brand, model, serial, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TokenApi.listTokenByLicense']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TokenApi - factory interface
 * @export
 */
export const TokenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TokenApiFp(configuration)
    return {
        /**
         * 
         * @summary Eliminar un token
         * @param {TokenApiDeleteTokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteToken(requestParameters: TokenApiDeleteTokenRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.deleteToken(requestParameters.brand, requestParameters.serial, requestParameters.version, requestParameters.app, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Obtener un token
         * @param {TokenApiGetTokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getToken(requestParameters: TokenApiGetTokenRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.getToken(requestParameters.brand, requestParameters.serial, requestParameters.version, requestParameters.app, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Obtener token generados
         * @param {TokenApiListTokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listToken(requestParameters: TokenApiListTokenRequest, options?: AxiosRequestConfig): AxiosPromise<Array<TokenItem>> {
            return localVarFp.listToken(requestParameters.master, requestParameters.app, requestParameters.brand, requestParameters.model, requestParameters.serial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Obtener la cantidad tokens generados por licencia
         * @param {TokenApiListTokenByLicenseRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTokenByLicense(requestParameters: TokenApiListTokenByLicenseRequest, options?: AxiosRequestConfig): AxiosPromise<Array<TokenByLicenseItem>> {
            return localVarFp.listTokenByLicense(requestParameters.master, requestParameters.app, requestParameters.brand, requestParameters.model, requestParameters.serial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteToken operation in TokenApi.
 * @export
 * @interface TokenApiDeleteTokenRequest
 */
export interface TokenApiDeleteTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof TokenApiDeleteToken
     */
    readonly brand: string

    /**
     * 
     * @type {string}
     * @memberof TokenApiDeleteToken
     */
    readonly serial: string

    /**
     * 
     * @type {number}
     * @memberof TokenApiDeleteToken
     */
    readonly version: number

    /**
     * 
     * @type {string}
     * @memberof TokenApiDeleteToken
     */
    readonly app: string
}

/**
 * Request parameters for getToken operation in TokenApi.
 * @export
 * @interface TokenApiGetTokenRequest
 */
export interface TokenApiGetTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof TokenApiGetToken
     */
    readonly brand: string

    /**
     * 
     * @type {string}
     * @memberof TokenApiGetToken
     */
    readonly serial: string

    /**
     * 
     * @type {number}
     * @memberof TokenApiGetToken
     */
    readonly version: number

    /**
     * 
     * @type {string}
     * @memberof TokenApiGetToken
     */
    readonly app: string
}

/**
 * Request parameters for listToken operation in TokenApi.
 * @export
 * @interface TokenApiListTokenRequest
 */
export interface TokenApiListTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof TokenApiListToken
     */
    readonly master: string

    /**
     * 
     * @type {string}
     * @memberof TokenApiListToken
     */
    readonly app?: string

    /**
     * 
     * @type {string}
     * @memberof TokenApiListToken
     */
    readonly brand?: string

    /**
     * 
     * @type {string}
     * @memberof TokenApiListToken
     */
    readonly model?: string

    /**
     * 
     * @type {string}
     * @memberof TokenApiListToken
     */
    readonly serial?: string
}

/**
 * Request parameters for listTokenByLicense operation in TokenApi.
 * @export
 * @interface TokenApiListTokenByLicenseRequest
 */
export interface TokenApiListTokenByLicenseRequest {
    /**
     * 
     * @type {string}
     * @memberof TokenApiListTokenByLicense
     */
    readonly master: string

    /**
     * 
     * @type {string}
     * @memberof TokenApiListTokenByLicense
     */
    readonly app?: string

    /**
     * 
     * @type {string}
     * @memberof TokenApiListTokenByLicense
     */
    readonly brand?: string

    /**
     * 
     * @type {string}
     * @memberof TokenApiListTokenByLicense
     */
    readonly model?: string

    /**
     * 
     * @type {string}
     * @memberof TokenApiListTokenByLicense
     */
    readonly serial?: string
}

/**
 * TokenApi - object-oriented interface
 * @export
 * @class TokenApi
 * @extends {BaseAPI}
 */
export class TokenApi extends BaseAPI {
    /**
     * 
     * @summary Eliminar un token
     * @param {TokenApiDeleteTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public deleteToken(requestParameters: TokenApiDeleteTokenRequest, options?: AxiosRequestConfig) {
        return TokenApiFp(this.configuration).deleteToken(requestParameters.brand, requestParameters.serial, requestParameters.version, requestParameters.app, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Obtener un token
     * @param {TokenApiGetTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public getToken(requestParameters: TokenApiGetTokenRequest, options?: AxiosRequestConfig) {
        return TokenApiFp(this.configuration).getToken(requestParameters.brand, requestParameters.serial, requestParameters.version, requestParameters.app, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Obtener token generados
     * @param {TokenApiListTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public listToken(requestParameters: TokenApiListTokenRequest, options?: AxiosRequestConfig) {
        return TokenApiFp(this.configuration).listToken(requestParameters.master, requestParameters.app, requestParameters.brand, requestParameters.model, requestParameters.serial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Obtener la cantidad tokens generados por licencia
     * @param {TokenApiListTokenByLicenseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public listTokenByLicense(requestParameters: TokenApiListTokenByLicenseRequest, options?: AxiosRequestConfig) {
        return TokenApiFp(this.configuration).listTokenByLicense(requestParameters.master, requestParameters.app, requestParameters.brand, requestParameters.model, requestParameters.serial, options).then((request) => request(this.axios, this.basePath));
    }
}

