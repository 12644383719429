export const permisos = {
  "ROLES_MODULE": "Roles",
  "USERS_MODULE": "Usuarios",
  "ORGANIZATIONS_MODULE": "Organizaciones",
  "TERMINAL_MODULE": "Terminales",
  "GROUPS_MODULE": "Grupos",
  "APPS_MODULE": "Aplicaciones",
  "TEMPLATE_MODULE": "Plantillas",
  "MASTER_MODULE": "Master",
  "FIRMWARE_MODULE": "Firmware",
  "FOLIO_MODULE": "Folio",
  "AFFILIATION_MODULE": "Afiliaciones",
  "BUSINESS_MODULE": "Razon Social",
  "FILE_MODULE": "Archivos",
  "BANORTE_MODULE": "Banorte"
};