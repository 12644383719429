/* eslint-disable prettier/prettier */

import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '@ui5/webcomponents/dist/Dialog.js';
import '@vaadin/vaadin-select';
import { connect } from 'pwa-helpers';
import { ConfigConsole,PageFolio } from '../../redux/actions';
import {
  CommunicationTerminalApi,
  FolioApi,
  CommunicationTerminalApiRequestCommandRequest,
  RequestCommandTypeCommandEnum,
  Terminal,
  FolioApiUpdateFolioRequest,
  FolioApiCreateFolioRequest,
  RequestFolioUpdate
} from '../../../api';
import Swal from 'sweetalert2';
import store from '../../redux/store';
import { State } from '../../redux/types';
import { nombrePlantilla, descripcionPlantilla } from '../../redux/actions';
import { errorNotification } from '../../services/notifications';
import { apisConfigurator } from '../../utils/axios';

@customElement('editar-folio')
export class EditarFolio extends connect(store)(LitElement) {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private communicationApi: CommunicationTerminalApi;

  private folioApi: FolioApi;

  constructor() {
    super();

    this.communicationApi = new CommunicationTerminalApi(
      ...apisConfigurator('Comunicación')
    );
    this.folioApi = new FolioApi(...apisConfigurator('Folio'));
  }

  @property({ type: Object })
  terminal?: Terminal;

  @state()
  private titulo = '';


  @state()
  private description = '';

  @state()
  private Description = '';

  @state()
  private FolioId = '';

  @state()
  private AppId = '';

  @state()
  private folio:any = '';

  @state()
  private afiliacion = '';

  private get dialog() {
    return document.getElementById('editar-folio-descripcion')! as any;
  }

  protected firstUpdated(): void {}

  async getOrganizaciones() {}

  async open(folio: any) {
    if (!folio) {
      errorNotification('Error al obtener la app ');
      return;
    }

    this.getOrganizaciones();
    this.Description = folio[0].description;
    this.AppId = folio[0].appId;
    this.FolioId = folio[0].folioId;
    console.log(folio)
    this.folio = folio;
    this.dialog.open = true;
  }

  close() {
    this.dialog.open = false;
    this.terminal = undefined;
  }

  private async handleSubmit(e: Event) {

    e.preventDefault();

    let requestFolioUpdate: RequestFolioUpdate = {
      description: this.description
    }

    let data:FolioApiUpdateFolioRequest = {
      appId: this.AppId,
      folioId:this.FolioId,
      requestFolioUpdate
    }
    console.log(data)
    try {
      const res = await this.folioApi.updateFolio(data);
      console.log(res)
      if(res.status == 200){
        Swal.fire(
          'Completado!',
          'Folio actualizado.',
          'success' 
        )
      }
      location.reload();
    } catch (error) {
      console.log(error);
    }

    this.close();
  }

  protected render() {
    return html` <ui5-dialog
      id="editar-folio-descripcion"
      header-text="Editar Folio"
      class="w-3/12"
    >
      <div
        class="px-4 flex flex-col items-center"
      >
        <div class="mx-2 w-full flex flex-col items-center">

          <mwc-textfield
            class="my-2 w-3/4"
            outlined
            .value=${this.Description ?? ''}
            @change=${(e: any) => (this.description = e.target.value)}
            label="Descripcion"
          ></mwc-textfield>
        </div>

        <!--<div class="mx-2 w-full flex flex-col items-center">
            <mwc-textfield
            class="my-2 w-3/4"
            outlined
            @change=${(e: any) => (this.afiliacion = e.target.value)}
            label="Afiliacion"
            ></mwc-textfield>
        </div> -->

        <div slot="footer" class="dialog-footer">
          <button
            class="text-red-600 p-4 rounded-md uppercase"
            id="closeDialogButton"
            @click=${() => {
              this.close();
            }}
          >
            Cerrar
          </button>
          <button
            class="text-blue-600 p-4 rounded-md uppercase"
            id="closeDialogButton"
            @click=${(e: Event) => {
              this.handleSubmit(e);
            }}
          >
            Guardar
          </button>
        </div>
      </div>
    </ui5-dialog>`;
  }
}
