/* eslint-disable prettier/prettier */
import Swal from 'sweetalert2';

const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: toast => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

export function succesNotification(title = 'Exito') {
  Toast.fire({
    title,
    icon: 'success',
  });
}

export function errorNotification(title = 'Error') {
  Toast.fire({
    title,
    icon: 'error',
  });
}
