/* eslint-disable prettier/prettier */

import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '@ui5/webcomponents/dist/Dialog.js';
import '@vaadin/vaadin-select';
import { errorNotification, succesNotification } from '../../services/notifications';
import { apisConfigurator } from '../../utils/axios';
import '../CustomSelect/CustomSelect';
import {
  App,
  BrandList,
  ModelApi,
  VersionApi,
  VersionApiAddInstallerRequest,
} from '../../../api';
import store from '../../redux/store';
import { State } from '../../redux/types';
import { flatMarcas, modelosPorMaca } from '../../utils/functions';
import { CustomMultiSelect } from '../CustomMultiSelect/CustomMultiSelect';
import { CustomSelect } from '../CustomSelect/CustomSelect';

@customElement('agregar-instalador-modal')
export class AgregarInstaladorModal extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private modelApi: ModelApi;
  private versionApi: VersionApi;

  constructor() {
    super();
    this.modelApi = new ModelApi(...apisConfigurator('Agregar instalador'));
    this.versionApi = new VersionApi(...apisConfigurator('Agregar instalador'));
  }

  @property({ type: Object })
  app?: any;

  @state()
  private brandSelected?: string;

  @state()
  private loading = false;

  @state()
  private span = false;

  @state()
  private name:string = "";

  @state()
  private file?: File;

  @state()
  private appId:any = "";

  @state()
  private modelosMarcas: BrandList[] = [];

  private get dialog() {
    return document.getElementById('dialog-agregar-instalador')! as any;
  }

  private get botonInstalador() {
    return document.querySelector(
      '#seleccionar-instalador'
    )! as HTMLInputElement;
  }

  private get version() {
    return document.getElementById('versionSelect') as CustomSelect;
  }

  private get sistemaOperativo() {
    return document.getElementById('sistemaOperativo') as CustomSelect;
  }

  private get brand() {
    return document.getElementById('brand') as CustomSelect;
  }

  private get models() {
    return document.getElementById('models') as CustomMultiSelect<string>;
  }

  private async getMarcas() {
    const resModel = await this.modelApi.modelList({});
    this.modelosMarcas = resModel.data;
  }

  async open(app?: App, appId?:string) {
    if (!app) {
      errorNotification('Error al agregar instalador');
      return;
    }

    this.getMarcas();
    this.appId = appId;
    this.app = app;
    this.dialog.open = true;
  }

  close() {
    this.span=false;
    this.dialog.open = false;
    this.app = undefined;
  }

  protected firstUpdated(): void {
    const archivo = this.botonInstalador

    archivo.addEventListener('change', (e) => {
      console.log(this.botonInstalador!.files)
      if (this.botonInstalador!.files![0]) {
        // Subir el archivo al servidor
      } else {
        errorNotification("Error al cargar el archivo");
      }
      this.name = this.botonInstalador!.files![0].name;
      this.span = true ; 
    });
  }

  private async handleSubmit(e: Event) {
    e.preventDefault();
 

    this.file = this.botonInstalador!.files![0];

    if (!this.file ) {
      console.log(this.file)
      errorNotification('Error al agregar instalador');
      return;
    }

    const body: VersionApiAddInstallerRequest = {
      version: this.version.selected,
      brand: this.brand.selected,
      id: this.appId,
      os: this.sistemaOperativo.selected,
      installer: this.file,
      firmwares: ['*'],
      models: this.models.selected,
    };
    console.log(this.version);
    console.log("body",body);

    try {
      this.loading = true;
      const res = await this.versionApi.addInstaller(body);

      this.loading = false;
      console.log(res);
      if(res.status==200){
        succesNotification('Archivo registrado!')
      }
      this.close();
    } catch (error: any) {
      this.close();
      this.loading = false;
      console.log(error);
      console.log(error.message);
      errorNotification(error.message.toString());
    }
  }

  protected render() {
    return html` <ui5-dialog
      id="dialog-agregar-instalador"
      header-text="Agregar instalador a ${this.app?.name}"
      class="w-4/12 h-80"
    > 
      <div
        class="px-4 flex flex-col items-center h-full"
      >
        <div class="mx-2 w-full flex flex-col items-center relative">
          <div class="flex">
            <div class="w-[210px] h-16 relative mx-2 z-10">
              <custom-select
                label="Version"
                id="versionSelect"
                .options=${this.app?.version?.map((v:any) => ({
                  value: v.version,
                  label: v.version,
                }))}
              ></custom-select>
            </div>
            <div class="w-[210px] h-16 relative mx-2">
              <custom-select
                label="Marca"
                id="brand"
                .options=${flatMarcas(this.modelosMarcas).map(m => ({
                  label: m,
                  value: m,
                }))}
                @change=${(ev: CustomEvent) => {
                  this.brandSelected = ev.detail;
                }}
              ></custom-select>
            </div>
          </div>
          <div class="flex">
            <div class="w-[210px] h-16 relative mx-2">
              <custom-select
                label="Sistema Operativo"
                id="sistemaOperativo"
                .options=${[
                  { label: 'Todos', value: '*' },
                  { label: 'Android', value: 'Android' },
                  { label: 'Linux', value: 'Linux' },
                ]}
              ></custom-select>
            </div>
            <div class="w-[210px] h-16 relative mx-2">
              <custom-multi-select
                label="Modelos"
                id="models"
                .options=${this.brandSelected
                  ? [
                      { label: 'Cualquiera', value: '*' },
                      ...modelosPorMaca(
                        this.modelosMarcas,
                        this.brandSelected
                      ).map(m => ({
                        label: m.model,
                        value: m.model,
                      })),
                    ]
                  : []}
              ></custom-multi-select>
            </div>
          </div>
          <input
            id="seleccionar-instalador"
            type="file"
            hidden
            @change=${(ev: InputEvent) => {}}
          />
          <mwc-button
            class="rounded"
            label="Seleccionar instalador"
            icon="publish"
            outlined
            .disabled=${this.loading}
            @click=${async () => {
              await this.botonInstalador.click();
            }}
          >
          </mwc-button>
          ${ 
            this.span ? html`<span class="text-green-500">${this.name}</span>` : html``
          }

        </div>

        <div slot="footer" class="dialog-footer mt-auto">
          <button
            class="text-red-600 p-4 rounded-md uppercase"
            id="closeDialogButton"
            @click=${() => {
              this.close();
            }}
          >
            Cerrar
          </button>
          <button
            class="text-blue-600 p-4 rounded-md uppercase"
            id="closeDialogButton"
            @click=${async (E:Event) => {
              this.handleSubmit(E);
            }}
            .disabled=${this.loading}
          >
            Guardar
          </button>
        </div>
      </div>
    </ui5-dialog>`;
  }
}
