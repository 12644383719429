/* eslint-disable prettier/prettier */

import { LitElement, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import '@ui5/webcomponents/dist/Dialog.js';
import '@vaadin/vaadin-select';
import { TerminalList } from '../../../api/index.js';
import '@vaadin/vaadin-grid';
import '../../styles.css';
import { GroupApi } from '../../../api/index.js';
import store from '../../redux/store.js';
import { State } from '../../redux/types.js';

@customElement('detalles-modal')
export class DetallesTerminal extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }
  private groupApi: GroupApi;
  private grupos: TerminalList[] = [];

  constructor() {
    super();
    this.groupApi = new GroupApi();
  }

  private get dialog() {
    return document.getElementById('dialog-edit-organization')! as any;
  }
  @state()
  private detailsOpenedItem: TerminalList[] = [];

  async open(e: any) {
    console.log(e);
    const { token } = store.getState() as State;

    const terminal = await this.groupApi.groupGet(
      { id: e },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    if (terminal.data) {
      this.dialog.open = true;
      this.grupos = terminal.data.terminals;
      this.detailsOpenedItem = terminal.data.terminals;
      console.log(terminal.data);
    } else {
      close();
    }
  }

  close() {
    this.dialog.open = false;
  }

  protected render() {
    return html`
      ${!this.grupos.length
        ? html`
            <ui5-dialog
              id="dialog-edit-organization"
              header-text="No hay datos"
              class="Terminales"
            >
              <p>no hay datos</p>
            </ui5-dialog>
          `
        : html`
            <ui5-dialog
              id="dialog-edit-organization"
              header-text="Detalles de terminal"
              class="Terminales"
            >
              <vaadin-grid
                class="h-full"
                .items="${this.grupos}"
                .detailsOpenedItems="${this.detailsOpenedItem}"
              >
                <vaadin-grid-selection-column> </vaadin-grid-selection-column>
                <vaadin-grid-column path="brand"></vaadin-grid-column>
                <vaadin-grid-column path="model"></vaadin-grid-column>
                <vaadin-grid-column path="serial"></vaadin-grid-column>
                <vaadin-grid-column path="organization"></vaadin-grid-column>
                <vaadin-grid-column path="owner"></vaadin-grid-column>
              </vaadin-grid>

              <div slot="footer" class="dialog-footer">
                <button
                  class="text-red-600 p-4 rounded-md uppercase"
                  id="closeDialogButton"
                  @click=${() => {
                    this.close();
                  }}
                >
                  Cerrar
                </button>
              </div>
            </ui5-dialog>
          `}
    `;
  }
}
