/* eslint-disable prettier/prettier */
import { css, html, LitElement, PropertyValueMap } from 'lit';
import { customElement, state, query } from 'lit/decorators.js';
import { RequestUserPassword, User, UserApi } from '../../../api';
import { iniciarSesion } from '../../redux/actions';
import store from '../../redux/store';
import { State } from '../../redux/types';
import router from '../../router/router';
import { errorNotification,succesNotification } from '../../services/notifications';

@customElement('profile-page')
export class PerfilPage extends LitElement {
  private userApi!: UserApi;

  @state()
  user?: string;

  @state()
  usuarios: string[] = [];

  @query('#oldPass')
  oldPassInput!: HTMLInputElement;

  @query('#newPass')
  newPassInput!: HTMLInputElement;

  @query('#newPassConfirm')
  newPassConfirmInput!: HTMLInputElement;

  private get dialog() {
    return document.getElementById('dialog')! as any;
  }

  protected firstUpdated(): void {
    let cambioPassword:string = localStorage.getItem('cambioPassword') ?? "";
    if(cambioPassword==="true"){
      (document.getElementById('dialog') as any).open = true;
      succesNotification("Su contraseña ha caducado, se quiere restablecer una contraseña nueva!")
    }
    this.userApi = new UserApi();

    const { usuario } = store.getState() as State;

    this.user = usuario;

    this.getUsersList(); 
  }

  async getUsersList() {
    const response = await this.userApi.userMasterList({});

    this.usuarios = response.data;
    console.log(response.data);
  }

  async changeUserMaster(newMaster: string) {
    try {
      const response = await this.userApi.userMasterChange({
        requestUserMasterChange: { newMaster },
      });
      const { token, authorizedFunctions, name } = response.data;

      store.dispatch(iniciarSesion(token, authorizedFunctions, name));
      router.cambiarSeccion('');
    } catch (err) {
      console.log(err);
    }
  }

  async handleChangePass() {
    const regex = new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9]).{12,}$");
    if (this.newPassInput.value !== this.newPassConfirmInput.value) {
      errorNotification('Las contraseñas no coinciden');
      return;
    }else if(regex.test(this.newPassInput.value)){
      const requestUserPassword: RequestUserPassword = {
        oldPassword: this.oldPassInput.value,
        newPassword: this.newPassInput.value,
      };
      this.dialog.open = false;
  
      try {
        const response = await this.userApi.userPasswordChange({
          requestUserPassword,
        });
  
        console.log(response);
        (document.getElementById('dialog') as any).open = false;
        this.oldPassInput.value = '';
        this.newPassInput.value = '';
      } catch (err) {}
    }else{
      errorNotification('Debe contener al menos una letra, 1 numero y ser mayor a 12 caracteres');
      return;
    }
    if(regex.test(this.newPassInput.value)){
      console.log()
    }else{
      errorNotification('Debe contener al menos 1 caracter Mayuscula y minimo 8 caracteres');
      return;
    }
  }

  render() {
    return html`
      <main class="w-full min-h-full">
        <div class="w-full px-5 py-4 flex flex-wrap items-start">
          <section
            class="w-full h-fit flex overflow-auto mb-2 bg-white shadow-md rounded-md py-5 px-4 border border-solid border-slate-300"
          >
            <div class="w-full">
              <div class="flex justify-between">
                <div class="flex">
                  <mwc-icon class="icon-primary icon-profile"
                    >account_circle</mwc-icon
                  >
                  <div class="flex flex-col ml-2">
                    <h1 class="text-3xl text-blue-600 font-semibold">Perfil</h1>
                    <h3 class="text-lg text-gray-600 font-semibold">
                      ${this.user}
                    </h3>
                    <!-- <h4 class="text-lg text-gray-600"><b>Organizacion</b>: Apps2Go</h4> -->
                  </div>
                </div>
                <div class="justify-self-end">
                  <button
                    class="rounded-md text-white bg-blue-600 px-4 py-2 uppercase hover:shadow-md hover:bg-blue-700"
                    .onclick=${() => {
                      (document.getElementById('dialog') as any).open = true;
                    }}
                  >
                    Cambiar contraseña
                  </button>
                </div>
              </div>
            </div>
          </section>
          
          <section
            class="w-9/12 pr-4 h-40"
          >
            <div class="w-full h-full bg-white shadow-md rounded-md py-5 px-4 border border-solid border-slate-300">
            </div>
          </section>

          <section
            class="w-3/12 pl-4"
          >
            <div class="w-full h-full bg-white shadow-md rounded-md py-5 px-4 border border-solid border-slate-300">
              <h3 class="text-xl text-blue-600">Usuarios</h3>
              <mwc-list>
                ${this.usuarios.map(
                  user => html`
                    <mwc-list-item hasMeta>
                      <p style="color: black">${user}</p>
                      <mwc-icon-button
                        style="--mdc-icon-button-size: 24px;"
                        class="text-blue-600"
                        slot="meta"
                        icon="sync"
                        @click="${() => {
                          this.changeUserMaster(user);
                        }}"
                      ></mwc-icon-button>
                    </mwc-list-item>
                  `
                )}
              </mwc-list>
            </div>
          </section>
        </div>
      </main>
        <ui5-dialog
          id="dialog"
          header-text="Cambiar contraseña"
          class="w-4/12"
          draggable="true"
        >
          <div class="px-4 flex flex-col items-center">

          <div class="mx-2 ">
          <mwc-textfield
                required
                    class="rounded w-80 m-2"
                      validationMessage="La contraseña debe tener al menos 8 caracteres"
                    label="Contraseña antigua"
                    type="password"
                    autoValidate
                    id="oldPass"
                    outlined>
                </mwc-textfield>
                </div>
          <div class="mx-2">
          <mwc-textfield
                required 
                    class="rounded w-80 m-2"
                      validationMessage="La contraseña debe tener al menos 8 caracteres"
                    label="Nueva contraseña"
                    type="password"
                      minLength="8"
                      autoValidate
                    id="newPass"
                    outlined>
                </mwc-textfield>
                </div>
          <mwc-textfield
                required
                    class="rounded w-80 m-2"
                      validationMessage="La contraseña debe tener al menos 8 caracteres"
                    label="Confirmar Contraseña"
                    type="password"
                      minLength="8"
                      autoValidate
                    id="newPassConfirm"
                    outlined>
                </mwc-textfield>
                </div>

          <div slot="footer" class="dialog-footer">
            <button
              class="text-red-600 p-4 rounded-md uppercase"
              id="closeDialogButton"
              @click=${() => {
                (document.getElementById('dialog') as any).open = false;
              }}
            >
              Cerrar
            </button>
            <button
              class="text-blue-600 p-4 rounded-md uppercase"
              id="closeDialogButton"
              type="submit"
              @click=${() => {
                this.handleChangePass();
                //(document.getElementById('dialog') as any).open = false;
              }}
            >
              Confirmar
            </button>
          </div>
        </form>
        </ui5-dialog>
    `;
  }

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  static get styles() {
    return css``;
  }
}
