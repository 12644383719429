import { TemplateInfo } from '../../../api';
import { ActionTypes } from '../types';

export const PLANTILLA = 'PLANTILLA';
export const AGREGAR_PLANTILLA = 'AGREGAR_PLANTILLA';
export const ELIMINAR_PLANTILLA = 'ELIMINAR_PLANTILLA';
export const DESCRIPCION_PLANTILLA = 'DESCRIPCION_PLANTILLA';
export const ID_PLANTILLA = 'ID_PLANTILLA';
export const NOMBRE_PLANTILLA = 'NOMBRE_PLANTILLA';
export const GET_TEMPLATES = 'GET_TEMPLATES';
export const TEMPLATES_RECEIVED = 'TERMPLATES_RECEIVED';
export const TEMPLATE_ADDED = 'TEMPLATE_ADDED';
export const CLEAN_TEMPLATE = 'CLEAN_TEMPLATE';
//export const LIST_PLANTILLA = 'LIST_PLANTILLA';

export function clearTerminal(): ActionTypes {
  return { type: CLEAN_TEMPLATE };
}

export function getTemplates(): ActionTypes {
  return { type: GET_TEMPLATES, loading: true };
}

export function templateAdded(newTemplate: TemplateInfo): ActionTypes {
  return { type: TEMPLATE_ADDED, newTemplate };
}

export function Plantilla(plantilla: Object[]): ActionTypes {
  return { type: PLANTILLA, plantilla };
} 

/*export function ListPlantilla(listPlantilla: Object[]): ActionTypes {
  return { type: LIST_PLANTILLA, listPlantilla };
}*/

export function agregarPlantilla(plantilla: Object[]): ActionTypes {
  return { type: AGREGAR_PLANTILLA, plantilla };
}

export function eliminarPlantilla(plantilla: Object[]): ActionTypes {
  return { type: ELIMINAR_PLANTILLA, plantilla };
}

export function descripcionPlantilla(
  descripcionPlantilla: string
): ActionTypes {
  return { type: DESCRIPCION_PLANTILLA, descripcionPlantilla };
}

export function idPlantilla(idPlantilla: string): ActionTypes {
  return { type: ID_PLANTILLA, idPlantilla };
}

export function nombrePlantilla(nombrePlantilla: string): ActionTypes {
  return { type: NOMBRE_PLANTILLA, nombrePlantilla };
}
