/* eslint-disable prettier/prettier */
/* eslint-disable class-methods-use-this */

import { Router } from '@vaadin/router';

import { PATHS } from './routes';

/** Implementa la navegación Single-Page en la aplicación. */
class AppRouter {
  /** Router interno del módulo. */
  private router!: Router;

  /**
   * Determina si el router terminó de renderizar y está listo para responder.
   * @returns - Promesa que se resuelve con la ruta actual cuando el router termina de renderizar.
   */
  get ready(): Promise<Router.Location> {
    return this.router.ready;
  }

  get url(): string {
    return window.location.href;
  }

  set url(url: string) {
    window.history.pushState(null, '', url);
  }

  /** Array con las rutas que conforman la URL actual. */
  get rutasArray(): Array<string> {
    const rutas: Array<string> = [];
    this.router.location.routes.forEach(ruta => {
      rutas.push(ruta.path.replace('/', ''));
    });
    return rutas;
  }

  /** Array con los parámetros de la ruta actual. */
  get parametrosDeRuta(): any {
    const parametros = this.router.location.params;
    return parametros;
  }

  /** Parte de la URL que contiene los parámetros de búsqueda. Por ejemplo: "?marca=idMarca&categoria=idCat" */
  get busqueda(): string {
    return window.location.search;
  }

  /**
   * Extrae el valor de un parámetro de búsqueda pasado a través de la URL.
   * @param parametro - Nombre del parámetro cuyo valor se desea obtener.
   * @returns - Valor del parámetro pasado en la URL o un string vacío si no se encontró.
   */
  getParametroDeBusqueda(parametro: string): string | undefined {
    return new URLSearchParams(this.busqueda).get(parametro) ?? undefined;
  }

  /** Setea el valor a un parámetro de la URL.
   * @param parametro - Nombre del parámetro de la URL a actualizar.
   * @param valor - Valor de texto para asignar al parámetro.
   */
  setearParametroDeBusqueda(parametro: string, valor: string | undefined) {
    const nuevaUrl = new URL(this.url);
    nuevaUrl.searchParams.set(parametro, valor ?? '');
    this.url = nuevaUrl.href;
  }

  /** Elimina un parámetro de búsqueda pasado a través de la URL.
   * @param parametro - Nombre del parámetro de la URL a eliminar.
   */
  eliminarParametroDeBusqueda(parametro: string) {
    const nuevaUrl = new URL(this.url);
    nuevaUrl.searchParams.delete(parametro);
    this.url = nuevaUrl.href;
  }

  /**
   * Inicializa el módulo realizando las configuraciones necesarias.
   * @param vista - Elemento HTML donde se quieren renderizar las vistas de la app.
   */
  async init(vista: HTMLElement) {
    this.router = new Router(vista);
    this.router.setRoutes(PATHS);
  }

  /** Cambia la sección mostrada por el router.
   * @param seccion - Sección principal de la app que se quiere mostrar.
   */
  cambiarSeccion(seccion: string) {
    this.irA(`/${seccion}`);
  }

  /** Cambia a una ruta específica del router.
   * @param ruta - Ruta a la que se quiere navegar.
   */
  irA(ruta: string) {
    Router.go(ruta);
    window.scrollTo(0, 0);
  }
}

export default new AppRouter();
