/* eslint-disable prettier/prettier */
import { html, LitElement, render } from 'lit';
import { guard } from 'lit/directives/guard.js';
import { customElement, state } from 'lit/decorators.js';
import Swal from 'sweetalert2';
import { connect } from 'pwa-helpers';
import '@ui5/webcomponents/dist/Tree.js';
import '@ui5/webcomponents/dist/Icon.js';
import '@ui5/webcomponents-icons/dist/it-instance.js';
import '@ui5/webcomponents-icons/dist/group-2.js';
import '@ui5/webcomponents-icons/dist/alert.js';
import '@ui5/webcomponents-icons/dist/cart.js';
import '@ui5/webcomponents-icons/dist/bell.js';
import '@material/mwc-icon';
import '@material/mwc-icon-button';
import '@vaadin/vaadin-grid'; 
import { State } from '../../redux/types';
//import './TerminalList.css';
import {
  Grid,
  GridActiveItemChangedEvent,
  GridItemModel,
} from '@vaadin/vaadin-grid';
import { AxiosResponse } from 'axios';

import '../../components/modals/EditOrganizationModal';
import router from '../../router/router.js';
import {
  CommunicationTerminalApi,
  CommunicationTerminalApiRequestCommandRequest,
  TerminalApi,
  OrganizationApi,
  FolioApiGetFolioRequest
} from '../../../api/api.js';
import {
    ConfigurationApi,
    ConfigurationApiCreateConfigurationRequest,
    AppApi,
    AppList,
    TemplateApi,
    RequestFolioCreate,
    FolioApiCreateFolioRequest,
    SavedConfigurationApi,
    FolioApi,
    FolioApiListFolioRequest,
    ResponseTemplateGet,
    RequestFolioPatch,
    FolioApiCleanFolioRequest
  } from '../../../api';
import {
  RequestCommandTypeCommandEnum,
  Terminal,
  TerminalList,
} from '../../../api/index.js';
import store from '../../redux/store.js';
import { ConfigConsole,PageFolio, getApps } from '../../redux/actions';
import { editarTerminal } from '../../redux/actions/index.js';
import { ListaAppModal } from '../../components/modals/ListaAppModal';
import { PushConfigModal } from '../../components/modals/PushConfigurationsDialog';
import '../../components/modals/PushConfigurationsDialog';
import { UbicacionTerminalMapa } from '../../components/modals/UbicacionTerminalMapa';
import '../../components/modals/ListaAppModal';
import { CrearFolio } from '../../components/modals/CrearFolio';
import '../../components/modals/CrearFolio';
import { ListadoApp } from '../../components/modals/ListadoApp';
//import { CambiarMaster } from '../../components/modals/CambiarMaster';
import '../../components/modals/ListadoApp';
import { ListadoFolio } from '../../components/modals/ListadoFolio';
import '../../components/modals/ListadoFolio';
import { ConfigTerminalFolio } from '../../components/ConfigTerminalFolio';
import '../../components/ConfigTerminalFolio';
import '../../components/modals/UbicacionTerminalMapa';
import { EditOrganizationModal } from '../../components/modals/EditOrganizationModal.js';
import { apisConfigurator } from '../../utils/axios';
import { EnviarMensajeModal } from '../../components/modals/EnviarMensajeModal';
import '../../components/modals/EnviarMensajeModal';
import { HistorialComandosModal } from '../../components/modals/HistorialComandosModal';
import '../../components/modals/HistorialComandosModal';
import { ListadoPlantillaExportacion } from '../../components/modals/ListadoPlantillaExportacion';
import '../../components/modals/ListadoPlantillaExportacion';

import { ConfigTerminalFolio_new } from '../../components/modals/ConfigTerminalFolio_new';
import '../../components/modals/ConfigTerminalFolio_new';
import { EditarFolio } from '../../components/modals/EditarFolio';
import '../../components/modals/EditarFolio';
import {
  iterarCampoJson2,
  deleteCamp
} from '../../utils/functions';

import {
  errorNotification,
  succesNotification,
} from '../../services/notifications';
import { getTerminals } from '../../redux/actions/terminals';
import { Unsubscribe } from '@reduxjs/toolkit';

@customElement('folio-list')
export class FolioList extends connect(store)(LitElement) {
    private appApi: AppApi;
  private terminalApi: TerminalApi;
  private templateApi: TemplateApi;
  private communicationApi: CommunicationTerminalApi;
  private savedConfigurationApi: SavedConfigurationApi;
  private organizationApi!: OrganizationApi;
  private folio: FolioApi;

  private storeUnsuscriber?: Unsubscribe;

  get enviarMensajeModal() {
    return document.getElementById('enviarMensajeModal') as EnviarMensajeModal;
  }

  get listadoPlantillaExportacion() {
    return document.getElementById('listadoPlantillaExportacion') as ListadoPlantillaExportacion;
  }

  get editarFolio() {
    return document.getElementById('editarFolio') as EditarFolio;
  }

  get listadoApps() {
    return document.getElementById('listadoApp') as ListadoApp;
  }

  get appSelected() {
    return document.getElementById('appSelect') as any;
  }

  get listadoFolio() {
    return document.getElementById('listadoFolio') as ListadoFolio;
  }

  get configFolio() {
    return document.getElementById('configFolio') as ConfigTerminalFolio_new
  }

  get historialComandosModal() {
    return document.getElementById(
      'historialComandosModal'
    ) as HistorialComandosModal;
  }

  @state()
  private terminals: TerminalList[] = [];

  private constructor() {
    super();
    this.templateApi = new TemplateApi(...apisConfigurator('Templates'));
    this.terminalApi = new TerminalApi(...apisConfigurator('Terminales'));
    this.communicationApi = new CommunicationTerminalApi(
      ...apisConfigurator('Terminales')
    );
    this.savedConfigurationApi = new SavedConfigurationApi(...apisConfigurator('saved configuration'));
    this.organizationApi = new OrganizationApi(
      ...apisConfigurator('Organización')
    );
    this.appApi = new AppApi(...apisConfigurator('Aplicaciones'));
    this.folio = new FolioApi(
      ...apisConfigurator('Folio')
    );
  }
  async stateChanged(state: any) {
    if(state.configConsole.band && this.band){
      console.log(state.configConsole)
      await this.getApp(this.appId,state.configConsole.crearFolio)
      this.listadoFolio.open(this.app)
      this.ObtenerFolios(this.appId)
      this.band = false;
    }
  }

  async ObtenerTodo(){
    this.templatesInfo = []
    this.objectoBand = [];
    let idPlantilla = ""
    let appTemplates = this.app.templates ?? [];
    let selectedPlantillas = appTemplates.map((plantilla:any) => plantilla);
    appTemplates.forEach(async (templateId:any) => {
      idPlantilla = templateId;
      let dataSet:any = []
      await this.savedConfigurationApi.listSavedConfiguration({templateId:templateId})
      .then(response => response.data)
      .then(async (data:any) => {
        const templateInfo:any = await this.getTemplate(data[0].templateId);
        const data2 = await this.savedConfigurationApi.getSavedConfiguration({
          id:data[0].id
        });
        return {
          nameConfiguration:data[0].name,
          idConfiguration:data[0].id,
          configuration:data2.data,
          templateInfo:templateInfo.name,
          templateId
        }
      }).then(response => response)
      .then((data:any) => {
        console.log("DATA",data)
        this.objectoBand.push(data);
      });
    })
  }

  @state()
  private apps: AppList[] = [];

  @state()
  termi?: Terminal;

  @state()
  objectoBand:Object[] = [];

  @state()
  folioList?: any;

  @state()
  final_vals: any[] = [];

  @state()
  folioItem?: any;

  @state()
  app?: any;

  @state()
  band?: any = false;

  @state()
  appId?: any;

  @state()
  NameBtn = 'Crear Terminal';

  @state()
  templatesInfo:any = [];

  @state()
  template:any = 'Crear Terminal';

  @state()
  detail:boolean = false;

  @state()
  detail2:boolean = false;

  @state()
  private loading = false;

  @state()
  organizacion = '';

  @state()
  private detailsOpenedItem: FolioList[] = [];

  @state()
  loader = true;

  @state()
  configuration: any;

  @state()
  brand = '';

  @state()
  serial = '';

  get editOrganizationModal() {
    return document.getElementById(
      'edit-organization-modal'
    ) as EditOrganizationModal;
  }

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  disconnectedCallback(): void {
    this.storeUnsuscriber && this.storeUnsuscriber();
  }

  private async handleDeleteTerminal(terminal: Terminal) {
    try {
      const res = await this.terminalApi.terminalDelete({
        brand: terminal.brand,
        serial: terminal.serial,
      }); 

      this.terminals = this.terminals.filter(t => t.serial !== terminal.serial);
      this.termi = undefined;
    } catch (err) {}
  }

  async updated(){
    if (localStorage.getItem("folioState")) {
      const appSelect = this.appSelected;
      const optionValue:any = localStorage.getItem("folioState");

      const optionElement = appSelect.querySelector(`option[value="${optionValue}"]`);
      optionElement.selected = true;
      this.detail2=true;
    } 
  }

  protected async firstUpdated(){
    this.getTerminals();
    this.getApps();
    if (localStorage.getItem("folioState")) {
      const appSelect = this.appSelected;
      const optionValue:any = localStorage.getItem("folioState");
      this.appId = optionValue;
      this.ObtenerFolios(optionValue)
      this.getApp(optionValue,"")
      this.detail2=true;
    } 
    this.detail = true;
  }
 
  async getTerminals() {
    store.dispatch(getTerminals());
    this.storeUnsuscriber = store.subscribe(() => {
      const { list, loading, listApps } = store.getState();
      console.log(loading);
      console.log({ list });
      this.terminals = list as TerminalList[];
      this.loader = loading;
    });
  }

  private actionRenderer = (root: HTMLElement, _: any, item: any) => {
    render(
      html`
        <mwc-icon-button
          @click=${() => router.cambiarSeccion('terminal')}
          icon="visibility"
        ></mwc-icon-button>
        <mwc-icon-button
          icon="edit"
          @click="${() => {
            store.dispatch(editarTerminal(item.item));
            router.cambiarSeccion('edit-terminal');
          }}"
        ></mwc-icon-button>
        <mwc-icon-button
          icon="corporate_fare"
          @click="${() => {
            store.dispatch(editarTerminal(item.item));
            this.editOrganizationModal.open();
          }}"
        ></mwc-icon-button>
        <mwc-icon-button
          icon="delete"
          @click="${() => {
            this.handleDeleteTerminal(item.item);
          }}"
        ></mwc-icon-button>
        <mwc-icon-button icon="more_vert"></mwc-icon-button>
      `,
      root
    );
  };

  edit(e: any) {
    store.dispatch(editarTerminal(e));
    router.cambiarSeccion('edit-terminal');
  }

  get ListarAplicacionesModal() {
    return document.getElementById('listaAppModal')! as ListaAppModal;
  }

  get CrearFolio() {
    return document.getElementById('crearFolio')! as CrearFolio;
  }

  get pushConfigModal() {
    return document.getElementById('pushConfigModal')! as PushConfigModal;
  }

  private get seleccionarArchivo() {
    return document.getElementById('seleccionar-archivo') as any;
  }


  get UbicarTerminalMapa() {
    return document.getElementById(
      'ubicacionTerminalMapa'
    )! as UbicacionTerminalMapa;
    //return document.getElementById('listaAppModal')! as ListaAppModal;
  }

  async rebootTerminal(terminal: Terminal) {
    const request: CommunicationTerminalApiRequestCommandRequest = {
      brand: terminal.brand,
      serial: terminal.serial,
      requestCommand: {
        commandId: `reboot-${terminal.serial}-${Date.now()}`,
        typeCommand: RequestCommandTypeCommandEnum.Reset,
      },
    };

    const res = await this.communicationApi.requestCommand(request);

    //console.log(res);
  }

  private async pushConfig(terminal: any) {
    console.log('terminal', terminal);
    if (!terminal) return;
    const requestCommand: CommunicationTerminalApiRequestCommandRequest = {
      brand: terminal.brand,
      serial: terminal.serial,
      requestCommand: {
        commandId: `pushConfig-${terminal.serial}-${Date.now()}`,
        typeCommand: RequestCommandTypeCommandEnum.UpdateAppConfig,
        parameters: ['20fa74aa-403c-40a3-9047-1699b236bfae'],
      },
    };
    console.log(requestCommand);
    try {
      console.log('empezar');
      const res = await this.communicationApi.requestCommand(requestCommand);
      console.log('envio PETICION res', res);
      succesNotification('Comando ejecutado con exito!');
    } catch (err: any) {
      console.log('envio mensaje err', err);
    }
  }
 
  async ObtenerOrganizacion(id: string) {
    await this.organizationApi
      .organizationGet({ id })
      .then(response => response)
      .then(data => {
        this.organizacion = data.data.name;
      });
  }

  async ObtenerFoliosPorUsar(app: any) {
    let data:FolioApiListFolioRequest = {
      appId: app.id,
      used:false
    }
    await this.folio
      .listFolio(data)
      .then(response => {
        console.log(response.data.length)
        return response.data;
      })
      .then(data => {
        this.folioList = data;

      });
  }

  private async refrescarInfo(terminal: any) {
    console.log('terminal', terminal);
    if (!terminal) return;
    const requestCommand: CommunicationTerminalApiRequestCommandRequest = {
      brand: terminal.brand,
      serial: terminal.serial,
      requestCommand: {
        commandId: `displayMessage-${terminal.serial}-${Date.now()}`,
        typeCommand: RequestCommandTypeCommandEnum.SendInformation,
        parameters: [],
      },
    };
    console.log(requestCommand);
    try {
      console.log('empezar');
      const res = await this.communicationApi.requestCommand(requestCommand);
      console.log('envio PETICION res', res);
      succesNotification('Comando ejecutado con exito!');
    } catch (err: any) {
      console.log('envio mensaje err', err);
    }
  }

  async toggleBluetooth() {
    const request: CommunicationTerminalApiRequestCommandRequest = {
      brand: this.termi!.brand,
      serial: this.termi!.serial,
      requestCommand: {
        commandId: `bluetooth-${this.termi?.serial}-${Date.now()}`,
        typeCommand:
          this.termi?.generalInformation?.bluetoothEnable.value === '1'
            ? RequestCommandTypeCommandEnum.DeactivateBluetooth
            : RequestCommandTypeCommandEnum.ActivateBluetooth,
      },
    };

    const res = await this.communicationApi.requestCommand(request);
    if (!this.termi) return;
    if (this.termi.generalInformation.bluetoothEnable.value === '1') {
      this.termi.generalInformation.bluetoothEnable.value = '0';
    } else {
      this.termi.generalInformation.bluetoothEnable.value = '1';
    }
    //console.log(res);
  }

  async toggleWifi() {
    const request: CommunicationTerminalApiRequestCommandRequest = {
      brand: this.termi!.brand,
      serial: this.termi!.serial,
      requestCommand: {
        commandId: `wifi-${this.termi?.serial}-${Date.now()}`,
        typeCommand:
          this.termi?.generalInformation?.wifiEnable.value === '1'
            ? RequestCommandTypeCommandEnum.DeactivateWifi
            : RequestCommandTypeCommandEnum.ActivateWifi,
      },
    };

    const res = await this.communicationApi.requestCommand(request);
    console.log(res)
    if (!this.termi) return;
    if (this.termi.generalInformation.wifiEnable.value === '1') {
      this.termi.generalInformation.wifiEnable.value = '0';
    } else {
      this.termi.generalInformation.wifiEnable.value = '1';
    }

    //console.log(res);
  }

  async getApps() {
    store.dispatch(getApps());
    this.storeUnsuscriber = store.subscribe(() => {
      const { list, loading, listApps } = store.getState();
      console.log(loading); 
      console.log("list",{ listApps });
      this.apps = listApps as AppList[];
      //this.loading = loading;
    });
  }

  async ObtenerFolios(appId: any) {
    let data:FolioApiListFolioRequest = {
      appId: appId,
    }
    await this.folio
      .listFolio(data)
      .then(response => response.data)
      .then(data => {
        this.folioList = data;
        console.log(data)
      });
  }

  async getTerminalDetail(terminal: TerminalList) {
    const res = await this.terminalApi.terminalGet({
      brand: terminal.brand,
      serial: terminal.serial,
    });
    this.termi = res.data;
    //console.log(res.data);
  }
 
  async getTemplate(id: any): Promise<ResponseTemplateGet | null> {
    //this.loader = true;
    try {
      const resTer = await this.templateApi.getTemplate({ id });

      //this.loader = false;
      return resTer.data;
    } catch (error) {
      return null;
    }
  }

  async VaciarFolio(folio: any) {
    let requestFolioPatch:RequestFolioPatch = {
      clean:true
    }
    let data:FolioApiCleanFolioRequest = {
      appId: folio[0].appId,
      folioId: folio[0].folioId,
      requestFolioPatch
    }
    console.log(data)
    await this.folio
      .cleanFolio(data)
      .then(response => response.data)
      .then(data => {
        console.log(data)
        succesNotification('Folio Vaciado!')
      });
  }

  async getConfigurations(id:string) {
    const { configConsole } = store.getState() as State;
    //console.log(configConsole.templateId);
    let JsonSchema;
    let templateName;
    let prueba;
    try { 
      await this.templateApi
      .getTemplate({ id })
      .then(response => response.data)
      .then(data => {
        console.log("DATA",data)
        this.configuration = data;
        JsonSchema = data.schema;
        templateName = data.name;
        const properties = JsonSchema.properties;
        configConsole.JsonSchema = JsonSchema;
        console.log("properties",properties);
        const prueba = deleteCamp(properties);

        const objecto1 = configConsole;
        const objecto2 = {
          templateId: id,
          JsonSchema,
          templateName
        };
        Object.assign(objecto1, objecto2);
        store.dispatch(
          ConfigConsole({
            templateId: id,
            JsonSchema,
            properties: prueba,
            ...configConsole,
          })
        ); 

      });
      const folioApiGetFolioRequest:FolioApiGetFolioRequest = {
        appId:configConsole.folio.appId,
        folioId:configConsole.folio.folioId
      }
      const res = await this.folio.getFolio(folioApiGetFolioRequest)
      let datosFolio =  res.data;
      let controlador:boolean;
      if(datosFolio.templates?.length){
        controlador = true;
      }else{
        controlador = false;
      }
      const objecto1 = configConsole;
      const objecto2 = { existConfig: controlador,page:3 }; 
      Object.assign(objecto1, objecto2);
        console.log("CONFIGURATION",true)
      store.dispatch(
        ConfigConsole({ 
          existConfig: objecto1,
          templateId: id,
          JsonSchema,
          properties: prueba,
          ...configConsole,
        })
      );
      
    } catch (error) {
      console.log(error);
    }finally{
      //PageFolio(3);
      const { configConsole } = store.getState() as State;
      console.log("config",configConsole)
    }
  }

  private get botonCsv() {
    return document.querySelector('#seleccionar-archivo')! as HTMLInputElement;
  }

  async getApp(appId: any,folio:any) {
    this.templatesInfo= []
    const { configConsole } = store.getState() as State;
    try {
      const res = await this.appApi.getApp({ id: appId });
      this.app = res.data;
      const app = res.data;
      this.template = app.templates;
      this.template.forEach(async (templateId:string) => {
        const templateInfo = await this.templateApi.getTemplate({id:templateId});
        console.log("PROBANDO TEMPLATE",templateInfo.data)
        this.templatesInfo.push({
          id:templateId,
          schema:templateInfo.data
        })
        console.log("PROBANDO INFO",this.templatesInfo)
      });

      const objecto1 = configConsole;
      const objecto2 = { appId, app,folio };
      configConsole.page=2;
      Object.assign(objecto1, objecto2);
      store.dispatch(
        ConfigConsole({ 
          appId,
          app,
          folio,
          ...configConsole,
        })
      );
    } catch (err) {
      //errorNotification('Error al obtener la app');
      console.log(err);
    } finally {    
      //this.band = -1;
      this.ObtenerTodo();
    }
  }

  render() {
    return html`
      <main
        class="w-full h-full FondoColor flex  ${!this.loader
          ? ''
          : 'items-center justify-center'}"
      >
        ${this.loader !== true
          ? html`
              <section
                class="w-3/4 relative flex flex-wrap p-4 SectionList rounded-l-lg"
              >
              <select name="cars" class="w-[210px] mx-1 mb-3 bg-white border border-gray-300 rounded-md text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white" id="appSelect"
                @change=${(ev: CustomEvent) => {
                  console.log(this.appSelected.value)
                  this.appId = this.appSelected.value;
                  localStorage.removeItem('affiliationState');
                  localStorage.setItem('folioState', this.appId);
                  localStorage.removeItem('appId');
                  localStorage.setItem('appId',this.appId);
                  this.getApp(this.appSelected.value,"")
                  this.ObtenerFolios(this.appSelected.value)
                  if (this.detail2 === false) {
                    this.detail2 = true;
                  } else if (this.detail === true) {
                    this.detail2 = false; 
                  }
                }}>
                    <option disabled selected>Aplicaciones</option>
                  ${this.apps.map((app:any,index:number)=> html`
                      <option value="${app.id}">${app.name}</option>
                  `)}
              </select> 
              <!-- <div class="mx-2 TexField">
                  <custom-select
                    label="Aplicaciones"
                    id="apps"
                    .options=${this.apps.map(m => ({
                      label: m.name,
                      value: m.id,
                    }))}
                    @change=${(ev: CustomEvent) => {
                      //this.brandSelected = ev.detail;
                      console.log(ev.detail)
                      this.appId = ev.detail;
                      this.getApp(ev.detail,"")
                      this.ObtenerFolios(ev.detail)
                    }}
                  ></custom-select> -->
                </div>
                <!-- <div id='editorHolder'></div> -->
                <vaadin-grid
                  class="w-screen h-full"
                  .items="${this.folioList}"
                  .detailsOpenedItems="${this.detailsOpenedItem}"
                  @active-item-changed="${( 
                    e: GridActiveItemChangedEvent<FolioList>
                  ) => { 
                    this.folioItem = e.detail.value;
                    this.detailsOpenedItem = [e.detail.value];
                    console.log(e.detail.value)
                    console.log("DETAIL",this.detail)
                        if (this.detail === false) {
                          this.detail = true;
                        } else if (this.detail === true) {
                          this.detail = false; 
                        }
                  }}"
                  .rowDetailsRenderer="${guard(
                    [],
                    () =>
                      (
                        root: HTMLElement,
                        _: Grid,
                        model: GridItemModel<FolioList>
                      ) => {
                        console.log(model.item)
                        render(
                          html`<vaadin-form-layout
                            .responsiveSteps=" ${[
                              { minWidth: '0', columns: 3 },
                            ]}"
                          >
                            <div
                            style="background-color:#F0EFFF;"
                              class="w-full h-14 flex items-center mt-5 justify-center flex-row "
                            >
                               <div
                                class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                                @click=${async () => {
                                  const { configConsole } = store.getState() as State;
                                  //console.log(this.objectoBand);
                                  console.log(this.appId)
                                  console.log(this.folioItem.folioId)
                                  
                                  //this.CrearFolio.open(this.appId,this.objectoBand,"");
                                  let page = 2;
                                  store.dispatch( 
                                  ConfigConsole({ 
                                    appId:this.appId,
                                    app:this.app,
                                    page, 
                                    folioId:this.folioItem.folioId,
                                    folio:this.folioItem.folioId,
                                  })
                                  ) 
                                  this.listadoFolio.open(this.app)
                                }}

                              >
                                <div>
                                  <mwc-icon-button icon="save">
                                  </mwc-icon-button>
                                </div>
                                <div>
                                  <p class="text-center">Configuracion</p>
                                </div>
                              </div> 
                              <div
                              class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                              @click=${() => {
                                this.VaciarFolio(this.detailsOpenedItem)
                              }}
                            >
                              <div>
                                <mwc-icon-button icon="cleaning_services">
                                </mwc-icon-button>
                              </div>
                              <div>
                                <p class="text-center">Limpiar</p>
                              </div>
                            </div> 
                              <div
                              class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                              @click=${() => { 
                                //console.log('enviar mensaje', this.termi);
                                //this.CrearFolio.open(model.item);
                                //this. band = true;
                                this.editarFolio.open(this.detailsOpenedItem);
                              }}
                            >
                              <div>
                                <mwc-icon-button icon="edit">
                                </mwc-icon-button>
                              </div>
                              <div>
                                <p class="text-center">Editar</p>
                              </div>
                            </div>                              
                            </div>
                          </vaadin-form-layout>`,
                          root
                        );
                      }
                  )}"
                > 
                <vaadin-grid-sort-column
              .renderer="${(root: HTMLElement, _: any, item: any) => {
                render( 
                  html`
                    <input 
                      class="" 
                      type="radio"
                      name="platform"
                      @change="${async(ev: InputEvent) => {
                      }}"
                    />
                  `,
                  root
                );
              }}" 
            ></vaadin-grid-sort-column>
            <vaadin-grid-filter-column path="folioId"></vaadin-grid-filter-column>
                        <vaadin-grid-filter-column path="affiliationId"></vaadin-grid-filter-column>
                        <!-- <vaadin-grid-sort-column path="description"></vaadin-grid-sort-column> -->
                        <vaadin-grid-filter-column path="description"></vaadin-grid-filter-column>
                        <vaadin-grid-filter-column path="serial"></vaadin-grid-filter-column>
                        <!-- <vaadin-grid-sort-column path="serial"></vaadin-grid-sort-column> -->
                        <vaadin-grid-filter-column path="brand"></vaadin-grid-filter-column>
                        <!-- <vaadin-grid-sort-column path="brand"></vaadin-grid-sort-column> -->
                        <vaadin-grid-filter-column
                    header="Estatus"
                    .renderer="${guard(
                      [],
                      () => (root: HTMLElement, _: any, model: any) => {
                        const bg =
                          model.item.hasOwnProperty("serial")
                          ? `text-blue-500`
                          : `text-green-500`;
                        render(
                          html`<p
                            class="py-1 capitalize px-4 w-fit ${bg} rounded font-bold"
                          >
                            ${
                                model.item.hasOwnProperty("serial")
                                ? "En uso"
                                : "Disponible"
                            }
                          </p>`,
                          root
                        );
                      }
                    )}"
                        ></vaadin-grid-filter-column>
                  <!-- <vaadin-grid-sort-column
                    path="status"
                    header="Estatus"
                    .renderer="${guard(
                      [],
                      () => (root: HTMLElement, _: any, model: any) => {
                        const bg =
                          model.item.status == `online`
                          ? `text-green-500`
                          : model.item.status == `created`
                          ? `text-orange-500`
                          : `text-red-500`;
                        render(
                          html`<p
                            class="py-1 capitalize px-4 w-fit ${bg} rounded font-bold"
                          >
                            ${model.item.status}
                          </p>`,
                          root
                        );
                      }
                    )}"
                  ></vaadin-grid-sort-column> -->
                  <!-- <vaadin-grid-column
                    .headerRenderer="${(root: HTMLElement) => {
                    render(html``, root);
                  }}"
                    text-align="end"
                    .renderer="${this.actionRenderer}"
                  >
                  </vaadin-grid-column> -->
                </vaadin-grid>
                <mwc-icon-button 
                  icon="autorenew"
                  class="absolute top-0 right-0"
                  @click=${() => {
                    this.getApps();
                  }}
                ></mwc-icon-button>
                <edit-organization-modal
                  id="edit-organization-modal"
                ></edit-organization-modal>
              </section>
              <section class="w-1/4  SectionDetail justify-center scroll">
              <div class="w-full flex justify-center">
              <mwc-button
                raised
                icon="add"
                class="rounded w-4/5 mx-auto mt-4 mb-2"
                style="--mdc-theme-secundary: #2E3EAE; --mdc-theme-on-primary: white;"
                @click="${() => {
                  console.log(this.folioItem)
                  this.CrearFolio.open(this.appId,this.objectoBand,this.folioItem);
                  this.band=true; 
                }}"
              > 
                Crear Folio
              </mwc-button>
              </div>
              <div class="w-full flex justify-center">
              <input
              id="seleccionar-archivo"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              placeholder="label"
              type="file"
              @change=${(ev: InputEvent) => {
              }}
              hidden
            />
              <mwc-button
                raised
                icon="delete"
                class="rounded w-4/5 mx-auto mt-4 mb-2"
                style="--mdc-theme-primary: #2E3EAE; --mdc-theme-on-primary: white;"
                @click="${() => {
                  this.botonCsv.click();
                }}"
              > 
                ELiminacion Masiva
              </mwc-button>
              </div>
              <div class="w-full flex justify-center">
              <mwc-button
                raised
                icon="download"
                class="rounded w-4/5 mx-auto mt-4 mb-2"
                style="--mdc-theme-primary: #2E3EAE; --mdc-theme-on-primary: white;"
                @click="${() => {
                  //this.listadoPlantillaExportacion.open();
                  router.cambiarSeccion('exportacion-masiva-page');
                }}"
              > 
                Exportacion Masiva
              </mwc-button>
              </div>
              ${this.detail2 === true 
                ? html`
                  <div class="w-full flex justify-center">
                  <mwc-button
                    raised
                    class="rounded w-4/5 mx-auto mt-4 mb-2"
                    style="--mdc-theme-primary: #2E3EAE; --mdc-theme-on-secundary: white;"
                    @click="${() => {
                      router.cambiarSeccion('carga-masiva-page');
                    }}"
                  >
                  <mwc-icon>upload</mwc-icon>
                    Carga masiva 
                  </mwc-button>
                </div>
                  
                  `
                : html``}
                ${this.detail2 === true 
                  ? html`
                <div class="w-full flex justify-center">
                <mwc-button
                  raised
                  class="rounded w-4/5 mx-auto mt-4 mb-2"
                  style="--mdc-theme-primary: #2E3EAE; --mdc-theme-on-secundary: white;"
                  @click="${() => {
                    router.cambiarSeccion('edit-masiva-page');
                  }}" 
                >
                <mwc-icon>edit</mwc-icon>
                  Edicion masiva 
                </mwc-button>
              </div>
              `
              : html``}
                <div class=" pt-3 flex items-center ">
              <!--<p class="text-base	text-blue-500	ml-3 ">Plantillas Disponibles</p>-->
              <!-- <mwc-icon-button icon="settingsremoteoutlined">
              </mwc-icon-button> -->
            </div>
                ${this.detail === true 
                  ? html`
            <div class="w-full py-3 border-b-2 border-grey-100 px-4">
            <div class="pt-1 ml-5">
            <!--<ul class="list-disc pl-2">
              ${this.templatesInfo.map(
                (template:any, index:number) => html`
                  <li
                    class="flex items-center gap-2 ml-2"
                  >
                  <p class="text-slate-500 hover:text-blue-600 text-sm ">${template.schema.name}</p>
                    <mwc-icon-button
                      class="ml-1"
                      icon="edit"
                      ariaLabel="Actualizar"  
                      @click=${async () => {
                        console.log(this.folioItem)
                        console.log(this.appId)
                        console.log(this.app)
                        const { configConsole } = store.getState() as State;
                        let page = 2;
                        store.dispatch(
                          ConfigConsole({
                            crearFolio:this.folioItem.folioId,
                            folioId:this.folioItem.folioId,
                            appId:this.appId,
                            app:this.app,
                            page
                          })
                        );
                        this.listadoFolio.open(this.app)
                      }}
                    ></mwc-icon-button>
                    <mwc-icon-button
                      class="ml-1"
                      icon="delete"
                      ariaLabel="Eliminar" 
                      @click=${() => {

                      }}
                    ></mwc-icon-button>
                  </li>
                `
              )}
            </ul>-->
          </div>
          </div>
          <div>
            <!-- <mwc-button
              class="rounded w-4/5 mx-auto mt-4 mb-2"
              style="--mdc-theme-primary: #2E3EAE;"
              raised
              icon="add_circle"
              @click=${() => {
                const { configConsole } = store.getState() as State;
                store.dispatch(
                  ConfigConsole({
                    page:1,
                    ...configConsole,
                  })
                );
                this.listadoFolio.open(this.app)
              }}
            >
              Ver mas</mwc-button
            > -->
          </div>
                        <!-- <div>
                          <h1 class="text-2xl	text-blue-900 ml-3 mb-8	">
                            ${this.termi?.serial}
                          </h1>
                        </div>

                        <div class="flex flex-row items-center">
                          <p class="text-base	font-bold	text-blue-500	ml-3">
                            Modelo:
                          </p>
                          <p class="text-base		text-blue-500	ml-3">
                            ${this.termi?.model}
                          </p>
                        </div>
                        <div class="flex flex-row items-center">
                          <p class="text-base	font-bold	text-blue-500	ml-3">
                            Marca:
                          </p>
                          <p class="text-base		text-blue-500	ml-3">
                            ${this.termi?.brand}
                          </p>
                        </div>
                        <div class="flex flex-row items-center">
                          <p class="text-base	font-bold	text-blue-500	ml-3">
                            Firmware:
                          </p>
                          <p class="text-base	text-blue-500	ml-3">
                            ${this.termi?.generalInformation?.firmwareVersion
                              ?.value}
                          </p>
                        </div>
                        <div class="flex flex-row pt-5 pl-3">
                          <mwc-icon class="text-blue-900"
                            >battery_charging_full</mwc-icon
                          >
                          <p class="text-lg	text-blue-900 mr-2">
                            ${this.termi?.generalInformation?.batteryPercentage
                              ?.value}%
                          </p>
                          <mwc-icon class="text-blue-900">memory</mwc-icon>
                          <p class="text-lg	text-blue-900 mr-2">
                            ${this.termi?.generalInformation?.memoryAvailable
                              ?.value}MB
                          </p>
                          <mwc-icon class="text-blue-900">storage</mwc-icon>
                          <p class="text-lg	text-blue-900">
                            ${this.termi?.generalInformation?.storageAvailable
                              ?.value}MB
                          </p>
                        </div> -->
                        <!-- <div class="flex flex-row pt-3"> 
                          <div
                            class="btnBloquear items-center content-center cursor-pointer flex  flex-row"
                            @click=${() => {
                              //this.historialComandosModal.open(this.termi!);
                            }} 
                          >

                            <mwc-icon-button icon="block">
                            </mwc-icon-button>
                            <p class="text-center ">Bloquear</p>
                          </div>
                        </div>
                        <div class="flex flex-row pt-3"> 
                          <div
                            class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                            @click=${() => {
                              this.historialComandosModal.open(this.termi!);
                            }} 
                          >

                              <mwc-icon-button icon="receipt">
                              </mwc-icon-button>
                              <p class="text-center ">Historial Comandos</p>
                          </div>
                        </div> -->
                        <!-- <div class="flex flex-row pt-3 ">
                          <div
                            class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                            @click=${() => {
                              console.log(this.termi);
                              const obj = this.termi?.generalInformation;
                              if (
                                JSON.stringify(obj) == '{}' ||
                                obj === undefined
                              ) {
                                errorNotification(
                                  'Terminal sin data geolocalizacion!'
                                );
                              } else {
                                this.UbicarTerminalMapa.open(this.termi!);
                              }
                            }}
                          >
                            <mwc-icon-button icon="place"> </mwc-icon-button>

                            <p class="text-center ">Ver en mapa</p>
                          </div>
                        </div> -->
                        <!-- <div class="flex flex-row pt-3 ">
                          <div
                            class="btnMessage w-44 items-center content-center cursor-pointer flex  flex-row"
                            @click=${() => {
                              this.listadoApps.open(this.brand, this.serial);
                            }}
                          >
                            <mwc-icon-button icon="build"> </mwc-icon-button>

                            <p class="text-center ">Config avanzada</p>
                          </div>
                        </div> -->
                        <!-- <div class=" pt-3 flex items-center  justify-between">
                          <p
                            class="text-base	font-bold	text-blue-500	ml-3 text-base"
                          >
                            Organizaciones
                          </p>
                        </div> -->
                        <!-- <div class="pt-1 ml-5">
                          <ul class="list-disc pl-2">
                            <li>
                              <p class="text-base	text-blue-800	">
                                ${this.organizacion}
                              </p>
                            </li>
                          </ul>
                        </div> -->

                      <!-- <div class="w-full py-3 pl-5 border-b-2 border-grey-100">
                        <div
                          class="group_icon items-center p-2  flex content-center  flex-row "
                        >
                          <div class="text-blue-500">
                            <mwc-icon-button icon="wifi"> </mwc-icon-button>
                          </div>
                          <div class="flex justify-between w-full">
                            <h1 class="text-1xl	text-blue-900 ml-3 	">Wifi</h1>

                            <mwc-switch
                              slot="meta"
                              .selected=${this.termi?.generalInformation
                              ?.wifiEnable?.value === '1'}
                              @click=${() => {
                              this.toggleWifi();
                            }}
                            ></mwc-switch>
                          </div>
                        </div> -->

                        <!-- <div
                          class=" group_icon items-center p-2   flex  flex-row cursor-default"
                        >
                          <div class="text-blue-500 cursor-default">
                            <mwc-icon-button icon="bluetooth">
                            </mwc-icon-button>
                          </div>
                          <div class="flex justify-between w-full">
                            <h1 class="text-1xl	text-blue-900 ml-3 	">
                              Bluetooth
                            </h1>

                            <mwc-switch
                              slot="meta"
                              .selected=${this.termi?.generalInformation
                              ?.bluetoothEnable?.value === '1'}
                              @click=${() => {
                              this.toggleBluetooth();
                            }}
                            ></mwc-switch>
                          </div>
                        </div>
                      </div> -->
                      <!-- <div
                        class="w-full mt-5 flex-col flex justify-center items-center"
                      >
                        <mwc-button
                          raised
                          style="--mdc-theme-primary: #E3E1EC; --mdc-theme-on-primary: #46464F"
                          class="mb-2 rounded w-3/4"
                          icon="edit"
                          @click="${() => {
                            store.dispatch(editarTerminal(this.termi!));
                            router.cambiarSeccion('edit-terminal');
                          }}"
                          >Editar</mwc-button
                        >
                        <mwc-button
                          raised
                          style="--mdc-theme-primary: tomato; --mdc-theme-on-primary: white"
                          class="mb-2 rounded w-3/4"
                          icon="delete"
                          @click="${() => {
                            this.handleDeleteTerminal(this.termi!);
                          }}"
                          >Eliminar</mwc-button
                        > 
                      </div> -->
                    `
                  : html``}
              </section>
            `
          : html`
              <mwc-circular-progress
                indeterminate
                density=${20}
              ></mwc-circular-progress>
            `}
      </main>
      <listado-folio id="listadoFolio"><listado-folio'>
      <editar-folio id="editarFolio"></editar-folio>
      <listado-plantilla-exportacion id="listadoPlantillaExportacion"></listado-plantilla-exportacion>
      <crear-folio id="crearFolio"></crear-folio>
      <ubicacion-terminal-mapa
        id="ubicacionTerminalMapa"
      ></ubicacion-terminal-mapa>
      <push-config-modal id="pushConfigModal"></push-config-modal>
      <enviar-mensaje-modal id="enviarMensajeModal"></enviar-mensaje-modal>
      <historial-comandos-modal
        id="historialComandosModal"
      ></historial-comandos-modal> 
    `;
  }
}
