/* eslint-disable prettier/prettier */
import { Chart, registerables } from 'chart.js';
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import ChartDataLabels from 'chartjs-plugin-datalabels';
import { DataQueryStatistic } from '../../api';

Chart.register(...registerables);
Chart.register(ChartDataLabels);

@customElement('horizontal-bar-chart')
export class HorizontalBarChart extends LitElement {
  @property({ type: String })
  id = 'bar-chart';

  @property({ type: String })
  legendPosition: 'bottom' | 'left' | 'right' | 'top' | 'center' = 'bottom';

  @property({ type: Boolean })
  showTotal = true;

  @property({ type: Boolean })
  showDataLabels = true;

  @property({ type: Boolean })
  showYLabels = true;

  @property({ type: String })
  titulo = 'Terminales';

  @property({ type: Array })
  data: DataQueryStatistic[] = [
    { label: 'Terminal', value: ['75', '25'] },
    { label: 'Terminal', value: ['80', '20'] },
    { label: 'Terminal', value: ['85', '15'] },
  ];

  @property({ type: String })
  idWidget!: string;

  @property({ type: Array })
  dataLabels = ['Tienda', 'Tienda', 'Tienda'];

  @property({ type: Array })
  dataColors = ['#2FB83D', '#2E3EAE', '#5F308B', '#EF873E', '#EF873E'];

  protected firstUpdated(): void {
    this.buildWidget();
  }

  buildWidget() {
    const canvas = this.shadowRoot?.getElementById(
      this.id
    ) as HTMLCanvasElement;

    const datasets = this.data.map((data, index) => {
      return {
        label: data.label,
        data: data.value.map(v => parseInt(v)),
        backgroundColor: this.dataColors[index],
        borderRadius: 12,
      };
    });

    new Chart(canvas, {
      type: 'bar',
      data: {
        labels: this.dataLabels,
        datasets,
      },
      options: {
        indexAxis: 'y',
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
            ticks: { display: this.showYLabels },
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              padding: 15,
            },
          },
          datalabels: {
            display: false,
          },
          title: {
            display: false,
          },
        },
      },
    });
  }

  render() {
    return html`<div class="horizontalBarChart">
      <canvas id="${this.id}" width="800" height="350"> </canvas>
    </div>`;
  }

  static get styles() {
    return css`
      .horizontalBarChart {
        position: relative;
      }
    `;
  }
}
