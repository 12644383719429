/* eslint-disable prettier/prettier */
import { ActionTypes, State } from '../types';
import { AppList, TerminalList,FirmwareList } from '../../../api';
import {
  INICIAR_SESION,
  CERRAR_SESION,
  EDITAR_TERMINAL,
  EDITAR_FIRMWARE,
  CLEAN_TERMINAL,
  CLEAN_FIRMWARE,
  CLEAN_GRUPO,
  EDITAR_GRUPO,
  EDITAR_USUARIO,
  CLEAN_USUARIO,
  EDITAR_APP,
  VER_APP,
  AGREGAR_PLANTILLA,
  ELIMINAR_PLANTILLA,
  NOMBRE_PLANTILLA,
  DESCRIPCION_PLANTILLA,
  ID_PLANTILLA,
  PLANTILLA,
  APPP,
  OBJECT_JSON,
  CONFIG_CONSOLE,
  //LIST_PLANTILLA,
  ENUMM,
  GET_APPS,
  APPS_RECEIVED,
  TypeJsonSchema,
  PAGE_FOLIO,
  ADD_FIRMWARE
} from '../actions';
import { TYPE_JSON_SCHEMA } from '../actions/objectJson';
import { APP_ADDED } from '../actions/app';
import {
  isAppList,
  isGroupList,
  isOrganizationName,
  isTerminalList,
  isFirmwareList,
  isTemplateList,
  isUser,
} from '../../utils/typeValidators';
import router from '../../router/router';
import {
  GET_TERMINALS,
  TERMINALS_RECEIVED,
  TERMINAL_ADDED,
} from '../actions/terminals';
import {
  GET_FIRMWARES,
  FIRMWARES_RECEIVED,
  FIRMWARE_ADDED,
} from '../actions/firmware';
import {
  GET_TEMPLATES,
  TEMPLATES_RECEIVED,
  TEMPLATE_ADDED,
} from '../actions/plantilla';
import {
  GET_USUARIOS,
  USUARIOS_RECEIVED,
  USUARIO_ADDED,
} from '../actions/usuario';
import {
  GET_ORGANIZACIONES,
  ORGANIZACIONES_RECEIVED,
  ORGANIZACION_ADDED,
} from '../actions/organizaciones';
import { GET_GRUPOS, GRUPOS_RECEIVED, GRUPO_ADDED } from '../actions/groups';

export const estadoInicial: State = { loading: false, list: [], listApps: [] };

export function reducer(state = estadoInicial, action: ActionTypes): State {
  switch (action.type) {
    case GET_GRUPOS:
      return { ...state, loading: true };
    case GRUPOS_RECEIVED:
      const { grupos } = action;

      if (isGroupList(state.previousAdition)) {
        const newTerminal = state.previousAdition;
        if (grupos.find(org => org.id === newTerminal.id))
          return { ...state, loading: false, list: grupos };

        grupos.push(state.previousAdition);
        state.previousAdition = undefined;
      }

      return { ...state, loading: false, list: grupos };
    case GRUPO_ADDED:
      router.cambiarSeccion('hierarchy');
      return { ...state, previousAdition: action.newGrupo };
    case GET_ORGANIZACIONES:
      return { ...state, loading: true };
    case ORGANIZACIONES_RECEIVED:
      const { organizaciones } = action;

      if (isOrganizationName(state.previousAdition)) {
        const newTerminal = state.previousAdition;
        if (organizaciones.find(org => org.id === newTerminal.id))
          return { ...state, loading: false, list: organizaciones };

        organizaciones.push(state.previousAdition);
        state.previousAdition = undefined;
      }

      return { ...state, loading: false, list: organizaciones };
    case ORGANIZACION_ADDED:
      router.cambiarSeccion('hierarchy');
      return { ...state, previousAdition: action.newOrganizacion };
    case GET_USUARIOS:
      return { ...state, loading: true };
    case USUARIOS_RECEIVED: 
      const { usuarios } = action;

      if (isUser(state.previousAdition)) {
        const newTerminal = state.previousAdition;
        if (usuarios.find(usuario => usuario.email === newTerminal.email))
          return { ...state, loading: false, list: usuarios };

        usuarios.push(state.previousAdition);
        state.previousAdition = undefined;
      }

      return { ...state, loading: false, list: usuarios };
    case USUARIO_ADDED:
      router.cambiarSeccion('users-list');
      return { ...state, previousAdition: action.newUsuario };
    case GET_TERMINALS:
      return { ...state, loading: true };
    case GET_FIRMWARES:
      return { ...state, loading: true };      
    case TERMINALS_RECEIVED:
      const { terminals } = action;
      if (isTerminalList(state.previousAdition)) {
        const newTerminal = state.previousAdition;
        if (terminals.find(terminal => terminal.serial === newTerminal.serial))
          return { ...state, loading: false, list: terminals };

        terminals.push(state.previousAdition);
        state.previousAdition = undefined;
      }

      return { ...state, loading: false, list: terminals };
    case FIRMWARES_RECEIVED:
      const { firmwares } = action;
      if (isFirmwareList(state.previousAdition)) {
        const newFirmware = state.previousAdition;
        if (firmwares.find(firmware => firmware.id === newFirmware.id))
          return { ...state, loading: false, list: firmwares };

        firmwares.push(state.previousAdition);
        state.previousAdition = undefined;
      }

      return { ...state, loading: false, list: terminals };
    case TEMPLATE_ADDED:
      router.cambiarSeccion('plantilla-page');
      return { ...state, previousAdition: action.newTemplate };
      case GET_TEMPLATES:
        return { ...state, loading: true };
      case TEMPLATES_RECEIVED:
        const { templates } = action;
  
        if (isTemplateList(state.previousAdition)) {
          const newTemplate = state.previousAdition;
          if (templates.find(template => template.id === newTemplate.id))
            return { ...state, loading: false, list: templates };
  
          templates.push(state.previousAdition);
          state.previousAdition = undefined;
        }
  
        return { ...state, loading: false, list: templates };
      case TEMPLATE_ADDED:
        router.cambiarSeccion('plantilla-page');
        return { ...state, previousAdition: action.newTemplate };
    case GET_APPS:
      return { ...state, loading: true };
    case APPS_RECEIVED:
      const { apps } = action;

      if (isAppList(state.previousAdition)) {
        const newApp = state.previousAdition;
        if (apps.find(app => app.id === newApp.id))
          return { ...state, loading: false, list: apps };

        apps.push(state.previousAdition);
        state.previousAdition = undefined;
      }

      return { ...state, loading: false, listApps: apps };
    case APP_ADDED:
      router.cambiarSeccion('apps-list');
      return { ...state, previousAdition: action.newApp };
    case ADD_FIRMWARE:
      return { ...state, firmware: action.firmware };
    case INICIAR_SESION:
      return {
        ...state,
        usuario: action.usuario,
        token: action.token,
        authorizedFunctions: action.authorizedFunctions,
        nameMaster: action.nameMaster,
        password: action.password, 
        email: action.email,
        fecha: action.fecha,
      };
    case TypeJsonSchema:
      return {
        ...state,
        typeJsonSchema: action.typeJsonSchema,
      };
    case CERRAR_SESION:
      return {
        ...state,
        usuario: undefined,
        token: undefined,
        authorizedFunctions: undefined,
        fecha: undefined,
      };
    case PAGE_FOLIO:
      return {
        ...state,
        page_folio: action.page_folio,
      };
    case EDITAR_TERMINAL:
      return {
        ...state,
        terminal: action.terminal,
      };
    case EDITAR_FIRMWARE:
      return {
        ...state,
        firmware: action.firmware,
      };
    case ENUMM:
      return {
        ...state,
        enumm: action.enumm,
      };
    case AGREGAR_PLANTILLA:
      return {
        ...state,
        plantilla: action.plantilla,
      };
    case CONFIG_CONSOLE:
      return {
        ...state,
        configConsole: action.config_console,
      };
    case OBJECT_JSON:
      return {
        ...state,
        objectJson: action.objectJson,
      };
    case PLANTILLA:
      return {
        ...state,
        plantilla: action.plantilla,
      };
    case APPP:
      return {
        ...state,
        appp: action.appp,
      };
    case ELIMINAR_PLANTILLA: {
      //const { index } = action;
      return {
        ...state,
        plantilla: action.plantilla,
        //plantilla: action.plantilla.filter(products => products.id !== index),
      };
    }
    case NOMBRE_PLANTILLA:
      return {
        ...state,
        nombrePlantilla: action.nombrePlantilla,
      };
    case DESCRIPCION_PLANTILLA:
      return {
        ...state,
        descripcionPlantilla: action.descripcionPlantilla,
      };
    case ID_PLANTILLA:
      return {
        ...state,
        idPlantilla: action.idPlantilla,
      };

    case CLEAN_TERMINAL:
      return {
        ...state,
        terminal: undefined,
      };
    case CLEAN_FIRMWARE:
      return {
        ...state,
        firmware: undefined,
      };
    case EDITAR_GRUPO:
      return {
        ...state,
        grupo: action.grupo,
      };
    case EDITAR_USUARIO:
      return {
        ...state,
        usuarioEdit: action.usuario,
      };
    case EDITAR_APP:
      return {
        ...state,
        appEdit: action.app, 
      };
    case CLEAN_GRUPO:
      return {
        ...state,
        grupo: undefined,
      };
    case CLEAN_USUARIO:
      return {
        ...state,
        usuarioEdit: undefined,
      };
    case VER_APP:
      return {
        ...state,
        verAppId: action.verAppId,
      };
    default:
      return state;
  }
}
