/* eslint-disable prettier/prettier */
import { html, LitElement, render } from 'lit';
import { guard } from 'lit/directives/guard.js';
import { customElement, state } from 'lit/decorators.js';
import { connect } from 'pwa-helpers';
import '@ui5/webcomponents/dist/Tree.js';
import '@ui5/webcomponents/dist/Icon.js';
import '@ui5/webcomponents-icons/dist/it-instance.js';
import '@ui5/webcomponents-icons/dist/group-2.js';
import '@ui5/webcomponents-icons/dist/alert.js';
import '@ui5/webcomponents-icons/dist/cart.js';
import '@ui5/webcomponents-icons/dist/bell.js';
import '@material/mwc-icon';
import '@material/mwc-icon-button';
import '@vaadin/vaadin-grid'; 
import { State } from '../../redux/types';
//import './TerminalList.css';
import {
  Grid,
  GridActiveItemChangedEvent,
  GridItemModel,
} from '@vaadin/vaadin-grid';
import { AxiosResponse } from 'axios';

import '../../components/modals/EditOrganizationModal';
import router from '../../router/router.js';
import {
  CommunicationTerminalApi,
  CommunicationTerminalApiRequestCommandRequest,
  TerminalApi,
  OrganizationApi,
  FolioApiGetFolioRequest
} from '../../../api/api.js';
import {
    ConfigurationApi,
    ConfigurationApiCreateConfigurationRequest,
    ConfigurationApiUpdateConfigurationRequest,
    AppApi,
    AppList,
    TemplateApi,
    RequestFolioCreate,
    FolioApiCreateFolioRequest,
    SavedConfigurationApi,
    FolioApi,
    FolioApiListFolioRequest,
    ResponseTemplateGet,
    RequestFolioPatch,
    FolioApiCleanFolioRequest
  } from '../../../api';
import {
  RequestCommandTypeCommandEnum,
  Terminal,
  FolioInfo,
  TerminalList,
} from '../../../api/index.js';
import store from '../../redux/store.js';
import { ConfigConsole,PageFolio, getApps } from '../../redux/actions';
import { editarTerminal } from '../../redux/actions/index.js';
import { ListaAppModal } from '../../components/modals/ListaAppModal';
import { PushConfigModal } from '../../components/modals/PushConfigurationsDialog';
import '../../components/modals/PushConfigurationsDialog';
import { UbicacionTerminalMapa } from '../../components/modals/UbicacionTerminalMapa';
import '../../components/modals/ListaAppModal';
import { CrearFolio } from '../../components/modals/CrearFolio';
import '../../components/modals/CrearFolio';
import { ListadoApp } from '../../components/modals/ListadoApp';
//import { CambiarMaster } from '../../components/modals/CambiarMaster';
import '../../components/modals/ListadoApp';
import { ListadoFolio } from '../../components/modals/ListadoFolio';
import '../../components/modals/ListadoFolio';
import { ConfigTerminalFolio } from '../../components/ConfigTerminalFolio';
import '../../components/ConfigTerminalFolio';
import '../../components/modals/UbicacionTerminalMapa';
import { EditOrganizationModal } from '../../components/modals/EditOrganizationModal.js';
import { apisConfigurator } from '../../utils/axios';
import { EnviarMensajeModal } from '../../components/modals/EnviarMensajeModal';
import '../../components/modals/EnviarMensajeModal';
import { HistorialComandosModal } from '../../components/modals/HistorialComandosModal';
import '../../components/modals/HistorialComandosModal';
import { CrearPreConfiguracionBanorteModals } from '../../components/modals/CrearPreConfiguracionBanorteModal';
import '../../components/modals/CrearPreConfiguracionBanorteModal';

import { EditarPreConfiguracionBanorteModals } from '../../components/modals/EditarPreConfiguracionBanorteModal';
import '../../components/modals/EditarPreConfiguracionBanorteModal';

import { ListadoPlantillaExportacion } from '../../components/modals/ListadoPlantillaExportacion';
import '../../components/modals/ListadoPlantillaExportacion';

import { ConfigTerminalFolio_new } from '../../components/modals/ConfigTerminalFolio_new';
import '../../components/modals/ConfigTerminalFolio_new';
import { EditarFolio } from '../../components/modals/EditarFolio';
import '../../components/modals/EditarFolio';
import {
  iterarCampoJson2,
  deleteCamp
} from '../../utils/functions';

import {
  errorNotification,
  succesNotification,
} from '../../services/notifications';
import { getTerminals } from '../../redux/actions/terminals';
import { Unsubscribe } from '@reduxjs/toolkit';

@customElement('carga-masiva-banorte')
export class CargaMasivaBanorte extends connect(store)(LitElement) {
  private appApi: AppApi;
  private templateApi: TemplateApi;
  private organizationApi!: OrganizationApi;
  private folio: FolioApi;

  private storeUnsuscriber?: Unsubscribe;

  get enviarMensajeModal() {
    return document.getElementById('enviarMensajeModal') as EnviarMensajeModal;
  }

  get editarFolio() {
    return document.getElementById('editarFolio') as EditarFolio;
  }

  get listadoApps() {
    return document.getElementById('listadoApp') as ListadoApp;
  }

  get appSelected() {
    return document.getElementById('appSelect') as any;
  }

  get listadoFolio() {
    return document.getElementById('listadoFolio') as ListadoFolio;
  }

  get configFolio() {
    return document.getElementById('configFolio') as ConfigTerminalFolio_new
  }
 
  get crearPreConfiguracionBanorte() {
    return document.getElementById('crearPreConfiguracionBanorteModals') as CrearPreConfiguracionBanorteModals
  }

  get editarPreConfiguracionBanorte() {
    return document.getElementById('editarPreConfiguracionBanorteModals') as EditarPreConfiguracionBanorteModals
  }

  get historialComandosModal() {
    return document.getElementById(
      'historialComandosModal'
    ) as HistorialComandosModal;
  }

  @state()
  private terminals: TerminalList[] = [];

  private constructor() {
    super();
    this.templateApi = new TemplateApi(...apisConfigurator('Templates'));
    this.appApi = new AppApi(...apisConfigurator('Aplicaciones'));
    this.folio = new FolioApi(
      ...apisConfigurator('Folio')
    );
  }
  async stateChanged(state: any) {
    if(state.configConsole.band && this.band){
      console.log(state.configConsole)
      await this.getApp(this.appId,state.configConsole.crearFolio)
      this.listadoFolio.open(this.app)
      this.band = false;
    }
  }

  @state() 
  private preConfig: any[] = [
    {id:"c829e7b7-b956-4695-8222-c6221cf248c0",name:"Banorte - Terminal - F"},
    {id:"b3618d37-b3e6-40e2-a02f-f2d19582b425",name:"Banorte - Comercio - F"},
    {id:"c99b1812-4b67-40eb-8075-6ce0b4aea653",name:"Banorte - Autorizador - F"}
  ]

  @state()
  private apps: AppList[] = [];

  @state()
  termi?: Terminal;

  @state()
  objectoBand:Object[] = [];

  @state()
  folioList?: any;

  @state()
  folioItem?: any;

  @state()
  app?: any;

  @state()
  band?: any = false;

  @state()
  appId?: any;

  @state()
  NameBtn = 'Crear Terminal';

  @state()
  templatesInfo:any = [];

  @state()
  template:any = 'Crear Terminal';

  @state()
  detail:boolean = false;

  @state()
  detail2:boolean = false;

  @state()
  private loading = false;

  @state()
  organizacion = '';

  @state()
  private detailsOpenedItem: FolioInfo[] = [];

  @state()
  loader = true;

  @state()
  configuration: any;

  @state()
  brand = '';

  @state()
  serial = '';

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  disconnectedCallback(): void {
    this.storeUnsuscriber && this.storeUnsuscriber();
  }


  async updated(){
    if (localStorage.getItem("folioState")) {
      const appSelect = this.appSelected;
      const optionValue:any = localStorage.getItem("folioState");

      const optionElement = appSelect.querySelector(`option[value="${optionValue}"]`);
      optionElement.selected = true;
      this.detail2=true;
    } 
  }

  protected async firstUpdated(){
    this.loader = true;
    this.getApps();
    if (localStorage.getItem("folioState")) {
      const appSelect = this.appSelected;
      const optionValue:any = localStorage.getItem("folioState");
      this.appId = optionValue;
      this.getApp(optionValue,"")
      this.detail2=true;
    } 
    this.detail = true;
    this.loader = false;
  }
 

  get ListarAplicacionesModal() {
    return document.getElementById('listaAppModal')! as ListaAppModal;
  }

  get CrearFolio() {
    return document.getElementById('crearFolio')! as CrearFolio;
  }

  get pushConfigModal() {
    return document.getElementById('pushConfigModal')! as PushConfigModal;
  }


  async getApps() {
    store.dispatch(getApps());
    this.storeUnsuscriber = store.subscribe(() => {
      const { list, loading, listApps } = store.getState();
      console.log(loading); 
      console.log("list",{ listApps });
      this.apps = listApps as AppList[];
      //this.loading = loading;
    });
  }
 
  async getTemplate(id: any): Promise<ResponseTemplateGet | null> {
    //this.loader = true;
    try {
      const resTer = await this.templateApi.getTemplate({ id });

      //this.loader = false;
      return resTer.data;
    } catch (error) {
      return null;
    }
  }

  async getApp(appId: any,folio:any) {
    this.templatesInfo= []
    const { configConsole } = store.getState() as State;
    try {
      const res = await this.appApi.getApp({ id: appId });
      this.app = res.data;
      const app = res.data;
      this.template = app.templates;
      this.template.forEach(async (templateId:string) => {
        const templateInfo = await this.templateApi.getTemplate({id:templateId});
        console.log("PROBANDO TEMPLATE",templateInfo.data)
        this.templatesInfo.push({
          id:templateId,
          schema:templateInfo.data
        })
        console.log("PROBANDO INFO",this.templatesInfo)
      });

    } catch (err) {
      console.log(err);
    }
  }

  render() {
    return html`
      <main
        class="w-full h-full FondoColor flex  ${!this.loader
          ? ''
          : 'items-center justify-center'}"
      >
        ${this.loader !== true
          ? html`
              <section
                class="w-3/4 relative flex flex-wrap p-4 SectionList rounded-l-lg"
              >
              <select name="cars" class="w-[210px] mx-1 mb-3 bg-white border border-gray-300 rounded-md text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white" id="appSelect"
                @change=${(ev: CustomEvent) => {
                  console.log(this.appSelected.value)
                  this.appId = this.appSelected.value;
                  localStorage.removeItem('affiliationState');
                  localStorage.setItem('folioState', this.appId);
                  localStorage.removeItem('appId');
                  localStorage.setItem('appId',this.appId);
                  this.getApp(this.appSelected.value,"")
                  if (this.detail2 === false) {
                    this.detail2 = true;
                  } else if (this.detail === true) {
                    this.detail2 = false; 
                  }
                }}>
                    <option disabled selected>Aplicaciones</option>
                  ${this.apps.map((app:any,index:number)=> html`
                      <option value="${app.id}">${app.name}</option>
                  `)}
              </select> 
              <!-- <div class="mx-2 TexField">
                  <custom-select
                    label="Aplicaciones"
                    id="apps"
                    .options=${this.apps.map(m => ({
                      label: m.name,
                      value: m.id,
                    }))}
                    @change=${(ev: CustomEvent) => {
                      //this.brandSelected = ev.detail;
                      console.log(ev.detail)
                      this.appId = ev.detail;
                      this.getApp(ev.detail,"")
                    }}
                  ></custom-select> -->
                </div>
                <!-- <div id='editorHolder'></div> -->
                <div
                class="px-4 flex flex-col items-center"
              >
                <div class="mx-2 w-full flex flex-col items-center">
                <div class=" pt-3 flex items-center mb-5 ">

                </div>        
              </div>
                <edit-organization-modal
                  id="edit-organization-modal"
                ></edit-organization-modal>
              </section>
              <section class="w-1/4  SectionDetail justify-center scroll">
              ${this.detail2 === true 
                ? html`

                  <div class="w-full flex justify-center">
                  <mwc-button
                    raised
                    class="rounded w-4/5 mx-auto mt-4 mb-2"
                    style="--mdc-theme-secundary: #2E3EAE; --mdc-theme-on-secundary: white;"
                    @click="${() => {
                        this.crearPreConfiguracionBanorte.open("")
                    }}"
                  >
                  <mwc-icon>upload</mwc-icon>
                    Carga masiva 
                  </mwc-button>
                </div>
                <div class="w-full flex justify-center">
                <mwc-button
                  raised
                  class="rounded w-4/5 mx-auto mt-4 mb-2"
                  style="--mdc-theme-secundary: #2E3EAE; --mdc-theme-on-secundary: white;"
                  @click="${() => {
                      this.editarPreConfiguracionBanorte.open("")
                  }}"
                >
                <mwc-icon>edit</mwc-icon>
                  Edicion masiva 
                </mwc-button>
              </div>
              <div class="w-full flex justify-center">
              <mwc-button
                raised
                class="rounded w-4/5 mx-auto mt-4 mb-2"
                style="--mdc-theme-secundary: #2E3EAE; --mdc-theme-on-secundary: white;"
                @click="${() => {
                  router.cambiarSeccion('exportacion-masiva-banorte-page');
                }}"
              >
              <mwc-icon>download</mwc-icon>
                exportacion masiva 
              </mwc-button>
            </div>
                  
                  `
                : html``}
                ${this.detail2 === true 
                  ? html`
              `
              : html``}
                <div class=" pt-3 flex items-center ">
            </div>
                ${this.detail === true 
                  ? html`
            <div class="w-full py-3 border-b-2 border-grey-100 px-4">
            <div class="pt-1 ml-5">
            <!--<ul class="list-disc pl-2">
              ${this.templatesInfo.map(
                (template:any, index:number) => html`
                  <li
                    class="flex items-center gap-2 ml-2"
                  >
                  <p class="text-slate-500 hover:text-blue-600 text-sm ">${template.schema.name}</p>
                    <mwc-icon-button
                      class="ml-1"
                      icon="edit"
                      ariaLabel="Actualizar"  
                      @click=${async () => {
                        console.log(this.folioItem)
                        console.log(this.appId)
                        console.log(this.app)
                        const { configConsole } = store.getState() as State;
                        let page = 2;
                        store.dispatch(
                          ConfigConsole({
                            crearFolio:this.folioItem.folioId,
                            folioId:this.folioItem.folioId,
                            appId:this.appId,
                            app:this.app,
                            page
                          })
                        );
                        this.listadoFolio.open(this.app)
                      }}
                    ></mwc-icon-button>
                    <mwc-icon-button
                      class="ml-1"
                      icon="delete"
                      ariaLabel="Eliminar" 
                      @click=${() => {

                      }}
                    ></mwc-icon-button>
                  </li>
                `
              )}
            </ul>-->
          </div>
          </div>
          <div>
          </div>
                        <!-- <div>
                          <h1 class="text-2xl	text-blue-900 ml-3 mb-8	">
                            ${this.termi?.serial}
                          </h1>
                        </div>

                        <div class="flex flex-row items-center">
                          <p class="text-base	font-bold	text-blue-500	ml-3">
                            Modelo:
                          </p>
                          <p class="text-base		text-blue-500	ml-3">
                            ${this.termi?.model}
                          </p>
                        </div>
                        <div class="flex flex-row items-center">
                          <p class="text-base	font-bold	text-blue-500	ml-3">
                            Marca:
                          </p>
                          <p class="text-base		text-blue-500	ml-3">
                            ${this.termi?.brand}
                          </p>
                        </div>
                        <div class="flex flex-row items-center">
                          <p class="text-base	font-bold	text-blue-500	ml-3">
                            Firmware:
                          </p>
                          <p class="text-base	text-blue-500	ml-3">
                            ${this.termi?.generalInformation?.firmwareVersion
                              ?.value}
                          </p>
                        </div>
                        <div class="flex flex-row pt-5 pl-3">
                          <mwc-icon class="text-blue-900"
                            >battery_charging_full</mwc-icon
                          >
                          <p class="text-lg	text-blue-900 mr-2">
                            ${this.termi?.generalInformation?.batteryPercentage
                              ?.value}%
                          </p>
                          <mwc-icon class="text-blue-900">memory</mwc-icon>
                          <p class="text-lg	text-blue-900 mr-2">
                            ${this.termi?.generalInformation?.memoryAvailable
                              ?.value}MB
                          </p>
                          <mwc-icon class="text-blue-900">storage</mwc-icon>
                          <p class="text-lg	text-blue-900">
                            ${this.termi?.generalInformation?.storageAvailable
                              ?.value}MB
                          </p>
                        </div> -->
                        <!-- <div class="flex flex-row pt-3"> 
                          <div
                            class="btnBloquear items-center content-center cursor-pointer flex  flex-row"
                            @click=${() => {
                              //this.historialComandosModal.open(this.termi!);
                            }} 
                          >

                            <mwc-icon-button icon="block">
                            </mwc-icon-button>
                            <p class="text-center ">Bloquear</p>
                          </div>
                        </div>
                        <div class="flex flex-row pt-3"> 
                          <div
                            class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                            @click=${() => {
                              this.historialComandosModal.open(this.termi!);
                            }} 
                          >

                              <mwc-icon-button icon="receipt">
                              </mwc-icon-button>
                              <p class="text-center ">Historial Comandos</p>
                          </div>
                        </div> -->
                        <!-- <div class="flex flex-row pt-3 ">
                          <div
                            class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                            @click=${() => {
                              console.log(this.termi);
                              const obj = this.termi?.generalInformation;
                              if (
                                JSON.stringify(obj) == '{}' ||
                                obj === undefined
                              ) {
                                errorNotification(
                                  'Terminal sin data geolocalizacion!'
                                );
                              } else {
                              }
                            }}
                          >
                            <mwc-icon-button icon="place"> </mwc-icon-button>

                            <p class="text-center ">Ver en mapa</p>
                          </div>
                        </div> -->
                        <!-- <div class="flex flex-row pt-3 ">
                          <div
                            class="btnMessage w-44 items-center content-center cursor-pointer flex  flex-row"
                            @click=${() => {
                              this.listadoApps.open(this.brand, this.serial);
                            }}
                          >
                            <mwc-icon-button icon="build"> </mwc-icon-button>

                            <p class="text-center ">Config avanzada</p>
                          </div>
                        </div> -->
                        <!-- <div class=" pt-3 flex items-center  justify-between">
                          <p
                            class="text-base	font-bold	text-blue-500	ml-3 text-base"
                          >
                            Organizaciones
                          </p>
                        </div> -->
                        <!-- <div class="pt-1 ml-5">
                          <ul class="list-disc pl-2">
                            <li>
                              <p class="text-base	text-blue-800	">
                                ${this.organizacion}
                              </p>
                            </li>
                          </ul>
                        </div> -->

                      <!-- <div class="w-full py-3 pl-5 border-b-2 border-grey-100">
                        <div
                          class="group_icon items-center p-2  flex content-center  flex-row "
                        >
                          <div class="text-blue-500">
                            <mwc-icon-button icon="wifi"> </mwc-icon-button>
                          </div>
                          <div class="flex justify-between w-full">
                            <h1 class="text-1xl	text-blue-900 ml-3 	">Wifi</h1>

                            <mwc-switch
                              slot="meta"
                              .selected=${this.termi?.generalInformation
                              ?.wifiEnable?.value === '1'}
                              @click=${() => {
                            }}
                            ></mwc-switch>
                          </div>
                        </div> -->

                        <!-- <div
                          class=" group_icon items-center p-2   flex  flex-row cursor-default"
                        >
                          <div class="text-blue-500 cursor-default">
                            <mwc-icon-button icon="bluetooth">
                            </mwc-icon-button>
                          </div>
                          <div class="flex justify-between w-full">
                            <h1 class="text-1xl	text-blue-900 ml-3 	">
                              Bluetooth
                            </h1>

                            <mwc-switch
                              slot="meta"
                              .selected=${this.termi?.generalInformation
                              ?.bluetoothEnable?.value === '1'}
                              @click=${() => {
                            }}
                            ></mwc-switch>
                          </div>
                        </div>
                      </div> -->
                      <!-- <div
                        class="w-full mt-5 flex-col flex justify-center items-center"
                      >
                        <mwc-button
                          raised
                          style="--mdc-theme-primary: #E3E1EC; --mdc-theme-on-primary: #46464F"
                          class="mb-2 rounded w-3/4"
                          icon="edit"
                          @click="${() => {
                            store.dispatch(editarTerminal(this.termi!));
                            router.cambiarSeccion('edit-terminal');
                          }}"
                          >Editar</mwc-button
                        >
                        <mwc-button
                          raised
                          style="--mdc-theme-primary: tomato; --mdc-theme-on-primary: white"
                          class="mb-2 rounded w-3/4"
                          icon="delete"
                          @click="${() => {
                          }}"
                          >Eliminar</mwc-button
                        > 
                      </div> -->
                    `
                  : html``}
              </section>
            `
          : html`
              <mwc-circular-progress
                indeterminate
                density=${20}
              ></mwc-circular-progress>
            `}
      </main>
      <pre-configuracion-banorte-modals id="crearPreConfiguracionBanorteModals"></pre-configuracion-banorte-modals>
      <editar-pre-configuracion-banorte-modals id="editarPreConfiguracionBanorteModals"></editar-pre-configuracion-banorte-modals>
      <listado-folio id="listadoFolio"></listado-folio>
      <editar-folio id="editarFolio"></editar-folio>
      <!-- <config-terminal-folio-new id="configFolio"></config-terminal-folio-new> -->
      <crear-folio id="crearFolio"></crear-folio>
      <!-- <listado-app id="listadoApp"></listado-app> -->
      <!-- <cambiar-master id="cambiarMaster"></cambiar-master> -->
      <ubicacion-terminal-mapa
        id="ubicacionTerminalMapa"
      ></ubicacion-terminal-mapa>
      <!-- <lista-app-modal id="listaAppModal"></lista-app-modal> -->
      <push-config-modal id="pushConfigModal"></push-config-modal>
      <enviar-mensaje-modal id="enviarMensajeModal"></enviar-mensaje-modal>
      <historial-comandos-modal
        id="historialComandosModal"
      ></historial-comandos-modal>
      <mostrar-fallados-modal id="mostrarFalladosModal"></mostrar-fallados-modal>
      <mostrar-registrados-modal id="mostrarRegistradosModal"></mostrar-registrados-modal>
      
    `;
  }
}
