/* eslint-disable prettier/prettier */
import { html, LitElement } from 'lit';
import { customElement, query, state, property } from 'lit/decorators.js';
import { connect } from 'pwa-helpers';
import { ObjectJson } from '../redux/actions';
import Ajv from 'ajv';
import '@vaadin/vaadin-list-box';
import '@vaadin/vaadin-text-field';
import { AxiosResponse } from 'axios';
import { SelectTag } from '../../src/components/SelectTag/SelectTag';
import '../../src/components/SelectTag/SelectTag';
import {
  OrganizationName, 
  RoleList,
  User,
  TemplateApi,
  ConfigurationApi,
  ConfigurationApiGetConfigurationRequest,
  SavedConfigurationApiCreateSavedConfigurationRequest,
  SavedConfigurationApiGetSavedConfigurationRequest,
  SavedConfigurationApi,
  FolioApi,
  FolioApiGetFolioConfigurationRequest
} from '../../api';
import '@vaadin/vaadin-text-field';
import './configTerminal.css';
import { apisConfigurator } from '../../src/utils/axios';
import {
  typeDataObject,
  addDefault,
  insertParametersPreConfiguration,
} from '../../src/utils/functions';
import store from '../../src/redux/store'; 
import { State } from '../../src/redux/types';
import { agregarPlantilla, ConfigConsole } from '../../src/redux/actions';
import { JSONEditor } from '@json-editor/json-editor';
import { errorNotification } from '../../src/services/notifications';

@customElement('config-terminal-folio-afiliaciones')
export class ConfigTerminalFolioAfiliaciones extends connect(store)(LitElement) {
  private templateApi: TemplateApi;
  private configurationApi: ConfigurationApi;
  private savedConfigurationApi: SavedConfigurationApi;
  private folio: FolioApi;

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private constructor() {
    super();
    this.templateApi = new TemplateApi(...apisConfigurator(''));
    this.configurationApi = new ConfigurationApi(...apisConfigurator(''));
    this.savedConfigurationApi = new SavedConfigurationApi(
      ...apisConfigurator('')
    );
    this.folio = new FolioApi(
      ...apisConfigurator('Folio')
    );
  }

  @state()
  plantillas: any = [
    {
      name: '',
      id: '12',
      description: '',
    },
  ];

  @query('#name')
  nameInput!: HTMLInputElement;

  @query('#lastName')
  lastNameInput!: HTMLInputElement;

  @state()
  DataJson: any = [];

  @state()
  loading = false;

  @state()
  editUser?: User;

  @state()
  token?: number;

  @state()
  roles: RoleList[] = [];

  @state()
  nombrePlantilla?: string = 'Nombre de la plantilla';

  @state()
  descripcionPlantilla?: string = '';

  @state()
  rolesUsuario: string[] = [];

  @state()
  organizationUsuario: string = '';

  @state()
  aux: string = '';

  @state()
  organizaciones: OrganizationName[] = [];

  @state()
  rol?: string = '';

  @state()
  checks: boolean[] = [];

  @state()
  JsonSchema?: any;

  @state()
  parameters?: Object;

  @state()
  parametersArray?: Object[];

  @state()
  existConfig?: boolean;

  @state()
  validError?: any;

  @state()
  editor?: any;

  @state()
  prueba?: object;

  @state()
  private preConfiguration: Object[] = [];

  @state()
  organization!: Array<string>;

  private get labelName() {
    return document.querySelectorAll(`label.je-form-input-label`) as any;
  }

  private get buttonDeleteRow() {
    return document.querySelector(`.json-editor-btn-delet`) as any;
  }

  private get editorContainer() {
    return document.querySelector(`.je-object__container`) as HTMLInputElement;
  }

  private get loaderContainer() {
    return document.getElementById('loader-container') as any;
  }

  private get formData() {
    return document.getElementById('editorHolder') as any;
  }

  private get divClass() {
    return document.getElementById('agregar') as any;
  }

  private get collapse() {
    return document.querySelector(`.je-object__title`) as HTMLInputElement;
  }

  private get collapse2() {
    return document.querySelector(
      `.json-editor-btn-edit_properties`
    ) as HTMLInputElement;
  }

  private get collapse3() {
    return document.querySelector(`.json-editor-btn-edit`) as HTMLInputElement;
  }

  private get jeindented() {
    return document.querySelector(`.je-indented-panel`) as HTMLInputElement;
  }

  get jeFormInput() {
    return document.querySelector('.je-form-input-label') as HTMLInputElement;
  }

  idGet(atributo: string) {
    return document.getElementById(atributo) as HTMLInputElement;
  }

  validGet(atributo: string) {
    return document.querySelector(`.${atributo}`) as HTMLInputElement;
  }

  private get buttonEliminar() {
    return document.querySelector('.json-editor-btn-subtract') as HTMLInputElement;
  }

  private get buttonAgregar() {
    return document.querySelector('.json-editor-btn-add') as HTMLInputElement;
  }

  private get tableTop() {
    return document.querySelector('.je-tabholder--top') as HTMLInputElement;
  }

  esconderDiv() {
    const buttonEliminar = this.buttonEliminar;
    const buttonAgregar = this.buttonAgregar;
    const tableTop = this.tableTop;
    console.log(buttonEliminar)
    const span:any = buttonEliminar.querySelector('span');
    const span2:any = buttonAgregar.querySelector('span');
    console.log(span)
    span.innerHTML="Eliminar";
    span2.innerHTML="Agregar";
    tableTop.style.border = 'none'; 
  }

  styleButtonRow() {
    const buttonRow = this.buttonDeleteRow; 
    buttonRow.setAttribute(
      'class',
      'json-editor-btn-delete delete json-editor-btntype-delete text-red-600 p-4 rounded-md uppercase'
    );
  }

  stateChanged(state: any) {
    if(state.configConsole){
      console.log("STATE",state.configConsole)
      this.DataJson = state.configConsole.DataJson
    }
  }

  async firstUpdated() {
    console.log("COMIENZO firstUpdated")
    this.loading = true;
    const promises = [
      this.listPreConfiguration(),
      this.getConfiguration(),
      this.getConfigurationSaved(),

    ].map(p => {
      console.log("PROMISE ALL",p)
      p.catch(e => e)
    }); // this will prevent the promise from breaking out, but there will be no 'result-object', unlike the first solution.
    await Promise.all(promises);
    this.loading = false;
    console.log("CONTROL DE LOADING",this.loading)
    this.esconderDiv()
    // esconder estilos de la libreria json-editor



  }

  async listPreConfiguration(){
    try {
      const { configConsole } = store.getState() as State;
      let response = await this.savedConfigurationApi.listSavedConfiguration();
      let i = response.data;
      let dataFiltre: Object[] = [];
  
      i.map((camp: any, index: number) => {
        if (configConsole.templateId.localeCompare(camp.templateId) == 0) {
          dataFiltre.push(camp);
        }
      });
      this.preConfiguration = dataFiltre;
      return response.data;
    } catch (error:any) {
      console.log(error);
      //errorNotification(error.toString());
    }
  }

  async getConfiguration() {
    const { configConsole } = store.getState() as State;
    let properties: Object = configConsole.JsonSchema.properties;

    const element = this.formData;

    let type = configConsole.JsonSchema.type;
    let editor;
    if (type == 'array') {
      editor = new JSONEditor(element, {
        ajax: true,
        template: 'hogan',
        collapsed:false,
        array_controls_top:true,
        disable_properties:true,
        //disable_properties: false,
        //disable_edit_json: false,
        remove_button_labels:false,
        iconlib: "fontawesome4",
        schema: {
          title: ' ',
          type: 'array',
          format: 'tabs-top',
          options: {
            disable_array_delete_all_rows: true,
          },
          items: {
            type: 'object',
            properties: properties,
          },
        },
      });
      this.editor = editor;
    } else {
      editor = new JSONEditor(element, {
        ajax: true,
        no_additional_properties:true,
        keep_oneof_values:true,
        disable_edit_json: true,
        disable_collapse: true,
        disable_properties: true,
        prompt_before_delete:true,
        schema: {
          title: ' ',
          type: 'object',
          format: 'grid',
          properties: properties,
        },
      });
      this.editor = editor;
      //await this.sleep(100);
      //this.esconderDiv(); //token-lis/license
      //token/list

    } 

    const objecto1 = configConsole;
    const objecto2 = { editor: this.editor };
    Object.assign(objecto1, objecto2);
    console.log("DISPATH getconfiguration")
    store.dispatch(
      ConfigConsole({
        editor: objecto1,
        ...configConsole,
      })
    );
  }

  sleep(ms:number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async getConfigurationSaved() {
    const { configConsole } = store.getState() as State;
    if (configConsole.existConfig) {
      const request: FolioApiGetFolioConfigurationRequest = {
        folioId: configConsole.folioId,
        appId: configConsole.appId,
        templateId: configConsole.templateId,
      }; 
      console.log("DATOS CONFIGURATION",request);
      try {
        await this.folio.getFolioConfiguration(request)
        .then(response => response.data)
        .then(data => {
          console.log(data);
          let type = configConsole.JsonSchema.type;
          let editor;
          console.log(configConsole);
          if (type == 'array') {
            console.log("ARRAY set value",data.parametersArray)
            this.editor.setValue(data.parametersArray);
          } else {
            console.log("OBJECT set value",data.parameters)
            this.editor.setValue(data.parameters);
            this.esconderDiv();
          }
          return data;
          /*const objecto1 = configConsole;
          const objecto2 = { editor: this.editor };
          Object.assign(objecto1, objecto2);*/
          /*store.dispatch(
            ConfigConsole({
              editor: objecto1,
              ...configConsole,
            })
          );*/
        })
      } catch (error:any) {
        console.log(error);
        //errorNotification(error.toString());
      }
  
    }
    
  }

  async getPreConfiguration(id: string) {
    const { configConsole } = store.getState() as State;
    const templateId = configConsole.templateId;
    const type = configConsole.JsonSchema.type;

    const request: SavedConfigurationApiGetSavedConfigurationRequest = {
      id,
    };
    try {
      const res = await this.savedConfigurationApi.getSavedConfiguration(
        request
      );
      console.log(res.data.parametersArray);
      if (type == 'array') {
        this.editor.setValue(res.data.parametersArray);
        var value = this.editor.getValue();
        console.log(value);
      } else {
        /*await this.parametersPreConfiguration(
          res.data.parameters ? res.data.parameters : {}
        );*/
        this.editor.setValue(res.data.parameters);
        this.esconderDiv()
      }
    } catch (error:any) {
      console.log(error);
      //errorNotification(error.toString());
    }
  }

  async parametersPreConfiguration(parameters: Object) {
    const editorContainer = this.editorContainer;
    await editorContainer.remove();
    const { configConsole } = store.getState() as State;
    let properties: Object = configConsole.JsonSchema.properties;
    let nuevo = insertParametersPreConfiguration(properties, parameters);

    const element = this.formData;
    let type = configConsole.JsonSchema.type;
    let editor;
    if (type == 'array') {
      editor = new JSONEditor(element, {
        ajax: true,
        template: 'hogan',
        collapsed:false,
        array_controls_top:true,
        disable_properties:true,
        iconlib: "fontawesome4",
        schema: {
          title: ' ',
          type: 'array',
          format: 'tabs-top',
          options: {
            disable_array_delete_all_rows: true,
          },
          items: {
            type: 'object',
            properties: properties,
          },
        },
      }); 
      this.editor = editor;
      editor.setValue([{ name: 'John Smith' }, { name: 'wladimir' }]);
      var value = this.editor.getValue();
      console.log(value.name); // Will log "John Smith"
      //this.styleButtonRow()
    } else {
      editor = new JSONEditor(element, {
        ajax: true,
        no_additional_properties:true,
        keep_oneof_values:true,
        disable_edit_json: true,
        disable_collapse: true,
        disable_properties: true,
        prompt_before_delete:true,
        schema: {
          title: ' ',
          type: 'object',
          format: 'grid',
          properties: nuevo,
        },
      });
      this.editor = editor;
    }

    await setTimeout(function () {
      console.log('');
    }, 500);
    console.log('setTimeout() Ejemplo...');

    /*const objecto1 = configConsole;
    const objecto2 = { editor: this.editor };
    Object.assign(objecto1, objecto2);*/
    /*store.dispatch(
      ConfigConsole({
        editor: objecto1,
        ...configConsole,
      })
    );*/
  }


  async refrescar() {
    const divEditor = this.formData;
    divEditor.style.display = "none";
    divEditor.removeAttribute("id");
    //var div = document.createElement('div');
    //div.setAttribute("id", "editorHolder");

    const divClass = this.divClass;
    divClass.innerHTML = '<div id="editorHolder"></div>'; 
    await this.listPreConfiguration();
    // obtenemos json-schema de la plantilla para configurar (parametrizar)
    await this.getConfiguration();
    try {
      const { configConsole } = store.getState() as State;

      const request: ConfigurationApiGetConfigurationRequest = {
        brand: configConsole.brand,
        serial: configConsole.serial,
        appId: configConsole.appId,
        templateId: configConsole.templateId,
      }; 
      console.log("DATOS CONFIGURATION",request);
      console.log("REFRESCAR")
      await this.configurationApi
        .getConfiguration(request)
        .then(response => response.data)
        .then(data => {
          const prueba = addDefault(
            configConsole.JsonSchema.properties,
            data.parameters ? data.parameters : {}
          );
          const element = this.formData;
          let type = configConsole.JsonSchema.type;
          let editor;
          if (type == 'array') {
            console.log("ARRAY set value",data.parametersArray)
            this.editor.setValue(data.parametersArray);
          } else {
            console.log("OBJECT set value",data.parameters)
            this.editor.setValue(data.parameters);
            this.esconderDiv()
          }

          return true;
        })
      } catch (error:any) {
        console.log(error);
        //errorNotification(error.toString());
      }


  }

  async cargarData() {
    this.verificarUserEdit();
    this.loading = false;
    // await this.changeInputData();
  }

  async getTemplate(id: string) {
    var resTer: AxiosResponse;
    try {
      resTer = await this.templateApi.getTemplate({ id });
      this.plantillas = [
        {
          name: resTer.data.name,
          description: resTer.data.description,
          id,
        },
      ];
      this.JsonSchema = await resTer.data.schema;
    } catch (error:any) {
      console.log(error);
      //errorNotification(error.toString());
    } finally {
      if (!this.plantillas)
        this.plantillas = [
          {
            name: 'plantilla-1',
            id: '12',
            description: 'prueba1',
          },
        ];
    }
  }

  async verificarUserEdit() {
    const { usuarioEdit, plantilla, nombrePlantilla } =
      store.getState() as State;
    this.checks = new Array(plantilla?.length);
    this.token = plantilla?.length;
    this.nombrePlantilla = 'Nombre de la plantilla';
    this.editUser = usuarioEdit;
  }


  render() {
    return html`
      <main class="w-full flex flex-col overflow-auto" style="height: 28rem;">
        <div class="flex justify-end">
          <!-- <select style="width: 20%">
          ${this.preConfiguration?.map(
            (camp: any, index: number) => html` 
              <option value="value1">${camp.name}</option>
            `
          )}
          </select> -->
          <!-- <select-tag
          style="width: 20%"
                label="Version"
                id="versionSelect"
                .options=${this.preConfiguration?.map(
            (v: any, index: number) => ({
              value: v.id,
              label: v.name,
            })
          )}
                @change=${(ev: CustomEvent) => {
            console.log(ev.detail);
            this.getPreConfiguration(ev.detail);
          }}
                >
          </select-tag> -->
          <custom-select

            style="width: 20%"
            label="Pre-Configuracion"
            id="versionSelect"
            .options=${this.preConfiguration?.map((v: any, index: number) => ({
              value: v.id,
              label: v.name,
            }))}
            @change=${(ev: CustomEvent) => {
              console.log(ev.detail);
              this.getPreConfiguration(ev.detail);
            }}
          >
          </custom-select>
        </div>
        <mwc-icon-button 
                  icon="autorenew"
                  class="absolute top-0 right-0"
                  @click=${() => {
                   this.refrescar()
                  }}
                ></mwc-icon-button>

                <div id="agregar" class="w-full h-full px-5 py-4 flex flex-wrap items-start">
                  <div id="editorHolder"></div>
                </div>
                ${this.loading !== true
          ? html`
                `
          : html`
              <mwc-circular-progress
                indeterminate
                density=${20}
              ></mwc-circular-progress>
            `}
      </main>
      <name-template id="cambiarNombrePlantilla"></name-template>
    `;
  }
}
