/* eslint-disable prettier/prettier */
import { html, LitElement, PropertyValueMap } from 'lit';
import { customElement, query, state } from 'lit/decorators.js';

import '@material/mwc-tab';
import '@material/mwc-tab-bar';
import '@material/mwc-list/mwc-list.js';
import '@material/mwc-list/mwc-list-item.js';
import '@material/mwc-switch';
import '@material/mwc-textarea';
import '@material/mwc-button';
import '@material/mwc-select';
import Swal from 'sweetalert2';
import { apisConfigurator } from '../../utils/axios';
import {
  AppApi, 
  AppApiCreateAppRequest,
  AppList,
  RequestAppCreate,
  AffiliationApi,
  AffiliationApiCreateAffiliationRequest,
  RequestAffiliationCreate
} from '../../../api';
import { errorNotification } from '../../services/notifications';
import router from '../../router/router';
import { TextField } from '@material/mwc-textfield';
import store from '../../redux/store';
import { appAdded } from '../../redux/actions/app';

@customElement('create-afiliation-page')
export class CreateAfiliationPage extends LitElement {
  private appApi: AppApi;
  private affiliationApi: AffiliationApi;

  @state()
  private loading = false;

  @state()
  private appId:string = "";

  @state()
  private typeFolio:any = [] ;

  @state()
  private preConfig: any[] = []

  @state()
  app?: any;

  @state()
  razonSocial?: any;

  @state()
  private apps: AppList[] = [];

  @state()
  private folioTypeId:any = "" ;

  get affiliationId() {
    return document.getElementById('affiliationId') as TextField;
  }

  get businessId() {
    return document.getElementById('businessId') as HTMLInputElement;
  }

  get name() {
    return document.getElementById('name') as HTMLInputElement;
  }

  get contactName() {
    return document.getElementById('contactName') as HTMLInputElement;
  }

  get appSelected() {
    return document.getElementById('appSelect') as any;
  }

  get contactEmail() {
    return document.getElementById('contactEmail') as HTMLInputElement;
  }

  get contactPhone() {
    return document.getElementById('contactPhone') as HTMLInputElement;
  }

  get arf() {
    return document.getElementById('RFC') as HTMLInputElement;
  }

  constructor() {
    super();
    this.appApi = new AppApi(...apisConfigurator('Crear App'));
    this.affiliationApi = new AffiliationApi(...apisConfigurator('Crear Afiliacion'));
  }

  protected firstUpdated(){
    let appId:string = localStorage.getItem('appId') ?? "";
    let razonSocial:any= localStorage.getItem('razonSocial') ?? "";
    this.razonSocial = razonSocial;
    console.log(this.razonSocial) 
    this.appId = appId
    //this.getApps()
  }

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  buildApp(): RequestAffiliationCreate {
    return {
      affiliationId: this.affiliationId.value,
      appId:this.appId,
      businessId: this.razonSocial,
      name: this.name.value,
      contactName: this.contactName.value,
      contactEmail: this.contactEmail.value,
      contactPhone: this.contactPhone.value,
      taxNumber:"",
      savedConfigurations:[]
    };
  }

  async onSubmit(ev: Event) {
    ev.preventDefault();
    this.loading = true;

    /*if (!this.validateForm()) {
      this.loading = false;
      return;
    }*/ 

    const data: AffiliationApiCreateAffiliationRequest = {
      requestAffiliationCreate: this.buildApp(),
    };

    console.log(data)
    try {
      const res = await this.affiliationApi.createAffiliation(data);
      if (res.status == 200) {
        await Swal.fire('Agregado!', 'La rafiliacion ha sido registrada', 'success');
        router.cambiarSeccion('afiliaciones-list');
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  }

  /*validateForm() {
    let x = document.forms.namedItem('createAppForm')!;

    if (!x.checkValidity()) {
      errorNotification('Por favor, revise los datos.');
      return false;
    }

    if (!this.name.checkValidity()) {
      errorNotification('El nombre de la app es requerido.');
      return false;
    }

    if (!this.packageName.checkValidity()) {
      errorNotification('El nombre del paquete de la app es requerido.');
      return false;
    }

    if (!this.developer.checkValidity()) {
      errorNotification('El desarrollador de la app es requerido.');
      return false;
    }

    if (!this.emailDeveloper.checkValidity()) {
      errorNotification('El correo del desarrollador es requerido.');
      return false;
    }

    if (!this.briefDescription.checkValidity()) {
      errorNotification('Ingresa una descripcion corta valida.');
      return false;
    }

    if (!this.descripcion.checkValidity()) {
      errorNotification('Ingresa una descripcion valida.');
      return false;
    }

    return true;
  }*/

  render() { 
    return html`
      <main class="w-full min-h-full">
        <div class="w-full px-5 py-4 flex flex-wrap items-start">
          <form
            name="createAppForm"
            @submit=${(e: any) => this.onSubmit(e)}
            class="w-full relative flex flex-col flex-wrap overflow-auto mb-2 bg-white shadow-md rounded-md pt-5 px-4 border border-solid border-slate-300"
          >
            <h2 class="text-2xl text-blue-600 font-bold uppercase">
              Crear Afiliacion
            </h2>
            <div class="flex flex-wrap my-4 mx-4">
              <div class="mx-2">
                <mwc-textfield
                  required
                  autoValidate
                  outlined
                  iconTrailing="close"
                  label="Afiliacion"
                  id="affiliationId"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                  minLength="3"
                  class="mx-2"
                  validationMessage="El id debe tener al menos 3 caracteres"
                ></mwc-textfield>
              </div> 
              <div class="mx-2">
                <mwc-textfield
                  required
                  disabled
                  autoValidate
                  outlined
                  iconTrailing="close"
                  label="Nombre de la Razón Social"
                  id="businessId"
                  .value=${this.razonSocial ?? ''}
                  minLength="3"
                  class="mx-2"
                  validationMessage="El nombre debe tener al menos 3 caracteres"
                ></mwc-textfield>
              </div> 
              <div class="mx-2">
                <mwc-textfield
                  required
                  autoValidate
                  outlined
                  iconTrailing="close"
                  id="name"
                  label="Nombre"
                  min="3"
                  class="mx-2"
                  validationMessage="El nombre debe tener al menos 3 caracteres"
                ></mwc-textfield>
              </div>
              <div class="mx-2">
                <mwc-textfield
                  required
                  autoValidate
                  outlined
                  iconTrailing="close"
                  id="contactName"
                  label="Nombre Contacto"
                  class="mx-2"
                  validationMessage="El nombre debe tener al menos 3 caracteres"
                ></mwc-textfield>
              </div>

              <div class="mx-2">
                <mwc-textarea
                required
                  autoValidate
                  outlined
                  iconTrailing="close"
                  id="contactEmail"
                  label="Email del Contacto"
                  class="mx-2"
                  type="email"
                >
                </mwc-textarea>
              </div>
              <div class="mx-2">
                <mwc-textarea
                  required
                  class="mx-2" 
                  validationMessage="el tax debe tener al menos 6 caracteres"
                  label="contactPhone"
                  id="contactPhone"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                  minLength="6"
                  autoValidate
                  outlined
                > 
                </mwc-textarea>
              </div>
              <!--<div class="mx-2">
                <mwc-textarea
                  required
                  class="mx-2"
                  validationMessage="el tax debe tener al menos 6 caracteres"
                  label="RFC"
                  id="RFC"
                  minLength="6"
                  autoValidate
                  outlined
                > 
                </mwc-textarea>
              </div>-->
            </div>
            <div class="flex justify-start">
            <button
              @click="${() => router.cambiarSeccion('afiliaciones-list')}"
              id="cancelarCrearAPP"
              class="w-48 rounded-md border-none bg-red-600 text-white py-3 text-xl mt-4 mx-auto hover:bg-red-700 hover:shadow-lg mb-4">
              CANCELAR
            </button> 
            <button
              type="submit"
              id="guardarCrearAPP"
              class="w-48 rounded-md border-none bg-blue-600 text-white py-3 text-xl mt-4 mx-auto hover:bg-blue-700 hover:shadow-lg mb-4"
              .disabled=${this.loading}
            >
              Guardar
            </button>

            </div>

          </form>
        </div>
      </main>
    `;
  }
}
