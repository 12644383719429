/* eslint-disable prettier/prettier */
import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('custom-card')
export class CustomCard extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  render() {
    return html`
      <div
        class="h-full w-full bg-slate-50 border border-solid border-slate-300"
      >
        <slot></slot>
      </div>
    `;
  }
}

/* /* eslint-disable prettier/prettier *
import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('custom-card')
class CustomCard extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  render() {
    return html``;
  }
}
 */
