/* tslint:disable */
/* eslint-disable */
/**
 * TMS Agnostiko
 * Tiene la definición de todos los servicios de TMS a Agnostic (para uso en la web o externo)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { FileInfoList } from '../models';
// @ts-ignore
import { Message } from '../models';
// @ts-ignore
import { RequestFileCreate } from '../models';
// @ts-ignore
import { RequestFileUpdate } from '../models';
// @ts-ignore
import { ResponseCreateFile } from '../models';
/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary crear archivo asociado a una aplicación
         * @param {RequestFileCreate} requestFileCreate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFile: async (requestFileCreate: RequestFileCreate, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestFileCreate' is not null or undefined
            assertParamExists('createFile', 'requestFileCreate', requestFileCreate)
            const localVarPath = `/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestFileCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary eliminar de un archivo
         * @param {string} appId 
         * @param {string} fileId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile: async (appId: string, fileId: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('deleteFile', 'appId', appId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('deleteFile', 'fileId', fileId)
            const localVarPath = `/file/{appId}/{fileId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary archivo de una aplicación
         * @param {string} appId 
         * @param {string} fileId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFile: async (appId: string, fileId: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getFile', 'appId', appId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('getFile', 'fileId', fileId)
            const localVarPath = `/file/{appId}/{fileId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Listado de archivos asociados a una aplicación
         * @param {string} appId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFiles: async (appId: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('listFiles', 'appId', appId)
            const localVarPath = `/file/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (appId !== undefined) {
                localVarQueryParameter['appId'] = appId;
            }

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary actualizar archivo de una aplicación
         * @param {string} appId 
         * @param {string} fileId 
         * @param {RequestFileUpdate} requestFileUpdate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFile: async (appId: string, fileId: string, requestFileUpdate: RequestFileUpdate, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('updateFile', 'appId', appId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('updateFile', 'fileId', fileId)
            // verify required parameter 'requestFileUpdate' is not null or undefined
            assertParamExists('updateFile', 'requestFileUpdate', requestFileUpdate)
            const localVarPath = `/file/{appId}/{fileId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestFileUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary upload file
         * @param {string} appId 
         * @param {string} fileId 
         * @param {File} file 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: async (appId: string, fileId: string, file: File, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('uploadFile', 'appId', appId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('uploadFile', 'fileId', fileId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadFile', 'file', file)
            const localVarPath = `/file/{appId}/{fileId}/uploadFile`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary crear archivo asociado a una aplicación
         * @param {RequestFileCreate} requestFileCreate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFile(requestFileCreate: RequestFileCreate, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCreateFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFile(requestFileCreate, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FileApi.createFile']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary eliminar de un archivo
         * @param {string} appId 
         * @param {string} fileId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFile(appId: string, fileId: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFile(appId, fileId, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FileApi.deleteFile']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary archivo de una aplicación
         * @param {string} appId 
         * @param {string} fileId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFile(appId: string, fileId: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileInfoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFile(appId, fileId, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FileApi.getFile']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Listado de archivos asociados a una aplicación
         * @param {string} appId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFiles(appId: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FileInfoList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listFiles(appId, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FileApi.listFiles']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary actualizar archivo de una aplicación
         * @param {string} appId 
         * @param {string} fileId 
         * @param {RequestFileUpdate} requestFileUpdate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFile(appId: string, fileId: string, requestFileUpdate: RequestFileUpdate, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFile(appId, fileId, requestFileUpdate, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FileApi.updateFile']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary upload file
         * @param {string} appId 
         * @param {string} fileId 
         * @param {File} file 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFile(appId: string, fileId: string, file: File, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFile(appId, fileId, file, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FileApi.uploadFile']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileApiFp(configuration)
    return {
        /**
         * 
         * @summary crear archivo asociado a una aplicación
         * @param {FileApiCreateFileRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFile(requestParameters: FileApiCreateFileRequest, options?: AxiosRequestConfig): AxiosPromise<ResponseCreateFile> {
            return localVarFp.createFile(requestParameters.requestFileCreate, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary eliminar de un archivo
         * @param {FileApiDeleteFileRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile(requestParameters: FileApiDeleteFileRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.deleteFile(requestParameters.appId, requestParameters.fileId, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary archivo de una aplicación
         * @param {FileApiGetFileRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFile(requestParameters: FileApiGetFileRequest, options?: AxiosRequestConfig): AxiosPromise<FileInfoList> {
            return localVarFp.getFile(requestParameters.appId, requestParameters.fileId, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Listado de archivos asociados a una aplicación
         * @param {FileApiListFilesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFiles(requestParameters: FileApiListFilesRequest, options?: AxiosRequestConfig): AxiosPromise<Array<FileInfoList>> {
            return localVarFp.listFiles(requestParameters.appId, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary actualizar archivo de una aplicación
         * @param {FileApiUpdateFileRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFile(requestParameters: FileApiUpdateFileRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.updateFile(requestParameters.appId, requestParameters.fileId, requestParameters.requestFileUpdate, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary upload file
         * @param {FileApiUploadFileRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(requestParameters: FileApiUploadFileRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.uploadFile(requestParameters.appId, requestParameters.fileId, requestParameters.file, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createFile operation in FileApi.
 * @export
 * @interface FileApiCreateFileRequest
 */
export interface FileApiCreateFileRequest {
    /**
     * 
     * @type {RequestFileCreate}
     * @memberof FileApiCreateFile
     */
    readonly requestFileCreate: RequestFileCreate

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof FileApiCreateFile
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteFile operation in FileApi.
 * @export
 * @interface FileApiDeleteFileRequest
 */
export interface FileApiDeleteFileRequest {
    /**
     * 
     * @type {string}
     * @memberof FileApiDeleteFile
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof FileApiDeleteFile
     */
    readonly fileId: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof FileApiDeleteFile
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for getFile operation in FileApi.
 * @export
 * @interface FileApiGetFileRequest
 */
export interface FileApiGetFileRequest {
    /**
     * 
     * @type {string}
     * @memberof FileApiGetFile
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof FileApiGetFile
     */
    readonly fileId: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof FileApiGetFile
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for listFiles operation in FileApi.
 * @export
 * @interface FileApiListFilesRequest
 */
export interface FileApiListFilesRequest {
    /**
     * 
     * @type {string}
     * @memberof FileApiListFiles
     */
    readonly appId: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof FileApiListFiles
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateFile operation in FileApi.
 * @export
 * @interface FileApiUpdateFileRequest
 */
export interface FileApiUpdateFileRequest {
    /**
     * 
     * @type {string}
     * @memberof FileApiUpdateFile
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof FileApiUpdateFile
     */
    readonly fileId: string

    /**
     * 
     * @type {RequestFileUpdate}
     * @memberof FileApiUpdateFile
     */
    readonly requestFileUpdate: RequestFileUpdate

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof FileApiUpdateFile
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for uploadFile operation in FileApi.
 * @export
 * @interface FileApiUploadFileRequest
 */
export interface FileApiUploadFileRequest {
    /**
     * 
     * @type {string}
     * @memberof FileApiUploadFile
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof FileApiUploadFile
     */
    readonly fileId: string

    /**
     * 
     * @type {File}
     * @memberof FileApiUploadFile
     */
    readonly file: File

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof FileApiUploadFile
     */
    readonly acceptLanguage?: string
}

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI {
    /**
     * 
     * @summary crear archivo asociado a una aplicación
     * @param {FileApiCreateFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public createFile(requestParameters: FileApiCreateFileRequest, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).createFile(requestParameters.requestFileCreate, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary eliminar de un archivo
     * @param {FileApiDeleteFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public deleteFile(requestParameters: FileApiDeleteFileRequest, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).deleteFile(requestParameters.appId, requestParameters.fileId, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary archivo de una aplicación
     * @param {FileApiGetFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public getFile(requestParameters: FileApiGetFileRequest, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).getFile(requestParameters.appId, requestParameters.fileId, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Listado de archivos asociados a una aplicación
     * @param {FileApiListFilesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public listFiles(requestParameters: FileApiListFilesRequest, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).listFiles(requestParameters.appId, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary actualizar archivo de una aplicación
     * @param {FileApiUpdateFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public updateFile(requestParameters: FileApiUpdateFileRequest, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).updateFile(requestParameters.appId, requestParameters.fileId, requestParameters.requestFileUpdate, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary upload file
     * @param {FileApiUploadFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public uploadFile(requestParameters: FileApiUploadFileRequest, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).uploadFile(requestParameters.appId, requestParameters.fileId, requestParameters.file, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}

