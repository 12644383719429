/* eslint-disable prettier/prettier */

import { LitElement, html, render } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import { customElement, property, state } from 'lit/decorators.js';
import { guard } from 'lit/directives/guard.js';
import '@vaadin/vaadin-grid';
import '@ui5/webcomponents/dist/Dialog.js';
import { connect } from 'pwa-helpers';
import '@vaadin/vaadin-select';
import {  
  ConfigurationApi,
  ConfigurationApiCreateConfigurationRequest,
  ConfigurationApiUpdateConfigurationRequest,
  AppApi,
  AppList,
  TemplateApi,
  FolioApi,
    TemplateInfo
} from '../../../api';
import {
  Grid,
  GridActiveItemChangedEvent,
  GridItemModel,
} from '@vaadin/vaadin-grid';
import { ConfigConsole, PageFolio } from '../../redux/actions';
import store from '../../redux/store';
import { State } from '../../redux/types';
import { AxiosResponse } from 'axios';
import { JSONEditor } from '@json-editor/json-editor';
import { iterarCampoJson2, validacionRequired } from '../../utils/functions';
import {
  errorNotification,
  succesNotification,
} from '../../services/notifications';
import { apisConfigurator } from '../../utils/axios';
import { agregarPlantilla, Enumm } from '../../redux/actions';

@customElement('listado-plantilla-exportacion')
export class ListadoPlantillaExportacion extends connect(store)(LitElement) {
  private appApi: AppApi;
  private configurationApi: ConfigurationApi;
  private templateApi: TemplateApi;
  private folio: FolioApi;

  constructor() {
    super();
    this.appApi = new AppApi(...apisConfigurator('Aplicaciones'));
    this.configurationApi = new ConfigurationApi(...apisConfigurator(''));
    this.templateApi = new TemplateApi(...apisConfigurator(''));
    this.folio = new FolioApi(
        ...apisConfigurator('Folio')
      );
  }

  @state()
  private apps: AppList[] = [];

  @state()
  private folioList: any = [];

  @state()
  plantillas: Object[] = [];

  @state()
  private detailsOpenedItem: AppList[] = [];

  @state()
  loader = true;

  @state()
  private page = 2;

  @state()
  private AppId: any;

  @state()
  private app: any;

  @state()
  private DataJson: any;

  @state()
  private ObjectJson: any;

  @state()
  private loading = false;

  @state()
  private habilitarButton = true;

  @state()
  brand = '';

  @state()
  serial = '';

  @state()
  plantillaId = '';

  @state()
  existConfig: boolean = false;

  @state()
  JsonSchema: any;

  @state()
  nameTemplate: any;

  @state()
  band: any;

  @state()
  private controlPlantilla: any[] = [
    {
      id: 1,
      name: '',
      type: '',
      lengthMinimo: 0,
      lengthMaximo: 0,
      description: '',
      pattern: '',
      required: false,
    }

  ];

  @state()
  editor?: any;

  protected firstUpdated(): void {
    this.getTemplates()
  }

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  agregarCampoNuevo() {
    let plantilla = this.controlPlantilla;
    console.log(plantilla.length);
    let id = plantilla?.length ? plantilla?.length + 1 : 0 + 1;
    const campoNuevo = {
      id,
      name: '',
      type: '',
      lengthMinimo: 0,
      lengthMaximo: 0,
      description: '',
      pattern: '',
      required: false,
    };
    console.log(plantilla)
    plantilla?.push(campoNuevo);
    //console.log(ordenarAsc(plantilla));
    store.dispatch(agregarPlantilla(plantilla ? plantilla : [{}]));
    this.controlPlantilla = plantilla ? plantilla : [{}];
    console.log(this.controlPlantilla);
  }

  esconderDiv() {
    const buttonEliminar = this.buttonEliminar;
    const buttonAgregar = this.buttonAgregar;
    const tableTop = this.tableTop;
    console.log(buttonEliminar)
    const span:any = buttonEliminar.querySelector('span');
    const span2:any = buttonAgregar.querySelector('span');
    console.log(span)
    span.innerHTML="Delete";
    span2.innerHTML="Add";
    tableTop.style.border = 'none';

  }

  appSelected(index:number) {
    return document.getElementById(`type${index}`) as any;
  }

  private get buttonAgregar() {
    return document.querySelector('.json-editor-btn-add') as HTMLInputElement;
  }

  private get tableTop() {
    return document.querySelector('.je-tabholder--top') as HTMLInputElement;
  }

  private get buttonEliminar() {
    return document.querySelector('.json-editor-btn-subtract') as HTMLInputElement;
  }

  private get dialog() {
    return document.getElementById('dialog-listado-app2')! as any;
  }

  private get formData() {
    return document.getElementById('editorHolder') as any;
  }

  private get divClass() {
    return document.getElementById('page2') as any;
  }

  async open() {

    this.page = 1;

    this.dialog.open = true;
  }

  close() {
    this.dialog.open = false;
    this.page = 1 ;
    const divClass = this.divClass;
    divClass.innerHTML = ``; 
  }

  async getConfiguration(idTemplate:string) {
    const res:any = await this.templateApi.getTemplate({id:idTemplate})
    console.log(res)

  }


  async getTemplates() {
    this.loader = true;
    var resTer: AxiosResponse;
    try {
      resTer = await this.templateApi.listTemplate({});
      //console.log(resTer)
      this.plantillas = resTer.data;
      const templates = resTer.data;
    } catch (error) {
    } finally {
      if (!this.plantillas)
        this.plantillas = [
          {
            name: 'plantilla-1',
            id: '12',
            description: 'prueba1',
          },
        ];
      this.loader = false;
    }
  }



  protected render() {
    return html` 
      <ui5-dialog
        id="dialog-listado-app2" 
        class="w-8/12"
        style="height:600px;border-radius: 15px;"
      >
        <main class="w-full h-full flex ">
          <section class="w-full flex flex-col pl-6  max-h-full">
            <section class="w-full flex my-2">
              <div class="container ml-4 w-full flex flex-row justify-between">
                <div class="nameApp">
                  <h2 class="text-3xl">${this.page == 3 ? html`${this.nameTemplate}` : html`Exportacion Masiva`}</h2>
                </div>
                ${this.page !== 3
                  ? html` 

                    `
                  : ``}
              </div>
            </section>
            ${this.page == 1
              ? html`
              <main class="w-full h-full flex overflow-auto ">
              <div class="w-full h-full px-5 py-4 flex flex-wrap  items-start">
                <form
                  class="w-full h-full relative mb-2 SectionList rounded-md pt-5px-4 "
                >
                <div class="header-tr">
                <div class="mx-2  TexField ">
                  <h1></h1>
                </div>
                <div class="mx-2 ml-3.5 TexField">
                  <h1>Tipo</h1>
                </div>
                <div class="mx-2 ml-3.5 TexField">
                  <h1></h1>
                </div>
                <div class="mx-2 ml-3.5 TexField">
                  <h1></h1>
                </div>
                <div class="mx-2  TexField">
                  <h1></h1>
                </div>
                <div class="mx-2  TexField">
                  <h1></h1>
                </div>
                <div class="mx-2  TexField">
                  <h1></h1>
                </div>
                <!-- <div class="mx-2  TexField">
                      <h1>Enum</h1>
                  </div> -->
              </div>
              ${repeat(
                this.controlPlantilla,
                (usuario: any) => usuario.id,
                (usuario: any, index: number = 1) => html`
                  <div class="flex flex-wrap my-4 mx-4">
                  <div class="mx-2 TexField">
                    <select
                      name="cars" 
                      id="type${usuario.id}"
                      class="style-select"
                      @change=${(ev: CustomEvent) => {
                       let appId = this.appSelected(usuario.id);
                       console.log(appId)
                      }}>
                    > 
                    <option disabled selected>Aplicaciones</option>
                  ${this.plantillas.map((app:any,index:number)=> html`
                      <option value="${app.id}">${app.name}</option>
                  `)}
                    </select>
                  </div>
                  <div class="mx-2 TexField">
                  <mwc-textfield
                    id="lengthMinimo"
                    type="number"
                    label="valor"
                    class="mx-2"
                    outlined
                    iconTrailing="close"
                    @keyup="${(ev: InputEvent) => {
                      console.log("hla")
                    }}"
                  >
                  </mwc-textfield>
                </div>
                
                  </div>
                  
                `
              )}
              <div class="flex flex-wrap my-4 mx-4 button-new-campo">
              <mwc-button
                outlined
                class="rounded"
                @click=${() => {
                  this.agregarCampoNuevo()
                }}
                ><mwc-icon>add_circle</mwc-icon> Agregar nuevo campo</mwc-button
              >
            </div>
                </form>
                </div>
                </main>
                `
              : html``}
                <div id="page2"></div>
            <div class="flex flex-row-reverse">
                ${ this.page == 1 ? 
                    html`
                    <button
                        @click=${() => {
                            this.close();
                        }}
                        class="rounded-3xl mx-3 my-3 mr-2 border w-28  border-indigo-500 text-blue-500  CancelarG  hover:shadow-md hover:bg-slate-300 text-xs	"
                    >
                        Cancelar
                    </button>` : html``
                }
                ${ this.page == 2 ? 
                    html`
                    <button
                        .disabled=${false}
                        @click=${() => {

                        }}
                        class="rounded-3xl mx-3 my-3 w-28 InstalarApp	"
                    >
                        Guardar
                    </button>
                    <button
                        @click=${() => {
                            this.page = 1;
                            const divClass = this.divClass;
                            divClass.innerHTML = ``; 
                        }}
                        class="rounded-3xl mx-3 my-3 mr-2 border w-28  border-indigo-500 text-blue-500  CancelarG  hover:shadow-md hover:bg-slate-300 text-xs	"
                    >
                    Atras
                    </button>
                    ` : html``
                }

                
            </div>
          </section>
        </main>

      </ui5-dialog>
    `;
  }
}
