/* eslint-disable prettier/prettier */

import { LitElement, html,render } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '@ui5/webcomponents/dist/Dialog.js';
import '@vaadin/vaadin-select';
import { connect } from 'pwa-helpers';
import { ConfigConsole,PageFolio } from '../../redux/actions';
import {
  CommunicationTerminalApi,
  FolioApi,
  CommunicationTerminalApiRequestCommandRequest,
  RequestCommandTypeCommandEnum,
  Terminal,
  AffiliationApi,
  AffiliationApiCreateAffiliationRequest,
  RequestAffiliationCreate
} from '../../../api';
import Swal from 'sweetalert2';
import store from '../../redux/store';
import { State } from '../../redux/types';
import { nombrePlantilla, descripcionPlantilla } from '../../redux/actions';
import { errorNotification } from '../../services/notifications';
import { apisConfigurator } from '../../utils/axios';

@customElement('crear-afiliacion')
export class CrearAfiliacion extends connect(store)(LitElement) {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private communicationApi: CommunicationTerminalApi;
  private affiliationApi: AffiliationApi;

  private folio: FolioApi;

  constructor() {
    super();

    this.communicationApi = new CommunicationTerminalApi(
      ...apisConfigurator('Comunicación')
    );
    this.affiliationApi = new AffiliationApi(...apisConfigurator('Crear Afiliacion'));

    this.folio = new FolioApi(
      ...apisConfigurator('Folio')
    );
  }

  @property({ type: Object })
  terminal?: Terminal;

  @state()
  private titulo = '';

  @state() 
  private preConfig: any[] = []

  @state()
  private app:any;

  @state()
  private affiliationId = '';

  @state()
  private appId = '';

  @state()
  private businessId = '';

  @state()
  private name = '';

  @state()
  private contactName = '';

  @state()
  private contactEmail = '';

  @state()
  private contactPhone = '';

  @state() 
  private arf = '';

  private get dialog() {
    return document.getElementById('crear-afiliacion')! as any;
  }

  protected firstUpdated(): void {}

  async getOrganizaciones() {}

  async open(app: any,afiliacion:any) {
    if (!app) {
      errorNotification('Error al obtener la app ');
      return;
    }
    let appId:string = localStorage.getItem('appId') ?? "";
    console.log("APP",appId)
    this.appId = appId; 
    let businessId:string = localStorage.getItem('businessId') ?? "";
    this.businessId = businessId;
    //this.getOrganizaciones();

    this.app = app;
    this.dialog.open = true;
  }

  close() {
    this.dialog.open = false;
    this.terminal = undefined;
  }

  private async handleSubmit(e: Event) {
    const { configConsole } = store.getState() as State;
    store.dispatch(
      ConfigConsole({
        crearFolio:this.app,
        band:true, 
        ...configConsole,
      })
    );
    e.preventDefault();

    let requestAffiliationCreate: RequestAffiliationCreate = {
      affiliationId: this.affiliationId,
      appId:this.appId,
      businessId: this.businessId,
      name: this.name,
      contactName: this.contactName,
      contactEmail: this.contactEmail,
      contactPhone: this.contactPhone,
      taxNumber:this.arf,
      savedConfigurations:[]
    }

    let data:AffiliationApiCreateAffiliationRequest = {
      requestAffiliationCreate
    }
    try {
      console.log(data)
      const res = await this.affiliationApi.createAffiliation(data);
  
      if(res.status == 200){
        Swal.fire(
          'Completado!',
          'La afiliacion ha sido registrada.',
          'success' 
        )
      }
    } catch (error:any) {
      errorNotification(error.toString());
    }

    //location.reload();

    this.close();
  }

  protected render() {
    return html` <ui5-dialog
      id="crear-afiliacion"
      header-text="Crear Afiliacion"
      class="w-3/12"
    >
      <div
        class="px-4 flex flex-col items-center"
      >
        <div class="mx-2 w-full flex flex-col items-center">

          <mwc-textfield
            class="my-2 w-3/4"
            outlined
            @change=${(e: any) => (this.affiliationId = e.target.value)}
            label="Afiliacion"
            oninput="this.value = this.value.replace(/[^0-9]/g, '')"
            id="affiliationId"
          ></mwc-textfield>
        </div>
        <div class="mx-2 w-full flex flex-col items-center">
          <label>Razon Social</label>
            <mwc-textfield
            class="my-2 w-3/4" 
            outlined
            disabled
            .value=${this.businessId ?? ''}
            @change=${(e: any) => (this.businessId = e.target.value)}
            label="Razon Social"
            ></mwc-textfield>
        </div>
        <div class="mx-2 w-full flex flex-col items-center">

            <mwc-textfield
            class="my-2 w-3/4"
            outlined
            @change=${(e: any) => (this.name = e.target.value)}
            label="Name"
            ></mwc-textfield>
        </div>
        <div class="mx-2 w-full flex flex-col items-center">

            <mwc-textfield
            class="my-2 w-3/4"
            outlined
            @change=${(e: any) => (this.contactName = e.target.value)}
            label="Nombre Contacto"
            ></mwc-textfield>
        </div>
        <div class="mx-2 w-full flex flex-col items-center">

            <mwc-textfield
            class="my-2 w-3/4"
            outlined
            type="email"
            @change=${(e: any) => (this.contactEmail = e.target.value)}
            label="contactEmail"
            ></mwc-textfield>
        </div>
        <div class="mx-2 w-full flex flex-col items-center">

            <mwc-textfield
            class="my-2 w-3/4"
            outlined
            oninput="this.value = this.value.replace(/[^0-9]/g, '')"
            @change=${(e: any) => (this.contactPhone = e.target.value)}
            label="contactPhone"
            ></mwc-textfield>
        </div>
        <!--<div class="mx-2 w-full flex flex-col items-center">

            <mwc-textfield
            class="my-2 w-3/4"
            outlined
            @change=${(e: any) => (this.arf = e.target.value)}
            label="RFC"
            ></mwc-textfield>
        </div>-->

        <!-- <vaadin-grid class="h-full" .items="${this.preConfig}">
            <vaadin-grid-sort-column
              .renderer="${(root: HTMLElement, _: any, item: any) => {
                render(
                  html`
                    <input 
                      class=""
                      type="radio"
                      name="platform"
                      @change="${async(ev: InputEvent) => {
                        //this.item=item.item.id;
                         //this.getConfigurations(item.item.id);
                      }}"
                    />
                  `,
                  root
                );
              }}"
            >
            </vaadin-grid-sort-column>
            <vaadin-grid-sort-column
              path="name"
              header="Nombre"
            ></vaadin-grid-sort-column>
           
          </vaadin-grid> -->




                <!-- <select name="cars" class="w-[210px] mx-1 mb-3" id="appSelect"
                @change=${(ev: CustomEvent) => {


                }}>
                    ${this.preConfig.map((app:any,index:number)=> html`
                        <option disabled selected></option>
                        <option value="${app.id}">${app.name}</option>
                    `)}
                </select>  -->

        </div>

        <div slot="footer" class="dialog-footer">
          <button
            class="text-red-600 p-4 rounded-md uppercase"
            id="closeDialogButton"
            @click=${() => {
              this.close();
            }}
          >
            Cerrar
          </button>
          <button
            class="text-blue-600 p-4 rounded-md uppercase"
            id="closeDialogButton"
            @click=${(e: Event) => {
              this.handleSubmit(e);
            }}
          >
            Guardar
          </button>
        </div>
      </div>
    </ui5-dialog>`;
  }
}
