import { put, takeLatest } from 'redux-saga/effects';
import { TemplateInfo, TemplateApi } from '../../../api';
import { apisConfigurator } from '../../utils/axios';
import { GET_TEMPLATES, TEMPLATES_RECEIVED } from '../actions/plantilla';
import { getCookie } from './../../services/cookie';
 
 
export function* getTemplates(): Generator {
  try {
    const userCookie:any = getCookie('tms_user');
    console.log("COOKIES",JSON.parse(userCookie));
    const templateApi = new TemplateApi(...apisConfigurator(''));
    console.log('getTemplates');
    const data = yield templateApi.listTemplate().then(res => res.data);
  
    console.log({ data });
    yield put({ type: TEMPLATES_RECEIVED, templates: data });
  } catch (error) {
    console.log(error)
  }

}

export function* templatesWatcher() {
  yield takeLatest(GET_TEMPLATES, getTemplates);
}
