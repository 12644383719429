/* eslint-disable prettier/prettier */
import { html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import '@material/mwc-textfield';
import { RequestLogin, SecurityApi } from '../../../api';
import { iniciarSesion } from '../../redux/actions';
import store from '../../redux/store';
import { Loader } from '../../components/loader/loader.js';
import '../../components/loader/loader.js';
import '../../styles.css';
import router from '../../router/router';
import { apisConfigurator } from '../../utils/axios';
@customElement('recovery-password')
export class RecoveryPassword extends LitElement {
  email = '';
  password = '';

  private securityApi!: SecurityApi;

  @state()
  mensajeError = '';
  Error = false;
  Validate = false;

  @state()
  loader = false;
  get editOrganizationModal() {
    return document.getElementById('modal-loader') as Loader;
  }
  protected firstUpdated(): void {
    //this.securityApi = new SecurityApi(...apisConfigurator('Iniciar sesión'));
  }

  private async handleSubmit(e?: Event) {
    e?.preventDefault();
    if (this.email === '' || this.password === '') {
      this.Error = true;
      this.mensajeError = 'No puede dejar campos vacios';
    } else {
      this.mensajeError = '';
      this.Error = false;

      this.editOrganizationModal.open();
      this.loader = true;
      if (!this.validateLogin()) return;
      const requestLogin: RequestLogin = {
        user: this.email,
        password: this.password,
      };
      try {
        const response = await this.securityApi.securityLogin({ requestLogin });
        console.log(response.data);
        if (!response) {
          this.editOrganizationModal.open();
        } else {
          this.editOrganizationModal.close();
          localStorage.setItem('token', response.data.token);
          console.log(response.data);
          store.dispatch(
            iniciarSesion(
              response.data.token,
              response.data.authorizedFunctions,
              response.data.name,
              response.data.activeMaster
            )
          );

          /* if (response.status === 200)  */
          router.cambiarSeccion('');
        }
      } catch (error) {
        this.editOrganizationModal.open();
        this.Error = true;
        if (error) {
          this.editOrganizationModal.close();
          this.Error = true;
          this.mensajeError = 'Usuario o contraseña invalida';
        }
      }
    }
  }
  inputEmail(e: any) {
    if (this.mensajeError != '') {
      this.mensajeError = '';
      this.Error = false;
    }
    this.email = e;
  }
  inputPassw(e: any) {
    const regex = new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9]).{12,}$");
    this.password = e;
    if(regex.test(this.password)){
      this.Error = false;
      this.mensajeError = '';
    }else{
      this.Error = true;
      this.mensajeError ='Debe contener al menos 1 letra, 1 numero y ser mayor a 12 caracteres';
    }
  }
  validateLogin() {
    const form = document.getElementById('login-form') as HTMLFormElement;
    const email = document.getElementById('email') as HTMLInputElement;
    const password = document.getElementById('password') as HTMLInputElement;
    if (email.value === '' || password.value === '') {
      this.Validate = true;
    } else {
      this.Validate = false;
      this.email = email.value;
      this.password = password.value;
      return form.checkValidity();
    }
  }

  render() {
    return html`
      <main class="w-full h-full  flex">
        <div class="w-3/5	 hidden md:block login-banner"></div>
        <div
          class="flex flex-col justify-center w-2/5 mx-auto mt-6 py-6 pl-28 px-8"
        >
          <!-- <img class="mt-8 mb-24" src="./logo.png" width="200" /> -->
          <h1 class="text-5xl text-bold">Recuperacion Contraseña<br /></h1>
          <form
            id="login-form"
            action="#"
            class="mt-12 login-form w-full flex flex-col"
            @submit=${(e: Event) => this.handleSubmit(e)}
          >
            <p class="text-gray-400 text-md">Indique su correo</p>

            <mwc-textfield
              class="rounded ${this.mensajeError !== ''
                ? 'redondeado'
                : ''}  mt-1test w-10/12"
              label="Correo"
              type="email"
              .value="${this.email}"
              id="email"
              outlined
              @input="${(e: any) => {
                this.inputEmail(e.target.value);
              }}"
              @keypress="${(ev: KeyboardEvent) => {
                if (ev.key === 'Enter') {
                  (
                    document.getElementById('password') as HTMLInputElement
                  ).focus();
                }
              }}"
            >
            </mwc-textfield>
            <style>
              mwc-textfield.redondeado {
                --mdc-text-field-outlined-idle-border-color: red;
              }
            </style>
            <!-- <mwc-textfield
              class="rounded ${this.mensajeError !== ''
              ? 'redondeado'
              : ''}  mt-8 w-10/12"
              label="Contraseña"
              type="password"
              .value="${this.password}"
              id="password"
              aria-errormessage="$1"
              outlined
              @input="${(e: any) => this.inputPassw(e.target.value)}"
              @keypress="${(ev: KeyboardEvent) => {
              if (ev.key === 'Enter') {
                this.handleSubmit(ev);
              }
            }}"
            >
            </mwc-textfield> -->

            <div class="Error">
              <p>
                ${this.Error === true ? html`${this.mensajeError}` : html``}
              </p>
            </div>

            <div class="my-4  mr-16 text-right text-gray-400">
              <a href="#"> ¿Olvidaste tu contraseña? </a>
            </div>

            <mwc-button
              @click="${(e: Event) => {
                this.handleSubmit(e);
              }}"
              class="mb-4 mt-2 rounded w-10/12"
              fullwidth
              raised
              >Enviar Token</mwc-button
            >
          </form>
        </div>

        <loader-general id="modal-loader"></loader-general>
      </main>
    `;
  }

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }
}
