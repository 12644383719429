/* eslint-disable prettier/prettier */
import { html, LitElement } from 'lit';
import { customElement, query, state } from 'lit/decorators.js';
 
import '@vaadin/vaadin-text-field';
import '@material/mwc-checkbox';
import { Checkbox } from '@material/mwc-checkbox';
import { Role, RoleApi, RoleApiSecurityRolUpdateRequest } from '../../../api';
import { apisConfigurator } from '../../utils/axios';
import router from '../../router/router';
import { errorNotification } from '../../services/notifications';
import { getCookie } from '../../services/cookie';
import Swal from 'sweetalert2';
import {permisos} from '../../../resources/Modulos'

@customElement('edit-rol-page')
export class EditarRol extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  @query('#name')
  nameInput!: HTMLInputElement;

  @query('#description')
  descriptionInput!: HTMLInputElement;

  @query('#organization')
  organizationSelect!: HTMLSelectElement;

  @query('#appsBox')
  appsBox!: Checkbox;

  @query('#terminalBox')
  terminalBox!: Checkbox;

  @query('#roleBox')
  roleBox!: Checkbox;

  @query('#usersBox')
  usersBox!: Checkbox;

  @query('#organizationBox')
  organizationBox!: Checkbox;

  @query('#groupsBox')
  groupsBox!: Checkbox;

  @state()
  modulos: string[] = [];

  @state()
  rolUser: string[] = [];

  @state()
  loading = true;

  @state()
  rol:any = "";

  @state()
  rolId:any = "";

  @state()
  modules:any = permisos;

  private rolesApi!: RoleApi;

  firstUpdated() {
    let role:string = localStorage.getItem('role') ?? "";
    this.rol = JSON.parse(role);
    let roleId:string = localStorage.getItem('roleId') ?? "";
    this.rolId = JSON.parse(roleId)
    const userCookie:any = getCookie('tms_user');
    let parseToken = JSON.parse(userCookie);
    this.rolUser = parseToken.authorizedFunctions;
    this.modulos = this.rol.functions;
    this.rolesApi = new RoleApi(...apisConfigurator('Rol'));
  }

  validateForm() {
    if (!this.nameInput.checkValidity()) {
      errorNotification('El nombre del grupo es requerido.');
      return false;
    }

    if (this.modulos.length === 0) {
      errorNotification('Al menos un modulo es requerido.');
      return false;
    }

    if (!this.descriptionInput.checkValidity()) {
      errorNotification('La descripcion del rol es requerida.');
      return false;
    }

    return true;
  }

  obtenerPorRol(rol:string) {
    return this.modules[rol] || rol;
  };

  async createRol() {
    if (!this.validateForm()) return;
    console.log(this.modulos)
    let role: Role = {
      name: this.nameInput.value,
      functions: this.modulos,
      description: this.descriptionInput.value,
    };

    let data:RoleApiSecurityRolUpdateRequest = {
        role,
        id:this.rolId
    }

    try { 
      const res = await this.rolesApi.securityRolUpdate(data);
      console.log(res.data);
      if (res.data.code == "role.update.ok") {
        await Swal.fire('Actualizado!', 'El rol ha sido actualizado', 'success');
        router.cambiarSeccion('role-list');
      }
      this.nameInput.value = '';
      this.descriptionInput.value = '';

      this.modulos = [];

    } catch (err) {
      errorNotification('Error al obtener los datos');
    }
  }

  render() {
    return html`
      <main class="w-full h-full flex">
        <div class="w-full  px-5 py-4">
          <form
          @submit="${(e: any) => {
            e.preventDefault();
            this.createRol();
          }}"
            class="w-full flex flex-col h-full overflow-auto bg-white rounded-md py-5 px-8 border border-solid border-slate-300"
          >
          <div class="flex">
            
            <h1 class="text-2xl font-semibold text-blue-600">Editar Rol</h1>
            
          </div>
            
            <div class="flex flex-wrap my-4 mx-4">
                <div class="mx-2">
                <mwc-textfield
                  required
                  autoValidate outlined iconTrailing="close"
                  label="Nombre"
                  id="name"
                  .value=${this.rol.name ?? ''}
                  minLength="3"
                  class="mx-2"
                  validationMessage="El nombre debe tener al menos 3 caracteres"
                 
                ></mwc-textfield>
                </div>
                <div class="mx-2">
                  <mwc-textfield
                    required
                    autoValidate outlined iconTrailing="close"
                    label="Descripción"
                    id="description"
                    minLength="4"
                    .value=${this.rol.description ?? ''}
                    class="mx-2"
                    validationMessage="La descripción debe tener al menos 4 caracteres"
                  ></mwc-textfield>
                </div>
                <div class="mx-2 w-full">
                  <h3 class="text-2xl font-semibold text-blue-600">Acceso a Modulos</h3>
                  <div class="grid grid-cols-3 gap-4">
                  ${this.rolUser.map(
                      (rol:any, index:number) =>
                        html` 
                        <div class="flex m-2">
                    <mwc-checkbox id="terminalBox" value={rol} @change="${(
                      e: any
                    ) => {
                      console.log(rol);
                      if (!this.modulos.includes(rol))
                        this.modulos.push(rol);
                      else
                        this.modulos = this.modulos.filter(
                          m => m !== rol
                        );
                    }}" .checked="${this.rol.functions.includes(
      rol
    )}" ></mwc-checkbox>
                    <p class="m-2">${this.obtenerPorRol(rol)}</p>
                  </div>
                        `
                  )}
                </div>
              </div>
              </div>

              <div class="flex justify-start">
              <button
                  @click="${() => router.cambiarSeccion('role-list')}"
                  class="rounded-3xl border  border-indigo-500 text-blue-500  CancelarG   text-xs	">
                  CANCELAR
                </button>
                <mwc-button
              raised
                type="submit"
                class="rounded w-52"
                @click="${() => this.createRol()}"
              >
                Actualizar
              </mwc-button>
              </div>

            </form>
          </div>
        </div>
      </main>
    `;
  }
}
