/* eslint-disable prettier/prettier */
import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import Swiper from 'swiper';

@customElement('swiper-carousel')
export class SwiperCarousel extends LitElement {
  @property({ type: Array })
  slides: string[] = [
    'https://yt3.ggpht.com/a/AATXAJwxevWs-yioTNtOMh82p6aIUv7fQ-QJXyop_A=s900-c-k-c0xffffffff-no-rj-mo',
    'https://th.bing.com/th/id/R.a4151f2c491c2e15ed07bd95fd119af0?rik=cH%2bOOED839Janw&pid=ImgRaw&r=0',
    'https://th.bing.com/th/id/OIP.1-V89QXqse2R19xqCj1yFgHaFa?pid=ImgDet&rs=1',
  ];

  private swiper!: Swiper;

  protected firstUpdated(): void {
    this.swiper = new Swiper(
      this.shadowRoot?.getElementById('swiper-carousel')!,
      {
        slidesPerView: 'auto',
        spaceBetween: 10,
        loop: true,
        navigation: {
          nextEl: this.shadowRoot?.getElementById('button-next')!,
          prevEl: this.shadowRoot?.getElementById('button-prev')!,
        },
        mousewheel: true,
        keyboard: true,
      }
    );

    this.swiper.slideNext();
  }

  render() {
    return html`<link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/swiper/swiper-bundle.min.css"
      />

      <div class="swiper-carousel" id="swiper-carousel">
        <div class="swiper-wrapper">
          ${this.slides.map(
            slide => html`<div class="swiper-slide">
              <img src="${slide}" alt="slide" />
            </div>`
          )}
        </div>
        <div
          class="button-next"
          id="button-next"
          @click=${() => {
            this.swiper.slideNext();
          }}
        >
          <mwc-icon-button icon="arrow_forward_ios"></mwc-icon-button>
        </div>
        <div
          class="button-prev"
          id="button-prev"
          @click=${() => {
            this.swiper.slidePrev();
          }}
        >
          <mwc-icon-button icon="arrow_back_ios"></mwc-icon-button>
        </div>
      </div> `;
  }

  static styles = css`
    .swiper-carousel {
      width: 100%;
      height: 100%;
    }

    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .swiper-slide {
      width: 50%;
      text-align: center;
      font-size: 18px;
      background: #fff;

      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }

    .button-next,
    .swiper-rtl .button-prev {
      right: 10px;
      left: auto;
    }

    .button-prev,
    .swiper-rtl .button-next {
      left: 10px;
      right: auto;
    }

    .button-next,
    .button-prev {
      position: absolute;
      top: 50%;
      width: calc(var(--swiper-navigation-size) / 44 * 27);
      height: var(--swiper-navigation-size);
      margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: darkblue;
    }
  `;
}
