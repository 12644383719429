/* tslint:disable */
/* eslint-disable */
/**
 * TMS Agnostiko
 * Tiene la definición de todos los servicios de TMS a Agnostic (para uso en la web o externo)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface RequestCommand
 */
export interface RequestCommand {
    /**
     * 
     * @type {string}
     * @memberof RequestCommand
     */
    'typeCommand': RequestCommandTypeCommandEnum;
    /**
     * 
     * @type {string}
     * @memberof RequestCommand
     */
    'commandId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RequestCommand
     */
    'parameters'?: Array<string>;
}

export const RequestCommandTypeCommandEnum = {
    SendInformation: 'SEND_INFORMATION',
    Lock: 'LOCK',
    Unlock: 'UNLOCK',
    ActivateWifi: 'ACTIVATE_WIFI',
    DeactivateWifi: 'DEACTIVATE_WIFI',
    ActivateBluetooth: 'ACTIVATE_BLUETOOTH',
    DeactivateBluetooth: 'DEACTIVATE_BLUETOOTH',
    ActivateEthernet: 'ACTIVATE_ETHERNET',
    DeactivateEthernet: 'DEACTIVATE_ETHERNET',
    EnablePrinter: 'ENABLE_PRINTER',
    DisablePrinter: 'DISABLE_PRINTER',
    ActivateGps: 'ACTIVATE_GPS',
    DeactivateGps: 'DEACTIVATE_GPS',
    Reset: 'RESET',
    UpdateConfig: 'UPDATE_CONFIG',
    UpdateFirmware: 'UPDATE_FIRMWARE',
    DisplayMessage: 'DISPLAY_MESSAGE',
    UpdateApp: 'UPDATE_APP',
    SendAppInfo: 'SEND_APP_INFO',
    UpdateAppConfig: 'UPDATE_APP_CONFIG',
    UpdateKeys: 'UPDATE_KEYS',
    UpdateDatetime: 'UPDATE_DATETIME',
    LockWithPwd: 'LOCK_WITH_PWD',
    InstalledApps: 'INSTALLED_APPS',
    DeleteApp: 'DELETE_APP',
    DownloadFile: 'DOWNLOAD_FILE'
} as const;

export type RequestCommandTypeCommandEnum = typeof RequestCommandTypeCommandEnum[keyof typeof RequestCommandTypeCommandEnum];


