/* tslint:disable */
/* eslint-disable */
/**
 * TMS Agnostiko
 * Tiene la definición de todos los servicios de TMS a Agnostic (para uso en la web o externo)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { License } from '../models';
// @ts-ignore
import { Message } from '../models';
// @ts-ignore
import { RequestCreateLicense } from '../models';
// @ts-ignore
import { RequestUpdateLicense } from '../models';
/**
 * LicenseApi - axios parameter creator
 * @export
 */
export const LicenseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Devuelve las licencias vigentes
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLicenses: async (acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/license/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Obtener una licencia por id
         * @param {string} id 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenseById: async (id: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLicenseById', 'id', id)
            const localVarPath = `/license/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Obtener Licencias por master
         * @param {string} id 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenseByMaster: async (id: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLicenseByMaster', 'id', id)
            const localVarPath = `/license/master/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Crea una licencia
         * @param {RequestCreateLicense} requestCreateLicense 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        licenseCreate: async (requestCreateLicense: RequestCreateLicense, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestCreateLicense' is not null or undefined
            assertParamExists('licenseCreate', 'requestCreateLicense', requestCreateLicense)
            const localVarPath = `/license`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestCreateLicense, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Elimina una licencia
         * @param {string} id 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        licenseDeleteById: async (id: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('licenseDeleteById', 'id', id)
            const localVarPath = `/license/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Actualiza una licencia
         * @param {string} id 
         * @param {RequestUpdateLicense} requestUpdateLicense 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLicense: async (id: string, requestUpdateLicense: RequestUpdateLicense, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateLicense', 'id', id)
            // verify required parameter 'requestUpdateLicense' is not null or undefined
            assertParamExists('updateLicense', 'requestUpdateLicense', requestUpdateLicense)
            const localVarPath = `/license/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestUpdateLicense, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LicenseApi - functional programming interface
 * @export
 */
export const LicenseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LicenseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Devuelve las licencias vigentes
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllLicenses(acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<License>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllLicenses(acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LicenseApi.getAllLicenses']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Obtener una licencia por id
         * @param {string} id 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLicenseById(id: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<License>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLicenseById(id, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LicenseApi.getLicenseById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Obtener Licencias por master
         * @param {string} id 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLicenseByMaster(id: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<License>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLicenseByMaster(id, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LicenseApi.getLicenseByMaster']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Crea una licencia
         * @param {RequestCreateLicense} requestCreateLicense 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async licenseCreate(requestCreateLicense: RequestCreateLicense, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.licenseCreate(requestCreateLicense, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LicenseApi.licenseCreate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Elimina una licencia
         * @param {string} id 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async licenseDeleteById(id: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.licenseDeleteById(id, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LicenseApi.licenseDeleteById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Actualiza una licencia
         * @param {string} id 
         * @param {RequestUpdateLicense} requestUpdateLicense 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLicense(id: string, requestUpdateLicense: RequestUpdateLicense, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLicense(id, requestUpdateLicense, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LicenseApi.updateLicense']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * LicenseApi - factory interface
 * @export
 */
export const LicenseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LicenseApiFp(configuration)
    return {
        /**
         * 
         * @summary Devuelve las licencias vigentes
         * @param {LicenseApiGetAllLicensesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLicenses(requestParameters: LicenseApiGetAllLicensesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<License>> {
            return localVarFp.getAllLicenses(requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Obtener una licencia por id
         * @param {LicenseApiGetLicenseByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenseById(requestParameters: LicenseApiGetLicenseByIdRequest, options?: AxiosRequestConfig): AxiosPromise<License> {
            return localVarFp.getLicenseById(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Obtener Licencias por master
         * @param {LicenseApiGetLicenseByMasterRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenseByMaster(requestParameters: LicenseApiGetLicenseByMasterRequest, options?: AxiosRequestConfig): AxiosPromise<Array<License>> {
            return localVarFp.getLicenseByMaster(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Crea una licencia
         * @param {LicenseApiLicenseCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        licenseCreate(requestParameters: LicenseApiLicenseCreateRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.licenseCreate(requestParameters.requestCreateLicense, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Elimina una licencia
         * @param {LicenseApiLicenseDeleteByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        licenseDeleteById(requestParameters: LicenseApiLicenseDeleteByIdRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.licenseDeleteById(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Actualiza una licencia
         * @param {LicenseApiUpdateLicenseRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLicense(requestParameters: LicenseApiUpdateLicenseRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.updateLicense(requestParameters.id, requestParameters.requestUpdateLicense, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAllLicenses operation in LicenseApi.
 * @export
 * @interface LicenseApiGetAllLicensesRequest
 */
export interface LicenseApiGetAllLicensesRequest {
    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof LicenseApiGetAllLicenses
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for getLicenseById operation in LicenseApi.
 * @export
 * @interface LicenseApiGetLicenseByIdRequest
 */
export interface LicenseApiGetLicenseByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof LicenseApiGetLicenseById
     */
    readonly id: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof LicenseApiGetLicenseById
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for getLicenseByMaster operation in LicenseApi.
 * @export
 * @interface LicenseApiGetLicenseByMasterRequest
 */
export interface LicenseApiGetLicenseByMasterRequest {
    /**
     * 
     * @type {string}
     * @memberof LicenseApiGetLicenseByMaster
     */
    readonly id: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof LicenseApiGetLicenseByMaster
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for licenseCreate operation in LicenseApi.
 * @export
 * @interface LicenseApiLicenseCreateRequest
 */
export interface LicenseApiLicenseCreateRequest {
    /**
     * 
     * @type {RequestCreateLicense}
     * @memberof LicenseApiLicenseCreate
     */
    readonly requestCreateLicense: RequestCreateLicense

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof LicenseApiLicenseCreate
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for licenseDeleteById operation in LicenseApi.
 * @export
 * @interface LicenseApiLicenseDeleteByIdRequest
 */
export interface LicenseApiLicenseDeleteByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof LicenseApiLicenseDeleteById
     */
    readonly id: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof LicenseApiLicenseDeleteById
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateLicense operation in LicenseApi.
 * @export
 * @interface LicenseApiUpdateLicenseRequest
 */
export interface LicenseApiUpdateLicenseRequest {
    /**
     * 
     * @type {string}
     * @memberof LicenseApiUpdateLicense
     */
    readonly id: string

    /**
     * 
     * @type {RequestUpdateLicense}
     * @memberof LicenseApiUpdateLicense
     */
    readonly requestUpdateLicense: RequestUpdateLicense

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof LicenseApiUpdateLicense
     */
    readonly acceptLanguage?: string
}

/**
 * LicenseApi - object-oriented interface
 * @export
 * @class LicenseApi
 * @extends {BaseAPI}
 */
export class LicenseApi extends BaseAPI {
    /**
     * 
     * @summary Devuelve las licencias vigentes
     * @param {LicenseApiGetAllLicensesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseApi
     */
    public getAllLicenses(requestParameters: LicenseApiGetAllLicensesRequest = {}, options?: AxiosRequestConfig) {
        return LicenseApiFp(this.configuration).getAllLicenses(requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Obtener una licencia por id
     * @param {LicenseApiGetLicenseByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseApi
     */
    public getLicenseById(requestParameters: LicenseApiGetLicenseByIdRequest, options?: AxiosRequestConfig) {
        return LicenseApiFp(this.configuration).getLicenseById(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Obtener Licencias por master
     * @param {LicenseApiGetLicenseByMasterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseApi
     */
    public getLicenseByMaster(requestParameters: LicenseApiGetLicenseByMasterRequest, options?: AxiosRequestConfig) {
        return LicenseApiFp(this.configuration).getLicenseByMaster(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Crea una licencia
     * @param {LicenseApiLicenseCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseApi
     */
    public licenseCreate(requestParameters: LicenseApiLicenseCreateRequest, options?: AxiosRequestConfig) {
        return LicenseApiFp(this.configuration).licenseCreate(requestParameters.requestCreateLicense, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Elimina una licencia
     * @param {LicenseApiLicenseDeleteByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseApi
     */
    public licenseDeleteById(requestParameters: LicenseApiLicenseDeleteByIdRequest, options?: AxiosRequestConfig) {
        return LicenseApiFp(this.configuration).licenseDeleteById(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Actualiza una licencia
     * @param {LicenseApiUpdateLicenseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseApi
     */
    public updateLicense(requestParameters: LicenseApiUpdateLicenseRequest, options?: AxiosRequestConfig) {
        return LicenseApiFp(this.configuration).updateLicense(requestParameters.id, requestParameters.requestUpdateLicense, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}

