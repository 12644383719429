/* eslint-disable prettier/prettier */
import { html, LitElement, render } from 'lit';
import { customElement, query, state } from 'lit/decorators.js';
import { guard } from 'lit/directives/guard.js';
import Swal from 'sweetalert2';
import '@material/mwc-icon-button';
import '@material/mwc-circular-progress';
import { getCookie } from '../../services/cookie';
import { editarGrupo, iniciarSesion } from '../../redux/actions/index.js';
import './OrganiGrama.ts';

import {
  Grid,
  GridActiveItemChangedEvent,
  GridItemModel,
} from '@vaadin/vaadin-grid';
import { ListaAppModal } from '../../components/modals/ListaAppModal';
import '../../components/modals/ListaAppModal';
import { 
  OrganizationApi,
  OrganizationName,
  CommunicationTerminalApiRequestCommandGroupRequest,
  RequestCommandTypeCommandEnum,
  CommunicationTerminalApi 
} from '../../../api';
import { GrupoTerminales, Terminal } from '../../utils/dataTerminales.js';
import store from '../../redux/store';
import router from '../../router/router';
import { apisConfigurator } from '../../utils/axios';
import { EnviarMensajeModalGrupo } from '../../components/modals/EnviarMensajeModalGrupo';
import '../../components/modals/EnviarMensajeModalGrupo';
import './Hierarchy.css';
import '@vaadin/vaadin-grid';
import { GroupList } from '../../../api/index.js';
import { GroupApi } from '../../../api/api.js';
import { AsignarPreConfiguracionModal } from '../../components/modals/AsignarPreConfiguracion.js';
import '../../components/modals/AsignarPreConfiguracion.js';
import { connect } from 'pwa-helpers';
import { getOrganizaciones } from '../../redux/actions/organizaciones';
import { Unsubscribe } from '@reduxjs/toolkit';
import { getGrupos } from '../../redux/actions/groups';
import { isGroupList } from '../../utils/typeValidators';
import { UpdateHoraFecha } from '../../components/modals/UpdateHoraFecha';
import '../../components/modals/UpdateHoraFecha';

@customElement('hierarchy-page')
export class HierarchyPage extends connect(store)(LitElement) {
  terminalSelected: Terminal | GrupoTerminales | undefined;
  private communicationApi: CommunicationTerminalApi;

  get enviarMensajeModal() {
    return document.getElementById(
      'enviarMensajeModalGrupo'
    ) as EnviarMensajeModalGrupo;
  }

  get updateHoraFecha() {
    return document.getElementById('updateHoraFecha') as UpdateHoraFecha;
  }

  get asignarPreconfigModal() {
    return document.getElementById(
      'asignarPreconfig'
    ) as AsignarPreConfiguracionModal;
  }

  selectMultiple = false;
  delete = false;
  private groupApi: GroupApi;

  @state()
  private grupos: GroupList[] = [];

  constructor() {
    super();
    this.groupApi = new GroupApi(...apisConfigurator('Grupos'));
    this.communicationApi = new CommunicationTerminalApi(
      ...apisConfigurator('Comunicación')
    );
  }
  @state()
  loading = true;
  @state()
  ButtomActive = false;
  @state()
  NameBtn = 'Crear Grupo';
  @state()
  detail = false;
  @state()
  person = { name: '', id: '' };
  @state()
  organizationSelected?: OrganizationName;
  @state()
  organizaciones: OrganizationName[] = [];
  @state()
  private detailsOpenedItem: GroupList[] = [];

  private storeUnsuscriber?: Unsubscribe;

  private async getGroupsAndOrganizations() {
    store.dispatch(getGrupos());
    store.dispatch(getOrganizaciones());
    this.storeUnsuscriber = store.subscribe(() => {
      const { list, loading, listApps } = store.getState();
      console.log(loading);
      console.log({ list });

      if (isGroupList(list[0])) this.grupos = list as GroupList[];
      else this.organizaciones = list as OrganizationName[];
      this.loading = loading;
    });
    console.log("LISTO")
  }

  @query('#input-edit')
  editInput!: HTMLInputElement;

  @query('#input-select')
  iconInput!: HTMLSelectElement;

  protected async firstUpdated() {
    var opcionOrganizaciones = localStorage.getItem("organizaciones");
    await this.getUserCookie();
    await this.getGroupsAndOrganizations();
    if(opcionOrganizaciones?.length){
      console.log("entro")
      this.handleCambiar('Organizaciones')
      localStorage.removeItem('organizaciones');
    }
  }

  disconnectedCallback(): void {
    this.storeUnsuscriber && this.storeUnsuscriber();
  }

  mapOrgParent(
    org: OrganizationName,
    parent?: OrganizationName
  ): {
    id: string;
    name: string;
    parent: string;
  }[] {
    const orgNew = {
      id: org.id,
      name: org.name,
      parent: parent?.id ?? 'TMS',
    };
    return [
      orgNew,
      ...org.sons?.map(son => this.mapOrgParent(son, org)).flat(),
    ];
  }

  get ListarAplicacionesModal() {
    return document.getElementById('listaAppModal')! as ListaAppModal;
  }

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  instanceOfGroup(object: any): object is GrupoTerminales {
    return 'grupo' in object;
  }

  handleCambiar(e: string) {
    //console.log(e);
    if (e === 'Grupos') {
      this.ButtomActive = false;
      this.NameBtn = 'Crear Grupo';
      this.organizationSelected = undefined;
    }
    if (e === 'Organizaciones') {
      this.ButtomActive = true;
      this.person = { name: '', id: '' };
      this.detail = false;
      this.NameBtn = 'Crear Organización';
    }
    console.log(this.ButtomActive);
  }
  editGroup(e: any) {
    //console.log(e);
    store.dispatch(editarGrupo(e));
    router.cambiarSeccion('edit-group');
  }

  async updateTimeDate(){
    let date = new Date()
    let fecha = ""
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    if(month < 10){
      fecha =`${day}-0${month}-${year}`
    }else{
      fecha = `${day}-${month}-${year}`
    }

    var horas = date.getHours();
    var minutos:any = date.getMinutes();
    if(date.getMinutes() < 10) minutos = "0" + date.getMinutes();
    const event = new Date(Date.UTC(2012, 11, 20, 3, 0, 0));
    const options:any = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    const requestCommand: CommunicationTerminalApiRequestCommandGroupRequest =
    {
      group: this.person?.id,
      requestCommand: {
        commandId: `updateDateTime-${this.person?.id}-${Date.now()}`,
        typeCommand: RequestCommandTypeCommandEnum.UpdateDatetime,
        parameters: [fecha,`${horas}:${minutos}`,event.toLocaleDateString(undefined, options)],
      },
    };
    try {
      const res = await this.communicationApi.requestCommandGroup(
          requestCommand
      );
      //console.log('envio mensaje res grupo', res);
      if(res.status == 200) {
        Swal.fire(
          'Completado!',
          'El comando ha sido enviado.',
          'success'
        )
      }

    } catch (err: any) {
      console.log('envio mensaje err', err);
    }

  }

  getUserCookie() {
    const userCookie = getCookie('tms_user');
    console.log(userCookie);

    if (!userCookie || userCookie === '') return;

    const user = JSON.parse(userCookie);

    //console.log('Usuario');
    //console.log(user);

    store.dispatch(
      iniciarSesion(user.token, user.authorizedFunctions, user.usuario)
    );
  }

  handleOrgSelected(event: CustomEvent<OrganizationName>) {
    const { detail } = event;
    //console.log(detail);
    this.organizationSelected = detail;
  }

  render() {
    return html`
      <main
        class="w-full h-full flex ${!this.loading
          ? ''
          : 'items-center justify-center'}"
      >
        ${!this.loading
          ? html`
              <main class="w-full flex FondoColor divide-slate-500">
                <section class="w-3/4 p-4 SectionList rounded-l-lg">
                  <div class="w-full h-14 flex justify-center mb-2">
                    <div
                      class="w-2/4 mr-2 ${this.ButtomActive === false
                        ? 'btnGru'
                        : 'InactiveBtn'} cursor-pointer"
                      @click=${() => this.handleCambiar('Grupos')}
                    > 
                      <p class="text-2xl pt-3 text-center">Grupos</p>
                    </div>
                    <div
                      class="w-2/4 ml-2 ${this.ButtomActive === true
                        ? 'btnOr'
                        : 'InactiveBtn'} cursor-pointer"
                      @click=${() => this.handleCambiar('Organizaciones')}
                    >
                      <p class="text-2xl pt-3 text-center">Organizaciones</p>
                    </div>
                  </div>
                  ${this.ButtomActive === false
                    ? html`
                        <vaadin-grid
                          id="material"
                          class="w-full"
                          style="height: calc(100% - 56px);"
                          .items="${this.grupos}"
                          .detailsOpenedItems="${this.detailsOpenedItem}"
                          @active-item-changed="${(
                            e: GridActiveItemChangedEvent<GroupList>
                          ) => (this.detailsOpenedItem = [e.detail.value])}"
                          .rowDetailsRenderer="${guard(
                            [],
                            () =>
                              (
                                root: HTMLElement,
                                _: Grid,
                                model: GridItemModel<GroupList>
                              ) => {
                                this.person = this.detailsOpenedItem[0];
                                //console.log(this.person);
                                if (this.detail === false) {
                                  //console.log(this.detail);
                                  this.detail = true;
                                  this.person = this.detailsOpenedItem[0];
                                } else {
                                  //console.log(this.detail);
                                  this.detail = false;
                                }
                                render(
                                  html`<vaadin-form-layout
                                    .responsiveSteps=" ${[
                                      { minWidth: '0', columns: 3 },
                                    ]}"
                                  >
                                    <div
                                      class="w-full h-14 flex items-center mt-5  justify-center flex-row "
                                    >
                                      <div
                                        class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                                        @click=${() => {
                                          //console.log('enviar mensaje', this.person);
                                          this.enviarMensajeModal.open(
                                            this.person!
                                          );
                                        }}
                                      >
                                        <div>
                                          <mwc-icon-button icon="chat">
                                          </mwc-icon-button>
                                        </div>
                                        <div>
                                          <p class="text-center  ">
                                            Enviar mensaje
                                          </p>
                                        </div>
                                      </div>
                                      <div
                                        class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                                        @click=${() => {
                                          this.updateHoraFecha.open("",false,this.person!);
                                        }}
                                      >
                                        <div>
                                          <mwc-icon-button icon="update">
                                          </mwc-icon-button>
                                        </div>
                                        <div>
                                          <p class="text-center  ">
                                            actualizar hora y fecha
                                          </p>
                                        </div>
                                      </div>
                                      <div
                                        class="btnMessage items-center content-center cursor-pointer flex  flex-row cursor-pointer"
                                        @click=${() => {
                                          //console.log(this.person)
                                          this.ListarAplicacionesModal.open(
                                            this.person
                                          );
                                        }}
                                      >
                                        <div>
                                          <mwc-icon-button icon="grid_view">
                                          </mwc-icon-button>
                                        </div>
                                        <div>
                                          <p class="text-center  ">
                                            Instalar apps
                                          </p>
                                        </div>
                                      </div>
                                      <div
                                        class="btnMessage items-center content-center cursor-pointer flex  flex-row cursor-pointer"
                                        @click=${() => {
                                          this.asignarPreconfigModal.open(
                                            model.item
                                          );
                                        }}
                                      >
                                        <div>
                                          <mwc-icon-button icon="grid_view">
                                          </mwc-icon-button>
                                        </div>
                                        <div>
                                          <p class="text-center  ">
                                            Pre-configuración
                                          </p>
                                        </div>
                                      </div>
                                      <div
                                        class="btnReset items-center content-center cursor-pointer flex  flex-row cursor-pointer"
                                      >
                                        <div>
                                          <mwc-icon-button icon="autorenew">
                                          </mwc-icon-button>
                                        </div>
                                        <div>
                                          <p class="text-center  ">Reiniciar</p>
                                        </div>
                                      </div>
                                      <div
                                        class="btnBloquear items-center content-center  flex  flex-row cursor-pointer"
                                      >
                                        <div>
                                          <mwc-icon-button icon="block">
                                          </mwc-icon-button>
                                        </div>
                                        <div>
                                          <p class="text-center  ">Bloquear</p>
                                        </div>
                                      </div>
                                    </div>
                                  </vaadin-form-layout>`,
                                  root
                                );
                              }
                          )}"
                        >
                          <vaadin-grid-selection-column>
                          </vaadin-grid-selection-column>
                          <vaadin-grid-sort-column
                            class="header"
                            path="name"
                            header="Grupo"
                          ></vaadin-grid-sort-column>
                          <vaadin-grid-sort-column
                            text-align="center"
                            path="terminals"
                            header="Terminales"
                          ></vaadin-grid-sort-column>
                        </vaadin-grid>
                      `
                    : html`<org-chart-page
                        style="height: calc(100% - 56px)"
                        .organizations="${this.organizaciones}"
                        @org-selected="${this.handleOrgSelected}"
                      ></org-chart-page>`}
                </section>
                <section class="w-1/4 SectionDetail justify-center">
                  <div class="w-full flex justify-center">
                    <mwc-button
                      class="rounded w-4/5 mx-auto mt-4 mb-2"
                      style="--mdc-theme-primary: #2E3EAE;"
                      raised
                      icon="add_circle"
                      @click=${() =>
                        this.ButtomActive === false
                          ? router.cambiarSeccion('create-group')
                          : router.cambiarSeccion('create-organization')}
                    >
                      ${this.NameBtn}</mwc-button
                    >
                  </div>
                  ${this.detail === true && this.NameBtn === 'Crear Grupo'
                    ? html`
                        <div class="w-full  my-3 border-b-2 border-grey-100 ">
                          <div>
                            <h1 class="text-2xl	text-blue-900 ml-3 mb-6	">
                              ${this.person.name}
                            </h1>
                          </div>

                          <div>
                            <p class="text-lg	text-blue-500	ml-3 my-2">
                              <b>Marca 1:</b> X unidades
                            </p>
                          </div>
                          <div>
                            <p class="text-lg	text-blue-500	ml-3 my-2">
                              <b>Marca 2:</b> X unidades
                            </p>
                          </div>
                          <div>
                            <p class="text-lg	text-blue-500	ml-3 my-2">
                              <b>Marca 3:</b> X unidades
                            </p>
                          </div>
                        </div>
                        <div
                          class="w-full my-3  pl-5 border-b-2 border-grey-100"
                        >
                          <div class="w-full mt-5 flex justify-center">
                            <mwc-button
                              @click="${() =>
                                this.editGroup({
                                  id: this.person.id,
                                  name: this.person.name,
                                })}"
                              icon="edit"
                              class="rounded w-4/5"
                              style="--mdc-theme-primary: #E3E1EC; --mdc-theme-on-primary: #46464F;"
                              raised
                              >Editar</mwc-button
                            >
                          </div>
                        </div>
                      `
                    : html``}
                  ${this.organizationSelected
                    ? html`
                        <div class="w-full  my-3 border-b-2 border-grey-100 ">
                          <div>
                            <h1 class="text-2xl	text-blue-900 ml-3 mb-6	">
                              ${this.organizationSelected.name}
                            </h1>
                          </div>

                          <div>
                            <p class="text-lg	text-blue-500	ml-3 my-2">
                              <b>Marca 1:</b> X unidades
                            </p>
                          </div>
                          <div>
                            <p class="text-lg	text-blue-500	ml-3 my-2">
                              <b>Marca 2:</b> X unidades
                            </p>
                          </div>
                          <div>
                            <p class="text-lg	text-blue-500	ml-3 my-2">
                              <b>Marca 3:</b> X unidades
                            </p>
                          </div>
                        </div>
                        <div class="w-full  my-3 border-b-2 border-grey-100 ">
                          <div>
                            <p class="text-lg	text-blue-500	ml-3 my-2">
                              Usuario 2
                            </p>
                          </div>
                          <div>
                            <p class="text-lg	text-blue-500	ml-3 my-2">
                              Usuario 3
                            </p>
                          </div>
                          <div>
                            <p class="text-lg	text-blue-500	ml-3 my-2">
                              Usuario 3
                            </p>
                          </div>
                        </div>
                        <div
                          class="w-full my-3  pl-5 border-b-2 border-grey-100	"
                        >
                          <div
                            class="group_icon items-center p-2  flex content-center  flex-row cursor-pointer"
                          >
                            <div class="text-blue-500">
                              <mwc-icon-button icon="print"> </mwc-icon-button>
                            </div>
                            <div>
                              <h1 class="text-1xl	text-blue-900 ml-3 	">
                                Impresora
                              </h1>
                            </div>
                          </div>

                          <div
                            class=" group_icon items-center p-2   flex  flex-row cursor-pointer"
                          >
                            <div class="text-blue-500">
                              <mwc-icon-button icon="bluetooth">
                              </mwc-icon-button>
                            </div>
                            <div>
                              <h1 class="text-1xl	text-blue-900 ml-3 	">
                                Bluetooth
                              </h1>
                            </div>
                          </div>
                          <div
                            class="group_icon  items-center p-2   flex  flex-row cursor-pointer"
                          >
                            <div class="text-blue-500">
                              <mwc-icon-button icon="place"> </mwc-icon-button>
                            </div>
                            <div>
                              <h1 class="text-1xl	text-blue-900 ml-3 ">
                                Ubicación
                              </h1>
                            </div>
                          </div>
                        </div>

                        <div class="w-full mt-5 flex justify-center">
                          <mwc-button
                            @click="${() => {}}"
                            icon="edit"
                            class="rounded w-4/5"
                            style="--mdc-theme-primary: #E3E1EC; --mdc-theme-on-primary: #46464F;"
                            raised
                            >Editar</mwc-button
                          >
                        </div>
                      `
                    : html``}
                </section>
              </main>
            `
          : html`
              <mwc-circular-progress
                indeterminate
                density=${20}
              ></mwc-circular-progress>
            `}
      </main>
      <lista-app-modal id="listaAppModal"></lista-app-modal>
      <enviar-mensaje-modal-grupo
        id="enviarMensajeModalGrupo"
      ></enviar-mensaje-modal-grupo>
      <asignar-preconfig id="asignarPreconfig"></asignar-preconfig>
      <actualizar-hora-fecha id="updateHoraFecha"></actualizar-hora-fecha>
    `;
  }
}
