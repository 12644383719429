/* eslint-disable prettier/prettier */

import { LitElement, html, render } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '@vaadin/vaadin-grid';
import '@ui5/webcomponents/dist/Dialog.js';

import '@vaadin/vaadin-select';
import {
  CommunicationTerminalApi,
  Terminal,
  AppApi,
  RequestAppUpdate,
  AppApiUpdateAppRequest,
  TemplateInfo,
} from '../../../api';
import {
  nombrePlantilla,
  descripcionPlantilla,
  idPlantilla,
  //ListPlantilla
} from '../../redux/actions';
import store from '../../redux/store';
import { State } from '../../redux/types';
import router from '../../router/router';
import { errorNotification } from '../../services/notifications';
import { apisConfigurator } from '../../utils/axios';

@customElement('lista-plantilla-modal')
export class ListaPlantilla extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private appApi: AppApi;

  constructor() {
    super();
    this.appApi = new AppApi(...apisConfigurator('Editar App'));
  }

  @state()
  plantillas: TemplateInfo[] = [];

  @state()
  loading = true;

  @state()
  selectedPlantillas: string[] = [];

  private get dialog() {
    return document.getElementById('dialog-enviar-mensaje')! as any;
  }

  protected firstUpdated(): void {
    console.log('hola');
    this.plantillas = [
      {
        name: 'plantilla-1',
        id: '12',
        description: 'prueba1',
      },
    ];
    console.log(this.plantillas);
  }

  async getOrganizaciones() {}

  async open(plantillas: any[], appPlantillas: any[]) {
    this.plantillas = plantillas;

    this.selectedPlantillas = appPlantillas.map(plantilla => plantilla.id);

    console.log(plantillas);
    /*if (!terminal) {
      errorNotification('Error al editar terminal');
      return;
    }*/

    this.getOrganizaciones();
    this.dialog.open = true;
  }

  close() {
    this.dialog.open = false;
    this.plantillas = [];
  }

  buildApp(): RequestAppUpdate {
    return {
      templates: this.selectedPlantillas,
    };
  }

  async handleSubmitData() {
    const { verAppId } = store.getState() as State;
    if (!verAppId) {
      errorNotification('Error al editar App');
      router.cambiarSeccion('apps-list');
      return;
    }

    const data: AppApiUpdateAppRequest = {
      id: verAppId,
      requestAppUpdate: this.buildApp(),
    };
    console.log(data);
    try {
      const res = await this.appApi.updateApp(data);

      console.log(res);
      //ListPlantilla([{}])
      router.cambiarSeccion('view-app');
      this.close();
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  }

  protected render() {
    return html`
      <ui5-dialog
        id="dialog-enviar-mensaje"
        class="w-8/12"
        style="border-radius: 15px;"
      >
        <div class="flex justify-between" style="overflow: hidden;">
          <h1 style="font: 30px/3 sans-serif;">
            Selecciona tu nueva plantilla
          </h1>
          <div class="header-botones" style="margin-top:30px;">
            <button
              class="rounded-3xl mr-2 border w-20  border-indigo-500 text-blue-500  CancelarG  hover:shadow-md hover:bg-slate-300 text-xs	"
              @click=${() => {
                this.close();
              }}
            >
              Cancelar
            </button>
            <button
              class="rounded-3xl h-10  w-40 guardar-cambios	"
              @click=${() => {
                this.handleSubmitData();
              }}
            >
              Guardar cambios
            </button>
          </div>
        </div>
        <div class="row-buscador py-4">
          <input 
            type="search"
            placeholder="Buscar"
            id="gsearch"
            style=":hover {background: white}"
            class="w-full border-0 rounded-xl bg-gray-300 h-10"
            name="gsearch"
          />
        </div>
        <vaadin-grid .items="${this.plantillas}">
          <vaadin-grid-sort-column
            .renderer="${(
              root: HTMLElement,
              _: any,
              item: { item: TemplateInfo }
            ) => {
              render(
                html`
                  <input
                    class="s-c bottom"
                    type="checkbox"
                    name="platform"
                    .checked=${this.selectedPlantillas.includes(item.item.id)
                      ? true
                      : false}
                    value=${true}
                    @change="${() => {
                      if (!this.selectedPlantillas.includes(item.item.id)) {
                        this.selectedPlantillas.push(item.item.id);
                      } else {
                        this.selectedPlantillas =
                          this.selectedPlantillas.filter(
                            plantilla => plantilla !== item.item.id
                          );
                      }
                    }}"
                  />
                `,
                root
              );
            }}"
          >
          </vaadin-grid-sort-column>
          <vaadin-grid-sort-column
            path="name"
            header="Nombre"
          ></vaadin-grid-sort-column>
          <vaadin-grid-sort-column
            header="En uso"
            .renderer="${(root: HTMLElement, _: any, item: any) => {
              render(html`####`, root);
            }}"
          ></vaadin-grid-sort-column>
          <vaadin-grid-sort-column path="description"></vaadin-grid-sort-column>

        </vaadin-grid>
      </ui5-dialog>
    `;
  }
}
