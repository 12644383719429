/* eslint-disable prettier/prettier */
import { html, LitElement } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import { customElement, query, state } from 'lit/decorators.js';
import { connect } from 'pwa-helpers';
import Swal from 'sweetalert2';
import '@vaadin/vaadin-list-box';
import '@vaadin/vaadin-text-field';
import './CreateTemplate.css';
import { OrganizationName, RoleList, User, TemplateApi } from '../../../api';
import '@vaadin/vaadin-text-field';
import { NameTemplate } from '../../components/modals/NameTemplate';
import '../../components/modals/NameTemplate';

import { EnumModal } from '../../components/modals/EnumModal';
import '../../components/modals/EnumModal';

import { DemoModal } from '../../components/modals/DemoModal';
import '../../components/modals/DemoModal';

import router from '../../router/router';
import { apisConfigurator } from '../../utils/axios';
import { InsertarPropiedad, ordenarAsc } from '../../utils/functions';
import store from '../../redux/store';
import { State } from '../../redux/types';
import { agregarPlantilla, Enumm } from '../../redux/actions';
@customElement('create-template-page')
export class CreateTemplate extends connect(store)(LitElement) {
  private templateApi: TemplateApi;
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private constructor() {
    super();
    this.templateApi = new TemplateApi(...apisConfigurator(''));
  }

  @query('#name')
  nameInput!: HTMLInputElement;

  @query('#lastName')
  lastNameInput!: HTMLInputElement;

  @state()
  private obj?: any;

  @state()
  loading = true;

  @state()
  editUser?: User;

  @state()
  private controlPlantilla: any[] = [
    {
      id: 1,
      name: '',
      type: '',
      lengthMinimo: 0,
      lengthMaximo: 0,
      description: '',
      pattern: '',
      required: false,
    },
  ];

  @state()
  token?: number;

  @state()
  private type: Object[] = [
    {
      id: 'string',
      type: 'String',
    },
    {
      id: 'number',
      type: 'Number',
    },
    {
      id: 'integer',
      type: 'Integer',
    },
    {
      id: 'boolean',
      type: 'Boolean',
    },
    {
      id: 'enum',
      type: 'Enum',
    },
  ];

  @state()
  roles: RoleList[] = [];

  @state()
  nombrePlantilla?: string = 'Nombre de la plantilla';

  @state()
  descripcionPlantilla?: string = '';

  @state()
  rolesUsuario: string[] = [];

  @state()
  organizationUsuario: string = '';

  @state()
  aux: string = '';

  @state()
  organizaciones: OrganizationName[] = [];

  @state()
  rol?: string = '';

  @state()
  private check: boolean = false;

  @state()
  checks: boolean = false;

  @state()
  jsonArray: boolean = false;

  @state()
  organization!: Array<string>;

  @state()
  dataSchema: any;

  private valueSelect(item: number) {
    return document.getElementById(`type${item}`) as any;
  }

  private get loaderContainer() {
    return document.getElementById('loader-container') as any;
  }

  get nameTemplate() {
    return document.getElementById('cambiarNombrePlantilla') as NameTemplate;
  }

  get enumModal() {
    return document.getElementById('enumModal') as EnumModal;
  }

  get demoModal() {
    return document.getElementById('demoModal') as DemoModal;
  }

  enumSpan(index: number) {
    return document.getElementById(`enum${index}`) as any;
  }

  idGet(atributo: string) {
    return document.getElementById(atributo) as HTMLInputElement;
  }

  firstUpdated() {
    this.obj = {
      properties: [],
      required: [],
    };
    const initial = [
      {
        id: 1,
        name: '',
        type: '',
        lengthMinimo: 0,
        lengthMaximo: 0,
        description: '',
        pattern: '',
        required: false,
      },
    ];
    store.dispatch(agregarPlantilla(initial));
    //const loaderContainer = this.loaderContainer;
    //loaderContainer.style.display = 'none';

    this.cargarData();
  }

  stateChanged(state: any) {
    this.controlPlantilla = state.plantilla;
    ordenarAsc(this.controlPlantilla);
    this.nombrePlantilla = state.nombrePlantilla;
    this.descripcionPlantilla = state.descripcionPlantilla;
    this.token = state.plantilla.length;
    this.templateApi.createTemplate;
    if (state.enumm) {
      this.changeInputEnum(state.enumm);
      /*if(state.enumm.data[0] == '' ){
        this.spanDataHidden(state.enumm.id)
      }else{

        this.spanData(state.enumm.id)
      }*/
    }
  }

  async cargarData() {
    this.verificarUserEdit();
    this.loading = false;
  }

  async verificarUserEdit() {
    const { usuarioEdit, plantilla, nombrePlantilla } =
      store.getState() as State;
    //this.checks= new Array(plantilla?.length);
    this.token = plantilla?.length;
    this.nombrePlantilla = 'Nombre de la plantilla';
    this.controlPlantilla = plantilla ? plantilla : [];
    this.editUser = usuarioEdit;
  }

  /*spanData(index: number) {
    const span = this.enumSpan(index);
    span.style.visibility = 'visible';
  }

  spanDataHidden(index: number) {
    const span = this.enumSpan(index);
    span.style.visibility = 'hidden';
  }*/

  agregarCampoNuevo() {
    let plantilla = this.controlPlantilla;
    console.log(plantilla.length);
    let id = plantilla?.length ? plantilla?.length + 1 : 0 + 1;
    const campoNuevo = {
      id,
      name: '',
      type: '',
      lengthMinimo: 0,
      lengthMaximo: 0,
      description: '',
      pattern: '',
      required: false,
    };
    plantilla?.push(campoNuevo);
    //console.log(ordenarAsc(plantilla));
    store.dispatch(agregarPlantilla(plantilla ? plantilla : [{}]));
    this.controlPlantilla = plantilla ? plantilla : [{}];
    console.log(this.controlPlantilla);
  }
  async createSchema() {
    this.obj.properties = this.controlPlantilla;
    const dataSchema = InsertarPropiedad(this.obj);
    if(this.jsonArray){
      dataSchema.type="array";
    }
    console.log(dataSchema);
    let requestTemplateCreate = {
      name: `${this.nombrePlantilla}`,
      description: `${this.descripcionPlantilla}`,
      schema: dataSchema,
    };
    console.log(requestTemplateCreate);
    try {
      const res = await this.templateApi.createTemplate({
        requestTemplateCreate,
      });
      if (res.status == 200) {
        //router.cambiarSeccion('plantilla-page');
        await Swal.fire('Agregado!', 'El tipo de dato registrado', 'success');
        //window.location.reload()
        router.cambiarSeccion('plantilla-page');
      }
    } catch (err) {}

    //console.log(this.descripcionPlantilla);
    //console.log(this.nombrePlantilla);
  }

  async eliminarCampo(id: number) {
    //const { plantilla } = store.getState() as State;
    //const map1 = plantilla?.splice( id, 1 );
    //const map2 = this.checks?.splice( id, 1 );
    let plantilla = this.controlPlantilla;
    const map1 = plantilla?.filter((x: any, index: number) => x.id != id);
    //const map2 = this.checks?.filter((x:any,index:number) => index != id );
    console.log(map1);
    //this.checks=map2;
    /*await map1.map((camp:any,index:number) =>{
      camp.id=index+1;
    })*/
    console.log(map1);
    store.dispatch(agregarPlantilla(map1 ? map1 : [{}]));
    this.controlPlantilla = map1 ? map1 : [{}];
  }

  changeInputEnum(data: any) {
    let id = data.id;
    let name = this.idGet(`name${data.id}`);
    let type = this.idGet(`type${data.id}`);
    let lengthMinimo = this.idGet(`lengthMinimo${data.id}`);
    let lengthMaximo = this.idGet(`lengthMaximo${data.id}`);
    let description = this.idGet(`description${data.id}`);
    let pattern = this.idGet(`pattern${data.id}`);

    let arrOfNum = data.data;

    if (
      type.value == 'number' ||
      type.value == 'integer' ||
      type.value == 'boolean'
    ) {
      type.value = 'string';
    }

    let dataObjeto = {
      id,
      name: name.value,
      type: 'string',
      //length: length.value,
      description: description.value,
      //pattern: pattern.value,
      required: this.checks,
      enum: arrOfNum,
    };
    const map2 = this.controlPlantilla.filter(
      (x: any, index: number) => x.id !== data.id
    );
    map2.push(dataObjeto);
    store.dispatch(Enumm(0));
    store.dispatch(agregarPlantilla(map2 ? map2 : [{}]));
    this.controlPlantilla = map2;
  }

  changeInput(indice: number) {
    let id = indice;
    let name = this.idGet(`name${indice}`);
    let type = this.idGet(`type${indice}`);
    let lengthMinimo = this.idGet(`lengthMinimo${indice}`);
    let lengthMaximo = this.idGet(`lengthMaximo${indice}`);
    /* validar que valor minimo sea menor y el valor maximo el mayor */
    /*id(lengthMinimo.value>=lengthMaximo){

    }else if{

    }*/
    let description = this.idGet(`description${indice}`);
    let pattern = this.idGet(`pattern${indice}`);

    let dataObjeto = {};
    const found = this.controlPlantilla.find(element => element.id == id);
    var index = this.controlPlantilla
      .map(producto => producto.id)
      .indexOf(found.id);

    if (found.enum) {
      dataObjeto = {
        id,
        name: name.value,
        type: 'string',
        description: description.value,
        enum: found.enum,
      };
      this.controlPlantilla[index] = dataObjeto;
    } else {
      dataObjeto = {
        id,
        name: name.value,
        type: type.value,
        lengthMinimo: lengthMinimo.value,
        lengthMaximo: lengthMaximo.value,
        description: description.value,
        pattern: pattern.value,
        required: this.checks,
      };
      this.controlPlantilla[index] = dataObjeto;
    }
    console.log(this.controlPlantilla);
  }

  extraerName(indice: any) {
    let subs: any = [];
    if (this.editUser?.name) {
      subs = this.editUser?.name.split(' ');
    } else {
      return '';
    }
    return subs[indice] == undefined ? '' : subs[indice];
  }

  render() {
    return html`
      <!-- <div id="loader-container">
        <div class="spinner"></div>
      </div> -->
      <main class="w-full h-full flex overflow-auto">
        <div class="w-full h-full px-5 py-4 flex flex-wrap items-start">
          <form
            class="w-full h-full relative mb-2 SectionList rounded-md pt-5px-4 "
          >
            <div class="flex justify-between">
              <h1 class="text-2xl font-semibold text-blue-600">
                ${this.nombrePlantilla
                  ? this.nombrePlantilla
                  : 'Nombre de la plantilla'}
                <mwc-icon
                  @click=${() => {
                    this.nameTemplate.open('ww');
                  }}
                  >mode</mwc-icon
                >
              </h1>
              <div>
                <mwc-checkbox
                  id="terminalBox"
                  value="jsonArray"
                  .checked="${this.jsonArray}"
                  @change="${(e: any) => {
                    this.jsonArray = !this.jsonArray
                    console.log(this.jsonArray)
                  }}"
                ></mwc-checkbox>
                Json array
              </div>
              <div>
              <mwc-button
                  outlined
                  class="rounded"
                  @click=${() => {
                    router.cambiarSeccion('plantilla-page');
                    //this.createSchema();
                  }}
                  ><mwc-icon>cancel</mwc-icon> Cancelar</mwc-button
                >
                <mwc-button
                  outlined
                  class="rounded"
                  @click=${() => {
                    //router.cambiarSeccion('create-rol');
                    this.createSchema();
                  }}
                  ><mwc-icon>save</mwc-icon> Guardar Plantilla</mwc-button
                >
                <mwc-button
                  outlined
                  class="rounded"
                  @click=${() => {
                    //router.cambiarSeccion('create-rol');
                    //this.createSchema();
                    this.obj.properties = this.controlPlantilla;
                    this.dataSchema = InsertarPropiedad(this.obj);
                    if (this.dataSchema) {
                      this.demoModal.open(this.dataSchema);
                    }
                  }}
                  >Demo</mwc-button
                >
              </div>
            </div>
            <div class="header-tr">
              <div class="mx-2  TexField ">
                <h1></h1>
              </div>
              <div class="mx-2 ml-3.5 TexField">
                <h1>Tipo</h1>
              </div>
              <div class="mx-2 ml-3.5 TexField">
                <h1></h1>
              </div>
              <div class="mx-2 ml-3.5 TexField">
                <h1></h1>
              </div>
              <div class="mx-2  TexField">
                <h1></h1>
              </div>
              <div class="mx-2  TexField">
                <h1></h1>
              </div>
              <div class="mx-2  TexField">
                <h1></h1>
              </div>
              <!-- <div class="mx-2  TexField">
                    <h1>Enum</h1>
                </div> -->
            </div>
            ${repeat(
              this.controlPlantilla,
              (usuario: any) => usuario.id,
              (usuario: any, index: number = 1) => html`
                <div class="flex flex-wrap my-4 mx-4">
                  <div class="mx-2  TexField">
                    <mwc-textfield
                      outlined
                      iconTrailing="close"
                      label="name"
                      id="name${usuario.id}"
                      minLength="1"
                      pattern="[a-zA-Z0-9-]+"
                      class="mx-2"
                      .value=${usuario.name}
                      .readonly="${this.controlPlantilla !== undefined}"
                      @keyup="${(ev: InputEvent) => {
                        console.log(usuario);
                        this.changeInput(usuario.id);
                        //return false
                      }}"
                    >
                    </mwc-textfield>
                  </div>
                  <div class="mx-2 TexField">
                    <select
                      name="cars" 
                      id="type${usuario.id}"
                      class="style-select"
                      @click=${() => {
                        const item = this.valueSelect(usuario.id);
                        if (item.value.localeCompare('Enum') == 0) {
                          this.enumModal.open(
                            usuario.id,
                            usuario.enum ? usuario : undefined
                          );
                        } else {
                          if (usuario.enum) {
                            delete usuario.enum;
                          }
                          this.changeInput(usuario.id);
                        }
                      }}
                    >
                      <option value="string">string</option>
                      <option value="number">number</option>
                      <option value="integer">integer</option>
                      <option value="boolean">boolean</option>
                      <option value="Enum">Enum</option>
                    </select>
                  </div>
                  <!-- <div class="mx-2 TexField">
                          <custom-select
                            label="Version"
                            value="Boolean"
                            id="type${usuario.id}"
                            .options=${this.type?.map(
                    (v: any, index: number) => ({
                      value: v.id,
                      label: v.type,
                    })
                  )}
                            @change=${(ev: CustomEvent) => {
                    console.log(ev.detail);
                    this.changeInput(usuario.id);
                  }}
                          >
                          </custom-select>
                        </div>  -->

                  <div class="mx-2 TexField">
                    <mwc-textfield
                      id="lengthMinimo${usuario.id}"
                      type="number"
                      label="minimo"
                      class="mx-2"
                      outlined
                      iconTrailing="close"
                      .value=${this.extraerName(1)}
                      .readonly="${this.editUser !== undefined}"
                      @keyup="${(ev: InputEvent) => {
                        this.changeInput(usuario.id);
                      }}"
                    >
                    </mwc-textfield>
                  </div>
                  <div class="mx-2 TexField">
                    <mwc-textfield
                      id="lengthMaximo${usuario.id}"
                      type="number"
                      label="maximo"
                      class="mx-2"
                      outlined
                      iconTrailing="close"
                      .value=${this.extraerName(1)}
                      .readonly="${this.editUser !== undefined}"
                      @keyup="${(ev: InputEvent) => {
                        this.changeInput(usuario.id);
                      }}"
                    >
                    </mwc-textfield>
                  </div>
                  <div class="mx-2 TexField">
                    <mwc-textfield
                      id="description${usuario.id}"
                      label="Descripcion"
                      class="mx-2"
                      outlined
                      iconTrailing="close"
                      @keyup="${(ev: InputEvent) => {
                        this.changeInput(usuario.id);
                      }}"
                    >
                    </mwc-textfield>
                  </div>
                  <div class="mx-2 TexField">
                    <mwc-textfield
                      id="pattern${usuario.id}"
                      label="Validator"
                      class="mx-2"
                      outlined
                      iconTrailing="close"
                      @keyup="${(ev: InputEvent) => {
                        this.changeInput(usuario.id);
                      }}"
                    >
                    </mwc-textfield>
                  </div>
                  <div class="mx-2 TexField">
                    <mwc-checkbox
                      id="terminalBox"
                      value="requerido"
                      .checked="${usuario.required}"
                      @change="${(e: any) => {
                        this.checks = !usuario.required;
                        this.changeInput(usuario.id);
                      }}"
                    ></mwc-checkbox>
                  </div>
                  <div class="mx-2 TexField">
                    <mwc-button
                      outlined
                      class="rounded"
                      @click=${() => {
                        //this.checks=!usuario.required;
                        this.enumModal.open(
                          usuario.id,
                          usuario.enum ? usuario : undefined
                        );
                        const item = this.valueSelect(usuario.id);
                        item.value = 'Enum';
                        console.log(item.options[4]);
                      }}
                      >enum</mwc-button
                    >
                    ${usuario.enum
                      ? html`<span class="text-red-600 font-bold">*</span>`
                      : html``}
                  </div>
                  <div class="mx-2 TexField">
                    <mwc-icon-button
                      icon="delete"
                      @click=${() => {
                        this.eliminarCampo(usuario.id);
                        console.log(usuario.id);
                        //router.cambiarSeccion('create-template');
                        //window.location.reload()
                      }}
                    ></mwc-icon-button>
                  </div>
                </div>
              `
            )}

            <div class="flex flex-wrap my-4 mx-4 button-new-campo">
              <mwc-button
                outlined
                class="rounded"
                @click=${() => {
                  this.agregarCampoNuevo();
                }}
                ><mwc-icon>add_circle</mwc-icon> Agregar nuevo campo</mwc-button
              >
            </div>
          </form>
        </div>
      </main>
      <name-template id="cambiarNombrePlantilla"></name-template>
      <enum-modal id="enumModal"></enum-modal>
      ${this.dataSchema
        ? html`<demo-modal
            id="demoModal"
            .Schema=${this.dataSchema.properties}
          ></demo-modal>`
        : html``}
    `;
  }
}
