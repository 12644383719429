import { User } from '../../../api';
import { ActionTypes } from '../types';

export const CLEAN_USUARIO = 'CLEAN_USUARIO';
export const EDITAR_USUARIO = 'EDITAR_USUARIO';
export const GET_USUARIOS = 'GET_USUARIOS';
export const USUARIOS_RECEIVED = 'USUARIOS_RECEIVED';
export const USUARIO_ADDED = 'USUARIO_ADDED';

export function clearUsuario(): ActionTypes {
  return { type: CLEAN_USUARIO };
}

export function editarUsuario(usuario: User): ActionTypes {
  return { type: EDITAR_USUARIO, usuario };
}

export function getUsuarios(): ActionTypes {
  return { type: GET_USUARIOS, loading: true };
}

export function usuarioAdded(newUsuario: User): ActionTypes {
  return { type: USUARIO_ADDED, newUsuario };
}
