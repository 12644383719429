/* eslint-disable prettier/prettier */
import { html, LitElement, render } from 'lit';
import { guard } from 'lit/directives/guard.js';
import { customElement, state } from 'lit/decorators.js';

import '@ui5/webcomponents/dist/Tree.js';
import '@ui5/webcomponents/dist/Icon.js';
import '@ui5/webcomponents-icons/dist/it-instance.js';
import '@ui5/webcomponents-icons/dist/group-2.js';
import '@ui5/webcomponents-icons/dist/alert.js';
import '@ui5/webcomponents-icons/dist/cart.js';
import '@ui5/webcomponents-icons/dist/bell.js';
import '@material/mwc-icon';
import '@material/mwc-icon-button';
import '@vaadin/vaadin-grid'; 
import { State } from '../../redux/types';
//import './TerminalList.css';
import { InstalarAppModal } from '../../components/modals/InstalarAppModal';
import '../../components/modals/InstalarAppModal'; 
import { AgregarInstaladorModal } from '../../components/modals/AgregarInstaladorApp';
import '../../components/modals/AgregarInstaladorApp';
import { DescargarInstaladorModal } from '../../components/modals/DescargarInstaladorApp';
import '../../components/modals/DescargarInstaladorApp';
import { AgregarVersionModal } from '../../components/modals/AgregarVersionApp';
import '../../components/modals/AgregarVersionApp';
import {
  Grid,
  GridActiveItemChangedEvent,
  GridItemModel,
} from '@vaadin/vaadin-grid';
import { AxiosResponse } from 'axios';

import '../../components/modals/EditOrganizationModal';
import router from '../../router/router.js';
import {
  CommunicationTerminalApi,
  CommunicationTerminalApiRequestCommandRequest,
  TerminalApi,
  OrganizationApi,
} from '../../../api/api.js';
import {
    ConfigurationApi,
    ConfigurationApiCreateConfigurationRequest,
    ConfigurationApiUpdateConfigurationRequest,
    AppApi,
    AppList,
    TemplateApi,
    RequestFolioCreate,
    FolioApiCreateFolioRequest,
    FolioApi,
    FolioApiListFolioRequest
  } from '../../../api';
  import { editarApp,verApp } from '../../redux/actions';
import {
  RequestCommandTypeCommandEnum,
  Terminal,
  TerminalList,
} from '../../../api/index.js';
import store from '../../redux/store.js';
import { ConfigConsole,PageFolio } from '../../redux/actions';
import { editarTerminal } from '../../redux/actions/index.js';
import { PushConfigModal } from '../../components/modals/PushConfigurationsDialog';
import '../../components/modals/PushConfigurationsDialog';
import { UbicacionTerminalMapa } from '../../components/modals/UbicacionTerminalMapa';
import { CrearFolio } from '../../components/modals/CrearFolio';
import '../../components/modals/CrearFolio';
//import { CambiarMaster } from '../../components/modals/CambiarMaster';
import '../../components/modals/ListadoApp';
import { ListadoFolio } from '../../components/modals/ListadoFolio';
import '../../components/modals/ListadoFolio';
import { ConfigTerminalFolio } from '../../components/ConfigTerminalFolio';
import '../../components/ConfigTerminalFolio';
import '../../components/modals/UbicacionTerminalMapa';
import { EditOrganizationModal } from '../../components/modals/EditOrganizationModal.js';
import { apisConfigurator } from '../../utils/axios';
import { EnviarMensajeModal } from '../../components/modals/EnviarMensajeModal';
import '../../components/modals/EnviarMensajeModal';
import { HistorialComandosModal } from '../../components/modals/HistorialComandosModal';
import '../../components/modals/HistorialComandosModal';

import {
  errorNotification,
  succesNotification,
} from '../../services/notifications';
import { getApps } from '../../redux/actions';
import { getTerminals } from '../../redux/actions/terminals';
import { Unsubscribe } from '@reduxjs/toolkit';

@customElement('apps-list-page')
export class AppsList extends LitElement {
    private appApi: AppApi;
  private terminalApi: TerminalApi;
  private communicationApi: CommunicationTerminalApi;
  private organizationApi!: OrganizationApi;
  private folio: FolioApi;

  private storeUnsuscriber?: Unsubscribe;

  get enviarMensajeModal() {
    return document.getElementById('enviarMensajeModal') as EnviarMensajeModal;
  }

  get listadoFolio() {
    return document.getElementById('listadoFolio') as ListadoFolio;
  }

  get instalarAppModal() {
    return document.getElementById('instalarAppModal')! as InstalarAppModal;
  }

  get agregarInstaladorModal() {
    return document.getElementById(
      'agregarInstaladorModal'
    )! as AgregarInstaladorModal;
  }

  get descargarInstaladorModal() {
    return document.getElementById(
      'descargarInstaladorModal'
    )! as DescargarInstaladorModal;
  }

  get agregarVersionModal() {
    return document.getElementById(
      'agregarVersionModal'
    )! as AgregarVersionModal;
  }

  /*get cambiarMaster() {
    return document.getElementById('cambiarMaster') as CambiarMaster;
  }*/

  get historialComandosModal() {
    return document.getElementById(
      'historialComandosModal'
    ) as HistorialComandosModal;
  }

  @state()
  private terminals: TerminalList[] = [];

  private constructor() {
    super();
    this.terminalApi = new TerminalApi(...apisConfigurator('Terminales'));
    this.communicationApi = new CommunicationTerminalApi(
      ...apisConfigurator('Terminales')
    );
    this.organizationApi = new OrganizationApi(
      ...apisConfigurator('Organización')
    );
    this.appApi = new AppApi(...apisConfigurator('Aplicaciones'));
    this.folio = new FolioApi(
      ...apisConfigurator('Folio')
    );
  }

  @state()
  private apps: AppList[] = [];

  @state()
  termi?: Terminal;

  @state()
  folioList?: any;

  @state()
  app?: any;

  @state()
  idApp?: any;

  @state()
  appId?: any;

  @state()
  appEdit?: any;

  @state()
  versions?: any;

  @state()
  NameBtn = 'Crear Terminal';

  @state()
  detail = false;

  @state()
  private loading = false;

  @state()
  organizacion = '';

  @state()
  private detailsOpenedItem: AppList[] = [];

  @state()
  loader = true;

  @state()
  brand = '';

  @state()
  serial = '';

  get editOrganizationModal() {
    return document.getElementById(
      'edit-organization-modal'
    ) as EditOrganizationModal;
  }

  private handleVersionCreated(ev: CustomEvent) {
    const { detail } = ev;
    if (this.app!.version) {
      this.app!.version.push(detail);
    } else {
      this.app!.version = [detail];
    }
    this.requestUpdate();
  }

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  disconnectedCallback(): void {
    this.storeUnsuscriber && this.storeUnsuscriber();
  }

  private async handleDeleteTerminal(terminal: Terminal) {
    try {
      const res = await this.terminalApi.terminalDelete({
        brand: terminal.brand,
        serial: terminal.serial,
      });

      this.terminals = this.terminals.filter(t => t.serial !== terminal.serial);
      this.termi = undefined;
    } catch (err) {}
  }

  protected firstUpdated(): void {
    this.getTerminals();
    this.getApps();
  }
 
  async getTerminals() {
    store.dispatch(getTerminals());
    this.storeUnsuscriber = store.subscribe(() => {
      const { list, loading, listApps } = store.getState();
      console.log(loading);
      console.log({ list });
      this.terminals = list as TerminalList[];
      this.loader = loading;
    });
  }

  private actionRenderer = (root: HTMLElement, _: any, item: any) => {
    render(
      html`
        <mwc-icon-button
          @click=${() => router.cambiarSeccion('terminal')}
          icon="visibility"
        ></mwc-icon-button>
        <mwc-icon-button
          icon="edit"
          @click="${() => {
            store.dispatch(editarTerminal(item.item));
            router.cambiarSeccion('edit-terminal');
          }}"
        ></mwc-icon-button>
        <mwc-icon-button
          icon="corporate_fare"
          @click="${() => {
            store.dispatch(editarTerminal(item.item));
            this.editOrganizationModal.open();
          }}"
        ></mwc-icon-button>
        <mwc-icon-button
          icon="delete"
          @click="${() => {
            this.handleDeleteTerminal(item.item);
          }}"
        ></mwc-icon-button>
        <mwc-icon-button icon="more_vert"></mwc-icon-button>
      `,
      root
    );
  };

  edit(e: any) {
    store.dispatch(editarTerminal(e));
    router.cambiarSeccion('edit-terminal');
  }

  get CrearFolio() {
    return document.getElementById('crearFolio')! as CrearFolio;
  }

  get pushConfigModal() {
    return document.getElementById('pushConfigModal')! as PushConfigModal;
  }

  /*async getApps() {
    const { configConsole } = store.getState() as State;
    console.log(configConsole);
    try {
      const res = await this.appApi.listApp();

      this.apps = res.data;
      const apps = res.data;
      //this.detailsOpenedItem = this.apps;
      store.dispatch(
        ConfigConsole({
          apps,
          ...configConsole,
        })
      );
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false; 
    }
  }*/

  async getApps() {
    store.dispatch(getApps());
    this.storeUnsuscriber = store.subscribe(() => {
      const { list, loading, listApps } = store.getState();
      console.log(loading); 
      console.log("list",{ listApps });
      this.apps = listApps as AppList[];
      this.loading = loading;
    });
  }

  get UbicarTerminalMapa() {
    return document.getElementById(
      'ubicacionTerminalMapa'
    )! as UbicacionTerminalMapa;
  }

  async rebootTerminal(terminal: Terminal) {
    const request: CommunicationTerminalApiRequestCommandRequest = {
      brand: terminal.brand,
      serial: terminal.serial,
      requestCommand: {
        commandId: `reboot-${terminal.serial}-${Date.now()}`,
        typeCommand: RequestCommandTypeCommandEnum.Reset,
      },
    };

    const res = await this.communicationApi.requestCommand(request);

    //console.log(res);
  }

  private async pushConfig(terminal: any) {
    console.log('terminal', terminal);
    if (!terminal) return;
    const requestCommand: CommunicationTerminalApiRequestCommandRequest = {
      brand: terminal.brand,
      serial: terminal.serial,
      requestCommand: {
        commandId: `pushConfig-${terminal.serial}-${Date.now()}`,
        typeCommand: RequestCommandTypeCommandEnum.UpdateAppConfig,
        parameters: ['20fa74aa-403c-40a3-9047-1699b236bfae'],
      },
    };
    console.log(requestCommand);
    try {
      console.log('empezar');
      const res = await this.communicationApi.requestCommand(requestCommand);
      console.log('envio PETICION res', res);
      succesNotification('Comando ejecutado con exito!');
    } catch (err: any) {
      console.log('envio mensaje err', err);
    }
  }
 
  async ObtenerOrganizacion(id: string) {
    await this.organizationApi
      .organizationGet({ id })
      .then(response => response)
      .then(data => {
        this.organizacion = data.data.name;
      });
  }

  async getApp(appId:string) {
    //const appId = (store.getState() as State).verAppId;

    console.log(appId)

    try {
      const res = await this.appApi.getApp({ id: appId });
      console.log(res.data)
      this.app = res.data;
      this.idApp = appId;
      console.log(res.data)
      this.versions=res.data.version

        if (this.detail === false) {
          this.detail = true;
        } else if (this.detail === true) {
          this.detail = false;
        }

    } catch (err) {
      errorNotification('Error al obtener la app');
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  private async refrescarInfo(terminal: any) {
    console.log('terminal', terminal);
    if (!terminal) return;
    const requestCommand: CommunicationTerminalApiRequestCommandRequest = {
      brand: terminal.brand,
      serial: terminal.serial,
      requestCommand: {
        commandId: `displayMessage-${terminal.serial}-${Date.now()}`,
        typeCommand: RequestCommandTypeCommandEnum.SendInformation,
        parameters: [],
      },
    };
    console.log(requestCommand);
    try {
      console.log('empezar');
      const res = await this.communicationApi.requestCommand(requestCommand);
      console.log('envio PETICION res', res);
      succesNotification('Comando ejecutado con exito!');
    } catch (err: any) {
      console.log('envio mensaje err', err);
    }
  }

  async toggleBluetooth() {
    const request: CommunicationTerminalApiRequestCommandRequest = {
      brand: this.termi!.brand,
      serial: this.termi!.serial,
      requestCommand: {
        commandId: `bluetooth-${this.termi?.serial}-${Date.now()}`,
        typeCommand:
          this.termi?.generalInformation?.bluetoothEnable.value === '1'
            ? RequestCommandTypeCommandEnum.DeactivateBluetooth
            : RequestCommandTypeCommandEnum.ActivateBluetooth,
      },
    };

    const res = await this.communicationApi.requestCommand(request);
    if (!this.termi) return;
    if (this.termi.generalInformation.bluetoothEnable.value === '1') {
      this.termi.generalInformation.bluetoothEnable.value = '0';
    } else {
      this.termi.generalInformation.bluetoothEnable.value = '1';
    }
    //console.log(res);
  }

  async toggleWifi() {
    const request: CommunicationTerminalApiRequestCommandRequest = {
      brand: this.termi!.brand,
      serial: this.termi!.serial,
      requestCommand: {
        commandId: `wifi-${this.termi?.serial}-${Date.now()}`,
        typeCommand:
          this.termi?.generalInformation?.wifiEnable.value === '1'
            ? RequestCommandTypeCommandEnum.DeactivateWifi
            : RequestCommandTypeCommandEnum.ActivateWifi,
      },
    };

    const res = await this.communicationApi.requestCommand(request);
    console.log(res)
    if (!this.termi) return;
    if (this.termi.generalInformation.wifiEnable.value === '1') {
      this.termi.generalInformation.wifiEnable.value = '0';
    } else {
      this.termi.generalInformation.wifiEnable.value = '1';
    }

    //console.log(res);
  }

  async getTerminalDetail(terminal: TerminalList) {
    const res = await this.terminalApi.terminalGet({
      brand: terminal.brand,
      serial: terminal.serial,
    });
    this.termi = res.data;
    //console.log(res.data);
  }

  render() {
    return html`
      <main
        class="w-full h-full FondoColor flex  ${!this.loader
          ? ''
          : 'items-center justify-center'}"
      >
        ${this.loader !== true
          ? html`
              <section
                class="w-3/4 relative flex flex-wrap p-4 SectionList rounded-l-lg"
              >
                <!-- <div id='editorHolder'></div> -->
                <vaadin-grid
                  class="w-screen h-full"
                  .items="${this.apps}"
                  .detailsOpenedItems="${this.detailsOpenedItem}"
                  @active-item-changed="${(
                    e: GridActiveItemChangedEvent<AppList>
                  ) => { 
                    this.appEdit = e.detail.value;
                    this.detailsOpenedItem = [e.detail.value];
                    if (!e.detail.value) return false;
                    this.appId = e.detail.value.id;
                    this.getApp(e.detail.value.id)
                  }}"
                  .rowDetailsRenderer="${guard(
                    [],
                    () =>
                      (
                        root: HTMLElement,
                        _: Grid,
                        model: GridItemModel<AppList>
                      ) => {

                        render(
                          html`<vaadin-form-layout
                            .responsiveSteps=" ${[
                              { minWidth: '0', columns: 3 },
                            ]}"
                          >
                            <div
                            style="background-color:#F0EFFF;"
                              class="w-full h-14 flex items-center mt-5 justify-center flex-row "
                            >
                               <div
                                class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                                @click=${() => {
                                  console.log(this.appEdit);
                                  //this.CrearFolio.open(this.app);
                                  store.dispatch(verApp(this.appEdit.id!))
                                  store.dispatch(editarApp(this.app));
                                  router.cambiarSeccion('edit-app');
                                }}
                              >  
                                <div>  
                                  <mwc-icon-button icon="edit">
                                  </mwc-icon-button>
                                </div>
                                <div>
                                  <p class="text-center">Editar</p>
                                </div>
                              </div>  
                              <div
                                class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                                @click=${() => {
                                  this.agregarVersionModal.open(this.appId); 
                                }}
                              >  
                                <div> 
                                  <mwc-icon-button icon="repeat_one_on">
                                  </mwc-icon-button>
                                </div>
                                <div>
                                  <p class="text-center">Version</p>
                                </div>
                              </div>  
                              <div
                                class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                                @click=${() => {
                                  this.agregarInstaladorModal.open(this.app,this.appId);
                                }}
                              >  
                                <div> 
                                  <mwc-icon-button icon="system_update">
                                  </mwc-icon-button>
                                </div>
                                <div>
                                  <p class="text-center">Instalador</p>
                                </div>
                              </div> 
                              <div
                                class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                                @click=${() => {
                                  console.log("this.app",this.app,"this.idApp",this.idApp)
                                  this.instalarAppModal.open(this.app, this.idApp); 
                                }}
                              >  
                                <div> 
                                  <mwc-icon-button icon="install_mobile">
                                  </mwc-icon-button>
                                </div>
                                <div>
                                  <p class="text-center">Instalar</p>
                                </div>
                              </div>                              
                            </div>
                          </vaadin-form-layout>`,
                          root
                        );
                      }
                  )}"
                > 
                  <vaadin-grid-selection-column></vaadin-grid-selection-column>
                  <vaadin-grid-sort-column
                    path="name"
                  ></vaadin-grid-sort-column>
                  <vaadin-grid-sort-column
                    header="description"
                    path="description"
                  ></vaadin-grid-sort-column>
                  <vaadin-grid-sort-column
                    header="Nombre Paquete"
                    path="packageName"
                  ></vaadin-grid-sort-column>
                  <!-- <vaadin-grid-sort-column
                    path="status"
                    header="Estatus"
                    .renderer="${guard(
                      [],
                      () => (root: HTMLElement, _: any, model: any) => {
                        const bg =
                          model.item.status == `online`
                          ? `text-green-500`
                          : model.item.status == `created`
                          ? `text-orange-500`
                          : `text-red-500`;
                        render(
                          html`<p
                            class="py-1 capitalize px-4 w-fit ${bg} rounded font-bold"
                          >
                            ${model.item.status}
                          </p>`,
                          root
                        );
                      }
                    )}"
                  ></vaadin-grid-sort-column> -->
                  <!-- <vaadin-grid-column
                    .headerRenderer="${(root: HTMLElement) => {
                    render(html``, root);
                  }}"
                    text-align="end"
                    .renderer="${this.actionRenderer}"
                  >
                  </vaadin-grid-column> -->
                </vaadin-grid>
                <mwc-icon-button 
                  icon="autorenew"
                  class="absolute top-0 right-0"
                  @click=${() => {
                    this.getApps();
                  }}
                ></mwc-icon-button>
                <edit-organization-modal
                  id="edit-organization-modal"
                ></edit-organization-modal>
              </section>
              <section class="w-1/4  SectionDetail justify-center scroll">
                <div class="w-full flex justify-center">
                  <mwc-button
                    raised
                    class="rounded w-4/5 mx-auto mt-4 mb-2"
                    style="--mdc-theme-primary: #2E3EAE; --mdc-theme-on-primary: white;"
                    @click="${() => {
                      router.cambiarSeccion('create-app');
                    }}"
                  >
                  <mwc-icon>apps</mwc-icon>
                    Crear App
                  </mwc-button>
                </div>
                <div class=" pt-3 flex items-center ">
              <p class="text-base	text-blue-500	ml-3 ">Historial de versiones</p>
              <!-- <mwc-icon-button icon="settingsremoteoutlined">
              </mwc-icon-button> -->
            </div>
                ${this.detail === true 
                  ? html`
                      <div class="w-full py-3 border-b-2 border-grey-100 px-4">
                      <div class="pt-1 ml-5">
            <ul class="list-disc pl-2">
              ${this.versions.map(  
                (version:any, index:number) => html`
                  <li
                    class="flex items-center gap-2 ml-2"
                  >
                  <p class="text-slate-500 hover:text-blue-600 text-lg "
                  @click=${() => {
                                  this.descargarInstaladorModal.open(version,this.appId);
                                }}>${version.briefDescription}</p>
                    <p class="text-slate-500 hover:text-blue-600 text-lg "
                    @click=${() => {
                      console.log("version",version,"appId",this.appId)
                                  this.descargarInstaladorModal.open(version,this.appId);
                                }}>${version.version}</p>
                    <!-- <mwc-icon-button  
                      class="ml-1"
                      icon="edit"
                      ariaLabel="Actualizar"  
                      @click=${async () => {
                        console.log(this.app)
                      }}
                    ></mwc-icon-button> -->
                    <!-- <mwc-icon-button
                      class="ml-1"
                      icon="delete"
                      ariaLabel="Eliminar" 
                      @click=${() => {

                      }}
                    ></mwc-icon-button> -->
                  </li>
                `
              )}
            </ul>
          </div>
          </div>
          <div>
            <!-- <mwc-button
              class="rounded w-4/5 mx-auto mt-4 mb-2"
              style="--mdc-theme-primary: #2E3EAE;"
              raised
              icon="add_circle"
              @click=${() => {
                const { configConsole } = store.getState() as State;
                store.dispatch(
                  ConfigConsole({
                    page:1,
                    ...configConsole,
                  })
                );
                this.listadoFolio.open(this.app)
              }}
            >
              Ver mas</mwc-button
            > -->
          </div>
                        <!-- <div>
                          <h1 class="text-2xl	text-blue-900 ml-3 mb-8	">
                            ${this.termi?.serial}
                          </h1>
                        </div>

                        <div class="flex flex-row items-center">
                          <p class="text-base	font-bold	text-blue-500	ml-3">
                            Modelo:
                          </p>
                          <p class="text-base		text-blue-500	ml-3">
                            ${this.termi?.model}
                          </p>
                        </div>
                        <div class="flex flex-row items-center">
                          <p class="text-base	font-bold	text-blue-500	ml-3">
                            Marca:
                          </p>
                          <p class="text-base		text-blue-500	ml-3">
                            ${this.termi?.brand}
                          </p>
                        </div>
                        <div class="flex flex-row items-center">
                          <p class="text-base	font-bold	text-blue-500	ml-3">
                            Firmware:
                          </p>
                          <p class="text-base	text-blue-500	ml-3">
                            ${this.termi?.generalInformation?.firmwareVersion
                              ?.value}
                          </p>
                        </div>
                        <div class="flex flex-row pt-5 pl-3">
                          <mwc-icon class="text-blue-900"
                            >battery_charging_full</mwc-icon
                          >
                          <p class="text-lg	text-blue-900 mr-2">
                            ${this.termi?.generalInformation?.batteryPercentage
                              ?.value}%
                          </p>
                          <mwc-icon class="text-blue-900">memory</mwc-icon>
                          <p class="text-lg	text-blue-900 mr-2">
                            ${this.termi?.generalInformation?.memoryAvailable
                              ?.value}MB
                          </p>
                          <mwc-icon class="text-blue-900">storage</mwc-icon>
                          <p class="text-lg	text-blue-900">
                            ${this.termi?.generalInformation?.storageAvailable
                              ?.value}MB
                          </p>
                        </div> -->
                        <!-- <div class="flex flex-row pt-3"> 
                          <div
                            class="btnBloquear items-center content-center cursor-pointer flex  flex-row"
                            @click=${() => {
                              //this.historialComandosModal.open(this.termi!);
                            }} 
                          >

                            <mwc-icon-button icon="block">
                            </mwc-icon-button>
                            <p class="text-center ">Bloquear</p>
                          </div>
                        </div>
                        <div class="flex flex-row pt-3"> 
                          <div
                            class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                            @click=${() => {
                              this.historialComandosModal.open(this.termi!);
                            }} 
                          >

                              <mwc-icon-button icon="receipt">
                              </mwc-icon-button>
                              <p class="text-center ">Historial Comandos</p>
                          </div>
                        </div> -->
                        <!-- <div class="flex flex-row pt-3 ">
                          <div
                            class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                            @click=${() => {
                              console.log(this.termi);
                              const obj = this.termi?.generalInformation;
                              if (
                                JSON.stringify(obj) == '{}' ||
                                obj === undefined
                              ) {
                                errorNotification(
                                  'Terminal sin data geolocalizacion!'
                                );
                              } else {
                                this.UbicarTerminalMapa.open(this.termi!);
                              }
                            }}
                          >
                            <mwc-icon-button icon="place"> </mwc-icon-button>

                            <p class="text-center ">Ver en mapa</p>
                          </div>
                        </div> -->
                        <!-- <div class="flex flex-row pt-3 ">
                          <div
                            class="btnMessage w-44 items-center content-center cursor-pointer flex  flex-row"
                            @click=${() => {
                              
                            }}
                          >
                            <mwc-icon-button icon="build"> </mwc-icon-button>

                            <p class="text-center ">Config avanzada</p>
                          </div>
                        </div> -->
                        <!-- <div class=" pt-3 flex items-center  justify-between">
                          <p
                            class="text-base	font-bold	text-blue-500	ml-3 text-base"
                          >
                            Organizaciones
                          </p>
                        </div> -->
                        <!-- <div class="pt-1 ml-5">
                          <ul class="list-disc pl-2">
                            <li>
                              <p class="text-base	text-blue-800	">
                                ${this.organizacion}
                              </p>
                            </li>
                          </ul>
                        </div> -->

                      <!-- <div class="w-full py-3 pl-5 border-b-2 border-grey-100">
                        <div
                          class="group_icon items-center p-2  flex content-center  flex-row "
                        >
                          <div class="text-blue-500">
                            <mwc-icon-button icon="wifi"> </mwc-icon-button>
                          </div>
                          <div class="flex justify-between w-full">
                            <h1 class="text-1xl	text-blue-900 ml-3 	">Wifi</h1>

                            <mwc-switch
                              slot="meta"
                              .selected=${this.termi?.generalInformation
                              ?.wifiEnable?.value === '1'}
                              @click=${() => {
                              this.toggleWifi();
                            }}
                            ></mwc-switch>
                          </div>
                        </div> -->

                        <!-- <div
                          class=" group_icon items-center p-2   flex  flex-row cursor-default"
                        >
                          <div class="text-blue-500 cursor-default">
                            <mwc-icon-button icon="bluetooth">
                            </mwc-icon-button>
                          </div>
                          <div class="flex justify-between w-full">
                            <h1 class="text-1xl	text-blue-900 ml-3 	">
                              Bluetooth
                            </h1>

                            <mwc-switch
                              slot="meta"
                              .selected=${this.termi?.generalInformation
                              ?.bluetoothEnable?.value === '1'}
                              @click=${() => {
                              this.toggleBluetooth();
                            }}
                            ></mwc-switch>
                          </div>
                        </div>
                      </div> -->
                      <!-- <div
                        class="w-full mt-5 flex-col flex justify-center items-center"
                      >
                        <mwc-button
                          raised
                          style="--mdc-theme-primary: #E3E1EC; --mdc-theme-on-primary: #46464F"
                          class="mb-2 rounded w-3/4"
                          icon="edit"
                          @click="${() => {
                            store.dispatch(editarTerminal(this.termi!));
                            router.cambiarSeccion('edit-terminal');
                          }}"
                          >Editar</mwc-button
                        >
                        <mwc-button
                          raised
                          style="--mdc-theme-primary: tomato; --mdc-theme-on-primary: white"
                          class="mb-2 rounded w-3/4"
                          icon="delete"
                          @click="${() => {
                            this.handleDeleteTerminal(this.termi!);
                          }}"
                          >Eliminar</mwc-button
                        > 
                      </div> -->
                    `
                  : html``}
              </section>
            `
          : html`
              <mwc-circular-progress
                indeterminate
                density=${20}
              ></mwc-circular-progress>
            `}
      </main>
      <!-- <listado-folio id="listadoFolio"></listado-folio>
      <crear-folio id="crearFolio"></crear-folio>
      <listado-app id="listadoApp"></listado-app> -->
      <!-- <cambiar-master id="cambiarMaster"></cambiar-master> -->
      <ubicacion-terminal-mapa
        id="ubicacionTerminalMapa"
      ></ubicacion-terminal-mapa>
      <instalar-app-modal id="instalarAppModal"></instalar-app-modal>
      <push-config-modal id="pushConfigModal"></push-config-modal>
      <enviar-mensaje-modal id="enviarMensajeModal"></enviar-mensaje-modal>
      <historial-comandos-modal
        id="historialComandosModal"
      ></historial-comandos-modal> 
      <agregar-instalador-modal
        id="agregarInstaladorModal"
      ></agregar-instalador-modal>
      <descargar-instalador-modal
        id="descargarInstaladorModal"
      ></descargar-instalador-modal>
      <agregar-version-modal
        id="agregarVersionModal"
        @version-created=${this.handleVersionCreated}
      ></agregar-version-modal>
    `;
  }
}
