export const PARAMETROS = {
    //Folio
    MERCHANTID: {
        exReg:".*",
        type:"string",
        name:"affiliationId",
        numero:19
    },
    INDEX: {
        exReg:".*",
        type:"number",
        name:"index",
        numero:2
    },
    DESCRIPTION: {
        exReg:".*",
        type:"string",
        name:"description",
        numero:15
    },
    //Banorte-Comercio-F
    INDUSTRIA:{
        exReg:"^[RTHSGC]$",
        type:"string",
        name:"business",
        numero:2
    },
    NOMBREDELCOMERCIO:{
        exReg:"^.{0,38}$",
        type:"number",
        name:"name",
        numero:15
    },
    CALLEYNUMERODELCOMERCIO:{
        exReg:"^.{0,38}$",
        type:"string",
        name:"address1",
        numero:16
    },
    CIUDADDELCOMERCIO:{
        exReg:"^.{0,38}$",
        type:"string",
        name:"address2",
        numero:17
    },
    TERMINALID:{
        exReg:".*",
        type:"string",
        name:"terminalId",
        numero:20
    },
    TIDAMEX:{
        exReg:".*",
        type:"string",
        name:"amexId",
        numero:21
    },
    CAJA:{
        exReg:".*",
        type:"string",
        name:"cashRegister",
        numero:23
    },
    MONEDA:{
        exReg:".*",
        type:"string",
        name:"currencyCode",
        numero:24
    },
    RB:{
        exReg:"^[01]+$",
        type:"string",
        name:"refund",
        numero:25 
    },
    BNTEVEND: {
        exReg:".*",
        type:"string",
        name:"waiter",
        numero:52
    },
    MAXTURBO: {
        exReg:".*",
        type:"string",
        name:"numOfShifts",
        numero:29
    },
    REFERENCIA1: {
        exReg:"^[01]+$",
        type:"string",
        name:"ref1", // 1 activo 0 inactivo
        numero:42

    },
    REFERENCIA2: {
        exReg:"^[01]+$",
        type:"string",
        name:"ref2", // 1 activo 0 inactivo
        numero:43
    },
    REFERENCIA3: {
        exReg:"^[01]+$",
        type:"string",
        name:"ref3", // 1 activo 0 inactivo
        numero:44
    },
    /*P2: {
        exReg:".*",
        type:"string",
        name:""
    },*/
    TCOMBOBOXRef1:{
        exReg:".*",
        type:"string",
        name:"comboRef",
        numero:48
    },
    BL:{
        exReg:"[0-9]{1,3}",
        type:"number",
        name:"batchLimit",
        numero:51
    },
    BW:{
        exReg:"[0-9]{1,3}",
        type:"number",
        name:"batchWarn",
        numero:50
    },
    CASHBACK: {
        exReg:"^[01]+$",
        type:"string",
        name:"cashback", // 1 activo 0 inactivo
        numero:58
    },
    // Banorte Autorizador - F
    AMEX:{
        exReg:".*",
        type:"string",
        name:""
    },
    PORT:{
        exReg:".*",
        type:"string",
        name:""
    },
    TLS:{
        exReg:".*",
        type:"string",
        name:""
    },
    URL:{
        exReg:".*",
        type:"string",
        name:""
    },
    //Banorte Terminal - F
    PD: {
        exexReg:"^[01]+$",
        type:"string",
        name:"paymentPlan", // 1 activo 0 inactivo
        numero:"30"
    },
    QPS: {
        exReg:".*",
        type:"string",
        name:"qps",
        numero:39
    },
    LIMITEQPS:{
        exReg:".*",
        type:"string",
        name:"qpsLimit",
        numero:40
    },
    LEYENDAQPS: {
        exReg:".*",
        type:"string",
        name:"qpsLabel",
        numero:41
    },
    PWDMASTER: {
        exReg:".*",
        type:"string",
        name:""
    },
    PWDVOID: {
        exReg:".*",
        type:"string",
        name:""
    },
    CVMLIMIT: {
        exReg:".*",
        type:"string",
        name:""
    },
    PWDADMIN: {
        exReg:".*",
        type:"string",
        name:""
    },
    PWDADJUST: {
        exReg:".*",
        type:"string",
        name:""
    },
    PWDKEYBOARD: {
        exReg:".*",
        type:"string",
        name:""
    },
    PWDREFUND: {
        exReg:".*",
        type:"string",
        name:""
    },
    OPENKEYBOARD: {
        exReg:".*",
        type:"string",
        name:""
    },
    // Razon Social
    CONTACTORS: {
        exReg:".*",
        type:"string",
        name:"contactName",
        numero:4
    },
    CONTACTOA: {
        exReg:".*",
        type:"string",
        name:"contactName",
        numero:10
    },
    EMAIL:{
        exReg:"^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$",
        type:"string",
        name:"contactEmail",
        numero:6
    },
    TELEFONORS: {
        exReg:".*",
        type:"string",
        name:"contactPhone",
        numero:5
    },
    TELEFONOA: {
        exReg:".*",
        type:"string",
        name:"contactPhone",
        numero:11
    },
    RFCRS: {
        exReg:".*",
        type:"string",
        name:"taxNumber",
        numero:8
    },
    RFCA: {
        exReg:".*",
        type:"string",
        name:"taxNumber",
        numero:13
    },
    CPRS: {
        exReg:".*",
        type:"string",
        name:"adress",
        numero:7
    },
    CPA: {
        exReg:".*",
        type:"string",
        name:"adress",
        numero:12
    },
    // AFILIACION-COMERCIO
    NOMBREDELACADENA: {
        exReg:".*",
        type:"string",
        name:"businessId",
        numero:3
    },
    NOMBREDELATIENDA: {
        exReg:".*",
        type:"string",
        name:"name",
        numero:9
    },
  };
  