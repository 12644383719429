/* eslint-disable prettier/prettier */

import { LitElement, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import '@ui5/webcomponents/dist/Dialog.js';
import '@vaadin/vaadin-select';
import '../CustomSelect/CustomSelect';
import { AppApi, License, AppList } from '../../../api';
import { apisConfigurator } from '../../utils/axios';

@customElement('ver-licencia-modal')
export class VerLicenciaModal extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  @state()
  private license?: License;

  private appApi: AppApi;

  @state()
  private apps: AppList[] = [];

  constructor() {
    super();
    this.appApi = new AppApi(...apisConfigurator('Detalles de licencia'));
  }

  private get dialog() {
    return document.getElementById('dialog-ver-licencia')! as any;
  }

  protected firstUpdated(): void {}

  async open(license: License) {
    this.license = license;
    const res = await this.appApi.listApp();
    this.apps = res.data;
    //await this.getApp(license.app);
    const found:any = this.apps.find((element) => element.packageName == license.app);
    console.log("found",found)
    this.license = { ...this.license!, app: found.name };

    this.dialog.open = true;
  } 

  close() {
    console.log('close');
    this.dialog.open = false;
    this.license = undefined;
  }

  async getApp(appId: string) {
    const res = await this.appApi.getApp({ id: appId });

    this.license = { ...this.license!, app: res.data.name };
  }

  protected render() {
    return html` <ui5-dialog
      id="dialog-ver-licencia"
      header-text="Licencia para ${this.license?.app}"
      class="w-6/12 h-6/12"
    >
      <div
        class="px-4 flex flex-col items-center h-full"
        name="createUpdateLicense"
      >
        <div class="mx-2 w-full flex flex-col items-center relative">
          <div class="flex flex-wrap justify-center">
            
          <div>
                <p class="p-2">Serial</p><mwc-textfield
              required
              class="w-[210px] mx-1 mb-3"
              validationMessage="El serial es requerido"
              id="serial"
              minLength="1"
              .value=${this.license?.serial ?? ''}
              autoValidate
              outlined
              disabled
            >
                </div>
            </mwc-textfield>
            
            <div>
                <p class="p-2">Marca</p><mwc-textfield
              required
              class="w-[210px] mx-1 mb-3"
              validationMessage="La marca es requerido"
              id="brand"
              minLength="1"
              .value=${this.license?.brand ?? ''}
              autoValidate
              outlined
              disabled
            >
                </div>
            </mwc-textfield>
              <div>
                <p class="p-2">Modelo</p>
            <mwc-textfield
              required
              class="w-[210px] mx-1 mb-3"
              validationMessage="El modelo es requerido"
              id="model"
              minLength="1"
              .value=${this.license?.model ?? ''}
              autoValidate
              outlined
              disabled
            >
            </mwc-textfield>
                </div>
              <div>
                <p class="p-2">Tiempo de Renovación</p>
            <mwc-textfield
              class="w-[210px] mx-1 mb-3"
              validationMessage="El tiempo de renovación es requerido"
              id="renewalTime"
              minLength="1"
              type="number"
              min="0"
              suffix="dias"
              .value=${this.license?.renewalTime ?? ''}
              required
              autoValidate
              outlined
              disabled
            >
            </mwc-textfield>
                </div>
              <div>
                <p class="p-2">Prioridad</p>
            <mwc-textfield
              class="w-[210px] mx-1 mb-3"
              validationMessage="La prioridad es requerida"
              id="priority"
              minLength="1"
              type="number"
              min="0"
              max="99"
              step="1"
              .value=${this.license?.priority ?? ''}
              required
              autoValidate
              outlined
              disabled
            >
            </mwc-textfield>
                </div>
              <div>
                <p class="p-2">Límite de terminales</p>
            <mwc-textfield
              class="w-[210px] mx-1 mb-3"
              validationMessage="El límite de terminales es requerido"
              id="terminalLimit"
              minLength="1"
              type="number"
              min="0"
              max="999"
              step="1"
              .value=${this.license?.terminalLimit ?? ''}
              required
              autoValidate
              outlined
              disabled
            >
            </mwc-textfield>
                </div>
              <div>
                <p class="p-2">Fecha de expiración</p>
            <mwc-textfield
              class="w-[210px] mx-1 mb-3"
              validationMessage="La fecha de expiración es requerida"
              id="endDate"
              type="date"
              .value=${this.license?.endDate ?? ''}
              required
              autoValidate
              outlined
              disabled
            >
            </mwc-textfield>
                </div>
              <div>
                <p class="p-2">Descripción</p>
            <mwc-textfield
              class="w-[210px] mx-1 mb-3"
              validationMessage="La descripción es requerida"
              
              id="description"
              .value=${this.license?.description ?? ''}
              outlined
              disabled
            >
            </mwc-textfield>
                </div>
          </div>
        </div>

        <div slot="footer" class="dialog-footer mt-auto">
          <button
            class="text-red-600 p-4 rounded-md uppercase"
            id="closeDialogButton"
            @click=${() => {
              this.close();
            }}
          >
            Cerrar
          </button>
        </div>
          </div>
    </ui5-dialog>`;
  }
}
