/* eslint-disable prettier/prettier */
import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import leaflet from 'leaflet';
import 'leaflet.markercluster';
import {
  TerminalApi,
} from '../../../api/api.js';
import { apisConfigurator } from '../../utils/axios';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import { ObjectToArrayIndexString } from '../../utils/functions.js';

@customElement('custom-map')
export class CustomMap extends LitElement {
  private terminalApi: TerminalApi;
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private constructor() {
    super();
    this.terminalApi = new TerminalApi(...apisConfigurator('Terminales'));
  }

  @state()
  map?: leaflet.Map;

  @state()
  private loading = false;

  @state()
  private terminals:any;

  @state()
  private terminals2:any;

  get customMap() {
    return document.getElementById('customMap')!;
  }

  protected async firstUpdated() {
    await this.getTerminals();
    this.map = await leaflet.map(this.customMap, {}).setView([23.0, -102.0], 2);
    this.configureMap(this.map); 
  }
 
  async getTerminals() {
    let objeto:any = []
    await this.terminalApi.terminalList()
    .then(response => response.data)
    .then(async (data) => {
      console.log("DATA",data)
      /*data.map(async (terminal,index) => {
        const res2 = await this.terminalApi.terminalGet({
          brand:terminal.brand,
          serial:terminal.serial
        })
        if(res2.data.generalInformation.locationLongitude){
          objeto.push({
            brand: res2.data.brand,
            latitude: res2.data.generalInformation.locationLatitude ? parseFloat(res2.data.generalInformation.locationLatitude.value) : 51.51 ,          
            longitude: res2.data.generalInformation.locationLongitude ? parseFloat(res2.data.generalInformation.locationLongitude.value) : -0.09,
            model: res2.data.model,
            owner: res2.data.owner,
          })
        }

      })*/
      this.terminals = data;
      console.log(this.terminals)
    })
  }

  private configureMap(map: leaflet.Map) {
    leaflet
      .tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      })
      .addTo(map);

    this.addMarkersToMap(map);
  }

  private addMarkersToMap(map: leaflet.Map) {
    let posIcon = leaflet.icon({
      iconUrl: './location.png',
      iconSize: [38, 40],
      iconAnchor: [22, 94],
      popupAnchor: [-3, -76],
      shadowSize: [68, 95],
      shadowAnchor: [22, 94],
    });

    let markers = (leaflet as any).markerClusterGroup();

    /*this.terminals2 = [
      {
        brand: 'PAX',
        latitude: 51.505,
        longitude: -0.09,
        model: 'A920',
        owner: 'org-1',
      },
      {
        brand: 'PAX',
        latitude: 51.51,
        longitude: -0.094,
        model: 'A920',
        owner: 'org-1',
      },
      {
        brand: 'PAX',
        latitude: 51.5,
        longitude: -0.093,
        model: 'A920',
        owner: 'org-1',
      },
      {
        brand: 'PAX',
        latitude: 51.516,
        longitude: -0.087,
        model: 'A920',
        owner: 'org-1',
      },
      {
        brand: 'PAX',
        latitude: 51.514,
        longitude: -0.093,
        model: 'A920',
        owner: 'org-1',
      },
      {
        brand: 'PAX',
        latitude: 51.522,
        longitude: -0.0959,
        model: 'A920',
        owner: 'org-1',
      },
      {
        brand: 'PAX',
        latitude: 51.512,
        longitude: -0.0956,
        model: 'A920',
        owner: 'org-1',
      },
      {
        brand: 'PAX',
        latitude: 51.508,
        longitude: -0.0953,
        model: 'A920',
        owner: 'org-1',
      },
      {
        brand: 'PAX',
        latitude: 51.502,
        longitude: -0.0951,
        model: 'A920',
        owner: 'org-1',
      },
      {
        brand: 'PAX',
        latitude: 51.503,
        longitude: -0.085,
        model: 'A920',
        owner: 'org-1',
      },
    ];*/

    this.terminals2 = [{brand: 'Newland', latitude: 23.620000839233395, longitude: -102.5699996948242, model: 'NSDK', owner: 'daniprueba'}
    ,
    {brand: 'PAX', latitude: 10.4743763, longitude: -66.94748225, model: 'A920Pro', owner: 'Org-1'}
    , 
    {brand: 'Newland', latitude: 19.42709518, longitude: -99.16050815, model: 'NSDK', owner: 'Banorte'}
    , 
    {brand: 'Newland', latitude: 23.620000839233395, longitude: -102.5699996948242, model: 'NSDK', owner: 'daniel'}
    ,
    {brand: 'Newland', latitude: 23.620000839233395, longitude: -102.5699996948242, model: 'NSDK', owner: 'fernando'}
    ,
    {brand: 'Newland', latitude: 23.620000839233395, longitude: -102.5699996948242, model: 'NSDK', owner: 'LUIS '}
    ,
    {brand: 'Newland', latitude: 23.620000839233395, longitude: -102.5699996948242, model: 'NSDK', owner: 'TestSolopago'}
    ,
    {brand: 'PAX', latitude: 0, longitude: 0, model: 'A920Pro', owner: 'Apps2go'}
    ,
    {brand: 'PAX', latitude: 19.58866866, longitude: -99.05760321, model: 'A920Pro', owner: 'daniel'}
    ,
    {brand: 'Newland', latitude: 0, longitude: 0, model: 'NSDK', owner: 'Banorte'}
    ,
    {brand: 'Newland', latitude: 19.42731222, longitude: -99.16060796, model: 'NSDK', owner: 'Banorte'}
    ,
    {brand: 'Newland', latitude: 6.409999847412109, longitude: -66.5699996948242, model: 'NSDK', owner: 'Ana Avila'}
    ,
    {brand: 'Newland', latitude: 6.409999847412109, longitude: -66.5699996948242, model: 'NSDK', owner: 'Org-1'}
    ,
    {brand: 'PAX', latitude: 19.42716816645483, longitude: -99.16024744974042, model: 'A920Pro', owner: 'luispruebas'}
    ,
    {brand: 'Newland', latitude: 6.409999847412109, longitude: -66.5699996948242, model: 'NSDK', owner: 'apps2go'}
    ,
    {brand: 'PAX', latitude: 19.427372524918724, longitude: -99.16073187034159, model: 'A920Pro', owner: 'luispax'}
    ,
    {brand: 'PAX', latitude: 10.47436648, longitude: -66.94718272, model: 'A920Pro', owner: 'org-1'}
    ,
    {brand: 'Newland', latitude: 23.620000839233395, longitude: -102.5699996948242, model: 'NSDK', owner: 'Armando'}]

    console.log("1",this.terminals)
    console.log("2",this.terminals2)
 
    for (const terminal of this.terminals) {
      //console.log("LAT",terminal.location.lat ?? "01")
      //console.log("LOG",terminal.location.log ?? "01")
      if(terminal.hasOwnProperty("location")){
        const marker = leaflet.marker([terminal.hasOwnProperty("location") ? terminal.location.lat : "", terminal.hasOwnProperty("location") ? terminal.location.log : "" ], {
          icon: posIcon,
        });
        marker.bindPopup(
          `<b>${terminal.brand} - ${terminal.model}</b><br/><p>${terminal.owner}</p>`
        );
        markers.addLayer(marker);
      }

    }

    map.addLayer(markers);
  }

  render() {
    return html`<link
        rel="stylesheet"
        href="https://unpkg.com/leaflet@1.9.3/dist/leaflet.css"
        integrity="sha256-kLaT2GOSpHechhsozzB+flnD+zUyjE2LlfWPgU04xyI="
        crossorigin="anonymous"
      />
      <script
        src="https://unpkg.com/leaflet@1.9.3/dist/leaflet.js"
        integrity="sha256-WBkoXOwTeyKclOHuWtc+i2uENFpDZ9YPdf5Hf+D7ewM="
        crossorigin="anonymous"
      ></script>
      <div id="customMap" class="w-full h-[420px] rounded-b-xl"></div> `;
  }
}
