/* tslint:disable */
/* eslint-disable */
/**
 * TMS Agnostiko
 * Tiene la definición de todos los servicios de TMS a Agnostic (para uso en la web o externo)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { FolioInfo } from '../models';
// @ts-ignore
import { FolioListWithParams } from '../models';
// @ts-ignore
import { Message } from '../models';
// @ts-ignore
import { RequestDeleteFolioBatch } from '../models';
// @ts-ignore
import { RequestFolioBatch } from '../models';
// @ts-ignore
import { RequestFolioConfigurationUpdate } from '../models';
// @ts-ignore
import { RequestFolioCreate } from '../models';
// @ts-ignore
import { RequestFolioPatch } from '../models';
// @ts-ignore
import { RequestFolioUpdate } from '../models';
// @ts-ignore
import { RequestParamsQuery } from '../models';
// @ts-ignore
import { RequestUpdateFolioBatch } from '../models';
// @ts-ignore
import { ResponseCreateFolio } from '../models';
// @ts-ignore
import { ResponseFolioBatch } from '../models';
// @ts-ignore
import { ResponseFolioConfigurationGet } from '../models';
// @ts-ignore
import { ResponseFolioGet } from '../models';
// @ts-ignore
import { ResponseFolioInfo } from '../models';
// @ts-ignore
import { ResponseFolioInfoList } from '../models';
// @ts-ignore
import { ResponseUpdateFolioBatch } from '../models';
/**
 * FolioApi - axios parameter creator
 * @export
 */
export const FolioApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary actualiza el folio para que vuelva a estar disponible
         * @param {string} appId 
         * @param {string} folioId 
         * @param {RequestFolioPatch} requestFolioPatch 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cleanFolio: async (appId: string, folioId: string, requestFolioPatch: RequestFolioPatch, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('cleanFolio', 'appId', appId)
            // verify required parameter 'folioId' is not null or undefined
            assertParamExists('cleanFolio', 'folioId', folioId)
            // verify required parameter 'requestFolioPatch' is not null or undefined
            assertParamExists('cleanFolio', 'requestFolioPatch', requestFolioPatch)
            const localVarPath = `/folio/{appId}/{folioId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"folioId"}}`, encodeURIComponent(String(folioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestFolioPatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Realiza la creación de un folio asociado a una aplicación
         * @param {RequestFolioCreate} requestFolioCreate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFolio: async (requestFolioCreate: RequestFolioCreate, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestFolioCreate' is not null or undefined
            assertParamExists('createFolio', 'requestFolioCreate', requestFolioCreate)
            const localVarPath = `/folio`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestFolioCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary elimina un folio
         * @param {string} appId 
         * @param {string} folioId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFolio: async (appId: string, folioId: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('deleteFolio', 'appId', appId)
            // verify required parameter 'folioId' is not null or undefined
            assertParamExists('deleteFolio', 'folioId', folioId)
            const localVarPath = `/folio/{appId}/{folioId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"folioId"}}`, encodeURIComponent(String(folioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary elimina una configuración asociada a un folio
         * @param {string} appId 
         * @param {string} folioId 
         * @param {string} templateId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFolioConfiguration: async (appId: string, folioId: string, templateId: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('deleteFolioConfiguration', 'appId', appId)
            // verify required parameter 'folioId' is not null or undefined
            assertParamExists('deleteFolioConfiguration', 'folioId', folioId)
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('deleteFolioConfiguration', 'templateId', templateId)
            const localVarPath = `/folio/configuration/{appId}/{folioId}/{templateId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"folioId"}}`, encodeURIComponent(String(folioId)))
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Realiza la creación de múltiples folios a la vez
         * @param {RequestFolioBatch} requestFolioBatch 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folioBatch: async (requestFolioBatch: RequestFolioBatch, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestFolioBatch' is not null or undefined
            assertParamExists('folioBatch', 'requestFolioBatch', requestFolioBatch)
            const localVarPath = `/folio/batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestFolioBatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Eliminación masiva de folios
         * @param {RequestDeleteFolioBatch} requestDeleteFolioBatch 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folioDeleteBatch: async (requestDeleteFolioBatch: RequestDeleteFolioBatch, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestDeleteFolioBatch' is not null or undefined
            assertParamExists('folioDeleteBatch', 'requestDeleteFolioBatch', requestDeleteFolioBatch)
            const localVarPath = `/folio/deleteByBatch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestDeleteFolioBatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Realiza la actualizacion de múltiples folios a la vez
         * @param {RequestUpdateFolioBatch} requestUpdateFolioBatch 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folioUpdateBatch: async (requestUpdateFolioBatch: RequestUpdateFolioBatch, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestUpdateFolioBatch' is not null or undefined
            assertParamExists('folioUpdateBatch', 'requestUpdateFolioBatch', requestUpdateFolioBatch)
            const localVarPath = `/folio/updateByBatch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestUpdateFolioBatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Obtener la  información de un folio
         * @param {string} appId 
         * @param {string} folioId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolio: async (appId: string, folioId: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getFolio', 'appId', appId)
            // verify required parameter 'folioId' is not null or undefined
            assertParamExists('getFolio', 'folioId', folioId)
            const localVarPath = `/folio/{appId}/{folioId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"folioId"}}`, encodeURIComponent(String(folioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Obtener la configuración específica para un folio de una aplicación
         * @param {string} appId 
         * @param {string} folioId 
         * @param {string} templateId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolioConfiguration: async (appId: string, folioId: string, templateId: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getFolioConfiguration', 'appId', appId)
            // verify required parameter 'folioId' is not null or undefined
            assertParamExists('getFolioConfiguration', 'folioId', folioId)
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('getFolioConfiguration', 'templateId', templateId)
            const localVarPath = `/folio/configuration/{appId}/{folioId}/{templateId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"folioId"}}`, encodeURIComponent(String(folioId)))
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta sobre los parámetros
         * @param {string} appId 
         * @param {RequestParamsQuery} requestParamsQuery 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFoliosByQuery: async (appId: string, requestParamsQuery: RequestParamsQuery, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getFoliosByQuery', 'appId', appId)
            // verify required parameter 'requestParamsQuery' is not null or undefined
            assertParamExists('getFoliosByQuery', 'requestParamsQuery', requestParamsQuery)
            const localVarPath = `/folio/list/{appId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestParamsQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Obtener la  información de un folio
         * @param {string} appId 
         * @param {string} folioId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProsaFolio: async (appId: string, folioId: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getProsaFolio', 'appId', appId)
            // verify required parameter 'folioId' is not null or undefined
            assertParamExists('getProsaFolio', 'folioId', folioId)
            const localVarPath = `/folio_prosa/{appId}/{folioId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"folioId"}}`, encodeURIComponent(String(folioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Obtener la lista de configuraciones
         * @param {string} appId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {boolean} [used] 
         * @param {string} [brand] 
         * @param {string} [serial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFolio: async (appId: string, acceptLanguage?: string, used?: boolean, brand?: string, serial?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('listFolio', 'appId', appId)
            const localVarPath = `/folio/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (appId !== undefined) {
                localVarQueryParameter['appId'] = appId;
            }

            if (used !== undefined) {
                localVarQueryParameter['used'] = used;
            }

            if (brand !== undefined) {
                localVarQueryParameter['brand'] = brand;
            }

            if (serial !== undefined) {
                localVarQueryParameter['serial'] = serial;
            }

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Obtener la lista de folios
         * @param {string} appId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {string} [brand] 
         * @param {string} [serial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProsaFolio: async (appId: string, acceptLanguage?: string, brand?: string, serial?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('listProsaFolio', 'appId', appId)
            const localVarPath = `/folio_prosa/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (appId !== undefined) {
                localVarQueryParameter['appId'] = appId;
            }

            if (brand !== undefined) {
                localVarQueryParameter['brand'] = brand;
            }

            if (serial !== undefined) {
                localVarQueryParameter['serial'] = serial;
            }

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary actualiza la información general de un folio
         * @param {string} appId 
         * @param {string} folioId 
         * @param {RequestFolioUpdate} requestFolioUpdate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFolio: async (appId: string, folioId: string, requestFolioUpdate: RequestFolioUpdate, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('updateFolio', 'appId', appId)
            // verify required parameter 'folioId' is not null or undefined
            assertParamExists('updateFolio', 'folioId', folioId)
            // verify required parameter 'requestFolioUpdate' is not null or undefined
            assertParamExists('updateFolio', 'requestFolioUpdate', requestFolioUpdate)
            const localVarPath = `/folio/{appId}/{folioId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"folioId"}}`, encodeURIComponent(String(folioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestFolioUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary actualiza una configuración
         * @param {string} appId 
         * @param {string} folioId 
         * @param {string} templateId 
         * @param {RequestFolioConfigurationUpdate} requestFolioConfigurationUpdate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFolioConfiguration: async (appId: string, folioId: string, templateId: string, requestFolioConfigurationUpdate: RequestFolioConfigurationUpdate, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('updateFolioConfiguration', 'appId', appId)
            // verify required parameter 'folioId' is not null or undefined
            assertParamExists('updateFolioConfiguration', 'folioId', folioId)
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('updateFolioConfiguration', 'templateId', templateId)
            // verify required parameter 'requestFolioConfigurationUpdate' is not null or undefined
            assertParamExists('updateFolioConfiguration', 'requestFolioConfigurationUpdate', requestFolioConfigurationUpdate)
            const localVarPath = `/folio/configuration/{appId}/{folioId}/{templateId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"folioId"}}`, encodeURIComponent(String(folioId)))
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestFolioConfigurationUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FolioApi - functional programming interface
 * @export
 */
export const FolioApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FolioApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary actualiza el folio para que vuelva a estar disponible
         * @param {string} appId 
         * @param {string} folioId 
         * @param {RequestFolioPatch} requestFolioPatch 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cleanFolio(appId: string, folioId: string, requestFolioPatch: RequestFolioPatch, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cleanFolio(appId, folioId, requestFolioPatch, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FolioApi.cleanFolio']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Realiza la creación de un folio asociado a una aplicación
         * @param {RequestFolioCreate} requestFolioCreate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFolio(requestFolioCreate: RequestFolioCreate, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCreateFolio>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFolio(requestFolioCreate, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FolioApi.createFolio']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary elimina un folio
         * @param {string} appId 
         * @param {string} folioId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFolio(appId: string, folioId: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFolio(appId, folioId, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FolioApi.deleteFolio']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary elimina una configuración asociada a un folio
         * @param {string} appId 
         * @param {string} folioId 
         * @param {string} templateId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFolioConfiguration(appId: string, folioId: string, templateId: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFolioConfiguration(appId, folioId, templateId, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FolioApi.deleteFolioConfiguration']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Realiza la creación de múltiples folios a la vez
         * @param {RequestFolioBatch} requestFolioBatch 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async folioBatch(requestFolioBatch: RequestFolioBatch, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFolioBatch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.folioBatch(requestFolioBatch, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FolioApi.folioBatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Eliminación masiva de folios
         * @param {RequestDeleteFolioBatch} requestDeleteFolioBatch 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async folioDeleteBatch(requestDeleteFolioBatch: RequestDeleteFolioBatch, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUpdateFolioBatch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.folioDeleteBatch(requestDeleteFolioBatch, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FolioApi.folioDeleteBatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Realiza la actualizacion de múltiples folios a la vez
         * @param {RequestUpdateFolioBatch} requestUpdateFolioBatch 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async folioUpdateBatch(requestUpdateFolioBatch: RequestUpdateFolioBatch, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUpdateFolioBatch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.folioUpdateBatch(requestUpdateFolioBatch, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FolioApi.folioUpdateBatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Obtener la  información de un folio
         * @param {string} appId 
         * @param {string} folioId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFolio(appId: string, folioId: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFolioGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFolio(appId, folioId, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FolioApi.getFolio']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Obtener la configuración específica para un folio de una aplicación
         * @param {string} appId 
         * @param {string} folioId 
         * @param {string} templateId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFolioConfiguration(appId: string, folioId: string, templateId: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFolioConfigurationGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFolioConfiguration(appId, folioId, templateId, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FolioApi.getFolioConfiguration']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Consulta sobre los parámetros
         * @param {string} appId 
         * @param {RequestParamsQuery} requestParamsQuery 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFoliosByQuery(appId: string, requestParamsQuery: RequestParamsQuery, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FolioListWithParams>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFoliosByQuery(appId, requestParamsQuery, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FolioApi.getFoliosByQuery']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Obtener la  información de un folio
         * @param {string} appId 
         * @param {string} folioId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProsaFolio(appId: string, folioId: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFolioInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProsaFolio(appId, folioId, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FolioApi.getProsaFolio']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Obtener la lista de configuraciones
         * @param {string} appId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {boolean} [used] 
         * @param {string} [brand] 
         * @param {string} [serial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFolio(appId: string, acceptLanguage?: string, used?: boolean, brand?: string, serial?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FolioInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listFolio(appId, acceptLanguage, used, brand, serial, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FolioApi.listFolio']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Obtener la lista de folios
         * @param {string} appId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {string} [brand] 
         * @param {string} [serial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProsaFolio(appId: string, acceptLanguage?: string, brand?: string, serial?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResponseFolioInfoList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProsaFolio(appId, acceptLanguage, brand, serial, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FolioApi.listProsaFolio']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary actualiza la información general de un folio
         * @param {string} appId 
         * @param {string} folioId 
         * @param {RequestFolioUpdate} requestFolioUpdate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFolio(appId: string, folioId: string, requestFolioUpdate: RequestFolioUpdate, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFolio(appId, folioId, requestFolioUpdate, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FolioApi.updateFolio']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary actualiza una configuración
         * @param {string} appId 
         * @param {string} folioId 
         * @param {string} templateId 
         * @param {RequestFolioConfigurationUpdate} requestFolioConfigurationUpdate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFolioConfiguration(appId: string, folioId: string, templateId: string, requestFolioConfigurationUpdate: RequestFolioConfigurationUpdate, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFolioConfiguration(appId, folioId, templateId, requestFolioConfigurationUpdate, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FolioApi.updateFolioConfiguration']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * FolioApi - factory interface
 * @export
 */
export const FolioApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FolioApiFp(configuration)
    return {
        /**
         * 
         * @summary actualiza el folio para que vuelva a estar disponible
         * @param {FolioApiCleanFolioRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cleanFolio(requestParameters: FolioApiCleanFolioRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.cleanFolio(requestParameters.appId, requestParameters.folioId, requestParameters.requestFolioPatch, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Realiza la creación de un folio asociado a una aplicación
         * @param {FolioApiCreateFolioRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFolio(requestParameters: FolioApiCreateFolioRequest, options?: AxiosRequestConfig): AxiosPromise<ResponseCreateFolio> {
            return localVarFp.createFolio(requestParameters.requestFolioCreate, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary elimina un folio
         * @param {FolioApiDeleteFolioRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFolio(requestParameters: FolioApiDeleteFolioRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.deleteFolio(requestParameters.appId, requestParameters.folioId, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary elimina una configuración asociada a un folio
         * @param {FolioApiDeleteFolioConfigurationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFolioConfiguration(requestParameters: FolioApiDeleteFolioConfigurationRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.deleteFolioConfiguration(requestParameters.appId, requestParameters.folioId, requestParameters.templateId, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Realiza la creación de múltiples folios a la vez
         * @param {FolioApiFolioBatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folioBatch(requestParameters: FolioApiFolioBatchRequest, options?: AxiosRequestConfig): AxiosPromise<ResponseFolioBatch> {
            return localVarFp.folioBatch(requestParameters.requestFolioBatch, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Eliminación masiva de folios
         * @param {FolioApiFolioDeleteBatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folioDeleteBatch(requestParameters: FolioApiFolioDeleteBatchRequest, options?: AxiosRequestConfig): AxiosPromise<ResponseUpdateFolioBatch> {
            return localVarFp.folioDeleteBatch(requestParameters.requestDeleteFolioBatch, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Realiza la actualizacion de múltiples folios a la vez
         * @param {FolioApiFolioUpdateBatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folioUpdateBatch(requestParameters: FolioApiFolioUpdateBatchRequest, options?: AxiosRequestConfig): AxiosPromise<ResponseUpdateFolioBatch> {
            return localVarFp.folioUpdateBatch(requestParameters.requestUpdateFolioBatch, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Obtener la  información de un folio
         * @param {FolioApiGetFolioRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolio(requestParameters: FolioApiGetFolioRequest, options?: AxiosRequestConfig): AxiosPromise<ResponseFolioGet> {
            return localVarFp.getFolio(requestParameters.appId, requestParameters.folioId, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Obtener la configuración específica para un folio de una aplicación
         * @param {FolioApiGetFolioConfigurationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolioConfiguration(requestParameters: FolioApiGetFolioConfigurationRequest, options?: AxiosRequestConfig): AxiosPromise<ResponseFolioConfigurationGet> {
            return localVarFp.getFolioConfiguration(requestParameters.appId, requestParameters.folioId, requestParameters.templateId, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Consulta sobre los parámetros
         * @param {FolioApiGetFoliosByQueryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFoliosByQuery(requestParameters: FolioApiGetFoliosByQueryRequest, options?: AxiosRequestConfig): AxiosPromise<Array<FolioListWithParams>> {
            return localVarFp.getFoliosByQuery(requestParameters.appId, requestParameters.requestParamsQuery, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Obtener la  información de un folio
         * @param {FolioApiGetProsaFolioRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProsaFolio(requestParameters: FolioApiGetProsaFolioRequest, options?: AxiosRequestConfig): AxiosPromise<ResponseFolioInfo> {
            return localVarFp.getProsaFolio(requestParameters.appId, requestParameters.folioId, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Obtener la lista de configuraciones
         * @param {FolioApiListFolioRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFolio(requestParameters: FolioApiListFolioRequest, options?: AxiosRequestConfig): AxiosPromise<Array<FolioInfo>> {
            return localVarFp.listFolio(requestParameters.appId, requestParameters.acceptLanguage, requestParameters.used, requestParameters.brand, requestParameters.serial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Obtener la lista de folios
         * @param {FolioApiListProsaFolioRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProsaFolio(requestParameters: FolioApiListProsaFolioRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ResponseFolioInfoList>> {
            return localVarFp.listProsaFolio(requestParameters.appId, requestParameters.acceptLanguage, requestParameters.brand, requestParameters.serial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary actualiza la información general de un folio
         * @param {FolioApiUpdateFolioRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFolio(requestParameters: FolioApiUpdateFolioRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.updateFolio(requestParameters.appId, requestParameters.folioId, requestParameters.requestFolioUpdate, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary actualiza una configuración
         * @param {FolioApiUpdateFolioConfigurationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFolioConfiguration(requestParameters: FolioApiUpdateFolioConfigurationRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.updateFolioConfiguration(requestParameters.appId, requestParameters.folioId, requestParameters.templateId, requestParameters.requestFolioConfigurationUpdate, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for cleanFolio operation in FolioApi.
 * @export
 * @interface FolioApiCleanFolioRequest
 */
export interface FolioApiCleanFolioRequest {
    /**
     * 
     * @type {string}
     * @memberof FolioApiCleanFolio
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof FolioApiCleanFolio
     */
    readonly folioId: string

    /**
     * 
     * @type {RequestFolioPatch}
     * @memberof FolioApiCleanFolio
     */
    readonly requestFolioPatch: RequestFolioPatch

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof FolioApiCleanFolio
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for createFolio operation in FolioApi.
 * @export
 * @interface FolioApiCreateFolioRequest
 */
export interface FolioApiCreateFolioRequest {
    /**
     * 
     * @type {RequestFolioCreate}
     * @memberof FolioApiCreateFolio
     */
    readonly requestFolioCreate: RequestFolioCreate

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof FolioApiCreateFolio
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteFolio operation in FolioApi.
 * @export
 * @interface FolioApiDeleteFolioRequest
 */
export interface FolioApiDeleteFolioRequest {
    /**
     * 
     * @type {string}
     * @memberof FolioApiDeleteFolio
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof FolioApiDeleteFolio
     */
    readonly folioId: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof FolioApiDeleteFolio
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteFolioConfiguration operation in FolioApi.
 * @export
 * @interface FolioApiDeleteFolioConfigurationRequest
 */
export interface FolioApiDeleteFolioConfigurationRequest {
    /**
     * 
     * @type {string}
     * @memberof FolioApiDeleteFolioConfiguration
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof FolioApiDeleteFolioConfiguration
     */
    readonly folioId: string

    /**
     * 
     * @type {string}
     * @memberof FolioApiDeleteFolioConfiguration
     */
    readonly templateId: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof FolioApiDeleteFolioConfiguration
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for folioBatch operation in FolioApi.
 * @export
 * @interface FolioApiFolioBatchRequest
 */
export interface FolioApiFolioBatchRequest {
    /**
     * 
     * @type {RequestFolioBatch}
     * @memberof FolioApiFolioBatch
     */
    readonly requestFolioBatch: RequestFolioBatch

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof FolioApiFolioBatch
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for folioDeleteBatch operation in FolioApi.
 * @export
 * @interface FolioApiFolioDeleteBatchRequest
 */
export interface FolioApiFolioDeleteBatchRequest {
    /**
     * 
     * @type {RequestDeleteFolioBatch}
     * @memberof FolioApiFolioDeleteBatch
     */
    readonly requestDeleteFolioBatch: RequestDeleteFolioBatch

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof FolioApiFolioDeleteBatch
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for folioUpdateBatch operation in FolioApi.
 * @export
 * @interface FolioApiFolioUpdateBatchRequest
 */
export interface FolioApiFolioUpdateBatchRequest {
    /**
     * 
     * @type {RequestUpdateFolioBatch}
     * @memberof FolioApiFolioUpdateBatch
     */
    readonly requestUpdateFolioBatch: RequestUpdateFolioBatch

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof FolioApiFolioUpdateBatch
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for getFolio operation in FolioApi.
 * @export
 * @interface FolioApiGetFolioRequest
 */
export interface FolioApiGetFolioRequest {
    /**
     * 
     * @type {string}
     * @memberof FolioApiGetFolio
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof FolioApiGetFolio
     */
    readonly folioId: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof FolioApiGetFolio
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for getFolioConfiguration operation in FolioApi.
 * @export
 * @interface FolioApiGetFolioConfigurationRequest
 */
export interface FolioApiGetFolioConfigurationRequest {
    /**
     * 
     * @type {string}
     * @memberof FolioApiGetFolioConfiguration
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof FolioApiGetFolioConfiguration
     */
    readonly folioId: string

    /**
     * 
     * @type {string}
     * @memberof FolioApiGetFolioConfiguration
     */
    readonly templateId: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof FolioApiGetFolioConfiguration
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for getFoliosByQuery operation in FolioApi.
 * @export
 * @interface FolioApiGetFoliosByQueryRequest
 */
export interface FolioApiGetFoliosByQueryRequest {
    /**
     * 
     * @type {string}
     * @memberof FolioApiGetFoliosByQuery
     */
    readonly appId: string

    /**
     * 
     * @type {RequestParamsQuery}
     * @memberof FolioApiGetFoliosByQuery
     */
    readonly requestParamsQuery: RequestParamsQuery

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof FolioApiGetFoliosByQuery
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for getProsaFolio operation in FolioApi.
 * @export
 * @interface FolioApiGetProsaFolioRequest
 */
export interface FolioApiGetProsaFolioRequest {
    /**
     * 
     * @type {string}
     * @memberof FolioApiGetProsaFolio
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof FolioApiGetProsaFolio
     */
    readonly folioId: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof FolioApiGetProsaFolio
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for listFolio operation in FolioApi.
 * @export
 * @interface FolioApiListFolioRequest
 */
export interface FolioApiListFolioRequest {
    /**
     * 
     * @type {string}
     * @memberof FolioApiListFolio
     */
    readonly appId: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof FolioApiListFolio
     */
    readonly acceptLanguage?: string

    /**
     * 
     * @type {boolean}
     * @memberof FolioApiListFolio
     */
    readonly used?: boolean

    /**
     * 
     * @type {string}
     * @memberof FolioApiListFolio
     */
    readonly brand?: string

    /**
     * 
     * @type {string}
     * @memberof FolioApiListFolio
     */
    readonly serial?: string
}

/**
 * Request parameters for listProsaFolio operation in FolioApi.
 * @export
 * @interface FolioApiListProsaFolioRequest
 */
export interface FolioApiListProsaFolioRequest {
    /**
     * 
     * @type {string}
     * @memberof FolioApiListProsaFolio
     */
    readonly appId: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof FolioApiListProsaFolio
     */
    readonly acceptLanguage?: string

    /**
     * 
     * @type {string}
     * @memberof FolioApiListProsaFolio
     */
    readonly brand?: string

    /**
     * 
     * @type {string}
     * @memberof FolioApiListProsaFolio
     */
    readonly serial?: string
}

/**
 * Request parameters for updateFolio operation in FolioApi.
 * @export
 * @interface FolioApiUpdateFolioRequest
 */
export interface FolioApiUpdateFolioRequest {
    /**
     * 
     * @type {string}
     * @memberof FolioApiUpdateFolio
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof FolioApiUpdateFolio
     */
    readonly folioId: string

    /**
     * 
     * @type {RequestFolioUpdate}
     * @memberof FolioApiUpdateFolio
     */
    readonly requestFolioUpdate: RequestFolioUpdate

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof FolioApiUpdateFolio
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateFolioConfiguration operation in FolioApi.
 * @export
 * @interface FolioApiUpdateFolioConfigurationRequest
 */
export interface FolioApiUpdateFolioConfigurationRequest {
    /**
     * 
     * @type {string}
     * @memberof FolioApiUpdateFolioConfiguration
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof FolioApiUpdateFolioConfiguration
     */
    readonly folioId: string

    /**
     * 
     * @type {string}
     * @memberof FolioApiUpdateFolioConfiguration
     */
    readonly templateId: string

    /**
     * 
     * @type {RequestFolioConfigurationUpdate}
     * @memberof FolioApiUpdateFolioConfiguration
     */
    readonly requestFolioConfigurationUpdate: RequestFolioConfigurationUpdate

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof FolioApiUpdateFolioConfiguration
     */
    readonly acceptLanguage?: string
}

/**
 * FolioApi - object-oriented interface
 * @export
 * @class FolioApi
 * @extends {BaseAPI}
 */
export class FolioApi extends BaseAPI {
    /**
     * 
     * @summary actualiza el folio para que vuelva a estar disponible
     * @param {FolioApiCleanFolioRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolioApi
     */
    public cleanFolio(requestParameters: FolioApiCleanFolioRequest, options?: AxiosRequestConfig) {
        return FolioApiFp(this.configuration).cleanFolio(requestParameters.appId, requestParameters.folioId, requestParameters.requestFolioPatch, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Realiza la creación de un folio asociado a una aplicación
     * @param {FolioApiCreateFolioRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolioApi
     */
    public createFolio(requestParameters: FolioApiCreateFolioRequest, options?: AxiosRequestConfig) {
        return FolioApiFp(this.configuration).createFolio(requestParameters.requestFolioCreate, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary elimina un folio
     * @param {FolioApiDeleteFolioRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolioApi
     */
    public deleteFolio(requestParameters: FolioApiDeleteFolioRequest, options?: AxiosRequestConfig) {
        return FolioApiFp(this.configuration).deleteFolio(requestParameters.appId, requestParameters.folioId, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary elimina una configuración asociada a un folio
     * @param {FolioApiDeleteFolioConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolioApi
     */
    public deleteFolioConfiguration(requestParameters: FolioApiDeleteFolioConfigurationRequest, options?: AxiosRequestConfig) {
        return FolioApiFp(this.configuration).deleteFolioConfiguration(requestParameters.appId, requestParameters.folioId, requestParameters.templateId, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Realiza la creación de múltiples folios a la vez
     * @param {FolioApiFolioBatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolioApi
     */
    public folioBatch(requestParameters: FolioApiFolioBatchRequest, options?: AxiosRequestConfig) {
        return FolioApiFp(this.configuration).folioBatch(requestParameters.requestFolioBatch, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Eliminación masiva de folios
     * @param {FolioApiFolioDeleteBatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolioApi
     */
    public folioDeleteBatch(requestParameters: FolioApiFolioDeleteBatchRequest, options?: AxiosRequestConfig) {
        return FolioApiFp(this.configuration).folioDeleteBatch(requestParameters.requestDeleteFolioBatch, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Realiza la actualizacion de múltiples folios a la vez
     * @param {FolioApiFolioUpdateBatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolioApi
     */
    public folioUpdateBatch(requestParameters: FolioApiFolioUpdateBatchRequest, options?: AxiosRequestConfig) {
        return FolioApiFp(this.configuration).folioUpdateBatch(requestParameters.requestUpdateFolioBatch, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Obtener la  información de un folio
     * @param {FolioApiGetFolioRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolioApi
     */
    public getFolio(requestParameters: FolioApiGetFolioRequest, options?: AxiosRequestConfig) {
        return FolioApiFp(this.configuration).getFolio(requestParameters.appId, requestParameters.folioId, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Obtener la configuración específica para un folio de una aplicación
     * @param {FolioApiGetFolioConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolioApi
     */
    public getFolioConfiguration(requestParameters: FolioApiGetFolioConfigurationRequest, options?: AxiosRequestConfig) {
        return FolioApiFp(this.configuration).getFolioConfiguration(requestParameters.appId, requestParameters.folioId, requestParameters.templateId, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Consulta sobre los parámetros
     * @param {FolioApiGetFoliosByQueryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolioApi
     */
    public getFoliosByQuery(requestParameters: FolioApiGetFoliosByQueryRequest, options?: AxiosRequestConfig) {
        return FolioApiFp(this.configuration).getFoliosByQuery(requestParameters.appId, requestParameters.requestParamsQuery, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Obtener la  información de un folio
     * @param {FolioApiGetProsaFolioRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolioApi
     */
    public getProsaFolio(requestParameters: FolioApiGetProsaFolioRequest, options?: AxiosRequestConfig) {
        return FolioApiFp(this.configuration).getProsaFolio(requestParameters.appId, requestParameters.folioId, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Obtener la lista de configuraciones
     * @param {FolioApiListFolioRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolioApi
     */
    public listFolio(requestParameters: FolioApiListFolioRequest, options?: AxiosRequestConfig) {
        return FolioApiFp(this.configuration).listFolio(requestParameters.appId, requestParameters.acceptLanguage, requestParameters.used, requestParameters.brand, requestParameters.serial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Obtener la lista de folios
     * @param {FolioApiListProsaFolioRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolioApi
     */
    public listProsaFolio(requestParameters: FolioApiListProsaFolioRequest, options?: AxiosRequestConfig) {
        return FolioApiFp(this.configuration).listProsaFolio(requestParameters.appId, requestParameters.acceptLanguage, requestParameters.brand, requestParameters.serial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary actualiza la información general de un folio
     * @param {FolioApiUpdateFolioRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolioApi
     */
    public updateFolio(requestParameters: FolioApiUpdateFolioRequest, options?: AxiosRequestConfig) {
        return FolioApiFp(this.configuration).updateFolio(requestParameters.appId, requestParameters.folioId, requestParameters.requestFolioUpdate, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary actualiza una configuración
     * @param {FolioApiUpdateFolioConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolioApi
     */
    public updateFolioConfiguration(requestParameters: FolioApiUpdateFolioConfigurationRequest, options?: AxiosRequestConfig) {
        return FolioApiFp(this.configuration).updateFolioConfiguration(requestParameters.appId, requestParameters.folioId, requestParameters.templateId, requestParameters.requestFolioConfigurationUpdate, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}

