/* eslint-disable prettier/prettier */
import { html, LitElement, render } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { connect } from 'pwa-helpers';
import '@material/mwc-tab';
import '@material/mwc-tab-bar';
import '@material/mwc-list/mwc-list.js';
import '@material/mwc-list/mwc-list-item.js';
import '@material/mwc-switch';
import '@material/mwc-textarea';
import '@material/mwc-button';
import '@material/mwc-select';
import store from '../../redux/store';
import Swal from 'sweetalert2';
import { State } from '../../redux/types';
import { Carousel } from './Carousel';
import './Carousel';

import { SwiperCarousel } from '../../components/SwiperCarousel/SwiperCarousel';
import '../../components/SwiperCarousel/SwiperCarousel';
import {
  agregarPlantilla,
  nombrePlantilla,
  descripcionPlantilla,
  idPlantilla,
  TypeJsonSchema,
} from '../../redux/actions';
import { iterarCampoJson2,existenciaConfiguracionTemplate } from '../../utils/functions';
import router from '../../router/router';
import { AxiosResponse } from 'axios';
import { errorNotification } from '../../services/notifications';
import {
  App,
  AppApi,
  AppApiUpdateAppRequest,
  AppApiAddPicturesRequest,
  RequestAppUpdate,
  TemplateApi,
  TemplateInfo,
  ResponseTemplateGet,
  ConfigurationApi
} from '../../../api';
import { apisConfigurator } from '../../utils/axios';
import { ListaPlantilla } from '../../components/modals/ListaPlantilla';
import '../../components/modals/ListaPlantilla';
import './EditApp.css';

@customElement('edit-app-page')
export class EditAppPage extends connect(store)(LitElement) {
  private templateApi: TemplateApi;
  private appApi: AppApi;
  private configurationApi: ConfigurationApi;

  @state()
  private app?: App;

  @state()
  loading = false;

  @state()
  private typeFolio:any = [] ;

  @state()
  obj = [];

  @state()
  loader = true;

  @state()
  plantillas: Object[] = [];

  @state()
  appTemplates: string[] = [];

  @state()
  private folioTypeId:any = "" ;

  @state()
  templatesInfo: { name: string; description: string; id: string }[] = [];

  @state()
  private file?: any;

  get cambiarPlantilla() {
    return document.getElementById('enviarMensajeModal') as ListaPlantilla;
  }

  private get cambiarImagen() {
    return document.querySelector('#seleccionar-imagen')! as HTMLInputElement;
  }

  private get divImagen() {
    return document.querySelector('#divImagen')! as HTMLInputElement;
  }

  get name() {
    return document.getElementById('name') as HTMLInputElement;
  }

  get package() {
    return document.getElementById('packageName') as HTMLInputElement;
  }

  get BriefDescription() {
    return document.getElementById('BriefDescription') as HTMLInputElement;
  }

  get description() {
    return document.getElementById('description') as HTMLInputElement;
  }

  constructor() {
    super();
    this.appApi = new AppApi(...apisConfigurator('Editar App'));
    this.templateApi = new TemplateApi(...apisConfigurator(''));
    this.configurationApi = new ConfigurationApi(...apisConfigurator(''));
  }

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  stateChanged(state: any) {
    /*if(state.list_plantilla) {
      let app = state.list_plantilla[0];
      this.appTemplates = app.templates ?? [];
      this.appTemplates.forEach(async templateId => {
        const templateInfo = await this.getTemplate(templateId);
        console.log(templateInfo);
        if (templateInfo)
          this.templatesInfo = [
            ...this.templatesInfo,
            { ...templateInfo, id: templateId },
          ];
      });
      console.log("WLADIMIR")
    }*/

  } 

  protected firstUpdated(): void {
    this.initConfig();
    this.typeFolio = [
      {
        name:"Random"
      },
      {
        name:"Incremental"
      },
      {
        name:"External"
      }
    ]
  }

  refresh() {
    const { appEdit } = store.getState() as State;

    if (appEdit === undefined) {
      errorNotification('Error al editar App');
      router.cambiarSeccion('apps-list');
      return;
    }

    this.app = appEdit;
    this.appTemplates = this.app.templates ?? [];

    console.log('appTemplates', this.appTemplates);

    this.appTemplates.forEach(async templateId => {
      const templateInfo = await this.getTemplate(templateId);
      console.log(templateInfo);
      if (templateInfo)
        this.templatesInfo = [
          ...this.templatesInfo,
          { ...templateInfo, id: templateId },
        ];
    });
  }

 
  async initConfig() {
    await this.getTemplates();
    await this.getApp();

    const { appEdit } = store.getState() as State;

    if (appEdit === undefined) {
      console.log(appEdit)
      errorNotification('Error al editar App');
      router.cambiarSeccion('apps-list');
      return;
    }
    console.log("APP",appEdit)
    this.app = appEdit;
    this.appTemplates = this.app.templates ?? [];

    console.log('appTemplates', this.appTemplates);

    this.appTemplates.forEach(async templateId => {
      const templateInfo = await this.getTemplate(templateId);
      console.log(templateInfo);
      if (templateInfo)
        this.templatesInfo = [
          ...this.templatesInfo,
          { ...templateInfo, id: templateId },
        ];
    });
  }

  async getTemplates() {
    this.loader = true;
    var resTer: AxiosResponse;
    try {
      resTer = await this.templateApi.listTemplate({});
      console.log(resTer);
      this.plantillas = resTer.data;
    } catch (error) {
    } finally {
      if (!this.plantillas)
        this.plantillas = [
          {
            name: 'plantilla-1',
            id: '12',
            description: 'prueba1',
          },
        ];
      //console.log(this.terminals);
      this.loader = false;
    }
  }

  async getTemplate(id: any): Promise<ResponseTemplateGet | null> {
    this.loader = true;
    try {
      const resTer = await this.templateApi.getTemplate({ id });

      let res = iterarCampoJson2(
        resTer.data.schema.properties,
        resTer.data.schema.required
      );
      console.log("TEMPLATES",resTer.data);
      store.dispatch(agregarPlantilla(res));
      store.dispatch(nombrePlantilla(resTer.data.name));
      store.dispatch(idPlantilla(id));
      store.dispatch(TypeJsonSchema(resTer.data.schema.type));
      store.dispatch(descripcionPlantilla(resTer.data.description));

      this.loader = false;
      return resTer.data;
    } catch (error) {
      return null;
    }
  }

  changeData(name: string): void {
    if (name == 'texarea') {
      console.log('');
    } else if (name == 'descripcion') {
      console.log(this.BriefDescription.value);
      console.log(this.description.value);
    } else if (name == 'nombre') {
      console.log(this.name.value);
      console.log(this.description.value);
    }
  }

  buildApp(): RequestAppUpdate {
    return {
      name: this.name.value,
      packageName: this.package.value,
      description: this.description.value,
      briefDescription: this.BriefDescription.value,
      developer: this.app?.developer,
      emailDeveloper: this.app?.emailDeveloper,
      folioTypeId:this.folioTypeId
    };
  }

  async getApp() {
    const appId = (store.getState() as State).verAppId;

    if (!appId) {
      router.cambiarSeccion('apps-list');
      errorNotification('Error al obtener la app');
      return;
    }

    try {
      const res = await this.appApi.getApp({ id: appId });
      this.app = res.data;
      console.log("app",this.app);
      this.folioTypeId = this.app?.folioTypeId
    } catch (err) {
      errorNotification('Error al obtener la app');
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  async onSubmit() {
    const { verAppId } = store.getState() as State;
    if (!verAppId) {
      errorNotification('Error al editar App');
      router.cambiarSeccion('apps-list');
      return;
    }
    this.file = this.cambiarImagen!.files![0];

    const data: AppApiUpdateAppRequest = {
      id: verAppId,
      requestAppUpdate: this.buildApp(),
    };
    /*const dataImagen: AppApiAddPicturesRequest ={
      id: verAppId,
      _function: 'brief',
      picture: this.file
    }*/
    console.log(data)
    try {
      const res = await this.appApi.updateApp(data);
      //const resImagen = await this.appApi.addPictures(dataImagen);

      console.log(res);
      //console.log(resImagen);
      router.cambiarSeccion('apps-list');
      window.location.reload();
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  }

  async comprobarConfigurationsEditar(id:string) {

    try{
      const res = await this.configurationApi.listConfiguration();
      console.log("RES",res)
      const result = existenciaConfiguracionTemplate(
        res.data,
        id
      );
      console.log("RESULT",result)
      if(result) {
        Swal.fire(
          'No Permitido',
          'Esta plantilla contiene configuracion guardada',
          'question'
        )
      }else{
        await this.getTemplate(id);
        router.cambiarSeccion('edit-template');
      }

      
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return html`
      <main class="EditApp w-full h-full bg-[#F5F2FC] p-5">
        <div
          class="w-full bg-white rounded-xl py-5 px-10 max-h-full h-full divide-y-2"
        >
          <section class="w-full flex">
            <div class="w-2/12">
              <div class="imgApp mx-auto" id="divImagen">
                <!-- <img class="h-full " src="" alt="Mi titulo de la imagen"> -->
                <input
                  id="seleccionar-imagen"
                  type="file"
                  @change=${(e: Event) => {
        const target = e.target as HTMLInputElement;
        const file: File = (target.files as FileList)[0];
        let url = URL.createObjectURL(file);
        const imagen = this.divImagen;
        imagen.style.backgroundImage = `url(${url})`;
      }}
                  accept="image/*"
                  style="opacity: 0.0; border: 1px solid red; width: 100%; height:100%;"
                />
              </div>
              <!-- <input type="file" accept="image/*"  name={"image"} className="custom-input file" /> -->
            </div>
            <div class="container ml-4 w-6/12 flex flex-col">
              <div class="mt-4">
                <input
                  type="text"
                  id="name"
                  value=${this.app?.name ?? ''}
                  class="w-full h-12 border border-[#d3d3e5] rounded-lg"
                  placeholder="Nombre"
                  name="name"
                  @keyup="${(ev: InputEvent) => {
        this.changeData('nombre');
      }}"
                />
              </div>
              <div class="mt-4">
                <input
                  type="text"
                  id="BriefDescription"
                  value=${this.app?.briefDescription ?? ''}
                  class="w-full h-12 border border-[#d3d3e5] rounded-lg"
                  placeholder="Descripcion"
                  name="fname"
                  @keyup="${(ev: InputEvent) => {
        this.changeData('descripcion'); 
      }}"
                />
              </div>
              <div class="mt-4">
                <input
                  type="text"
                  id="packageName"
                  value=${this.app?.packageName ?? ''}
                  class="w-full h-12 border border-[#d3d3e5] rounded-lg"
                  placeholder="Nombre paquete"
                  name="fname"
                  @keyup="${(ev: InputEvent) => {
        this.changeData('package');
      }}"
                />
              </div>
              <div class="mt-4">
                <custom-select
                    label="Tipo Folio"
                    class="w-full h-12 border border-[#d3d3e5] rounded-lg"
                    id="brand"
                    .options=${this.typeFolio.map((m:any) => ({
                      label: m.name,
                      value: m.name,
                    }))}
                    @change=${(ev: CustomEvent) => {
                      //this.brandSelected = ev.detail;
                      //this.bandBatch= false;
                      this.folioTypeId = ev.detail;
                    }}
                ></custom-select>
              </div>
            </div>
            <div class="flex mt-4 mr-5 w-4/12 pr-10">
              <button
                class="rounded-3xl mx-auto border w-28  border-indigo-500 text-blue-500  h-10  hover:shadow-md hover:bg-slate-300 text-xs	"
                @click=${() => {
        router.cambiarSeccion('apps-list');
      }}
              >
                Cancelar
              </button>
              <button
                class="rounded-3xl w-28 mx-auto h-10 InstalarApp"
                @click=${() => {
        this.onSubmit();
      }}
              >
                Guardar
              </button>
            </div>
          </section>
          <section
            class="w-full flex flex-col mt-5 max-h-5/6 "
            style="max-height: 82%;border:none;overflow: auto;margin-bottom:30px;"
          >
            <div class="w-full p-5">
              <div class="mt-3 flex flex-col">
                <!-- <div class="flex justify-between items-center">
                  <div ><span class="material-symbols-outlined">arrow_back_ios</span></div>
                  <div class="w-5/12">
                    <div class="imgApp h-60 w-full mx-auto"></div>
                  </div>
                  <div class="w-5/12">
                    <div class="imgApp h-60 w-full mx-auto"></div>
                  </div>
                  <div><span class="material-symbols-outlined">arrow_forward_ios</span></div>
                </div> -->
                <!-- <carousel-1
                ></carousel-1> -->
                <!-- <div
                  class="my-3 h-80 w-full max-w-full overflow-hidden relative"
                >
                  <swiper-carousel></swiper-carousel>
                </div> -->
                <!-- <swiper-carousel></swiper-carousel> -->
              </div>
            </div>
            <div class="w-full p-5">
              <div class="mt-0 flex flex-col">
                <div class="flex flex-col justify-center items-star">
                  <p class="my-5">
                    <label for="w3review" class="text-xl font-semibold"
                      >Descripcion general</label
                    >
                  </p>
                  <textarea
                    id="description"
                    class="border border-[#d3d3e5] rounded-lg"
                    name="description"
                    rows="8"
                    cols="40"
                  >
${this.app?.description ?? ''}</textarea
                  >
                </div>
              </div>
            </div>
            <div class="w-full p-5">
              <div class="mt-3 flex flex-col" style="margin-bottom:60px;">
                <div class="flex justify-between item-center">
                  <p class="my-5">
                    <label for="w3review" class="text-xl font-semibold"
                      >Configuracion de Plantilla</label
                    >
                  </p>
                  <button
                    class="rounded-3xl mx-2 border w-40  border-indigo-500 text-blue-500  h-10  hover:shadow-md hover:bg-slate-300 text-xs	"
                    @click=${() => {
        this.cambiarPlantilla.open(
          this.plantillas,
          this.templatesInfo
        );

      }}
                  >
                    Cambiar plantilla
                  </button>
                </div>

                ${this.templatesInfo.length == 0 ? html`
                <div 
                      class="h-24 flex justify-around items-center border border-[#d3d3e5] rounded-lg"
                      style="margin-bottom:40px;"
                    >
                      <p><b>sin plantilla</b></p>
                      <p><b>sin plantilla</b></p>
                      <p><b>sin plantilla</b></p>
                    </div>
                `: html`
                `}
 
                ${this.templatesInfo.map(template => {
        console.log(template);
        return html`
                    <div 
                      class="h-24 flex justify-around items-center border border-[#d3d3e5] rounded-lg"
                      style="margin-bottom:40px;"
                    >
                      <p>${template?.name ? template?.name : 'sin plantilla'}</p>
                      <p>${template?.description ? template?.description : 'sin plantilla'}</p>
                      <button
                        class="rounded-3xl mr-2 border w-20  border-indigo-500 text-blue-500  CancelarG  hover:shadow-md hover:bg-slate-300 text-xs	"
                        @click=${async () => {
                          console.log(template);
                          this.comprobarConfigurationsEditar(template.id)
                        }}
                      >
                        Editar
                      </button>
                    </div>
                  `;
      })}   
              </div>
            </div>
          </section>
        </div>
      </main>
      <lista-plantilla-modal id="enviarMensajeModal"></lista-plantilla-modal>
    `;
  }
}
