/* tslint:disable */
/* eslint-disable */
/**
 * TMS Agnostiko
 * Tiene la definición de todos los servicios de TMS a Agnostic (para uso en la web o externo)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { Message } from '../models';
// @ts-ignore
import { RequestVersionCreate } from '../models';
// @ts-ignore
import { RequestVersionUpdate } from '../models';
// @ts-ignore
import { ResponseAddInstaller } from '../models';
// @ts-ignore
import { Version } from '../models';
/**
 * VersionApi - axios parameter creator
 * @export
 */
export const VersionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary añade un instalador a un version
         * @param {string} id 
         * @param {string} version 
         * @param {string} brand 
         * @param {Array<string>} models 
         * @param {string} os 
         * @param {Array<string>} firmwares 
         * @param {File} installer 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInstaller: async (id: string, version: string, brand: string, models: Array<string>, os: string, firmwares: Array<string>, installer: File, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addInstaller', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('addInstaller', 'version', version)
            // verify required parameter 'brand' is not null or undefined
            assertParamExists('addInstaller', 'brand', brand)
            // verify required parameter 'models' is not null or undefined
            assertParamExists('addInstaller', 'models', models)
            // verify required parameter 'os' is not null or undefined
            assertParamExists('addInstaller', 'os', os)
            // verify required parameter 'firmwares' is not null or undefined
            assertParamExists('addInstaller', 'firmwares', firmwares)
            // verify required parameter 'installer' is not null or undefined
            assertParamExists('addInstaller', 'installer', installer)
            const localVarPath = `/app/{id}/version/{version}/installer`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }

            if (brand != null) {
                localVarHeaderParameter['brand'] = String(brand);
            }

            if (models) {
                let mapped = models.map(value => (<any>"Array<string>" !== "Array<string>") ? JSON.stringify(value) : (value || ""));
                localVarHeaderParameter['models'] = mapped.join(COLLECTION_FORMATS["csv"]);
            }

            if (os != null) {
                localVarHeaderParameter['os'] = String(os);
            }

            if (firmwares) {
                let mapped = firmwares.map(value => (<any>"Array<string>" !== "Array<string>") ? JSON.stringify(value) : (value || ""));
                localVarHeaderParameter['firmwares'] = mapped.join(COLLECTION_FORMATS["csv"]);
            }


            if (installer !== undefined) { 
                localVarFormParams.append('installer', installer as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Realiza la creación de una version de una aplicación
         * @param {string} id 
         * @param {RequestVersionCreate} requestVersionCreate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVersion: async (id: string, requestVersionCreate: RequestVersionCreate, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createVersion', 'id', id)
            // verify required parameter 'requestVersionCreate' is not null or undefined
            assertParamExists('createVersion', 'requestVersionCreate', requestVersionCreate)
            const localVarPath = `/app/{id}/version`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestVersionCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary elimina un instalador a un version
         * @param {string} id 
         * @param {string} version 
         * @param {string} installerID 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInstaller: async (id: string, version: string, installerID: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteInstaller', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('deleteInstaller', 'version', version)
            // verify required parameter 'installerID' is not null or undefined
            assertParamExists('deleteInstaller', 'installerID', installerID)
            const localVarPath = `/app/{id}/version/{version}/installer/{installerID}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)))
                .replace(`{${"installerID"}}`, encodeURIComponent(String(installerID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary obtiene un version
         * @param {string} id 
         * @param {string} version 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion: async (id: string, version: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getVersion', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('getVersion', 'version', version)
            const localVarPath = `/app/list/{id}/version/{version}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary actualiza un version
         * @param {string} id 
         * @param {string} version 
         * @param {RequestVersionUpdate} requestVersionUpdate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVersion: async (id: string, version: string, requestVersionUpdate: RequestVersionUpdate, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateVersion', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('updateVersion', 'version', version)
            // verify required parameter 'requestVersionUpdate' is not null or undefined
            assertParamExists('updateVersion', 'requestVersionUpdate', requestVersionUpdate)
            const localVarPath = `/app/{id}/version/{version}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestVersionUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VersionApi - functional programming interface
 * @export
 */
export const VersionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VersionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary añade un instalador a un version
         * @param {string} id 
         * @param {string} version 
         * @param {string} brand 
         * @param {Array<string>} models 
         * @param {string} os 
         * @param {Array<string>} firmwares 
         * @param {File} installer 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addInstaller(id: string, version: string, brand: string, models: Array<string>, os: string, firmwares: Array<string>, installer: File, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseAddInstaller>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addInstaller(id, version, brand, models, os, firmwares, installer, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VersionApi.addInstaller']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Realiza la creación de una version de una aplicación
         * @param {string} id 
         * @param {RequestVersionCreate} requestVersionCreate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVersion(id: string, requestVersionCreate: RequestVersionCreate, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVersion(id, requestVersionCreate, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VersionApi.createVersion']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary elimina un instalador a un version
         * @param {string} id 
         * @param {string} version 
         * @param {string} installerID 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInstaller(id: string, version: string, installerID: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInstaller(id, version, installerID, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VersionApi.deleteInstaller']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary obtiene un version
         * @param {string} id 
         * @param {string} version 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVersion(id: string, version: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Version>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVersion(id, version, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VersionApi.getVersion']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary actualiza un version
         * @param {string} id 
         * @param {string} version 
         * @param {RequestVersionUpdate} requestVersionUpdate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVersion(id: string, version: string, requestVersionUpdate: RequestVersionUpdate, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVersion(id, version, requestVersionUpdate, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VersionApi.updateVersion']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * VersionApi - factory interface
 * @export
 */
export const VersionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VersionApiFp(configuration)
    return {
        /**
         * 
         * @summary añade un instalador a un version
         * @param {VersionApiAddInstallerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInstaller(requestParameters: VersionApiAddInstallerRequest, options?: AxiosRequestConfig): AxiosPromise<ResponseAddInstaller> {
            return localVarFp.addInstaller(requestParameters.id, requestParameters.version, requestParameters.brand, requestParameters.models, requestParameters.os, requestParameters.firmwares, requestParameters.installer, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Realiza la creación de una version de una aplicación
         * @param {VersionApiCreateVersionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVersion(requestParameters: VersionApiCreateVersionRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.createVersion(requestParameters.id, requestParameters.requestVersionCreate, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary elimina un instalador a un version
         * @param {VersionApiDeleteInstallerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInstaller(requestParameters: VersionApiDeleteInstallerRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.deleteInstaller(requestParameters.id, requestParameters.version, requestParameters.installerID, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary obtiene un version
         * @param {VersionApiGetVersionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion(requestParameters: VersionApiGetVersionRequest, options?: AxiosRequestConfig): AxiosPromise<Version> {
            return localVarFp.getVersion(requestParameters.id, requestParameters.version, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary actualiza un version
         * @param {VersionApiUpdateVersionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVersion(requestParameters: VersionApiUpdateVersionRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.updateVersion(requestParameters.id, requestParameters.version, requestParameters.requestVersionUpdate, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addInstaller operation in VersionApi.
 * @export
 * @interface VersionApiAddInstallerRequest
 */
export interface VersionApiAddInstallerRequest {
    /**
     * 
     * @type {string}
     * @memberof VersionApiAddInstaller
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof VersionApiAddInstaller
     */
    readonly version: string

    /**
     * 
     * @type {string}
     * @memberof VersionApiAddInstaller
     */
    readonly brand: string

    /**
     * 
     * @type {Array<string>}
     * @memberof VersionApiAddInstaller
     */
    readonly models: Array<string>

    /**
     * 
     * @type {string}
     * @memberof VersionApiAddInstaller
     */
    readonly os: string

    /**
     * 
     * @type {Array<string>}
     * @memberof VersionApiAddInstaller
     */
    readonly firmwares: Array<string>

    /**
     * 
     * @type {File}
     * @memberof VersionApiAddInstaller
     */
    readonly installer: File

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof VersionApiAddInstaller
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for createVersion operation in VersionApi.
 * @export
 * @interface VersionApiCreateVersionRequest
 */
export interface VersionApiCreateVersionRequest {
    /**
     * 
     * @type {string}
     * @memberof VersionApiCreateVersion
     */
    readonly id: string

    /**
     * 
     * @type {RequestVersionCreate}
     * @memberof VersionApiCreateVersion
     */
    readonly requestVersionCreate: RequestVersionCreate

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof VersionApiCreateVersion
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteInstaller operation in VersionApi.
 * @export
 * @interface VersionApiDeleteInstallerRequest
 */
export interface VersionApiDeleteInstallerRequest {
    /**
     * 
     * @type {string}
     * @memberof VersionApiDeleteInstaller
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof VersionApiDeleteInstaller
     */
    readonly version: string

    /**
     * 
     * @type {string}
     * @memberof VersionApiDeleteInstaller
     */
    readonly installerID: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof VersionApiDeleteInstaller
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for getVersion operation in VersionApi.
 * @export
 * @interface VersionApiGetVersionRequest
 */
export interface VersionApiGetVersionRequest {
    /**
     * 
     * @type {string}
     * @memberof VersionApiGetVersion
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof VersionApiGetVersion
     */
    readonly version: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof VersionApiGetVersion
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateVersion operation in VersionApi.
 * @export
 * @interface VersionApiUpdateVersionRequest
 */
export interface VersionApiUpdateVersionRequest {
    /**
     * 
     * @type {string}
     * @memberof VersionApiUpdateVersion
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof VersionApiUpdateVersion
     */
    readonly version: string

    /**
     * 
     * @type {RequestVersionUpdate}
     * @memberof VersionApiUpdateVersion
     */
    readonly requestVersionUpdate: RequestVersionUpdate

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof VersionApiUpdateVersion
     */
    readonly acceptLanguage?: string
}

/**
 * VersionApi - object-oriented interface
 * @export
 * @class VersionApi
 * @extends {BaseAPI}
 */
export class VersionApi extends BaseAPI {
    /**
     * 
     * @summary añade un instalador a un version
     * @param {VersionApiAddInstallerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionApi
     */
    public addInstaller(requestParameters: VersionApiAddInstallerRequest, options?: AxiosRequestConfig) {
        return VersionApiFp(this.configuration).addInstaller(requestParameters.id, requestParameters.version, requestParameters.brand, requestParameters.models, requestParameters.os, requestParameters.firmwares, requestParameters.installer, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Realiza la creación de una version de una aplicación
     * @param {VersionApiCreateVersionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionApi
     */
    public createVersion(requestParameters: VersionApiCreateVersionRequest, options?: AxiosRequestConfig) {
        return VersionApiFp(this.configuration).createVersion(requestParameters.id, requestParameters.requestVersionCreate, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary elimina un instalador a un version
     * @param {VersionApiDeleteInstallerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionApi
     */
    public deleteInstaller(requestParameters: VersionApiDeleteInstallerRequest, options?: AxiosRequestConfig) {
        return VersionApiFp(this.configuration).deleteInstaller(requestParameters.id, requestParameters.version, requestParameters.installerID, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary obtiene un version
     * @param {VersionApiGetVersionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionApi
     */
    public getVersion(requestParameters: VersionApiGetVersionRequest, options?: AxiosRequestConfig) {
        return VersionApiFp(this.configuration).getVersion(requestParameters.id, requestParameters.version, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary actualiza un version
     * @param {VersionApiUpdateVersionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionApi
     */
    public updateVersion(requestParameters: VersionApiUpdateVersionRequest, options?: AxiosRequestConfig) {
        return VersionApiFp(this.configuration).updateVersion(requestParameters.id, requestParameters.version, requestParameters.requestVersionUpdate, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}

