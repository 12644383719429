/* eslint-disable prettier/prettier */
import { LitElement, html, css } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
// import './styles.css';

@customElement('select-tag')
export class SelectTag extends LitElement {
  /* createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  } */

  @property({ type: Array })
  options: { label: string; value: any }[] = [];

  @property({ type: Object })
  selected?: any;

  @property({ type: String })
  label = '';

  private select!: HTMLSelectElement;

  @state()
  show = false;

  protected firstUpdated(): void {
    //this.onSelected(this.label)
    //console.log(this.label)

    window.addEventListener('click', e => {
      if (
        /* this.shadowRoot!.getElementById(`app-cover`)?.contains(e.target as any) ||  */ this.contains(
          e.target as any
        ) ||
        this === e.target
      ) {
        console.log('clicked inside');
      } else {
        (
          this.shadowRoot!.getElementById(
            `options-view-button-${this.label}`
          )! as any
        ).checked = false;
      }
    });
  }

  dropDown() {
    this.show = !this.show;
    this.onSelected(this.label,"");
    //console.log(this.select)
  }

  onSelected(value: any,label: any) {
    //console.log(value);
    this.selected = value;
    const w = {value, id:label}

    const ev = new CustomEvent('change', { detail: w });
    this.dispatchEvent(ev);
  }

  render() {
    return html`
      <div id="app-cover">
        <div id="select-box">
          <input
            type="checkbox"
            class="options-view-button"
            id="options-view-button-${this.label}"
          />
          <div id="select-button" class="brd">
            <div id="selected-value">
              <span>${this.label}</span>
            </div>
            <div id="chevrons">
              <mwc-icon>unfold_more</mwc-icon>
            </div>
          </div>
          <div id="options">
            ${this.options.map(
              op => html`
                <div class="option">
                  <input
                    class="s-c top"
                    type="radio"
                    name="platform"
                    value=${op.value}
                    .checked=${this.selected === op.value}
                    @change="${() => {
                      this.onSelected(op.value,op.label);
                    }}"
                  />
                  <input
                    class="s-c bottom"
                    type="radio"
                    name="platform"
                    value=${op.value} 
                    .checked=${this.selected === op.value}
                    @change="${() => {
                      this.onSelected(op.value,op.label);
                    }}"
                  />
                  <span class="label">${op.value}</span>
                  <span class="opt-val">${op.label}</span>
                </div>
              `
            )}

            <div id="option-bg"></div>
          </div>
        </div>
      </div>
    `;
  }

  static styles = css`
    :host {
      position: relative;
    }

    .brd {
      border: 1px solid #e2eded;
      border-color: #eaf1f1 #e4eded #dbe7e7 #e4eded;
    }

    #app-cover {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      width: 210px;
      height: 60px;
      z-index: 1;
      font-family: 'Poppins', sans-serif;
    }

    #select-button {
      position: relative;
      height: 16px;
      padding: 18px 14px;
      background-color: #fff;
      border-radius: 4px;
      cursor: pointer;
    }

    .options-view-button {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      opacity: 0;
      cursor: pointer;
      z-index: 3;
    }

    #selected-value {
      font-size: 16px;
      line-height: 1;
      margin-right: 26px;
    }

    .option,
    .label {
      color: #2d3667;
      font-size: 16px;
      white-space: nowrap;
    }

    #chevrons {
      position: absolute;
      top: 0;
      right: 16px;
      bottom: 0;
      width: 12px;
      padding: 9px 14px;
    }

    #chevrons mwc-icon {
      display: block;
      height: 100%;
      color: #d1dede;
      --mdc-icon-size: 28px;
      text-align: right;
    }

    .options-view-button:checked + #select-button #chevrons mwc-icon {
      color: #2d3667;
    }

    .options {
      position: absolute;
      left: 0;
      width: 250px;
    }

    #options {
      position: absolute;
      top: 56px;
      right: 0;
      left: 0;
      width: 210px;
      margin: 0 auto;
      background-color: #fff;
      border-radius: 4px;
    }

    .options-view-button:checked ~ #options {
      border: 1px solid #e2eded;
      border-color: #eaf1f1 #e4eded #dbe7e7 #e4eded;
    }

    .option {
      position: relative;
      line-height: 1;
      transition: 0.3s ease all;
      z-index: 2;
    }

    .label {
      display: none;
      padding: 0;
    }

    .options-view-button:checked ~ #options .label {
      display: block;
      padding: 12px 14px;
    }

    .s-c {
      position: absolute;
      left: 0;
      width: 100%;
      height: 50%;
    }

    .s-c.top {
      top: 0;
    }

    .s-c.bottom {
      bottom: 0;
    }

    input[type='radio'] {
      position: absolute;
      right: 0;
      left: 0;
      width: 100%;
      height: 50%;
      margin: 0;
      opacity: 0;
      cursor: pointer;
    }

    .s-c:hover {
      height: 100%;
      z-index: 1;
    }

    @keyframes moveup {
      0% {
        bottom: -25px;
        opacity: 0;
      }
      100% {
        bottom: 0;
        opacity: 1;
      }
    }

    @keyframes movedown {
      0% {
        top: -25px;
        opacity: 0;
      }
      100% {
        top: 0;
        opacity: 1;
      }
    }

    .label {
      transition: 0.3s ease all;
    }

    .opt-val {
      position: absolute;
      left: 14px;
      width: 158px;
      height: 21px;
      opacity: 0;
      background-color: #fff;
      transform: scale(0);
    }

    .option input[type='radio']:checked ~ .opt-val {
      opacity: 1;
      transform: scale(1);
    }

    .option input[type='radio']:checked ~ .label:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
    }

    .options-view-button:not(:checked)
      ~ #options
      .option
      input[type='radio']:checked
      ~ .opt-val {
      top: -38px;
    }

    .option:nth-child(1) input[type='radio']:checked ~ .opt-val {
      top: -39px;
    }

    .option:nth-child(2) input[type='radio']:checked ~ .opt-val {
      top: -79px;
    }

    .option:nth-child(3) input[type='radio']:checked ~ .opt-val {
      top: -119px;
    }

    .option:nth-child(4) input[type='radio']:checked ~ .opt-val {
      top: -159px;
    }

    .option:nth-child(5) input[type='radio']:checked ~ .opt-val {
      top: -199px;
    }

    .option:nth-child(6) input[type='radio']:checked ~ .opt-val {
      top: -239px;
    }

    #option-bg {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 40px;
      transition: 0.3s ease all;
      z-index: 1;
      display: none;
    }

    .options-view-button:checked ~ #options #option-bg {
      display: block;
    }

    .option:hover ~ #option-bg {
      background-color: #e2eded;
      border-radius: 4px 4px 0 0;
    }

    .option:nth-child(1):hover ~ #option-bg {
      top: 0;
    }

    .option:nth-child(2):hover ~ #option-bg {
      top: 40px;
    }

    .option:nth-child(3):hover ~ #option-bg {
      top: 80px;
    }

    .option:nth-child(4):hover ~ #option-bg {
      top: 120px;
    }

    .option:nth-child(5):hover ~ #option-bg {
      top: 160px;
    }
  `;
}
