/* eslint-disable prettier/prettier */
import { LitElement, html } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import '@material/mwc-drawer';
import '@material/mwc-button';
import '@material/mwc-icon-button';
import '@material/mwc-top-app-bar-fixed';
import '@material/mwc-icon';
import '@material/mwc-menu';
import '@material/mwc-list/mwc-list-item';
import { connect } from 'pwa-helpers';
import { SecurityApi } from '../api';
import router from './router/router';
import './styles.css';
import './components';
import './pages/Hierarchy/Hierarchy';
import store from './redux/store';
import { cerrarSesion, iniciarSesion } from './redux/actions';
import { errorNotification } from '../src/services/notifications';
import { State } from './redux/types';
import { getCookie } from './services/cookie';
import { Menu } from '@material/mwc-menu';
import { CambiarMaster } from '../src/components/modals/CambiarMaster';
import '../src/components/modals/CambiarMaster';
import { IconButton } from '@material/mwc-icon-button';
import { apisConfigurator } from './utils/axios';

// const logo = new URL('../../assets/open-wc-logo.svg', import.meta.url).href;
@customElement('tms-maqueta')
export class TmsMaqueta extends connect(store)(LitElement) {
  @property({ type: String }) title = 'My app';

  @state() private drawerOpen = false;

  @state() private menuOpen = false;

  @state() private user? = '';

  @state() timer? = new Date();

  @state() band: boolean = true;

  @state() private showModules?: string[] = [];

  @state()
  private securityApi!: SecurityApi;

  @query('#menu')
  menu!: HTMLElement;

  @query('#menu_button')
  menuButton!: IconButton;

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  openCloseDrawer() {
    this.drawerOpen = !this.drawerOpen;
  }

  navigate(route: string) {
    router.cambiarSeccion(route);
  }

  get cambioMaster() {
    return document.getElementById('cambiarMaster') as CambiarMaster;
  }

  calcularHoras(fecha1: any, fecha2: Date) {
    let w = new Date(fecha1);
    return (fecha2.valueOf() - w.valueOf()) / 1000 / 60;
  }

  stateChanged(state: any) {
    if (state.fecha) {
      this.timer = state.fecha;
    }
  }

  protected firstUpdated(): void {
    router.init(document.getElementById('outlet')!);

    this.getUserCookie();  

    window.addEventListener('vaadin-router-location-changed', () => {
      const { authorizedFunctions, usuario } = store.getState() as State;
      console.log(authorizedFunctions)  
      /*if(this.timer) {
        let tiempoActual = new Date();
        let resultado = this.calcularHoras(this.timer,tiempoActual)
        if (resultado>=24.1) {
          //this.handleLogout()
          errorNotification("Token de sesion vencido, vuelva realizar login de usuario!")
          location.reload();
          this.timer = undefined;
        }
      }*/

      this.showModules = authorizedFunctions;
      this.user = usuario;
      this.verifyAuth();

      //console.log(this.showModules);
    });

    window.addEventListener('click', (e: any) => {
      //this.resetTimer();
      //console.log(this.currSeconds)
      if (!document.getElementById('menu')) return;

      if (
        !document.getElementById('menu')!.contains(e.target) &&
        !document.getElementById('menu_button')!.contains(e.target) &&
        this.menuOpen
      ) {
        this.menu.style.display = 'none';
      }
    });
  }
 
  getUserCookie() {
    const userCookie = getCookie('tms_user');
    console.log(userCookie);

    if (!userCookie || userCookie === '') {
      return;
    }

    const user = JSON.parse(userCookie);

    //console.log('Usuario');
    //console.log(user);

    store.dispatch(
      iniciarSesion(user.token, user.authorizedFunctions, user.usuario)
    );
  }

  verifyAuth() {

    if (!this.user && !router.url.includes('login')) {
      this.drawerOpen = false;
      router.cambiarSeccion('login');
    }
    this.requestUpdate('drawerOpen');
    this.requestUpdate('user');
  }

  protected async handleLogout() {
    localStorage.removeItem('token');
    try {
      const response = await new SecurityApi(
        ...apisConfigurator('Cerrar sesión')
      ).securityLogout({});

      store.dispatch(cerrarSesion());

      if (response.status === 200) {
      }
      router.cambiarSeccion('login');
    } catch (error) {
      console.log(error);

      store.dispatch(cerrarSesion());
      router.cambiarSeccion('login');
    }
  }
 
  seccionActiva(seccion: string) {
    if (router.url.split('/')[3].includes(seccion)) return true;
    return false;
  }

  render() {
    return html`
      <mwc-drawer
        id="drawer"
        .open=${this.user ? true : false}
        type="dismissible"
        hasHeader
        class="items-center"
      >
        <img
          class="text-center mx-4"
          slot="title"
          src="./Logo_IO.png"
          style="width: 195px;"
        />
        <div class="h-full flex w-full flex-col items-center">
          <div
            class="flex  items-center w-full rounded-1xl cursor-pointer ${!this.seccionActiva(
              'hierarchy'
            ) &&
            !this.seccionActiva('group') &&
            !this.seccionActiva('app') &&
            !this.seccionActiva('terminal') &&
            !this.seccionActiva('user') &&
            !this.seccionActiva('plantilla-page')
              ? ' Active w-full text-base'
              : 'Inactive w-full text-base hover:text-blue-700'}"
            .onclick="${() => this.navigate('')}"
          >
            <mwc-icon-button icon="dashboard"></mwc-icon-button>
            <p class="">Dashboard</p>
          </div>
          ${this.showModules?.includes('ORGANIZATIONS_MODULE')
            ? html`
                <div
                  class="flex  pl-3 items-center w-full rounded-1xl  cursor-pointer ${this.seccionActiva(
                    'hierarchy'
                  )
                    ? 'Active  w-full text-base'
                    : 'Inactive text-base  w-full hover:text-blue-700'}"
                  .onclick="${() => this.navigate('hierarchy')}"
                >
                  <mwc-icon-button icon="people"></mwc-icon-button>
                  <p>Grupos</p>
                </div>
              `
            : ''}
          ${this.showModules?.includes('TERMINAL_MODULE')
            ? html` <div
                class="flex  pl-3 items-center w-full rounded-1xl cursor-pointer ${this.seccionActiva(
                  'terminal'
                )
                  ? 'Active text-base w-full'
                  : 'Inactive text-base  w-full hover:text-blue-700'}"
                .onclick="${() => this.navigate('terminal-list')}"
              >
                <mwc-icon-button icon="smartphone"></mwc-icon-button>
                <p>Terminales</p>
              </div>`
            : ''}
          ${this.showModules?.includes('USERS_MODULE')
            ? html` <div
                class="flex  pl-3 items-center w-fullrounded-1xl cursor-pointer ${this.seccionActiva(
                  'user'
                )
                  ? 'Active text-base w-full'
                  : 'Inactive text-base w-full hover:text-blue-700'}"
                .onclick="${() => this.navigate('users-list')}"
              >
                <mwc-icon-button icon="auto_awesome_motion"></mwc-icon-button>
                <p>Usuarios</p>
              </div>`
            : ''}
            ${this.showModules?.includes('FIRMWARE_MODULE')
            ? html` <div
                class="flex  items-center w-full rounded-1xl cursor-pointer ${this.seccionActiva(
                  'firmware-list'
                )
                  ? ' Active w-full text-base'
                  : 'Inactive w-full text-base hover:text-blue-700'}"
                .onclick="${() => this.navigate('firmware-list')}"
              >
                <mwc-icon-button icon="memory"></mwc-icon-button>
                <p class="">Firmware</p>
              </div>`
            : ''}
          ${this.showModules?.includes('APPS_MODULE')
            ? html`
                <div
                  class="flex  pl-3 items-center w-full rounded-1xl cursor-pointer ${this.seccionActiva(
                    'app'
                  )
                    ? 'Active text-base  w-full'
                    : 'Inactive text-base w-full hover:text-blue-700'}"
                  .onclick="${() => this.navigate('apps-list')}"
                >
                  <mwc-icon-button icon="widgets"></mwc-icon-button>
                  <p>Apps</p>
                </div>
              `
            : ''}
          ${this.showModules?.includes('TEMPLATE_MODULE')
            ? html` <div
                class="flex  items-center w-full rounded-1xl cursor-pointer ${this.seccionActiva(
                  'plantilla-page'
                )
                  ? ' Active w-full text-base'
                  : 'Inactive w-full text-base hover:text-blue-700'}"
                .onclick="${() => this.navigate('plantilla-page')}"
              >
                <mwc-icon-button icon="view_list"></mwc-icon-button>
                <p class="">Plantillas</p>
              </div>`
            : ''}
            ${this.showModules?.includes('FOLIO_MODULE')
            ? html` <div
                class="flex  items-center w-full rounded-1xl cursor-pointer ${this.seccionActiva(
                  'folio-list'
                )
                  ? ' Active w-full text-base'
                  : 'Inactive w-full text-base hover:text-blue-700'}"
                .onclick="${() => this.navigate('folio-list')}"
              >
                <mwc-icon-button icon="format_list_numbered"></mwc-icon-button>
                <p class="">Folio</p>
              </div>`
            : ''}
            ${this.showModules?.includes('AFFILIATION_MODULE')
            ? html` <div
                class="flex  items-center w-full rounded-1xl cursor-pointer ${this.seccionActiva(
                  'afiliaciones-list'
                )
                  ? ' Active w-full text-base'
                  : 'Inactive w-full text-base hover:text-blue-700'}"
                .onclick="${() => this.navigate('afiliaciones-list')}"
              >
                <mwc-icon-button icon="handshake"></mwc-icon-button>
                <p class="">Afiliaciones</p>
              </div>`
            : ''}
            ${this.showModules?.includes('BUSINESS_MODULE')
            ? html` <div
                class="flex  items-center w-full rounded-1xl cursor-pointer ${this.seccionActiva(
                  'razon-social-list'
                )
                  ? ' Active w-full text-base'
                  : 'Inactive w-full text-base hover:text-blue-700'}"
                .onclick="${() => this.navigate('razon-social-list')}"
              >
                <mwc-icon-button icon="diversity_3"></mwc-icon-button>
                <p class="">Razon Social</p>
              </div>` 
            : ''}
            ${this.showModules?.includes('FILE_MODULE')
            ? html` <div
                class="flex  items-center w-full rounded-1xl cursor-pointer ${this.seccionActiva(
                  'file-list'
                )
                  ? ' Active w-full text-base'
                  : 'Inactive w-full text-base hover:text-blue-700'}"
                .onclick="${() => this.navigate('file-list')}"
              >
                <mwc-icon-button icon="archive"></mwc-icon-button>
                <p class="">Archivo</p>
              </div>`
            : ''}
            ${this.showModules?.includes('BANORTE_MODULE')
            ? html` <div
                class="flex  items-center w-full rounded-1xl cursor-pointer ${this.seccionActiva(
                  'carga-masiva-banorte'
                )
                  ? ' Active w-full text-base'
                  : 'Inactive w-full text-base hover:text-blue-700'}"
                .onclick="${() => this.navigate('carga-masiva-banorte')}"
              >
                <mwc-icon-button icon="archive"></mwc-icon-button>
                <p class="">Banorte</p>
              </div>`
            : ''}
          <!-- <div
            class="flex  items-center w-full rounded-1xl cursor-pointer ${this.seccionActiva(
              'preconfiguration-page'
            )
              ? ' Active w-full text-base'
              : 'Inactive w-full text-base hover:text-blue-700'}"
            .onclick="${() => this.navigate('preconfiguration-page')}"
          >
            <mwc-icon-button icon="dashboard"></mwc-icon-button>
            <p class="">Pre-Configuracion</p>
          </div> -->
          <div
            class="border-t w-3/4 py-4 mt-auto text-center text-blue-800 text-sm"
          >
            &copy; ${new Date().getFullYear()} Agnostiko
          </div>
        </div>
        <div class="h-screen" slot="appContent">
          ${this.user
            ? html`
                <mwc-top-app-bar-fixed class="top-bar"
                  ><div
                    id="menu"
                    style="position: absolute; left: 85%; top: 24px; display: none; z-index: 10;"
                    class="py-1 rounded-md bg-white flex-col"
                  >
                    <mwc-list-item
                      graphic="icon"
                      @click="${() => {
                        this.navigate('profile');
                      }}"
                    >
                      <mwc-icon slot="graphic">account_circle</mwc-icon>
                      <p>Perfil</p>
                    </mwc-list-item>
                    <mwc-list-item
                      graphic="icon"
                      @click="${() => {
                        this.cambioMaster.open();
                      }}"
                    >
                      <mwc-icon slot="graphic">autorenew</mwc-icon>
                      <p>Cambiar Master</p>
                    </mwc-list-item>
                    <mwc-list-item
                      graphic="icon"
                      @click="${() => {
                        this.handleLogout();
                      }}"
                    >
                      <mwc-icon slot="graphic">logout</mwc-icon>
                      <p>Cerrar Sesión</p>
                    </mwc-list-item>
                  </div>
                  <div
                    class="flex items-center mx-2"
                    slot="actionItems"
                    id="action-items"
                  >
                    <mwc-icon-button
                      icon="notifications"
                      class="icon-primary profile-action"
                      style="--mdc-icon-font: Material Icons Outlined"
                    ></mwc-icon-button>
                    <mwc-icon-button
                      icon="account_circle"
                      class="icon-primary profile-action"
                      id="menu_button"
                      @click="${() => {
                        this.menuOpen = true;
                        this.menu.style.display = 'flex';
                      }}"
                    >
                    </mwc-icon-button>
                    <div class="flex flex-col mx-2 ">
                      <p class="text-bold text-sm">${this.user ?? ''}</p>
                    </div>
                  </div>
                </mwc-top-app-bar-fixed>
              `
            : ''}
          <div
            class="overflow-auto w-full"
            style="${this.user
              ? 'width: calc(100vw - 268px); height: calc(100vh - 64px);'
              : 'width: 100vw; height: 100vh'}"
          >
            <div
              id="outlet"
              style="${this.user
                ? 'width: calc(100vw - 268px); height: calc(100vh - 64px);'
                : 'width: 100vw; height: 100vh'}"
            ></div>
          </div>
        </div>
      </mwc-drawer>
      <cambiar-master id="cambiarMaster"></cambiar-master>
    `;
  }
}
