/* tslint:disable */
/* eslint-disable */
/**
 * TMS Agnostiko
 * Tiene la definición de todos los servicios de TMS a Agnostic (para uso en la web o externo)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ConfigurationInfo } from '../models';
// @ts-ignore
import { Message } from '../models';
// @ts-ignore
import { RequestConfigurationCreate } from '../models';
// @ts-ignore
import { RequestConfigurationUpdate } from '../models';
// @ts-ignore
import { ResponseConfigurationGet } from '../models';
/**
 * ConfigurationApi - axios parameter creator
 * @export
 */
export const ConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Realiza la creación de un registro de configuración para un terminal y aplicación
         * @param {RequestConfigurationCreate} requestConfigurationCreate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConfiguration: async (requestConfigurationCreate: RequestConfigurationCreate, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestConfigurationCreate' is not null or undefined
            assertParamExists('createConfiguration', 'requestConfigurationCreate', requestConfigurationCreate)
            const localVarPath = `/configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestConfigurationCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary elimina una configuración
         * @param {string} brand 
         * @param {string} serial 
         * @param {string} appId 
         * @param {string} templateId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConfiguration: async (brand: string, serial: string, appId: string, templateId: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brand' is not null or undefined
            assertParamExists('deleteConfiguration', 'brand', brand)
            // verify required parameter 'serial' is not null or undefined
            assertParamExists('deleteConfiguration', 'serial', serial)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('deleteConfiguration', 'appId', appId)
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('deleteConfiguration', 'templateId', templateId)
            const localVarPath = `/configuration/{brand}/{serial}/{appId}/{templateId}`
                .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
                .replace(`{${"serial"}}`, encodeURIComponent(String(serial)))
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Obtener la configuración específica para un terminal y aplicación
         * @param {string} brand 
         * @param {string} serial 
         * @param {string} appId 
         * @param {string} templateId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfiguration: async (brand: string, serial: string, appId: string, templateId: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brand' is not null or undefined
            assertParamExists('getConfiguration', 'brand', brand)
            // verify required parameter 'serial' is not null or undefined
            assertParamExists('getConfiguration', 'serial', serial)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getConfiguration', 'appId', appId)
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('getConfiguration', 'templateId', templateId)
            const localVarPath = `/configuration/{brand}/{serial}/{appId}/{templateId}`
                .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
                .replace(`{${"serial"}}`, encodeURIComponent(String(serial)))
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Obtener la lista de configuraciones
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {string} [brand] 
         * @param {string} [serial] 
         * @param {string} [appId] 
         * @param {string} [templateId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConfiguration: async (acceptLanguage?: string, brand?: string, serial?: string, appId?: string, templateId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/configuration/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (brand !== undefined) {
                localVarQueryParameter['brand'] = brand;
            }

            if (serial !== undefined) {
                localVarQueryParameter['serial'] = serial;
            }

            if (appId !== undefined) {
                localVarQueryParameter['appId'] = appId;
            }

            if (templateId !== undefined) {
                localVarQueryParameter['templateId'] = templateId;
            }

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary actualiza una configuración
         * @param {string} brand 
         * @param {string} serial 
         * @param {string} appId 
         * @param {string} templateId 
         * @param {RequestConfigurationUpdate} requestConfigurationUpdate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConfiguration: async (brand: string, serial: string, appId: string, templateId: string, requestConfigurationUpdate: RequestConfigurationUpdate, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brand' is not null or undefined
            assertParamExists('updateConfiguration', 'brand', brand)
            // verify required parameter 'serial' is not null or undefined
            assertParamExists('updateConfiguration', 'serial', serial)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('updateConfiguration', 'appId', appId)
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('updateConfiguration', 'templateId', templateId)
            // verify required parameter 'requestConfigurationUpdate' is not null or undefined
            assertParamExists('updateConfiguration', 'requestConfigurationUpdate', requestConfigurationUpdate)
            const localVarPath = `/configuration/{brand}/{serial}/{appId}/{templateId}`
                .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
                .replace(`{${"serial"}}`, encodeURIComponent(String(serial)))
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestConfigurationUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigurationApi - functional programming interface
 * @export
 */
export const ConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Realiza la creación de un registro de configuración para un terminal y aplicación
         * @param {RequestConfigurationCreate} requestConfigurationCreate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createConfiguration(requestConfigurationCreate: RequestConfigurationCreate, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createConfiguration(requestConfigurationCreate, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ConfigurationApi.createConfiguration']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary elimina una configuración
         * @param {string} brand 
         * @param {string} serial 
         * @param {string} appId 
         * @param {string} templateId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConfiguration(brand: string, serial: string, appId: string, templateId: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConfiguration(brand, serial, appId, templateId, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ConfigurationApi.deleteConfiguration']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Obtener la configuración específica para un terminal y aplicación
         * @param {string} brand 
         * @param {string} serial 
         * @param {string} appId 
         * @param {string} templateId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConfiguration(brand: string, serial: string, appId: string, templateId: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseConfigurationGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConfiguration(brand, serial, appId, templateId, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ConfigurationApi.getConfiguration']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Obtener la lista de configuraciones
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {string} [brand] 
         * @param {string} [serial] 
         * @param {string} [appId] 
         * @param {string} [templateId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConfiguration(acceptLanguage?: string, brand?: string, serial?: string, appId?: string, templateId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConfigurationInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConfiguration(acceptLanguage, brand, serial, appId, templateId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ConfigurationApi.listConfiguration']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary actualiza una configuración
         * @param {string} brand 
         * @param {string} serial 
         * @param {string} appId 
         * @param {string} templateId 
         * @param {RequestConfigurationUpdate} requestConfigurationUpdate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConfiguration(brand: string, serial: string, appId: string, templateId: string, requestConfigurationUpdate: RequestConfigurationUpdate, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConfiguration(brand, serial, appId, templateId, requestConfigurationUpdate, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ConfigurationApi.updateConfiguration']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ConfigurationApi - factory interface
 * @export
 */
export const ConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigurationApiFp(configuration)
    return {
        /**
         * 
         * @summary Realiza la creación de un registro de configuración para un terminal y aplicación
         * @param {ConfigurationApiCreateConfigurationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConfiguration(requestParameters: ConfigurationApiCreateConfigurationRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.createConfiguration(requestParameters.requestConfigurationCreate, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary elimina una configuración
         * @param {ConfigurationApiDeleteConfigurationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConfiguration(requestParameters: ConfigurationApiDeleteConfigurationRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.deleteConfiguration(requestParameters.brand, requestParameters.serial, requestParameters.appId, requestParameters.templateId, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Obtener la configuración específica para un terminal y aplicación
         * @param {ConfigurationApiGetConfigurationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfiguration(requestParameters: ConfigurationApiGetConfigurationRequest, options?: AxiosRequestConfig): AxiosPromise<ResponseConfigurationGet> {
            return localVarFp.getConfiguration(requestParameters.brand, requestParameters.serial, requestParameters.appId, requestParameters.templateId, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Obtener la lista de configuraciones
         * @param {ConfigurationApiListConfigurationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConfiguration(requestParameters: ConfigurationApiListConfigurationRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<ConfigurationInfo>> {
            return localVarFp.listConfiguration(requestParameters.acceptLanguage, requestParameters.brand, requestParameters.serial, requestParameters.appId, requestParameters.templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary actualiza una configuración
         * @param {ConfigurationApiUpdateConfigurationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConfiguration(requestParameters: ConfigurationApiUpdateConfigurationRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.updateConfiguration(requestParameters.brand, requestParameters.serial, requestParameters.appId, requestParameters.templateId, requestParameters.requestConfigurationUpdate, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createConfiguration operation in ConfigurationApi.
 * @export
 * @interface ConfigurationApiCreateConfigurationRequest
 */
export interface ConfigurationApiCreateConfigurationRequest {
    /**
     * 
     * @type {RequestConfigurationCreate}
     * @memberof ConfigurationApiCreateConfiguration
     */
    readonly requestConfigurationCreate: RequestConfigurationCreate

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof ConfigurationApiCreateConfiguration
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteConfiguration operation in ConfigurationApi.
 * @export
 * @interface ConfigurationApiDeleteConfigurationRequest
 */
export interface ConfigurationApiDeleteConfigurationRequest {
    /**
     * 
     * @type {string}
     * @memberof ConfigurationApiDeleteConfiguration
     */
    readonly brand: string

    /**
     * 
     * @type {string}
     * @memberof ConfigurationApiDeleteConfiguration
     */
    readonly serial: string

    /**
     * 
     * @type {string}
     * @memberof ConfigurationApiDeleteConfiguration
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof ConfigurationApiDeleteConfiguration
     */
    readonly templateId: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof ConfigurationApiDeleteConfiguration
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for getConfiguration operation in ConfigurationApi.
 * @export
 * @interface ConfigurationApiGetConfigurationRequest
 */
export interface ConfigurationApiGetConfigurationRequest {
    /**
     * 
     * @type {string}
     * @memberof ConfigurationApiGetConfiguration
     */
    readonly brand: string

    /**
     * 
     * @type {string}
     * @memberof ConfigurationApiGetConfiguration
     */
    readonly serial: string

    /**
     * 
     * @type {string}
     * @memberof ConfigurationApiGetConfiguration
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof ConfigurationApiGetConfiguration
     */
    readonly templateId: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof ConfigurationApiGetConfiguration
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for listConfiguration operation in ConfigurationApi.
 * @export
 * @interface ConfigurationApiListConfigurationRequest
 */
export interface ConfigurationApiListConfigurationRequest {
    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof ConfigurationApiListConfiguration
     */
    readonly acceptLanguage?: string

    /**
     * 
     * @type {string}
     * @memberof ConfigurationApiListConfiguration
     */
    readonly brand?: string

    /**
     * 
     * @type {string}
     * @memberof ConfigurationApiListConfiguration
     */
    readonly serial?: string

    /**
     * 
     * @type {string}
     * @memberof ConfigurationApiListConfiguration
     */
    readonly appId?: string

    /**
     * 
     * @type {string}
     * @memberof ConfigurationApiListConfiguration
     */
    readonly templateId?: string
}

/**
 * Request parameters for updateConfiguration operation in ConfigurationApi.
 * @export
 * @interface ConfigurationApiUpdateConfigurationRequest
 */
export interface ConfigurationApiUpdateConfigurationRequest {
    /**
     * 
     * @type {string}
     * @memberof ConfigurationApiUpdateConfiguration
     */
    readonly brand: string

    /**
     * 
     * @type {string}
     * @memberof ConfigurationApiUpdateConfiguration
     */
    readonly serial: string

    /**
     * 
     * @type {string}
     * @memberof ConfigurationApiUpdateConfiguration
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof ConfigurationApiUpdateConfiguration
     */
    readonly templateId: string

    /**
     * 
     * @type {RequestConfigurationUpdate}
     * @memberof ConfigurationApiUpdateConfiguration
     */
    readonly requestConfigurationUpdate: RequestConfigurationUpdate

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof ConfigurationApiUpdateConfiguration
     */
    readonly acceptLanguage?: string
}

/**
 * ConfigurationApi - object-oriented interface
 * @export
 * @class ConfigurationApi
 * @extends {BaseAPI}
 */
export class ConfigurationApi extends BaseAPI {
    /**
     * 
     * @summary Realiza la creación de un registro de configuración para un terminal y aplicación
     * @param {ConfigurationApiCreateConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public createConfiguration(requestParameters: ConfigurationApiCreateConfigurationRequest, options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).createConfiguration(requestParameters.requestConfigurationCreate, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary elimina una configuración
     * @param {ConfigurationApiDeleteConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public deleteConfiguration(requestParameters: ConfigurationApiDeleteConfigurationRequest, options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).deleteConfiguration(requestParameters.brand, requestParameters.serial, requestParameters.appId, requestParameters.templateId, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Obtener la configuración específica para un terminal y aplicación
     * @param {ConfigurationApiGetConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public getConfiguration(requestParameters: ConfigurationApiGetConfigurationRequest, options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).getConfiguration(requestParameters.brand, requestParameters.serial, requestParameters.appId, requestParameters.templateId, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Obtener la lista de configuraciones
     * @param {ConfigurationApiListConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public listConfiguration(requestParameters: ConfigurationApiListConfigurationRequest = {}, options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).listConfiguration(requestParameters.acceptLanguage, requestParameters.brand, requestParameters.serial, requestParameters.appId, requestParameters.templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary actualiza una configuración
     * @param {ConfigurationApiUpdateConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public updateConfiguration(requestParameters: ConfigurationApiUpdateConfigurationRequest, options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).updateConfiguration(requestParameters.brand, requestParameters.serial, requestParameters.appId, requestParameters.templateId, requestParameters.requestConfigurationUpdate, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}

