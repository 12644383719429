/* eslint-disable prettier/prettier */
import { LitElement, html, css, TemplateResult, PropertyValueMap } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { App, AppList } from '../../api';
import { editarApp, verApp } from '../redux/actions';
import store from '../redux/store';
import router from '../router/router';
import { Application } from '../utils/dataTerminales';

@customElement('app-card')
export class AppCard extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  @property({ type: Object })
  app?: AppList;

  protected firstUpdated(): void {
    this.classList.add('w-6/12');
    this.classList.add('p-2');
  }

  render() {
    return html`
      <div
        class="w-full flex rounded-xl SectionDetail CardApp click"
        @click=${() => store.dispatch(verApp(this.app?.id!))}
      >
        <div
          class="w-4/12 Img "
          style="background: url(${this.app
            ?.briefPicture}) no-repeat center; background-size: cover"
        ></div>
        <div class="w-8/12 p-3">
          <div class="flex items-center mb-3">
            <img
              class="rounded-full mr-2"
              width="36"
              src="${this.app?.briefPicture!}"
            />
            <p class="text-md font-semibold">${this.app?.name}</p>
          </div>
          <p class="mb-2">${this.app?.description}</p>
          <div class="flex">
            <button
              @click=${() => {
                store.dispatch(editarApp(this.app));
                router.cambiarSeccion('edit-app');
              }}
              class="rounded-3xl mr-2 border w-28  border-indigo-500 text-blue-500  CancelarG  hover:shadow-md hover:bg-slate-300 text-xs	"
            >
              Editar
            </button>
            <button
              @click=${() => router.cambiarSeccion('view-app')}
              class="rounded-3xl  w-28 InstalarApp	"
            >
              Instalar
            </button>
          </div>
        </div>
      </div>
    `;
  }
}
