/* eslint-disable prettier/prettier */
import { html, LitElement } from 'lit';
import { customElement, query, state } from 'lit/decorators.js';
/*import '@vaadin/vaadin-text-field';
import '@vaadin/vaadin-select';*/
import Ajv from 'ajv';
import '@material/mwc-tab';
import '@material/mwc-tab-bar'; 
import '@material/mwc-list/mwc-list.js';
import '@material/mwc-list/mwc-list-item.js';
import '@material/mwc-switch';
import '@material/mwc-textfield';
//import './CrearTerminal.css';
import Swal from 'sweetalert2';
import {
  BrandList,
  ModelApi,
  RequestBatchFolioCreate,
  OrganizationApi,
  OrganizationName,
  RequestTerminalCreate,
  RequestTerminalBatch,
  TemplateApiGetTemplateRequest,
  TerminalApi,
  TemplateApi,
  FolioApi,
  RequestFolioBatch,
  AppApi
} from '../../../api';
import {
  succesNotification,
} from '../../services/notifications';
import {
  flatMarcas,
  flatOrganizations,
  modelosPorMaca,
  validar_campos
} from '../../utils/functions';
import { AxiosResponse } from 'axios';
import { EjemploCsv } from '../../components/modals/EjemploCsv';
import '../../components/modals/EjemploCsv';
import router from '../../router/router';
import { apisConfigurator } from '../../utils/axios';
import { generate_table,parameteArrays,verificarTemplates, InsertarPropiedad,validar_campos_carga_masiva } from '../../utils/functions';
import '../../components/CustomMultiSelect/CustomMultiSelect';
import { CustomSelect } from '../../components/CustomSelect/CustomSelect';
import '../../components/CustomSelect/CustomSelect';
import { errorNotification } from '../../services/notifications';
import { terminalAdded } from '../../redux/actions/terminals';
import store from '../../redux/store';

@customElement('carga-masiva-page')
export class CargaMasivaPage extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private modelApi: ModelApi;
  private folioApi: FolioApi;
  private appApi: AppApi;
  private templateApi: TemplateApi;

  @state()
  private loading = false;

  @state()
  private modelosMarcas: BrandList[] = [];

  @state()
  private organizaciones: OrganizationName[] = [];

  @state()
  private bandBatch:any = true;

  @state()
  plantillas: any = {};


  @state()
  private templates:any = true;

  @state()
  private template:any = true;

  @state()
  private brandSelected?: string;

  @state()
  private appId: string = "";

  @state()
  private templates2:any = true;

  @state()
  private templatesVerificar: string[] =[]

  @state()
  private app: any;

  @state()
  private areaError?: boolean;

  @state()
  private span?: boolean;

  @state()
  private file?: File;
  // is the array to hold the final values from the CSV file
  @state()
  final_vals: RequestBatchFolioCreate[] = [];
  //private final_vals: FileReader[] = [];

  @query('#serial')
  private serialInput!: HTMLInputElement;

  @query('#state')
  private estadoInput!: HTMLInputElement;

  @query('#owner')
  private ownerInput!: HTMLInputElement;

  @query('#description')
  private descriptionInput!: HTMLInputElement;

  @query('#csvForm')
  private csvFormInput!: HTMLInputElement;

  @query('#organization')
  private orgSelect!: CustomSelect;

  @query('#brand')
  private brandSelect!: CustomSelect;

  @query('#model')
  private modelSelect!: CustomSelect;

  /* terminalApi = new TerminalApi(); */

  private get botonCsv() {
    return document.querySelector('#seleccionar-archivo')! as HTMLInputElement;
    this.bandBatch= true;
  }

  get ejemploCsv() {
    return document.getElementById(
      'ejemploCsv'
    )! as EjemploCsv;
  }

  private constructor() {
    super();
    this.modelApi = new ModelApi(...apisConfigurator('Terminal'));
    this.appApi = new AppApi(...apisConfigurator('App'));
    this.templateApi = new TemplateApi(...apisConfigurator('Template'));
    this.folioApi = new FolioApi(...apisConfigurator('Folio'));
  }

  protected  async firstUpdated() {
    this.appId = localStorage.getItem('appId') ?? "";
    var obj = {
      $schema: 'http://json-schema.org/draft-04/schema#',
      properties: {},
      required: {},
    };
    InsertarPropiedad(obj);
    await this.getApp(this.appId);
    await this.getTemplates();

  }

  async getApp(appId: any) {

    try {
      const res = await this.appApi.getApp({ id: appId });
      const app = res.data;
      console.log("getApp",res.data)
      let templates:any = res.data.templates;
      templates.map(async(item:any,index:number)=>{
        await this.getTemplate2(item);
      })
      this.app = app;

    } catch (err) {
      errorNotification('Error al obtener la app');
      console.log(err);
    } finally {    

    }
  }

  private get seleccionarArchivo() {
    return document.getElementById('seleccionar-archivo') as any;
  }

  private get displayArea() {
    return document.getElementById('displayArea') as any;
  }

  private get disabledButton() {
    return document.getElementById('crearTerminal') as any;
  }

  private get alertCampo() {
    return document.getElementById('alertCampo') as any;
  }

  sortObjectKeysReverse(object: any) {
    const sortedKeys:any = Object.keys(object);
    sortedKeys.reverse();
  
    const sortedObject:any = {};
    for (const key of sortedKeys) {
      sortedObject[key] = object[key];
    }
  
    return sortedObject;
  }

  async getTemplate2(id: string) {
    var resTer: AxiosResponse;
    try {
      resTer = await this.templateApi.getTemplate({ id });
      this.plantillas = 
        {
          ...this.plantillas,
          ...resTer.data.schema.properties
        }
      ;
      console.log("PARAMETROS",this.plantillas);
    } catch (error) {
    } finally {
    }
  }

  async getTemplate(idTemplate:any) {
    const id:TemplateApiGetTemplateRequest = {
      id:idTemplate
    }
    console.log(id)
      const res = await this.templateApi.getTemplate(
        id
      );
      console.log("template",res.data)
    return res.data;

  }


async validarTipoJson(arrayTable: any[]) {
  const ajv = new Ajv()
  console.log("ENTRAR",this.templatesVerificar)
  arrayTable.map(async (item:any, index:number) => {
    const elementoEncontrado:any = this.templatesVerificar.filter((objeto:any) => objeto.id === item.templateId);
    const schema = {
      type: elementoEncontrado.configuration.type,
      properties: elementoEncontrado.configuration.properties,
      additionalProperties: false
    }
    if (item.hasOwnProperty("parametersArray")) {
      item.parametersArray.map((item2:any,index:number)=>{
        const data = item2;
        const valid = ajv.validate(schema, data)
        console.log(valid)
      })
    } else {
      const data = item.parameters;
      const valid = ajv.validate(schema, data)
      console.log(valid)
      
    }
  })
}

  async getTemplates() {
    try {
      const res = await this.templateApi.listTemplate();
      console.log("templates",res.data)
      const templates = res.data;
      const ingresar:any = []
      let elementoEncontrado:any = ""
      const templatesApp = this.app.templates;
      templatesApp.map(async(item:any,index:number) =>{
        const configuration = await this.getTemplate(item)
        elementoEncontrado = templates.filter((objeto:any) => objeto.id === item);
        elementoEncontrado[0].configuration = configuration.schema;
        ingresar.push(elementoEncontrado[0])
      })
      
      console.log("verificarTemplates",ingresar)
      this.templatesVerificar = ingresar;
      this.templates = res.data;
    } catch (error) {
      console.log(error)
    }
  }
  

  cvsForm() {
    let final_vals: any[] = [];
    let indices:any = [];
    let templatesVerificar = this.templatesVerificar;
    let templates = this.templates;
    let plantillas = this.plantillas;
    let titulosCSV:any = [];
    let validarTipoJson = this.validarTipoJson;
    let verificacion = "";
    let data: RequestBatchFolioCreate[] = [];
    let csvReader = new FileReader(); // generate a filereader from the JS API
    const alertCampo = this.alertCampo;
    const area = this.displayArea;
    console.log(area);
    const disabledButton = this.disabledButton;
    const input = this.seleccionarArchivo.files[0]; // grab the first (only) file from the input
 
    csvReader.onload = function (evt) {
      const text = evt?.target?.result; // this is the data generated from the csvReader reading the information in the file
      if (typeof text === 'string' || text instanceof String) {
        //const values = text.split(/[\n]+/); // group the information by the CSV breakpoint \n is a new line
        const values = text.split(/\r\n|\r|\n/,-1);
        values.map((val, index) => {
          // further split by each section by the CSV
          if (val!=="") {
          console.log("VAL",val)
          if (index == 0) {
            titulosCSV = val.split(/;/)
            if (!validar_campos_carga_masiva(val.split(/;/))) { 
              console.log(validar_campos(val.split(/;/)))
              console.log(val.split(/;/))
              area.style.border = '4px solid #b92326';
              alertCampo.style.visibility = 'visible';
              alertCampo.style.color = '#b92326';
              disabledButton.disabled = true;
            } else {
              indices = val.split(/;/).reduce((acumulador:any, elemento:any, indice:number) => {
                if (elemento === "item") {
                  acumulador.push(indice);
                }
                return acumulador;
              }, []);
              console.log(indices)
              console.log(validar_campos(val.split(/;/)))
              console.log(val.split(/;/))
              area.style.border = '';
              alertCampo.style.visibility = 'hidden'; 
              disabledButton.disabled = false;
              //alertCampo.style.color = "#b92326";
            }
        } else {
          console.log("temp;lates",val)
          console.log(val.split(/;/))
          let arregloFiltro = val.split(/;|,/);
          arregloFiltro.map((item:any,index:number) =>{
            arregloFiltro[index]=item.replace(/,/g, " ")
          })
          verificacion = verificarTemplates(val.split(/;/),templatesVerificar,titulosCSV);
          console.log(verificacion)
          let configurations = parameteArrays(val.split(/;/),indices,titulosCSV,templates,templatesVerificar,plantillas);
          console.log(configurations,index,values)
          //validarTipoJson(configurations)
          if(!configurations) {
            console.log("CONTROL")
            area.style.border = '4px solid #b92326';
            alertCampo.style.visibility = 'visible';
            alertCampo.style.color = '#b92326';
            disabledButton.disabled = true;
          } 
            const requestBatchFolioCreate: RequestBatchFolioCreate = {
              affiliationId: val.split(/;|,/)[0],
              description: val.split(/;|,/)[1],
              configurations

            };
            data.push(requestBatchFolioCreate);
          }
          let arrayOrdenar = val.split(/;|,/);
          console.log("ARRAYORDENAR",arrayOrdenar)
          if (index==1 && verificacion.length) {
            arrayOrdenar.unshift(verificacion);
            area.style.border = '4px solid #b92326';
            alertCampo.style.visibility = 'visible';
            alertCampo.style.color = '#b92326';
            disabledButton.disabled = true;
          }
          if(
            arrayOrdenar[0] !== " " &&
            arrayOrdenar[1] !== " " &&
            arrayOrdenar[2] !== " " &&
            arrayOrdenar[3] !== " " &&
            arrayOrdenar[4] !== " " &&
            arrayOrdenar[5] !== " "
          )final_vals.push(arrayOrdenar);
          console.log(arrayOrdenar)
          }
        });
        console.log(final_vals)
        if (verificacion.length) {
          final_vals[0].unshift("Error");
        }
        // create form
        generate_table(<[string[]]>final_vals).then(result => {
          // async function is used to ensure the formatting does not try to occur after the table is created

          area.innerHTML = result;

          const th_values = document.getElementsByTagName('th');
          const td_values = document.getElementsByTagName('td');

          const capitilize_table_column = (table_el: HTMLElement) => {
            table_el.innerHTML =
              table_el.innerHTML[0].toUpperCase() + table_el.innerHTML.slice(1);
          };

          for (const th_val of th_values) {
            capitilize_table_column(th_val);
          }
          for (const td_val of td_values) {
            capitilize_table_column(td_val);
          }
        });
      }
    };
    this.final_vals = data;

    // this runs the above action
    csvReader.readAsText(input);
  }

  eventoError(band: boolean) {}
 
  async handleSubmitBatch(e: Event) {
    e.preventDefault();
    let appId:string = localStorage.getItem('appId') ?? "";
    const requestFolioBatch : RequestFolioBatch = {
      appId:this.appId,
      batch: this.final_vals,
    };
    console.log(requestFolioBatch)

    try {
      const res = await this.folioApi.folioBatch({
        requestFolioBatch
      });
      console.log(res)
      if (res.status == 200) {
        this.span = true;
        let str = ``;
        res.data.list.forEach((element) => {
        str += `<div class="mx-2 TexField"><span>${element.id}</span></div>`;
        });
        const divClass = this.divClass;
        divClass.innerHTML = str;
        succesNotification('Folios registrados!')
        /*await Swal.fire('Agregado Folios!',
        str,
          'success');
        router.cambiarSeccion('folio-list');*/
      }
    } catch (err) {}
  }

  private get divClass() {
    return document.getElementById('lista-folios') as any;
  }

  validateForm() {
    if (!this.serialInput.checkValidity()) {
      errorNotification('El serial del terminal es requerido.');
      return false;
    }

    if (!this.brandSelect.selected) {
      errorNotification('La marca del terminal es requerida.');
      return false;
    }

    if (!this.modelSelect.selected) {
      errorNotification('El modelo del terminal es requerido.');
      return false;
    }

    if (!this.orgSelect.selected) {
      errorNotification('La organizacion del terminal es requerida.');
      return false;
    }

    if (!this.ownerInput.checkValidity()) {
      errorNotification('El dueño del terminal es requerido.');
      return false;
    }

    return true;
  }

  async handleSubmit(e: Event) {
    e.preventDefault();
    this.loading = true;

    if (!this.validateForm()) {
      this.loading = false;
      return;
    }

    const requestTerminalCreate: RequestTerminalCreate = {
      model: this.modelSelect.selected ?? '',
      brand: this.brandSelect.selected ?? '',
      serial: this.serialInput.value,
      owner: this.ownerInput.value,
      organization: this.orgSelect.selected ?? '',
      description: this.descriptionInput.value,
    };

  }

  render() {
    return html`
      <main class="w-full FondoColor min-h-full">
        <div class="w-full h-full px-5 py-4 flex flex-col flex-wrap items-start">
          <h2 class="text-2xl text-blue-600 font-bold uppercase">
            Carga Masiva Folio
          </h2>
          ${
            this.span ? html` <span class="text-green-500 font-medium text-lg tracking-wider mt-4">Folios agregados:</span>`
            : html ``
          }
    
          <div class="flex flex-wrap items-start my-4 mx-4" id="lista-folios">
            <div class="mx-2 TexField">
              <!-- <mwc-textfield
                required
                validationMessage="El número serial debe tener al menos 8 caracteres"
                label="Número Serial"
                id="serial"
                minLength="8"
                maxLength="50"
                autoValidate
                outlined
                iconTrailing="close"
              >
              </mwc-textfield> -->
            </div>
            <div class="mx-2 TexField">
              <!-- <custom-select
                label="Marca"
                id="brand"
                .options=${flatMarcas(this.modelosMarcas).map(m => ({
                  label: m,
                  value: m,
                }))}
                @change=${(ev: CustomEvent) => {
                  this.brandSelected = ev.detail;
                  this.bandBatch= false;
                }}
              ></custom-select> -->
            </div>
            <div class="mx-2 TexField">
              <!-- <custom-select
                label="Modelo"
                id="model"
                .options=${this.brandSelected
                  ? modelosPorMaca(this.modelosMarcas, this.brandSelected).map(
                      m => ({
                        label: m.model,
                        value: m.model,
                      })
                    )
                  : []}
              ></custom-select> -->
            </div>
            <div class="mx-2 TexField">
              <!-- <custom-select
                id="organization"
                label="Organizaciones"
                .options=${this.organizaciones.map(o => ({
                  label: o.name,
                  value: o.id,
                }))}
              ></custom-select> -->
            </div>
            <div class="mx-2 TexField">
              <!-- <mwc-textfield
                iconTrailing="close"
                required
                validationMessage="El dueño debe tener al menos 8 caracteres"
                label="Dueño"
                id="owner"
                minLength="5"
                autoValidate
                outlined
              >
              </mwc-textfield> -->
            </div>
            <div class="mx-2 TexField">
              <!-- <mwc-textfield
                iconTrailing="close"
                id="description"
                label="Descripción"
                outlined
              >
              </mwc-textfield> -->
            </div>
          </div> 
          <div class="my-4 ml-8">
            <p>
              sube un archivo CSV con la cantidad de Folio que desees agregar.
            </p>
            <span id="alertCampo">Formato de campos incorrectos</span>
          </div>
          <div class="w-full flex flex-row my-4 ml-8">
            <div class="CajonXlm w-3/5" id="displayArea"></div>
            <div class="w-2/5	ml-10 CrearTerminal normal-case 	flex flex-col">
              <div class="w-40	 min-w-full">
                <mat-form-field>
                  <!-- <input type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" placeholder="label" value="value"> -->
                </mat-form-field>
                <!-- <form id="csvForm">
                    <div class="mb-3">
                        <label for="csvFile" class="form-label">CSV File</label>
                        <input class="form-control" id="csvFile" type="file" accept=".csv"/>
                    </div>
                    <div class="mb-3">
                        <input class="btn btn-primary" type="submit" value="Submit" />
                    </div>
                  </form> -->
                <!-- <mwc-button  
                  class="rounded-3xl ml-4 mb-2">
                    <p class="normal-case text-xs">Explorar</p>
                  </mwc-button> -->
                <input
                  id="seleccionar-archivo"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  placeholder="label"
                  type="file"
                  @change=${(ev: InputEvent) => {
                    this.cvsForm();
                    //console.log(ev);
                    //console.log(this.botonCsv.files);
                  }}
                  hidden
                />
                <mwc-button
                  class="rounded-3xl ml-4 mb-2"
                  @click=${() => {
                    this.botonCsv.click();
                  }}
                  }}
                >
                  <p class="normal-case text-xs">Explorar</p>
                </mwc-button>
              </div>
              <div class="w-40	 min-w-full">
                <mwc-button
                  type="submit"
                  class="rounded-3xl ml-4 mt-4  bg-gray-500 text-gray-700	normal-case	"
                  @click=${() => {
                    let area = this.displayArea;
                    const alertCampo = this.alertCampo;
                    area.innerHTML = ``;
                    area.style.border = '';
                    alertCampo.style.visibility = 'hidden';
                  }}
                >
                  <p class="normal-case	 text-xs">Eliminar</p>
                </mwc-button>
              </div>
            </div>
          </div>

          <div class="w-full flex flex-row my-4 ml-8">
            <div class="w-2/4	"></div>
            <div class="w-2/4 CrearTerminal	flex flex-row">
              <button
                class="rounded-3xl border  border-indigo-500 text-blue-500  CancelarG  hover:shadow-md hover:bg-slate-300 text-xs	"
                @click="${(e: Event) => router.cambiarSeccion('folio-list')}"
              >
                Cancelar
              </button>

              <mwc-button
                id="crearTerminal"
                class="rounded-3xl ml-4 mb-2  normal-case		"
                @click="${(e: Event) => {
                  if(this.bandBatch){
                    this.handleSubmitBatch(e)
                  }else{
                    this.handleSubmit(e)
                  }
                }}"
                .disabled=${this.loading}
              >
                <p class="normal-case	text-xs ">Cargar</p>
              </mwc-button>
            </div>
          </div>
          <!-- </form> -->
        </div>
      </main>
      <ejemplo-csv id="ejemploCsv"></ejemplo-csv>
    `;
  }
}
