/* eslint-disable prettier/prettier */
import { html, LitElement, PropertyValueMap } from 'lit';
import { customElement, state } from 'lit/decorators.js';

import '@material/mwc-list/mwc-list.js';
import '@material/mwc-list/mwc-list-item.js';

import '../../components/SwiperCarousel/SwiperCarousel';
import { InstalarAppModal } from '../../components/modals/InstalarAppModal';
import '../../components/modals/InstalarAppModal';
import { AgregarInstaladorModal } from '../../components/modals/AgregarInstaladorApp';
import '../../components/modals/AgregarInstaladorApp';
import { AgregarVersionModal } from '../../components/modals/AgregarVersionApp';
import '../../components/modals/AgregarVersionApp';
import '../../components/SwiperCarousel/SwiperCarousel';
import { editarApp } from '../../redux/actions';
import store from '../../redux/store';
import router from '../../router/router';
import { App, AppApi, DownloadApi } from '../../../api';
import { apisConfigurator } from '../../utils/axios';
import { State } from '../../redux/types';
import { errorNotification } from '../../services/notifications';

@customElement('view-app-page')
export class ViewAppPage extends LitElement {
  @state()
  private app?: App;

  @state()
  private idApp = '';

  @state()
  private idIcon: string = '';

  @state()
  private loading = false;

  private appApi: AppApi;

  private downloadApi: DownloadApi;

  constructor() {
    super();
    this.appApi = new AppApi(...apisConfigurator('Aplicaciones'));
    this.downloadApi = new DownloadApi(...apisConfigurator(''));
  }

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  get instalarAppModal() {
    return document.getElementById('instalarAppModal')! as InstalarAppModal;
  }

  get agregarInstaladorModal() {
    return document.getElementById(
      'agregarInstaladorModal'
    )! as AgregarInstaladorModal;
  }

  get agregarVersionModal() {
    return document.getElementById(
      'agregarVersionModal'
    )! as AgregarVersionModal;
  }

  protected firstUpdated(): void {
    this.classList.add('w-full');
    this.getApp();
  }

  async getApp() {
    const appId = (store.getState() as State).verAppId;

    if (!appId) {
      router.cambiarSeccion('apps-list');
      //errorNotification('Error al obtener la app');
      console.log('Error al obtener la app');
      return;
    }
    this.idApp = appId;

    try {
      const res = await this.appApi.getApp({ id: appId });
      this.app = res.data;
      //this.idIcon = res.data.briefPicture ? res.data.briefPicture : "" ;
      console.log(this.app);
      //const resDownload = await this.downloadApi.downloadImage({ id: this.idIcon });
      //console.log(resDownload)
    } catch (err) {
      //errorNotification('Error al obtener la app');
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  @state()
  private selectedTab = 0;

  private handleVersionCreated(ev: CustomEvent) {
    const { detail } = ev;
    if (this.app!.version) {
      this.app!.version.push(detail);
    } else {
      this.app!.version = [detail];
    }
    this.requestUpdate();
  }

  render() {
    return html`
      <main class="w-full h-full bg-[#F5F2FC] p-5">
        <div
          class="w-full bg-white rounded-xl py-5 px-10 max-h-full h-full divide-y-2"
        >
          <section class="w-full flex">
            <div class="w-2/12">
              <!-- <div class="imgApp mx-auto" style="background: url(${this.app
                ?.briefPicture}) no-repeat center; background-size: cover"></div> -->
              <img
                class="rounded-full mr-2"
                width="36"
                src="${this.app?.briefPicture!}"
              />
            </div>
            <div class="container ml-4 w-6/12 flex flex-col">
              <div class="nameApp">
                <h1>${this.app?.name}</h1>
              </div>
              <div class="mt-4 text-gray-500">
                <p>${this.app?.description}</p>
              </div>
              <div class="mt-4 text-gray-500">
                <h1>${this.app?.packageName}</h1>
              </div>
            </div>
            <div class="flex mt-4 mr-5 w-4/12 pr-10">
              <button
                @click=${() => {
                  store.dispatch(editarApp(this.app));
                  router.cambiarSeccion('edit-app');
                }}
                class="rounded-3xl mx-auto border w-28  border-indigo-500 text-blue-500  h-10  hover:shadow-md hover:bg-slate-300 text-xs	"
              >
                Editar
              </button>
              <button
                class="rounded-3xl w-28 mx-auto h-10 InstalarApp flex items-center"
                @click=${() => {
                  this.agregarVersionModal.open(this.app); 
                }}
              >
                <mwc-icon>add</mwc-icon>
                <p class="ml-1">Version</p>
              </button>
              ${this.app?.version?.length! > 0
                ? html`
                    <button
                      class="rounded-3xl w-28 mx-auto h-10 InstalarApp flex items-center"
                      @click=${() => {
                        this.agregarInstaladorModal.open(this.app);
                      }}
                    >
                      <mwc-icon>add</mwc-icon>
                      <p class="ml-1">Instalador</p>
                    </button>
                  `
                : html``}
              <button
                class="rounded-3xl w-28 mx-auto h-10 InstalarApp"
                @click=${() => {
                  this.instalarAppModal.open(this.app, this.idApp);
                }}
              >
                Instalar
              </button>
            </div>
          </section>
          <section
            class="w-full flex flex-col mt-5 max-h-5/6 overflow-y-auto divide-y-2"
            style="max-height: 82%;"
          >
            <div class="w-full p-5">
              <div class="mt-3 flex flex-col">
                <div class="flex justify-between">
                  <div class="flex flex-col items-center">
                    <p class="text-gray-500 font-bold">Valoración</p>
                    <div class="mt-3 flex items-center">
                      <span
                        class="px-8 py-2 rounded-full bg-black text-xl text-white font-bold"
                        >4.5</span
                      >
                      <div
                        class="flex ml-4 text-black"
                        style="--mdc-icon-font: Material Icons Round"
                      >
                        <mwc-icon>star</mwc-icon>
                        <mwc-icon>star</mwc-icon>
                        <mwc-icon>star</mwc-icon>
                        <mwc-icon>star</mwc-icon>
                        <mwc-icon>star_half</mwc-icon>
                      </div>
                    </div>
                  </div>
                  <div></div>
                  <div></div>
                </div>
                ${this.app?.picture?.length
                  ? html`
                      <div
                        class="my-3 h-80 w-full max-w-full overflow-hidden relative"
                      >
                        <swiper-carousel
                          .slides=${this.app?.picture}
                        ></swiper-carousel>
                      </div>
                    `
                  : html``}
                <div class="mt-3 flex flex-col">
                  <p></p>
                  <p class="text-blue-600 font-bold"></p>
                </div>
              </div>
            </div>
            <div class="w-full p-5">
              <div class="mt-3 flex flex-col">
                <div class="flex justify-between">
                  <h3 class="text-xl font-bold"></h3>
                  <p class="text-blue-600  font-bold">Historial de versiones</p>
                </div>
                <div class="flex justify-between">
                  <a></a>
                  <ul class="ml-4 mt-2 list-disc">
                    ${this.app?.version?.map(
                      v => html`
                        <li><b>${v.version}:</b> ${v.briefDescription}</li>
                      `
                    )}
                  </ul>
                </div>

                <!-- <p class="ml-auto text-blue-600 font-bold">Ver más</p> -->
              </div>
            </div>
            <div class="w-full p-5">
              <div class="mt-3 flex flex-col">
                <div class="flex justify-between">
                  <h3 class="text-xl font-bold">Información</h3>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
      <instalar-app-modal id="instalarAppModal"></instalar-app-modal>
      <agregar-instalador-modal
        id="agregarInstaladorModal"
      ></agregar-instalador-modal>
      <agregar-version-modal
        id="agregarVersionModal"
        @version-created=${this.handleVersionCreated}
      ></agregar-version-modal>
    `;
  }
}
