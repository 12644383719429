/* eslint-disable prettier/prettier */
import { html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { OrgChart } from 'd3-org-chart';
import * as d3 from 'd3';
import '@material/mwc-icon-button';
import { OrganizationName } from '../../../api';
import { OrgParent, OrgParentNode } from '../../models/Organizations';
import { flatOrganizations } from '../../utils/functions';

@customElement('org-chart-page')
export class OrgarCharPage extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  @property({ type: Array }) organizations: OrganizationName[] = [];

  @property({ type: String })
  data = { name: '' };

  @state()
  orgDataMapped: OrgParent[] = [];

  @state()
  color = false;

  @state()
  nodo = '';

  protected firstUpdated(): void {
    this.orgDataMapped = this.organizations
      .map(org => this.mapOrgParent(org))
      .flat();
    this.orgDataMapped.push({ id: 'TMS', name: 'TMS', parent: '' });
    this.classList.add('w-full');
    this.createOrgChart();
  }

  mapOrgParent(org: OrganizationName, parent?: OrganizationName): OrgParent[] {
    const orgNew = {
      id: org.id,
      name: org.name,
      parent: parent?.id ?? 'TMS',
    };
    return [
      orgNew,
      ...org.sons?.map(son => this.mapOrgParent(son, org)).flat(),
    ];
  }

  dataFlattened = [
    {
      name: 'TMS Organizaciones',
      id: 'TMS',
      parentId: '',
      size: '',
    },
    {
      name: 'Ian Devling',
      imageUrl:
        'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/cto.jpg',
      area: 'Corporate',
      profileUrl: 'http://example.com/employee/profile',
      office: 'CTO office',
      tags: 'Ceo,tag1,manager,cto',
      isLoggedUser: false,
      positionName: 'Chief Executive Officer',
      id: 'O-6066',
      parentId: 'TMS',
      size: '',
    },
    {
      name: 'Another',
      imageUrl:
        'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/cto.jpg',
      area: 'Corporate',
      profileUrl: 'http://example.com/employee/profile',
      office: 'CTO office',
      tags: 'Ceo,tag1,manager,cto',
      isLoggedUser: false,
      positionName: 'Chief Executive Officer',
      id: 'O-0067',
      parentId: 'TMS',
      size: '',
    },

    {
      name: 'Davolio Nancy',
      imageUrl:
        'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg',
      area: 'Corporate',
      profileUrl: 'http://example.com/employee/profile',
      office: 'CEO office',
      tags: 'Ceo,tag1, tag2',
      isLoggedUser: false,
      positionName: 'CTO',
      id: 'O-6068',
      parentId: 'O-6066',
      size: '',
    },
    {
      name: 'Leverling Janet',
      imageUrl:
        'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg',
      area: 'Corporate',
      profileUrl: 'http://example.com/employee/profile',
      office: 'CEO office',
      tags: 'Ceo,tag1, tag2',
      isLoggedUser: false,
      positionName: 'CTO',
      id: 'O-6069',
      parentId: 'O-6066',
      size: '',
    },
    {
      name: 'Leverling Janet',
      imageUrl:
        'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg',
      area: 'Corporate',
      profileUrl: 'http://example.com/employee/profile',
      office: 'CEO office',
      tags: 'Ceo,tag1, tag2',
      isLoggedUser: false,
      positionName: 'CTO',
      id: 'O-6070',
      parentId: 'O-6069',
      size: '',
    },
    {
      name: 'Leverling Janet',
      imageUrl:
        'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg',
      area: 'Corporate',
      profileUrl: 'http://example.com/employee/profile',
      office: 'CEO office',
      tags: 'Ceo,tag1, tag2',
      isLoggedUser: false,
      positionName: 'CTO',
      id: 'O-6071',
      parentId: 'O-6069',
      size: '',
    },
    {
      name: 'Leverling Janet',
      imageUrl:
        'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg',
      area: 'Corporate',
      profileUrl: 'http://example.com/employee/profile',
      office: 'CEO office',
      tags: 'Ceo,tag1, tag2',
      isLoggedUser: false,
      positionName: 'CTO',
      id: 'O-6072',
      parentId: 'O-6069',
      size: '',
    },
    {
      name: 'Leverling Janet',
      imageUrl:
        'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg',
      area: 'Corporate',
      profileUrl: 'http://example.com/employee/profile',
      office: 'CEO office',
      tags: 'Ceo,tag1, tag2',
      isLoggedUser: false,
      positionName: 'CTO',
      id: 'O-6073',
      parentId: 'O-6070',
      size: '',
    },
    {
      name: 'Fuller Andrew',
      imageUrl:
        'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg',
      area: 'Corporate',
      profileUrl: 'http://example.com/employee/profile',
      office: 'CEO office',
      tags: 'Ceo,tag1, tag2',
      isLoggedUser: false,
      positionName: 'Linear Manager',
      id: 'O-6074',
      parentId: 'O-6071',
      size: '',
    },
    {
      name: 'Peacock Margaret',
      imageUrl:
        'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg',
      area: 'Corporate',
      profileUrl: 'http://example.com/employee/profile',
      office: 'CEO office',
      tags: 'Ceo,tag1, tag2',
      isLoggedUser: false,
      positionName: 'CEO',
      id: 'O-6075',
      parentId: 'O-6071',
      size: '',
    },
    {
      name: 'Buchanan Steven',
      imageUrl:
        'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg',
      area: 'Corporate',
      profileUrl: 'http://example.com/employee/profile',
      office: 'CEO office',
      tags: 'Ceo,tag1, tag2',
      isLoggedUser: false,
      positionName: 'Head of direction',
      id: 'O-6076',
      parentId: 'O-6070',
      size: '',
    },
    {
      name: 'Suyama Michael',
      imageUrl:
        'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg',
      area: 'Corporate',
      profileUrl: 'http://example.com/employee/profile',
      office: 'CEO office',
      tags: 'Ceo,tag1, tag2',
      isLoggedUser: false,
      positionName: 'Senior sales manager',
      id: 'O-6077',
      parentId: 'O-6069',
      size: '',
    },
    {
      name: 'TMS',
      imageUrl:
        'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg',
      area: 'Corporate',
      profileUrl: 'http://example.com/employee/profile',
      office: 'CEO office',
      tags: 'Ceo,tag1, tag2',
      isLoggedUser: false,
      positionName: 'CEO',
      id: 'O-6079',
      parentId: 'O-0067',
      size: '',
    },
    {
      name: 'Buchanan Steven',
      imageUrl:
        'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg',
      area: 'Corporate',
      profileUrl: 'http://example.com/employee/profile',
      office: 'CEO office',
      tags: 'Ceo,tag1, tag2',
      isLoggedUser: false,
      positionName: 'Head of direction',
      id: 'O-60780',
      parentId: 'O-6068',
      size: '',
    },
    {
      name: 'Suyama Michael',
      imageUrl:
        'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg',
      area: 'Corporate',
      profileUrl: 'http://example.com/employee/profile',
      office: 'CEO office',
      tags: 'Ceo,tag1, tag2',
      isLoggedUser: false,
      positionName: 'Senior sales manager',
      id: 'O-6081',
      parentId: 'O-6068',
      size: '',
    },
  ];

  createOrgChart() {
    let chart: OrgChart<OrgParentNode>;
    const mappedData = this.orgDataMapped.map((d, index) => {
      const width = 240;
      const height = index === 0 ? 40 : 90;

      const expanded = d.id == 'O-6';

      //const titleMarginLeft = nodeImageWidth / 2 + 20 + centerLeftDistance;
      const titleMarginLeft = 45;

      return {
        nodeId: d.id,
        parentNodeId: d.parent,
        width: width,
        height: height,
        borderWidth: 1,
        borderRadius: 5,
        borderColor: {
          red: 15,
          green: 140,
          blue: 121,
          alpha: 1,
        },
        backgroundColor: {
          red: 51,
          green: 182,
          blue: 208,
          alpha: 1,
        },
        template: (hihlighted: boolean) => `
        <div style="color: ${hihlighted ? 'white' : 'black'};" >
                  <div style="margin-left:${titleMarginLeft}px;
                              margin-top:10px;
                              font-size:18px;
                              font-weight:bold;" 
                        class="${hihlighted ? 'text-white' : 'text-black'}"
                  >${d.name} </div>
                <div class="flex flex-row">
                 <div class="flex felx-row items-center	" style="margin-left:35px;
                              margin-top:3px;
                              font-size:13px;
                         ">
                         <mwc-icon-button
              icon="ad_units"
              class="${hihlighted ? 'text-white' : 'text-blue-600'}"
              
            ></mwc-icon-button>
            <p class="text-xl mr-1 ${
              hihlighted ? 'text-white' : 'text-blue-600'
            }">
              <span class="mx-2">100</span>
            </p>
                         </div>

                 <div class="flex felx-row items-center	" style="margin-left:10px;
                              margin-top:3px;
                              font-size:12px;
                         ">         <mwc-icon-button
              icon="people"
              class="${hihlighted ? 'text-white' : 'text-blue-600'}"
            ></mwc-icon-button>
            <p class="text-xl mr-1 ${
              hihlighted ? 'text-white' : 'text-blue-600'
            }">
              <span class="mx-2">100</span>
            </p>
                  </div>
                         </div>
              </div>`,
        connectorLineColor: {
          red: 220,
          green: 189,
          blue: 207,
          alpha: 1,
        },
        data: {
          name: d.name,
        },
        connectorLineWidth: 5,
        dashArray: '',
        expanded: expanded,
      };
    });

    chart = new OrgChart<OrgParentNode>()
      .container('.org-chart-container')
      .data(mappedData)
      .nodeWidth(n => 250)
      .nodeHeight((n: any) => (n.data.nodeId === 'TMS' ? 40 : 90))
      .compactMarginBetween(d => 50)
      .siblingsMargin(d => 100)
      .nodeContent((d: any, index) => {
        if (index === 0) {
          return `
          <div style="text-align: center" >
            <h1 style="font-size: 20px; font-weight: bold;">TMS Organizaciones</h1>
          </div>
          `;
        }

        return `
              <div  class="outer-wrapper" style="padding-left:70px;padding-top:40px;background-color:none;width:${
                d.width - 70
              }px;height:${d.height}px">
                <div id="node-content" style="margin-left:-70px;  margin-top:-40px;border-radius:5px;color:white;background-color:'#F1EFFA';width:${
                  d.width
                }px;  height:${d.height}px">
                   <div style="margin-left:-30px;padding-top:2px; ">${d.data.template(
                     d.data._highlighted || d.data._upToTheRootHighlighted
                   )}</div>
                </div>
              
              </div>
              `;
      })
      .linkUpdate(function (this: any, d, index, array: any) {
        const pplOrgs = array.filter(
          (org: any) => org.__data__.data.parentNodeId === 'TMS'
        );

        if (index < pplOrgs.length) return;

        d3.select(this)
          .attr('stroke', (d: any) =>
            d.data._upToTheRootHighlighted ? '#000C61' : '#A8A9C1'
          )
          .attr('stroke-width', (d: any) =>
            d.data._upToTheRootHighlighted ? 4 : 1
          );

        if ((d.data as any)._upToTheRootHighlighted) {
          d3.select(this).raise();
        }
      })

      .nodeUpdate(function (this: any, d, i, arr) {
        if (i === 0) return;
        const node = d3.select(this);
        node
          .select('.node-rect')
          .attr('stroke', (d: any) =>
            d.data._highlighted || d.data._upToTheRootHighlighted
              ? '#2e3eae'
              : 'none'
          )
          .attr(
            'stroke-width',
            (d.data as any)._highlighted ||
              (d.data as any)._upToTheRootHighlighted
              ? 4
              : 1
          );
        node.select('#node-content').attr('style', (d: any) => {
          return `margin-left:-70px; margin-top:-40px; border-radius:5px; background-color: ${
            d.data._highlighted || d.data._upToTheRootHighlighted
              ? `#000C61; color:white !important`
              : `#F1EFFA`
          }; width:${d.width}px; height:${d.height}px`;
        });
      })
      .buttonContent(({ node, state }) => {
        return `<div style="color: ${
          node.data._hihlighted || node.data._upToTheRootHighlighted
            ? 'white'
            : '#000C61'
        } ; border-radius:5px;padding:3px;font-size:10px;margin:auto auto;background-color:${
          node.data._hihlighted || node.data._upToTheRootHighlighted
            ? '#000C61'
            : '#F1EFFA'
        };border: 1px solid ${
          node.data._hihlighted || node.data._upToTheRootHighlighted
            ? 'white'
            : '#000C61'
        }"> <span style="font-size:9px">${
          node.children
            ? `<i class="fas fa-angle-up"></i>`
            : `<i class="fas fa-angle-down"></i>`
        }</span> ${(node.data as any)._directSubordinates}  </div>`;
      })
      .compact(false)
      .render();

    chart.onNodeClick(d => {
      chart.clearHighlighting();
      chart.setUpToTheRootHighlighted(`${d}`).render().fit();

      const orgSelected = flatOrganizations(this.organizations).find(
        org => org.id === (d as unknown as string)
      );

      if (!orgSelected) return;

      this.dispatchOrgSelected(orgSelected);

      Array.prototype.forEach.call(this.dataFlattened, items => {
        if (items.id === d) {
          console.log(items);
          this.data = { name: items.name };
          console.log(this.data.name);
        }
      });
    });

    chart.expandAll();
  }

  dispatchOrgSelected(org: OrganizationName) {
    const event = new CustomEvent<OrganizationName>('org-selected', {
      detail: org,
    });
    this.dispatchEvent(event);
  }

  render() {
    return html`<div class="org-chart-container"></div>`;
  }
}
