import { Group, GroupList } from '../../../api';
import { ActionTypes } from '../types';

export const EDITAR_GRUPO = 'EDITAR_GRUPO';
export const CLEAN_GRUPO = 'CLEAN_GRUPO';
export const GET_GRUPOS = 'GET_GRUPOS';
export const GRUPO_ADDED = 'GRUPO_ADDED';
export const GRUPOS_RECEIVED = 'GRUPOS_RECEIVED';

export function editarGrupo(grupo: Group): ActionTypes {
  return { type: EDITAR_GRUPO, grupo };
}
export function clearGrupo(): ActionTypes {
  return { type: CLEAN_GRUPO };
}

export function getGrupos(): ActionTypes {
  return { type: GET_GRUPOS, loading: true };
}

export function grupoAdded(newGrupo: GroupList): ActionTypes {
  return { type: GRUPO_ADDED, newGrupo };
}
