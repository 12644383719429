/* eslint-disable prettier/prettier */

import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '@ui5/webcomponents/dist/Dialog.js';
import '@vaadin/vaadin-select';
import {
  CommunicationTerminalApi,
  CommunicationTerminalApiRequestCommandRequest,
  RequestCommandTypeCommandEnum,
  Terminal,
} from '../../../api';
import { errorNotification } from '../../services/notifications';
import { apisConfigurator } from '../../utils/axios';

@customElement('mostrar-registrados-modal')
export class MostrarRegistradosModal extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private communicationApi: CommunicationTerminalApi;

  constructor() {
    super();

    this.communicationApi = new CommunicationTerminalApi(
      ...apisConfigurator('Comunicación')
    );
  }

  @property({ type: Object })
  terminal?: Terminal;

  @state()
  private titulo = '';

  @state()
  private productos:any[] = [["Folio","Afiliacion","Nombre Comercio","Nombre Contacto","Status"]];

  @state()
  private registrados:any[] = [];

  @state()
  private mensaje = '';

  @state()
  private operacion = '';

  @state()
  private dataRegistro:any = '';

  @state()
  private band:boolean = false;

  private get dialog() {
    return document.getElementById('registros-carga')! as any;
  }

  protected firstUpdated(): void {}

  async getOrganizaciones() {}

  async open(registrados:any,operacion:any,dataRegistro:any) {
    this.productos = [["Folio","Afiliacion","Nombre Comercio","Nombre Contacto","Status"]];
    this.operacion = operacion;
    this.registrados = registrados;
    this.dataRegistro = dataRegistro;
    console.log(dataRegistro);
    this.dialog.open = true;
    this.band=true;

    let Afiliacion;
    let Rs;

    this.registrados.map((item:any,index:number)=>{

      if(this.operacion === "Folio" ){
        //["Folio","Afiliacion","Nombre Comercio","Nombre Contacto","Status"]
        this.productos.push([`${item.id}`,`${dataRegistro[index].affiliationId}`,``,``,`${item.status}`])
      }else if(this.operacion === "Afiliacion"){
        Afiliacion = dataRegistro.find((producto:any) => producto.affiliationId === item.id);
        this.productos.push([``,`${item.id}`,`${Afiliacion.businessId}`,``,`${item.status}`])
      }else{
        Rs = dataRegistro.find((producto:any) => producto.businessId === item.id);
        this.productos.push([``,``,`${item.id}`,`${Rs.contactName}`,`${item.status}`])
      }
    })
  }

  close() { 
    this.dialog.open = false;
    this.terminal = undefined;
  }

  exportarCSV(){
    //const productos = [ ["C","afiliationId", "description", "cantidad" ], [ "CP", "Banorte-autorizador", "Amex", "port", "tls","url" ], [ "CP", "Banorte-comercio", "business ","name", "comboRef","" ], [ "CP","Banorte-terminal-F", "precio", 49.99 ] ]
    //const productValuesArrays = productos.map(producto =>Object.values(producto));
    //productValuesArrays.unshift(Object.keys(productos));
    const csvContent = "data:text/csv;charset=utf-8," 
    + this.productos.map((row:any) => row.join(";")).join("\n");

    const encodedUri = encodeURI(csvContent);
    
const link = document.createElement("a");
link.setAttribute("href", encodedUri);
link.setAttribute("download", "resultados.csv");
document.body.appendChild(link); 

link.click(); // This will download the CSV file named "products.csv".

  }

  private async handleSubmit(e: Event) {
    e.preventDefault();
 
    //console.log('terminal', this.terminal);
    if (!this.terminal) return;
    const requestCommand: CommunicationTerminalApiRequestCommandRequest = {
      brand: this.terminal.brand,
      serial: this.terminal.serial,
      requestCommand: {
        commandId: `displayMessage-${this.terminal.serial}-${Date.now()}`,
        typeCommand: RequestCommandTypeCommandEnum.DisplayMessage,
        parameters: [this.titulo, this.mensaje ?? this.titulo],
      },
    };

    try {
      this.close();
      const res = await this.communicationApi.requestCommand(requestCommand);
      //console.log('envio mensaje res', res);
    } catch (err: any) {
      console.log('envio mensaje err', err);
    }
  }

  private renderCommand(item: any, index:number) {
    let dataRegistro = this.dataRegistro;
    let Afiliacion;
    let Folio;
    let Rs;
    if(this.operacion === "Folio" ){
      //Folio = dataRegistro.find((producto:any) => producto.affiliationId === item.id);
    }else if(this.operacion === "Afiliacion"){
      Afiliacion = dataRegistro.find((producto:any) => producto.affiliationId === item.id);
    }else{
      Rs = dataRegistro.find((producto:any) => producto.businessId === item.id);
    }
    return html`<div class="flex flex-row justify-between border-y p-2">
    ${this.operacion === "Folio" ? 
    html`
    <div class="flex flex-col">
      <span class="text-gray-600 text-sm"
      ><b>${this.operacion}:</b> ${item.id}</span>

      <span class="text-gray-600 text-sm"
      ><b>Descripcion:</b> ${dataRegistro[index].description}</span> 

      <span class="text-gray-600 text-sm"
      ><b>Afiliacion Id:</b> ${dataRegistro[index].affiliationId}</span>

      <span class="text-gray-600 text-sm"
        ><b>Status:</b> ${item.status}</span>
    </div>
    <br>
    `
    : this.operacion === "Afiliacion" ?
    html `
    <div class="flex flex-col">
      <span class="text-gray-600 text-sm"
      ><b>${this.operacion}:</b> ${item.id}</span>

      <span class="text-gray-600 text-sm"
      ><b>Nombre Comercio:</b> ${Afiliacion.businessId}</span>

      <span class="text-gray-600 text-sm"
        ><b>status:</b> ${item.status}</span>
    </div>
    <br>
    `
    :
    html `
    <div class="flex flex-col">
      <span class="text-gray-600 text-sm"
      ><b>${this.operacion}:</b> ${item.id}</span>

      <span class="text-gray-600 text-sm"
      ><b>Nombre Contacto:</b> ${Rs.contactName}</span>

      <span class="text-gray-600 text-sm"
        ><b>status:</b> ${item.status}</span>
    </div>
    <br>
    `
  }
      <div class="flex flex-col"></div>
    </div>`;
  }

  

  protected render() {
    return html` <ui5-dialog
      id="registros-carga"
      header-text="Resultados"
      class="w-3/12"
    >
      <form 
        class="px-4 flex flex-col items-center"
        @submit=${this.handleSubmit}
      >
        <div class="flex flex-col w-full overflow-y-auto max-h-80 mb-2">
            ${this.registrados?.map((item:any,index:number) => this.renderCommand(item,index))}
        </div>

        <div slot="footer" class="dialog-footer">
        <button
          class="text-red-600 p-4 rounded-md uppercase"
          id="closeDialogButton"
          @click=${() => {
            this.close();
          }}
        >
          Cerrar
        </button>
        <button
        class="text-green-600 p-4 rounded-md uppercase"
        id="closeDialogButton"
        @click=${() => {
          this.exportarCSV()
        }}
      >
        Exportar
      </button>

        </div>
      </form>
    </ui5-dialog>`;
  }
}
