/* eslint-disable prettier/prettier */
import { html, LitElement, render } from 'lit';
import { customElement, query, state, property } from 'lit/decorators.js';
import Swal from 'sweetalert2';
//import  JSONEditor  from 'json-editor';
//import * as JSONEditor from "json-editor";
//import JSONEditor = require("json-editor");
import '@material/mwc-icon-button';
import '@material/mwc-list/mwc-list.js';
import '@material/mwc-list/mwc-list-item.js';
import '@material/mwc-list/mwc-list.css';
import '@material/mwc-list/mwc-list-item.css';
import '@vaadin/vaadin-grid';
import router from '../../src/router/router.js';
import { connect } from 'pwa-helpers';
import {
  TemplateApi,
  ConfigurationApi,
  ConfigurationApiGetConfigurationRequest,
  ConfigurationApiDeleteConfigurationRequest
} from '../../api/api.js';
import {
  ResponseTemplateGet,
} from '../../api';
import { AxiosResponse } from 'axios';
import {
  InsertarPropiedad,
  iterarCampoJson2,
  existenciaConfiguracion,
  deleteCamp,
} from '../../src/utils/functions';
import store from '../../src/redux/store';
import { State } from '../../src/redux/types';
import { Appp, ConfigConsole } from '../redux/actions';
import {
  descripcionPlantilla,
  idPlantilla,
  TypeJsonSchema
} from '../redux/actions/index.js';
import { existenciaConfiguracionTemplate,idConfiguracion} from '../utils/functions';
import { apisConfigurator } from '../../src/utils/axios.js';
import {
  agregarPlantilla,
  nombrePlantilla,
} from '../../src/redux/actions/index.js';

import { InformacionConfiguracion } from './modals/InformacionConfiguracion';
import './modals/InformacionConfiguracion';

@customElement('listado-plantilla')
export class ListadoPlantilla extends connect(store)(LitElement) {
  private templateApi: TemplateApi;
  private configurationApi: ConfigurationApi;

  private constructor() {
    super();
    this.templateApi = new TemplateApi(...apisConfigurator(''));
    this.configurationApi = new ConfigurationApi(...apisConfigurator(''));
  }

  get informacionConfiguracion() {
    return document.getElementById('informacionConfiguracion')! as InformacionConfiguracion;
  }

  get idCampo() {
    return document.querySelector('#campo')! as HTMLInputElement;
  }

  @query('#campos')
  private campos!: HTMLInputElement;

  @query('#descripcion')
  @state()
  loader = true;

  @state()
  appTemplates: string[] = [];

  @state()
  item: string = "";

  @state()
  configuration: any;

  @state()
  selectedPlantillas: string[] = [];

  @state()
  templatesInfo: { name: string; description: string; id: string }[] = [];

  @state()
  Plantill = { nombre: 'wladimir', uso: '12', descripcion: 'prueba1' };

  @state()
  plantillas: Object[] = [];

  @state()
  private loading = false;

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  async getConfigurations(id:string) {
    const { configConsole } = store.getState() as State;
    console.log(configConsole.templateId);
    let JsonSchema;
    let templateName;
    let prueba;
    try { 
      await this.templateApi
      .getTemplate({ id })
      .then(response => response.data)
      .then(data => {
        console.log("DATA",data)
        this.configuration = data;
        JsonSchema = data.schema;
        templateName = data.name;
        const properties = JsonSchema.properties;
        configConsole.JsonSchema = JsonSchema;
        console.log("properties",properties);
        const prueba = deleteCamp(properties);

        const objecto1 = configConsole;
        const objecto2 = {
          templateId: id,
          JsonSchema,
          templateName
        };
        Object.assign(objecto1, objecto2);
        /*store.dispatch(
          ConfigConsole({
            templateId: id,
            JsonSchema,
            properties: prueba,
            ...configConsole,
          })
        ); */

      });
      const res = await this.configurationApi.listConfiguration();
      const result = existenciaConfiguracion(
        res.data,
        configConsole.appId,
        configConsole.templateId,
        configConsole.brand,
        configConsole.serial
      );
      const objecto1 = configConsole;
      const objecto2 = { existConfig: result }; 
      Object.assign(objecto1, objecto2);
        console.log("CONFIGURATION",result)
      store.dispatch(
        ConfigConsole({
          existConfig: objecto1,
          templateId: id,
          JsonSchema,
          properties: prueba,
          ...configConsole,
        })
      );
      
    } catch (error) {
      console.log(error);
    }
  }

  async comprobarConfigurations(id:string) {
    const { configConsole } = store.getState() as State;
    try{
      const res = await this.configurationApi.listConfiguration();
      console.log("RES",res)
      const result = existenciaConfiguracion(
        res.data,
        configConsole.appId,  
        id,
        configConsole.brand, 
        configConsole.serial
      );
      let idConfig:ConfigurationApiDeleteConfigurationRequest = {
        appId:configConsole.appId,  
        templateId:id,
        brand:configConsole.brand, 
        serial:configConsole.serial
      }
      if(result) {
        Swal.fire({
          title: 'Desea elimnar la configuracion?',
          text: "Esta Plantilla contiene configuracion!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Eliminar'
        }).then(async(result) => {
          if (result.isConfirmed) {
            const res = await this.configurationApi.deleteConfiguration(
              idConfig
            );
            console.log("RES",idConfig)
            Swal.fire(
              'Eliminado!',
              'configuracion borrada.',
              'success'
            )
          }
        })
      }else{
        Swal.fire('No posee parametros configuracion!')
      }

      
    } catch (error) {
      console.log(error);
    }
  }

  async getTemplate(id: any): Promise<ResponseTemplateGet | null> {
    this.loader = true;
    try {
      const resTer = await this.templateApi.getTemplate({ id });

      let res = iterarCampoJson2(
        resTer.data.schema.properties,
        resTer.data.schema.required
      );
      console.log(resTer.data.name);
      /*store.dispatch(agregarPlantilla(res));
      store.dispatch(nombrePlantilla(resTer.data.name));
      store.dispatch(idPlantilla(id));
      store.dispatch(TypeJsonSchema(resTer.data.schema.type));
      store.dispatch(descripcionPlantilla(resTer.data.description));*/

      this.loader = false;
      return resTer.data;
    } catch (error) {
      return null;
    }
  }

  async deleteTemplate(id: any) {
    console.log("ELIMINADO")
    /*this.loader = true;
    try {
      const resTer = await this.templateApi.deleteTemplate({ id });
      if(resTer.request.status==200){
        await Swal.fire('Eliminado!', 'Plantilla ha sido eliminada', 'success');
        location.reload()
      }

    } catch (error) {
      return null;
    }*/
  } 

  async comprobarConfigurationsEditar(id:string) {

    try{
      const res = await this.configurationApi.listConfiguration();
      console.log("RES",res)
      const result = existenciaConfiguracionTemplate(
        res.data,
        id
      );
      console.log("RESULT",result)
      if(result) {
        Swal.fire(
          'No Permitido',
          'Esta plantilla contiene configuracion guardada',
          'question'
        )
      }else{
        await this.getConfigurations(id);
        let res = iterarCampoJson2(
          this.configuration.schema.properties,
          this.configuration.schema.required
        );
        store.dispatch(agregarPlantilla(res));
        store.dispatch(nombrePlantilla(this.configuration.name));
        store.dispatch(idPlantilla(id));
        store.dispatch(TypeJsonSchema(this.configuration.schema.type));
        store.dispatch(descripcionPlantilla(this.configuration.description));
        router.cambiarSeccion('edit-template');
      }

      
    } catch (error) {
      console.log(error);
    }
  }
 
  async ObtenerTodo2(){
    this.templatesInfo = []
    const { configConsole } = store.getState() as State;
    console.log("templates",configConsole);
    this.loader=true
    this.appTemplates = configConsole.app.templates ?? [];
    this.selectedPlantillas = this.appTemplates.map(plantilla => plantilla);
    this.appTemplates.forEach(async templateId => {
      const templateInfo = await this.getTemplate(templateId);
      console.log(templateInfo);
      if (templateInfo){
        this.loader=false;
        this.templatesInfo = [
          ...this.templatesInfo,
          { ...templateInfo, id: templateId },
        ];
      }
    });
  }

  async Obtenertodo() {
    const { configConsole } = store.getState() as State;
    console.log("templates",configConsole.app.templates);

    this.appTemplates = configConsole.app.templates ?? [];
    const id = configConsole.app.templates[0];

    await this.templateApi
      .getTemplate({ id })
      .then(response => response.data)
      .then(data => {
        this.plantillas = [ 
          {
            name: data.name,
            description: data.description,
            id,
          },
        ];

        const JsonSchema = data.schema;
        const properties = JsonSchema.properties;
        configConsole.JsonSchema = JsonSchema;
        console.log(properties);
        const prueba = deleteCamp(properties);

        const objecto1 = configConsole;
        const objecto2 = {
          templateId: id,
          JsonSchema,
          templateName: data.name,
        };
        Object.assign(objecto1, objecto2);
        store.dispatch(
          ConfigConsole({
            templateId: id,
            JsonSchema,
            properties: prueba,
            ...configConsole,
          })
        );

        return true;
      })
      .then(dat => {
        if (dat) {
          this.getConfigurations("");
        }
      });
  } 

  protected firstUpdated(): void {
    this.ObtenerTodo2();
  }

  render() {
    return html`
      <main class="w-full FondoColor flex " style="height: 28rem;">
      ${this.loader !== true
          ? html`
        <section class="w-full flex flex-wrap">
          <vaadin-grid class="h-full" .items="${this.templatesInfo}">
            <vaadin-grid-sort-column
              .renderer="${(root: HTMLElement, _: any, item: any) => {
                render(
                  html`
                    <input 
                      class=""
                      type="radio"
                      name="platform"
                      value=${item.item.id==this.item? true : false}
                      @change="${async(ev: InputEvent) => {
                        //this.item=item.item.id;
                         //this.getConfigurations(item.item.id);
                      }}" 
                    />
                  `,
                  root
                );
              }}"
            >
            </vaadin-grid-sort-column>
            <vaadin-grid-sort-column
              path="name"
              header="Nombre"
            ></vaadin-grid-sort-column>
            <vaadin-grid-sort-column
              path="description"
            ></vaadin-grid-sort-column>
            <vaadin-grid-sort-column
            header="Acciones"
            .renderer="${(root: HTMLElement, _: any, item: any) => {
              render(html`
                <div 
                  class="flex items-center  ml-2"
                > 
                <mwc-icon-button
                    class="ml-1"
                    icon="edit" 
                    ariaLabel="Actualizar"
                    @click=${async() => {
                      //console.log(item)
                      //console.log(item.item)
                      //this.comprobarConfigurationsEditar(item.item.id)
                      this.getConfigurations(item.item.id);
                    }}
                  ></mwc-icon-button>
                  <mwc-icon-button
                    class="ml-1"
                    icon="delete"
                    ariaLabel="Eliminar"
                    @click=${() => {
                      this.comprobarConfigurations(item.item.id)

                    }}
                  ></mwc-icon-button>
                </div>
              
              `, root); 
            }}"
          ></vaadin-grid-sort-column>
          </vaadin-grid>
          <mwc-icon-button
                  icon="autorenew"
                  class="absolute top-0 right-0"
                  @click=${() => {
                    this.ObtenerTodo2();
                  }}
                ></mwc-icon-button>
        </section>
        `
          : html`
              <mwc-circular-progress
                indeterminate
                density=${20}
              ></mwc-circular-progress>
            `}
      </main>
      <informacion-configuracion
        id="informacionConfiguracion"
      ></informacion-configuracion>
    `;
  }
}
