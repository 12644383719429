/* eslint-disable prettier/prettier */
import { html, LitElement } from 'lit';
import { customElement, query, state } from 'lit/decorators.js';
import { connect } from 'pwa-helpers';
import '@vaadin/vaadin-text-field';
import '@vaadin/vaadin-select';
import '@material/mwc-tab';
import '@material/mwc-tab-bar';
import '@material/mwc-list/mwc-list.js';
import '@material/mwc-list/mwc-list-item.js';
import '@material/mwc-switch';
import '@material/mwc-textfield';
//import './CrearTerminal.css';
import { State } from '../../redux/types';
import Swal from 'sweetalert2';
import {
  BrandList,
  ModelApi,
  OrganizationApi,
  OrganizationName,
  RequestTerminalCreate,
  FirmwareApiCreateFirmwareRequest,
  RequestTerminalBatch,
  TerminalApi,
  FirmwareApi,
  Firmware,
  FirmwareApiUpdateFirmwareRequest,
  RequestFirmwareUpdate
} from '../../../api';
import {
  succesNotification,
} from '../../services/notifications';
import {
  flatMarcas,
  flatOrganizations,
  modelosPorMaca,
  validar_campos,
} from '../../utils/functions';
import { EjemploCsv } from '../../components/modals/EjemploCsv';
import '../../components/modals/EjemploCsv';
import router from '../../router/router';
import { apisConfigurator } from '../../utils/axios';
import { generate_table, InsertarPropiedad } from '../../utils/functions';
import '../../components/CustomMultiSelect/CustomMultiSelect';
import { CustomSelect } from '../../components/CustomSelect/CustomSelect';
import '../../components/CustomSelect/CustomSelect';
import { errorNotification } from '../../services/notifications';
import { terminalAdded } from '../../redux/actions/terminals';
import store from '../../redux/store';

@customElement('edit-firmware-page')
export class EditFirmwarePage extends connect(store)(LitElement){
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }
  private firmwareApi: FirmwareApi;

  @state()
  private loading = false;

  @state()
  private firmware?: any;

  @state()
  final_vals: RequestTerminalCreate[] = [];

  @query('#name')
  private name!: HTMLInputElement;

  @query('#description')
  private description!: HTMLInputElement;

  @query('#briefDescription')
  private briefDescription!: HTMLInputElement;

  @query('#developer')
  private developer!: HTMLInputElement;

  @query('#os')
  private os!: HTMLInputElement;

  @query('#requirements')
  private requirements!: HTMLInputElement;

  @query('#brand')
  private brandSelect!: CustomSelect;

  @query('#model')
  private modelSelect!: CustomSelect;

  stateChanged(state: any) {
    this.firmware = state.firmware;
  }

  private constructor() {
    super();
    this.firmwareApi = new FirmwareApi(...apisConfigurator('Firmware'));
  }

  protected firstUpdated(): void {

    var obj = {
      $schema: 'http://json-schema.org/draft-04/schema#',
      properties: {},
      required: {},
    };
    //JsonPrueba(obj);
    InsertarPropiedad(obj);
  }
  
  private get displayArea() {
    return document.getElementById('displayArea') as any;
  }

  private get disabledButton() {
    return document.getElementById('crearTerminal') as any;
  }

  private get alertCampo() {
    return document.getElementById('alertCampo') as any;
  }

  eventoError(band: boolean) {}

  validateForm() {
    if (!this.name.checkValidity()) {
      errorNotification('El nombre del firmware es requerido.');
      return false;
    }

    if (!this.brandSelect.selected) {
      errorNotification('La marca del firmware es requerida.');
      return false;
    }

    if (!this.modelSelect.selected) {
      errorNotification('El modelo del firmware es requerido.');
      return false;
    }

    if (!this.description.checkValidity()) {
      errorNotification('La descripcion del firmware es requerida.');
      return false;
    }

    if (!this.developer.checkValidity()) {
      errorNotification('El desarrollador del firmware es requerido.');
      return false;
    }

    if (!this.os.checkValidity()) {
      errorNotification('El os del firmware es requerido.');
      return false;
    }

    if (!this.requirements.checkValidity()) {
      errorNotification('El requirements del firmware es requerido.');
      return false;
    }

    return true;
  }

  async handleSubmit(e: Event) {
    e.preventDefault();
    this.loading = true;

    /*if (!this.validateForm()) {
      this.loading = false;
      return;
    }*/
    console.log(this.name.value)
    const requestFirmwareUpdate: RequestFirmwareUpdate = { 

      name: this.name.value ?? this.firmware.name,
      briefDescription: this.briefDescription.value ?? this.firmware.briefDescription,
      description: this.description.value ?? this.firmware.description,
      developer: this.developer.value ?? this.firmware.developer,
      requirements: this.requirements.value ?? this.firmware.requirements
    }

    try {
      const res = await this.firmwareApi.updateFirmware({
        id: this.firmware.id,
        requestFirmwareUpdate,
      });
      console.log(res)
      succesNotification('Datos guardados con exito!'); 
      if (res.status == 200) {
        //router.cambiarSeccion('plantilla-page');
        await Swal.fire('Agregado!', 'El tipo de dato registrado', 'success');
        //window.location.reload()
        router.cambiarSeccion('firmware-list');
      } 
      //router.cambiarSeccion('terminal-list');
      //store.dispatch(terminalAdded(requestTerminalCreate));
    } catch (err) {
    } finally { 
      this.loading = false;
    }
  }

  render() {
    return html`
      <main class="w-full FondoColor min-h-full">
        <div class="w-full h-full px-5 py-4 flex flex-wrap items-start">
          <div
            class="w-full h-full relative mb-2  SectionList rounded-md pt-5 px-4 "
          >
          <h2 class="text-2xl text-blue-600 font-bold uppercase">
            Actualizar Firmware
          </h2>
          <div class="flex flex-wrap items-start my-4 mx-4">
            <div class="mx-2 TexField">
              <mwc-textfield
                required
                validationMessage="El número serial debe tener al menos 8 caracteres"
                label="Nombre"
                id="name"
                minLength="1"
                maxLength="50"
                autoValidate
                .value=${this.firmware.name ?? ''} 
                outlined
                iconTrailing="close"
              >
              </mwc-textfield>
            </div>
            <div class="mx-2 TexField">
            <mwc-textfield
                iconTrailing="close"
                required
                validationMessage="El dueño debe tener al menos 8 caracteres"
                label="briefDescription"
                id="briefDescription"
                .value=${this.firmware.briefDescription ?? ''} 
                minLength="1"
                autoValidate
                outlined
              >
              </mwc-textfield>
            </div>
            <div class="mx-2 TexField">
            <mwc-textfield
                iconTrailing="close"
                required
                validationMessage="El dueño debe tener al menos 8 caracteres"
                label="Descripcion"
                id="description"
                .value=${this.firmware.description ?? ''} 
                minLength="1"
                autoValidate
                outlined
              >
              </mwc-textfield>
            </div>
            <div class="mx-2 TexField">
              <mwc-textfield
                iconTrailing="close"
                required
                validationMessage="El dueño debe tener al menos 8 caracteres"
                label="Developer"
                id="developer"
                .value=${this.firmware.developer ?? ''} 
                minLength="1"
                autoValidate
                outlined
              >
              </mwc-textfield>
            </div>
            <div class="mx-2 TexField">
              <mwc-textfield
                iconTrailing="close"
                required
                validationMessage="El dueño debe tener al menos 8 caracteres"
                label="Requerimientos"
                id="requirements"
                .value=${this.firmware.requirements ?? ''} 
                minLength="1"
                autoValidate
                outlined
              >
              </mwc-textfield>
            </div>
          </div>
          <div class="flex justify-start">
            <button
              @click="${() => router.cambiarSeccion('firmware-list')}"
              id="cancelarCrearAPP"
              class="w-48 rounded-md border-none bg-red-600 text-white py-3 text-xl mt-4 mx-auto hover:bg-red-700 hover:shadow-lg mb-4">
              CANCELAR
            </button> 
            <button
              id="guardarCrearAPP"
              class="w-48 rounded-md border-none bg-blue-600 text-white py-3 text-xl mt-4 mx-auto hover:bg-blue-700 hover:shadow-lg mb-4"
              .disabled=${this.loading}
              @click=${(e: Event) => {
                this.handleSubmit(e);
              }}
            > 
              Guardar Firmware
            </button>

            </div>
          <!-- <div class="my-4 ml-8">
            <p>
              sube un archivo CSV con la cantidad de terminales que desees con los siguientes campos: <a href="javascript:void(0);"">Ejemplo</a>
            </p>
            <span id="alertCampo">Formato de campos incorrectos</span>
          </div> -->
          <div class="w-full flex flex-row my-4 ml-8">
            <!-- <div class="CajonXlm w-3/5" id="displayArea"></div> -->
            <div class="w-2/5	ml-10 CrearTerminal normal-case 	flex flex-col">
              <div class="w-40	 min-w-full">
                <mat-form-field>
                  <!-- <input type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" placeholder="label" value="value"> -->
                </mat-form-field>
                <!-- <form id="csvForm">
                    <div class="mb-3">
                        <label for="csvFile" class="form-label">CSV File</label>
                        <input class="form-control" id="csvFile" type="file" accept=".csv"/>
                    </div>
                    <div class="mb-3">
                        <input class="btn btn-primary" type="submit" value="Submit" />
                    </div>
                  </form> -->
                <!-- <mwc-button  
                  class="rounded-3xl ml-4 mb-2">
                    <p class="normal-case text-xs">Explorar</p>
                  </mwc-button> -->
                <!-- <input
                  id="seleccionar-archivo"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  placeholder="label"
                  type="file"
                  @change=${(ev: InputEvent) => {

                  }}
                  hidden
                />
                <mwc-button
                  class="rounded-3xl ml-4 mb-2"
                  @click=${() => {

                  }}
                  }}
                >
                  <p class="normal-case text-xs">Explorar</p>
                </mwc-button> -->
              </div>
              <!-- <div class="w-40	 min-w-full">
                <mwc-button
                  type="submit"
                  class="rounded-3xl ml-4 mt-4  bg-gray-500 text-gray-700	normal-case	"
                  @click=${() => {
                    let area = this.displayArea;
                    const alertCampo = this.alertCampo;
                    area.innerHTML = ``;
                    area.style.border = '';
                    alertCampo.style.visibility = 'hidden';
                  }}
                >
                  <p class="normal-case	 text-xs">Eliminar</p>
                </mwc-button>
              </div> -->
            </div>
          </div>

          <!-- <div class="w-full flex flex-row my-4 ml-8">
            <div class="w-2/4	"></div>
            <div class="w-2/4 CrearTerminal	flex flex-row">
              <button
                class="rounded-3xl border  border-indigo-500 text-blue-500  CancelarG  hover:shadow-md hover:bg-slate-300 text-xs	"
                @click="${(e: Event) => router.cambiarSeccion('terminal-list')}"
              >
                Cancelar
              </button>

              <mwc-button
                id="crearTerminal"
                class="rounded-3xl ml-4 mb-2  normal-case		"
                @click="${(e: Event) => this.handleSubmit(e)}"
                .disabled=${this.loading}
              >
                <p class="normal-case	text-xs ">Crear terminal</p>
              </mwc-button>
            </div>
          </div> -->
          </div>
        </div>
      </main>
    `;
  }
}
