/* tslint:disable */
/* eslint-disable */
/**
 * TMS Agnostiko
 * Tiene la definición de todos los servicios de TMS a Agnostic (para uso en la web o externo)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { Message } from '../models';
/**
 * DownloadApi - axios parameter creator
 * @export
 */
export const DownloadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Obtener la imagen deseada por el id
         * @param {string} id 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadImage: async (id: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadImage', 'id', id)
            const localVarPath = `/download/image/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Obtener el instalador deseado 
         * @param {string} id 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadInstaller: async (id: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadInstaller', 'id', id)
            const localVarPath = `/download/installer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Obtener el un archivo deseado 
         * @param {string} id 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOther: async (id: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadOther', 'id', id)
            const localVarPath = `/download/other/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DownloadApi - functional programming interface
 * @export
 */
export const DownloadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DownloadApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Obtener la imagen deseada por el id
         * @param {string} id 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadImage(id: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadImage(id, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DownloadApi.downloadImage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Obtener el instalador deseado 
         * @param {string} id 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadInstaller(id: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadInstaller(id, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DownloadApi.downloadInstaller']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Obtener el un archivo deseado 
         * @param {string} id 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadOther(id: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadOther(id, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DownloadApi.downloadOther']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DownloadApi - factory interface
 * @export
 */
export const DownloadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DownloadApiFp(configuration)
    return {
        /**
         * 
         * @summary Obtener la imagen deseada por el id
         * @param {DownloadApiDownloadImageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadImage(requestParameters: DownloadApiDownloadImageRequest, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.downloadImage(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Obtener el instalador deseado 
         * @param {DownloadApiDownloadInstallerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadInstaller(requestParameters: DownloadApiDownloadInstallerRequest, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.downloadInstaller(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Obtener el un archivo deseado 
         * @param {DownloadApiDownloadOtherRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOther(requestParameters: DownloadApiDownloadOtherRequest, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.downloadOther(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for downloadImage operation in DownloadApi.
 * @export
 * @interface DownloadApiDownloadImageRequest
 */
export interface DownloadApiDownloadImageRequest {
    /**
     * 
     * @type {string}
     * @memberof DownloadApiDownloadImage
     */
    readonly id: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof DownloadApiDownloadImage
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for downloadInstaller operation in DownloadApi.
 * @export
 * @interface DownloadApiDownloadInstallerRequest
 */
export interface DownloadApiDownloadInstallerRequest {
    /**
     * 
     * @type {string}
     * @memberof DownloadApiDownloadInstaller
     */
    readonly id: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof DownloadApiDownloadInstaller
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for downloadOther operation in DownloadApi.
 * @export
 * @interface DownloadApiDownloadOtherRequest
 */
export interface DownloadApiDownloadOtherRequest {
    /**
     * 
     * @type {string}
     * @memberof DownloadApiDownloadOther
     */
    readonly id: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof DownloadApiDownloadOther
     */
    readonly acceptLanguage?: string
}

/**
 * DownloadApi - object-oriented interface
 * @export
 * @class DownloadApi
 * @extends {BaseAPI}
 */
export class DownloadApi extends BaseAPI {
    /**
     * 
     * @summary Obtener la imagen deseada por el id
     * @param {DownloadApiDownloadImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadApi
     */
    public downloadImage(requestParameters: DownloadApiDownloadImageRequest, options?: AxiosRequestConfig) {
        return DownloadApiFp(this.configuration).downloadImage(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Obtener el instalador deseado 
     * @param {DownloadApiDownloadInstallerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadApi
     */
    public downloadInstaller(requestParameters: DownloadApiDownloadInstallerRequest, options?: AxiosRequestConfig) {
        return DownloadApiFp(this.configuration).downloadInstaller(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Obtener el un archivo deseado 
     * @param {DownloadApiDownloadOtherRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadApi
     */
    public downloadOther(requestParameters: DownloadApiDownloadOtherRequest, options?: AxiosRequestConfig) {
        return DownloadApiFp(this.configuration).downloadOther(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}

