import { put, takeLatest } from 'redux-saga/effects';
import { GroupApi } from '../../../api';
import { apisConfigurator } from '../../utils/axios';
import { GET_GRUPOS, GRUPOS_RECEIVED } from '../actions/groups';

export function* getGrupos(): Generator {
  const gruposApi = new GroupApi(...apisConfigurator(''));
  console.log('getGrupos');
  const data = yield gruposApi.groupList().then(res => res.data);

  console.log({ data });
  yield put({ type: GRUPOS_RECEIVED, grupos: data });
}

export function* gruposWatcher() {
  yield takeLatest(GET_GRUPOS, getGrupos);
}
