/* tslint:disable */
/* eslint-disable */
/**
 * TMS Agnostiko
 * Tiene la definición de todos los servicios de TMS a Agnostic (para uso en la web o externo)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { Message } from '../models';
// @ts-ignore
import { RequestCommand } from '../models';
/**
 * CommunicationTerminalApi - axios parameter creator
 * @export
 */
export const CommunicationTerminalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary envía un comando a ejecutar al terminal 
         * @param {string} brand 
         * @param {string} serial 
         * @param {RequestCommand} requestCommand 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestCommand: async (brand: string, serial: string, requestCommand: RequestCommand, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brand' is not null or undefined
            assertParamExists('requestCommand', 'brand', brand)
            // verify required parameter 'serial' is not null or undefined
            assertParamExists('requestCommand', 'serial', serial)
            // verify required parameter 'requestCommand' is not null or undefined
            assertParamExists('requestCommand', 'requestCommand', requestCommand)
            const localVarPath = `/communication/{brand}/{serial}`
                .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
                .replace(`{${"serial"}}`, encodeURIComponent(String(serial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary envía un comando a ejecutar al grupo de terminales
         * @param {string} group 
         * @param {RequestCommand} requestCommand 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestCommandGroup: async (group: string, requestCommand: RequestCommand, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'group' is not null or undefined
            assertParamExists('requestCommandGroup', 'group', group)
            // verify required parameter 'requestCommand' is not null or undefined
            assertParamExists('requestCommandGroup', 'requestCommand', requestCommand)
            const localVarPath = `/communication/{group}`
                .replace(`{${"group"}}`, encodeURIComponent(String(group)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommunicationTerminalApi - functional programming interface
 * @export
 */
export const CommunicationTerminalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommunicationTerminalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary envía un comando a ejecutar al terminal 
         * @param {string} brand 
         * @param {string} serial 
         * @param {RequestCommand} requestCommand 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestCommand(brand: string, serial: string, requestCommand: RequestCommand, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestCommand(brand, serial, requestCommand, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CommunicationTerminalApi.requestCommand']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary envía un comando a ejecutar al grupo de terminales
         * @param {string} group 
         * @param {RequestCommand} requestCommand 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestCommandGroup(group: string, requestCommand: RequestCommand, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestCommandGroup(group, requestCommand, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CommunicationTerminalApi.requestCommandGroup']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CommunicationTerminalApi - factory interface
 * @export
 */
export const CommunicationTerminalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommunicationTerminalApiFp(configuration)
    return {
        /**
         * 
         * @summary envía un comando a ejecutar al terminal 
         * @param {CommunicationTerminalApiRequestCommandRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestCommand(requestParameters: CommunicationTerminalApiRequestCommandRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.requestCommand(requestParameters.brand, requestParameters.serial, requestParameters.requestCommand, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary envía un comando a ejecutar al grupo de terminales
         * @param {CommunicationTerminalApiRequestCommandGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestCommandGroup(requestParameters: CommunicationTerminalApiRequestCommandGroupRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.requestCommandGroup(requestParameters.group, requestParameters.requestCommand, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for requestCommand operation in CommunicationTerminalApi.
 * @export
 * @interface CommunicationTerminalApiRequestCommandRequest
 */
export interface CommunicationTerminalApiRequestCommandRequest {
    /**
     * 
     * @type {string}
     * @memberof CommunicationTerminalApiRequestCommand
     */
    readonly brand: string

    /**
     * 
     * @type {string}
     * @memberof CommunicationTerminalApiRequestCommand
     */
    readonly serial: string

    /**
     * 
     * @type {RequestCommand}
     * @memberof CommunicationTerminalApiRequestCommand
     */
    readonly requestCommand: RequestCommand

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof CommunicationTerminalApiRequestCommand
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for requestCommandGroup operation in CommunicationTerminalApi.
 * @export
 * @interface CommunicationTerminalApiRequestCommandGroupRequest
 */
export interface CommunicationTerminalApiRequestCommandGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof CommunicationTerminalApiRequestCommandGroup
     */
    readonly group: string

    /**
     * 
     * @type {RequestCommand}
     * @memberof CommunicationTerminalApiRequestCommandGroup
     */
    readonly requestCommand: RequestCommand

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof CommunicationTerminalApiRequestCommandGroup
     */
    readonly acceptLanguage?: string
}

/**
 * CommunicationTerminalApi - object-oriented interface
 * @export
 * @class CommunicationTerminalApi
 * @extends {BaseAPI}
 */
export class CommunicationTerminalApi extends BaseAPI {
    /**
     * 
     * @summary envía un comando a ejecutar al terminal 
     * @param {CommunicationTerminalApiRequestCommandRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationTerminalApi
     */
    public requestCommand(requestParameters: CommunicationTerminalApiRequestCommandRequest, options?: AxiosRequestConfig) {
        return CommunicationTerminalApiFp(this.configuration).requestCommand(requestParameters.brand, requestParameters.serial, requestParameters.requestCommand, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary envía un comando a ejecutar al grupo de terminales
     * @param {CommunicationTerminalApiRequestCommandGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationTerminalApi
     */
    public requestCommandGroup(requestParameters: CommunicationTerminalApiRequestCommandGroupRequest, options?: AxiosRequestConfig) {
        return CommunicationTerminalApiFp(this.configuration).requestCommandGroup(requestParameters.group, requestParameters.requestCommand, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}

