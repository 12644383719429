/* eslint-disable prettier/prettier */
import { LitElement, html, css, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../styles.css';

@customElement('card-d')
export class CardD extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  @property({ type: Object })
  customSlot?: TemplateResult<1>;

  protected firstUpdated(): void {
    this.classList.add('w-full');
    this.classList.add('h-full');
  }

  render() {
    return html`
      <div class="p-2 min-h-full z-0">
        <div class="w-full min-h-full rounded-3xl p-4 card-c">
          ${this.customSlot}
        </div>
      </div>
    `;
  }
}
