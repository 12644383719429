/* eslint-disable prettier/prettier */

import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '@ui5/webcomponents/dist/Dialog.js';
import '@vaadin/vaadin-select';
import { Select } from '@vaadin/vaadin-select';
import {
  OrganizationApi,
  OrganizationName,
  RequestTerminalUpdate,
  Terminal,
  TerminalApi,
} from '../../../api';
import { clearTerminal } from '../../redux/actions';
import store from '../../redux/store';
import { State } from '../../redux/types';
import { errorNotification } from '../../services/notifications';
import { flatOrganizations } from '../../utils/functions';
import { apisConfigurator } from '../../utils/axios';

@customElement('edit-organization-modal')
export class EditOrganizationModal extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private organizationApi: OrganizationApi;
  private terminalApi: TerminalApi;

  private organizaciones: OrganizationName[] = [];

  constructor() {
    super();

    this.organizationApi = new OrganizationApi(
      ...apisConfigurator('Organización')
    );
    this.terminalApi = new TerminalApi(...apisConfigurator('Organización'));
  }

  @property({ type: Object })
  terminal?: Terminal;

  private get orgSelect(): Select {
    return document.getElementById('organization-select')! as Select;
  }

  private get dialog() {
    return document.getElementById('dialog-edit-organization')! as any;
  }

  protected firstUpdated(): void {}

  async getOrganizaciones() {
    const resOrg = await this.organizationApi.organizationList({});

    this.organizaciones = flatOrganizations(resOrg.data);
    this.requestUpdate('organizaciones');
    console.log(this.organizaciones);
    console.log(this.terminal);
  }

  async open() {
    const { terminal } = store.getState() as State;

    if (!terminal) {
      errorNotification('Error al editar terminal');
      return;
    }

    this.getOrganizaciones();

    this.terminal = terminal;
    this.dialog.open = true;
  }

  close() {
    this.dialog.open = false;
    this.terminal = undefined;
    this.organizaciones = [];
  }

  private async handleSubmit(e: Event) {
    e.preventDefault();

    if (!this.terminal) return;

    const requestTerminalUpdate: RequestTerminalUpdate = {
      organization: this.orgSelect.value,
    };

    console.log(requestTerminalUpdate);

    try {
      const res = await this.terminalApi.terminalUpdate({
        brand: this.terminal.brand,
        serial: this.terminal.serial,
        requestTerminalUpdate,
      });

      store.dispatch(clearTerminal());
      this.close();
    } catch (err: any) {}
  }

  protected render() {
    return html` <ui5-dialog
      id="dialog-edit-organization"
      header-text="Cambiar organización"
      class="w-3/12"
    >
      <form
        class="px-4 flex flex-col items-center"
        @submit=${this.handleSubmit}
      >
        <div class="mx-2 ">
          <!-- <vaadin-select
            required
            .items="${this.organizaciones.map(org => ({
            label: org.name,
            value: org.id,
          }))}"
            id="organization-select"
            class="rounded"
            label="Organización"
            class="mx-2"
          >
          </vaadin-select> -->
          <mwc-select
            outlined
            label="Organización Padre"
            id="organization"
            class="mx-2"
          >
            <mwc-list-item></mwc-list-item>

            ${this.organizaciones.map(
              org =>
                html`<mwc-list-item value=${org.id}>${org.name}</mwc-list-item>`
            )}
          </mwc-select>
        </div>

        <div slot="footer" class="dialog-footer">
          <button
            class="text-red-600 p-4 rounded-md uppercase"
            id="closeDialogButton"
            @click=${() => {
              this.close();
            }}
          >
            Cerrar
          </button>
          <button
            class="text-blue-600 p-4 rounded-md uppercase"
            id="closeDialogButton"
            type="submit"
          >
            Confirmar
          </button>
        </div>
      </form>
    </ui5-dialog>`;
  }
}
