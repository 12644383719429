/* eslint-disable prettier/prettier */
import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { AppApi } from '../../api';
import { connect } from 'pwa-helpers';
import { ConfigConsole } from '../redux/actions';
import store from '../../src/redux/store';
import { State } from '../../src/redux/types';
import { apisConfigurator } from '../../src/utils/axios';

@customElement('app-card2')
export class AppCard2 extends connect(store)(LitElement) {
  private appApi: AppApi;

  constructor() {
    super();
    this.appApi = new AppApi(...apisConfigurator('Aplicaciones'));
  }

  @property()
  app?: any;

  @property()
  band?: any;

  @state()
  App?: any;

  @property()
  index?: any;

  idGet(atributo: string) {
    return document.getElementById(atributo) as HTMLInputElement;
  }

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  async getApp(appId: any) {
    const { configConsole } = store.getState() as State;
    try {
      const res = await this.appApi.getApp({ id: appId });
      const app = res.data;
      const objecto1 = configConsole;
      const objecto2 = { appId, app };
      Object.assign(objecto1, objecto2);
      store.dispatch(
        ConfigConsole({
          appId: objecto1,
          ...configConsole,
        })
      );
    } catch (err) {
      //errorNotification('Error al obtener la app');
      console.log(err);
    } finally {
      //this.loading = false;
    }
  }

  protected firstUpdated(): void {
    //console.log(this.app)
    this.classList.add('w-6/12');
    this.classList.add('p-2');
  }

  render() {
    return html`
      <div
        class="w-full flex rounded-xl SectionDetail CardApp click"
        id="id${this.index}"
        style=${this.band == this.index
          ? 'border: 4px solid #2563eb;'
          : 'border: none;'}
        @click=${() => this.getApp(this.app.id)}
      >
        <div
          class="w-4/12 Img "
          style="background: url(${this.app
            ?.briefPicture}) no-repeat center; background-size: cover; background-color: #d3d3e5;"
        ></div>
        <div class="w-8/12 p-3">
          <div class="flex items-center mb-3">
            <img
              class="rounded-full mr-2"
              width="36"
              src="${this.app?.briefPicture!}" 
            />
            <p class="text-md font-semibold">${this.app?.name}</p>
          </div>
          <p class="mb-2">${this.app?.description}</p>
        </div>
      </div>
    `;
  }
}
