/* eslint-disable prettier/prettier */
import { html, LitElement, render } from 'lit';
import { guard } from 'lit/directives/guard.js';
import { customElement, state } from 'lit/decorators.js';
import Swal from 'sweetalert2';
import { connect } from 'pwa-helpers';
import '@ui5/webcomponents/dist/Tree.js';
import '@ui5/webcomponents/dist/Icon.js';
import '@ui5/webcomponents-icons/dist/it-instance.js';
import '@ui5/webcomponents-icons/dist/group-2.js';
import '@ui5/webcomponents-icons/dist/alert.js';
import '@ui5/webcomponents-icons/dist/cart.js';
import '@ui5/webcomponents-icons/dist/bell.js';
import '@material/mwc-icon';
import '@material/mwc-icon-button';
import '@vaadin/vaadin-grid'; 
import { State } from '../../redux/types';
//import './TerminalList.css';
import { InstalarAppModal } from '../../components/modals/InstalarAppModal';
import '../../components/modals/InstalarAppModal'; 
import { AgregarInstaladorModal } from '../../components/modals/AgregarInstaladorApp';
import '../../components/modals/AgregarInstaladorApp';
import { DescargarInstaladorModal } from '../../components/modals/DescargarInstaladorApp';
import '../../components/modals/DescargarInstaladorApp';
import { AgregarVersionModal } from '../../components/modals/AgregarVersionApp';
import '../../components/modals/AgregarVersionApp';
import {
  Grid,
  GridActiveItemChangedEvent,
  GridItemModel,
} from '@vaadin/vaadin-grid';
import { AxiosResponse } from 'axios';

import '../../components/modals/EditOrganizationModal';
import router from '../../router/router.js';
import {
  CommunicationTerminalApi,
  CommunicationTerminalApiRequestCommandRequest,
  TerminalApi,
  BusinessApi,
  AffiliationApi,
  OrganizationApi,
  BusinessApiListBusinessRequest
} from '../../../api/api.js';
import {
    ConfigurationApi,
    ConfigurationApiCreateConfigurationRequest,
    AffiliationApiListAffiliationRequest,
    AffiliationApiDeleteAffiliationRequest,
    BusinessApiDeleteBusinessRequest,
    AppApi,
    AppList,
    TemplateApi,
    RequestFolioCreate,
    FolioApiCreateFolioRequest,
    FolioApi,
    FolioApiListFolioRequest
  } from '../../../api';
  import { editarApp,verApp } from '../../redux/actions';
import {
  RequestCommandTypeCommandEnum,
  Terminal,
  TerminalList,
} from '../../../api/index.js';
import store from '../../redux/store.js';
import { ConfigConsole,PageFolio } from '../../redux/actions';
import { editarTerminal } from '../../redux/actions/index.js';
import { PushConfigModal } from '../../components/modals/PushConfigurationsDialog';
import '../../components/modals/PushConfigurationsDialog';
import { UbicacionTerminalMapa } from '../../components/modals/UbicacionTerminalMapa';
import { CrearFolio } from '../../components/modals/CrearFolio';
import '../../components/modals/CrearFolio';
import { CrearAfiliacion } from '../../components/modals/CrearAfiliacion';
import '../../components/modals/CrearAfiliacion';
//import { CambiarMaster } from '../../components/modals/CambiarMaster';
import '../../components/modals/ListadoApp';
import { ListadoFolio } from '../../components/modals/ListadoFolio';
import '../../components/modals/ListadoFolio';
import { ConfigTerminalFolio } from '../../components/ConfigTerminalFolio';
import '../../components/ConfigTerminalFolio';
import '../../components/modals/UbicacionTerminalMapa';
import { EditOrganizationModal } from '../../components/modals/EditOrganizationModal.js';
import { apisConfigurator } from '../../utils/axios';
import { EnviarMensajeModal } from '../../components/modals/EnviarMensajeModal';
import '../../components/modals/EnviarMensajeModal';
import { HistorialComandosModal } from '../../components/modals/HistorialComandosModal';
import '../../components/modals/HistorialComandosModal';
import { EditarFolio } from '../../components/modals/EditarFolio';
import '../../components/modals/EditarFolio';

import {
  errorNotification,
  succesNotification,
} from '../../services/notifications';
import { getApps } from '../../redux/actions';
import { getTerminals } from '../../redux/actions/terminals';
import { Unsubscribe } from '@reduxjs/toolkit';

@customElement('razon-social-list-page')
export class RazonSocialList extends connect(store)(LitElement) {
    private appApi: AppApi;
  private terminalApi: TerminalApi;
  private afiliacionesApi: AffiliationApi;
  private communicationApi: CommunicationTerminalApi;
  private organizationApi!: OrganizationApi;
  private templateApi: TemplateApi;
  private folio: FolioApi;
  private businessApi: BusinessApi;

  private storeUnsuscriber?: Unsubscribe;

  get enviarMensajeModal() {
    return document.getElementById('enviarMensajeModal') as EnviarMensajeModal;
  }

  get listadoFolio() {
    return document.getElementById('listadoFolio') as ListadoFolio;
  }

  get editarFolio() {
    return document.getElementById('editarFolio') as EditarFolio;
  }

  get instalarAppModal() {
    return document.getElementById('instalarAppModal')! as InstalarAppModal;
  }


  get crearAfiliacion() {
    return document.getElementById('crearAfiliacion') as CrearAfiliacion;
  }


  get agregarInstaladorModal() {
    return document.getElementById(
      'agregarInstaladorModal'
    )! as AgregarInstaladorModal;
  }

  get appSelected() {
    return document.getElementById('appSelect-business') as any;
  }

  get descargarInstaladorModal() {
    return document.getElementById(
      'descargarInstaladorModal'
    )! as DescargarInstaladorModal;
  }

  get agregarVersionModal() {
    return document.getElementById(
      'agregarVersionModal'
    )! as AgregarVersionModal;
  }


  get historialComandosModal() {
    return document.getElementById(
      'historialComandosModal'
    ) as HistorialComandosModal;
  }

  @state()
  private terminals: TerminalList[] = [];

  private constructor() {
    super();
    this.terminalApi = new TerminalApi(...apisConfigurator('Terminales'));
    this.afiliacionesApi = new AffiliationApi(...apisConfigurator('Afiliaciones'));
    this.communicationApi = new CommunicationTerminalApi(
      ...apisConfigurator('Terminales')
    );
    this.templateApi = new TemplateApi(...apisConfigurator('Templates'));
    this.organizationApi = new OrganizationApi(
      ...apisConfigurator('Organización')
    );
    this.appApi = new AppApi(...apisConfigurator('Aplicaciones'));
    this.folio = new FolioApi(
      ...apisConfigurator('Folio') 
    );
    this.businessApi = new BusinessApi(...apisConfigurator('Crear Razon Social'));
  }

  async stateChanged(state: any) {

    if(state.configConsole.band && this.band){
      //console.log("configuration",this.appId,this.app,state.configConsole.crearFolio)
      await this.getApp(this.appId,state.configConsole.crearFolio)
      /*console.log("configuration",this.appId,this.app,state.configConsole.crearFolio)
      this.listadoFolio.open(this.app)
      this.band = false;*/
    }
  }

  @state()
  private apps: AppList[] = [];

  @state()
  termi?: Terminal;

  @state()
  templatesInfo:any = [];

  @state()
  template:any = 'Crear Terminal';

  @state()
  afiliacionesList?: any;

  @state()
  businessList?: any;

  @state()
  app?: any;

  @state()
  band?: any = false;

  @state()
  idApp?: any;

  @state()
  appId?: any;

  @state()
  afiliacion?: any;

  @state()
  versions?: any;

  @state()
  NameBtn = 'Crear Terminal';

  @state()
  detail = false;

  @state()
  detailCrear = false;

  @state()
  detail2 = false;

  @state()
  folioList:any = [
    {name:"1-enterprise1"},
    {name:"2-enterprise2"},
  ];

  @state()
  organizacion = '';

  @state()
  private detailsOpenedItem:any = "";

  @state()
  loader = true;

  @state()
  brand = '';

  @state()
  serial = '';

  get editOrganizationModal() {
    return document.getElementById(
      'edit-organization-modal'
    ) as EditOrganizationModal;
  }

  private handleVersionCreated(ev: CustomEvent) {
    const { detail } = ev;
    if (this.app!.version) {
      this.app!.version.push(detail);
    } else {
      this.app!.version = [detail];
    }
    this.requestUpdate();
  }

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  disconnectedCallback(): void {
    this.storeUnsuscriber && this.storeUnsuscriber();
  }

  async updated(){
    if (localStorage.getItem("businessState")) {
      const optionValue:any = localStorage.getItem("businessState");
      const appSelect = this.appSelected;
      const optionElement = appSelect.querySelector(`option[value="${optionValue}"]`);
      optionElement.selected = true;
      this.detailCrear = true;
    } 
  }

  protected async firstUpdated(){
    this.getTerminals();
    this.getApps();

    if (localStorage.getItem("businessState")) {
      console.log("El nombre es", localStorage.getItem("businessState"));
      const appSelect = this.appSelected;
      const optionValue:any = localStorage.getItem("businessState");
      this.appId = optionValue;
      this.ObtenerFolios(optionValue)
      this.ObtenerRazonSocial(optionValue)
      this.detailCrear = true;

    } 
  }

  private async handleDeleteAffiliation() {
    const businessApiDeleteBusinessRequest:BusinessApiDeleteBusinessRequest = {
      appId:this.appId,
      businessId:this.afiliacion.businessId
    }
    const respuestaSwal = await Swal.fire({
      title: 'Desea eliminar la Razon Social?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar'
    }).then(async(result) => {
      if (result.isConfirmed) {
        console.log(businessApiDeleteBusinessRequest)
        const res = await this.businessApi.deleteBusiness(businessApiDeleteBusinessRequest);
        console.log("RES",res)
        Swal.fire(
          'Eliminado!',
          'Razon Social borrada.', 
          'success'
        )
        location.reload();
      }
    })
  }
 
  async getTerminals() {
    store.dispatch(getTerminals());
    this.storeUnsuscriber = store.subscribe(() => {
      const { list, loading, listApps } = store.getState();
      console.log(loading);
      console.log({ list });
      this.terminals = list as TerminalList[];
      this.loader = loading;
    });
  }

  async ObtenerAfiliaciones(appId: any,businessId:any) {
    let data:AffiliationApiListAffiliationRequest = {
      appId: appId,
    } 
    console.log("appId",appId)
    await this.afiliacionesApi
      .listAffiliation(data)
      .then(response => response.data)
      .then(data => {
        let dataBusiness:any = []
        console.log(data)
        data.map((item:any,index:number) => {
          if(businessId==item.businessId){
            dataBusiness.push(item)
          }
        })
        this.afiliacionesList = dataBusiness; 
        if(this.afiliacionesList.length)
        /*if (this.detail2 === false) {
          this.detail2 = true; 
        } else if (this.detail2 === true) {
          this.detail2 = false; 
        }*/
        this.detail2 = true; 
      else{
        this.detail2 = false; 
      }
        /*data.map((item,index) => {
          item.affiliationId = `SOLO PAGO SA DE CV${index}`;
        })*/
        //this.afiliacionesList.nombreComercio = "solo pago comercio";
      });
  }

  async ObtenerRazonSocial(appId: any) {
    let data:BusinessApiListBusinessRequest = {
      appId: appId,
    } 
    await this.businessApi
      .listBusiness(data)
      .then(response => response.data)
      .then(data => {
        console.log(data)
        /*data.map((item,index) => {
          item.affiliationId = `SOLO PAGO SA DE CV${index}`;
        })*/
        this.businessList = data;
        //this.afiliacionesList.nombreComercio = "solo pago comercio";
      });
  }

  async ObtenerFolios(appId: any) {
    let data:FolioApiListFolioRequest = {
      appId: appId,
    }
    await this.folio
      .listFolio(data) 
      .then(response => response.data)
      .then(data => {
        //this.folioList = data;
        console.log("folios",data)
      });
  }

  async getApp(appId: any,folio:any) {
    this.templatesInfo = []
    const { configConsole } = store.getState() as State;
    console.log(configConsole)
    try { 
      const res = await this.appApi.getApp({ id: appId });
      const app = res.data;
      this.app = res.data;
      const objecto1 = configConsole;
      const objecto2 = { appId, app,folio };
      console.log("wladimir")
      configConsole.page=2;
      Object.assign(objecto1, objecto2);
      console.log("wladimir2")
      store.dispatch(
        ConfigConsole({
          appId: objecto1,
          ...configConsole,
        })
      );
     this.template = app.templates;
      this.template.forEach(async (templateId:string) => {
        const templateInfo = await this.templateApi.getTemplate({id:templateId});
        console.log("PROBANDO TEMPLATE",templateInfo.data)
        this.templatesInfo.push({
          id:templateId,
          schema:templateInfo.data
        })

      });

    } catch (err) {
      errorNotification('Error al obtener la app');
    } finally {    
    }
  }

  private actionRenderer = (root: HTMLElement, _: any, item: any) => {
    render(
      html`
        <mwc-icon-button
          @click=${() => router.cambiarSeccion('terminal')}
          icon="visibility"
        ></mwc-icon-button>
        <mwc-icon-button
          icon="edit"
          @click="${() => {
            store.dispatch(editarTerminal(item.item));
            router.cambiarSeccion('edit-terminal');
          }}"
        ></mwc-icon-button>
        <mwc-icon-button
          icon="corporate_fare"
          @click="${() => {
            store.dispatch(editarTerminal(item.item));
            this.editOrganizationModal.open();
          }}"
        ></mwc-icon-button>
        <mwc-icon-button
          icon="delete"
          @click="${() => {

          }}"
        ></mwc-icon-button>
        <mwc-icon-button icon="more_vert"></mwc-icon-button>
      `,
      root
    );
  };

  edit(e: any) {
    store.dispatch(editarTerminal(e));
    router.cambiarSeccion('edit-terminal');
  }

  get CrearFolio() {
    return document.getElementById('crearFolio')! as CrearFolio;
  }

  get pushConfigModal() {
    return document.getElementById('pushConfigModal')! as PushConfigModal;
  }


  async getApps() {
    store.dispatch(getApps());
    const { configConsole } = store.getState() as State;
    this.storeUnsuscriber = store.subscribe(() => {
      const { list, loading, listApps } = store.getState();
      console.log(loading); 
      console.log("list",{ listApps });
      this.apps = listApps as AppList[];
      const apps = listApps as AppList[];
    });
  }


  render() {
    return html`
      <main
        class="w-full h-full FondoColor flex  ${!this.loader
          ? ''
          : 'items-center justify-center'}"
      >
        ${this.loader !== true
          ? html`
              <section
                class="w-3/4 relative flex flex-wrap p-4 SectionList rounded-l-lg"
              >
                <select name="cars" class="w-[210px] mx-1 mb-3 bg-white border border-gray-300 rounded-md text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white" id="appSelect-business"
                @change=${(ev: CustomEvent) => {

                  this.appId = this.appSelected.value;
                  console.log("APP",this.appId)
                  localStorage.removeItem('businessState');
                  localStorage.setItem('businessState', this.appId);
                  localStorage.removeItem('appId');
                  localStorage.setItem('appId', this.appId);
                  this.ObtenerFolios(this.appSelected.value)
                  this.ObtenerRazonSocial(this.appSelected.value)
                  if (this.detailCrear === false) {
                    this.detailCrear = true; 
                  } else if (this.detail === true) {
                    this.detailCrear = false; 
                  }

                }}>
                <option disabled selected>Aplicaciones</option>
                    ${this.apps.map((app:any,index:number)=> html`
                        <option value="${app.id}">${app.name}</option>
                    `)}
                </select> 
                <!--<div class="mx-2 TexField">
                  <custom-select
                    label="Aplicaciones"
                    id="apps"
                    .options=${this.apps.map(m => ({
                      label: m.name,
                      value: m.id,
                    }))}
                    @change=${(ev: CustomEvent) => {
                      //this.brandSelected = ev.detail;
                      //this.bandBatch= false;
                      this.appId = ev.detail;
                      console.log(ev.detail)
                      //this.getApp(this.appSelected.value,"")
                      this.ObtenerFolios(ev.detail)
                      this.ObtenerRazonSocial(ev.detail)
                    }}
                  ></custom-select>
                </div> -->
                <div class="mx-2 TexField">
                  <!-- <input
                    type="search"
                    placeholder="Buscar"
                    id="gsearch"
                    style=":hover {background: white; border: 1px solid gainsboro;}"
                    class="w-full border-0 rounded-xl bg-gray-300 h-11"
                    name="gsearch"
                    @keyup="${async(ev: Event) => {
                      //this.handleChange(ev)
                    }}"
                  /> -->
                  <div class="flex items-center border-2 border-gray-200 rounded-full">
                    <mwc-icon-button icon="search" class="mr-3"></mwc-icon-button>
                    <input type="text" style=":hover {background: white; border: 1px solid gainsboro;}" class="px-4 bg-gray-300 py-2 w-full rounded-full focus:outline-none" placeholder="Buscar...">
                  </div>
                </div>
                <vaadin-grid
                  class="w-screen h-full"
                  .items="${this.businessList}"
                  .detailsOpenedItems="${this.detailsOpenedItem}"
                  @active-item-changed="${(
                    e: GridActiveItemChangedEvent<BusinessApi>
                  ) => {  
                    this.afiliacion = e.detail.value; 
                    console.log(this.afiliacion)
                    localStorage.removeItem('businessId');
                    localStorage.setItem('businessId', this.afiliacion.businessId);
                    this.ObtenerAfiliaciones(this.afiliacion.appId,this.afiliacion.businessId)
                    const afiliacionString = JSON.stringify(this.afiliacion);
                    localStorage.setItem('razonSocial', afiliacionString);
                    console.log(e.detail.value)
                    this.detailsOpenedItem =[e.detail.value];
                    if (!e.detail.value) return false;
                    //this.appId = e.detail.value.affiliationId;
                    //this.getApp(e.detail.value.id)
                      if (this.detail === false) {
                        this.detail = true; 
                      } else if (this.detail === true) {
                        this.detail = false; 
                      }

                  }}"
                  .rowDetailsRenderer="${guard(
                    [],
                    () =>
                      (
                        root: HTMLElement,
                        _: Grid,
                        model: GridItemModel<BusinessApi>
                      ) => {

                        render(
                          html`<vaadin-form-layout
                            .responsiveSteps=" ${[
                              { minWidth: '0', columns: 3 },
                            ]}"
                          > 
                            <div
                            style="background-color:#F0EFFF;"
                              class="w-full h-14 flex items-center mt-5 justify-center flex-row "
                            >
                               <div
                                class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                                @click=${() => {
                                  router.cambiarSeccion('edit-razon-social-page');
                                }}
                              >  
                                <div>  
                                  <mwc-icon-button icon="edit">
                                  </mwc-icon-button>
                                </div>
                                <div>
                                  <p class="text-center">Editar</p>
                                </div>
                              </div>  
                              <div
                                class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                                @click=${() => {
                                  console.log(this.afiliacion)
                                  this.crearAfiliacion.open(this.appId,this.afiliacion);
                                  //this.band = true;  
                                }}
                              >  
                                <div> 
                                  <mwc-icon-button icon="add">
                                  </mwc-icon-button>
                                </div> 
                                <div>
                                  <p class="text-center">Crear Afiliacion</p>
                                </div>
                              <!-- </div>  
                              <div
                                class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                                @click=${() => {
                                  this.agregarInstaladorModal.open(this.app);
                                }}
                              >  
                                <div> 
                                  <mwc-icon-button icon="content_copy">
                                  </mwc-icon-button>
                                </div>
                                <div>
                                  <p class="text-center">Copiar Folio</p>
                                </div>
                              </div>  -->
                              <!-- <div
                                class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                                @click=${() => {

                                }}
                              >  
                                <div> 
                                  <mwc-icon-button icon="visibility">
                                  </mwc-icon-button>
                                </div>
                                <div>
                                  <p class="text-center">Ver folio</p>
                                </div>
                              </div>    -->
                              <!-- <div
                                class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                                @click=${() => {

                                }}
                              >  
                                <div> 
                                  <mwc-icon-button icon="ios_share">
                                  </mwc-icon-button>
                                </div>
                                <div>
                                  <p class="text-center">Exportar folio</p>
                                </div>
                              </div>                             -->
                            </div>
                          </vaadin-form-layout>`,
                          root
                        );
                      }
                  )}"
                > 
                  <vaadin-grid-selection-column></vaadin-grid-selection-column>
                  <vaadin-grid-filter-column
                  header="Razon Social"
                    path="businessId"
                  ></vaadin-grid-filter-column>
                  <vaadin-grid-filter-column
                    header="Nombre Contacto"
                    path="contactName"
                  ></vaadin-grid-filter-column>
                  <vaadin-grid-filter-column
                  header="RFC"
                  path="taxNumber"
                ></vaadin-grid-filter-column>
                </vaadin-grid>
                <mwc-icon-button 
                  icon="autorenew"
                  class="absolute top-0 right-0"
                  @click=${() => {
                    this.getApps();
                  }}
                ></mwc-icon-button>
                <edit-organization-modal
                  id="edit-organization-modal"
                ></edit-organization-modal>
              </section>
              <section class="w-1/4  SectionDetail justify-center scroll">
              ${this.detailCrear === true 
                ? html`
                  <div class="w-full flex justify-center">
                    <mwc-button
                      raised
                      class="rounded w-4/5 mx-auto mt-4 mb-2"
                      style="--mdc-theme-primary: #2E3EAE; --mdc-theme-on-primary: white;"
                      @click="${() => {
                        router.cambiarSeccion('create-razon-social-page');
                      }}"
                    >
                    <mwc-icon>save</mwc-icon>
                      Crear Razon Social
                    </mwc-button>
                  </div>`
                  : html``
                  }
                  ${this.detailCrear === true 
                    ? html`
                    <div class="w-full flex justify-center">
                      <mwc-button
                        raised 
                        class="rounded w-4/5 mx-auto mt-4 mb-2"
                        style="--mdc-theme-primary: tomato; --mdc-theme-on-primary: white"
                        @click="${() => {
                          router.cambiarSeccion('carga-masiva-business-page');
                        }}"
                      >
                      <mwc-icon>upload</mwc-icon>
                        carga masiva
                      </mwc-button>
                    </div>
                    
                  `
                  : html``}
                  ${this.detail === true 
                    ? html`
                    <div class="w-full flex justify-center">
                      <mwc-button
                        raised 
                        class="rounded w-4/5 mx-auto mt-4 mb-2"
                        style="--mdc-theme-primary: tomato; --mdc-theme-on-primary: white"
                        @click="${() => {
                          this.handleDeleteAffiliation()
                          //router.cambiarSeccion('create-afiliation-page');
                        }}"
                      >
                      <mwc-icon>delete</mwc-icon>
                        Eliminar Razón Social
                      </mwc-button>
                    </div>                    
                  `
                  : html``}
                <div class=" pt-3 flex items-center ">
              <p class="text-base	text-blue-500	ml-3 ">Afiliaciones</p>
            </div>
            ${this.detail2 === true 
                  ? html`
              <div class="w-full py-3 border-b-2 border-grey-100 px-4">
              <div class="pt-1 ml-5">
              <div class="pt-1 ml-5">
              <ul class="list-disc pl-2">
              ${this.afiliacionesList.map(
                (folio:any, index:number) => html`
                  <li
                    class="flex items-center gap-2 ml-2"
                  >
                  <p class="text-slate-500 hover:text-blue-600 text-lg "></p>
                    <p class="text-slate-500 hover:text-blue-600 text-lg ">${folio.affiliationId}</p>
                    <mwc-icon-button
                      class="ml-1"
                      icon="edit"
                      ariaLabel="Actualizar"  
                      @click=${async () => {
                        //await this.getApp(this.app,folio)
                        //this.listadoFolio.open(this.app)
                        //this.editarFolio.open(folio) 
                        const afiliacionString = JSON.stringify(folio);
                        localStorage.setItem('afiliacion', afiliacionString);
                        router.cambiarSeccion('edit-afiliation-page');
                      }}
                    ></mwc-icon-button>

                  </li>
                `
              )}
            </ul>
          </div>

              </div>
              </div>
              `
                  : html``}
              </section>
            `
          : html`
              <mwc-circular-progress
                indeterminate
                density=${20}
              ></mwc-circular-progress>
            `}
      </main>

      <ubicacion-terminal-mapa
        id="ubicacionTerminalMapa"
      ></ubicacion-terminal-mapa>
      <listado-folio id="listadoFolio"></listado-folio>
      <editar-folio id="editarFolio"></editar-folio>
      <crear-afiliacion id="crearAfiliacion"></crear-afiliacion>
      <instalar-app-modal id="instalarAppModal"></instalar-app-modal>
      <push-config-modal id="pushConfigModal"></push-config-modal>
      <enviar-mensaje-modal id="enviarMensajeModal"></enviar-mensaje-modal>
      <crear-folio id="crearFolio"></crear-folio>
      <historial-comandos-modal
        id="historialComandosModal"
      ></historial-comandos-modal> 
      <agregar-instalador-modal
        id="agregarInstaladorModal"
      ></agregar-instalador-modal>
      <descargar-instalador-modal
        id="descargarInstaladorModal"
      ></descargar-instalador-modal>
      <agregar-version-modal
        id="agregarVersionModal"
        @version-created=${this.handleVersionCreated}
      ></agregar-version-modal>
    `;
  }
}
