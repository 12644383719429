import { put, takeLatest } from 'redux-saga/effects';
import { FirmwareApi } from '../../../api';
import { apisConfigurator } from '../../utils/axios';
import { GET_FIRMWARES, FIRMWARES_RECEIVED } from '../actions/firmware';
import { getCookie } from './../../services/cookie';
  
export function* getFirmwares(): Generator {
  try { 
    const userCookie:any = getCookie('tms_user');
    console.log("COOKIES",JSON.parse(userCookie));
    const firmwareApi = new FirmwareApi(...apisConfigurator(''));
    console.log('getTerminals');
    const data = yield firmwareApi.listFirmware().then(res => res.data);
  
    console.log({ data });
    yield put({ type: FIRMWARES_RECEIVED, firmwares: data });
  } catch (error) {
    console.log(error)
  }

}

export function* firmwaresWatcher() {
  yield takeLatest(GET_FIRMWARES, getFirmwares);
}
