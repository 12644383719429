/* tslint:disable */
/* eslint-disable */
/**
 * TMS Agnostiko
 * Tiene la definición de todos los servicios de TMS a Agnostic (para uso en la web o externo)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { Message } from '../models';
// @ts-ignore
import { ResponseQueryStatistic } from '../models';
/**
 * TerminalStatisticApi - axios parameter creator
 * @export
 */
export const TerminalStatisticApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Consultar estadístico de terminales por marca
         * @param {string} idData 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerminalXBrand: async (idData: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idData' is not null or undefined
            assertParamExists('getTerminalXBrand', 'idData', idData)
            const localVarPath = `/statistic/{idData}`
                .replace(`{${"idData"}}`, encodeURIComponent(String(idData)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TerminalStatisticApi - functional programming interface
 * @export
 */
export const TerminalStatisticApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TerminalStatisticApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Consultar estadístico de terminales por marca
         * @param {string} idData 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTerminalXBrand(idData: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseQueryStatistic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTerminalXBrand(idData, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TerminalStatisticApi.getTerminalXBrand']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TerminalStatisticApi - factory interface
 * @export
 */
export const TerminalStatisticApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TerminalStatisticApiFp(configuration)
    return {
        /**
         * 
         * @summary Consultar estadístico de terminales por marca
         * @param {TerminalStatisticApiGetTerminalXBrandRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerminalXBrand(requestParameters: TerminalStatisticApiGetTerminalXBrandRequest, options?: AxiosRequestConfig): AxiosPromise<ResponseQueryStatistic> {
            return localVarFp.getTerminalXBrand(requestParameters.idData, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getTerminalXBrand operation in TerminalStatisticApi.
 * @export
 * @interface TerminalStatisticApiGetTerminalXBrandRequest
 */
export interface TerminalStatisticApiGetTerminalXBrandRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalStatisticApiGetTerminalXBrand
     */
    readonly idData: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof TerminalStatisticApiGetTerminalXBrand
     */
    readonly acceptLanguage?: string
}

/**
 * TerminalStatisticApi - object-oriented interface
 * @export
 * @class TerminalStatisticApi
 * @extends {BaseAPI}
 */
export class TerminalStatisticApi extends BaseAPI {
    /**
     * 
     * @summary Consultar estadístico de terminales por marca
     * @param {TerminalStatisticApiGetTerminalXBrandRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalStatisticApi
     */
    public getTerminalXBrand(requestParameters: TerminalStatisticApiGetTerminalXBrandRequest, options?: AxiosRequestConfig) {
        return TerminalStatisticApiFp(this.configuration).getTerminalXBrand(requestParameters.idData, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}

