/* tslint:disable */
/* eslint-disable */
/**
 * TMS Agnostiko
 * Tiene la definición de todos los servicios de TMS a Agnostic (para uso en la web o externo)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ConfigurationInfo1 } from '../models';
// @ts-ignore
import { ElementCopySavedConfiguration } from '../models';
// @ts-ignore
import { Message } from '../models';
// @ts-ignore
import { RequestCopyConfiguration } from '../models';
// @ts-ignore
import { RequestSavedConfigurationCreate } from '../models';
// @ts-ignore
import { RequestSavedConfigurationUpdate } from '../models';
// @ts-ignore
import { ResponseCreateSavedConfiguration } from '../models';
// @ts-ignore
import { ResponseSavedConfigurationGet } from '../models';
/**
 * SavedConfigurationApi - axios parameter creator
 * @export
 */
export const SavedConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary copia una configuración guarda a todos los terminales de un grupo
         * @param {RequestCopyConfiguration} requestCopyConfiguration 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copySavedConfiguration: async (requestCopyConfiguration: RequestCopyConfiguration, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestCopyConfiguration' is not null or undefined
            assertParamExists('copySavedConfiguration', 'requestCopyConfiguration', requestCopyConfiguration)
            const localVarPath = `/saved/configuration/group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestCopyConfiguration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Realiza la creación de un registro de configuración guardo
         * @param {RequestSavedConfigurationCreate} requestSavedConfigurationCreate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSavedConfiguration: async (requestSavedConfigurationCreate: RequestSavedConfigurationCreate, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestSavedConfigurationCreate' is not null or undefined
            assertParamExists('createSavedConfiguration', 'requestSavedConfigurationCreate', requestSavedConfigurationCreate)
            const localVarPath = `/saved/configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestSavedConfigurationCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary elimina una configuración guarda
         * @param {string} id 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSavedConfiguration: async (id: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSavedConfiguration', 'id', id)
            const localVarPath = `/saved/configuration/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Obtener la configuración guarda
         * @param {string} id 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSavedConfiguration: async (id: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSavedConfiguration', 'id', id)
            const localVarPath = `/saved/configuration/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Obtener la lista de configuraciones guarda
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {string} [templateId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSavedConfiguration: async (acceptLanguage?: string, templateId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/saved/configuration/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (templateId !== undefined) {
                localVarQueryParameter['templateId'] = templateId;
            }

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary actualiza una configuración guarda
         * @param {string} id 
         * @param {RequestSavedConfigurationUpdate} requestSavedConfigurationUpdate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSavedConfiguration: async (id: string, requestSavedConfigurationUpdate: RequestSavedConfigurationUpdate, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSavedConfiguration', 'id', id)
            // verify required parameter 'requestSavedConfigurationUpdate' is not null or undefined
            assertParamExists('updateSavedConfiguration', 'requestSavedConfigurationUpdate', requestSavedConfigurationUpdate)
            const localVarPath = `/saved/configuration/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestSavedConfigurationUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SavedConfigurationApi - functional programming interface
 * @export
 */
export const SavedConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SavedConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary copia una configuración guarda a todos los terminales de un grupo
         * @param {RequestCopyConfiguration} requestCopyConfiguration 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copySavedConfiguration(requestCopyConfiguration: RequestCopyConfiguration, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ElementCopySavedConfiguration>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copySavedConfiguration(requestCopyConfiguration, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SavedConfigurationApi.copySavedConfiguration']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Realiza la creación de un registro de configuración guardo
         * @param {RequestSavedConfigurationCreate} requestSavedConfigurationCreate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSavedConfiguration(requestSavedConfigurationCreate: RequestSavedConfigurationCreate, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCreateSavedConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSavedConfiguration(requestSavedConfigurationCreate, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SavedConfigurationApi.createSavedConfiguration']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary elimina una configuración guarda
         * @param {string} id 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSavedConfiguration(id: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSavedConfiguration(id, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SavedConfigurationApi.deleteSavedConfiguration']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Obtener la configuración guarda
         * @param {string} id 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSavedConfiguration(id: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseSavedConfigurationGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSavedConfiguration(id, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SavedConfigurationApi.getSavedConfiguration']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Obtener la lista de configuraciones guarda
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {string} [templateId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSavedConfiguration(acceptLanguage?: string, templateId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConfigurationInfo1>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSavedConfiguration(acceptLanguage, templateId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SavedConfigurationApi.listSavedConfiguration']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary actualiza una configuración guarda
         * @param {string} id 
         * @param {RequestSavedConfigurationUpdate} requestSavedConfigurationUpdate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSavedConfiguration(id: string, requestSavedConfigurationUpdate: RequestSavedConfigurationUpdate, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSavedConfiguration(id, requestSavedConfigurationUpdate, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SavedConfigurationApi.updateSavedConfiguration']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * SavedConfigurationApi - factory interface
 * @export
 */
export const SavedConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SavedConfigurationApiFp(configuration)
    return {
        /**
         * 
         * @summary copia una configuración guarda a todos los terminales de un grupo
         * @param {SavedConfigurationApiCopySavedConfigurationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copySavedConfiguration(requestParameters: SavedConfigurationApiCopySavedConfigurationRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ElementCopySavedConfiguration>> {
            return localVarFp.copySavedConfiguration(requestParameters.requestCopyConfiguration, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Realiza la creación de un registro de configuración guardo
         * @param {SavedConfigurationApiCreateSavedConfigurationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSavedConfiguration(requestParameters: SavedConfigurationApiCreateSavedConfigurationRequest, options?: AxiosRequestConfig): AxiosPromise<ResponseCreateSavedConfiguration> {
            return localVarFp.createSavedConfiguration(requestParameters.requestSavedConfigurationCreate, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary elimina una configuración guarda
         * @param {SavedConfigurationApiDeleteSavedConfigurationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSavedConfiguration(requestParameters: SavedConfigurationApiDeleteSavedConfigurationRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.deleteSavedConfiguration(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Obtener la configuración guarda
         * @param {SavedConfigurationApiGetSavedConfigurationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSavedConfiguration(requestParameters: SavedConfigurationApiGetSavedConfigurationRequest, options?: AxiosRequestConfig): AxiosPromise<ResponseSavedConfigurationGet> {
            return localVarFp.getSavedConfiguration(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Obtener la lista de configuraciones guarda
         * @param {SavedConfigurationApiListSavedConfigurationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSavedConfiguration(requestParameters: SavedConfigurationApiListSavedConfigurationRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<ConfigurationInfo1>> {
            return localVarFp.listSavedConfiguration(requestParameters.acceptLanguage, requestParameters.templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary actualiza una configuración guarda
         * @param {SavedConfigurationApiUpdateSavedConfigurationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSavedConfiguration(requestParameters: SavedConfigurationApiUpdateSavedConfigurationRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.updateSavedConfiguration(requestParameters.id, requestParameters.requestSavedConfigurationUpdate, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for copySavedConfiguration operation in SavedConfigurationApi.
 * @export
 * @interface SavedConfigurationApiCopySavedConfigurationRequest
 */
export interface SavedConfigurationApiCopySavedConfigurationRequest {
    /**
     * 
     * @type {RequestCopyConfiguration}
     * @memberof SavedConfigurationApiCopySavedConfiguration
     */
    readonly requestCopyConfiguration: RequestCopyConfiguration

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof SavedConfigurationApiCopySavedConfiguration
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for createSavedConfiguration operation in SavedConfigurationApi.
 * @export
 * @interface SavedConfigurationApiCreateSavedConfigurationRequest
 */
export interface SavedConfigurationApiCreateSavedConfigurationRequest {
    /**
     * 
     * @type {RequestSavedConfigurationCreate}
     * @memberof SavedConfigurationApiCreateSavedConfiguration
     */
    readonly requestSavedConfigurationCreate: RequestSavedConfigurationCreate

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof SavedConfigurationApiCreateSavedConfiguration
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteSavedConfiguration operation in SavedConfigurationApi.
 * @export
 * @interface SavedConfigurationApiDeleteSavedConfigurationRequest
 */
export interface SavedConfigurationApiDeleteSavedConfigurationRequest {
    /**
     * 
     * @type {string}
     * @memberof SavedConfigurationApiDeleteSavedConfiguration
     */
    readonly id: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof SavedConfigurationApiDeleteSavedConfiguration
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for getSavedConfiguration operation in SavedConfigurationApi.
 * @export
 * @interface SavedConfigurationApiGetSavedConfigurationRequest
 */
export interface SavedConfigurationApiGetSavedConfigurationRequest {
    /**
     * 
     * @type {string}
     * @memberof SavedConfigurationApiGetSavedConfiguration
     */
    readonly id: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof SavedConfigurationApiGetSavedConfiguration
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for listSavedConfiguration operation in SavedConfigurationApi.
 * @export
 * @interface SavedConfigurationApiListSavedConfigurationRequest
 */
export interface SavedConfigurationApiListSavedConfigurationRequest {
    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof SavedConfigurationApiListSavedConfiguration
     */
    readonly acceptLanguage?: string

    /**
     * 
     * @type {string}
     * @memberof SavedConfigurationApiListSavedConfiguration
     */
    readonly templateId?: string
}

/**
 * Request parameters for updateSavedConfiguration operation in SavedConfigurationApi.
 * @export
 * @interface SavedConfigurationApiUpdateSavedConfigurationRequest
 */
export interface SavedConfigurationApiUpdateSavedConfigurationRequest {
    /**
     * 
     * @type {string}
     * @memberof SavedConfigurationApiUpdateSavedConfiguration
     */
    readonly id: string

    /**
     * 
     * @type {RequestSavedConfigurationUpdate}
     * @memberof SavedConfigurationApiUpdateSavedConfiguration
     */
    readonly requestSavedConfigurationUpdate: RequestSavedConfigurationUpdate

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof SavedConfigurationApiUpdateSavedConfiguration
     */
    readonly acceptLanguage?: string
}

/**
 * SavedConfigurationApi - object-oriented interface
 * @export
 * @class SavedConfigurationApi
 * @extends {BaseAPI}
 */
export class SavedConfigurationApi extends BaseAPI {
    /**
     * 
     * @summary copia una configuración guarda a todos los terminales de un grupo
     * @param {SavedConfigurationApiCopySavedConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavedConfigurationApi
     */
    public copySavedConfiguration(requestParameters: SavedConfigurationApiCopySavedConfigurationRequest, options?: AxiosRequestConfig) {
        return SavedConfigurationApiFp(this.configuration).copySavedConfiguration(requestParameters.requestCopyConfiguration, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Realiza la creación de un registro de configuración guardo
     * @param {SavedConfigurationApiCreateSavedConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavedConfigurationApi
     */
    public createSavedConfiguration(requestParameters: SavedConfigurationApiCreateSavedConfigurationRequest, options?: AxiosRequestConfig) {
        return SavedConfigurationApiFp(this.configuration).createSavedConfiguration(requestParameters.requestSavedConfigurationCreate, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary elimina una configuración guarda
     * @param {SavedConfigurationApiDeleteSavedConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavedConfigurationApi
     */
    public deleteSavedConfiguration(requestParameters: SavedConfigurationApiDeleteSavedConfigurationRequest, options?: AxiosRequestConfig) {
        return SavedConfigurationApiFp(this.configuration).deleteSavedConfiguration(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Obtener la configuración guarda
     * @param {SavedConfigurationApiGetSavedConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavedConfigurationApi
     */
    public getSavedConfiguration(requestParameters: SavedConfigurationApiGetSavedConfigurationRequest, options?: AxiosRequestConfig) {
        return SavedConfigurationApiFp(this.configuration).getSavedConfiguration(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Obtener la lista de configuraciones guarda
     * @param {SavedConfigurationApiListSavedConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavedConfigurationApi
     */
    public listSavedConfiguration(requestParameters: SavedConfigurationApiListSavedConfigurationRequest = {}, options?: AxiosRequestConfig) {
        return SavedConfigurationApiFp(this.configuration).listSavedConfiguration(requestParameters.acceptLanguage, requestParameters.templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary actualiza una configuración guarda
     * @param {SavedConfigurationApiUpdateSavedConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavedConfigurationApi
     */
    public updateSavedConfiguration(requestParameters: SavedConfigurationApiUpdateSavedConfigurationRequest, options?: AxiosRequestConfig) {
        return SavedConfigurationApiFp(this.configuration).updateSavedConfiguration(requestParameters.id, requestParameters.requestSavedConfigurationUpdate, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}

