/* eslint-disable prettier/prettier */
import globalAxios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse, 
} from 'axios';
import { Configuration } from '../../api';
import store from '../redux/store';
import { errorNotification } from '../../src/services/notifications';
import { getCookie } from '../../src/services/cookie';

export function loadCloudConfig() {
  const basePath = 'https://tms-server-demo.apps2go.tech/'.replace(/\/+$/, '');

  return new Configuration({ basePath });
}

export function loadLocalConfig() {
  const basePath = 'http://localhost:8080/'.replace(/\/+$/, '');

  return new Configuration({ basePath });
}

function succesInterceptorBuilder(module: string) {
  try {
    return async (response: AxiosResponse) => {
      if (response.config.method?.toLowerCase() === 'get') return response;
  
      //succesNotification(`Exito. ${module}`);
      return response;
    };
  } catch (error) {
    errorNotification(`${error}`);
  }
}

function errorInterceptorBuilder(module: string) {
  try {
    return async (error: AxiosError) => {
      //let status:any = error.toJSON()
      let status:any = JSON.stringify(error)
      if(status.status == 403){
        errorNotification(`Su token fue Vencido, vuelva a iniciar sesion`);
      }
    
      //errorNotification(`Error. ${module}`);
      return Promise.reject(error);
    };
  } catch (error) {
    errorNotification(`${error}`);
  }
} 
  
 
function tokenInterceptorBuilder() {
  try {
    return async (config: AxiosRequestConfig) => {
      const { token } = store.getState();
      const userCookie:any = getCookie('tms_user');
      let parseToken:any; 
      //const token = JSON.parse(userCookie);
      var URLactual = window.location.pathname;
   
      if(URLactual.localeCompare('/login')){
        parseToken = JSON.parse(userCookie);
        parseToken = parseToken?.token;
      }else{
        parseToken = token;
      }
      config.headers = {  
        ...config.headers,
        Authorization: `Bearer ${parseToken}`,  
        Accept: 'application/json',
      };
      return config;
    };
  } catch (error) {
    errorNotification(`${error}`);
  }
}
 
export function apisConfigurator(
  module: string,
  configuration: Configuration = loadCloudConfig()
): [Configuration, string | undefined, AxiosInstance] {
  const axios = globalAxios;
  try {
    axios.interceptors.request.use(
      tokenInterceptorBuilder(),
      errorInterceptorBuilder(module)
    );
  
    axios.interceptors.response.use(
      succesInterceptorBuilder(module),
      errorInterceptorBuilder(module)
    );
  } catch (error) {
    errorNotification(`${error}`);
  }

  return [configuration, configuration.basePath, axios];
}
