/* eslint-disable prettier/prettier */

import { LitElement, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { apisConfigurator } from '../../utils/axios.js';
import { connect } from 'pwa-helpers';
import {
  UserApi,
  UserApiUserMasterChangeRequest,
  SecurityApi,
  RequestLogin,
} from '../../../api';
import router from '../../router/router';
import store from '../../redux/store';
import { iniciarSesion } from '../../redux/actions';
import {
  errorNotification,
  succesNotification,
} from '../../services/notifications';
import { State } from '../../redux/types';
import '@ui5/webcomponents/dist/Dialog.js';
import '@vaadin/vaadin-select';
import '../CustomSelect/CustomSelect';

@customElement('cambiar-master')
export class CambiarMaster extends connect(store)(LitElement) {
  private usersApi!: UserApi;
  private securityApi!: SecurityApi;
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  @property({ type: String })
  dialogTitle = '¿Estás seguro de realizar esta acción?';

  @property({ type: String })
  dialogBody = '';

  @property({ type: String })
  acceptButtonMessage = 'Aceptar';

  @property({ type: String })
  cancelButtonMessage = 'Cancelar';

  @state()
  users: string[] = [''];

  @state()
  loading = false;

  private get dialog() {
    return document.getElementById('dialog-master')! as any;
  }

  protected firstUpdated(): void {

  }

  async open() {
    this.usersApi = new UserApi(...apisConfigurator('Usuarios'));
    this.securityApi = new SecurityApi(...apisConfigurator('Iniciar sesión'));
    this.listUserMaster();
    this.dialog.open = true;
  }

  close() { 
    this.dialog.open = false;
  } 

  private async listUserMaster() {
    this.loading = true;
    try {
      let resTer = await this.usersApi.userMasterList({});

      this.users = resTer.data;
      console.log("LISTA MASTER",this.users);
      this.loading = false;
    } catch (error) {}
  }

  private async loginUser() {
    const { password, usuario, email } = store.getState() as State;
    console.log(password);
    console.log(email);
    const requestLogin: RequestLogin = {
      user: email as string,
      password: password as string,
    };
    console.log(requestLogin);

    const response = await this.securityApi.securityLogin({ requestLogin });

    localStorage.setItem('token', response.data.token);

    store.dispatch(
      iniciarSesion(
        response.data.token,
        response.data.authorizedFunctions,
        response.data.name,
        response.data.activeMaster
      )
    );
    succesNotification('cambio ejecutado con exito');
    router.cambiarSeccion('');
    this.close();
  }

  private async loginMaster(user: string) {
    const { nameMaster, password, email } = store.getState() as State;

    const userApiUserMasterChangeRequest: UserApiUserMasterChangeRequest = {
      requestUserMasterChange: {
        newMaster: user,
      },
    };

    try {
      const response = await this.usersApi.userMasterChange(
        userApiUserMasterChangeRequest
      );
      console.log(response);
      localStorage.removeItem('token');
      localStorage.setItem('token', response.data.token);
      store.dispatch( 
        iniciarSesion(  
          response.data.token,
          response.data.authorizedFunctions,
          //response.data.name,
          response.data.activeMaster
        )
      ); 
      succesNotification('cambio a master ejecutado con exito');
      router.cambiarSeccion('');
      this.close();
    } catch (err) {
      console.log(err);
    }
  }

  protected render() {
    return html` <ui5-dialog
      id="dialog-master"
    >
 
      <div class="flex flex-wrap overflow-y-auto">
            
 
      <div
  class="block rounded-lg bg-white text-center shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
  <div
    class="border-b-2 border-neutral-100 px-6 py-3 dark:border-neutral-600 dark:text-neutral-50">
    Cuentas Usuario
  </div>
  ${this.loading !== true
    ? html`
          `
    : html`
        <mwc-circular-progress
          indeterminate
          density=${20}
        ></mwc-circular-progress>
      `}
  ${this.users.map(
    (user: string, index) => html`
      <div class="p-6 hover:bg-sky-500" @click=${() => this.loginMaster(user)}>
        <h5
          class="mb-2 w-full text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50"
        >
          ${user}
        </h5>
        <p class="mb-4 text-base text-neutral-600 dark:text-neutral-200"></p>
      </div>
    `
  )}


  </div>
  <!-- <div
    class="border-t-2 border-neutral-100 px-6 py-3 dark:border-neutral-600 dark:text-neutral-50">
    2 days ago
  </div> -->
</div>



</div>
    </ui5-dialog>`;
  }

  static styles = css`
    .dialogBody {
      text-align: center;
    }

    #ui5-popup-header-text {
      font-weight: bold;
      margin-top: 2px;
    }
  `;
}
