import { AppApi } from '../../../api';
import { apisConfigurator } from '../../utils/axios';
import { put, takeLatest } from 'redux-saga/effects';
import { APPS_RECEIVED, GET_APPS } from '../actions/app';


export function* getApps(): Generator {
  console.log('getApps');
  const appApi = new AppApi(...apisConfigurator(''));
  const data = yield appApi.listApp().then(res => res.data);

  console.log({ data });
  yield put({ type: APPS_RECEIVED, apps: data });
}

export function* appsWatcher() {
  yield takeLatest(GET_APPS, getApps);
}
