/* eslint-disable prettier/prettier */
import { html, LitElement } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import { customElement, query, state } from 'lit/decorators.js';
import { connect } from 'pwa-helpers';
import Swal from 'sweetalert2';
import '@vaadin/vaadin-list-box';
import '@vaadin/vaadin-text-field';
import './EditarPlantilla.css';
import { OrganizationName, RoleList, User, TemplateApi } from '../../../api';
import '@vaadin/vaadin-text-field';
import { NameTemplate } from '../../components/modals/NameTemplate';
import '../../components/modals/NameTemplate';

import { EnumModal } from '../../components/modals/EnumModal';
import '../../components/modals/EnumModal';

import router from '../../router/router';
import { apisConfigurator } from '../../utils/axios';
import { InsertarPropiedad, ordenarAsc } from '../../utils/functions';
import store from '../../redux/store';
import { State } from '../../redux/types';
import { agregarPlantilla, Enumm } from '../../redux/actions';
import { CustomMultiSelect } from '../../components/CustomMultiSelect/CustomMultiSelect';
import { Map, map } from 'leaflet';
@customElement('edit-template-page')
export class EditarPlantilla extends connect(store)(LitElement) {
  private templateApi: TemplateApi; 
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private constructor() {
    super();
    this.templateApi = new TemplateApi(...apisConfigurator(''));
  }

  @query('#name')
  nameInput!: HTMLInputElement;

  @query('#lastName')
  lastNameInput!: HTMLInputElement;

  @state()
  private obj?: any;

  @state()
  loading = true;

  @state()
  editUser?: User;

  @state()
  private controlPlantilla: any[] = [
    {
      name: ' ',
    },
  ];

  @state()
  token?: number;

  @state()
  roles: RoleList[] = [];

  @state()
  nombrePlantilla?: string;

  @state()
  idPlantilla: string = '';

  @state()
  descripcionPlantilla?: string = '';

  @state()
  rolesUsuario: string[] = [];

  @state()
  organizationUsuario: string = '';

  @state()
  aux: string = '';

  @state()
  organizaciones: OrganizationName[] = [];

  @state()
  rol?: string = '';

  @state()
  typeJsnSchema: boolean = false;

  @state()
  private check: boolean = false;

  @state()
  checks: boolean = false;

  @state()
  organization!: Array<string>;

  private valueSelect(item: number) {
    return document.getElementById(`type${item}`) as any;
  }

  get enumModal() {
    return document.getElementById('enumModal') as EnumModal;
  }

  private get loaderContainer() {
    return document.getElementById('loader-container') as any;
  }

  get nameTemplate() {
    return document.getElementById('cambiarNombrePlantilla') as NameTemplate;
  }

  idGet(atributo: string) {
    return document.getElementById(atributo) as HTMLInputElement;
  }

  firstUpdated() {
    this.obj = {
      properties: [],
      required: [],
    };
    const initial = [
      {
        id: 1,
        nombre: '',
        type: '',
        LengthMaximo: 0,
        LengthMinimo: 0,
        default: 0,
        validator: '',
        requerido: '2',
      },
    ];
    //store.dispatch(agregarPlantilla(initial));
    const loaderContainer = this.loaderContainer;
    loaderContainer.style.display = 'none';

    this.cargarData();
  }

  stateChanged(state: any) {
    this.controlPlantilla = state.plantilla;
    console.log(state); 
    ordenarAsc(this.controlPlantilla);
    this.nombrePlantilla = state.nombrePlantilla;
    console.log(this.nombrePlantilla);
    this.descripcionPlantilla = state.descripcionPlantilla;
    this.idPlantilla = state.idPlantilla;
    this.token = state.plantilla.length;
    this.templateApi.createTemplate;
    if (state.enumm) {
      this.changeInputEnum(state.enumm);
    }
    if(state.typeJsonSchema){
      console.log(state.typeJsonSchema)
      if(state.typeJsonSchema == "array"){
        this.typeJsnSchema = true;
      }else{
        this.typeJsnSchema = false;
      }
    }
  }

  extraer(obj: any, index: string, requiredIndex: any) {
    const {
      name,
      type,
      pattern,
      description,
      maximum,
      minimum,
      maxLength,
      minLength,
      required,
    } = obj;
    if (index == 'name') {
      return name;
    } else if (index == 'type') {
      return type;
    } else if (index == 'pattern') {
      return pattern;
    } else if (index == 'description') {
      return description;
    } else if (index == 'lengthMaximo') {
      return maximum ? maximum : maxLength;
    } else if (index == 'lengthMinimo') {
      return minimum ? minimum : minLength;
    } else if (index == 'required') {
      this.checks = required;
      return required;
    } else if (index == 'enum') {
      let li = obj.enum;
      let cadena = '';
      li.map((camp: any, index: number) => {
        cadena = cadena + `$camp,`;
      });
      cadena = cadena.substring(0, cadena.length - 1);
      return cadena;
    }
  }

  async cargarData() {
    this.verificarUserEdit();
    this.loading = false;
  }

  async verificarUserEdit() {
    const { usuarioEdit, plantilla, nombrePlantilla } =
      store.getState() as State;
    //this.checks= new Array(plantilla?.length);
    this.token = plantilla?.length;
    //this.nombrePlantilla = "Nombre de la plantilla";
    this.controlPlantilla = plantilla ? plantilla : [];
    this.editUser = usuarioEdit;

    if (!plantilla) {
      router.cambiarSeccion('apps-list');
    }
  }

  agregarCampoNuevo() {
    //const { usuarioEdit, plantilla } = store.getState() as State;
    //console.log(plantilla)
    let plantilla = this.controlPlantilla;
    let id = plantilla?.length ? plantilla?.length + 1 : 0 + 1;
    console.log(id);
    const campoNuevo = {
      id: id,
      name: '',
      type: '',
      Length: 0,
      LengthMaximo: 0,
      lengthMinimo: 0,
      default: 0,
      pattern: '',
      required: false,
    };
    plantilla?.push(campoNuevo);
    //console.log(ordenarAsc(plantilla));
    store.dispatch(agregarPlantilla(plantilla ? plantilla : [{}]));
    this.controlPlantilla = plantilla ? plantilla : [{}];
    console.log(this.controlPlantilla);
  }
  async createSchema() {
    this.controlPlantilla.map((item: any, index: number) => {
      if (!item.enum) {
        this.changeInput(item.id);
      }
    });
    this.obj.properties = this.controlPlantilla;
    console.log(this.obj)
    const dataSchema = InsertarPropiedad(this.obj);
    console.log(dataSchema); 
    if(this.typeJsnSchema){
      dataSchema.type="array"
    }
    let requestTemplateUpdate = {
      name: `${this.nombrePlantilla}`,
      description: `${this.descripcionPlantilla}`,
      schema: dataSchema,
    };
    let requestTemplate = {
      id: this.idPlantilla,
      requestTemplateUpdate,
    };
    console.log(requestTemplate);

    try {
      const res = await this.templateApi.updateTemplate(requestTemplate);
      if (res.status == 200) {
        console.log(res);
        //router.cambiarSeccion('plantilla-page');
        await Swal.fire('Actualizado!', 'El dato fue actualizado', 'success');
        router.cambiarSeccion('apps-list');
      }
    } catch (err) {}
  }

  async eliminarCampo(id: number) {
    //const { plantilla } = store.getState() as State;
    let plantilla = this.controlPlantilla;
    const map1 = plantilla?.filter((x: any, index: number) => x.id !== id);
    //const map2 = this.checks?.filter((x:any,index:number) => index+1 !== id );
    //this.checks=map2;
    /*await map1.map((camp:any,index:number) =>{
      camp.id=index+1;
    })*/
    console.log(map1);
    store.dispatch(agregarPlantilla(map1 ? map1 : [{}]));
    this.controlPlantilla = map1 ? map1 : [{}];
    console.log(this.controlPlantilla);
  }

  changeInput(indice: number) {
    let id = indice;
    let name = this.idGet(`name${indice}`);
    let type = this.idGet(`type${indice}`);
    let lengthMaximo = this.idGet(`lengthMaximo${indice}`);
    let lengthMinimo = this.idGet(`lengthMinimo${indice}`);
    let description = this.idGet(`description${indice}`);
    let pattern = this.idGet(`pattern${indice}`);

    let dataObjeto = {};
    const found = this.controlPlantilla.find(element => element.id == id);
    var index = this.controlPlantilla
      .map(producto => producto.id)
      .indexOf(found.id);
    console.log(id);
    if (found.enum) {
      dataObjeto = {
        id,
        name: name.value,
        type: 'string',
        description: description.value,
        enum: found.enum,
        required: this.checks,
      };
      this.controlPlantilla[index] = dataObjeto;
    } else {
      dataObjeto = {
        id,
        name: name.value,
        type: type.value,
        lengthMaximo: lengthMaximo.value,
        lengthMinimo: lengthMinimo.value,
        description: description.value,
        pattern: pattern.value,
        required: this.checks,
      };
      this.controlPlantilla[index] = dataObjeto;
    }
    console.log(this.controlPlantilla);
  }

  changeInputEnum(data: any) {
    let id = data.id;
    console.log(id);
    let name = this.idGet(`name${data.id}`);
    let type = this.idGet(`type${data.id}`);
    let length = this.idGet(`length${data.id}`);
    let description = this.idGet(`description${data.id}`);
    let pattern = this.idGet(`pattern${data.id}`);

    if (
      type.value == 'number' ||
      type.value == 'integer' ||
      type.value == 'boolean'
    ) {
      type.value = 'string';
    }

    let dataObjeto = {
      id,
      name: name.value,
      type: 'string',
      //length: length.value,
      description: description.value,
      //pattern: pattern.value,
      required: this.checks,
      enum: data.data,
    };

    const map2 = this.controlPlantilla.filter(
      (x: any, index: number) => x.id !== data.id
    );
    map2.push(dataObjeto);
    store.dispatch(Enumm(0));
    store.dispatch(agregarPlantilla(map2 ? map2 : [{}]));
    this.controlPlantilla = map2;
    console.log(this.controlPlantilla);
  }

  extraerName(indice: any) {
    let subs: any = [];
    if (this.editUser?.name) {
      subs = this.editUser?.name.split(' ');
    } else {
      return '';
    }
    return subs[indice] == undefined ? '' : subs[indice];
  }

  render() {
    return html`
      <div id="loader-container">
        <div class="spinner"></div>
      </div>
      <main class="w-full h-full flex overflow-auto">
        <div class="w-full h-full px-5 py-4 flex flex-wrap items-start">
          <form
            class="w-full h-full relative mb-2 SectionList rounded-md pt-5px-4 "
          >
            <div class="flex justify-between">
              <div class="nombre-descripcion-section">
                <h1 class="text-2xl font-semibold text-blue-600">
                  ${this.nombrePlantilla ? this.nombrePlantilla : ''}
                  <mwc-icon
                    @click=${() => {
                      console.log('hola');
                      this.nameTemplate.open('ww');
                    }}
                    >mode</mwc-icon
                  >
                </h1>
                <span
                  >${this.descripcionPlantilla
                    ? this.descripcionPlantilla
                    : ''}</span
                >
              </div>
              <div>
                <mwc-checkbox
                  id="terminalBox"
                  value="jsonArray"
                  .checked="${this.typeJsnSchema}"
                  disabled
                ></mwc-checkbox>
                Json array
              </div>
              <mwc-button
                outlined
                class="rounded"
                @click=${() => {
                  //router.cambiarSeccion('create-rol');
                  this.createSchema();
                }}
                ><mwc-icon>edit</mwc-icon> Editar Plantilla</mwc-button
              >
            </div>
            <div class="header-tr">
              <div class="mx-2  TexField ">
                <h1></h1>
              </div>
              <div class="mx-2  TexField">
                <h1>Tipo</h1>
              </div>
              <div class="mx-2  TexField">
                <h1></h1>
              </div>
              <div class="mx-2  TexField">
                <h1></h1>
              </div>
              <div class="mx-2  TexField">
                <h1></h1>
              </div>
              <div class="mx-2  TexField">
                <h1></h1>
              </div>
              <div class="mx-2  TexField">
                <h1></h1>
              </div>
            </div>
            ${repeat(
              this.controlPlantilla,
              (usuario: any) => usuario.id,
              (usuario: any, index: number) => html`
                <div class="flex flex-wrap my-4 mx-4">
                  <div class="mx-2  TexField">
                    <mwc-textfield
                      outlined
                      iconTrailing="close"
                      label="name"
                      id="name${usuario.id}"
                      minLength="1"
                      class="mx-2"
                      .value=${this.extraer(usuario, 'name', index)}
                      .readonly="${this.controlPlantilla !== undefined}"
                      @keyup="${(ev: InputEvent) => {
                        this.changeInput(usuario.id);
                      }}"
                    >
                    </mwc-textfield>
                  </div>
                  <div class="mx-2 TexField">
                    ${this.extraer(usuario, 'type', usuario.id) === 'string'
                      ? html`
                          <select
                            name="cars"
                            id="type${usuario.id}"
                            class="style-select"
                          >
                            <option value="string" selected>string</option>
                            <option value="number">number</option>
                            <option value="integer">integer</option>
                            <option value="boolean">boolean</option>
                            <option value="Enum">Enum</option>
                          </select>
                        `
                      : this.extraer(usuario, 'type', usuario.id) === 'number'
                      ? html`
                          <select
                            name="cars"
                            id="type${usuario.id}"
                            class="style-select"
                          >
                            <option value="string">string</option>
                            <option value="number" selected>number</option>
                            <option value="integer">integer</option>
                            <option value="boolean">boolean</option>
                            <option value="Enum">Enum</option>
                          </select>
                        `
                      : this.extraer(usuario, 'type', usuario.id) === 'integer'
                      ? html`
                          <select
                            name="cars"
                            id="type${usuario.id}"
                            class="style-select"
                          >
                            <option value="string">string</option>
                            <option value="number">number</option>
                            <option value="integer" seleted>integer</option>
                            <option value="boolean">boolean</option>
                            <option value="Enum">Enum</option>
                          </select>
                        `
                      : this.extraer(usuario, 'type', usuario.id) === 'boolean'
                      ? html`
                          <select
                            name="cars"
                            id="type${usuario.id}"
                            class="style-select"
                          >
                            <option value="string">string</option>
                            <option value="number">number</option>
                            <option value="integer">integer</option>
                            <option value="boolean" selected>boolean</option>
                            <option value="Enum">Enum</option>
                          </select>
                        `
                      : html`
                          <select
                            name="cars"
                            id="type${usuario.id}"
                            class="style-select"
                          >
                            <option value="string">string</option>
                            <option value="number">number</option>
                            <option value="integer">integer</option>
                            <option value="boolean">boolean</option>
                            <option value="Enum">Enum</option>
                          </select>
                        `}
                  </div>
                  <div class="mx-2 TexField">
                    <mwc-textfield
                      id="lengthMinimo${usuario.id}"
                      type="number"
                      label="minimo"
                      class="mx-2"
                      outlined
                      iconTrailing="close"
                      .value=${this.extraer(
                        usuario,
                        'lengthMinimo',
                        usuario.id
                      )}
                      .readonly="${this.editUser !== undefined}"
                      @keyup="${(ev: InputEvent) => {
                        console.log(usuario.id);
                        this.changeInput(usuario.id);
                      }}"
                    >
                    </mwc-textfield>
                  </div>
                  <div class="mx-2 TexField">
                    <mwc-textfield
                      id="lengthMaximo${usuario.id}"
                      type="number"
                      label="maximo"
                      class="mx-2"
                      outlined
                      iconTrailing="close"
                      .value=${this.extraer(
                        usuario,
                        'lengthMaximo',
                        usuario.id
                      )}
                      .readonly="${this.editUser !== undefined}"
                      @keyup="${(ev: InputEvent) => {
                        this.changeInput(usuario.id);
                      }}"
                    >
                    </mwc-textfield>
                  </div>
                  <div class="mx-2 TexField">
                    <mwc-textfield
                      id="description${usuario.id}"
                      label="Descripcion"
                      class="mx-2"
                      outlined
                      .value=${this.extraer(usuario, 'description', index)}
                      iconTrailing="close"
                      @keyup="${(ev: InputEvent) => {
                        this.changeInput(usuario.id);
                      }}"
                    >
                    </mwc-textfield>
                  </div>
                  <div class="mx-2 TexField">
                    <mwc-textfield
                      id="pattern${usuario.id}"
                      label="Validator"
                      class="mx-2"
                      .value=${this.extraer(usuario, 'pattern', index)}
                      outlined
                      iconTrailing="close"
                      @keyup="${(ev: InputEvent) => {
                        this.changeInput(usuario.id);
                      }}"
                    >
                    </mwc-textfield>
                  </div>
                  <div class="mx-2 TexField">
                    <mwc-checkbox
                      id="terminalBox"
                      value="requerido"
                      .checked="${usuario.required}"
                      @change="${(e: any) => {
                        console.log(!usuario.required);
                        this.checks = !usuario.required;
                        this.changeInput(usuario.id);
                      }}"
                    ></mwc-checkbox>
                  </div>
                  <div class="mx-2 TexField">
                    <mwc-button
                      outlined
                      class="rounded"
                      @click=${() => {
                        this.enumModal.open(usuario.id, usuario);
                        const item = this.valueSelect(usuario.id);
                        item.value = 'Enum';
                        console.log(item.options[4]);
                      }}
                      >enum
                    </mwc-button>
                    ${usuario.enum
                      ? html`<span class="text-red-600 font-bold">*</span>`
                      : html``}
                  </div>
                  <div class="mx-2 TexField">
                    <mwc-icon-button
                      icon="delete"
                      @click=${() => {
                        this.eliminarCampo(usuario.id);
                        //router.cambiarSeccion('create-template');
                        //window.location.reload()
                      }}
                    ></mwc-icon-button>
                  </div>
                </div>
              `
            )}

            <div class="flex flex-wrap my-4 mx-4 button-new-campo">
              <mwc-button
                outlined
                class="rounded"
                @click=${() => {
                  this.agregarCampoNuevo();
                  //console.log(this.controlPlantilla)
                }}
                ><mwc-icon>add_circle</mwc-icon> Agregar nuevo campo</mwc-button
              >
            </div>
          </form>
        </div>
      </main>
      <name-template id="cambiarNombrePlantilla"></name-template>
      <enum-modal id="enumModal"></enum-modal>
    `;
  }
}
