/* tslint:disable */
/* eslint-disable */
/**
 * TMS Agnostiko
 * Tiene la definición de todos los servicios de TMS a Agnostic (para uso en la web o externo)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { Command } from '../models';
// @ts-ignore
import { Message } from '../models';
/**
 * CommandApi - axios parameter creator
 * @export
 */
export const CommandApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Obtener la lista de terminales
         * @param {string} groupId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commandListByGroup: async (groupId: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('commandListByGroup', 'groupId', groupId)
            const localVarPath = `/command/group/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Obtener el status de los comandos enviados a un terminal
         * @param {string} brand 
         * @param {string} serial 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commandsListByTerminal: async (brand: string, serial: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brand' is not null or undefined
            assertParamExists('commandsListByTerminal', 'brand', brand)
            // verify required parameter 'serial' is not null or undefined
            assertParamExists('commandsListByTerminal', 'serial', serial)
            const localVarPath = `/command/list/{brand}/{serial}`
                .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
                .replace(`{${"serial"}}`, encodeURIComponent(String(serial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Listado de los últimos comandos
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {number} [limit] 
         * @param {number} [init] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lastCommands: async (acceptLanguage?: string, limit?: number, init?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/command/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (init !== undefined) {
                localVarQueryParameter['init'] = init;
            }

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommandApi - functional programming interface
 * @export
 */
export const CommandApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommandApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Obtener la lista de terminales
         * @param {string} groupId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commandListByGroup(groupId: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Command>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commandListByGroup(groupId, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CommandApi.commandListByGroup']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Obtener el status de los comandos enviados a un terminal
         * @param {string} brand 
         * @param {string} serial 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commandsListByTerminal(brand: string, serial: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Command>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commandsListByTerminal(brand, serial, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CommandApi.commandsListByTerminal']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Listado de los últimos comandos
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {number} [limit] 
         * @param {number} [init] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lastCommands(acceptLanguage?: string, limit?: number, init?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Command>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lastCommands(acceptLanguage, limit, init, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CommandApi.lastCommands']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CommandApi - factory interface
 * @export
 */
export const CommandApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommandApiFp(configuration)
    return {
        /**
         * 
         * @summary Obtener la lista de terminales
         * @param {CommandApiCommandListByGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commandListByGroup(requestParameters: CommandApiCommandListByGroupRequest, options?: AxiosRequestConfig): AxiosPromise<Array<Command>> {
            return localVarFp.commandListByGroup(requestParameters.groupId, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Obtener el status de los comandos enviados a un terminal
         * @param {CommandApiCommandsListByTerminalRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commandsListByTerminal(requestParameters: CommandApiCommandsListByTerminalRequest, options?: AxiosRequestConfig): AxiosPromise<Array<Command>> {
            return localVarFp.commandsListByTerminal(requestParameters.brand, requestParameters.serial, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Listado de los últimos comandos
         * @param {CommandApiLastCommandsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lastCommands(requestParameters: CommandApiLastCommandsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<Command>> {
            return localVarFp.lastCommands(requestParameters.acceptLanguage, requestParameters.limit, requestParameters.init, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for commandListByGroup operation in CommandApi.
 * @export
 * @interface CommandApiCommandListByGroupRequest
 */
export interface CommandApiCommandListByGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof CommandApiCommandListByGroup
     */
    readonly groupId: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof CommandApiCommandListByGroup
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for commandsListByTerminal operation in CommandApi.
 * @export
 * @interface CommandApiCommandsListByTerminalRequest
 */
export interface CommandApiCommandsListByTerminalRequest {
    /**
     * 
     * @type {string}
     * @memberof CommandApiCommandsListByTerminal
     */
    readonly brand: string

    /**
     * 
     * @type {string}
     * @memberof CommandApiCommandsListByTerminal
     */
    readonly serial: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof CommandApiCommandsListByTerminal
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for lastCommands operation in CommandApi.
 * @export
 * @interface CommandApiLastCommandsRequest
 */
export interface CommandApiLastCommandsRequest {
    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof CommandApiLastCommands
     */
    readonly acceptLanguage?: string

    /**
     * 
     * @type {number}
     * @memberof CommandApiLastCommands
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof CommandApiLastCommands
     */
    readonly init?: number
}

/**
 * CommandApi - object-oriented interface
 * @export
 * @class CommandApi
 * @extends {BaseAPI}
 */
export class CommandApi extends BaseAPI {
    /**
     * 
     * @summary Obtener la lista de terminales
     * @param {CommandApiCommandListByGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandApi
     */
    public commandListByGroup(requestParameters: CommandApiCommandListByGroupRequest, options?: AxiosRequestConfig) {
        return CommandApiFp(this.configuration).commandListByGroup(requestParameters.groupId, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Obtener el status de los comandos enviados a un terminal
     * @param {CommandApiCommandsListByTerminalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandApi
     */
    public commandsListByTerminal(requestParameters: CommandApiCommandsListByTerminalRequest, options?: AxiosRequestConfig) {
        return CommandApiFp(this.configuration).commandsListByTerminal(requestParameters.brand, requestParameters.serial, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Listado de los últimos comandos
     * @param {CommandApiLastCommandsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandApi
     */
    public lastCommands(requestParameters: CommandApiLastCommandsRequest = {}, options?: AxiosRequestConfig) {
        return CommandApiFp(this.configuration).lastCommands(requestParameters.acceptLanguage, requestParameters.limit, requestParameters.init, options).then((request) => request(this.axios, this.basePath));
    }
}

