import { setCookie } from '../../services/cookie';
import { ActionTypes } from '../types';

export const INICIAR_SESION = 'INICIAR_SESION';
export const CERRAR_SESION = 'CERRAR_SESION';

export function iniciarSesion(
  token: string,
  authorizedFunctions: string[],
  usuario?: string,
  nameMaster?: string,
  password?: string,
  email?: string
): ActionTypes {
  setCookie(
    'tms_user',
    JSON.stringify({
      token,
      authorizedFunctions,
      usuario,
      nameMaster,
      password,
      email,
    }),
    12
  );
  return {
    type: INICIAR_SESION,
    usuario,
    token,
    authorizedFunctions,
    nameMaster,
    password,
    email,
  };
}

export function cerrarSesion(): ActionTypes {
  setCookie('tms_user', '', 1);
  return { type: CERRAR_SESION };
}
