/* tslint:disable */
/* eslint-disable */
/**
 * TMS Agnostiko
 * Tiene la definición de todos los servicios de TMS a Agnostic (para uso en la web o externo)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { BusinessInfo } from '../models';
// @ts-ignore
import { BusinessInfoList } from '../models';
// @ts-ignore
import { Message } from '../models';
// @ts-ignore
import { RequestBusinessBatch } from '../models';
// @ts-ignore
import { RequestBusinessCreate } from '../models';
// @ts-ignore
import { RequestBusinessUpdate } from '../models';
// @ts-ignore
import { RequestUpdateConfigurations } from '../models';
// @ts-ignore
import { ResponseBusinessBatch } from '../models';
// @ts-ignore
import { ResponseCreateBusiness } from '../models';
/**
 * BusinessApi - axios parameter creator
 * @export
 */
export const BusinessApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Realiza la creación de múltiples razon social a la vez
         * @param {RequestBusinessBatch} requestBusinessBatch 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessBatch: async (requestBusinessBatch: RequestBusinessBatch, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBusinessBatch' is not null or undefined
            assertParamExists('businessBatch', 'requestBusinessBatch', requestBusinessBatch)
            const localVarPath = `/business/batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBusinessBatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Realiza la creación de una razón social asociada a una aplicación
         * @param {RequestBusinessCreate} requestBusinessCreate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBusiness: async (requestBusinessCreate: RequestBusinessCreate, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBusinessCreate' is not null or undefined
            assertParamExists('createBusiness', 'requestBusinessCreate', requestBusinessCreate)
            const localVarPath = `/business`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBusinessCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary elimina una razón social
         * @param {string} appId 
         * @param {string} businessId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBusiness: async (appId: string, businessId: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('deleteBusiness', 'appId', appId)
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('deleteBusiness', 'businessId', businessId)
            const localVarPath = `/business/{appId}/{businessId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Obtener la  información de una razón social
         * @param {string} appId 
         * @param {string} businessId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusiness: async (appId: string, businessId: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getBusiness', 'appId', appId)
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getBusiness', 'businessId', businessId)
            const localVarPath = `/business/{appId}/{businessId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Listado de razón social a una aplicación
         * @param {string} appId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBusiness: async (appId: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('listBusiness', 'appId', appId)
            const localVarPath = `/business/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (appId !== undefined) {
                localVarQueryParameter['appId'] = appId;
            }

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary actualiza la información general de una razón social
         * @param {string} appId 
         * @param {string} businessId 
         * @param {RequestBusinessUpdate} requestBusinessUpdate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBusiness: async (appId: string, businessId: string, requestBusinessUpdate: RequestBusinessUpdate, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('updateBusiness', 'appId', appId)
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('updateBusiness', 'businessId', businessId)
            // verify required parameter 'requestBusinessUpdate' is not null or undefined
            assertParamExists('updateBusiness', 'requestBusinessUpdate', requestBusinessUpdate)
            const localVarPath = `/business/{appId}/{businessId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBusinessUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary reemplaza las configuraciones de una razon social
         * @param {string} appId 
         * @param {string} businessId 
         * @param {RequestUpdateConfigurations} requestUpdateConfigurations 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConfigurations: async (appId: string, businessId: string, requestUpdateConfigurations: RequestUpdateConfigurations, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('updateConfigurations', 'appId', appId)
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('updateConfigurations', 'businessId', businessId)
            // verify required parameter 'requestUpdateConfigurations' is not null or undefined
            assertParamExists('updateConfigurations', 'requestUpdateConfigurations', requestUpdateConfigurations)
            const localVarPath = `/business/configuration/{appId}/{businessId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestUpdateConfigurations, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BusinessApi - functional programming interface
 * @export
 */
export const BusinessApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BusinessApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Realiza la creación de múltiples razon social a la vez
         * @param {RequestBusinessBatch} requestBusinessBatch 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessBatch(requestBusinessBatch: RequestBusinessBatch, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseBusinessBatch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessBatch(requestBusinessBatch, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BusinessApi.businessBatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Realiza la creación de una razón social asociada a una aplicación
         * @param {RequestBusinessCreate} requestBusinessCreate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBusiness(requestBusinessCreate: RequestBusinessCreate, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCreateBusiness>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBusiness(requestBusinessCreate, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BusinessApi.createBusiness']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary elimina una razón social
         * @param {string} appId 
         * @param {string} businessId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBusiness(appId: string, businessId: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBusiness(appId, businessId, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BusinessApi.deleteBusiness']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Obtener la  información de una razón social
         * @param {string} appId 
         * @param {string} businessId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBusiness(appId: string, businessId: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBusiness(appId, businessId, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BusinessApi.getBusiness']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Listado de razón social a una aplicación
         * @param {string} appId 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBusiness(appId: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BusinessInfoList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBusiness(appId, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BusinessApi.listBusiness']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary actualiza la información general de una razón social
         * @param {string} appId 
         * @param {string} businessId 
         * @param {RequestBusinessUpdate} requestBusinessUpdate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBusiness(appId: string, businessId: string, requestBusinessUpdate: RequestBusinessUpdate, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBusiness(appId, businessId, requestBusinessUpdate, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BusinessApi.updateBusiness']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary reemplaza las configuraciones de una razon social
         * @param {string} appId 
         * @param {string} businessId 
         * @param {RequestUpdateConfigurations} requestUpdateConfigurations 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConfigurations(appId: string, businessId: string, requestUpdateConfigurations: RequestUpdateConfigurations, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConfigurations(appId, businessId, requestUpdateConfigurations, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BusinessApi.updateConfigurations']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * BusinessApi - factory interface
 * @export
 */
export const BusinessApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BusinessApiFp(configuration)
    return {
        /**
         * 
         * @summary Realiza la creación de múltiples razon social a la vez
         * @param {BusinessApiBusinessBatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessBatch(requestParameters: BusinessApiBusinessBatchRequest, options?: AxiosRequestConfig): AxiosPromise<ResponseBusinessBatch> {
            return localVarFp.businessBatch(requestParameters.requestBusinessBatch, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Realiza la creación de una razón social asociada a una aplicación
         * @param {BusinessApiCreateBusinessRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBusiness(requestParameters: BusinessApiCreateBusinessRequest, options?: AxiosRequestConfig): AxiosPromise<ResponseCreateBusiness> {
            return localVarFp.createBusiness(requestParameters.requestBusinessCreate, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary elimina una razón social
         * @param {BusinessApiDeleteBusinessRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBusiness(requestParameters: BusinessApiDeleteBusinessRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.deleteBusiness(requestParameters.appId, requestParameters.businessId, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Obtener la  información de una razón social
         * @param {BusinessApiGetBusinessRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusiness(requestParameters: BusinessApiGetBusinessRequest, options?: AxiosRequestConfig): AxiosPromise<BusinessInfo> {
            return localVarFp.getBusiness(requestParameters.appId, requestParameters.businessId, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Listado de razón social a una aplicación
         * @param {BusinessApiListBusinessRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBusiness(requestParameters: BusinessApiListBusinessRequest, options?: AxiosRequestConfig): AxiosPromise<Array<BusinessInfoList>> {
            return localVarFp.listBusiness(requestParameters.appId, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary actualiza la información general de una razón social
         * @param {BusinessApiUpdateBusinessRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBusiness(requestParameters: BusinessApiUpdateBusinessRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.updateBusiness(requestParameters.appId, requestParameters.businessId, requestParameters.requestBusinessUpdate, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary reemplaza las configuraciones de una razon social
         * @param {BusinessApiUpdateConfigurationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConfigurations(requestParameters: BusinessApiUpdateConfigurationsRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.updateConfigurations(requestParameters.appId, requestParameters.businessId, requestParameters.requestUpdateConfigurations, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for businessBatch operation in BusinessApi.
 * @export
 * @interface BusinessApiBusinessBatchRequest
 */
export interface BusinessApiBusinessBatchRequest {
    /**
     * 
     * @type {RequestBusinessBatch}
     * @memberof BusinessApiBusinessBatch
     */
    readonly requestBusinessBatch: RequestBusinessBatch

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof BusinessApiBusinessBatch
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for createBusiness operation in BusinessApi.
 * @export
 * @interface BusinessApiCreateBusinessRequest
 */
export interface BusinessApiCreateBusinessRequest {
    /**
     * 
     * @type {RequestBusinessCreate}
     * @memberof BusinessApiCreateBusiness
     */
    readonly requestBusinessCreate: RequestBusinessCreate

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof BusinessApiCreateBusiness
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteBusiness operation in BusinessApi.
 * @export
 * @interface BusinessApiDeleteBusinessRequest
 */
export interface BusinessApiDeleteBusinessRequest {
    /**
     * 
     * @type {string}
     * @memberof BusinessApiDeleteBusiness
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof BusinessApiDeleteBusiness
     */
    readonly businessId: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof BusinessApiDeleteBusiness
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for getBusiness operation in BusinessApi.
 * @export
 * @interface BusinessApiGetBusinessRequest
 */
export interface BusinessApiGetBusinessRequest {
    /**
     * 
     * @type {string}
     * @memberof BusinessApiGetBusiness
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof BusinessApiGetBusiness
     */
    readonly businessId: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof BusinessApiGetBusiness
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for listBusiness operation in BusinessApi.
 * @export
 * @interface BusinessApiListBusinessRequest
 */
export interface BusinessApiListBusinessRequest {
    /**
     * 
     * @type {string}
     * @memberof BusinessApiListBusiness
     */
    readonly appId: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof BusinessApiListBusiness
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateBusiness operation in BusinessApi.
 * @export
 * @interface BusinessApiUpdateBusinessRequest
 */
export interface BusinessApiUpdateBusinessRequest {
    /**
     * 
     * @type {string}
     * @memberof BusinessApiUpdateBusiness
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof BusinessApiUpdateBusiness
     */
    readonly businessId: string

    /**
     * 
     * @type {RequestBusinessUpdate}
     * @memberof BusinessApiUpdateBusiness
     */
    readonly requestBusinessUpdate: RequestBusinessUpdate

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof BusinessApiUpdateBusiness
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateConfigurations operation in BusinessApi.
 * @export
 * @interface BusinessApiUpdateConfigurationsRequest
 */
export interface BusinessApiUpdateConfigurationsRequest {
    /**
     * 
     * @type {string}
     * @memberof BusinessApiUpdateConfigurations
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof BusinessApiUpdateConfigurations
     */
    readonly businessId: string

    /**
     * 
     * @type {RequestUpdateConfigurations}
     * @memberof BusinessApiUpdateConfigurations
     */
    readonly requestUpdateConfigurations: RequestUpdateConfigurations

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof BusinessApiUpdateConfigurations
     */
    readonly acceptLanguage?: string
}

/**
 * BusinessApi - object-oriented interface
 * @export
 * @class BusinessApi
 * @extends {BaseAPI}
 */
export class BusinessApi extends BaseAPI {
    /**
     * 
     * @summary Realiza la creación de múltiples razon social a la vez
     * @param {BusinessApiBusinessBatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessApi
     */
    public businessBatch(requestParameters: BusinessApiBusinessBatchRequest, options?: AxiosRequestConfig) {
        return BusinessApiFp(this.configuration).businessBatch(requestParameters.requestBusinessBatch, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Realiza la creación de una razón social asociada a una aplicación
     * @param {BusinessApiCreateBusinessRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessApi
     */
    public createBusiness(requestParameters: BusinessApiCreateBusinessRequest, options?: AxiosRequestConfig) {
        return BusinessApiFp(this.configuration).createBusiness(requestParameters.requestBusinessCreate, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary elimina una razón social
     * @param {BusinessApiDeleteBusinessRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessApi
     */
    public deleteBusiness(requestParameters: BusinessApiDeleteBusinessRequest, options?: AxiosRequestConfig) {
        return BusinessApiFp(this.configuration).deleteBusiness(requestParameters.appId, requestParameters.businessId, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Obtener la  información de una razón social
     * @param {BusinessApiGetBusinessRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessApi
     */
    public getBusiness(requestParameters: BusinessApiGetBusinessRequest, options?: AxiosRequestConfig) {
        return BusinessApiFp(this.configuration).getBusiness(requestParameters.appId, requestParameters.businessId, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Listado de razón social a una aplicación
     * @param {BusinessApiListBusinessRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessApi
     */
    public listBusiness(requestParameters: BusinessApiListBusinessRequest, options?: AxiosRequestConfig) {
        return BusinessApiFp(this.configuration).listBusiness(requestParameters.appId, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary actualiza la información general de una razón social
     * @param {BusinessApiUpdateBusinessRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessApi
     */
    public updateBusiness(requestParameters: BusinessApiUpdateBusinessRequest, options?: AxiosRequestConfig) {
        return BusinessApiFp(this.configuration).updateBusiness(requestParameters.appId, requestParameters.businessId, requestParameters.requestBusinessUpdate, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary reemplaza las configuraciones de una razon social
     * @param {BusinessApiUpdateConfigurationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessApi
     */
    public updateConfigurations(requestParameters: BusinessApiUpdateConfigurationsRequest, options?: AxiosRequestConfig) {
        return BusinessApiFp(this.configuration).updateConfigurations(requestParameters.appId, requestParameters.businessId, requestParameters.requestUpdateConfigurations, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}

