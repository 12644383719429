/* eslint-disable prettier/prettier */

import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '@ui5/webcomponents/dist/Dialog.js';
import Swal from 'sweetalert2';
import '@vaadin/vaadin-select';
import {
  CommunicationTerminalApi,
  CommunicationTerminalApiRequestCommandGroupRequest,
  CommunicationTerminalApiRequestCommandRequest,
  RequestCommandTypeCommandEnum,
  Terminal,
  Group 
} from '../../../api';
import { errorNotification } from '../../services/notifications';
import { apisConfigurator } from '../../utils/axios';

@customElement('enviar-mensaje-modal-grupo')
export class EnviarMensajeModalGrupo extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private communicationApi: CommunicationTerminalApi;

  constructor() {
    super();

    this.communicationApi = new CommunicationTerminalApi(
      ...apisConfigurator('Comunicación')
    );
  }

  @property({ type: Object })
  grupo?: any;

  @state()
  private titulo = '';

  @state()
  private mensaje = '';

  private get dialog() {
    return document.getElementById('dialog-enviar-mensaje')! as any;
  }

  protected firstUpdated(): void {}

  async getOrganizaciones() {}

  async open(grupo: any) {
    if (!grupo) {
      errorNotification('Error al editar grupo');
      return;
    }

    this.getOrganizaciones();

    this.grupo = grupo;
    this.dialog.open = true;
  }

  close() { 
    this.dialog.open = false;
    this.grupo = undefined;
  }

  private async handleSubmit(e: Event) {
    /*e.preventDefault();

    console.log('terminal', this.terminal);
    if (!this.terminal) return;
    const requestCommand: CommunicationTerminalApiRequestCommandRequest = {
      brand: this.terminal.brand,
      serial: this.terminal.serial,
      requestCommand: {
        commandId: `displayMessage-${this.terminal.serial}-${Date.now()}`,
        typeCommand: RequestCommandTypeCommandEnum.DisplayMessage,
        parameters: [this.titulo, this.mensaje ?? this.titulo],
      },
    };*/
    e.preventDefault();
    const requestCommand: CommunicationTerminalApiRequestCommandGroupRequest =
    {
      group: this.grupo?.id,
      requestCommand: {
        commandId: `displayMessage-${this.grupo?.id}-${Date.now()}`,
        typeCommand: RequestCommandTypeCommandEnum.DisplayMessage,
        parameters: [this.titulo, this.mensaje ?? this.titulo],
      },
    };
    //console.log(requestCommand);


    try {
        this.close();
        const res = await this.communicationApi.requestCommandGroup(
            requestCommand
        );
        //console.log('envio mensaje res grupo', res);
        if(res.status == 200) {
          Swal.fire(
            'Completado!',
            'El mensaje ha sido enviado.',
            'success'
          )
        }

    } catch (err: any) {
      console.log('envio mensaje err', err);
    }
  }

  protected render() {
    return html` <ui5-dialog
      id="dialog-enviar-mensaje"
      header-text="Enviar mensaje a ${this.grupo?.brand} - ${this.grupo
        ?.serial}"
      class="w-3/12"
    >
      <form
        class="px-4 flex flex-col items-center"
        @submit=${this.handleSubmit}
      >
        <div class="mx-2 w-full flex flex-col items-center">
          <mwc-textfield
            class="my-2 w-3/4"
            outlined
            label="Titulo"
            @change=${(e: any) => (this.titulo = e.target.value)}
            required
          ></mwc-textfield>
          <mwc-textfield
            class="my-2 w-3/4"
            outlined
            @change=${(e: any) => (this.mensaje = e.target.value)}
            label="Mensaje"
          ></mwc-textfield>
        </div>

        <div slot="footer" class="dialog-footer">
          <button
            class="text-red-600 p-4 rounded-md uppercase"
            id="closeDialogButton"
            @click=${() => {
              this.close();
            }}
          >
            Cerrar
          </button>
          <button
            class="text-blue-600 p-4 rounded-md uppercase"
            id="closeDialogButton"
            type="submit"
          >
            Confirmar
          </button>
        </div>
      </form>
    </ui5-dialog>`;
  }
}
