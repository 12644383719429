/* eslint-disable prettier/prettier */
import { html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';

import '@material/mwc-icon-button';
import '@material/mwc-list/mwc-list.js';
import '@material/mwc-list/mwc-list-item.js';
import '@material/mwc-list/mwc-list.css';
import '@material/mwc-list/mwc-list-item.css';

import '../../components/DashboardGraphCard/DashboardGraphCard';
import '../../components/DashboardCounter/DashboardCounter';
import '../../components/VerticalBarGraph/VerticalBarGraph';
import '../../components/DashboardWidgetContainer/DashboardWidgetCointainer';
import '../../components/CustomMap/CustomMap';
import './Dashboard.css';
import { Widget, WidgetApi } from '../../../api';
import { apisConfigurator } from '../../utils/axios';
import { buildDashboardWidgets } from '../../utils/dashboard_widgets';
import store from '../../redux/store';
import { State } from '../../redux/types';
@customElement('dashboard-page')
export class CrearUsuario extends LitElement {
  private widgetApi: WidgetApi;

  @state()
  private loading = false;

  @state()
  private user?: string;

  @state()
  private widgets: Widget[] = [];

  constructor() {
    super();
    this.widgetApi = new WidgetApi(...apisConfigurator('Dashboard'));
  }

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  protected firstUpdated(): void {
    this.user = (store.getState() as State).usuario;

    this.getWidgets();
  }

  async getWidgets() {
    if (!this.user) return;

    this.loading = true;
    const res = await this.widgetApi.getAllWidget();
    this.widgets = res.data;

    this.loading = false;
  }

  render() {
    return html`
      ${this.loading
        ? html`
            <main class="w-full min-h-full flex justify-center items-center">
              <mwc-circular-progress
                indeterminate
                density=${20}
              ></mwc-circular-progress>
            </main>
          `
        : html`
            <main class="w-full min-h-full flex flex-wrap px-5 py-4 ColorFondo">
              <!-- <card-a .customSlot=${html`<p>Nombre widget</p>`}></card-a>
            <card-b .customSlot=${html`<p>Nombre widget</p>`}></card-b> -->
              <card-d
                .customSlot=${html` 
                  <h2 class="text-xl text-blue mb-0">
                    Ubicación de terminales
                  </h2>
                  <custom-map></custom-map> 
                  <!-- <img class="w-full rounded" src="./google-map.PNG" /> -->
                `}
              ></card-d> 

              ${buildDashboardWidgets(this.widgets).map(w => w)}
              <div class="w-full p-3 flex justify-center items-center">
                <mwc-button
                  style="
              --mdc-button-raised-box-shadow: 0;
              --mdc-theme-primary: #E3E1EC;
              --mdc-theme-on-primary: #A3A3A3;"
                  raised
                  class="w-3/4 rounded"
                  icon="add"
                  >Agregar widget</mwc-button
                >
              </div>
            </main>
          `}
    `;
  }
}
