/* tslint:disable */
/* eslint-disable */
/**
 * TMS Agnostiko
 * Tiene la definición de todos los servicios de TMS a Agnostic (para uso en la web o externo)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { App } from '../models';
// @ts-ignore
import { AppList } from '../models';
// @ts-ignore
import { Message } from '../models';
// @ts-ignore
import { RequestAppCreate } from '../models';
// @ts-ignore
import { RequestAppUpdate } from '../models';
// @ts-ignore
import { ResponseAppCreate } from '../models';
/**
 * AppApi - axios parameter creator
 * @export
 */
export const AppApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary actualiza las imágenes de una app
         * @param {string} id 
         * @param {AddPicturesFunctionEnum} _function 
         * @param {File} picture 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {string} [ordinal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPictures: async (id: string, _function: AddPicturesFunctionEnum, picture: File, acceptLanguage?: string, ordinal?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addPictures', 'id', id)
            // verify required parameter '_function' is not null or undefined
            assertParamExists('addPictures', '_function', _function)
            // verify required parameter 'picture' is not null or undefined
            assertParamExists('addPictures', 'picture', picture)
            const localVarPath = `/app/{id}/pictures`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }

            if (_function != null) {
                localVarHeaderParameter['function'] = String(_function);
            }

            if (ordinal != null) {
                localVarHeaderParameter['ordinal'] = String(ordinal);
            }


            if (picture !== undefined) { 
                localVarFormParams.append('picture', picture as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Realiza la creación de una aplicación
         * @param {RequestAppCreate} requestAppCreate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApp: async (requestAppCreate: RequestAppCreate, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestAppCreate' is not null or undefined
            assertParamExists('createApp', 'requestAppCreate', requestAppCreate)
            const localVarPath = `/app`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestAppCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary elimina un app
         * @param {string} id 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApp: async (id: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApp', 'id', id)
            const localVarPath = `/app/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary actualiza las imágenes de una app
         * @param {string} id 
         * @param {DeletePicturesFunctionEnum} _function 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {string} [ordinal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePictures: async (id: string, _function: DeletePicturesFunctionEnum, acceptLanguage?: string, ordinal?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePictures', 'id', id)
            // verify required parameter '_function' is not null or undefined
            assertParamExists('deletePictures', '_function', _function)
            const localVarPath = `/app/{id}/pictures`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }

            if (_function != null) {
                localVarHeaderParameter['function'] = String(_function);
            }

            if (ordinal != null) {
                localVarHeaderParameter['ordinal'] = String(ordinal);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Obtener una app
         * @param {string} id 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApp: async (id: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApp', 'id', id)
            const localVarPath = `/app/list/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Obtener la lista de apps
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listApp: async (acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/app/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary actualiza un app
         * @param {string} id 
         * @param {RequestAppUpdate} requestAppUpdate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApp: async (id: string, requestAppUpdate: RequestAppUpdate, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateApp', 'id', id)
            // verify required parameter 'requestAppUpdate' is not null or undefined
            assertParamExists('updateApp', 'requestAppUpdate', requestAppUpdate)
            const localVarPath = `/app/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestAppUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppApi - functional programming interface
 * @export
 */
export const AppApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary actualiza las imágenes de una app
         * @param {string} id 
         * @param {AddPicturesFunctionEnum} _function 
         * @param {File} picture 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {string} [ordinal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPictures(id: string, _function: AddPicturesFunctionEnum, picture: File, acceptLanguage?: string, ordinal?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPictures(id, _function, picture, acceptLanguage, ordinal, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppApi.addPictures']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Realiza la creación de una aplicación
         * @param {RequestAppCreate} requestAppCreate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createApp(requestAppCreate: RequestAppCreate, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseAppCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createApp(requestAppCreate, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppApi.createApp']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary elimina un app
         * @param {string} id 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApp(id: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApp(id, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppApi.deleteApp']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary actualiza las imágenes de una app
         * @param {string} id 
         * @param {DeletePicturesFunctionEnum} _function 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {string} [ordinal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePictures(id: string, _function: DeletePicturesFunctionEnum, acceptLanguage?: string, ordinal?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePictures(id, _function, acceptLanguage, ordinal, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppApi.deletePictures']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Obtener una app
         * @param {string} id 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApp(id: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<App>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApp(id, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppApi.getApp']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Obtener la lista de apps
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listApp(acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listApp(acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppApi.listApp']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary actualiza un app
         * @param {string} id 
         * @param {RequestAppUpdate} requestAppUpdate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApp(id: string, requestAppUpdate: RequestAppUpdate, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateApp(id, requestAppUpdate, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppApi.updateApp']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AppApi - factory interface
 * @export
 */
export const AppApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppApiFp(configuration)
    return {
        /**
         * 
         * @summary actualiza las imágenes de una app
         * @param {AppApiAddPicturesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPictures(requestParameters: AppApiAddPicturesRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.addPictures(requestParameters.id, requestParameters._function, requestParameters.picture, requestParameters.acceptLanguage, requestParameters.ordinal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Realiza la creación de una aplicación
         * @param {AppApiCreateAppRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApp(requestParameters: AppApiCreateAppRequest, options?: AxiosRequestConfig): AxiosPromise<ResponseAppCreate> {
            return localVarFp.createApp(requestParameters.requestAppCreate, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary elimina un app
         * @param {AppApiDeleteAppRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApp(requestParameters: AppApiDeleteAppRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.deleteApp(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary actualiza las imágenes de una app
         * @param {AppApiDeletePicturesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePictures(requestParameters: AppApiDeletePicturesRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.deletePictures(requestParameters.id, requestParameters._function, requestParameters.acceptLanguage, requestParameters.ordinal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Obtener una app
         * @param {AppApiGetAppRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApp(requestParameters: AppApiGetAppRequest, options?: AxiosRequestConfig): AxiosPromise<App> {
            return localVarFp.getApp(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Obtener la lista de apps
         * @param {AppApiListAppRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listApp(requestParameters: AppApiListAppRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<AppList>> {
            return localVarFp.listApp(requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary actualiza un app
         * @param {AppApiUpdateAppRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApp(requestParameters: AppApiUpdateAppRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.updateApp(requestParameters.id, requestParameters.requestAppUpdate, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addPictures operation in AppApi.
 * @export
 * @interface AppApiAddPicturesRequest
 */
export interface AppApiAddPicturesRequest {
    /**
     * 
     * @type {string}
     * @memberof AppApiAddPictures
     */
    readonly id: string

    /**
     * 
     * @type {'icon' | 'brief' | 'detail'}
     * @memberof AppApiAddPictures
     */
    readonly _function: AddPicturesFunctionEnum

    /**
     * 
     * @type {File}
     * @memberof AppApiAddPictures
     */
    readonly picture: File

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof AppApiAddPictures
     */
    readonly acceptLanguage?: string

    /**
     * 
     * @type {string}
     * @memberof AppApiAddPictures
     */
    readonly ordinal?: string
}

/**
 * Request parameters for createApp operation in AppApi.
 * @export
 * @interface AppApiCreateAppRequest
 */
export interface AppApiCreateAppRequest {
    /**
     * 
     * @type {RequestAppCreate}
     * @memberof AppApiCreateApp
     */
    readonly requestAppCreate: RequestAppCreate

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof AppApiCreateApp
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteApp operation in AppApi.
 * @export
 * @interface AppApiDeleteAppRequest
 */
export interface AppApiDeleteAppRequest {
    /**
     * 
     * @type {string}
     * @memberof AppApiDeleteApp
     */
    readonly id: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof AppApiDeleteApp
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deletePictures operation in AppApi.
 * @export
 * @interface AppApiDeletePicturesRequest
 */
export interface AppApiDeletePicturesRequest {
    /**
     * 
     * @type {string}
     * @memberof AppApiDeletePictures
     */
    readonly id: string

    /**
     * 
     * @type {'icon' | 'brief' | 'detail'}
     * @memberof AppApiDeletePictures
     */
    readonly _function: DeletePicturesFunctionEnum

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof AppApiDeletePictures
     */
    readonly acceptLanguage?: string

    /**
     * 
     * @type {string}
     * @memberof AppApiDeletePictures
     */
    readonly ordinal?: string
}

/**
 * Request parameters for getApp operation in AppApi.
 * @export
 * @interface AppApiGetAppRequest
 */
export interface AppApiGetAppRequest {
    /**
     * 
     * @type {string}
     * @memberof AppApiGetApp
     */
    readonly id: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof AppApiGetApp
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for listApp operation in AppApi.
 * @export
 * @interface AppApiListAppRequest
 */
export interface AppApiListAppRequest {
    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof AppApiListApp
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateApp operation in AppApi.
 * @export
 * @interface AppApiUpdateAppRequest
 */
export interface AppApiUpdateAppRequest {
    /**
     * 
     * @type {string}
     * @memberof AppApiUpdateApp
     */
    readonly id: string

    /**
     * 
     * @type {RequestAppUpdate}
     * @memberof AppApiUpdateApp
     */
    readonly requestAppUpdate: RequestAppUpdate

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof AppApiUpdateApp
     */
    readonly acceptLanguage?: string
}

/**
 * AppApi - object-oriented interface
 * @export
 * @class AppApi
 * @extends {BaseAPI}
 */
export class AppApi extends BaseAPI {
    /**
     * 
     * @summary actualiza las imágenes de una app
     * @param {AppApiAddPicturesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public addPictures(requestParameters: AppApiAddPicturesRequest, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).addPictures(requestParameters.id, requestParameters._function, requestParameters.picture, requestParameters.acceptLanguage, requestParameters.ordinal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Realiza la creación de una aplicación
     * @param {AppApiCreateAppRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public createApp(requestParameters: AppApiCreateAppRequest, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).createApp(requestParameters.requestAppCreate, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary elimina un app
     * @param {AppApiDeleteAppRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public deleteApp(requestParameters: AppApiDeleteAppRequest, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).deleteApp(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary actualiza las imágenes de una app
     * @param {AppApiDeletePicturesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public deletePictures(requestParameters: AppApiDeletePicturesRequest, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).deletePictures(requestParameters.id, requestParameters._function, requestParameters.acceptLanguage, requestParameters.ordinal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Obtener una app
     * @param {AppApiGetAppRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public getApp(requestParameters: AppApiGetAppRequest, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).getApp(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Obtener la lista de apps
     * @param {AppApiListAppRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public listApp(requestParameters: AppApiListAppRequest = {}, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).listApp(requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary actualiza un app
     * @param {AppApiUpdateAppRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public updateApp(requestParameters: AppApiUpdateAppRequest, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).updateApp(requestParameters.id, requestParameters.requestAppUpdate, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const AddPicturesFunctionEnum = {
    Icon: 'icon',
    Brief: 'brief',
    Detail: 'detail'
} as const;
export type AddPicturesFunctionEnum = typeof AddPicturesFunctionEnum[keyof typeof AddPicturesFunctionEnum];
/**
 * @export
 */
export const DeletePicturesFunctionEnum = {
    Icon: 'icon',
    Brief: 'brief',
    Detail: 'detail'
} as const;
export type DeletePicturesFunctionEnum = typeof DeletePicturesFunctionEnum[keyof typeof DeletePicturesFunctionEnum];
