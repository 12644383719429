/* eslint-disable prettier/prettier */

import { LitElement, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import '@ui5/webcomponents/dist/Dialog.js';
import '@vaadin/vaadin-select';
import { errorNotification } from '../../services/notifications';
import { apisConfigurator } from '../../utils/axios';
import '../CustomSelect/CustomSelect';
import {
  AppApi, 
  AppList,
  License,
  LicenseApi,
  Master,
  RequestCreateLicense,
  RequestUpdateLicense,
} from '../../../api';
import { TextField } from '@material/mwc-textfield';
import { CustomSelect } from '../CustomSelect/CustomSelect';

@customElement('agregar-licencia-modal')
export class AgregarLicenciaModal extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  @state()
  private loading = false;

  @state()
  private licenseUpdate?: License;

  @state()
  private appName?: string;

  @state()
  private packageName?: string;

  @state()
  private user?: Master;

  @state()
  private apps: AppList[] = [];

  private licenseApi: LicenseApi;
  private appApi: AppApi;

  get serial() {
    return document.getElementById('serial') as TextField;
  }

  get model() {
    return document.getElementById('model') as TextField;
  }

  get brand() {
    return document.getElementById('brand') as TextField;
  }

  get description() {
    return document.getElementById('description') as TextField;
  }

  get priority() {
    return document.getElementById('priority') as TextField;
  }

  get renewalTime() {
    return document.getElementById('renewalTime') as TextField;
  }

  get endDate() {
    return document.getElementById('endDate') as TextField;
  }

  get terminalLimit() {
    return document.getElementById('terminalLimit') as TextField;
  }

  get appSelected() {
    return document.getElementById('appSelect') as any;
  }

  get appOnUpdate() {
    return document.getElementById('app') as TextField;
  }

  constructor() {
    super();
    this.licenseApi = new LicenseApi(...apisConfigurator('Licencias'));
    this.appApi = new AppApi(...apisConfigurator('Licencias'));
  }

  private get dialog() {
    return document.getElementById('dialog-agregar-licencia')! as any;
  }

  protected firstUpdated(): void {}

  async open(user?: Master) {
    if (!user) {
      errorNotification('Error al agregar licencia');
      return;
    }

    this.user = user;

    const res = await this.appApi.listApp();
    this.apps = res.data;
    console.log(this.apps)

    this.dialog.open = true;
  }

  async updateLicense(li: License) {
    this.licenseUpdate = li;
    console.log("linsencia",this.apps) 
    //await this.getApp(li.app);

    this.dialog.open = true;
  }

  close() {
    this.dialog.open = false;
    this.user = undefined;
    this.apps = [];
    this.licenseUpdate = undefined;
  }

  private async handleSubmit(e: Event) {
    e.preventDefault();
  }

  private async onSubmit() {
    if (!this.validate()) return;
    console.log("DATOS LICENCIA",this.buildLicense())
    if (this.licenseUpdate) {
      const res = await this.licenseApi.updateLicense({
        requestUpdateLicense: this.buildLicense(),
        id: this.licenseUpdate.id,
      });
      this.dispatchLicenseUpdate();
      this.close();
      return;
    } 

    const res = await this.licenseApi.licenseCreate({
      requestCreateLicense: this.buildLicense(),
    });
    this.dispatchLicenseUpdate();
    this.close();
    return;
  }

  private dispatchLicenseUpdate() {
    const ev = new CustomEvent('LicensesUpdated');
    this.dispatchEvent(ev);
  }
 
  private buildLicense(): RequestCreateLicense | RequestUpdateLicense {
    console.log(this.appSelected) 
    //const found:any = this.apps.find((element) => element.packageName == license.app);
    return {
      app: this.licenseUpdate
      ? this.licenseUpdate.app
      : this.appSelected.value!,
      brand: this.brand.value,
      description: this.description.value,
      endDate: this.endDate.value,
      idMaster: this.licenseUpdate
        ? this.licenseUpdate!.idMaster
        : this.user!.master,
      model: this.model.value,
      priority: parseInt(this.priority.value),
      renewalTime: parseInt(this.renewalTime.value),
      serial: this.serial.value,
      terminalLimit: parseInt(this.terminalLimit.value),
    };
  }

  private validate() {
    let x = document.forms.namedItem('createUpdateLicense')!;

    if (!x.checkValidity()) {
      errorNotification('Por favor, revise los datos.');
      return false;
    }

    if (!this.serial.checkValidity()) {
      errorNotification('El serial es requerido.');
      return false;
    }

    if (!this.brand.checkValidity()) {
      errorNotification('La marca es requerida.');
      return false;
    }

    if (!this.model.checkValidity()) {
      errorNotification('El modelo es requerido.');
      return false;
    }

    if (!this.priority.checkValidity()) {
      errorNotification('La prioridad es requerida.');
      return false;
    }

    if (!this.renewalTime.checkValidity()) {
      errorNotification('El tiempo de renovacion es requerido.');
      return false;
    }

    if (!this.endDate.checkValidity()) {
      errorNotification('La fecha de expiracion es requerida.');
      return false;
    }

    if (!this.terminalLimit.checkValidity()) {
      errorNotification('El limite de terminales es requerido.');
      return false;
    }



    if (this.licenseUpdate) {
      if (!this.licenseUpdate?.app.length) {
        errorNotification('La aplicacion es requerida.');
        return false;
      }
    } else {
      if (!this.appSelected.value) {
        errorNotification('La aplicacion es requerida');
        return false;
      }
    }

    return true;
  }

  async getApp(appId: string) {
    const res = await this.appApi.getApp({ id: appId });
    console.log(res.data)
    this.appName = res.data.name;
    this.packageName = res.data.packageName;
  }

  protected render() {
    return html` <ui5-dialog
      id="dialog-agregar-licencia"
      header-text="${
        this.licenseUpdate ? 'Actualizar' : 'Agregar'
      } licencia a ${this.user?.master}"
      class="w-6/12 h-6/12"
    >
      <form
        class="px-4 flex flex-col items-center h-full"
        name="createUpdateLicense" 
        @submit=${this.handleSubmit}
      >
        <div class="mx-2 w-full flex flex-col items-center relative">
          <div class="flex flex-wrap justify-center">
            ${
              !this.licenseUpdate
                ? html`
                    <div class="w-[210px] mx-1">
                      <p class="p-2">Nombre Paquete</p>
                      <select 
                        class="w-[210px] mx-1 mb-3"
                        id="appSelect"
                        @change=${(ev: CustomEvent) => {
                          //this.getApp(ev.detail)
                        }}
                      >
                      ${this.apps.map((app:any,index:number)=> html`
                          <option value="${app.packageName}">${app.packageName}</option>
                      `)}

                    </select>
                    </div>
                  `
                : html` <div>
                    <p class="p-2">Aplicacion</p>
                    <mwc-textfield
                      .value=${this.appName ?? ''}
                      class="w-[210px] mx-1 mb-3"
                      validationMessage="La app es requerida"
                      id="app"
                      required
                      minLength="1"
                      autoValidate
                      outlined
                      disabled
                    ></mwc-textfield>
                  </div>`
            }
            
              <div>
                <p class="p-2">Serial</p><mwc-textfield
              required
              class="w-[210px] mx-1 mb-3"
              validationMessage="El serial es requerido"
              minLength="1"
              id="serial"
              .value=${this.licenseUpdate?.serial ?? ''}
              autoValidate
              outlined
            >
                </div>
            </mwc-textfield>
            
            <div>
                <p class="p-2">Marca</p><mwc-textfield
              required
              class="w-[210px] mx-1 mb-3"
              validationMessage="La marca es requerido"
              id="brand"
              minLength="1"
              .value=${this.licenseUpdate?.brand ?? ''}
              autoValidate
              outlined
            >
                </div>
            </mwc-textfield>
              <div>
                <p class="p-2">Modelo</p>
            <mwc-textfield
              required
              class="w-[210px] mx-1 mb-3"
              validationMessage="El modelo es requerido"
              id="model"
              minLength="1"
              .value=${this.licenseUpdate?.model ?? ''}
              autoValidate
              outlined
            >
            </mwc-textfield>
                </div>
              <div>
                <p class="p-2">Tiempo de Renovación</p>
            <mwc-textfield
              class="w-[210px] mx-1 mb-3"
              validationMessage="El tiempo de renovación es requerido"
              id="renewalTime"
              minLength="1"
              type="number"
              min="0"
              suffix="dias"
              .value=${this.licenseUpdate?.renewalTime ?? ''}
              required
              autoValidate
              outlined
            >
            </mwc-textfield>
                </div>
              <div>
                <p class="p-2">Prioridad</p>
            <mwc-textfield
              class="w-[210px] mx-1 mb-3"
              validationMessage="La prioridad es requerida"
              id="priority"
              minLength="1"
              type="number"
              min="0"
              max="99"
              step="1"
              .value=${this.licenseUpdate?.priority ?? ''}
              required
              autoValidate
              outlined
            >
            </mwc-textfield>
                </div>
              <div>
                <p class="p-2">Límite de terminales</p>
            <mwc-textfield
              class="w-[210px] mx-1 mb-3"
              validationMessage="El límite de terminales es requerido"
              id="terminalLimit"
              minLength="1"
              type="number"
              min="0"
              max="999"
              step="1"
              .value=${this.licenseUpdate?.terminalLimit ?? ''}
              required
              autoValidate
              outlined
            >
            </mwc-textfield>
                </div>
              <div>
                <p class="p-2">Fecha de expiración</p>
            <mwc-textfield
              class="w-[210px] mx-1 mb-3"
              validationMessage="La fecha de expiración es requerida"
              id="endDate"
              type="date"
              .value=${this.licenseUpdate?.endDate ?? ''}
              required
              autoValidate
              outlined
            >
            </mwc-textfield>
                </div>
              <div>
                <p class="p-2">Descripción</p>
            <mwc-textfield
              class="w-[210px] mx-1 mb-3"
              validationMessage="La descripción es requerida"
              
              id="description"
              .value=${this.licenseUpdate?.description ?? ''}
              outlined
            >
            </mwc-textfield>
                </div>
          </div>
        </div>

        <div slot="footer" class="dialog-footer mt-auto">
          <button
            class="text-red-600 p-4 rounded-md uppercase"
            id="closeDialogButton"
            @click=${() => {
              this.close();
            }}
          >
            Cerrar
          </button>
          <button
            class="text-blue-600 p-4 rounded-md uppercase"
            id="closeDialogButton"
            type="submit"
            .disabled=${this.loading}
            @click=${this.onSubmit}
          >
            Guardar
          </button>
        </div>
      </form>
    </ui5-dialog>`;
  }
}
