/* eslint-disable prettier/prettier */
import { Chart, registerables } from 'chart.js';
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import ChartDataLabels from 'chartjs-plugin-datalabels';
import { TerminalStatisticApi } from '../../../api';
import { apisConfigurator } from '../../utils/axios';
Chart.register(...registerables);
Chart.register(ChartDataLabels);

@customElement('vertical-bar-graph')
export class VerticalBarGraph extends LitElement {
  private widgetApi: TerminalStatisticApi;

  constructor() {
    super();
    this.widgetApi = new TerminalStatisticApi(...apisConfigurator('Widget'));
  }

  @property({ type: String })
  id = 'bar-chart';

  @property({ type: String })
  legendPosition: 'bottom' | 'left' | 'right' | 'top' | 'center' = 'bottom';

  @property({ type: Boolean })
  showTotal = true;

  @property({ type: Boolean })
  showDataLabels = true;

  @property({ type: Boolean })
  showYLabels = true;

  @property({ type: String })
  titulo = 'Terminales';

  @property({ type: String })
  idWidget!: string;

  @property({ type: Array })
  data: number[] = [];

  @property({ type: Array })
  dataLabels = ['Tienda', 'Tienda', 'Tienda'];

  @property({ type: Array })
  dataColors = ['#2FB83D', '#2E3EAE', '#5F308B', '#EF873E', '#C63461'];

  protected firstUpdated(): void {
    const canvas = this.shadowRoot?.getElementById(
      this.id
    ) as HTMLCanvasElement;

    const datasets = this.data.map((data, index) => {
      return {
        label: this.dataLabels[index],
        data: data,
        backgroundColor: this.dataColors[index],
        borderRadius: 12,
      };
    });

    new Chart(canvas, {
      type: 'bar',
      data: {
        labels: this.dataLabels,
        datasets,
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              padding: 15,
            },
          },
          datalabels: {
            display: false,
          },
          title: {
            display: false,
          },
        },
      },
    });
  }

  render() {
    return html`<div class="VerticalBarGraph">
      <canvas id="${this.id}" width="800" height="350"> </canvas>
    </div>`;
  }

  static get styles() {
    return css`
      .VerticalBarGraph {
        position: relative;
      }
    `;
  }
}
