/* eslint-disable prettier/prettier */
import { Chart, registerables } from 'chart.js';
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(...registerables);
Chart.register(ChartDataLabels);

@customElement('donut-chart')
export class DonutChart extends LitElement {
  @property({ type: String })
  id = 'donut-chart';

  @property({ type: String })
  legendPosition: 'bottom' | 'left' | 'right' | 'top' | 'center' = 'bottom';

  @property({ type: Boolean })
  showTotal = true;

  @property({ type: Boolean })
  showDataLabels = true;

  @property({ type: String })
  titulo = 'Terminales';

  @property({ type: Array })
  data = [75, 25];

  @property({ type: Array })
  dataLabels = ['Activos', 'Inactivos'];

  @property({ type: Array })
  dataColors = [
    'rgb(255, 99, 132)',
    'rgb(255, 159, 64)',
    'rgb(255, 205, 86)',
    'rgb(75, 192, 192)',
    'rgb(54, 162, 235)',
  ];

  protected firstUpdated(): void {
    const canvas = this.shadowRoot?.getElementById(
      this.id
    ) as HTMLCanvasElement;

    new Chart(canvas, {
      type: 'doughnut',
      data: {
        labels: this.dataLabels,
        datasets: [
          {
            label: this.titulo,
            backgroundColor: this.dataColors,
            data: this.data,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            position: this.legendPosition,
            labels: {
              padding: 15,
            },
          },
          datalabels: {
            display: this.showDataLabels,
            backgroundColor: '#ccc',
            borderRadius: 3,
            anchor: 'end',
            font: {
              weight: 'bold',
            },
            align: 'end',
          },
          title: {
            display: true,
            text: this.titulo,
            font: {
              size: 20,
            },
          },
          tooltip: {
            enabled: !this.showDataLabels,
            bodyColor: '#000',

            titleFont: {
              size: 16,
            },
            bodyFont: {
              size: 14,
            },
            titleColor: '#0066ff',
            backgroundColor: '#FFFBFF',
          },
        },
        cutout: '90%',
      },
    });
  }

  render() {
    return html`<div class="donutChart">
  <canvas id="${this.id}" width="300" height="350"> </canvas>
  ${this.showTotal
        ? html`
  <div class="centerLabel">
    <p>${this.data.reduce((p, c) => p + c)}</p>
    <span>Total</span>
  </div>
  `
        : ''}
</div>`;
  }

  static get styles() {
    return css`
      .donutChart {
        position: relative;
      }

      .centerLabel {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-45%, -50%);
        font-size: 1.2em;
        font-weight: bold;
        text-align: center;
        display: flex;
        flex-direction: column;
        
      }

      .centerLabel * {
        margin: 0;
      }

      .centerLabel p {
        font-size: 2em;
      }
    `;
  }
}
