/* eslint-disable prettier/prettier */
import { html, LitElement, render } from 'lit';
import { guard } from 'lit/directives/guard.js';
import { customElement, state } from 'lit/decorators.js';
import Swal from 'sweetalert2';
import { connect } from 'pwa-helpers';
import '@ui5/webcomponents/dist/Tree.js';
import '@ui5/webcomponents/dist/Icon.js';
import '@ui5/webcomponents-icons/dist/it-instance.js';
import '@ui5/webcomponents-icons/dist/group-2.js';
import '@ui5/webcomponents-icons/dist/alert.js';
import '@ui5/webcomponents-icons/dist/cart.js';
import '@ui5/webcomponents-icons/dist/bell.js';
import '@material/mwc-icon';
import '@material/mwc-icon-button';
import '@vaadin/vaadin-grid'; 
import { State } from '../../redux/types';
import {
  Grid,
  GridActiveItemChangedEvent,
  GridItemModel,
} from '@vaadin/vaadin-grid';
import '../../components/modals/EditOrganizationModal';
import router from '../../router/router.js';
import {
    RoleApiSecurityRolDeleteRequest,
    AppList,
    RoleApi,
    RoleList,
    RoleApiSecurityRolGetRequest

  } from '../../../api';
import {
  Terminal,
} from '../../../api/index.js';
import store from '../../redux/store.js';
import { editarTerminal } from '../../redux/actions/index.js';
import '../../components/modals/PushConfigurationsDialog';
import '../../components/modals/UbicacionTerminalMapa';
import { EditOrganizationModal } from '../../components/modals/EditOrganizationModal.js';
import { apisConfigurator } from '../../utils/axios';
import { getApps } from '../../redux/actions';
import { Unsubscribe } from '@reduxjs/toolkit';


@customElement('roles-list-page')
export class RolesList extends connect(store)(LitElement) {
  private roleApi: RoleApi;


  private storeUnsuscriber?: Unsubscribe;

  private constructor() {
    super();

    this.roleApi = new RoleApi(...apisConfigurator('Role'));
  }


  async stateChanged(state: any) {}

  @state()
  private apps: AppList[] = [];

  @state()
  termi?: Terminal;

  @state()
  templatesInfo:any = [];

  @state()
  objectoBand:Object[] = [];

  @state()
  template:any = 'Crear Terminal';

  @state()
  rolesList?: any;

  @state()
  app?: any;

  @state()
  band?: any = false;

  @state()
  idApp?: any;

  @state()
  appId?: any;

  @state()
  rol?: any;

  @state()
  afiliacion?: any;

  @state()
  versions?: any;

  @state()
  modulos?: any;

  @state()
  NameBtn = 'Crear Terminal';

  @state()
  detail = false;

  @state()
  detail2 = false;

  @state()
  folioList:any = false;

  @state()
  organizacion = '';

  @state()
  private detailsOpenedItem:any = "";

  @state()
  loader = true;

  @state()
  brand = '';

  @state()
  serial = '';


  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  disconnectedCallback(): void {
    this.storeUnsuscriber && this.storeUnsuscriber();
  } 


  protected async firstUpdated(){
    this.ObtenerRoles("")
  }

  async ObtenerRoles(appId: any) {
    this.loader = true;
    await this.roleApi
      .securityRolList()
      .then(response => response.data)
      .then(data => {
        this.rolesList = data;
        console.log(data)
        this.loader = false;
      });
  }

  async getRol(roleId: any) {
    let data:RoleApiSecurityRolGetRequest = {
        id:roleId
      }

    await this.roleApi
        .securityRolGet(data)
        .then(response => response.data)
        .then(data => {
        console.log(data)
        this.rol = data;
        this.modulos = data.functions;
        localStorage.removeItem('role');
        const roleString = JSON.stringify(this.rol);
        localStorage.setItem('role', roleString);
    });
      
  }

  async deleteRole(roleId: any) {
    let data:RoleApiSecurityRolDeleteRequest = {
      id:roleId
    }
    const respuestaSwal = await Swal.fire({
      title: 'Estas Seguro?',
      text: '¡No podrás revertir esto!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si!',
    });
    //console.log({user:user.email})
    if (respuestaSwal.isConfirmed) {
     await this.roleApi
        .securityRolDelete(data)
        .then(response => response.data)
        .then(data => {
          console.log(data)
          if(data.code==="role.delete.ok"){
            Swal.fire(
              'Eliminado!',
              'Rol Eliminado.', 
              'success'
            )
          }
          location.reload();
      });
    }
  }


  render() {
    return html`
      <main
        class="w-full h-full FondoColor flex  ${!this.loader
          ? ''
          : 'items-center justify-center'}"
      >
        ${this.loader !== true
          ? html`
              <section
                class="w-3/4 relative flex flex-wrap p-4 SectionList rounded-l-lg"
              >
                </div>
                <vaadin-grid
                  class="w-screen h-full"
                  .items="${this.rolesList}"
                  .detailsOpenedItems="${this.detailsOpenedItem}"
                  @active-item-changed="${(
                    e: GridActiveItemChangedEvent<RoleList>
                  ) => { 
                    this.afiliacion = e.detail.value; 
                    this.detailsOpenedItem =[e.detail.value]; 
                    this.getRol(this.afiliacion.id)
                    localStorage.removeItem('roleId');
                    const roleString = JSON.stringify(this.afiliacion.id);
                    localStorage.setItem('roleId', roleString);
                    if (!e.detail.value) return false;

                  }}"
                  .rowDetailsRenderer="${guard(
                    [],
                    () =>
                      (
                        root: HTMLElement,
                        _: Grid,
                        model: GridItemModel<RoleList>
                      ) => {

                        render(
                          html`<vaadin-form-layout
                            .responsiveSteps=" ${[
                              { minWidth: '0', columns: 3 },
                            ]}"
                          >
                            <div
                            style="background-color:#F0EFFF;"
                              class="w-full h-14 flex items-center mt-5 justify-center flex-row "
                            >
                               <div 
                                class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                                @click=${() => {
                                  router.cambiarSeccion('edit-rol-page'); 
                                }}
                              >  
                                <div>  
                                  <mwc-icon-button icon="edit">
                                  </mwc-icon-button>
                                </div> 
                                <div> 
                                  <p class="text-center">Editar</p>
                                </div>
                              </div>  
                              <div 
                                class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                                @click=${() => {
                                  this.deleteRole(model.item.id)
                                }}
                              >  
                                <div> 
                                  <mwc-icon-button icon="delete">
                                  </mwc-icon-button>
                                </div> 
                                <div>
                                  <p class="text-center">Eliminar</p>
                                </div>
                              </div>  
                            </div>
                          </vaadin-form-layout>`,
                          root
                        );
                      }
                  )}"
                > 
                  <vaadin-grid-filter-column
                  header="Name"
                    path="name" 
                  ></vaadin-grid-filter-column>
                  <vaadin-grid-filter-column
                  header="Descripcion"
                    path="description" 
                  ></vaadin-grid-filter-column>
                <mwc-icon-button 
                  icon="autorenew"
                  class="absolute top-0 right-0"
                  @click=${() => {
                  }}
                ></mwc-icon-button>
                <edit-organization-modal
                  id="edit-organization-modal"
                ></edit-organization-modal>
              </section>
              <section class="w-1/4  SectionDetail justify-center scroll">

                
                <div class="w-full flex justify-center">
                  <mwc-button
                    raised
                    class="rounded w-4/5 mx-auto mt-4 mb-2"
                    style="--mdc-theme-secundary: #2E3EAE; --mdc-theme-on-secundary: white;"
                    @click="${() => {
                      router.cambiarSeccion('create-rol');
                    }}"
                  >
                  <mwc-icon>add_circle</mwc-icon>
                    Crear Role
                  </mwc-button>
                </div>                
                <div class=" pt-3 flex items-center ">
              <p class="text-base	text-blue-500	ml-3 "></p>
            </div>
              </section>
            `
          : html`
              <mwc-circular-progress
                indeterminate
                density=${20}
              ></mwc-circular-progress>
            `}
      </main>

      <ubicacion-terminal-mapa
        id="ubicacionTerminalMapa"
      ></ubicacion-terminal-mapa>
      <cargar-archivo-modal id="cargarArchivoModal"></cargar-archivo-modal>
      <crear-folio-afiliacion id="crearFolioAfiliacion"></crear-folio-afiliacion>
      <instalar-app-modal id="instalarAppModal"></instalar-app-modal>
      <push-config-modal id="pushConfigModal"></push-config-modal>
      <enviar-mensaje-modal id="enviarMensajeModal"></enviar-mensaje-modal>
      <crear-folio id="crearFolio"></crear-folio>
      <historial-comandos-modal
        id="historialComandosModal"
      ></historial-comandos-modal> 
      <descargar-instalador-modal
        id="descargarInstaladorModal"
      ></descargar-instalador-modal>
    `;
  }
}
