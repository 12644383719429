/* eslint-disable prettier/prettier */

import { LitElement, html, render } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import '@vaadin/vaadin-grid';
import '@ui5/webcomponents/dist/Dialog.js';
import { connect } from 'pwa-helpers';
import '@vaadin/vaadin-select';
import {
  TemplateApi,
  GroupList,
  SavedConfigurationApi,
  ConfigurationInfo1,
  ConfigurationInfo,
  ResponseTemplateGet,
  AppList,
  AppApi,
} from '../../../api';
import store from '../../redux/store';
import {
  errorNotification,
  succesNotification,
} from '../../services/notifications';
import { apisConfigurator } from '../../utils/axios';
import { GridItemModel } from '@vaadin/vaadin-grid';

@customElement('asignar-preconfig')
export class AsignarPreConfiguracionModal extends connect(store)(LitElement) {
  private savedConfigurationApi: SavedConfigurationApi;
  private templateApi: TemplateApi;
  private appApi: AppApi;

  constructor() {
    super();
    this.templateApi = new TemplateApi(...apisConfigurator(''));
    this.appApi = new AppApi(...apisConfigurator(''));
    this.savedConfigurationApi = new SavedConfigurationApi(
      ...apisConfigurator('')
    );
  }

  @state()
  apps: AppList[] = [];

  @state()
  savedConfigurations: SavedConfTemplate[] = [];

  @state()
  group?: GroupList;

  @state()
  savedConfigurationSelected?: ConfigurationInfo1;

  @state()
  appSelected?: AppList;

  @state()
  appAux?: AppList;

  @state()
  private loading = false;

  protected createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private get dialog() {
    return document.getElementById('dialog-asignar-preconfig')! as any;
  }

  async open(group: GroupList) {
    console.log(group);
    this.group = group;

    this.loading = true;

    await this.getApps();
    await this.getSavedConfigurations();

    this.loading = false;

    this.dialog.open = true;
  }

  close() {
    this.dialog.open = false;
    this.savedConfigurations = [];
    this.apps = [];
    this.group = undefined;
    this.appSelected = undefined;
    this.savedConfigurationSelected = this.appSelected;
  }

  async getSavedConfigurations() {
    this.loading = true;
    try {
      const { data } = await this.savedConfigurationApi.listSavedConfiguration(
        {}
      );
      const confAux = [];
      for (const conf of data) {
        if (conf.templateId === '') continue;
        const template = await this.getTemplate(conf);

        confAux.push({ savedConfiguration: conf, template });
      }
      this.savedConfigurations = confAux;
    } catch (error) {
      console.log(error);
      errorNotification('Error al obtener las pre-configuraciones');
    } finally {
      this.loading = false;
    }
  }

  async getApps() {
    const { data } = await this.appApi.listApp({});
    this.apps = data;
  }

  async getTemplate(config: ConfigurationInfo1) {
    console.log(config);
    const { data } = await this.templateApi.getTemplate({
      id: config.templateId,
    });

    return data;
  }

  async handleSubmit() {
    if (!this.savedConfigurationSelected || !this.appSelected) {
      errorNotification('Selecciona una pre-configuracion');
      return;
    }

    try {
      await this.savedConfigurationApi.copySavedConfiguration({
        requestCopyConfiguration: {
          groupId: this.group!.id,
          id: this.savedConfigurationSelected.id,
          appId: this.appSelected.id,
        },
      });

      succesNotification('Pre-configuración guardada');

      this.close();
    } catch (error) {
      console.log(error);

      errorNotification('A ocurrido un error, intentalo de nuevo');
      this.close();
    }
  }

  protected render() {
    return html`
      <ui5-dialog
        id="dialog-asignar-preconfig"
        class="w-8/12"
        style="height:600px;border-radius: 15px;"
      >
        <section class="w-full flex flex-col p-4 max-h-full">
          <h2 class="my-2 text-2xl">
            Asignar Pre-Configuracion a ${this.group?.name}
          </h2>

          ${!this.appSelected
            ? html`
                <vaadin-grid .items=${this.apps}>
                  <vaadin-grid-column
                    width="3em"
                    flex-grow="0"
                    .renderer="${(
                      root: HTMLElement,
                      _: any,
                      model: GridItemModel<AppList>
                    ) => {
                      render(
                        html`
                          <input
                            type="radio"
                            name="platform"
                            .checked=${this.appAux === model.item}
                            @change="${(_: InputEvent) => {
                              this.appAux = model.item;
                            }}"
                          />
                        `,
                        root
                      );
                    }}"
                  >
                  </vaadin-grid-column>
                  <vaadin-grid-sort-column
                    header="Nombre"
                    .renderer=${(
                      root: HTMLElement,
                      __: any,
                      model: GridItemModel<AppList>
                    ) => {
                      render(html` <p>${model.item.name}</p> `, root);
                    }}
                  ></vaadin-grid-sort-column>
                  <vaadin-grid-column
                    header="Nombre de Paquete"
                    .renderer=${(
                      root: HTMLElement,
                      __: any,
                      model: GridItemModel<AppList>
                    ) => {
                      render(html` <p>${model.item.packageName}</p> `, root);
                    }}
                  ></vaadin-grid-column>
                  <vaadin-grid-column
                    header="Descripción"
                    .renderer=${(
                      root: HTMLElement,
                      __: any,
                      model: GridItemModel<AppList>
                    ) => {
                      render(
                        html` <p>${model.item.briefDescription}</p> `,
                        root
                      );
                    }}
                  ></vaadin-grid-column>
                </vaadin-grid>

                <div class="flex flex-row-reverse">
                  <button
                    .disabled=${false}
                    @click=${() => {
                      this.appSelected = this.appAux;
                    }}
                    class="rounded-3xl mx-3 my-3 w-28 InstalarApp	"
                  >
                    Seleccionar
                  </button>
                  <button
                    @click=${() => {
                      this.close();
                    }}
                    class="rounded-3xl mx-3 my-3 mr-2 border w-28  border-indigo-500 text-blue-500  CancelarG  hover:shadow-md hover:bg-slate-300 text-xs	"
                  >
                    Cancelar
                  </button>
                </div>
              `
            : html`
                <vaadin-grid .items=${this.savedConfigurations}>
                  <vaadin-grid-column
                    width="3em"
                    flex-grow="0"
                    .renderer="${(
                      root: HTMLElement,
                      _: any,
                      model: GridItemModel<SavedConfTemplate>
                    ) => {
                      render(
                        html`
                          <input
                            type="radio"
                            name="platform"
                            .checked=${this.savedConfigurationSelected ===
                            model.item.savedConfiguration}
                            @change="${(_: InputEvent) => {
                              this.savedConfigurationSelected =
                                model.item.savedConfiguration;
                            }}"
                          />
                        `,
                        root
                      );
                    }}"
                  >
                  </vaadin-grid-column>
                  <vaadin-grid-sort-column
                    header="Nombre"
                    .renderer=${(
                      root: HTMLElement,
                      __: any,
                      model: GridItemModel<SavedConfTemplate>
                    ) => {
                      render(
                        html` <p>${model.item.savedConfiguration.name}</p> `,
                        root
                      );
                    }}
                  ></vaadin-grid-sort-column>
                  <vaadin-grid-column
                    header="Plantilla"
                    .renderer=${(
                      root: HTMLElement,
                      __: any,
                      model: GridItemModel<SavedConfTemplate>
                    ) => {
                      render(html` <p>${model.item.template.name}</p> `, root);
                    }}
                  ></vaadin-grid-column>
                  <vaadin-grid-column
                    header="Descripción"
                    .renderer=${(
                      root: HTMLElement,
                      __: any,
                      model: GridItemModel<SavedConfTemplate>
                    ) => {
                      render(
                        html` <p>${model.item.template.description}</p> `,
                        root
                      );
                    }}
                  ></vaadin-grid-column>
                </vaadin-grid>

                <div class="flex flex-row-reverse">
                  <button
                    .disabled=${false}
                    @click=${this.handleSubmit}
                    class="rounded-3xl mx-3 my-3 w-28 InstalarApp	"
                  >
                    Guardar
                  </button>
                  <button
                    @click=${() => {
                      this.appSelected = undefined;
                    }}
                    class="rounded-3xl mx-3 my-3 mr-2 border w-28  border-indigo-500 text-blue-500  CancelarG  hover:shadow-md hover:bg-slate-300 text-xs	"
                  >
                    Atras
                  </button>
                </div>
              `}
        </section>
      </ui5-dialog>
    `;
  }
}

interface SavedConfTemplate {
  savedConfiguration: ConfigurationInfo1;
  template: ResponseTemplateGet;
}
