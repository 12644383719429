/* eslint-disable prettier/prettier */

import { LitElement, html, render } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
// import { guard } from 'lit/directives/guard.js';
import '@ui5/webcomponents/dist/Dialog.js';
import '@vaadin/vaadin-select';
import '@vaadin/vaadin-grid';
//import { TerminalApi, TerminalList } from '../../../api';
import {
  TerminalApi,
  TerminalList,
  CommunicationTerminalApi,
  CommunicationTerminalApiRequestCommandRequest,
  RequestCommandTypeCommandEnum,
  Terminal,
} from '../../../api';
import { errorNotification } from '../../services/notifications';
import { apisConfigurator } from '../../utils/axios';

@customElement('token-generados')
export class TokenGenerados extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private terminalApi: TerminalApi;
  private communicationApi: CommunicationTerminalApi;

  constructor() {
    super();

    this.terminalApi = new TerminalApi(...apisConfigurator('Terminales'));
    this.communicationApi = new CommunicationTerminalApi(
      ...apisConfigurator('Comunicación')
    );
  }

  @property({ type: Object })
  app?: any;

  @state()
  terminales: TerminalList[] = [];

  @state()
  private idApp = '';

  @state()
  private detailsOpenedItem: any[] = [{
    master:"app2go",
    brand:"123",
    model:"1234",
    serial:"sde234",
    version:"1.0.1",
    app:"pruebas",
    last:"0"
}];

  private get dialog() {
    return document.getElementById('dialog-instalar-app')! as any;
  }

  protected firstUpdated(): void {}

  private async getTerminales() {
    const res = await this.terminalApi.terminalList({});
    console.log(res);
    this.terminales =
      /* [
      {
        brand: 'brand',
        model: 'model',
        organization: 'org',
        serial: '123456',
        owner: 'org-1',
        status: 'activo',
      },
    ]; */ res.data;
    this.detailsOpenedItem = this.terminales;
    console.log(this.terminales);
    this.requestUpdate();
  }

  async open(app: any) {
    if (!app) {
      errorNotification('Error al instalar la app');
      return;
    }
    //this.idApp = id;
    this.app = app;
    //await this.getTerminales();
    this.dialog.open = true;
  }

  private async instalarApp(terminal: any) {
    console.log('app', this.app.version[0].version);
    console.log('terminal', terminal.item);

    if (!this.app) return;
    const requestCommand: CommunicationTerminalApiRequestCommandRequest = {
      brand: terminal.item.brand,
      serial: terminal.item.serial,
      requestCommand: {
        commandId: `updateApp-${terminal.item.serial}-${Date.now()}`,
        typeCommand: `UPDATE_APP`,
        parameters: [this.idApp, this.app.version[0].version],
      },
    };

    try {
      const res = await this.communicationApi.requestCommand(requestCommand);
      console.log('envio mensaje res', res);
      this.close();
    } catch (err: any) {
      console.log('envio mensaje err', err);
    }
  }

  close() {
    this.dialog.open = false;
    this.app = undefined;
    this.terminales = [];
  }

  protected render() {
    return html` <ui5-dialog
      id="dialog-instalar-app"
      header-text="Tokens"
      class="w-8/12"
    >
      <vaadin-grid class="mx-2" .items="${this.terminales}"
                .detailsOpenedItems="${this.detailsOpenedItem}">
        <vaadin-grid-sort-column path="brand"></vaadin-grid-sort-column>
        <vaadin-grid-sort-column path="model"></vaadin-grid-sort-column>
        <vaadin-grid-sort-column path="serial"></vaadin-grid-sort-column>
        <vaadin-grid-sort-column path="version"></vaadin-grid-sort-column>
        <vaadin-grid-sort-column path="app"></vaadin-grid-sort-column>
        <vaadin-grid-sort-column path="last"></vaadin-grid-sort-column>
        <!-- <vaadin-grid-sort-column
          path="status"
          header="Estatus"
          .renderer="${(root: HTMLElement, _: any, model: any) => {
            const bg =
              model.item.status == `online` ? `bg-green-300` : `bg-red-300`;
            render(
              html`<p class="py-1 capitalize px-4 w-fit ${bg} rounded">
                ${model.item.status}
              </p>`,
              root
            );
          }}"
          "
        ></vaadin-grid-sort-column>
        <vaadin-grid-column
          header=""
          .renderer="${(root: HTMLElement, _: any, item: any) => {
            render(
              html`<mwc-button
                class="rounded"
                type="submit"
                @click=${() => {
                  this.instalarApp(item);
                }}
                raised
                >Instalar</mwc-button
              >`,
              root
            );
          }}"
        ></vaadin-grid-column> -->
      </vaadin-grid>

      <div slot="footer" class="dialog-footer">
        <button
          class="text-red-600 p-4 mb-2 rounded-md uppercase"
          id="closeDialogButton"
          @click=${() => {
            this.close();
          }}
        >
          Cerrar
        </button>
      </div>
    </ui5-dialog>`;
  }
}
