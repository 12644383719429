/* eslint-disable prettier/prettier */
import { html, LitElement } from 'lit';
import { customElement, query, state } from 'lit/decorators.js';
import { succesNotification } from '../../services/notifications';
import { connect } from 'pwa-helpers';
import { AxiosResponse } from 'axios';
import '@vaadin/vaadin-list-box';
import '@vaadin/vaadin-text-field';
//import './CreateTemplate.css';
import {
  OrganizationName,
  RoleList,
  User,
  TemplateApi,
  SavedConfigurationApi,
  RequestSavedConfigurationCreate,
  RequestSavedConfigurationUpdate,
  SavedConfigurationApiCreateSavedConfigurationRequest,
  SavedConfigurationApiUpdateSavedConfigurationRequest,
  SavedConfigurationApiGetSavedConfigurationRequest
} from '../../../api';
import '@vaadin/vaadin-text-field';
import { NameTemplate } from '../../components/modals/NameTemplate';
import '../../components/modals/NameTemplate';

import '../../components/modals/EnumModal';
import '../../components/modals/DemoModal';
import {
  insertParametersPreConfiguration,
} from '../../utils/functions';
import router from '../../router/router';
import { apisConfigurator } from '../../utils/axios';
import store from '../../redux/store';
import { State } from '../../redux/types';
import { ConfigConsole } from '../../redux/actions';
import { Console } from 'console';

import { JSONEditor } from '@json-editor/json-editor';

@customElement('create-pre-configuracion')
export class CreatePreConfiguracion extends connect(store)(LitElement) {
  private templateApi: TemplateApi;
  private savedConfigurationApi: SavedConfigurationApi;
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private constructor() {
    super();
    this.templateApi = new TemplateApi(...apisConfigurator(''));
    this.savedConfigurationApi = new SavedConfigurationApi(
      ...apisConfigurator('')
    );
  }

  @state()
  plantillas: any = [
    {
      name: '',
      id: '12',
      description: '',
    },
  ];
  @state()
  id_plantilla: string = '';

  @state()
  JsonSchema?: any;

  @query('#name')
  nameInput!: HTMLInputElement;

  @query('#lastName')
  lastNameInput!: HTMLInputElement;

  @state()
  DataJson: any = [];

  @state()
  loading = true;

  @state()
  editUser?: User;

  @state()
  private controlPlantilla: Object[] = [
    {
      name: ' ',
    },
  ];

  @state()
  token?: number;

  @state()
  roles: RoleList[] = [];

  @state()
  properties?: any;

  @state()
  nombrePlantilla?: string = 'Nombre de la plantilla';

  @state()
  descripcionPlantilla?: string = '';

  @state()
  rolesUsuario: string[] = [];

  @state()
  organizationUsuario: string = '';

  @state()
  aux: string = '';

  @state()
  organizaciones: OrganizationName[] = [];

  @state()
  rol?: string = '';

  @state()
  private check: boolean = false;

  @state()
  checks: boolean[] = [];

  @state()
  organization!: Array<string>;

  @state()
  isSaved:any= localStorage.getItem('id_saved_configuration');

  @state()
  dataSchema: any;

  @state()
  editor?: any;

  get nameTemplate() {
    return document.getElementById('cambiarNombrePlantilla') as NameTemplate;
  }

  private get formData() {
    return document.getElementById('editorHolder') as any;
  }

  private get editorContainer() {
    return document.querySelector(`.je-object__container`) as HTMLInputElement;
  }

  private get collapse() {
    return document.querySelector(`.je-object__title`) as HTMLInputElement;
  }

  private get collapse2() {
    return document.querySelector(
      `.json-editor-btn-edit_properties`
    ) as HTMLInputElement;
  }

  private get collapse3() {
    return document.querySelector(`.json-editor-btn-edit`) as HTMLInputElement;
  }

  private get jeindented() {
    return document.querySelector(`.je-indented-panel`) as HTMLInputElement;
  }

  get jeFormInput() {
    return document.querySelector('.je-form-input-label') as HTMLInputElement;
  }

  idGet(atributo: string) {
    return document.getElementById(atributo) as HTMLInputElement;
  }

  validGet(atributo: string) {
    return document.querySelector(`.${atributo}`) as HTMLInputElement;
  }

  esconderDiv() {
    const collapse = this.collapse;
    const collapse2 = this.collapse2;
    const collapse3 = this.collapse3;
    collapse.style.display = 'none';
    collapse2.style.display = 'none';
    collapse3.style.display = 'none';
    const jeindented = this.jeindented;
    jeindented.setAttribute('style', 'border:none');
    const div: any = jeindented.querySelector('div');
    const div2 = div.querySelector('div');
    div2.setAttribute('class', 'flex flex-wrap my-4 mx-4');
  }

  async firstUpdated() {
    let id:any = localStorage.getItem('id_plantilla');
    //let idSaved:any = localStorage.getItem('id_saved_configuration');
    this.id_plantilla = id ? id : '';
    console.log(this.id_plantilla);
    await this.getTemplate(this.id_plantilla);
    await this.setConfiguration();
    if(this.isSaved){
      console.log("id saved configuration",this.isSaved)
      await this.getPreConfiguration(this.isSaved)
    }


    await setTimeout(function () {
      console.log('');
    }, 500);
    console.log('setTimeout() Ejemplo...');

    await this.esconderDiv();
  }

  async getTemplate(id: string) {
    console.log("id plantilla",id)
    var resTer: AxiosResponse;
    try {
      resTer = await this.templateApi.getTemplate({ id });
      this.plantillas = [
        {
          name: resTer.data.name,
          description: resTer.data.description,
          id,
        },
      ];
      console.log("schema",resTer.data)
      this.JsonSchema = await resTer.data.schema;
      console.log("JsonSchema",this.JsonSchema);
    } catch (error) {
    } finally {
      if (!this.plantillas)
        this.plantillas = [
          {
            name: 'plantilla-1',
            id: '12',
            description: 'prueba1',
          },
        ];
    }
  }

  private async submitData() {
    console.log(this.editor.getValue());
    let parameters = this.editor.getValue();
    const { nombrePlantilla, idPlantilla } = store.getState() as State;
    let name = nombrePlantilla?.toString();

    if (name == '') {
      name = 'sin nombre';
    }

    let requestSavedConfigurationCreate: RequestSavedConfigurationCreate;
    let type = this.JsonSchema.type;
    if(type == "array"){
      requestSavedConfigurationCreate = {
        name: name ? name : 'sin nombre',
        templateId: idPlantilla ? idPlantilla : '',
        parametersArray: parameters,
      };
    }else{
      requestSavedConfigurationCreate = {
        name: name ? name : 'sin nombre',
        templateId: idPlantilla ? idPlantilla : '',
        parameters: parameters,
      };
    }

    const savedConfigurationApiCreateSavedConfigurationRequest: SavedConfigurationApiCreateSavedConfigurationRequest =
      {
        requestSavedConfigurationCreate,
      };

    console.log(savedConfigurationApiCreateSavedConfigurationRequest);

    try {
      const response =
        await this.savedConfigurationApi.createSavedConfiguration(
          savedConfigurationApiCreateSavedConfigurationRequest
        );
      console.log(response);

      succesNotification('Registro Completado!!');

    } catch (err) {
      console.log(err);
    }
  }

  private async submitDataUpdate() {
    console.log(this.editor.getValue());
    let parameters = this.editor.getValue();
    const { nombrePlantilla, idPlantilla } = store.getState() as State;
    let name = nombrePlantilla?.toString();

    if (name == '') {
      name = 'sin nombre';
    }

    let requestSavedConfigurationUpdate: RequestSavedConfigurationUpdate;
    let type = this.JsonSchema.type;
    if(type == "array"){
      requestSavedConfigurationUpdate = {
        name: name ? name : 'sin nombre',
        parametersArray: parameters,
      };
    }else{
      requestSavedConfigurationUpdate = {
        name: name ? name : 'sin nombre',
        parameters: parameters,
      };
    }

    const savedConfigurationApiUpdateSavedConfigurationRequest: SavedConfigurationApiUpdateSavedConfigurationRequest =
      {
        id:this.isSaved,
        requestSavedConfigurationUpdate,
      };

    console.log(savedConfigurationApiUpdateSavedConfigurationRequest);

    try {
      const response =
        await this.savedConfigurationApi.updateSavedConfiguration(
          savedConfigurationApiUpdateSavedConfigurationRequest
        );
      console.log(response);

      succesNotification('Registro Completado!!');

    } catch (err) {
      console.log(err);
    }
  }




  async setConfiguration() {
    /*const editorContainer = this.editorContainer;
    if(editorContainer){
      await editorContainer.remove()
    }*/
    const element = this.formData;
    let properties = this.JsonSchema.properties;
    this.properties = properties;
    let type = this.JsonSchema.type;
    if(type == "array"){
      this.editor = new JSONEditor(element, {
        ajax: true,
        disable_collapse:true,
        disable_array_reorder:true,
        schema: {
          title: " ",
          type:"array",
          format: "table",
          options: {
            disable_array_delete_all_rows:true
          },
          items: {
            type: "object",
            properties: properties,
          }
          /*"type": "array",
          "format": "table",
          "items": {
            "type": "object",
            "properties": {
              "name": {
                "type": "string"
              }
            }
          }*/
        }
      })
    }else {
      this.editor = new JSONEditor(element, {
        ajax: true,
        schema: {
          type: 'object',
          title: ' ',
          properties: properties,
          /*properties: {
            edad: {
              default:2,
              description: "descripcion",
              maximum: "100",
              type: "number"
            },
            genero: {
              default:true,
              description: "",
              type: "boolean"
            },
            nombre: {
              default:"nombre",
              description: "desscripcion",
              maxLength: 255,
              type: "string"
            },
            numero: {
              default:"nomnre",
              description: "",
              maxLength: 5,
              type: "string"
            }
          }*/
        },
      });
    }

  }

  async getPreConfiguration(id: string) {

    const templateId = id;
    let type = this.JsonSchema.type;

    const request: SavedConfigurationApiGetSavedConfigurationRequest = {
      id,
    };
    try {
      const res:any = await this.savedConfigurationApi.getSavedConfiguration(
        request
      );
      const data = res.data;
      //const editorContainer = this.editorContainer;
      //await editorContainer.remove();
      if (type == 'array') {
        for (const key in this.properties) {
          if (!data.parametersArray[0].hasOwnProperty(key)) {
            const propertyType = typeof this.properties[key];
            console.log(propertyType)
            data.parametersArray.map((item:any,index:number)=>{
              item[key] = null
            })
            //data.parametersArray[key] = null
          } 
        }
        console.log("parametersArray",res.data.parametersArray)
        this.editor.setValue(res.data.parametersArray);
        var value = this.editor.getValue();
        console.log("value",value)
      } else {
        for (const key in this.properties) {
          if (!data.parameters.hasOwnProperty(key)) {
            const propertyType = typeof this.properties[key];
            console.log(propertyType)
            data.parameters[key] = null
            // Add more type checks for other data types as needed
          }
        }
        /*await this.parametersPreConfiguration(
          res.data.parameters ? res.data.parameters : {}
        );*/
        console.log("parameters",res.data.parameters)
        this.editor.setValue(res.data.parameters);
        var value = this.editor.getValue();
        console.log("value",value)
      }

      //this.esconderDiv();

    } catch (error) {
      console.log(error);
    }
  }

  stateChanged(state: any) {
    this.nombrePlantilla = state.nombrePlantilla;
    this.descripcionPlantilla = state.descripcionPlantilla;
  }

  render() {
    return html`
      <main class="w-full h-full flex overflow-auto">
        <div class="w-full h-full px-5 py-4 flex flex-wrap items-start">
          <div
            class="w-full h-full relative mb-2 SectionList rounded-md pt-5px-4 "
          >
            <div class="flex justify-between">
              <h1 class="text-2xl font-semibold text-blue-600">
                ${this.nombrePlantilla
                  ? this.nombrePlantilla
                  : 'Nombre de la plantilla'}
                <mwc-icon
                  @click=${() => {
                    this.nameTemplate.open('ww');
                  }}
                  >mode</mwc-icon
                >
              </h1>
              <div>
                <button
                  class="text-red-600 p-4 rounded-md uppercase"
                  id="closeDialogButton"
                  @click=${() => {
                    localStorage.removeItem('id_plantilla');
                    router.cambiarSeccion('plantilla-page');
                  }}
                >
                  Cancelar
                </button>

                <button
                  class="text-blue-600 p-4 rounded-md uppercase"
                  id="closeDialogButton"
                  @click=${() => {
                    if(this.isSaved) {
                      this.submitDataUpdate()
                    }else{
                      this.submitData();
                    }
                    router.cambiarSeccion('plantilla-page');
                  }}
                >
                  Guardar
                </button>
              </div>
            </div>
            <div class="header-tr">
              <!-- <div class="mx-2  TexField ">
                    <h1>Nombre</h1>
                </div> -->
            </div>

            <div class="w-full h-full px-5 py-4 flex flex-wrap items-start">
              <div id="editorHolder"></div>
            </div>

            <div class="flex flex-wrap my-4 mx-4 button-new-campo"></div>
          </div>
        </div>
      </main>
      <name-template id="cambiarNombrePlantilla"></name-template>
      <enum-modal id="enumModal"></enum-modal>
    `;
  }
}
