/* eslint-disable prettier/prettier */

import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '@ui5/webcomponents/dist/Dialog.js';
import '@vaadin/vaadin-select';
import { connect } from 'pwa-helpers';
import {
  CommunicationTerminalApi,
  CommunicationTerminalApiRequestCommandRequest,
  RequestCommandTypeCommandEnum,
  Terminal,
} from '../../../api';
import store from '../../redux/store';
import { State } from '../../redux/types';
import { nombrePlantilla, descripcionPlantilla } from '../../redux/actions';
import { errorNotification } from '../../services/notifications';
import { apisConfigurator } from '../../utils/axios';

@customElement('name-template')
export class NameTemplate extends connect(store)(LitElement) {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private communicationApi: CommunicationTerminalApi;

  constructor() {
    super();

    this.communicationApi = new CommunicationTerminalApi(
      ...apisConfigurator('Comunicación')
    );
  }

  @property({ type: Object })
  terminal?: Terminal;

  @state()
  private titulo = '';

  @state()
  private mensaje = '';

  private get dialog() {
    return document.getElementById('editar-nombre-plantilla')! as any;
  }

  protected firstUpdated(): void {}

  async getOrganizaciones() {}

  async open(terminal: any) {
    if (!terminal) {
      errorNotification('Error al editar terminal');
      return;
    }

    this.getOrganizaciones();

    this.terminal = terminal;
    this.dialog.open = true;
  }

  close() {
    this.dialog.open = false;
    this.terminal = undefined;
  }

  private async handleSubmit(e: Event) {
    e.preventDefault();
    store.dispatch(nombrePlantilla(this.titulo));
    //console.log(this.mensaje)
    store.dispatch(descripcionPlantilla(this.mensaje));
    this.close();
  }

  protected render() {
    return html` <ui5-dialog
      id="editar-nombre-plantilla"
      header-text="Editar Nombre Plantilla"
      class="w-3/12"
    >
      <form
        class="px-4 flex flex-col items-center"
        @submit=${this.handleSubmit}
      >
        <div class="mx-2 w-full flex flex-col items-center">
          <mwc-textfield
            class="my-2 w-3/4"
            outlined
            label="Nombre"
            @change=${(e: any) => (this.titulo = e.target.value)}
            required
          ></mwc-textfield>
          <mwc-textfield
            class="my-2 w-3/4"
            outlined
            @change=${(e: any) => (this.mensaje = e.target.value)}
            label="Descripcion"
          ></mwc-textfield>
        </div>

        <div slot="footer" class="dialog-footer">
          <button
            class="text-red-600 p-4 rounded-md uppercase"
            id="closeDialogButton"
            @click=${() => {
              this.close();
            }}
          >
            Cerrar
          </button>
          <button
            class="text-blue-600 p-4 rounded-md uppercase"
            id="closeDialogButton"
            type="submit"
          >
            Guardar
          </button>
        </div>
      </form>
    </ui5-dialog>`;
  }
}
