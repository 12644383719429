/* eslint-disable prettier/prettier */
import { html, LitElement, PropertyValueMap } from 'lit';
import { customElement, query, state } from 'lit/decorators.js';

import '@material/mwc-tab';
import '@material/mwc-tab-bar';
import '@material/mwc-list/mwc-list.js';
import '@material/mwc-list/mwc-list-item.js';
import '@material/mwc-switch';
import '@material/mwc-textarea';
import '@material/mwc-button';
import '@material/mwc-select';
import Swal from 'sweetalert2';
import { apisConfigurator } from '../../utils/axios';
import {
  AppApi, 
  AppApiCreateAppRequest,
  BusinessApiCreateBusinessRequest,
  AppList,
  RequestAppCreate,
  RequestBusinessCreate,
  RequestBusinessUpdate,
  BusinessApiUpdateBusinessRequest,
  BusinessApi
} from '../../../api';
import { errorNotification } from '../../services/notifications';
import router from '../../router/router';
import { TextField } from '@material/mwc-textfield';
import store from '../../redux/store';
import { appAdded } from '../../redux/actions/app';

@customElement('edit-razon-social-page')
export class EditRazonSocialPage extends LitElement {
  private appApi: AppApi;
  private businessApi: BusinessApi;

  @state()
  private loading = false;

  @state()
  private typeFolio:any = [] ;

  @state()
  private preConfig: any[] = []
  
  @state()
  app?: any;

  @state()
  Description?: string;

  @state()
  Arf?: string;

  @state()
  ContactName?: string;

  @state()
  ContactEmail?: string;

  @state()
  ContactPhone?: string;

  @state()
  AppId?: any;

  @state()
  BusinessId?: any;

  @state()
  private apps: AppList[] = [];

  @state()
  private appId:string = "";

  @state()
  private folioTypeId:any = "" ;

  get businessId() {
    return document.getElementById('businessId') as TextField;
  }

  get description() {
    return document.getElementById('description') as HTMLInputElement;
  }

  get contactName() {
    return document.getElementById('contactName') as HTMLInputElement;
  }

  get contactEmail() {
    return document.getElementById('contactEmail') as HTMLInputElement;
  }

  get appSelected() {
    return document.getElementById('appSelect') as any;
  }

  get contactPhone() {
    return document.getElementById('contactPhone') as HTMLInputElement;
  }

  get taxNumber() {
    return document.getElementById('taxNumber') as HTMLInputElement;
  }

  constructor() {
    super();
    this.appApi = new AppApi(...apisConfigurator('Crear App'));
    this.businessApi = new BusinessApi(...apisConfigurator('Crear Razon Social'));
  }

  protected firstUpdated(){
    let appId:string = localStorage.getItem('appId') ?? "";
    this.appId = appId
    let afiliacion:string = localStorage.getItem('razonSocial') ?? "";
    let data:any = JSON.parse(afiliacion);
    console.log(data)
    this.appId = data.appId;
    console.log(appId)
    this.ContactName = data.contactName;
    console.log(this.ContactName)
    this.ContactEmail = data.contactEmail;
    this.ContactPhone = data.contactPhone;
    this.BusinessId = data.businessId;
    this.AppId = data.appId;
    this.Description = data.description;
    this.Arf = data.taxNumber;
    console.log("APPP",this.appId)
    this.getApps()
  }

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  buildApp(): RequestBusinessUpdate{
    return {
      contactEmail: this.contactEmail.value,
      contactPhone: this.contactPhone.value,
      taxNumber: this.taxNumber.value,
      savedConfigurations:[]
    };
  }

  async getApps() {
    //const { configConsole } = store.getState() as State;
    //console.log(configConsole);
    try {
      const res = await this.appApi.listApp();

      //this.apps = res.data;
      const apps = res.data;

    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false; 
    }
  }

  async onSubmit(ev: Event) {
    ev.preventDefault();
    this.loading = false;

    /*if (!this.validateForm()) {
      this.loading = false;
      return;
    }*/
    const data: BusinessApiUpdateBusinessRequest = {
      businessId: this.BusinessId,
      appId:this.appId,
      requestBusinessUpdate:this.buildApp(),
    };
    console.log(data)
    try {
      const res = await this.businessApi.updateBusiness(data);
      if (res.status == 200) {
        await Swal.fire('Actualizado!', 'La razon social ha sido actualizada', 'success');
        router.cambiarSeccion('razon-social-list');
      }else{
        console.log(res)
      }
    } catch (error:any) {
      console.log(error);
      errorNotification(error.toString());
    } finally {
      this.loading = false;
    }
  }

  validateForm() {
    let x = document.forms.namedItem('createAppForm')!;

    if (!x.checkValidity()) {
      errorNotification('Por favor, revise los datos.');
      return false;
    }

    if (!this.businessId.checkValidity()) {
      errorNotification('El nombre de la app es requerido.');
      return false;
    }

    if (!this.description.checkValidity()) {
      errorNotification('El nombre del paquete de la app es requerido.');
      return false;
    }

    if (!this.contactName.checkValidity()) {
      errorNotification('El desarrollador de la app es requerido.');
      return false;
    }

    if (!this.contactEmail.checkValidity()) {
      errorNotification('El correo del desarrollador es requerido.');
      return false;
    }

    if (!this.contactPhone.checkValidity()) {
      errorNotification('Ingresa una descripcion corta valida.');
      return false;
    }

    if (!this.taxNumber.checkValidity()) {
      errorNotification('Ingresa una descripcion valida.');
      return false;
    }

    return true;
  }

  render() { 
    return html`
      <main class="w-full min-h-full">
        <div class="w-full px-5 py-4 flex flex-wrap items-start">
          <form
            name="createAppForm"
            @submit=${(e: any) => this.onSubmit(e)}
            class="w-full relative flex flex-col flex-wrap overflow-auto mb-2 bg-white shadow-md rounded-md pt-5 px-4 border border-solid border-slate-300"
          >
            <h2 class="text-2xl text-blue-600 font-bold uppercase">
              Editar Razon Social
            </h2>
            <div class="flex flex-wrap my-4 mx-4">
              <!-- <div class="mx-2">
                <mwc-textfield
                  required
                  autoValidate
                  outlined
                  iconTrailing="close"
                  label="Descripcion"
                  id="description"
                  .value=${this.Description ?? ''}
                  minLength="3"
                  class="mx-2"
                  validationMessage="El nombre debe tener al menos 3 caracteres"
                ></mwc-textfield>
              </div> -->
             <!-- <div class="mx-2">
                <mwc-textfield
                  required
                  autoValidate
                  outlined
                  iconTrailing="close"
                  id="contactName"
                  .value=${this.ContactName ?? ''}
                  label="Nombre Contacto"
                  min="3"
                  class="mx-2"
                  validationMessage="El nombre debe tener al menos 3 caracteres"
                ></mwc-textfield>
              </div> -->
              <div class="mx-2">
                <mwc-textfield
                  required
                  autoValidate
                  outlined
                  iconTrailing="close"
                  id="contactEmail"
                  .value=${this.ContactEmail ?? ''}
                  label="Email del Contacto"
                  class="mx-2"
                  type="email"
                  validationMessage="El nombre debe tener al menos 3 caracteres"
                ></mwc-textfield>
              </div>

              <div class="mx-2 ">
                <mwc-textarea
                required
                  autoValidate
                  outlined
                  oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                  iconTrailing="close"
                  id="contactPhone"
                  .value=${this.ContactPhone ?? ''}
                  label="Telefono del Contacto"
                  class="mx-2"
                  type="email"
                  validationMessage="El nombre debe tener al menos 3 caracteres"
                >
                </mwc-textarea>
              </div>
              <div class="mx-2 ">
                <mwc-textarea
                  required
                  class="mx-2"
                  validationMessage="el tax debe tener al menos 12 caracteres"
                  label="RFC"
                  .value=${this.Arf ?? ''}
                  id="taxNumber"
                  minLength="12"
                  autoValidate
                  outlined
                > 
                </mwc-textarea>
              </div>
            </div>
            <div class="flex justify-start">
            <button
              @click="${() => router.cambiarSeccion('razon-social-list')}"
              id="cancelarCrearAPP"
              class="w-48 rounded-md border-none bg-red-600 text-white py-3 text-xl mt-4 mx-auto hover:bg-red-700 hover:shadow-lg mb-4">
              CANCELAR
            </button> 
            <button
              type="submit"
              id="guardarCrearAPP"
              class="w-48 rounded-md border-none bg-blue-600 text-white py-3 text-xl mt-4 mx-auto hover:bg-blue-700 hover:shadow-lg mb-4"
            >
              Guardar
            </button>

            </div>

          </form>
        </div>
      </main>
    `;
  }
}
