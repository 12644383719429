/* eslint-disable prettier/prettier */

import { LitElement, html, render } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '@vaadin/vaadin-grid';
import '@ui5/webcomponents/dist/Dialog.js';
import { connect } from 'pwa-helpers';
import './ConfigTerminalFolio_new.css';
import '@vaadin/vaadin-select';
import {
  ConfigurationApi,
  ConfigurationApiCreateConfigurationRequest,
  ConfigurationApiUpdateConfigurationRequest,
  AppApi,
  SavedConfigurationApi,
  SavedConfigurationApiGetSavedConfigurationRequest,
  AppList,
  TemplateApi,
  FolioApi
} from '../../../api';
import {
  Grid,
  GridActiveItemChangedEvent,
  GridItemModel,
} from '@vaadin/vaadin-grid';
import { ConfigConsole } from '../../redux/actions';
import store from '../../redux/store';
import { State } from '../../redux/types';
import { AxiosResponse } from 'axios';
import { iterarCampoJson2, validacionRequired } from '../../utils/functions';
import {
  errorNotification,
  succesNotification,
} from '../../services/notifications';
import { apisConfigurator } from '../../utils/axios';
import { JSONEditor } from '@json-editor/json-editor';

@customElement('config-terminal-folio-new')
export class ConfigTerminalFolio_new extends connect(store)(LitElement) {
  private appApi: AppApi;
  private folioApi: FolioApi;
  private configurationApi: ConfigurationApi;
  private templateApi: TemplateApi;
  private savedConfigurationApi: SavedConfigurationApi;

  constructor() {
    super();
    this.savedConfigurationApi = new SavedConfigurationApi(
        ...apisConfigurator('')
    );
    this.appApi = new AppApi(...apisConfigurator('Aplicaciones'));
    this.folioApi = new FolioApi(...apisConfigurator('Folio'));
    this.configurationApi = new ConfigurationApi(...apisConfigurator(''));
    this.templateApi = new TemplateApi(...apisConfigurator(''));
  }

  @state()
  private apps: AppList[] = [];

  @state()
  plantillas: Object[] = [];

  @state()
  private detailsOpenedItem: AppList[] = [];

  @state()
  loader = true;

  @state()
  existFolio = true;
  
  @state()
  appId = "";

  @state()
  private page = 0;

  @state()
  private AppId: any;

  @state()
  private app: any;

  @state()
  private DataJson: any;

  @state()
  private preConfiguration: Object[] = [];

  @state()
  private ObjectJson: any;

  @state()
  private loading = false;

  @state()
  private habilitarButton = true;

  @state()
  brand = '';

  @state()
  serial = '';

  @state()
  template:any = '';

  @state()
  plantillaId = '';

  @state()
  existConfig: boolean = false;

  @state()
  JsonSchema: any;

  @state()
  band: any;

  @state()
  editor?: any;
 
  async stateChanged(state: any) {
    console.log("PAGEW",this.page)
    console.log("probando el state",state)
    if(state.hasOwnProperty("configConsole")){
      this.editor = state.configConsole.editor;
    }

    if (this.page == 3) { 

      console.log("state.configConsole.editor",)

      this.ObjectJson = state.objectJson;
      this.existConfig = state.configConsole.existConfig;
    }
    if (this.page == 1) {
      this.habilitarButton = false;
    }
    if(this.page == 2){
      this.page=3;
    }
  }

  protected firstUpdated(): void {

  }

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private get divClass() {
    return document.getElementById('agregar') as any;
  }

  private get dialog() {
    return document.getElementById('dialog-listado-app')! as any;
  }

  private get formData() {
    return document.getElementById('editorHolder') as any;
  }

  private get buttonEliminar() {
    return document.querySelector('.json-editor-btn-subtract') as HTMLInputElement;
  }

  private get buttonAgregar() {
    return document.querySelector('.json-editor-btn-add') as HTMLInputElement;
  }

  private get tableTop() {
    return document.querySelector('.je-tabholder--top') as HTMLInputElement;
  }

  esconderDiv() {

    const buttonEliminar = this.buttonEliminar;
    const buttonAgregar = this.buttonAgregar;
    const tableTop = this.tableTop;

    const span:any = buttonEliminar.querySelector('span');
    const span2:any = buttonAgregar.querySelector('span');

    span.innerHTML="Delete";
    span2.innerHTML="Add";
    tableTop.style.border = 'none';

  }

  async open(template: any) {
    this.template = template;
    const divEditor = this.formData;
    divEditor.style.display = "none";
    divEditor.removeAttribute("id");
    const divClass = this.divClass;
    divClass.innerHTML = '<div id="editorHolder"></div>'; 
    await this.listPreConfiguration();
    await this.getConfiguration();
    //this.getTemplates();
    //this.loading = true;
    //this.getApps();
    this.dialog.open = true;
    //this.folioDisponible();
    this.esconderDiv(); 
  }

  close() {
    const divEditor = this.formData;
    divEditor.style.display = "none";
    divEditor.removeAttribute("id");
    const divClass = this.divClass;
    divClass.innerHTML = '<div id="editorHolder"></div>';
    this.dialog.open = false;
    //this.terminal = undefined;
  }

  async listPreConfiguration(){
    try {
        console.log("LISTA PRECONFIGURACIONES")
      //const { configConsole } = store.getState() as State;
      let response = await this.savedConfigurationApi.listSavedConfiguration();
      let i = response.data;
      let dataFiltre: Object[] = [];
  
      i.map((camp: any, index: number) => {
        if (this.template.id.localeCompare(camp.templateId) == 0) {
          dataFiltre.push(camp);
        }
      });
      this.preConfiguration = dataFiltre;
      console.log("LISTA PRECONFIGURACIONES",dataFiltre)
      return response.data;
    } catch (error:any) {
      console.log(error);
      //errorNotification(error.toString());
    }
  }

  async getPreConfiguration(id: string) {
    //const { configConsole } = store.getState() as State;
    const templateId = this.template.id;
    const type = this.template.schema.schema.type;

    const request: SavedConfigurationApiGetSavedConfigurationRequest = {
      id,
    };
    try {
      const res = await this.savedConfigurationApi.getSavedConfiguration(
        request
      );
      console.log(res.data.parametersArray);
      if (type == 'array') {
        this.editor.setValue(res.data.parametersArray);
        var value = this.editor.getValue();
        console.log(value);
      } else {
        /*await this.parametersPreConfiguration(
          res.data.parameters ? res.data.parameters : {}
        );*/
        this.editor.setValue(res.data.parameters);
        //this.esconderDiv()
      }
    } catch (error:any) {
      console.log(error);
      errorNotification(error.toString());
    }
  }

  async getConfiguration() {
    //const { configConsole } = store.getState() as State;
    let properties: Object = this.template.schema.schema.properties;

    const element = this.formData;

    let type = this.template.schema.schema.type;
    let editor;
    if (type == 'array') {
      editor = new JSONEditor(element, {
        ajax: true,
        template: 'hogan',
        collapsed:false,
        array_controls_top:true,
        disable_properties:true,
        //disable_properties: false,
        //disable_edit_json: false,
        remove_button_labels:false,
        iconlib: "fontawesome4",
        schema: {
          title: ' ',
          type: 'array',
          format: 'tabs-top',
          options: {
            disable_array_delete_all_rows: true,
          },
          items: {
            type: 'object',
            properties: properties,
          },
        },
      });
      this.editor = editor;
      //this.esconderDiv(); 
    } else {
      editor = new JSONEditor(element, {
        ajax: true,
        no_additional_properties:true,
        keep_oneof_values:true,
        disable_edit_json: true,
        disable_collapse: true,
        disable_properties: true,
        prompt_before_delete:true,
        schema: {
          title: ' ',
          type: 'object',
          format: 'grid',
          properties: properties,
        },
      });
      this.editor = editor;
      //await this.sleep(100);
      //this.esconderDiv(); 
      //token/list

    } 
  }

  async getApp(appId: any) {
    const { configConsole } = store.getState() as State;
    try {
      const res = await this.appApi.getApp({ id: appId });
      const app = res.data;
      const objecto1 = configConsole;
      const objecto2 = { appId, app };
      Object.assign(objecto1, objecto2);
      store.dispatch(
        ConfigConsole({
          appId: objecto1,
          ...configConsole,
        })
      );
    } catch (err) {
      //errorNotification('Error al obtener la app');
      console.log(err);
    } finally {
      //this.loading = false;
      this.folioDisponible()
    }
  }



  async getApps() {
    const { configConsole } = store.getState() as State;
    console.log(configConsole);
    try {
      const res = await this.appApi.listApp();

      this.apps = res.data;
      const apps = res.data;
      this.detailsOpenedItem = this.apps;
      store.dispatch(
        ConfigConsole({
          apps,
          ...configConsole,
        })
      );
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false; 
    }
  }

  async folioDisponible() {
    console.log("brand",this.brand)
    console.log("serial",this.serial)
    try {
      const res = await this.folioApi.listFolio({
        appId:this.appId,
        used:true
      })
      console.log("res folio:",res)
      if(res.data.length){
        this.existFolio = true;
      }else{
        this.existFolio = false;
      }
    } catch (error) {
      console.log(error)
    }

  }

  async submitUpdateConfiguration() {
    const { configConsole } = store.getState() as State;
    console.log("configConsole ",configConsole )
    const editor = this.editor;


    let datos = editor.getValue() as Object;
    console.log("DATOS GETVALUE ANTES DE GUARDAR",datos)
    const requerido = validacionRequired(datos);

    if (requerido) {
      errorNotification(requerido);
      return;
    }
    let requestConfigurationUpdate = {}
    let type = configConsole.JsonSchema.type;
    if(type == "array"){
      requestConfigurationUpdate= {
        parametersArray:datos
      }
    }else{
      requestConfigurationUpdate= {
        parameters:datos
      }
    }

    const data: ConfigurationApiUpdateConfigurationRequest = {
      brand: configConsole.brand,
      serial: configConsole.serial,
      appId: configConsole.appId,
      templateId: configConsole.templateId,
      requestConfigurationUpdate

    };
    
    console.log(data)

    try { 
      const res = await this.configurationApi.updateConfiguration(data);

      this.close();
      this.page = 1;
      succesNotification('Datos guardados con exito!');
      this.ObjectJson = {};
    } catch (error:any) {
      console.log(error);
      errorNotification(error.toString());
    } finally {
      this.loading = false;
    }
  }

  async submitConfiguration() {
    const { configConsole } = store.getState() as State;

    console.log("configConsole ",configConsole )
    const editor = this.editor;

    let datos:any = editor.getValue() as Object;
    console.log("DATOS GETVALUE ANTES DE GUARDAR",datos)
    const requerido = validacionRequired(datos);

    if (requerido) {
      errorNotification(requerido);
      return; 
    }
    let data: ConfigurationApiCreateConfigurationRequest;
    let type = configConsole.JsonSchema.type;
    
    if(type == "array"){

      data = {
        requestConfigurationCreate: {
          brand: configConsole.brand,
          serial: configConsole.serial,
          appId: configConsole.appId,
          templateId: configConsole.templateId,
          parametersArray: datos,
        }
      }
    }else{
      data = {
        requestConfigurationCreate: {
          brand: configConsole.brand,
          serial: configConsole.serial,
          appId: configConsole.appId,
          templateId: configConsole.templateId,
          parameters:datos,
        }
      }
    }
    /*const parameters = datos;

    const data: ConfigurationApiCreateConfigurationRequest = {
      requestConfigurationCreate: {
        brand: configConsole.brand,
        serial: configConsole.serial,
        appId: configConsole.appId,
        templateId: configConsole.templateId,
        parameters,
      },
    };*/

    console.log(data);
    try {
      const res = await this.configurationApi.createConfiguration(data);

      this.close();
      this.page = 1;
      succesNotification('Datos guardados con exito!');
      console.log(res);
      this.ObjectJson = {};
    } catch (error:any) {
      console.log(error);
      errorNotification(error.toString());
    } finally {
      this.loading = false;
    }
  }

  async getTemplates() {
    const { configConsole } = store.getState() as State;
    this.loader = true;
    var resTer: AxiosResponse;
    try {
      resTer = await this.templateApi.listTemplate({});
      //console.log(resTer)
      this.plantillas = resTer.data;
      const templates = resTer.data;
      store.dispatch(
        ConfigConsole({
          templates,
          ...configConsole,
        })
      );
    } catch (error) {
    } finally {
      if (!this.plantillas)
        this.plantillas = [
          {
            name: 'plantilla-1',
            id: '12',
            description: 'prueba1',
          },
        ];
      this.loader = false;
    }
  }

  prueba(index: number) {
    this.band = index;
  }

  protected render() {
    return html`
      <ui5-dialog
        id="dialog-listado-app"
        class="w-8/12"
        style="height:600px;border-radius: 15px;"
      >
        <main class="w-full h-full flex FondoColor ">
          <section class="w-full flex flex-col pl-6  max-h-full">
            <section class="w-full flex my-2">
              <div class="container ml-4 w-full flex flex-row justify-between">
                <div class="nameApp">
                  <h2 class="text-3xl">Configuracion de terminal</h2>
                </div>
              </div>
            </section>
            <!-- <config-terminal .DataJson=${this.DataJson}></config-terminal> -->
            <main class="w-full flex flex-col overflow-auto" style="height: 28rem;">
                <div class="flex justify-end">

                    <custom-select

                        style="width: 20%"
                        label="Pre Configuracion"
                        id="versionSelect"
                        .options=${this.preConfiguration?.map((v: any, index: number) => ({
                        value: v.id,
                        label: v.name,
                        }))}
                        @change=${(ev: CustomEvent) => {
                        console.log(ev.detail);
                        this.getPreConfiguration(ev.detail);
                        }}
                    >
                    </custom-select>
                </div>
                <mwc-icon-button 
                    icon="autorenew"
                    class="absolute top-0 right-0"
                    @click=${() => {
                        //this.refrescar()
                    }}
                ></mwc-icon-button>

                <div id="agregar" class="w-full h-full px-5 py-4 flex flex-wrap items-start">
                    <div id="editorHolder"></div>
                </div>
                ${this.loading !== true
                ? html`
                        `
                : html`
                    <mwc-circular-progress
                        indeterminate
                        density=${20}
                    ></mwc-circular-progress>
                    `}
            </main>
                
            <div class="flex flex-row-reverse">
                <button
                    .disabled=${false}
                    @click=${() => {
                    if (this.existConfig) {
                        this.submitUpdateConfiguration();
                    } else {
                        this.submitConfiguration();
                    }
                    }}
                    class="rounded-3xl mx-3 my-3 w-28 InstalarApp	"
                >
                    Guardar
                </button>
                <button
                    @click=${() => {
                    this.habilitarButton = true;
                    this.close();
                    this.band = -1;
                    }}
                    class="rounded-3xl mx-3 my-3 mr-2 border w-28  border-indigo-500 text-blue-500  CancelarG  hover:shadow-md hover:bg-slate-300 text-xs	"
                >
                    Cancelar
                </button>
            </div>
          </section>
        </main>

      </ui5-dialog>
    `;
  }
}
