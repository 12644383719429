/* eslint-disable prettier/prettier */
import { LitElement, html, css } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

@customElement('custom-multi-select')
export class CustomMultiSelect<T> extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  @property({ type: Array })
  options: { label: string; value: T }[] = [];

  @property({ type: Array })
  selected: T[] = [];

  @property({ type: String })
  label = '';

  @state()
  show = false;

  protected firstUpdated(): void {
    //console.log(this.options)
    window.addEventListener('click', e => {
      if (
        document
          .getElementById(`select-${this.label}`)
          ?.contains(e.target as any)
      ) {
        // Clicked in box
      } else {
        this.show = false;
      }
    });
  }

  dropDown() {
    this.show = !this.show;
  }

  onSelected(value: any) {
    if (this.selected.includes(value)) {
      this.selected = this.selected.filter(v => v !== value);
    } else {
      this.selected = [...this.selected, value];
    }
    const ev = new CustomEvent('change', { detail: this.selected });
    this.dispatchEvent(ev);
  }

  render() {
    return html`
      <div id="select-${this.label}">
        <button
          @click="${() => this.dropDown()}"
          class="menu-btn"
          type="button"
        >
          ${this.label} <mwc-icon>arrow_drop_down</mwc-icon>
        </button>
        ${this.show
          ? html`
              <div class="shadow rounded menu">
                ${this.options.map(
                  option => html`
                    <span class="block menu-option">
                      <label>
                        <span class="checkmark"></span>
                        <input
                          type="checkbox"
                          .checked=${this.selected.includes(option.value)}
                          @change=${() => this.onSelected(option.value)}
                        />&nbsp; ${option.label}
                      </label>
                    </span>
                  `
                )}
              </div>
            `
          : ''}
      </div>
    `;
  }
}
