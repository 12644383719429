/* eslint-disable prettier/prettier */
import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';

import '@ui5/webcomponents/dist/Table.js';
import '@ui5/webcomponents/dist/TableColumn.js';
import '@ui5/webcomponents/dist/TableRow.js';
import '@ui5/webcomponents/dist/Dialog.js';
import '@ui5/webcomponents/dist/TableCell.js';
import '@ui5/webcomponents/dist/Icon.js';
import '@ui5/webcomponents/dist/Switch.js';
import '@ui5/webcomponents-icons/dist/action-settings.js';

import '@vaadin/vaadin-grid';

import { Usuario, usuariosPrueba } from '../../utils/dataTerminales';
@customElement('users-page')
export class UsuariosPage extends LitElement {
  usuarioSelected?: Usuario;

  proveedorData = [
    {
      nombre: 'ReSeller1',
      ver: true,
      editar: true,
      borrar: true,
      agregar: true,
    },
    {
      nombre: 'Tienda1',
      ver: true,
      editar: true,
      borrar: false,
      agregar: true,
    },
    {
      nombre: 'Grupo1',
      ver: true,
      editar: false,
      borrar: false,
      agregar: false,
    },
    {
      nombre: 'Grupo2',
      ver: true,
      editar: true,
      borrar: false,
      agregar: false,
    },
  ];

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  render() {
    return html`
      <main class="w-full min-h-full flex">
        <div class="w-full  px-5 py-4 flex flex-wrap">
          <section
            class="w-6/12 flex h-full overflow-auto bg-white rounded-md py-5 px-4 border border-solid border-slate-300 flex-col"
          >
            <h1 class="text-2xl font-semibold text-blue-600">
              Administrar Usuarios
            </h1>
            <ui5-table class="demo-table" id="table">
              <!-- Columns -->
              <ui5-table-column slot="columns" min-width="500">
                <span style="line-height: 1.4rem">Id</span>
              </ui5-table-column>

              <ui5-table-column slot="columns" min-width="500">
                <span style="line-height: 1.4rem">Nombre</span>
              </ui5-table-column>

              <ui5-table-column slot="columns" min-width="500">
                <span style="line-height: 1.4rem">Organizacion</span>
              </ui5-table-column>

              <ui5-table-column slot="columns" min-width="500">
                <span style="line-height: 1.4rem">Email</span>
              </ui5-table-column>

              <ui5-table-column slot="columns" min-width="500">
                <span style="line-height: 1.4rem">Opciones</span>
              </ui5-table-column>

              ${usuariosPrueba.map(
                usuario => html`
                  <ui5-table-row>
                    <ui5-table-cell> ${usuario.id} </ui5-table-cell>
                    <ui5-table-cell> ${usuario.nombre} </ui5-table-cell>
                    <ui5-table-cell> ${usuario.organizacion} </ui5-table-cell>
                    <ui5-table-cell> ${usuario.email} </ui5-table-cell>
                    <ui5-table-cell>
                      <div>
                        <button
                          @click=${() => {
                            console.log(usuario);
                            this.usuarioSelected = usuario;
                            this.requestUpdate();
                            (document.getElementById('dialog') as any).open =
                              true;
                          }}
                        >
                          <ui5-icon
                            name="action-settings"
                            class="text-blue-500 hover:text-blue-700 w-6"
                          ></ui5-icon>
                        </button>
                      </div>
                    </ui5-table-cell>
                  </ui5-table-row>
                `
              )}
            </ui5-table>
          </section>

          <section class="w-6/12 h-full pl-2">
            <div
              class="w-full flex h-full overflow-auto bg-white rounded-md py-5 px-4 border border-solid border-slate-300 flex-col"
            >
              <h1 class="text-2xl font-semibold text-blue-600">
                Administrar Grupos
              </h1>
              <vaadin-grid .items=${this.proveedorData} height-by-rows>
                <vaadin-grid-column
                  header="Nombre"
                  .renderer=${this.renderNombre}
                  auto-width
                >
                </vaadin-grid-column>
                <vaadin-grid-column
                  header="Agregar"
                  .renderer=${this.renderAgregar}
                  width="100px"
                  flex-grow="0"
                >
                </vaadin-grid-column>
                <vaadin-grid-column
                  header="Borrar"
                  .renderer=${this.renderBorrar}
                  width="100px"
                  flex-grow="0"
                >
                </vaadin-grid-column>
                <vaadin-grid-column
                  header="Editar"
                  .renderer=${this.renderEditar}
                  width="100px"
                  flex-grow="0"
                >
                </vaadin-grid-column>
                <vaadin-grid-column
                  header="Ver"
                  .renderer=${this.renderVer}
                  width="100px"
                  flex-grow="0"
                >
                </vaadin-grid-column>
              </vaadin-grid>
            </div>
          </section>
        </div>
        <ui5-dialog
          id="dialog"
          header-text="Permisos usuario"
          class="w-6/12"
          draggable="true"
        >
          <section class="px-4">
            <div class="flex flex-col mx-2">
              <h1 class="text-2xl font-bold text-blue-600 mb-2">
                ${this.usuarioSelected?.nombre} - ${this.usuarioSelected?.email}
              </h1>
              <div class="flex items-center w-full justify-between my-2">
                <p class="text-lg font-semibold mr-3">Crear Grupos</p>
                <ui5-switch
                  text-on="Si"
                  text-off="No"
                  @change=${(e: any) => {
                    console.log(e);
                    (this.usuarioSelected as any).permisos.crearGrupos =
                      !this.usuarioSelected?.permisos.crearGrupos ?? false;
                  }}
                  .checked=${this.usuarioSelected?.permisos.crearGrupos}
                ></ui5-switch>
              </div>
              <div class="flex items-center w-full justify-between my-2">
                <p class="text-lg font-semibold mr-3">Agregar Terminales</p>
                <ui5-switch
                  text-on="Si"
                  text-off="No"
                  @change=${(e: any) => {
                    console.log(e);
                    (this.usuarioSelected as any).permisos.crearTerminales =
                      !this.usuarioSelected?.permisos.crearTerminales ?? false;
                  }}
                  .checked=${this.usuarioSelected?.permisos.crearTerminales}
                ></ui5-switch>
              </div>
              <div class="flex items-center w-full justify-between my-2">
                <p class="text-lg font-semibold mr-3">Crear Usuarios</p>
                <ui5-switch
                  text-on="Si"
                  text-off="No"
                  @change=${(e: any) => {
                    console.log(e);
                    (this.usuarioSelected as any).permisos.crearUsuarios =
                      !this.usuarioSelected?.permisos.crearUsuarios ?? false;
                  }}
                  .checked=${this.usuarioSelected?.permisos.crearUsuarios}
                ></ui5-switch>
              </div>
            </div>
          </section>

          <div slot="footer" class="dialog-footer">
            <div style="flex: 1;"></div>
            <button
              class="text-blue-600 p-4 rounded-md"
              id="closeDialogButton"
              @click=${() => {
                (document.getElementById('dialog') as any).open = false;
              }}
            >
              Cerrar
            </button>
          </div>
        </ui5-dialog>
      </main>
    `;
  }

  renderNombre = (elemento: Element, columna: Element, dataFila: any) => {
    elemento.innerHTML = dataFila.item.nombre
      ? `<p>${dataFila.item.nombre}</p>`
      : ``;
  };

  renderAgregar = (elemento: Element, columna: Element, dataFila: any) => {
    elemento.innerHTML = this.generarCheckPermiso('agregar', dataFila);
  };

  renderBorrar = (elemento: Element, columna: Element, dataFila: any) => {
    elemento.innerHTML = this.generarCheckPermiso('borrar', dataFila);
  };

  renderEditar = (elemento: Element, columna: Element, dataFila: any) => {
    elemento.innerHTML = this.generarCheckPermiso('editar', dataFila);
  };

  renderVer = (elemento: Element, columna: Element, dataFila: any) => {
    elemento.innerHTML = this.generarCheckPermiso('ver', dataFila);
  };

  generarCheckPermiso(nombrePermiso: string, dataFila: any): string {
    // if (dataFila.level !== 1) return ``;
    return dataFila.item[nombrePermiso]
      ? `
      <ui5-switch 
      text-on="Si"
      text-off="No"
      checked></ui5-switch>`
      : `
      <ui5-switch 
      text-on="Si"
      text-off="No"></ui5-switch>`;
  }
}
