/* eslint-disable prettier/prettier */
import { css, html, LitElement } from 'lit';
import { customElement, query, state } from 'lit/decorators.js';
import '@material/mwc-textfield';

import '@vaadin/vaadin-text-field';
import '@material/mwc-tab';
import '@material/mwc-tab-bar';
import '@material/mwc-list/mwc-list.js';
import '@material/mwc-list/mwc-list-item.js';
import '@material/mwc-switch';
import { RequestTerminalUpdate, Terminal, TerminalApi } from '../../../api';
import { State } from '../../redux/types';
import store from '../../redux/store';
import router from '../../router/router';
import { clearTerminal } from '../../redux/actions';
import { apisConfigurator } from '../../utils/axios';
import { errorNotification } from '../../services/notifications';
/* 
import { Terminal, TerminalApi } from '../../../apisHierarchy'; */

@customElement('edit-terminal-page')
export class EditTerminalPage extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private terminalApi: TerminalApi;

  @state()
  private terminal?: Terminal;

  @state()
  private loading = false;

  @query('#owner')
  private ownerInput!: HTMLInputElement;

  @query('#description')
  private descriptionInput!: HTMLInputElement;

  /* terminalApi = new TerminalApi(); */

  private constructor() {
    super();
    this.terminalApi = new TerminalApi(...apisConfigurator('Terminal Editado'));
  }

  protected firstUpdated(): void {
    const { terminal } = store.getState() as State;
    this.terminal = terminal;
  }

  validateForm() {
    if (!this.ownerInput.checkValidity()) {
      errorNotification('El dueño del terminal es requerido.');
      return false;
    }

    return true;
  }

  async handleSubmit(e: Event) {
    e.preventDefault();
    this.loading = true;

    if (!this.terminal) return;

    if (!this.validateForm()) {
      this.loading = false;
      return;
    }

    const requestTerminalUpdate: RequestTerminalUpdate = {
      owner: this.ownerInput.value,
      description: this.descriptionInput.value,
    };

    //console.log(requestTerminalUpdate);

    try {
      const res = await this.terminalApi.terminalUpdate({
        brand: this.terminal.brand,
        serial: this.terminal.serial,
        requestTerminalUpdate,
      });

      store.dispatch(clearTerminal());
      router.cambiarSeccion('terminal-list');
    } catch (err: any) {
    } finally {
      this.loading = false;
    }
  }

  render() {
    return html`
      <main class="w-full FondoColor min-h-full">
        <div class="w-full h-full px-5 py-4 flex flex-wrap items-start">
          <!-- <form
            @submit=${this.handleSubmit}
            class="w-full h-full relative mb-2  SectionList rounded-md pt-5 px-4 "
          > -->
          <h2 class="text-2xl text-blue-600 font-bold uppercase">
            Actualizar Terminal - ${this.terminal?.serial}
          </h2>
          <div class="flex flex-wrap my-4 mx-4">
            <div class="mx-2">
              <mwc-textfield
                required
                validationMessage="El dueño debe tener al menos 8 caracteres"
                label="Dueño"
                id="owner"
                class="mx-2"
                iconTrailing="close"
                minLength="5"
                .value="${this.terminal?.owner ?? ''}"
                autoValidate
                outlined
              >
              </mwc-textfield>
            </div>
            <div class="mx-2">
              <mwc-textfield
                iconTrailing="close"
                id="description"
                label="Descripción"
                .value="${this.terminal?.description ?? ''}"
                class="mx-2"
                outlined
              >
              </mwc-textfield>
            </div>
            <div class="w-full flex flex-row my-4 ml-8">
              <div class="w-2/4	"></div>
              <div class="w-2/4 CrearTerminal	flex flex-row">
                <button
                  class="rounded-3xl border  border-indigo-500 text-blue-500  CancelarG  hover:shadow-md hover:bg-slate-300 text-xs	"
                  @click="${(e: Event) =>
                    router.cambiarSeccion('terminal-list')}"
                >
                  Cancelar
                </button>

                <mwc-button
                  type="submit"
                  class="rounded-3xl ml-4 mb-2  normal-case		"
                  @click="${(e: Event) => this.handleSubmit(e)}"
                  .disabled=${this.loading}
                >
                  <p class="normal-case	text-xs ">Guardar terminal</p>
                </mwc-button>
              </div>
            </div>
          </div>
          <!-- </form> -->
        </div>
      </main>
    `;
  }
}
