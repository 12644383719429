/* eslint-disable prettier/prettier */
import { html, LitElement, render } from 'lit';
import { guard } from 'lit/directives/guard.js';
import { customElement, state } from 'lit/decorators.js';
import Swal from 'sweetalert2';
import { connect } from 'pwa-helpers';
import '@ui5/webcomponents/dist/Tree.js';
import '@ui5/webcomponents/dist/Icon.js';
import '@ui5/webcomponents-icons/dist/it-instance.js';
import '@ui5/webcomponents-icons/dist/group-2.js';
import '@ui5/webcomponents-icons/dist/alert.js';
import '@ui5/webcomponents-icons/dist/cart.js';
import '@ui5/webcomponents-icons/dist/bell.js';
import '@material/mwc-icon';
import '@material/mwc-icon-button';
import '@vaadin/vaadin-grid'; 
import { State } from '../../redux/types';
//import './TerminalList.css';
import { InstalarAppModal } from '../../components/modals/InstalarAppModal';
import '../../components/modals/InstalarAppModal'; 
import { AgregarInstaladorModal } from '../../components/modals/AgregarInstaladorApp';
import '../../components/modals/AgregarInstaladorApp';
import { DescargarInstaladorModal } from '../../components/modals/DescargarInstaladorApp';
import '../../components/modals/DescargarInstaladorApp';
import { AgregarVersionModal } from '../../components/modals/AgregarVersionApp';
import '../../components/modals/AgregarVersionApp';
//import { ListadoCrearFolioAfiliaciones } from '../../components/modals/ListadoCrearFolioAfiliaciones';
//import '../../components/modals/ListadoCrearFolioAfiliaciones';
import {
  Grid,
  GridActiveItemChangedEvent,
  GridItemModel,
} from '@vaadin/vaadin-grid';
import { AxiosResponse } from 'axios';

import '../../components/modals/EditOrganizationModal';
import router from '../../router/router.js';
import {
  CommunicationTerminalApi,
  CommunicationTerminalApiRequestCommandRequest,
  TerminalApi,
  AffiliationApi,
  OrganizationApi,
} from '../../../api/api.js';
import {
    ConfigurationApi,
    ConfigurationApiCreateConfigurationRequest,
    AffiliationApiListAffiliationRequest,
    AffiliationApiDeleteAffiliationRequest,
    AppApi,
    AppList,
    TemplateApi,
    RequestFolioCreate,
    FolioApiCreateFolioRequest,
    FolioApi,
    FolioApiListFolioRequest,
    SavedConfigurationApi
  } from '../../../api';
  import { editarApp,verApp } from '../../redux/actions';
import {
  RequestCommandTypeCommandEnum,
  Terminal,
  TerminalList,
  AffiliationInfoList
} from '../../../api/index.js';
import store from '../../redux/store.js';
import { ConfigConsole,PageFolio } from '../../redux/actions';
import { editarTerminal } from '../../redux/actions/index.js';
import { PushConfigModal } from '../../components/modals/PushConfigurationsDialog';
import '../../components/modals/PushConfigurationsDialog';
import { UbicacionTerminalMapa } from '../../components/modals/UbicacionTerminalMapa';
import { CrearFolio } from '../../components/modals/CrearFolio';
import '../../components/modals/CrearFolio';
//import { CambiarMaster } from '../../components/modals/CambiarMaster';
import '../../components/modals/ListadoApp';
import { ListadoFolioAfiliaciones } from '../../components/modals/ListadoFolioAfiliaciones';
import '../../components/modals/ListadoFolioAfiliaciones';
import { ListadoPlantillaFolioAfiliaciones } from '../../components/ListadoPlantillaFolioAfiliaciones';
import '../../components/ListadoPlantillaFolioAfiliaciones';
import { ListadoPlantillaCrearFolioAfiliaciones } from '../../components/ListadoPlantillaCrearFolioAfiliaciones';
import '../../components/ListadoPlantillaCrearFolioAfiliaciones';
import { ConfigTerminalFolioAfiliaciones } from '../../components/ConfigTerminalFolioAfiliaciones';
import '../../components/ConfigTerminalFolioAfiliaciones';
import { ConfigTerminalCrearFolioAfiliaciones } from '../../components/ConfigTerminalCrearFolioAfiliaciones';
import '../../components/ConfigTerminalCrearFolioAfiliaciones';
import '../../components/modals/UbicacionTerminalMapa';
import { EditOrganizationModal } from '../../components/modals/EditOrganizationModal.js';
import { apisConfigurator } from '../../utils/axios';
import { EnviarMensajeModal } from '../../components/modals/EnviarMensajeModal';
import '../../components/modals/EnviarMensajeModal';
import { HistorialComandosModal } from '../../components/modals/HistorialComandosModal';
import '../../components/modals/HistorialComandosModal';
import { EditarFolio } from '../../components/modals/EditarFolio';
import '../../components/modals/EditarFolio';
import { CrearFolioAfiliacion} from '../../components/modals/CrearFolioAfiliacion';
import '../../components/modals/CrearFolioAfiliacion';
import {
  ResponseTemplateGet,
} from '../../../api';
import {
  InsertarPropiedad,
  iterarCampoJson2,
  existenciaConfiguracion,
  deleteCamp,
} from '../../utils/functions';

import {
  errorNotification,
  succesNotification,
} from '../../services/notifications';
import { getApps } from '../../redux/actions';
import { getTerminals } from '../../redux/actions/terminals';
import { Unsubscribe } from '@reduxjs/toolkit';

@customElement('afiliaciones-list-page')
export class AfiliacionesList extends connect(store)(LitElement) {
    private appApi: AppApi;
  private terminalApi: TerminalApi;
  private afiliacionesApi: AffiliationApi;
  private communicationApi: CommunicationTerminalApi;
  private savedConfigurationApi: SavedConfigurationApi;
  private organizationApi!: OrganizationApi;
  private templateApi: TemplateApi;
  private folio: FolioApi;

  private storeUnsuscriber?: Unsubscribe;

  private constructor() {
    super();
    this.terminalApi = new TerminalApi(...apisConfigurator('Terminales'));
    this.savedConfigurationApi = new SavedConfigurationApi(...apisConfigurator('saved configuration'));
    this.afiliacionesApi = new AffiliationApi(...apisConfigurator('Afiliaciones'));
    this.communicationApi = new CommunicationTerminalApi(
      ...apisConfigurator('Terminales')
    );
    this.templateApi = new TemplateApi(...apisConfigurator('Templates'));
    this.organizationApi = new OrganizationApi(
      ...apisConfigurator('Organización')
    );
    this.appApi = new AppApi(...apisConfigurator('Aplicaciones'));
    this.folio = new FolioApi(
      ...apisConfigurator('Folio')
    );
  }

  get enviarMensajeModal() {
    return document.getElementById('enviarMensajeModal') as EnviarMensajeModal;
  }

  get listadoFolio() {
    return document.getElementById('listadoFolio') as ListadoFolioAfiliaciones;
  }

  /*get listadoCrearFolio() {
    return document.getElementById('listadoCrearFolio') as ListadoCrearFolioAfiliaciones;
  }*/


  get editarFolio() {
    return document.getElementById('editarFolio') as EditarFolio;
  }

  get instalarAppModal() {
    return document.getElementById('instalarAppModal')! as InstalarAppModal;
  }

  get crearFolioRazonSocial() {
    return document.getElementById('crearFolioAfiliacion') as CrearFolioAfiliacion;
  }

  get agregarInstaladorModal() {
    return document.getElementById(
      'agregarInstaladorModal'
    )! as AgregarInstaladorModal;
  }

  get appSelected() {
    return document.getElementById('appSelect') as any;
  }

  get descargarInstaladorModal() {
    return document.getElementById(
      'descargarInstaladorModal'
    )! as DescargarInstaladorModal;
  }

  get agregarVersionModal() {
    return document.getElementById(
      'agregarVersionModal'
    )! as AgregarVersionModal;
  }


  get historialComandosModal() { 
    return document.getElementById(
      'historialComandosModal'
    ) as HistorialComandosModal;
  }

  @state()
  private terminals: TerminalList[] = [];

  async stateChanged(state: any) { 

    if(state.configConsole.band && this.band){
      await this.getApp(this.appId,state.configConsole.crearFolio)
      console.log("configuration",this.appId,this.app,state.configConsole.crearFolio)
      this.listadoFolio.open(this.app)
      this.band = false;
    }
  }

  @state()
  private apps: AppList[] = [];

  @state()
  termi?: Terminal;

  @state()
  templatesInfo:any = [];

  @state()
  objectoBand:Object[] = [];

  @state()
  template:any = 'Crear Terminal';

  @state()
  afiliacionesList?: any;

  @state()
  app?: any;

  @state()
  band?: any = false;

  @state()
  idApp?: any;

  @state()
  appId?: any;

  @state()
  afiliacion?: any;

  @state()
  versions?: any;

  @state()
  NameBtn = 'Crear Terminal';

  @state()
  detail = false;

  @state()
  detail2 = false;

  @state()
  folioList:any = false;

  @state()
  organizacion = '';

  @state()
  private detailsOpenedItem:any = "";

  @state()
  loader = true;

  @state()
  brand = '';

  @state()
  serial = '';

  async getTemplate(id: any): Promise<ResponseTemplateGet | null> {

    try {
      const resTer = await this.templateApi.getTemplate({ id });

      let res = iterarCampoJson2(
        resTer.data.schema.properties,
        resTer.data.schema.required
      );
      console.log(resTer.data.name);

      return resTer.data;
    } catch (error) {
      return null;
    }
  }

  async ObtenerTodo(){
    this.templatesInfo = []
    this.objectoBand = [];
    let idPlantilla = ""
    let appTemplates = this.app.templates ?? [];
    let selectedPlantillas = appTemplates.map((plantilla:any) => plantilla);
    appTemplates.forEach(async (templateId:any) => {
      idPlantilla = templateId;
      let dataSet:any = []
      await this.savedConfigurationApi.listSavedConfiguration({templateId:templateId})
      .then(response => response.data)
      .then(async (data:any) => {
        const templateInfo:any = await this.getTemplate(data[0].templateId);
        const data2 = await this.savedConfigurationApi.getSavedConfiguration({
          id:data[0].id
        });
        return {
          nameConfiguration:data[0].name,
          idConfiguration:data[0].id,
          configuration:data2.data,
          templateInfo:templateInfo.name,
          templateId
        }
      }).then(response => response)
      .then((data:any) => {
        console.log("DATA",data)
        this.objectoBand.push(data);
      });
    })
  }

  async ObtenerPlantillas(){
    console.log(this.app)
    this.templatesInfo = []

    let appTemplates = this.app.templates ?? [];
    let selectedPlantillas = appTemplates.map((plantilla:any) => plantilla);
    appTemplates.forEach(async (templateId:any) => {
      //const templateInfo:any = await this.getTemplate(templateId);
      const data = await this.savedConfigurationApi.listSavedConfiguration({
        templateId:templateId
      });
      if (data){
        this.templatesInfo.push({
          templateInfo:data.data,
          id: templateId
        })
      }
    });
  }

  async ObtenerListaConfiguracion(){
    console.log(this.templatesInfo)
    this.templatesInfo.forEach(async (template:any) => {
      const data = await this.savedConfigurationApi.listSavedConfiguration({
        templateId:template.id
      });
      console.log("configuracion",data.data)
      this.objectoBand.push(data.data)
    });

  }

  async ObtenerConfiguracion(){

    this.templatesInfo.forEach(async (template:any) => {
      const data = await this.savedConfigurationApi.getSavedConfiguration({
        id:template.templateInfo[0].id
      })
      console.log(data.data)
      this.objectoBand.push(data.data)
      console.log(this.objectoBand)
      //this.objectoBand = data;
    });

  }

  get editOrganizationModal() {
    return document.getElementById(
      'edit-organization-modal'
    ) as EditOrganizationModal;
  }

  private handleVersionCreated(ev: CustomEvent) {
    const { detail } = ev;
    if (this.app!.version) {
      this.app!.version.push(detail);
    } else {
      this.app!.version = [detail];
    }
    this.requestUpdate();
  }

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  disconnectedCallback(): void {
    this.storeUnsuscriber && this.storeUnsuscriber();
  } 

  async updated(){
    if (localStorage.getItem("affiliationState")) {
      console.log("El nombre es", localStorage.getItem("affiliationState"));
      const appSelect = this.appSelected;
      const optionValue:any = localStorage.getItem("affiliationState");

      const optionElement = appSelect.querySelector(`option[value="${optionValue}"]`);
      optionElement.selected = true;
    } 
  }

  protected async firstUpdated(){
    await this.getTerminals();
    await this.getApps();

    if (localStorage.getItem("affiliationState")) {
      console.log("El nombre es", localStorage.getItem("affiliationState"));
      const appSelect = this.appSelected;
      const optionValue:any = localStorage.getItem("affiliationState");
      this.appId = optionValue;
      this.getApp(optionValue,"")
      await this.ObtenerAfiliaciones(optionValue)

    } 
  }

  private async handleDeleteAffiliation() {
    const data:AffiliationApiDeleteAffiliationRequest = {
      appId:this.appId,
      affiliationId:this.afiliacion.affiliationId
    }
    const respuestaSwal = await Swal.fire({
      title: 'Desea eliminar la Afiliacion?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar'
    }).then(async(result) => {
      if (result.isConfirmed) {
        console.log(data)
        const res = await this.afiliacionesApi.deleteAffiliation(data);
        console.log("RES",res)
        Swal.fire(
          'Eliminado!',
          'Afiliacion borrada.', 
          'success'
        )
        location.reload();
      }
    })
  }
 
  async getTerminals() {
    store.dispatch(getTerminals());
    this.storeUnsuscriber = store.subscribe(() => {
      const { list, loading, listApps } = store.getState();
      console.log(loading);
      console.log({ list });
      this.terminals = list as TerminalList[];
      this.loader = loading;
    });
  }

  async ObtenerAfiliaciones(appId: any) {
    let data:AffiliationApiListAffiliationRequest = {
      appId: appId,
    }
    localStorage.removeItem('appId');
    localStorage.setItem('appId', appId);
    await this.afiliacionesApi
      .listAffiliation(data)
      .then(response => response.data)
      .then(data => {
        this.afiliacionesList = data;
        console.log(data)
      });
  }

  async ObtenerFolios(appId: any,affiliationId:any) {
    let data:FolioApiListFolioRequest = {
      appId: appId,
    }
    await this.folio
      .listFolio(data) 
      .then(response => response.data)
      .then(data => {
        //this.folioList = data;
        let dataFolio:any = []
        console.log("folios",data)
        data.map((item:any,index:number) => {
          if(affiliationId==item.affiliationId){
            dataFolio.push(item)
          }
        })
        console.log(dataFolio)
        this.folioList = dataFolio;
        if(this.folioList.length)
          /*if (this.detail2 === false) {
            this.detail2 = true; 
          } else if (this.detail2 === true) {
            this.detail2 = false; 
          }*/
          this.detail2 = true; 
        else{
          this.detail2 = false; 
        }
      });
  }

  async getApp(appId: any,folio:any) {
    this.templatesInfo= []
    const { configConsole } = store.getState() as State;
    try {
      const res = await this.appApi.getApp({ id: appId });
      this.app = res.data;
      const app = res.data;
      this.template = app.templates;
      this.template.forEach(async (templateId:string) => {
        const templateInfo = await this.templateApi.getTemplate({id:templateId});
        console.log("PROBANDO TEMPLATE",templateInfo.data)
        this.templatesInfo.push({
          id:templateId,
          schema:templateInfo.data
        })
        console.log("PROBANDO INFO",this.templatesInfo)
      });

      const objecto1 = configConsole;
      const objecto2 = { appId, app,folio };
      configConsole.page=2;
      Object.assign(objecto1, objecto2);
      store.dispatch(
        ConfigConsole({ 
          appId,
          app,
          folio,
          ...configConsole,
        })
      );
    } catch (err) {
      //errorNotification('Error al obtener la app');
      console.log(err);
    } finally {    
      //this.band = -1;
      this.ObtenerTodo();
    }
  }

  

  /*async getApp(appId: any,folio:any) {
    console.log(appId)
    try { 
      const res = await this.appApi.getApp({ id: appId });
      const app = res.data;
      console.log("app",app)
      this.app = res.data;
    } catch (err) {
      errorNotification('Error al obtener la app');
      console.log(err);
    } finally {    ;
      this.ObtenerTodo();
    }
  }*/

  private actionRenderer = (root: HTMLElement, _: any, item: any) => {
    render(
      html`
        <mwc-icon-button
          @click=${() => router.cambiarSeccion('terminal')}
          icon="visibility"
        ></mwc-icon-button>
        <mwc-icon-button
          icon="edit"
          @click="${() => {
            store.dispatch(editarTerminal(item.item));
            router.cambiarSeccion('edit-terminal');
          }}"
        ></mwc-icon-button>
        <mwc-icon-button
          icon="corporate_fare"
          @click="${() => {
            store.dispatch(editarTerminal(item.item));
            this.editOrganizationModal.open();
          }}"
        ></mwc-icon-button>
        <mwc-icon-button
          icon="delete"
          @click="${() => {

          }}"
        ></mwc-icon-button>
        <mwc-icon-button icon="more_vert"></mwc-icon-button>
      `,
      root
    );
  };

  edit(e: any) {
    store.dispatch(editarTerminal(e));
    router.cambiarSeccion('edit-terminal');
  }

  get CrearFolio() {
    return document.getElementById('crearFolio')! as CrearFolio;
  }

  get pushConfigModal() {
    return document.getElementById('pushConfigModal')! as PushConfigModal;
  }


  async getApps() {
    store.dispatch(getApps());
    const { configConsole } = store.getState() as State;
    this.storeUnsuscriber = store.subscribe(() => {
      const { list, loading, listApps } = store.getState();
      console.log(loading); 
      console.log("list",{ listApps });
      this.apps = listApps as AppList[];
      const apps = listApps as AppList[];
    });
  }


  render() {
    return html`
      <main
        class="w-full h-full FondoColor flex  ${!this.loader
          ? ''
          : 'items-center justify-center'}"
      >
        ${this.loader !== true
          ? html`
              <section
                class="w-3/4 relative flex flex-wrap p-4 SectionList rounded-l-lg"
              >
              <select name="cars" class="w-[210px] mx-1 mb-3 bg-white border border-gray-300 rounded-md text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white" id="appSelect"
              @change=${(ev: CustomEvent) => {
                this.appId = this.appSelected.value;
                localStorage.removeItem('affiliationState');
                localStorage.setItem('affiliationState', this.appId);
                this.getApp(this.appSelected.value,"")
                this.ObtenerAfiliaciones(this.appSelected.value) 

              }}>
              <option disabled selected>Aplicaciones</option>
                  ${this.apps.map((app:any,index:number)=> html`
                      <option value="${app.id}">${app.name}</option>
                  `)}
              </select> 
                <!--<div class="mx-2 TexField">
                  <custom-select
                    label="Aplicaciones"
                    id="apps"
                    .options=${this.apps.map(m => ({
                      label: m.name,
                      value: m.id,
                    }))}
                    @change=${(ev: CustomEvent) => {
                      //this.brandSelected = ev.detail;
                      //this.bandBatch= false;
                      this.appId = ev.detail;
                      //this.ObtenerFolios(ev.detail)
                      this.ObtenerAfiliaciones(ev.detail)
                    }} 
                  ></custom-select> -->
                </div>
                <div class="mx-2 TexField">
                  <!-- <input
                    type="search"
                    placeholder="Buscar"
                    id="gsearch"
                    style=":hover {background: white; border: 1px solid gainsboro;}"
                    class="w-full border-0 rounded-xl bg-gray-300 h-11"
                    name="gsearch"
                    @keyup="${async(ev: Event) => {
                      //this.handleChange(ev)
                    }}"
                  /> -->
                  <div class="flex items-center border-2 border-gray-200 rounded-full">
                    <mwc-icon-button icon="search" class="mr-3"></mwc-icon-button>
                    <input type="text" style=":hover {background: white; border: 1px solid gainsboro;}" class="px-4 bg-gray-300 py-2 w-full rounded-full focus:outline-none" placeholder="Buscar...">
                  </div>
                </div>
                <vaadin-grid
                  class="w-screen h-full"
                  .items="${this.afiliacionesList}"
                  .detailsOpenedItems="${this.detailsOpenedItem}"
                  @active-item-changed="${(
                    e: GridActiveItemChangedEvent<AffiliationInfoList>
                  ) => { 
                    this.afiliacion = e.detail.value; 
                    this.detailsOpenedItem =[e.detail.value]; 
                    console.log(this.afiliacion)
                    this.ObtenerFolios(this.afiliacion.appId,this.afiliacion.affiliationId)
                    localStorage.removeItem('afiliacion');
                    const afiliacionString = JSON.stringify(this.afiliacion);
                    localStorage.setItem('afiliacion', afiliacionString);
                    if (!e.detail.value) return false;
                    //this.appId = e.detail.value.affiliationId;
                    //this.getApp(e.detail.value.id)
                      if (this.detail === false) {
                        this.detail = true; 
                      } else if (this.detail === true) {
                        this.detail = false; 
                      }

                  }}"
                  .rowDetailsRenderer="${guard(
                    [],
                    () =>
                      (
                        root: HTMLElement,
                        _: Grid,
                        model: GridItemModel<AffiliationInfoList>
                      ) => {

                        render(
                          html`<vaadin-form-layout
                            .responsiveSteps=" ${[
                              { minWidth: '0', columns: 3 },
                            ]}"
                          >
                            <div
                            style="background-color:#F0EFFF;"
                              class="w-full h-14 flex items-center mt-5 justify-center flex-row "
                            >
                               <div 
                                class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                                @click=${() => {
                                  router.cambiarSeccion('edit-afiliation-page');
                                }}
                              >  
                                <div>  
                                  <mwc-icon-button icon="edit">
                                  </mwc-icon-button>
                                </div> 
                                <div> 
                                  <p class="text-center">Editar</p>
                                </div>
                              </div>  
                              <div
                                class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                                @click=${async () => {
                                  /*let page = 2; 
                                  store.dispatch(
                                    ConfigConsole({
                                      app:this.app,
                                      page,
                                      appId:this.appId,
                                      folioId:"110378" 
                                    })
                                  );
                                  this.listadoFolio.open(this.appId);*/
                                  //this.band = true; 
                                  console.log(this.objectoBand);
                                  console.log(this.appId)
                                  this.CrearFolio.open(this.appId,this.objectoBand,this.afiliacion);
                                  this.band=true; 
                                }}
                              >  
                                <div> 
                                  <mwc-icon-button icon="add">
                                  </mwc-icon-button>
                                </div> 
                                <div>
                                  <p class="text-center">Crear Folio</p>
                                </div>
                              </div>
                              <div 
                                class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                                @click=${() => {
                                  localStorage.removeItem('razonSocial');
                                  console.log(this.detailsOpenedItem[0].businessId)
                                  localStorage.setItem('razonSocial',this.detailsOpenedItem[0].businessId);
                                  router.cambiarSeccion('create-afiliation-page'); 
                                }}
                              >  
                                <div> 
                                  <mwc-icon-button icon="save">
                                  </mwc-icon-button>
                                </div> 
                                <div>
                                  <p class="text-center">Crear Afiliacion</p>
                                </div>
                              </div>  
                              <!-- <div
                                class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                                @click=${() => {
                                  this.agregarInstaladorModal.open(this.app);
                                }}
                              >  
                                <div> 
                                  <mwc-icon-button icon="content_copy">
                                  </mwc-icon-button>
                                </div>
                                <div>
                                  <p class="text-center">Copiar Folio</p>
                                </div>
                              </div>  -->
                              <!-- <div
                                class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                                @click=${() => {

                                }}
                              >  
                                <div> 
                                  <mwc-icon-button icon="visibility">
                                  </mwc-icon-button>
                                </div>
                                <div>
                                  <p class="text-center">Ver folio</p>
                                </div>
                              </div>    -->
                              <!-- <div
                                class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                                @click=${() => {

                                }}
                              >  
                                <div> 
                                  <mwc-icon-button icon="ios_share">
                                  </mwc-icon-button>
                                </div>
                                <div>
                                  <p class="text-center">Exportar folio</p>
                                </div>
                              </div>                             -->
                            </div>
                          </vaadin-form-layout>`,
                          root
                        );
                      }
                  )}"
                > 
                  <vaadin-grid-selection-column></vaadin-grid-selection-column>
                    <vaadin-grid-filter-column
                    path="affiliationId"
                  ></vaadin-grid-filter-column>
                  <vaadin-grid-filter-column
                  header="Razon social"
                    path="businessId"
                  ></vaadin-grid-filter-column>
                  <vaadin-grid-filter-column
                  header="name"
                    path="name"
                  ></vaadin-grid-filter-column>
                  <vaadin-grid-filter-column
                    path="contactName"
                  ></vaadin-grid-filter-column>
                </vaadin-grid>
                <mwc-icon-button 
                  icon="autorenew"
                  class="absolute top-0 right-0"
                  @click=${() => {
                    this.getApps();
                  }}
                ></mwc-icon-button>
                <edit-organization-modal
                  id="edit-organization-modal"
                ></edit-organization-modal>
              </section>
              <section class="w-1/4  SectionDetail justify-center scroll">
              <!--<div class="w-full flex justify-center">
                  <mwc-button
                    raised
                    class="rounded w-4/5 mx-auto mt-4 mb-2"
                    style="--mdc-theme-primary: #2E3EAE; --mdc-theme-on-primary: white;"
                    @click="${() => {
                      router.cambiarSeccion('create-afiliation-page');
                    }}"
                  >
                  <mwc-icon>save</mwc-icon>
                    Crear Afiliacion
                  </mwc-button>
                </div> -->
                <div class="w-full flex justify-center">
                  <mwc-button
                    raised
                    class="rounded w-4/5 mx-auto mt-4 mb-2"
                    style="--mdc-theme-secundary: #2E3EAE; --mdc-theme-on-secundary: white;"
                    @click="${() => {
                      router.cambiarSeccion('edit-masiva-page');
                    }}"
                  >
                  <mwc-icon>edit</mwc-icon>
                    Edicion masiva 
                  </mwc-button>
                </div>
                <div class="w-full flex justify-center">
                  <mwc-button
                    raised
                    class="rounded w-4/5 mx-auto mt-4 mb-2"
                    style="--mdc-theme-secundary: #2E3EAE; --mdc-theme-on-secundary: white;"
                    @click="${() => {
                      router.cambiarSeccion('create-masiva-afiliation-page');
                    }}"
                  >
                  <mwc-icon>upload</mwc-icon>
                    Carga masiva 
                  </mwc-button>
                </div>
                ${this.detail === true 
                  ? html`
                    <div class="w-full flex justify-center">
                      <mwc-button
                        raised 
                        class="rounded w-4/5 mx-auto mt-4 mb-2"
                        style="--mdc-theme-primary: tomato; --mdc-theme-on-primary: white"
                        @click="${() => {
                          this.handleDeleteAffiliation()
                          //router.cambiarSeccion('create-afiliation-page');
                        }}"
                      > 
                      <mwc-icon>delete</mwc-icon>
                        Eliminar Afiliacion
                      </mwc-button>
                    </div>
                    
                  `
                  : html``}
                <div class=" pt-3 flex items-center ">
              <p class="text-base	text-blue-500	ml-3 ">Folios Disponibles</p>
            </div>
            ${this.detail2 === true 
                  ? html`
              <div class="w-full py-3 border-b-2 border-grey-100 px-4">
              <div class="pt-1 ml-5">
              <div class="pt-1 ml-5">
            <ul class="list-disc pl-2">
              ${this.folioList.map(
                (folio:any, index:number) => html`
                  <li
                    class="flex items-center gap-2 ml-2"
                  >
                  <p class="text-slate-500 hover:text-blue-600 text-lg "></p>
                    <p class="text-slate-500 hover:text-blue-600 text-lg ">${folio.folioId}</p>
                    ${folio.hasOwnProperty("serial") 
                      ? html`<p class="py-1 capitalize px-4 w-fit text-orange-500 rounded font-bold">En Uso</p>` 
                      : html`<p class="py-1 capitalize px-4 w-fit text-green-500 rounded font-bold">Disponible</p>`
                    }
                    <!-- <mwc-icon-button
                      class="ml-1"
                      icon="delete" 
                      ariaLabel="Eliminar" 
                      @click=${() => { 

                      }}
                    ></mwc-icon-button> -->
                    <mwc-icon-button
                    class="ml-1"
                    icon="edit"
                    ariaLabel="Actualizar"  
                    @click=${async () => {
                      console.log(folio)
                      console.log(this.app)
                      let page = 2; 
                      store.dispatch(
                        ConfigConsole({
                          app:this.app,
                          page,
                          appId:this.appId,
                          folioId:folio.folioId 
                        })
                      );
                      this.listadoFolio.open(this.appId)
                    }}
                  ></mwc-icon-button>
                  </li>
                `
              )}
            </ul>
          </div>

              </div>
              </div>
              `
                  : html``}
              </section>
            `
          : html`
              <mwc-circular-progress
                indeterminate
                density=${20}
              ></mwc-circular-progress>
            `}
      </main>

      <ubicacion-terminal-mapa
        id="ubicacionTerminalMapa"
      ></ubicacion-terminal-mapa>
      <listado-crear-folio-afiliaciones id="listadoCrearFolio"></listado-crear-folio-afiliaciones>
      <listado-folio-afiliaciones id="listadoFolio"></listado-folio-afiliaciones>
      <editar-folio id="editarFolio"></editar-folio>
      <crear-folio-afiliacion id="crearFolioAfiliacion"></crear-folio-afiliacion>
      <instalar-app-modal id="instalarAppModal"></instalar-app-modal>
      <push-config-modal id="pushConfigModal"></push-config-modal>
      <enviar-mensaje-modal id="enviarMensajeModal"></enviar-mensaje-modal>
      <crear-folio id="crearFolio"></crear-folio>
      <historial-comandos-modal
        id="historialComandosModal"
      ></historial-comandos-modal> 
      <agregar-instalador-modal
        id="agregarInstaladorModal"
      ></agregar-instalador-modal>
      <descargar-instalador-modal
        id="descargarInstaladorModal"
      ></descargar-instalador-modal>
      <agregar-version-modal
        id="agregarVersionModal"
        @version-created=${this.handleVersionCreated}
      ></agregar-version-modal>
    `;
  }
}
