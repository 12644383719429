/* eslint-disable prettier/prettier */
// @ts-ignore
import { reducer } from './reducers';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas';
import { configureStore } from '@reduxjs/toolkit';

const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
  reducer,
  middleware: [sagaMiddleware],
}); //createStore(reducer, applyMiddleware(sagaMiddleware));

export default store;

sagaMiddleware.run(rootSaga);
