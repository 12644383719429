/* eslint-disable prettier/prettier */

import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '@ui5/webcomponents/dist/Dialog.js';
import '@vaadin/vaadin-select';
import { errorNotification, succesNotification } from '../../services/notifications';
import { apisConfigurator } from '../../utils/axios';
import '../CustomSelect/CustomSelect';
import {
  App,
  BrandList,
  ModelApi,
  VersionApi,
  FileApi,
  FileApiUploadFileRequest
} from '../../../api';
import store from '../../redux/store';
import { State } from '../../redux/types';
import { flatMarcas, modelosPorMaca } from '../../utils/functions';
import { CustomMultiSelect } from '../CustomMultiSelect/CustomMultiSelect';
import { CustomSelect } from '../CustomSelect/CustomSelect';

@customElement('cargar-archivo-modal')
export class CargarArchivoModal extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private fileApi: FileApi;

  constructor() {
    super();
    this.fileApi = new FileApi(...apisConfigurator('Agregar archivo'));
  }

  @property({ type: Object })
  app?: any;

  @state()
  private brandSelected?: string;

  @state()
  private loading = false;

  @state()
  private span = false;

  @state()
  private name:string = "";

  @state()
  private file?: File;

  @state()
  private appId:any = "";

  @state()
  private fileId:any = "";

  @state()
  private modelosMarcas: BrandList[] = [];

  private get dialog() {
    return document.getElementById('cargarArchivo')! as any;
  }

  private get botonInstalador() {
    return document.querySelector(
      '#seleccionar-archivo'
    )! as HTMLInputElement;
  }

  private get version() {
    return document.getElementById('versionSelect') as CustomSelect;
  }

  private get sistemaOperativo() {
    return document.getElementById('sistemaOperativo') as CustomSelect;
  }

  private get brand() {
    return document.getElementById('brand') as CustomSelect;
  }

  private get models() {
    return document.getElementById('models') as CustomMultiSelect<string>;
  }


  async open(fileId?: any, appId?:string) {
    if (!fileId) {
      errorNotification('Error al agregar archivo');
      return;
    }

    this.appId = appId;
    this.fileId = fileId;
    this.dialog.open = true;
  }

  close() {
    this.span=false;
    this.dialog.open = false;
    this.app = undefined;
  }

  protected firstUpdated(): void {
    const archivo = this.botonInstalador

    archivo.addEventListener('change', (e) => {
      console.log(this.botonInstalador!.files)
      if (this.botonInstalador!.files![0]) {
        // Subir el archivo al servidor
      } else {
        errorNotification("Error al cargar el archivo");
      }
      this.name = this.botonInstalador!.files![0].name;
      this.span = true ; 
    });
  }

  private async handleSubmit(e: Event) {
    e.preventDefault();
 

    this.file = this.botonInstalador!.files![0];

    if (!this.file ) {
      console.log(this.file)
      errorNotification('Error al agregar instalador');
      return;
    }

    const body: FileApiUploadFileRequest = {
      fileId:this.fileId,
      appId: this.appId,
      file:this.file
    
    };
    console.log(this.version);
    console.log("body",body);

    try {
      this.loading = true;
      const res = await this.fileApi.uploadFile(body);

      this.loading = false;
      console.log(res);
      if(res.status==200){
        succesNotification('Archivo registrado!')
      }
      this.close();
    } catch (error: any) {
      this.close();
      this.loading = false;
      console.log(error);
      console.log(error.message);
      errorNotification(error.message.toString());
    }
  }

  protected render() {
    return html` <ui5-dialog
      id="cargarArchivo"
      header-text="Agregar Archivo a ${this.fileId}"
      class="w-4/12 h-80"
    > 
      <div
        class="px-4 flex flex-col items-center h-full"
      >
        <div class="mx-2 w-full flex flex-col items-center relative">
          <div class="flex flex-col">
          <input
          id="seleccionar-archivo"
          type="file"
          hidden
          @change=${(ev: InputEvent) => {}}
        />
         <mwc-button
            class="rounded"
            label="Seleccionar Archivo"
            icon="publish"
            outlined
            .disabled=${this.loading}
            @click=${async () => {
                console.log("policia1")
              await this.botonInstalador.click();
            }}
          >
          </mwc-button>
          ${ 
            this.span ? html`<span class="text-green-500">${this.name}</span>` : html``
          }

        </div>

        <div slot="footer" class="dialog-footer mt-auto">
          <button
            class="text-red-600 p-4 rounded-md uppercase"
            id="closeDialogButton"
            @click=${() => {
              this.close();
            }}
          >
            Cerrar
          </button>
          <button
            class="text-blue-600 p-4 rounded-md uppercase"
            id="closeDialogButton"
            @click=${async (E:Event) => {
              this.handleSubmit(E);
            }}
            .disabled=${this.loading}
          >
            Guardar
          </button>
        </div>
      </div>
    </ui5-dialog>`;
  }
}
