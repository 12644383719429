/* eslint-disable prettier/prettier */
import { html, LitElement, render } from 'lit';
import { customElement, query, state, property } from 'lit/decorators.js';
import Swal from 'sweetalert2';
//import  JSONEditor  from 'json-editor';
//import * as JSONEditor from "json-editor";
//import JSONEditor = require("json-editor");
import '@material/mwc-icon-button';
import '@material/mwc-list/mwc-list.js';
import '@material/mwc-list/mwc-list-item.js';
import '@material/mwc-list/mwc-list.css';
import '@material/mwc-list/mwc-list-item.css';
import '@vaadin/vaadin-grid';
import router from '../router/router.js';
import { connect } from 'pwa-helpers';
import {
  TemplateApi,
  ConfigurationApi,
  ConfigurationApiGetConfigurationRequest,
  ConfigurationApiDeleteConfigurationRequest,
  FolioApi,
  FolioApiGetFolioRequest,
  FolioApiDeleteFolioConfigurationRequest,
  FolioApiGetFolioConfigurationRequest
} from '../../api/api.js';
import {
  ResponseTemplateGet,
} from '../../api/index.js';
import { AxiosResponse } from 'axios';
import {
  InsertarPropiedad,
  iterarCampoJson2,
  existenciaConfiguracion,
  deleteCamp,
} from '../utils/functions.js';
import store from '../redux/store.js';
import { State } from '../redux/types.js';
import { Appp, ConfigConsole, PageFolio } from '../redux/actions/index.js';
import {
  descripcionPlantilla,
  idPlantilla,
  TypeJsonSchema
} from '../redux/actions/index.js';
import { existenciaConfiguracionTemplate,idConfiguracion} from '../utils/functions.js';
import { apisConfigurator } from '../utils/axios.js';
import {
  agregarPlantilla,
  nombrePlantilla,
} from '../redux/actions/index.js';

import { InformacionConfiguracion } from './modals/InformacionConfiguracion.js';
import './modals/InformacionConfiguracion.js';

@customElement('listado-plantilla-crear-folio-afiliaciones')
export class ListadoPlantillaCrearFolioAfiliaciones extends connect(store)(LitElement) {
  private templateApi: TemplateApi;
  private configurationApi: ConfigurationApi;
  private folio: FolioApi;

  private constructor() {
    super();
    this.templateApi = new TemplateApi(...apisConfigurator(''));
    this.configurationApi = new ConfigurationApi(...apisConfigurator(''));
    this.folio = new FolioApi(
      ...apisConfigurator('Folio')
    );
  }

  get informacionConfiguracion() {
    return document.getElementById('informacionConfiguracion')! as InformacionConfiguracion;
  }

  get idCampo() {
    return document.querySelector('#campo')! as HTMLInputElement;
  }

  @query('#campos')
  private campos!: HTMLInputElement;

  @query('#descripcion')
  @state()
  loader = true;

  @state()
  appTemplates: string[] = [];

  @state()
  template: any[] = [];

  @state()
  item: string = "";

  @state()
  configuration: any;

  @state()
  selectedPlantillas: string[] = [];

  @state()
  templatesInfo: { name: string; description: string; id: string }[] = [];

  @state()
  Plantill = { nombre: 'wladimir', uso: '12', descripcion: 'prueba1' };

  @state()
  plantillas: Object[] = [];

  @state()
  private loading = false;

  @state()
  private band = true;

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }
 

  async stateChanged(state: any) {
    if(state.configConsole.page == 2 && this.band){
      this.ObtenerTodo2();
      this.band = false;
    }
  }

  async getConfigurations(id:string) {
    const { configConsole } = store.getState() as State;
    console.log(configConsole)
    let JsonSchema;
    let templateName;
    let prueba;
    try { 
      await this.templateApi
      .getTemplate({ id })
      .then(response => response.data)
      .then(data => {
        console.log("DATA",data) 
        this.configuration = data;
        JsonSchema = data.schema;
        templateName = data.name;
        const properties = JsonSchema.properties;
        configConsole.JsonSchema = JsonSchema;
        console.log("properties",properties);
        const prueba = deleteCamp(properties);

        const objecto1 = configConsole;
        const objecto2 = {
          templateId: id,
          JsonSchema,
          templateName
        };
        Object.assign(objecto1, objecto2);

      });
      console.log("WLADIMIR")
      const folioApiGetFolioRequest:FolioApiGetFolioRequest = {
        appId: configConsole.appId,
        folioId: configConsole.folioId
      }
      const res = await this.folio.getFolio(folioApiGetFolioRequest)
      let datosFolio =  res.data;
      let controlador:boolean;
      if(datosFolio.templates?.length){
        controlador = true;
      }else{
        controlador = false;
      }
      const objecto1 = configConsole;
      const objecto2 = { existConfig: controlador,page:3 }; 
      Object.assign(objecto1, objecto2);
        console.log("CONFIGURATION",true)
      store.dispatch(
        ConfigConsole({ 
          existConfig: objecto1,
          templateId: id,
          JsonSchema,
          properties: prueba,
          ...configConsole,
        })
      );
      
    } catch (error) {
      console.log(error);
    }finally{
      //PageFolio(3);
    }
  }

  async comprobarConfigurations(id:string) {
    const { configConsole } = store.getState() as State;
    const folioApiGetFolioConfigurationRequest:FolioApiGetFolioConfigurationRequest = {
      appId:configConsole.folio.appId,
      folioId:configConsole.folio.folioId,
      templateId: id
    }
    try{
      const res = await this.folio.getFolioConfiguration(folioApiGetFolioConfigurationRequest)
      let datosFolio =  res.data;
      let controlador:boolean;
      console.log(datosFolio)
      if(datosFolio.hasOwnProperty("parameters") || datosFolio.hasOwnProperty("parametersArray") ){
        controlador = true;
      }else{
        controlador = false;
      }
      let idConfig:FolioApiDeleteFolioConfigurationRequest = {
        appId:configConsole.appId,  
        templateId:id,
        folioId: configConsole.folio.folioId
      }
      console.log(controlador)
      if(controlador) {
        Swal.fire({
          title: 'Desea elimnar la configuracion?',
          text: "Esta Plantilla contiene configuracion!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Eliminar'
        }).then(async(result) => {
          if (result.isConfirmed) {
            const res = await this.folio.deleteFolioConfiguration(idConfig);
            console.log("RES",idConfig)
            Swal.fire(
              'Eliminado!',
              'configuracion borrada.',
              'success'
            )
          }
        })
      }else{
        Swal.fire('No posee parametros configuracion!')
      }

      
    } catch (error) {
      console.log(error);
      Swal.fire('No posee parametros configuracion!')
    }
  }

  async getTemplate(id: any): Promise<ResponseTemplateGet | null> {
    this.loader = true;
    try {
      const resTer = await this.templateApi.getTemplate({ id });

      let res = iterarCampoJson2(
        resTer.data.schema.properties,
        resTer.data.schema.required
      );
      console.log(resTer.data.name);

      this.loader = false;
      return resTer.data;
    } catch (error) {
      return null;
    }
  }

  async deleteTemplate(id: any) {
    console.log("ELIMINADO")
    /*this.loader = true;
    try {
      const resTer = await this.templateApi.deleteTemplate({ id });
      if(resTer.request.status==200){
        await Swal.fire('Eliminado!', 'Plantilla ha sido eliminada', 'success');
        location.reload()
      }

    } catch (error) {
      return null;
    }*/
  } 

  async comprobarConfigurationsEditar(id:string) {

    try{
      const res = await this.configurationApi.listConfiguration();
      console.log("RES",res)
      const result = existenciaConfiguracionTemplate(
        res.data,
        id
      );
      console.log("RESULT",result)
      if(result) {
        Swal.fire(
          'No Permitido',
          'Esta plantilla contiene configuracion guardada',
          'question'
        )
      }else{
        await this.getConfigurations(id);
        let res = iterarCampoJson2(
          this.configuration.schema.properties,
          this.configuration.schema.required
        );
        store.dispatch(agregarPlantilla(res));
        store.dispatch(nombrePlantilla(this.configuration.name));
        store.dispatch(idPlantilla(id));
        store.dispatch(TypeJsonSchema(this.configuration.schema.type));
        store.dispatch(descripcionPlantilla(this.configuration.description));
        router.cambiarSeccion('edit-template');
      }

      
    } catch (error) {
      console.log(error);
    }
  }
 
  async ObtenerTodo2(){
    this.templatesInfo = []
    const { configConsole } = store.getState() as State;
    console.log("templates",configConsole);
    this.template=configConsole.app.templates;
    this.loader=true;
    this.appTemplates = configConsole.app.templates ?? [];
    this.selectedPlantillas = this.appTemplates.map(plantilla => plantilla);
    console.log("PROBANDO",this.appTemplates)
    this.appTemplates.forEach(async (templateId:any,index:number) => {
      const templateInfo = await this.getTemplate(templateId);
      console.log(`templateInfo${index}`,templateInfo);
      if (templateInfo){
        this.loader=false;
        this.templatesInfo = [
          ...this.templatesInfo,
          { ...templateInfo, id: templateId },
        ];
      }
    });
  }

  async Obtenertodo() {
    const { configConsole } = store.getState() as State;
    console.log("templates",configConsole.app.templates);

    this.appTemplates = configConsole.app.templates ?? [];
    const id = configConsole.app.templates[0];

    await this.templateApi
      .getTemplate({ id })
      .then(response => response.data)
      .then(data => {
        this.plantillas = [ 
          {
            name: data.name,
            description: data.description,
            id,
          },
        ];

        const JsonSchema = data.schema;
        const properties = JsonSchema.properties;
        configConsole.JsonSchema = JsonSchema;
        console.log(properties);
        const prueba = deleteCamp(properties);

        const objecto1 = configConsole;
        const objecto2 = {
          templateId: id,
          JsonSchema,
          templateName: data.name,
        };
        Object.assign(objecto1, objecto2);
        store.dispatch(
          ConfigConsole({
            templateId: id,
            JsonSchema,
            properties: prueba,
            ...configConsole,
          })
        );

        return true;
      })
      .then(dat => {
        if (dat) {
          this.getConfigurations("");
        }
      });
  } 

  protected firstUpdated(): void { 
  }

  render() {
    return html`
      <main class="w-full FondoColor flex " style="height: 28rem;">
      ${this.loader !== true
          ? html`
        <section class="w-full flex flex-wrap">
          <vaadin-grid class="h-full" .items="${this.templatesInfo}">
            <vaadin-grid-sort-column
              .renderer="${(root: HTMLElement, _: any, item: any) => {
                render(
                  html`
                    <input 
                      class=""
                      type="radio"
                      name="platform"
                      value=${item.item.id==this.item? true : false}
                      @change="${async(ev: InputEvent) => {
                        //this.item=item.item.id;
                         //this.getConfigurations(item.item.id);
                      }}"
                    />
                  `,
                  root
                );
              }}"
            >
            </vaadin-grid-sort-column>
            <vaadin-grid-sort-column
              path="name"
              header="Nombre"
            ></vaadin-grid-sort-column>
            <vaadin-grid-sort-column
              path="description"
            ></vaadin-grid-sort-column>
            <vaadin-grid-sort-column
            header="Acciones"
            .renderer="${(root: HTMLElement, _: any, item: any) => {
              render(html`
                <div 
                  class="flex items-center  ml-2"
                > 
                <mwc-icon-button
                    class="ml-1"
                    icon="edit" 
                    ariaLabel="Actualizar"
                    @click=${async() => {
                      //console.log(item)
                      //console.log(item.item)
                      //this.comprobarConfigurationsEditar(item.item.id)
                      this.getConfigurations(item.item.id); 
                    }}
                  ></mwc-icon-button>
                  <mwc-icon-button
                    class="ml-1"
                    icon="delete"
                    ariaLabel="Eliminar"
                    @click=${() => {
                      this.comprobarConfigurations(item.item.id)

                    }}
                  ></mwc-icon-button>
                </div>
              
              `, root); 
            }}"
          ></vaadin-grid-sort-column>
          </vaadin-grid>
          <mwc-icon-button
                  icon="autorenew"
                  class="absolute top-0 right-0"
                  @click=${() => {
                    this.ObtenerTodo2();
                  }}
                ></mwc-icon-button>
        </section>
        `
          : html`
              <mwc-circular-progress
                indeterminate
                density=${20}
              ></mwc-circular-progress>
            `}
      </main>
      <informacion-configuracion
        id="informacionConfiguracion"
      ></informacion-configuracion>
    `;
  }
}
