import { put, takeLatest } from 'redux-saga/effects';
import { GET_USUARIOS, USUARIOS_RECEIVED } from '../actions/usuario';
import { UserApi } from '../../../api';
import { apisConfigurator } from '../../utils/axios';

export function* getUsuarios(): Generator {
  const usuarioApi = new UserApi(...apisConfigurator(''));
  console.log('getUsuarios');
  const data = yield usuarioApi.userList().then(res => res.data);

  console.log({ data });
  yield put({ type: USUARIOS_RECEIVED, usuarios: data });
}

export function* usuariosWatcher() {
  yield takeLatest(GET_USUARIOS, getUsuarios);
}
