/* eslint-disable prettier/prettier */
export interface Terminal {
  modelo: string;
  marca: string;
  serial: string;
  estado: string;
  dueno: string;
  ubicacion?: string;
  icon?: string;
}

export interface  Firmware {
  name: string;
  briefDescription: string; 
  description: string;
  developer: string;
  brand: string;
  model?: string;
  os?: string;
  requirements?: string;
}


export interface Application {
  nombre: string;
  descripcion: string;
  foto: string;
  fotosEjemplo: string[];
}
export interface Usuario {
  id: string;
  nombre: string;
  organizacion: string;
  email: string;
  permisos: Permisos;
  grupoTerminales?: GrupoTerminales[];
  terminales?: Terminal[];
}
export interface GrupoTerminales {
  // tipo: string;
  grupo: string;
  dueno: string;
  terminales?: Terminal[];
  grupoHijo?: GrupoTerminales[];
  icon?: string;
}
export interface Permisos {
  crearUsuarios: boolean;
  crearGrupos: boolean;
  crearTerminales: boolean;
}

export const basicUser: Usuario = {
  id: 'asd34d87vf7v',
  nombre: 'Juan Perez',
  organizacion: 'Apps2Go',
  email: 'juanperez@apps2go.com',
  permisos: {
    crearUsuarios: true,
    crearGrupos: true,
    crearTerminales: true,
  },
  grupoTerminales: [
    {
      grupo: 'Tienda1',
      dueno: '1234567',
      terminales: [
        {
          modelo: 'string',
          marca: 'string',
          serial: 'Tienda1-1',
          estado: 'string',
          dueno: 'string',
        },
        {
          modelo: 'string',
          marca: 'string',
          serial: 'Tienda1-2',
          estado: 'string',
          dueno: 'string',
        },
        {
          modelo: 'string',
          marca: 'string',
          serial: 'Tienda1-3',
          estado: 'string',
          dueno: 'string',
        },
      ],
    },
    {
      grupo: 'ReSeller1',
      dueno: '7654321',
      terminales: [
        {
          modelo: 'string',
          marca: 'string',
          serial: 'ReSeller1-1',
          estado: 'string',
          dueno: 'string',
        },
        {
          modelo: 'string',
          marca: 'string',
          serial: 'ReSeller1-2',
          estado: 'string',
          dueno: 'string',
        },
        {
          modelo: 'string',
          marca: 'string',
          serial: 'ReSeller1-3',
          estado: 'string',
          dueno: 'string',
        },
        {
          modelo: 'string',
          marca: 'string',
          serial: 'ReSeller1-4',
          estado: 'string',
          dueno: 'string',
        },
        {
          modelo: 'string',
          marca: 'string',
          serial: 'ReSeller1-5',
          estado: 'string',
          dueno: 'string',
        },
      ],
      grupoHijo: [
        {
          grupo: 'ReSeller1-Tienda1',
          dueno: '1237654',
          terminales: [
            {
              modelo: 'string',
              marca: 'string',
              serial: 'ReSeller1-Tienda1-1',
              estado: 'string',
              dueno: 'string',
            },
            {
              modelo: 'string',
              marca: 'string',
              serial: 'ReSeller1-Tienda1-2',
              estado: 'string',
              dueno: 'string',
            },
            {
              modelo: 'string',
              marca: 'string',
              serial: 'ReSeller1-Tienda1-3',
              estado: 'string',
              dueno: 'string',
            },
          ],
        },
      ],
    },
  ],
  terminales: [
    {
      modelo: 'string',
      marca: 'string',
      serial: 'POS-test-1',
      estado: 'string',
      dueno: 'string',
    },
    {
      modelo: 'string',
      marca: 'string',
      serial: 'POS-test-2',
      estado: 'string',
      dueno: 'string',
    },
    {
      modelo: 'string',
      marca: 'string',
      serial: 'POS-test-3',
      estado: 'string',
      dueno: 'string',
    },
    {
      modelo: 'string',
      marca: 'string',
      serial: 'POS-test-4',
      estado: 'string',
      dueno: 'string',
    },
    {
      modelo: 'string',
      marca: 'string',
      serial: 'POS-test-5',
      estado: 'string',
      dueno: 'string',
    },
  ],
};

export const terminalesPrueba = [
  {
    modelo: 'A290',
    marca: 'PAX',
    serial: 'POS-test-1',
    estado: 'activo',
    dueno: 'apps2go',
    ubicacion: 'Caracas',
  },
  {
    modelo: 'A290',
    marca: 'PAX',
    serial: 'POS-test-2',
    estado: 'activo',
    dueno: 'apps2go',
    ubicacion: 'Caracas',
  },
  {
    modelo: 'A290',
    marca: 'PAX',
    serial: 'POS-test-3',
    estado: 'activo',
    dueno: 'apps2go',
    ubicacion: 'Miranda',
  },
  {
    modelo: 'N9210',
    marca: 'Newland',
    serial: 'POS-test-4',
    estado: 'activo',
    dueno: 'apps2go',
    ubicacion: 'Caracas',
  },
  {
    modelo: 'N9210',
    marca: 'Newland',
    serial: 'POS-test-5',
    estado: 'inactivo',
    dueno: 'apps2go',
    ubicacion: 'Miranda',
  },
  {
    modelo: 'N9210',
    marca: 'Newland',
    serial: 'POS-test-6',
    estado: 'inactivo',
    dueno: 'apps2go',
    ubicacion: 'Caracas',
  },
  {
    modelo: 'N9210',
    marca: 'Newland',
    serial: 'POS-test-7',
    estado: 'activo',
    dueno: 'apps2go',
    ubicacion: 'Caracas',
  },
];

export const appPrueba: Application[] = [
  {
    descripcion: 'Aplicación Fuerza de Ventas Agnostiko',
    foto: './avatar_agnostiko_boldblue.jpg',
    fotosEjemplo: ['./avatar_agnostiko_white.jpg'],
    nombre: 'SFA Agnostiko',
  },
  {
    descripcion: 'Tienda de aplicaciones Agnostiko',
    foto: './avatar_agnostiko_boldblue.jpg',
    fotosEjemplo: ['./avatar_agnostiko_white.jpg'],
    nombre: 'Tienda Agnostiko',
  },
  {
    descripcion: 'Funcionalidades LUP Agnostiko',
    foto: './avatar_agnostiko_boldblue.jpg',
    fotosEjemplo: ['./avatar_agnostiko_white.jpg'],
    nombre: 'LUP Agnostiko',
  },
  {
    descripcion: 'Manejo de terminal remoto Agnostiko',
    foto: './avatar_agnostiko_boldblue.jpg',
    fotosEjemplo: ['./avatar_agnostiko_white.jpg'],
    nombre: 'TMS Agnostiko',
  },
];

export const usuariosPrueba: Usuario[] = [
  {
    id: '1234567',
    nombre: 'Pablo Perez',
    organizacion: 'apps2go',
    email: 'pabloperez@apps2go.com',

    permisos: {
      crearUsuarios: false,
      crearGrupos: true,
      crearTerminales: true,
    },
  },
  {
    id: '7654321',
    nombre: 'Jose Perez',
    organizacion: 'apps2go',
    email: 'joseperez@apps2go.com',

    permisos: {
      crearUsuarios: true,
      crearGrupos: false,
      crearTerminales: false,
    },
  },
  {
    id: '1237654',
    nombre: 'Ricardo Perez',
    organizacion: 'apps2go',
    email: 'ricardoperez@apps2go.com',

    permisos: {
      crearUsuarios: false,
      crearGrupos: false,
      crearTerminales: true,
    },
  },
  {
    id: '4561237',
    nombre: 'Fernando Perez',
    organizacion: 'apps2go',
    email: 'fernandoperez@apps2go.com',

    permisos: {
      crearUsuarios: false,
      crearGrupos: true,
      crearTerminales: true,
    },
  },
  {
    id: '8541235',
    nombre: 'Carlos Perez',
    organizacion: 'apps2go',
    email: 'Carlosperez@apps2go.com',

    permisos: {
      crearUsuarios: false,
      crearGrupos: false,
      crearTerminales: true,
    },
  },
];

export const gruposPrueba: GrupoTerminales[] = [
  {
    grupo: 'Grupo1',
    dueno: '1234567',
    terminales: [
      {
        modelo: 'string',
        marca: 'string',
        serial: 'Grupo1-1',
        estado: 'string',
        dueno: 'string',
      },
      {
        modelo: 'string',
        marca: 'string',
        serial: 'Grupo1-2',
        estado: 'string',
        dueno: 'string',
      },
      {
        modelo: 'string',
        marca: 'string',
        serial: 'Grupo1-3',
        estado: 'string',
        dueno: 'string',
      },
      {
        modelo: 'string',
        marca: 'string',
        serial: 'Grupo1-4',
        estado: 'string',
        dueno: 'string',
      },
    ],
  },
  {
    grupo: 'Grupo2',
    dueno: '1234567',
    terminales: [
      {
        modelo: 'string',
        marca: 'string',
        serial: 'Grupo2-1',
        estado: 'string',
        dueno: 'string',
      },
      {
        modelo: 'string',
        marca: 'string',
        serial: 'Grupo2-2',
        estado: 'string',
        dueno: 'string',
      },
      {
        modelo: 'string',
        marca: 'string',
        serial: 'Grupo2-3',
        estado: 'string',
        dueno: 'string',
      },
      {
        modelo: 'string',
        marca: 'string',
        serial: 'Grupo2-4',
        estado: 'string',
        dueno: 'string',
      },
      {
        modelo: 'string',
        marca: 'string',
        serial: 'Grupo2-5',
        estado: 'string',
        dueno: 'string',
      },
    ],
  },
  {
    grupo: 'Grupo3',
    dueno: '1234567',
    terminales: [
      {
        modelo: 'string',
        marca: 'string',
        serial: 'Grupo3-1',
        estado: 'string',
        dueno: 'string',
      },
      {
        modelo: 'string',
        marca: 'string',
        serial: 'Grupo3-2',
        estado: 'string',
        dueno: 'string',
      },
      {
        modelo: 'string',
        marca: 'string',
        serial: 'Grupo3-3',
        estado: 'string',
        dueno: 'string',
      },
    ],
  },
  {
    grupo: 'Grupo4',
    dueno: '1234567',
    terminales: [
      {
        modelo: 'string',
        marca: 'string',
        serial: 'Grupo4-1',
        estado: 'string',
        dueno: 'string',
      },
      {
        modelo: 'string',
        marca: 'string',
        serial: 'Grupo4-2',
        estado: 'string',
        dueno: 'string',
      },
      {
        modelo: 'string',
        marca: 'string',
        serial: 'Grupo4-3',
        estado: 'string',
        dueno: 'string',
      },
      {
        modelo: 'string',
        marca: 'string',
        serial: 'Grupo4-4',
        estado: 'string',
        dueno: 'string',
      },
      {
        modelo: 'string',
        marca: 'string',
        serial: 'Grupo4-5',
        estado: 'string',
        dueno: 'string',
      },
      {
        modelo: 'string',
        marca: 'string',
        serial: 'Grupo4-6',
        estado: 'string',
        dueno: 'string',
      },
      {
        modelo: 'string',
        marca: 'string',
        serial: 'Grupo4-7',
        estado: 'string',
        dueno: 'string',
      },
      {
        modelo: 'string',
        marca: 'string',
        serial: 'Grupo4-8',
        estado: 'string',
        dueno: 'string',
      },
    ],
  },
];
