import { LitElement, html, render } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
// import { guard } from 'lit/directives/guard.js';
import '@ui5/webcomponents/dist/Dialog.js';
import '@vaadin/vaadin-select';
import '@vaadin/vaadin-grid';
//import { TerminalApi, TerminalList } from '../../../api';
//import { AppApi, AppList, CommunicationTerminalApi,  } from '../../../api';
import {
  AppApi,
  AppList,
  CommunicationTerminalApi,
  CommunicationTerminalApiRequestCommandRequest,
  RequestCommandTypeCommandEnum,
  Terminal,
} from '../../../api';
import { errorNotification } from '../../services/notifications';
import { apisConfigurator } from '../../utils/axios';

@customElement('ubicacion-terminal-mapa')
export class UbicacionTerminalMapa extends LitElement {
  private appApi: AppApi;
  private communicationApi: CommunicationTerminalApi;

  @state()
  private apps: AppList[] = [];

  @state()
  private detailsOpenedItem: AppList[] = [];

  private get dialog() {
    return document.getElementById('dialog-ubicacion-terminal')! as any;
  }

  @state()
  private loading = false;

  @state()
  private longitud = 77.923029;

  @state()
  private latitud = 10.305385;

  constructor() {
    super();
    this.appApi = new AppApi(...apisConfigurator('Aplicaciones'));
    this.communicationApi = new CommunicationTerminalApi(
      ...apisConfigurator('Comunicación')
    );
  }

  @property({ type: Object })
  terminal?: any;

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  protected firstUpdated(): void {

  }

  async getApps() {
    try {
      const res = await this.appApi.listApp();

      this.apps = res.data;
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  async open(terminal: any) {
    //console.log(`wladimir ${terminal}`);
    if (!terminal) {
      errorNotification('Error al instalar la app');
      return;
    }
    this.loading = true;
    this.getApps();
    if (
      terminal?.generalInformation?.locationLatitude &&
      terminal?.generalInformation?.locationLongitude
    ) {
      this.latitud = terminal?.generalInformation?.locationLatitude.value;
      this.longitud = terminal?.generalInformation?.locationLongitude.value;
    } else {
      this.latitud = terminal?.location?.lat;
      this.longitud = terminal?.location?.log;
    }

    this.terminal = terminal;
    //console.log(this.latitud);
    await this.getApps();
    this.dialog.open = true;
  }

  private async instalarApp(aplicacion: any) {
    //console.log('terminal', this.terminal);
    //console.log('aplication', aplicacion.item);

    if (!this.terminal) return;
    const requestCommand: CommunicationTerminalApiRequestCommandRequest = {
      brand: this.terminal.brand,
      serial: this.terminal.serial,
      requestCommand: {
        commandId: `2022/12/20`,
        typeCommand: `UPDATE_APP`,
        parameters: [aplicacion.item.id, `1.0.0`],
      },
    };

    try {
      this.close();
      const res = await this.communicationApi.requestCommand(requestCommand);
      //console.log('envio mensaje res', res);
    } catch (err: any) {
      console.log('envio mensaje err', err);
    }
  }

  close() {
    this.dialog.open = false;
    this.terminal = undefined;
    this.apps = [];

    this.longitud = 77.923029;
    this.latitud = 10.305385;
  }

  render() {
    return html` <ui5-dialog
      id="dialog-ubicacion-terminal"
      header-text="Mostrar Terminal"
      class="w-8/12"
    >
      <iframe
        width="1300"
        height="400"
        frameborder="0"
        scrolling="no"
        marginheight="0"
        marginwidth="0"
        src="https://maps.google.com/maps?q=${this.latitud},
          ${this.longitud}&hl=es;z=14&amp;output=embed"
      >
      </iframe>
      <div slot="footer" class="dialog-footer">
        <button
          class="text-red-600 p-4 mb-2 rounded-md uppercase"
          id="closeDialogButton"
          @click=${() => {
            this.close();
          }}
        >
          Cerrar
        </button>
      </div>
    </ui5-dialog>`;
  }
}
