/* eslint-disable prettier/prettier */
import { Chart, registerables } from 'chart.js';
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import ChartDataLabels from 'chartjs-plugin-datalabels';

import '../../components/DashboardGraphCard/DashboardGraphCard';
import '../../components/DashboardCounter/DashboardCounter';
import '../../components/VerticalBarGraph/VerticalBarGraph';
import '../../components/CustomMap/CustomMap';

Chart.register(...registerables);
Chart.register(ChartDataLabels);

@customElement('dashboard-counter')
export class DashboardCounter extends LitElement {
  @property({ type: String })
  id = 'donut-chart';

  @property({ type: Boolean })
  showDataLabels = true;

  @property({ type: Array })
  data = [75, 25];

  @property({ type: Array })
  dataLabels = ['Activos', 'Inactivos'];

  @property({ type: Array })
  dataColors = [
    '#2FB83D',
    '#B94332',
    'rgb(255, 205, 86)',
    'rgb(75, 192, 192)',
    'rgb(54, 162, 235)',
    ,
  ];

  protected firstUpdated(): void {
    this.buildWidget();
  }

  round(number: number) {
    return Math.round(number);
  }

  buildWidget() {
    const canvas = this.shadowRoot?.getElementById(
      this.id
    ) as HTMLCanvasElement;

    new Chart(canvas, {
      type: 'doughnut',
      data: {
        labels: this.dataLabels,
        datasets: [
          {
            backgroundColor: this.dataColors,
            data: this.data,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        cutout: '75%',
      },
    });
  }

  render() {
    return html`<div class="donutChart">
      <div class="counter">
        <canvas id="${this.id}" width="200"></canvas>
        <div class="centerLabel">
          <p>
            ${this.round(
              (this.data[this.getDataHighValueIndex()] * 100) /
                this.data.reduce((p, c) => p + c)
            )}%
          </p>
          <span>${this.dataLabels[this.getDataHighValueIndex()]}</span>
        </div>
      </div>
      <div class="counter-legend">
        ${this.data.map(
          (d, index) => html`
            <div class="flex">
              <div
                class="legend-color"
                style=${`background-color: ${this.dataColors[index]}`}
              ></div>
              <p><span>${d}</span>${this.dataLabels[index]}</p>
            </div>
          `
        )}
        <div class="divider"></div>
        <p>
          <span>${this.round(this.data.reduce((p, c) => p + c))}</span> Total
        </p>
      </div>
    </div> `;
  }

  getDataHighValueIndex() {
    let high = 0;

    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i] > this.data[high]) {
        high = i;
      }
    }

    return high;
  }

  static get styles() {
    return css`
      .donutChart {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background: #fffbff;
      }

      .counter {
        position: relative;
      }

      .flex {
        display: flex;
        align-items: center;
      }

      .legend-color {
        height: 8px;
        width: 20px;
        border-radius: 8px;
        margin-left: 2px;
      }

      .counter-legend span {
        font-size: 18px;
        margin: 0 4px;
        color: #000645;
      }

      .counter-legend p {
        font-size: 14px;
        margin: 4px 0;
        color: #000645;
      }

      .counter-legend .divider {
        width: 40px;
        height: 4px;
        border-radius: 4px;
        background-color: gray;
        margin: 4px 0;
        color: #000645;
      }

      .centerLabel {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-45%, -50%);
        font-size: 1.2em;
        font-weight: bold;
        text-align: center;
        display: flex;
        flex-direction: column;
      }

      .centerLabel * {
        margin: 0;
      }

      .centerLabel p {
        font-size: 1.6em;
      }
    `;
  }
}
