/* tslint:disable */
/* eslint-disable */
/**
 * TMS Agnostiko
 * Tiene la definición de todos los servicios de TMS a Agnostic (para uso en la web o externo)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { Firmware } from '../models';
// @ts-ignore
import { FirmwareList } from '../models';
// @ts-ignore
import { Message } from '../models';
// @ts-ignore
import { RequestFirmwareUpdate } from '../models';
// @ts-ignore
import { ResponseFirmwareCreate } from '../models';
/**
 * FirmwareApi - axios parameter creator
 * @export
 */
export const FirmwareApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary añade un instalador a un firmware
         * @param {string} id 
         * @param {File} installer 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFirmwareInstaller: async (id: string, installer: File, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addFirmwareInstaller', 'id', id)
            // verify required parameter 'installer' is not null or undefined
            assertParamExists('addFirmwareInstaller', 'installer', installer)
            const localVarPath = `/firmware/{id}/installer`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


            if (installer !== undefined) { 
                localVarFormParams.append('installer', installer as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Realiza la creación de un firmware
         * @param {Firmware} firmware 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFirmware: async (firmware: Firmware, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'firmware' is not null or undefined
            assertParamExists('createFirmware', 'firmware', firmware)
            const localVarPath = `/firmware`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(firmware, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary elimina un firmware
         * @param {string} id 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFirmware: async (id: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFirmware', 'id', id)
            const localVarPath = `/firmware/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary elimina un instalador de un firmware
         * @param {string} id 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFirmwareInstaller: async (id: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFirmwareInstaller', 'id', id)
            const localVarPath = `/firmware/{id}/installer`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Obtener un firmwares
         * @param {string} id 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFirmware: async (id: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFirmware', 'id', id)
            const localVarPath = `/firmware/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Obtener la lista de firmwares
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFirmware: async (acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/firmware/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary actualiza un firmware
         * @param {string} id 
         * @param {RequestFirmwareUpdate} requestFirmwareUpdate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFirmware: async (id: string, requestFirmwareUpdate: RequestFirmwareUpdate, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFirmware', 'id', id)
            // verify required parameter 'requestFirmwareUpdate' is not null or undefined
            assertParamExists('updateFirmware', 'requestFirmwareUpdate', requestFirmwareUpdate)
            const localVarPath = `/firmware/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestFirmwareUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FirmwareApi - functional programming interface
 * @export
 */
export const FirmwareApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FirmwareApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary añade un instalador a un firmware
         * @param {string} id 
         * @param {File} installer 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFirmwareInstaller(id: string, installer: File, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFirmwareInstaller(id, installer, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FirmwareApi.addFirmwareInstaller']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Realiza la creación de un firmware
         * @param {Firmware} firmware 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFirmware(firmware: Firmware, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFirmwareCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFirmware(firmware, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FirmwareApi.createFirmware']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary elimina un firmware
         * @param {string} id 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFirmware(id: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFirmware(id, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FirmwareApi.deleteFirmware']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary elimina un instalador de un firmware
         * @param {string} id 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFirmwareInstaller(id: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFirmwareInstaller(id, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FirmwareApi.deleteFirmwareInstaller']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Obtener un firmwares
         * @param {string} id 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFirmware(id: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Firmware>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFirmware(id, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FirmwareApi.getFirmware']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Obtener la lista de firmwares
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFirmware(acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FirmwareList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listFirmware(acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FirmwareApi.listFirmware']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary actualiza un firmware
         * @param {string} id 
         * @param {RequestFirmwareUpdate} requestFirmwareUpdate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFirmware(id: string, requestFirmwareUpdate: RequestFirmwareUpdate, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFirmware(id, requestFirmwareUpdate, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FirmwareApi.updateFirmware']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * FirmwareApi - factory interface
 * @export
 */
export const FirmwareApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FirmwareApiFp(configuration)
    return {
        /**
         * 
         * @summary añade un instalador a un firmware
         * @param {FirmwareApiAddFirmwareInstallerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFirmwareInstaller(requestParameters: FirmwareApiAddFirmwareInstallerRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.addFirmwareInstaller(requestParameters.id, requestParameters.installer, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Realiza la creación de un firmware
         * @param {FirmwareApiCreateFirmwareRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFirmware(requestParameters: FirmwareApiCreateFirmwareRequest, options?: AxiosRequestConfig): AxiosPromise<ResponseFirmwareCreate> {
            return localVarFp.createFirmware(requestParameters.firmware, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary elimina un firmware
         * @param {FirmwareApiDeleteFirmwareRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFirmware(requestParameters: FirmwareApiDeleteFirmwareRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.deleteFirmware(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary elimina un instalador de un firmware
         * @param {FirmwareApiDeleteFirmwareInstallerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFirmwareInstaller(requestParameters: FirmwareApiDeleteFirmwareInstallerRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.deleteFirmwareInstaller(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Obtener un firmwares
         * @param {FirmwareApiGetFirmwareRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFirmware(requestParameters: FirmwareApiGetFirmwareRequest, options?: AxiosRequestConfig): AxiosPromise<Firmware> {
            return localVarFp.getFirmware(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Obtener la lista de firmwares
         * @param {FirmwareApiListFirmwareRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFirmware(requestParameters: FirmwareApiListFirmwareRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<FirmwareList>> {
            return localVarFp.listFirmware(requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary actualiza un firmware
         * @param {FirmwareApiUpdateFirmwareRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFirmware(requestParameters: FirmwareApiUpdateFirmwareRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.updateFirmware(requestParameters.id, requestParameters.requestFirmwareUpdate, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addFirmwareInstaller operation in FirmwareApi.
 * @export
 * @interface FirmwareApiAddFirmwareInstallerRequest
 */
export interface FirmwareApiAddFirmwareInstallerRequest {
    /**
     * 
     * @type {string}
     * @memberof FirmwareApiAddFirmwareInstaller
     */
    readonly id: string

    /**
     * 
     * @type {File}
     * @memberof FirmwareApiAddFirmwareInstaller
     */
    readonly installer: File

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof FirmwareApiAddFirmwareInstaller
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for createFirmware operation in FirmwareApi.
 * @export
 * @interface FirmwareApiCreateFirmwareRequest
 */
export interface FirmwareApiCreateFirmwareRequest {
    /**
     * 
     * @type {Firmware}
     * @memberof FirmwareApiCreateFirmware
     */
    readonly firmware: Firmware

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof FirmwareApiCreateFirmware
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteFirmware operation in FirmwareApi.
 * @export
 * @interface FirmwareApiDeleteFirmwareRequest
 */
export interface FirmwareApiDeleteFirmwareRequest {
    /**
     * 
     * @type {string}
     * @memberof FirmwareApiDeleteFirmware
     */
    readonly id: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof FirmwareApiDeleteFirmware
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteFirmwareInstaller operation in FirmwareApi.
 * @export
 * @interface FirmwareApiDeleteFirmwareInstallerRequest
 */
export interface FirmwareApiDeleteFirmwareInstallerRequest {
    /**
     * 
     * @type {string}
     * @memberof FirmwareApiDeleteFirmwareInstaller
     */
    readonly id: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof FirmwareApiDeleteFirmwareInstaller
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for getFirmware operation in FirmwareApi.
 * @export
 * @interface FirmwareApiGetFirmwareRequest
 */
export interface FirmwareApiGetFirmwareRequest {
    /**
     * 
     * @type {string}
     * @memberof FirmwareApiGetFirmware
     */
    readonly id: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof FirmwareApiGetFirmware
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for listFirmware operation in FirmwareApi.
 * @export
 * @interface FirmwareApiListFirmwareRequest
 */
export interface FirmwareApiListFirmwareRequest {
    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof FirmwareApiListFirmware
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateFirmware operation in FirmwareApi.
 * @export
 * @interface FirmwareApiUpdateFirmwareRequest
 */
export interface FirmwareApiUpdateFirmwareRequest {
    /**
     * 
     * @type {string}
     * @memberof FirmwareApiUpdateFirmware
     */
    readonly id: string

    /**
     * 
     * @type {RequestFirmwareUpdate}
     * @memberof FirmwareApiUpdateFirmware
     */
    readonly requestFirmwareUpdate: RequestFirmwareUpdate

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof FirmwareApiUpdateFirmware
     */
    readonly acceptLanguage?: string
}

/**
 * FirmwareApi - object-oriented interface
 * @export
 * @class FirmwareApi
 * @extends {BaseAPI}
 */
export class FirmwareApi extends BaseAPI {
    /**
     * 
     * @summary añade un instalador a un firmware
     * @param {FirmwareApiAddFirmwareInstallerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmwareApi
     */
    public addFirmwareInstaller(requestParameters: FirmwareApiAddFirmwareInstallerRequest, options?: AxiosRequestConfig) {
        return FirmwareApiFp(this.configuration).addFirmwareInstaller(requestParameters.id, requestParameters.installer, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Realiza la creación de un firmware
     * @param {FirmwareApiCreateFirmwareRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmwareApi
     */
    public createFirmware(requestParameters: FirmwareApiCreateFirmwareRequest, options?: AxiosRequestConfig) {
        return FirmwareApiFp(this.configuration).createFirmware(requestParameters.firmware, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary elimina un firmware
     * @param {FirmwareApiDeleteFirmwareRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmwareApi
     */
    public deleteFirmware(requestParameters: FirmwareApiDeleteFirmwareRequest, options?: AxiosRequestConfig) {
        return FirmwareApiFp(this.configuration).deleteFirmware(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary elimina un instalador de un firmware
     * @param {FirmwareApiDeleteFirmwareInstallerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmwareApi
     */
    public deleteFirmwareInstaller(requestParameters: FirmwareApiDeleteFirmwareInstallerRequest, options?: AxiosRequestConfig) {
        return FirmwareApiFp(this.configuration).deleteFirmwareInstaller(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Obtener un firmwares
     * @param {FirmwareApiGetFirmwareRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmwareApi
     */
    public getFirmware(requestParameters: FirmwareApiGetFirmwareRequest, options?: AxiosRequestConfig) {
        return FirmwareApiFp(this.configuration).getFirmware(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Obtener la lista de firmwares
     * @param {FirmwareApiListFirmwareRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmwareApi
     */
    public listFirmware(requestParameters: FirmwareApiListFirmwareRequest = {}, options?: AxiosRequestConfig) {
        return FirmwareApiFp(this.configuration).listFirmware(requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary actualiza un firmware
     * @param {FirmwareApiUpdateFirmwareRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmwareApi
     */
    public updateFirmware(requestParameters: FirmwareApiUpdateFirmwareRequest, options?: AxiosRequestConfig) {
        return FirmwareApiFp(this.configuration).updateFirmware(requestParameters.id, requestParameters.requestFirmwareUpdate, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}

