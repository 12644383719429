/* tslint:disable */
/* eslint-disable */
/**
 * TMS Agnostiko
 * Tiene la definición de todos los servicios de TMS a Agnostic (para uso en la web o externo)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { Message } from '../models';
// @ts-ignore
import { Organization } from '../models';
// @ts-ignore
import { OrganizationName } from '../models';
// @ts-ignore
import { RequestOrganizationCreate } from '../models';
// @ts-ignore
import { RequestOrganizationUpdate } from '../models';
// @ts-ignore
import { ResponseOrganizationCreate } from '../models';
/**
 * OrganizationApi - axios parameter creator
 * @export
 */
export const OrganizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Realiza la creación o actualización de una organización
         * @param {RequestOrganizationCreate} requestOrganizationCreate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationCreate: async (requestOrganizationCreate: RequestOrganizationCreate, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestOrganizationCreate' is not null or undefined
            assertParamExists('organizationCreate', 'requestOrganizationCreate', requestOrganizationCreate)
            const localVarPath = `/organization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestOrganizationCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary elimina una organización
         * @param {string} id 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationDelete: async (id: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationDelete', 'id', id)
            const localVarPath = `/organization/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Obtener una organización
         * @param {string} id 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationGet: async (id: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationGet', 'id', id)
            const localVarPath = `/organization/list/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Obtener la lista de organizaciones
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationList: async (acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/organization/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary actualiza el nombre de la organización
         * @param {string} id 
         * @param {RequestOrganizationUpdate} requestOrganizationUpdate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationUpdate: async (id: string, requestOrganizationUpdate: RequestOrganizationUpdate, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationUpdate', 'id', id)
            // verify required parameter 'requestOrganizationUpdate' is not null or undefined
            assertParamExists('organizationUpdate', 'requestOrganizationUpdate', requestOrganizationUpdate)
            const localVarPath = `/organization/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestOrganizationUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationApi - functional programming interface
 * @export
 */
export const OrganizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Realiza la creación o actualización de una organización
         * @param {RequestOrganizationCreate} requestOrganizationCreate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationCreate(requestOrganizationCreate: RequestOrganizationCreate, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOrganizationCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationCreate(requestOrganizationCreate, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrganizationApi.organizationCreate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary elimina una organización
         * @param {string} id 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationDelete(id: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationDelete(id, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrganizationApi.organizationDelete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Obtener una organización
         * @param {string} id 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationGet(id: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationGet(id, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrganizationApi.organizationGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Obtener la lista de organizaciones
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationList(acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationName>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationList(acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrganizationApi.organizationList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary actualiza el nombre de la organización
         * @param {string} id 
         * @param {RequestOrganizationUpdate} requestOrganizationUpdate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationUpdate(id: string, requestOrganizationUpdate: RequestOrganizationUpdate, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationUpdate(id, requestOrganizationUpdate, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrganizationApi.organizationUpdate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * OrganizationApi - factory interface
 * @export
 */
export const OrganizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationApiFp(configuration)
    return {
        /**
         * 
         * @summary Realiza la creación o actualización de una organización
         * @param {OrganizationApiOrganizationCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationCreate(requestParameters: OrganizationApiOrganizationCreateRequest, options?: AxiosRequestConfig): AxiosPromise<ResponseOrganizationCreate> {
            return localVarFp.organizationCreate(requestParameters.requestOrganizationCreate, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary elimina una organización
         * @param {OrganizationApiOrganizationDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationDelete(requestParameters: OrganizationApiOrganizationDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.organizationDelete(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Obtener una organización
         * @param {OrganizationApiOrganizationGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationGet(requestParameters: OrganizationApiOrganizationGetRequest, options?: AxiosRequestConfig): AxiosPromise<Organization> {
            return localVarFp.organizationGet(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Obtener la lista de organizaciones
         * @param {OrganizationApiOrganizationListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationList(requestParameters: OrganizationApiOrganizationListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<OrganizationName>> {
            return localVarFp.organizationList(requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary actualiza el nombre de la organización
         * @param {OrganizationApiOrganizationUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationUpdate(requestParameters: OrganizationApiOrganizationUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.organizationUpdate(requestParameters.id, requestParameters.requestOrganizationUpdate, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for organizationCreate operation in OrganizationApi.
 * @export
 * @interface OrganizationApiOrganizationCreateRequest
 */
export interface OrganizationApiOrganizationCreateRequest {
    /**
     * 
     * @type {RequestOrganizationCreate}
     * @memberof OrganizationApiOrganizationCreate
     */
    readonly requestOrganizationCreate: RequestOrganizationCreate

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof OrganizationApiOrganizationCreate
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for organizationDelete operation in OrganizationApi.
 * @export
 * @interface OrganizationApiOrganizationDeleteRequest
 */
export interface OrganizationApiOrganizationDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationApiOrganizationDelete
     */
    readonly id: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof OrganizationApiOrganizationDelete
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for organizationGet operation in OrganizationApi.
 * @export
 * @interface OrganizationApiOrganizationGetRequest
 */
export interface OrganizationApiOrganizationGetRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationApiOrganizationGet
     */
    readonly id: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof OrganizationApiOrganizationGet
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for organizationList operation in OrganizationApi.
 * @export
 * @interface OrganizationApiOrganizationListRequest
 */
export interface OrganizationApiOrganizationListRequest {
    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof OrganizationApiOrganizationList
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for organizationUpdate operation in OrganizationApi.
 * @export
 * @interface OrganizationApiOrganizationUpdateRequest
 */
export interface OrganizationApiOrganizationUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationApiOrganizationUpdate
     */
    readonly id: string

    /**
     * 
     * @type {RequestOrganizationUpdate}
     * @memberof OrganizationApiOrganizationUpdate
     */
    readonly requestOrganizationUpdate: RequestOrganizationUpdate

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof OrganizationApiOrganizationUpdate
     */
    readonly acceptLanguage?: string
}

/**
 * OrganizationApi - object-oriented interface
 * @export
 * @class OrganizationApi
 * @extends {BaseAPI}
 */
export class OrganizationApi extends BaseAPI {
    /**
     * 
     * @summary Realiza la creación o actualización de una organización
     * @param {OrganizationApiOrganizationCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationCreate(requestParameters: OrganizationApiOrganizationCreateRequest, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).organizationCreate(requestParameters.requestOrganizationCreate, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary elimina una organización
     * @param {OrganizationApiOrganizationDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationDelete(requestParameters: OrganizationApiOrganizationDeleteRequest, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).organizationDelete(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Obtener una organización
     * @param {OrganizationApiOrganizationGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationGet(requestParameters: OrganizationApiOrganizationGetRequest, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).organizationGet(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Obtener la lista de organizaciones
     * @param {OrganizationApiOrganizationListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationList(requestParameters: OrganizationApiOrganizationListRequest = {}, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).organizationList(requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary actualiza el nombre de la organización
     * @param {OrganizationApiOrganizationUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationUpdate(requestParameters: OrganizationApiOrganizationUpdateRequest, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).organizationUpdate(requestParameters.id, requestParameters.requestOrganizationUpdate, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}

