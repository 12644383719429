/* eslint-disable prettier/prettier */
import { html, LitElement } from 'lit';
import { customElement, query, state } from 'lit/decorators.js';
import '@vaadin/vaadin-text-field';
import '@vaadin/vaadin-select';
import '@material/mwc-tab';
import '@material/mwc-tab-bar';
import '@material/mwc-list/mwc-list.js';
import '@material/mwc-list/mwc-list-item.js';
import '@material/mwc-switch';
import '@material/mwc-textfield';
//import './CrearTerminal.css';
import Swal from 'sweetalert2';
import {
  BrandList,
  ModelApi,
  OrganizationApi,
  OrganizationName,
  RequestTerminalCreate,
  FirmwareApiCreateFirmwareRequest,
  RequestTerminalBatch,
  TerminalApi,
  FirmwareApi,
  Firmware
} from '../../../api';
import {
  succesNotification,
} from '../../services/notifications';
import {
  flatMarcas,
  flatOrganizations,
  modelosPorMaca,
  validar_campos,
} from '../../utils/functions';
import { EjemploCsv } from '../../components/modals/EjemploCsv';
import '../../components/modals/EjemploCsv';
import router from '../../router/router';
import { apisConfigurator } from '../../utils/axios';
import { generate_table, InsertarPropiedad } from '../../utils/functions';
import '../../components/CustomMultiSelect/CustomMultiSelect';
import { CustomSelect } from '../../components/CustomSelect/CustomSelect';
import '../../components/CustomSelect/CustomSelect';
import { errorNotification } from '../../services/notifications';
import { terminalAdded } from '../../redux/actions/terminals';
import store from '../../redux/store';

@customElement('create-firmware-page')
export class CreateFirmwarePage extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private modelApi: ModelApi;
  private terminalApi: TerminalApi;
  private firmwareApi: FirmwareApi;
  private organizationApi: OrganizationApi;

  @state()
  private loading = false;

  @state()
  private modelosMarcas: BrandList[] = [];

  @state()
  private organizaciones: OrganizationName[] = [];

  @state()
  private brandSelected?: string;

  @state()
  private areaError?: boolean;

  @state()
  private file?: File;
  // is the array to hold the final values from the CSV file
  @state()
  final_vals: RequestTerminalCreate[] = [];
  //private final_vals: FileReader[] = [];

  @query('#name')
  private name!: HTMLInputElement;

  @query('#description')
  private description!: HTMLInputElement;

  @query('#briefDescription')
  private briefDescription!: HTMLInputElement;

  @query('#developer')
  private developer!: HTMLInputElement;

  @query('#os')
  private os!: HTMLInputElement;

  @query('#requirements')
  private requirements!: HTMLInputElement;

  @query('#brand')
  private brandSelect!: CustomSelect;

  @query('#model')
  private modelSelect!: CustomSelect;

  /* terminalApi = new TerminalApi(); */

  private get botonCsv() {
    return document.querySelector('#seleccionar-archivo')! as HTMLInputElement;
  }

  get ejemploCsv() {
    return document.getElementById(
      'ejemploCsv'
    )! as EjemploCsv;
  }

  private constructor() {
    super();
    this.modelApi = new ModelApi(...apisConfigurator('Terminal'));
    this.firmwareApi = new FirmwareApi(...apisConfigurator('Firmware'));
    this.terminalApi = new TerminalApi(...apisConfigurator('Terminal'));
    this.organizationApi = new OrganizationApi(...apisConfigurator('Terminal'));
  }

  protected firstUpdated(): void {
    var obj = {
      $schema: 'http://json-schema.org/draft-04/schema#',
      properties: {},
      required: {},
    };
    //JsonPrueba(obj);
    InsertarPropiedad(obj);
    this.getModels();
    this.getOrganizaciones();
  }

  async getModels() {
    const resModel = await this.modelApi.modelList({});

    //console.log(resModel);

    this.modelosMarcas = resModel.data;
  }

  async getOrganizaciones() {
    const resOrg = await this.organizationApi.organizationList({});

    this.organizaciones = flatOrganizations(resOrg.data);
  }
  
  private get seleccionarArchivo() {
    return document.getElementById('seleccionar-archivo') as any;
  }

  private get displayArea() {
    return document.getElementById('displayArea') as any;
  }

  private get disabledButton() {
    return document.getElementById('crearTerminal') as any;
  }

  private get alertCampo() {
    return document.getElementById('alertCampo') as any;
  }

  cvsForm() {
    let final_vals: any[] = [];
    let data: RequestTerminalCreate[] = [];
    let csvReader = new FileReader(); // generate a filereader from the JS API
    const alertCampo = this.alertCampo;
    const area = this.displayArea;
    console.log(area);
    const disabledButton = this.disabledButton;
    const input = this.seleccionarArchivo.files[0]; // grab the first (only) file from the input

    csvReader.onload = function (evt) {
      const text = evt?.target?.result; // this is the data generated from the csvReader reading the information in the file
      if (typeof text === 'string' || text instanceof String) {
        const values = text.split(/[\n]+/); // group the information by the CSV breakpoint \n is a new line

        values.forEach((val, index) => {
          // further split by each section by the CSV
          if (index == 0) {
            if (!validar_campos(val.split(/;|,/))) { 
              //console.log(validar_campos(val.split(/;|,/)))
              area.style.border = '4px solid #b92326';
              alertCampo.style.visibility = 'visible';
              alertCampo.style.color = '#b92326';
              disabledButton.disabled = true;
            } else {
              //console.log(validar_campos(val.split(/;|,/)))
              area.style.border = '';
              alertCampo.style.visibility = 'hidden';
              disabledButton.disabled = false;
              //alertCampo.style.color = "#b92326";
            }
          } else if (index != values.length - 1) {
            const requestTerminalCreate: RequestTerminalCreate = {
              brand: val.split(/;|,/)[1],
              model: val.split(/;|,/)[2],
              serial: val.split(/;|,/)[0],
              organization: val.split(/;|,/)[3],
              owner: val.split(/;|,/)[4],
              description: val.split(/;|,/)[5],
              icon: 'icon',
            };
            data.push(requestTerminalCreate);
          }
          final_vals.push(val.split(/;|,/));
        });

        // create form
        generate_table(<[string[]]>final_vals).then(result => {
          // async function is used to ensure the formatting does not try to occur after the table is created

          area.innerHTML = result;

          const th_values = document.getElementsByTagName('th');
          const td_values = document.getElementsByTagName('td');

          const capitilize_table_column = (table_el: HTMLElement) => {
            table_el.innerHTML =
              table_el.innerHTML[0].toUpperCase() + table_el.innerHTML.slice(1);
          };

          for (const th_val of th_values) {
            capitilize_table_column(th_val);
          }
          for (const td_val of td_values) {
            capitilize_table_column(td_val);
          }
        });
      }
    };
    this.final_vals = data;

    // this runs the above action
    csvReader.readAsText(input);
  }

  eventoError(band: boolean) {}

  async handleSubmitBatch(e: Event) {
    e.preventDefault();

    const requestTerminalBatch: RequestTerminalBatch = {
      batch: this.final_vals,
    };
    //console.log(requestTerminalBatch)

    try {
      const res = await this.terminalApi.terminalBatch({
        requestTerminalBatch,
      });
      router.cambiarSeccion('terminal-list');
    } catch (err) {}
  }

  validateForm() {
    if (!this.name.checkValidity()) {
      errorNotification('El nombre del firmware es requerido.');
      return false;
    }

    if (!this.brandSelect.selected) {
      errorNotification('La marca del firmware es requerida.');
      return false;
    }

    if (!this.modelSelect.selected) {
      errorNotification('El modelo del firmware es requerido.');
      return false;
    }

    if (!this.description.checkValidity()) {
      errorNotification('La descripcion del firmware es requerida.');
      return false;
    }

    if (!this.developer.checkValidity()) {
      errorNotification('El desarrollador del firmware es requerido.');
      return false;
    }

    if (!this.os.checkValidity()) {
      errorNotification('El os del firmware es requerido.');
      return false;
    }

    if (!this.requirements.checkValidity()) {
      errorNotification('El requirements del firmware es requerido.');
      return false;
    }

    return true;
  }

  async handleSubmit(e: Event) {
    e.preventDefault();
    this.loading = true;

    if (!this.validateForm()) {
      this.loading = false;
      return;
    }

    const firmware: Firmware = { 
      model: this.modelSelect.selected ?? '',
      brand: this.brandSelect.selected ?? '',
      name: this.name.value,
      os: this.os.value,
      briefDescription: this.briefDescription.value ?? '',
      description: this.description.value ?? '',
      developer: this.developer.value ?? '',
      requirements: this.requirements.value ?? ''
    }

    try {
      const res = await this.firmwareApi.createFirmware({
        firmware,
      });
      succesNotification('Datos guardados con exito!'); 
      if (res.status == 200) {
        //router.cambiarSeccion('plantilla-page');
        await Swal.fire('Agregado!', 'El tipo de dato registrado', 'success');
        //window.location.reload()
        router.cambiarSeccion('firmware-list');
      } 
      //router.cambiarSeccion('terminal-list');
      //store.dispatch(terminalAdded(requestTerminalCreate));
    } catch (err) {
    } finally { 
      this.loading = false;
    }
  }

  render() {
    return html`
      <main class="w-full FondoColor min-h-full">
        <div class="w-full h-full px-5 py-4 flex flex-wrap items-start">
          <div
            class="w-full h-full relative mb-2  SectionList rounded-md pt-5 px-4 "
          >
          <h2 class="text-2xl text-blue-600 font-bold uppercase">
            Agregar Firmware
          </h2>
          <div class="flex flex-wrap items-start my-4 mx-4">
            <div class="mx-2 TexField">
              <mwc-textfield
                required
                validationMessage="El número serial debe tener al menos 8 caracteres"
                label="Nombre"
                id="name"
                minLength="1"
                maxLength="50"
                autoValidate
                outlined
                iconTrailing="close"
              >
              </mwc-textfield>
            </div>
            <div class="mx-2 TexField">
              <custom-select
                label="Marca"
                id="brand"
                .options=${flatMarcas(this.modelosMarcas).map(m => ({
                  label: m,
                  value: m,
                }))}
                @change=${(ev: CustomEvent) => {
                  this.brandSelected = ev.detail;
                }}
              ></custom-select>
            </div>
            <div class="mx-2 TexField">
              <custom-select
                label="Modelo"
                id="model"
                .options=${this.brandSelected
                  ? modelosPorMaca(this.modelosMarcas, this.brandSelected).map(
                      m => ({
                        label: m.model,
                        value: m.model,
                      })
                    )
                  : []}
              ></custom-select>
            </div>
            <div class="mx-2 TexField">
            <mwc-textfield
                iconTrailing="close"
                required
                validationMessage="El dueño debe tener al menos 8 caracteres"
                label="briefDescription"
                id="briefDescription"
                minLength="1"
                autoValidate
                outlined
              >
              </mwc-textfield>
            </div>
            <div class="mx-2 TexField">
            <mwc-textfield
                iconTrailing="close"
                required
                validationMessage="El dueño debe tener al menos 8 caracteres"
                label="Descripcion"
                id="description"
                minLength="1"
                autoValidate
                outlined
              >
              </mwc-textfield>
            </div>
            <div class="mx-2 TexField">
              <mwc-textfield
                iconTrailing="close"
                required
                validationMessage="El dueño debe tener al menos 8 caracteres"
                label="Developer"
                id="developer"
                minLength="1"
                autoValidate
                outlined
              >
              </mwc-textfield>
            </div>
            <div class="mx-2 TexField">
              <mwc-textfield
                iconTrailing="close"
                required
                validationMessage="El dueño debe tener al menos 8 caracteres"
                label="os"
                id="os"
                minLength="1"
                autoValidate
                outlined
              >
              </mwc-textfield>
            </div>
            <div class="mx-2 TexField">
              <mwc-textfield
                iconTrailing="close"
                required
                validationMessage="El dueño debe tener al menos 8 caracteres"
                label="Requerimientos"
                id="requirements"
                minLength="1"
                autoValidate
                outlined
              >
              </mwc-textfield>
            </div>
          </div>
          <div class="flex justify-start">
            <button
              @click="${() => router.cambiarSeccion('firmware-list')}"
              id="cancelarCrearAPP"
              class="w-48 rounded-md border-none bg-red-600 text-white py-3 text-xl mt-4 mx-auto hover:bg-red-700 hover:shadow-lg mb-4">
              CANCELAR
            </button> 
            <button
              type="submit"
              id="guardarCrearAPP"
              class="w-48 rounded-md border-none bg-blue-600 text-white py-3 text-xl mt-4 mx-auto hover:bg-blue-700 hover:shadow-lg mb-4"
              .disabled=${this.loading}
              @click=${(e: Event) => {
                this.handleSubmit(e);
              }}
            > 
              Guardar Firmware
            </button>

            </div>
          <!-- <div class="my-4 ml-8">
            <p>
              sube un archivo CSV con la cantidad de terminales que desees con los siguientes campos: <a href="javascript:void(0);" @click="${(e: Event) => this.ejemploCsv.open()}">Ejemplo</a>
            </p>
            <span id="alertCampo">Formato de campos incorrectos</span>
          </div> -->
          <div class="w-full flex flex-row my-4 ml-8">
            <!-- <div class="CajonXlm w-3/5" id="displayArea"></div> -->
            <div class="w-2/5	ml-10 CrearTerminal normal-case 	flex flex-col">
              <div class="w-40	 min-w-full">
                <mat-form-field>
                  <!-- <input type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" placeholder="label" value="value"> -->
                </mat-form-field>
                <!-- <form id="csvForm">
                    <div class="mb-3">
                        <label for="csvFile" class="form-label">CSV File</label>
                        <input class="form-control" id="csvFile" type="file" accept=".csv"/>
                    </div>
                    <div class="mb-3">
                        <input class="btn btn-primary" type="submit" value="Submit" />
                    </div>
                  </form> -->
                <!-- <mwc-button  
                  class="rounded-3xl ml-4 mb-2">
                    <p class="normal-case text-xs">Explorar</p>
                  </mwc-button> -->
                <!-- <input
                  id="seleccionar-archivo"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  placeholder="label"
                  type="file"
                  @change=${(ev: InputEvent) => {
                    this.cvsForm();
                    //console.log(ev);
                    //console.log(this.botonCsv.files);
                  }}
                  hidden
                />
                <mwc-button
                  class="rounded-3xl ml-4 mb-2"
                  @click=${() => {
                    this.botonCsv.click();
                  }}
                  }}
                >
                  <p class="normal-case text-xs">Explorar</p>
                </mwc-button> -->
              </div>
              <!-- <div class="w-40	 min-w-full">
                <mwc-button
                  type="submit"
                  class="rounded-3xl ml-4 mt-4  bg-gray-500 text-gray-700	normal-case	"
                  @click=${() => {
                    let area = this.displayArea;
                    const alertCampo = this.alertCampo;
                    area.innerHTML = ``;
                    area.style.border = '';
                    alertCampo.style.visibility = 'hidden';
                  }}
                >
                  <p class="normal-case	 text-xs">Eliminar</p>
                </mwc-button>
              </div> -->
            </div>
          </div>

          <!-- <div class="w-full flex flex-row my-4 ml-8">
            <div class="w-2/4	"></div>
            <div class="w-2/4 CrearTerminal	flex flex-row">
              <button
                class="rounded-3xl border  border-indigo-500 text-blue-500  CancelarG  hover:shadow-md hover:bg-slate-300 text-xs	"
                @click="${(e: Event) => router.cambiarSeccion('terminal-list')}"
              >
                Cancelar
              </button>

              <mwc-button
                id="crearTerminal"
                class="rounded-3xl ml-4 mb-2  normal-case		"
                @click="${(e: Event) => this.handleSubmit(e)}"
                .disabled=${this.loading}
              >
                <p class="normal-case	text-xs ">Crear terminal</p>
              </mwc-button>
            </div>
          </div> -->
          </div>
        </div>
      </main>
      <ejemplo-csv id="ejemploCsv"></ejemplo-csv>
    `;
  }
}
