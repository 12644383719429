/* eslint-disable prettier/prettier */
import { html, LitElement, render } from 'lit';
import { guard } from 'lit/directives/guard.js';
import { repeat } from 'lit/directives/repeat.js';
import { customElement, query, state } from 'lit/decorators.js';
import { connect } from 'pwa-helpers';
import Swal from 'sweetalert2';
import '@vaadin/vaadin-list-box';
import '@vaadin/vaadin-text-field';
import {
    errorNotification,
    succesNotification,
  } from '../../services/notifications';
import {
    Grid,
    GridActiveItemChangedEvent,
    GridItemModel,
  } from '@vaadin/vaadin-grid';
  import writeXlsxFile from 'write-excel-file'
import { OrganizationName, RoleList,RequestTemplates, TemplateApiGetTemplatesRequest, User, FolioInfo, TemplateApi, FolioApi, RequestParamsQuery, FolioApiGetFoliosByQueryRequest, AppApi  } from '../../../api';
import '@vaadin/vaadin-text-field';
import { NameTemplate } from '../../components/modals/NameTemplate';
import '../../components/modals/NameTemplate';

import { EnumModal } from '../../components/modals/EnumModal';
import '../../components/modals/EnumModal';

import { DemoModal } from '../../components/modals/DemoModal';
import '../../components/modals/DemoModal';

import router from '../../router/router';
import { apisConfigurator } from '../../utils/axios';
import { InsertarPropiedad, ordenarAsc} from '../../utils/functions';
import store from '../../redux/store';
import { State } from '../../redux/types';
import { AxiosResponse } from 'axios';
import { agregarPlantilla, Enumm } from '../../redux/actions';
import { map } from 'leaflet';
@customElement('exportacion-masiva-banorte-page')
export class ExportacionMasivaBanortePage extends connect(store)(LitElement) {
  private templateApi: TemplateApi;
  private folioApi: FolioApi;
  private appApi: AppApi;
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private constructor() {
    super();
    this.templateApi = new TemplateApi(...apisConfigurator(''));
    this.appApi = new AppApi(...apisConfigurator('App'));
    this.folioApi = new FolioApi(...apisConfigurator(''));
  }

  @query('#name')
  nameInput!: HTMLInputElement;

  @query('#lastName')
  lastNameInput!: HTMLInputElement;

  @state()
  private obj?: any;

  @state()
  loading = true;

  @state()
  editUser?: User;

  @state()
  private controlPlantilla: any[] = [
    {
      id: 1,
      name: '',
      type: '',
      lengthMinimo: 0,
      lengthMaximo: 0,
      description: '',
      pattern: '',
      required: false,
    },
  ];

  @state()
  token?: number;

  @state()
  private type: Object[] = [
    {
      id: 'string',
      type: 'String',
    },
    {
      id: 'number',
      type: 'Number',
    },
    {
      id: 'integer',
      type: 'Integer',
    },
    {
      id: 'boolean',
      type: 'Boolean',
    },
    {
      id: 'enum',
      type: 'Enum',
    },
  ];

  @state()
  roles: RoleList[] = [];

  @state()
  plantillas: Object[] = [];

  @state()
  loader = true;

  @state()
  nombrePlantilla?: string = 'Nombre de la plantilla';

  @state()
  descripcionPlantilla?: string = '';

  @state()
  rolesUsuario: string[] = [];

  @state()
  organizationUsuario: string = '';

  @state()
  aux: string = '';

  @state()
  productValuesArrays:any = []

  @state()
  productValuesArrays2:any = []

  @state()
  templateConfig:any = []

  @state()
  organizaciones: OrganizationName[] = [];

  @state()
  rol?: string = '';

  @state()
  private check: boolean = false;

  @state()
  private band: boolean = false;

  @state()
  private parameter: any[] = [];

  @state()
  private templatesId: Array<string> = [""];

  @state()
  checks: boolean = false;

  @state()
  private appId:string = "";

  @state()
  jsonArray: boolean = false;

  @state()
  organization!: Array<string>;

  @state()
  dataSchema: any;

  @state()
  folios: any;

  @state()
  bandFolios: boolean = false;

    @state()
  private detailsOpenedItem: FolioInfo[] = [];

  appSelected(index:number) {
    return document.getElementById(`type${index}`) as any;
  }

   diValor(index:number) {
    return document.getElementById(`myDiv${index}`) as any;
  }

  parameterSelected(index:number) {
    return document.getElementById(`parametros${index}`) as any;
  }

  private valueSelect(item: number) {
    return document.getElementById(`type${item}`) as any;
  }

  private get loaderContainer() {
    return document.getElementById('loader-container') as any;
  }

  get nameTemplate() {
    return document.getElementById('cambiarNombrePlantilla') as NameTemplate;
  }

  get enumModal() {
    return document.getElementById('enumModal') as EnumModal;
  }

  get demoModal() {
    return document.getElementById('demoModal') as DemoModal;
  }

  enumSpan(index: number) {
    return document.getElementById(`enum${index}`) as any;
  }

  idGet(atributo: string) {
    return document.getElementById(atributo) as HTMLInputElement;
  }

  async firstUpdated() {
    this.obj = {
      properties: [],
      required: [],
    };
    const initial = [
      {
        id: 1,
        name: '',
        type: '',
        lengthMinimo: 0,
        lengthMaximo: 0,
        description: '',
        pattern: '',
        required: false,
      },
    ];
    store.dispatch(agregarPlantilla(initial));
    let appId:string = localStorage.getItem('appId') ?? "";
    this.appId = appId
    await this.getApps();
    await this.cargarData();
    await this.getTemplates();
  }

  async getApps(){
    try {
      const res:any = await this.appApi.getApp({
        id:this.appId
      })
      console.log(res)

        this.templatesId = res.data.templates


    } catch (error) {
      console.log(error)
    } finally {
      this.getTemplatesConfig()
    }

  }

  async getTemplatesConfig(){
    const requestTemplates:RequestTemplates = {
      ids:this.templatesId
    }
    const data:TemplateApiGetTemplatesRequest = {
      requestTemplates
    }
    console.log(data)
    try {
      const res:any = await this.templateApi.getTemplates(data)
      console.log(res)
      this.templateConfig = res.data;
      if(res.data.status == 200){
        //this.templatesId = res.data.templates
      }

    } catch (error) {
      console.log(error)
    }

  }

  stateChanged(state: any) {
    console.log(state.plantilla)
    if(typeof state.plantilla === "undefined") {
        console.log("entro")
        this.controlPlantilla = [{
            id: 1,
            name: '',
            type: '',
            lengthMinimo: 0,
            lengthMaximo: 0,
            description: '',
            pattern: '',
            required: false,
          }];
    }else{
        this.controlPlantilla = state.plantilla 
    }
    console.log(this.controlPlantilla)
    ordenarAsc(this.controlPlantilla);
    this.nombrePlantilla = state.nombrePlantilla;
    this.descripcionPlantilla = state.descripcionPlantilla;
    this.token = state.plantilla.length;
    this.templateApi.createTemplate;
    if (state.enumm) {
      this.changeInputEnum(state.enumm);
    }
  }

  async cargarData() {
    this.verificarUserEdit();
    this.loading = false;
  }

  async verificarUserEdit() {
    const { usuarioEdit, plantilla, nombrePlantilla } =
      store.getState() as State;
    //this.checks= new Array(plantilla?.length);
    this.token = plantilla?.length;
    this.nombrePlantilla = 'Nombre de la plantilla';
    this.controlPlantilla = plantilla ? plantilla : [];
    this.editUser = usuarioEdit;
  }

  agregarCampoNuevo() {
    let plantilla = this.controlPlantilla;
    console.log(plantilla.length);
    let id = plantilla?.length ? plantilla?.length + 1 : 0 + 1;
    const campoNuevo = {
      id,
      name: '',
      type: '',
      lengthMinimo: 0,
      lengthMaximo: 0,
      description: '',
      pattern: '',
      required: false,
    };
    plantilla?.push(campoNuevo);
    //console.log(ordenarAsc(plantilla));
    store.dispatch(agregarPlantilla(plantilla ? plantilla : [{}]));
    this.controlPlantilla = plantilla ? plantilla : [{}];
  }
  async createSchema() {
    this.obj.properties = this.controlPlantilla;
    const dataSchema = InsertarPropiedad(this.obj);
    if(this.jsonArray){
      dataSchema.type="array";
    }
    console.log(dataSchema);
    let requestTemplateCreate = {
      name: `${this.nombrePlantilla}`,
      description: `${this.descripcionPlantilla}`,
      schema: dataSchema,
    };
    console.log(requestTemplateCreate);
    try {
      const res = await this.templateApi.createTemplate({
        requestTemplateCreate,
      });
      if (res.status == 200) {
        //router.cambiarSeccion('plantilla-page');
        await Swal.fire('Agregado!', 'El tipo de dato registrado', 'success');
        //window.location.reload()
        router.cambiarSeccion('plantilla-page');
      }
    } catch (err) {}

    //console.log(this.descripcionPlantilla);
    //console.log(this.nombrePlantilla);
  }

  async eliminarCampo(id: number) {
    let plantilla = this.controlPlantilla;
    const map1 = plantilla?.filter((x: any, index: number) => x.id != id);
    console.log(map1);
    console.log(map1);
    store.dispatch(agregarPlantilla(map1 ? map1 : [{}]));
    this.controlPlantilla = map1 ? map1 : [{}];
  }

  async getConfiguration(idTemplate:string,index:any) {
    const res:any = await this.templateApi.getTemplate({id:idTemplate})
    console.log(res)
    const propertiesObject = res.data.schema.properties;

    store.dispatch(agregarPlantilla(this.controlPlantilla ? this.controlPlantilla : [{}]));
    const propertiesArray = Object.keys(propertiesObject).map((propertyName) => {
        propertiesObject[propertyName].name = propertyName;
        return {
          nombrePropiedad: propertyName,
          valorPropiedad: propertiesObject[propertyName]
        };
    });
    console.log(propertiesArray)
      this.parameter = propertiesArray;
      this.band = true;
      const selectElement:any = this.parameterSelected(index) // Obtener el elemento select
      const myDiv:any = document.getElementById(`myDiv${index}`);
      myDiv.innerHTML = '';
      selectElement.innerHTML = '';
      const optionDisabled = document.createElement('option');
      optionDisabled.disabled = true;
      optionDisabled.selected = true;
      optionDisabled.text = 'Parametros';
      selectElement.appendChild(optionDisabled);
      // Recorrer el array propertiesArray
      propertiesArray.forEach((propertyObject) => {
        const optionElement = document.createElement('option'); // Crear elemento option
        optionElement.value = JSON.stringify(propertyObject.valorPropiedad); // Establecer valor de la opción
        optionElement.text = propertyObject.nombrePropiedad; // Establecer texto de la opción
        selectElement.appendChild(optionElement); // Agregar la opción al select
      });

  }

  async getTemplates() {
    this.loader = true;
    this.plantillas = []
    var resTer: AxiosResponse;
    try {
      resTer = await this.templateApi.listTemplate({});
      console.log(resTer);
      const templates = resTer.data;
      await templates.map((item:any,index:number)=>{
        this.templatesId
        const estaEnPlantillas = this.templatesId.includes(item.id);
        if(estaEnPlantillas){
          this.plantillas.push(item)
        }
      })
      console.log(this.plantillas)
    } catch (error) {
    } finally {
      if (!this.plantillas)
        this.plantillas = [
          {
            name: 'plantilla-1',
            id: '12',
            description: 'prueba1',
          },
        ];
      this.loader = false;
    }
  }

  changeInputEnum(data: any) {
    let id = data.id;
    let name = this.idGet(`name${data.id}`);
    let type = this.idGet(`type${data.id}`);
    let lengthMinimo = this.idGet(`lengthMinimo${data.id}`);
    let lengthMaximo = this.idGet(`lengthMaximo${data.id}`);
    let description = this.idGet(`description${data.id}`);
    let pattern = this.idGet(`pattern${data.id}`);

    let arrOfNum = data.data;

    if (
      type.value == 'number' ||
      type.value == 'integer' ||
      type.value == 'boolean'
    ) {
      type.value = 'string';
    }

    let dataObjeto = {
      id,
      name: name.value,
      type: 'string',
      //length: length.value,
      description: description.value,
      //pattern: pattern.value,
      required: this.checks,
      enum: arrOfNum,
    };
    const map2 = this.controlPlantilla.filter(
      (x: any, index: number) => x.id !== data.id
    );
    map2.push(dataObjeto);
    store.dispatch(Enumm(0));
    store.dispatch(agregarPlantilla(map2 ? map2 : [{}]));
    this.controlPlantilla = map2;
  }

  changeInput(indice: number) {
    let id = indice;
    let name = this.idGet(`name${indice}`);
    let type = this.idGet(`type${indice}`);
    let lengthMinimo = this.idGet(`lengthMinimo${indice}`);
    let lengthMaximo = this.idGet(`lengthMaximo${indice}`);
    /* validar que valor minimo sea menor y el valor maximo el mayor */
    /*id(lengthMinimo.value>=lengthMaximo){

    }else if{

    }*/
    let description = this.idGet(`description${indice}`);
    let pattern = this.idGet(`pattern${indice}`);

    let dataObjeto = {};
    const found = this.controlPlantilla.find(element => element.id == id);
    var index = this.controlPlantilla
      .map(producto => producto.id)
      .indexOf(found.id);

    if (found.enum) {
      dataObjeto = {
        id,
        name: name.value,
        type: 'string',
        description: description.value,
        enum: found.enum,
      };
      this.controlPlantilla[index] = dataObjeto;
    } else {
      dataObjeto = {
        id,
        name: name.value,
        type: type.value,
        lengthMinimo: lengthMinimo.value,
        lengthMaximo: lengthMaximo.value,
        description: description.value,
        pattern: pattern.value,
        required: this.checks,
      };
      this.controlPlantilla[index] = dataObjeto;
    }
    console.log(this.controlPlantilla);
  }

  extraerName(indice: any) {
    let subs: any = [];
    if (this.editUser?.name) {
      subs = this.editUser?.name.split(' ');
    } else {
      return '';
    }
    return subs[indice] == undefined ? '' : subs[indice];
  }

  async cabeceras(){
    //this.plantillas
    //folios
    /*this.productValuesArrays = [ ["C","folioId","afiliationId","cantidad","marca","serial" ]];
    this.templateConfig.map((item:any,index:number)=>{
      const keys = Object.keys(item.details.schema.properties);
      let p = ["CP",item.details.name,].concat(keys);
      this.productValuesArrays.push(p)
    })*/
    let data = ["#APL","Nombre Holding","Contacto","Teléfono","Email","C.P.","RegFedCau","Nombre de la tienda","Contacto","Teléfono","C.P.","RegFedCau","folio","#MN[[bd_noafiliacion]]","#MA[[bd_noafiliacion]]","#MA2[[bd_noafiliacion]]","#CP[[bd_noafiliacion]]","#MID[[bd_noafiliacion]]","#TID[[bd_noafiliacion]]","#TIDAMEX[[bd_noafiliacion]]","#MIDAMEX[[bd_noafiliacion]]","#CAJA[[bd_noafiliacion]]","#MONEDA[[bd_noafiliacion]]","*RT[[bd_noafiliacion]]","#VFZ[[bd_noafiliacion]]","#FDI[[bd_noafiliacion]]","#CHKAMT[[bd_noafiliacion]]","#MAXTUR[[bd_noafiliacion]]","*PD[[bd_noafiliacion]]","*BNTPMIXTO[[bd_noafiliacion]]","#PMOVIL","#PLANETP","#PPMID","#PPTID","FDFLAG","CTFLAG","#LABELFD","*BNTQPS","*BNTQPSMTO","*BNTLEYENDA","*BNTEREF1","*BNTEREF2","*BNTEREF3","*BNTEMOD1","*BNTEMOD2","*BNTEMOD3","*BNTECMB1","*BNTECMB2","#BW","#BL","*BNTEVEND","#P1","#P2","#COUTOFF","#ASKCVV","#PWORKS2","#CASHBACK","#MASKCARD","#SOLROLLOS"];
    await data.map(async(item:any,index:number)=>{
      this.productValuesArrays.push({
        value:item
      })
    })
    console.log(this.productValuesArrays)

  }

  async cuerpo(){
    let data = ["#APL","Nombre Holding","Contacto","Teléfono","Email","C.P.","RegFedCau","Nombre de la tienda","Contacto","Teléfono","C.P.","RegFedCau","folio","#MN[[bd_noafiliacion]]","#MA[[bd_noafiliacion]]","#MA2[[bd_noafiliacion]]","#CP[[bd_noafiliacion]]","#MID[[bd_noafiliacion]]","#TID[[bd_noafiliacion]]","#TIDAMEX[[bd_noafiliacion]]","#MIDAMEX[[bd_noafiliacion]]","#CAJA[[bd_noafiliacion]]","#MONEDA[[bd_noafiliacion]]","*RT[[bd_noafiliacion]]","#VFZ[[bd_noafiliacion]]","#FDI[[bd_noafiliacion]]","#CHKAMT[[bd_noafiliacion]]","#MAXTUR[[bd_noafiliacion]]","*PD[[bd_noafiliacion]]","*BNTPMIXTO[[bd_noafiliacion]]","#PMOVIL","#PLANETP","#PPMID","#PPTID","FDFLAG","CTFLAG","#LABELFD","*BNTQPS","*BNTQPSMTO","*BNTLEYENDA","*BNTEREF1","*BNTEREF2","*BNTEREF3","*BNTEMOD1","*BNTEMOD2","*BNTEMOD3","*BNTECMB1","*BNTECMB2","#BW","#BL","*BNTEVEND","#P1","#P2","#COUTOFF","#ASKCVV","#PWORKS2","#CASHBACK","#MASKCARD","#SOLROLLOS"];
    await data.map(async(item:any,index:number)=>{
      this.productValuesArrays2.push({
        type: String,
        value: ''
      })
    })

    this.folios.map((folio:any,index:number)=>{
      console.log(folio)
      this.productValuesArrays2[18].value = folio.affiliationId;
      this.productValuesArrays2[14].value = folio.description;

    })

  }

  async exportarCSV(){
    const productos = [ ["C","afiliationId", "description", "cantidad" ], [ "CP", "Banorte-autorizador", "Amex", "port", "tls","url" ], [ "CP", "Banorte-comercio", "business ","name", "comboRef","" ], [ "CP","Banorte-terminal-F", "precio", 49.99 ] ]
    let cabeceras = ["#APL","Nombre Holding","Contacto","Teléfono","Email","C.P.","RegFedCau","Nombre de la tienda","Contacto","Teléfono","C.P.","RegFedCau","folio","#MN[[bd_noafiliacion]]","#MA[[bd_noafiliacion]]","#MA2[[bd_noafiliacion]]","#CP[[bd_noafiliacion]]","#MID[[bd_noafiliacion]]","#TID[[bd_noafiliacion]]","#TIDAMEX[[bd_noafiliacion]]","#MIDAMEX[[bd_noafiliacion]]","#CAJA[[bd_noafiliacion]]","#MONEDA[[bd_noafiliacion]]","*RT[[bd_noafiliacion]]","#VFZ[[bd_noafiliacion]]","#FDI[[bd_noafiliacion]]","#CHKAMT[[bd_noafiliacion]]","#MAXTUR[[bd_noafiliacion]]","*PD[[bd_noafiliacion]]","*BNTPMIXTO[[bd_noafiliacion]]","#PMOVIL","#PLANETP","#PPMID","#PPTID","FDFLAG","CTFLAG","#LABELFD","*BNTQPS","*BNTQPSMTO","*BNTLEYENDA","*BNTEREF1","*BNTEREF2","*BNTEREF3","*BNTEMOD1","*BNTEMOD2","*BNTEMOD3","*BNTECMB1","*BNTECMB2","#BW","#BL","*BNTEVEND","#P1","#P2","#COUTOFF","#ASKCVV","#PWORKS2","#CASHBACK","#MASKCARD","#SOLROLLOS"];
    await cabeceras.map(async(item:any,index:number)=>{
      this.productValuesArrays.push({
        value:item
      })
    })
    // FIN CABECERAS

    const data1:any = [
      this.productValuesArrays,
    ];

    // CUERPO
    let data = ["#APL","Nombre Holding","Contacto","Teléfono","Email","C.P.","RegFedCau","Nombre de la tienda","Contacto","Teléfono","C.P.","RegFedCau","folio","#MN[[bd_noafiliacion]]","#MA[[bd_noafiliacion]]","#MA2[[bd_noafiliacion]]","#CP[[bd_noafiliacion]]","#MID[[bd_noafiliacion]]","#TID[[bd_noafiliacion]]","#TIDAMEX[[bd_noafiliacion]]","#MIDAMEX[[bd_noafiliacion]]","#CAJA[[bd_noafiliacion]]","#MONEDA[[bd_noafiliacion]]","*RT[[bd_noafiliacion]]","#VFZ[[bd_noafiliacion]]","#FDI[[bd_noafiliacion]]","#CHKAMT[[bd_noafiliacion]]","#MAXTUR[[bd_noafiliacion]]","*PD[[bd_noafiliacion]]","*BNTPMIXTO[[bd_noafiliacion]]","#PMOVIL","#PLANETP","#PPMID","#PPTID","FDFLAG","CTFLAG","#LABELFD","*BNTQPS","*BNTQPSMTO","*BNTLEYENDA","*BNTEREF1","*BNTEREF2","*BNTEREF3","*BNTEMOD1","*BNTEMOD2","*BNTEMOD3","*BNTECMB1","*BNTECMB2","#BW","#BL","*BNTEVEND","#P1","#P2","#COUTOFF","#ASKCVV","#PWORKS2","#CASHBACK","#MASKCARD","#SOLROLLOS"];
    await data.map(async(item:any,index:number)=>{
      this.productValuesArrays2.push({
        type: String,
        value: ''
      })
    })
    console.log(this.productValuesArrays2)
    this.folios.map(async(folio:any,index:number)=>{
      let itemFolio:any = [];
      console.log(folio)
      // FOLIO
      itemFolio[19-2] = {value:folio.affiliationId};
      itemFolio[15-2] = {value:folio.description};
      data1.push(itemFolio)
      // BANORTE COMERCIO
      itemFolio[2-2] = {value:folio.templates[0].parametersArray[0].business};
      itemFolio[16-2] = {value:folio.templates[0].parametersArray[0].address1};
      itemFolio[17-2] = {value:folio.templates[0].parametersArray[0].address2};
      itemFolio[20-2] = {value:folio.templates[0].parametersArray[0].terminalId};
      itemFolio[21-2] = {value:folio.templates[0].parametersArray[0].amexId};
      itemFolio[23-2] = {value:folio.templates[0].parametersArray[0].cashRegister};
      itemFolio[24-2] = {value:folio.templates[0].parametersArray[0].currencyCode};
      itemFolio[25-2] = {value:folio.templates[0].parametersArray[0].refund};
      itemFolio[29-2] = {value:folio.templates[0].parametersArray[0].numOfShifts};

      itemFolio[42-2] = {value:folio.templates[0].parametersArray[0].ref1};
      itemFolio[43-2] = {value:folio.templates[0].parametersArray[0].ref2};
      itemFolio[44-2] = {value:folio.templates[0].parametersArray[0].ref3};

      itemFolio[48-2] = {value:folio.templates[0].parametersArray[0].comboRef};
      itemFolio[51-2] = {value:folio.templates[0].parametersArray[0].batchLimit};
      itemFolio[52-2] = {value:folio.templates[0].parametersArray[0].waiter};
      itemFolio[58-2] = {value:folio.templates[0].parametersArray[0].cashback};


    });
    // FIN CUERPO
    console.log(data1)
    await writeXlsxFile(data1, {
      fileName: 'file.xlsx'
    })
  }

  render() {
    return html`
      <main class="w-full h-full flex overflow-auto">
      ${!this.loader
        ? html`
        <div class="w-full h-full px-5 py-4 flex flex-wrap items-start">
          <form
            class="w-full h-full relative mb-2 SectionList rounded-md pt-5px-4 "
          >
          <div class="flex justify-between">
          <h1 class="text-2xl font-semibold text-blue-600">
          Exportacion Masiva        
      </h1>
      ${
        !this.bandFolios ? html `
      
        `
        :
        html`
        <mwc-button
        outlined
        class="rounded"
        @click=${() => {
          this.bandFolios = false;
        }}
        ><mwc-icon>arrow_back</mwc-icon>Volver</mwc-button
      >
        `
      }

      ${
        this.bandFolios ? html`
        <mwc-button
        outlined
        class="rounded"
        @click=${() => {
          this.exportarCSV();
        }}
        ><mwc-icon>downloading</mwc-icon>Exportar</mwc-button>
        `
        :
        html`
        <mwc-button
        outlined
        class="rounded"
        @click=${async () => {
            const parameters:any = {};
            this.controlPlantilla.map((item:any,index:number) =>{
                let valor:any = document.getElementById(`valor${item.id}`);
                let atributo:any = document.getElementById(`parametros${item.id}`);
                console.log(JSON.parse(atributo.value))
                if(valor){
                    const tipo = JSON.parse(atributo.value);
                    atributo = JSON.parse(atributo.value).name;
                    if(tipo.type === 'number' || tipo.type === 'integer'){
                        valor = parseInt(valor.value)
                    }else if(tipo.type === 'boolean'){
                        valor = valor.checked;
                    }else{
                        valor = valor.value;
                    }
                    const additionalProp = item.additionalProp;
    
                    // Check if the additionalProp exists as a property in parameters
                    if (!parameters[atributo]) {
                      // If it doesn't exist, create a new property with the value "string"
                      parameters[atributo] = valor;
                    }
                }
            })
            const requestParamsQuery:RequestParamsQuery = {
                parameters
            }
            const folioApiGetFoliosByQueryRequest:FolioApiGetFoliosByQueryRequest = {
                appId:this.appId,
                requestParamsQuery
            } 
            console.log(folioApiGetFoliosByQueryRequest)
            const res = await this.folioApi.getFoliosByQuery(folioApiGetFoliosByQueryRequest)
            console.log(res)
            if(res.data.length) {
                this.folios = res.data;
                this.bandFolios = true;
            }else{
                errorNotification("No se encuentran folios asociados")
            }
        }}
        ><mwc-icon>pageview</mwc-icon>Filtrar</mwc-button>
        <mwc-button
        outlined
        class="rounded"
        @click=${async () => {
          this.controlPlantilla = [{
            id: 1,
            name: '',
            type: '',
            lengthMinimo: 0,
            lengthMaximo: 0,
            description: '',
            pattern: '',
            required: false,
          }];
          this.getTemplates()
        }}
        ><mwc-icon>delete</mwc-icon>Limpiar</mwc-button>
        <div></div>
        `
      }

      </div>
            ${
                this.bandFolios ? html`
                <vaadin-grid
                class="w-screen h-full"
                .items="${this.folios}"
                .detailsOpenedItems="${this.detailsOpenedItem}"
                @active-item-changed="${( 
                  e: GridActiveItemChangedEvent<FolioInfo>
                ) => { 
                }}"
                .rowDetailsRenderer="${guard(
                  [],
                  () =>
                    (
                      root: HTMLElement,
                      _: Grid,
                      model: GridItemModel<FolioInfo>
                    ) => {
                      console.log(model.item)
                      render(
                        html`<vaadin-form-layout
                          .responsiveSteps=" ${[
                            { minWidth: '0', columns: 3 },
                          ]}"
                        >
                          <div
                          style="background-color:#F0EFFF;"
                            class="w-full h-14 flex items-center mt-5 justify-center flex-row "
                          >
                             <div
                              class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                              @click=${async () => {

                              }}

                            >
                              <div>
                                <mwc-icon-button icon="save">
                                </mwc-icon-button>
                              </div> 
                              <div>
                                <p class="text-center">Configuracion</p>
                              </div>
                            </div> 
                            <div
                            class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                            @click=${() => {
                            }}
                          >
                            <div>
                              <mwc-icon-button icon="cleaning_services">
                              </mwc-icon-button>
                            </div>
                            <div>
                              <p class="text-center">Limpiar</p>
                            </div>
                          </div> 
                            <div
                            class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                            @click=${() => { 
                            }}
                          >
                            <div>
                              <mwc-icon-button icon="edit">
                              </mwc-icon-button>
                            </div>
                            <div>
                              <p class="text-center">Editar</p>
                            </div>
                          </div>                              
                          </div>
                        </vaadin-form-layout>`,
                        root
                      );
                    }
                )}"
              > 
                <vaadin-grid-filter-column path="folioId"></vaadin-grid-filter-column>
                <vaadin-grid-filter-column path="description"></vaadin-grid-filter-column>
                <vaadin-grid-filter-column path="brand"></vaadin-grid-filter-column>
                <vaadin-grid-filter-column path="serial"></vaadin-grid-filter-column>

              </vaadin-grid>
                `
                :
                html`
                </div>
              <div class="header-tr">
                <div class="mx-2  TexField ">
                  <h1></h1>
                </div>
                <div class="mx-2 ml-3.5 TexField">
                  <h1>Tipo</h1>
                </div>
                <div class="mx-2 ml-3.5 TexField">
                  <h1></h1>
                </div>
                <div class="mx-2 ml-3.5 TexField">
                  <h1></h1>
                </div>
                <div class="mx-2  TexField">
                  <h1></h1>
                </div>
                <div class="mx-2  TexField">
                  <h1></h1>
                </div>
                <div class="mx-2  TexField">
                  <h1></h1>
                </div>
                <!-- <div class="mx-2  TexField">
                      <h1>Enum</h1>
                  </div> -->
              </div>
              ${repeat(
                  this.controlPlantilla,
                  (usuario: any) => usuario.id,
                  (usuario: any, index: number = 1) => html`
                    <div class="flex flex-wrap my-4 mx-4">
                    <div class="mx-2 TexField">
                      <select
                        name="cars" 
                        id="type${usuario.id}"
                        class="style-select"
                        @change=${(ev: CustomEvent) => {
                         let appId = this.appSelected(usuario.id).value;
                         this.getConfiguration(appId,usuario.id)
                        }}>
                      > 
                      <option disabled selected>Plantillas</option>
                    ${this.plantillas.map((app:any,index:number)=> html`
                        <option value="${app.id}">${app.name}</option>
                    `)}
                      </select>
                    </div>
                    <div class="mx-2 TexField">
                    <select
                      name="cars" 
                      id="parametros${usuario.id}"
                      class="style-select"
                      @change=${(ev: CustomEvent) => {
                          const divElement:any = this.diValor(usuario.id);
                          const existingInput = divElement.querySelector('mwc-textfield'); // Get the existing input element
                          const selectElement:any = this.parameterSelected(usuario.id)
                          const parametro = JSON.parse(selectElement.value)
                          if(parametro.hasOwnProperty("enum")){
  
                              const myDiv:any = document.getElementById(`myDiv${usuario.id}`); // Get the div element
                              myDiv.innerHTML = '';
                              const selectElement = document.createElement('select'); // Create the select element
                              selectElement.setAttribute('id', `valor${usuario.id}`);
                              const optionDisabled = document.createElement('option');
                              optionDisabled.disabled = true;
                              optionDisabled.selected = true;
                              optionDisabled.text = 'Valores';
                              selectElement.appendChild(optionDisabled);
                              for (const optionText of parametro.enum) {
                                const optionElement = document.createElement('option');
                                optionElement.value = optionText; // Set the option value
                                optionElement.textContent = optionText; // Set the option text
                                selectElement.appendChild(optionElement); // Add option to select
                              }
                            
                              myDiv.appendChild(selectElement); // Append select to div
                          }else if(parametro.type === "string"){
                              const myDiv:any = document.getElementById(`myDiv${usuario.id}`);
                              myDiv.innerHTML = '';
                              const inputElement = document.createElement('mwc-textfield');
  
                              // Set attributes based on inputObject properties
                              inputElement.id = `valor${usuario.id}`; // Set ID (assuming it's unique)
                              inputElement.type = "text"; // Set input type
                              inputElement.name = parametro.description; // Set input name
                              inputElement.value = ""; // Set initial value (optional)
                              inputElement.classList.add('mx-2');
                              inputElement.outlined = true;
                              inputElement.placeholder = parametro.description;
                            
                              // Add other attributes if needed
                              if (parametro.maxLength) {
                                inputElement.maxLength = parametro.maxLength;
                              }
                              if (parametro.minLength) {
                                inputElement.minLength = parametro.minLength;
                              }
                              if (parametro.pattern) {
                                inputElement.pattern = parametro.pattern;
                              }
                            
                              myDiv.appendChild(inputElement);
                          }else if(parametro.type === "number" || parametro.type === "integer"){
                              console.log("entro")
                              const myDiv:any = document.getElementById(`myDiv${usuario.id}`);
                              myDiv.innerHTML = '';
                              const inputElement = document.createElement('mwc-textfield');
  
                              // Set attributes based on inputObject properties
                              inputElement.id = `valor${usuario.id}`; // Set ID (assuming it's unique)
                              inputElement.type = "number"; // Set input type
                              inputElement.name = parametro.description; // Set input name
                              inputElement.classList.add('mx-2');
                              inputElement.outlined = true;
                              inputElement.placeholder = parametro.description;
                            
                              // Add other attributes if needed
                              if (parametro.maximum) {
                                inputElement.max = parametro.maximum;
                              }
                              if (parametro.minimum) {
                                inputElement.min = parametro.minimum;
                              }
  
                              console.log(inputElement)
                              // Append input element to body or desired container
                              myDiv.appendChild(inputElement);
                          }else if (parametro.type === "boolean"){
                              const myDiv:any = document.getElementById(`myDiv${usuario.id}`);
                              myDiv.innerHTML = '';
                              const inputElement = document.createElement('input');
  
                              // Set attributes based on inputObject properties
                              inputElement.id = `valor${usuario.id}`; // Set ID (assuming it's unique)
                              inputElement.type = 'checkbox'; // Set input type
                              inputElement.name = parametro.description; // Set input name
                              inputElement.value = ""; // Set initial value (optional)
                              inputElement.classList.add('mx-2');
                              
                              const labelElement:any = document.createElement('label');
                              labelElement.for = 'myCheckbox'; // Associate the label with the checkbox ID
                              labelElement.textContent = parametro.description; // Set the label text
                            
  
                              myDiv.appendChild(labelElement);
                              myDiv.appendChild(inputElement);
                          }
  
                      }}>
                    > 
                    <option disabled selected>Parametros</option>
                    </select>
                  </div>
                  <div class="mx-2 TexField" id="myDiv${usuario.id}">
                  </div>
                  </div>
                `
              )}
  
              <div class="flex flex-wrap my-4 mx-4 button-new-campo">
                <mwc-button
                  outlined
                  class="rounded"
                  @click=${() => {
                    this.agregarCampoNuevo();
                  }}
                  ><mwc-icon>add_circle</mwc-icon> Agregar nuevo campo</mwc-button
                >
              </div>
  
                `
            }
           </form>
        </div>
        `
        : html`
            <mwc-circular-progress
              indeterminate
              density=${20}
            ></mwc-circular-progress>
          `}
      </main>
      <name-template id="cambiarNombrePlantilla"></name-template>
      <enum-modal id="enumModal"></enum-modal>
      ${this.dataSchema
        ? html`<demo-modal
            id="demoModal"
            .Schema=${this.dataSchema.properties}
          ></demo-modal>`
        : html``}
    `;
  }
}
