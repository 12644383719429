/* eslint-disable prettier/prettier */
import { html, LitElement, render } from 'lit';
import { guard } from 'lit/directives/guard.js';
import { customElement, state } from 'lit/decorators.js';
import Swal from 'sweetalert2';
import { connect } from 'pwa-helpers';
import '@ui5/webcomponents/dist/Tree.js';
import '@ui5/webcomponents/dist/Icon.js';
import '@ui5/webcomponents-icons/dist/it-instance.js';
import '@ui5/webcomponents-icons/dist/group-2.js';
import '@ui5/webcomponents-icons/dist/alert.js';
import '@ui5/webcomponents-icons/dist/cart.js';
import '@ui5/webcomponents-icons/dist/bell.js';
import '@material/mwc-icon';
import '@material/mwc-icon-button';
import '@vaadin/vaadin-grid'; 
import { State } from '../../redux/types';
import { InstalarAppModal } from '../../components/modals/InstalarAppModal';
import '../../components/modals/InstalarAppModal'; 
import { getCookie } from '../../../src/services/cookie';
import { AgregarInstaladorModal } from '../../components/modals/AgregarInstaladorApp';
import '../../components/modals/AgregarInstaladorApp';
import { DescargarInstaladorModal } from '../../components/modals/DescargarInstaladorApp';
import '../../components/modals/DescargarInstaladorApp';
import { AgregarVersionModal } from '../../components/modals/AgregarVersionApp';
import '../../components/modals/AgregarVersionApp';
import {
  Grid,
  GridActiveItemChangedEvent,
  GridItemModel,
} from '@vaadin/vaadin-grid';
import { AxiosResponse } from 'axios';

import '../../components/modals/EditOrganizationModal';
import router from '../../router/router.js';
import {
  CommunicationTerminalApi,
  FileApiDeleteFileRequest,
  AffiliationApi,
  OrganizationApi,
} from '../../../api/api.js';
import {
    ConfigurationApi,
    FileApiListFilesRequest,
    FileApiGetFileRequest,
    AffiliationApiDeleteAffiliationRequest,
    AppApi,
    AppList,
    TemplateApi,
    RequestFolioCreate,
    FileInfoList,
    FolioApiCreateFolioRequest,
    FolioApi,
    FolioApiListFolioRequest,
    SavedConfigurationApi
  } from '../../../api';
  import { editarApp,verApp } from '../../redux/actions';
import {
  RequestCommandTypeCommandEnum,
  Terminal,
  TerminalList,
  FileApi,
  AffiliationInfoList
} from '../../../api/index.js';
import store from '../../redux/store.js';
import { ConfigConsole,PageFolio } from '../../redux/actions';
import { editarTerminal } from '../../redux/actions/index.js';
import { PushConfigModal } from '../../components/modals/PushConfigurationsDialog';
import '../../components/modals/PushConfigurationsDialog';
import { UbicacionTerminalMapa } from '../../components/modals/UbicacionTerminalMapa';
import { CrearFolio } from '../../components/modals/CrearFolio';
import '../../components/modals/CrearFolio';
import '../../components/modals/ListadoApp';
import { ListadoFolioAfiliaciones } from '../../components/modals/ListadoFolioAfiliaciones';
import '../../components/modals/ListadoFolioAfiliaciones';
import { ListadoPlantillaFolioAfiliaciones } from '../../components/ListadoPlantillaFolioAfiliaciones';
import '../../components/ListadoPlantillaFolioAfiliaciones';
import { ListadoPlantillaCrearFolioAfiliaciones } from '../../components/ListadoPlantillaCrearFolioAfiliaciones';
import '../../components/ListadoPlantillaCrearFolioAfiliaciones';
import { ConfigTerminalFolioAfiliaciones } from '../../components/ConfigTerminalFolioAfiliaciones';
import '../../components/ConfigTerminalFolioAfiliaciones';
import { ConfigTerminalCrearFolioAfiliaciones } from '../../components/ConfigTerminalCrearFolioAfiliaciones';
import '../../components/ConfigTerminalCrearFolioAfiliaciones';
import '../../components/modals/UbicacionTerminalMapa';
import { EditOrganizationModal } from '../../components/modals/EditOrganizationModal.js';
import { apisConfigurator } from '../../utils/axios';
import { EnviarMensajeModal } from '../../components/modals/EnviarMensajeModal';
import '../../components/modals/EnviarMensajeModal';
import { HistorialComandosModal } from '../../components/modals/HistorialComandosModal';
import '../../components/modals/HistorialComandosModal';
import { EditarFolio } from '../../components/modals/EditarFolio';
import '../../components/modals/EditarFolio';
import { CrearFolioAfiliacion} from '../../components/modals/CrearFolioAfiliacion';
import '../../components/modals/CrearFolioAfiliacion';
import { CargarArchivoModal} from '../../components/modals/CargarArchivo';
import '../../components/modals/CargarArchivo';
import {
  ResponseTemplateGet,
} from '../../../api';
import {
  InsertarPropiedad,
  iterarCampoJson2,
  existenciaConfiguracion,
  deleteCamp,
} from '../../utils/functions';

import {
  errorNotification,
  succesNotification,
} from '../../services/notifications';
import { getApps } from '../../redux/actions';
import { getTerminals } from '../../redux/actions/terminals';
import { Unsubscribe } from '@reduxjs/toolkit';

@customElement('files-list-page')
export class FilesList extends connect(store)(LitElement) {
  private appApi: AppApi;
  private fileApi: FileApi;


  private storeUnsuscriber?: Unsubscribe;

  private constructor() {
    super();

    this.fileApi = new FileApi(...apisConfigurator('Files'));
    this.appApi = new AppApi(...apisConfigurator('Aplicaciones'));
  }

  get enviarMensajeModal() {
    return document.getElementById('enviarMensajeModal') as EnviarMensajeModal;
  }

  get cargarArchivoModal() {
    return document.getElementById('cargarArchivoModal') as CargarArchivoModal;
  }

  get instalarAppModal() {
    return document.getElementById('instalarAppModal')! as InstalarAppModal;
  }

  get agregarInstaladorModal() {
    return document.getElementById(
      'agregarInstaladorModal'
    )! as AgregarInstaladorModal;
  }

  get appSelected() {
    return document.getElementById('appSelect') as any;
  }

  get descargarInstaladorModal() {
    return document.getElementById(
      'descargarInstaladorModal'
    )! as DescargarInstaladorModal;
  }

  get agregarVersionModal() {
    return document.getElementById(
      'agregarVersionModal'
    )! as AgregarVersionModal;
  }


  get historialComandosModal() { 
    return document.getElementById(
      'historialComandosModal'
    ) as HistorialComandosModal;
  }

  @state()
  private terminals: TerminalList[] = [];

  async stateChanged(state: any) {}

  @state()
  private apps: AppList[] = [];

  @state()
  termi?: Terminal;

  @state()
  templatesInfo:any = [];

  @state()
  objectoBand:Object[] = [];

  @state()
  template:any = 'Crear Terminal';

  @state()
  afiliacionesList?: any;

  @state()
  app?: any;

  @state()
  band?: any = false;

  @state()
  idApp?: any;

  @state()
  appId?: any;

  @state()
  afiliacion?: any;

  @state()
  versions?: any;

  @state()
  NameBtn = 'Crear Terminal';

  @state()
  detail = false;

  @state()
  detail2 = false;

  @state()
  folioList:any = false;

  @state()
  organizacion = '';

  @state()
  private detailsOpenedItem:any = "";

  @state()
  loader = true;

  @state()
  brand = '';

  @state()
  serial = '';

  get editOrganizationModal() {
    return document.getElementById(
      'edit-organization-modal'
    ) as EditOrganizationModal;
  }

  private handleVersionCreated(ev: CustomEvent) {
    const { detail } = ev;
    if (this.app!.version) {
      this.app!.version.push(detail);
    } else {
      this.app!.version = [detail];
    }
  }

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  disconnectedCallback(): void {
    this.storeUnsuscriber && this.storeUnsuscriber();
  } 

  async updated(){
    if (localStorage.getItem("fileState")) {
      console.log("El nombre es", localStorage.getItem("fileState"));
      const appSelect = this.appSelected;
      const optionValue:any = localStorage.getItem("fileState");

      const optionElement = appSelect.querySelector(`option[value="${optionValue}"]`);
      optionElement.selected = true;
      this.detail=true;
    } 
  }

  protected async firstUpdated(){
    await this.getTerminals();
    await this.getApps();

    if (localStorage.getItem("fileState")) {
      console.log("El nombre es", localStorage.getItem("fileState"));
      const appSelect = this.appSelected;
      const optionValue:any = localStorage.getItem("fileState");
      this.appId = optionValue;
      this.getApp(optionValue,"")
      this.detail=true;
      await this.ObtenerFiles(optionValue)

    } 
  }
 
  async getTerminals() {
    store.dispatch(getTerminals());
    this.storeUnsuscriber = store.subscribe(() => {
      const { list, loading, listApps } = store.getState();
      console.log(loading);
      console.log({ list });
      this.terminals = list as TerminalList[];
      this.loader = loading;
    });
  }

  async ObtenerFiles(appId: any) {
    let data:FileApiListFilesRequest = {
      appId: appId,
    }
    localStorage.removeItem('appId');
    localStorage.setItem('appId', appId);
    await this.fileApi
      .listFiles(data)
      .then(response => response.data)
      .then(data => {
        this.afiliacionesList = data;
        console.log(data)
      });
  }

  async deleteFile(fileId: any) {
    let data:FileApiDeleteFileRequest = {
      appId: this.appId,
      fileId:fileId
    }
    const respuestaSwal = await Swal.fire({
      title: 'Estas Seguro?',
      text: '¡No podrás revertir esto!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si!',
    });
    //console.log({user:user.email})
    if (respuestaSwal.isConfirmed) {
      await this.fileApi
        .deleteFile(data)
        .then(response => response.data)
        .then(data => {
          console.log(data)
          if(data.code==="app.file.deleteOk"){
            Swal.fire(
              'Eliminado!',
              'Archivo borrado.', 
              'success'
            )
          }
          location.reload();
      });
    }
  }

  async getApp(appId: any,folio:any) {
    this.templatesInfo= []
    const { configConsole } = store.getState() as State;
    try {
      const res = await this.appApi.getApp({ id: appId });
      this.app = res.data;
      const app = res.data;

    } catch (err) {
      errorNotification('Error al obtener la app');
      console.log(err);
    } finally {    
    }
  }

  async donwloadInstaller(file:any){
    const userCookie:any = getCookie('tms_user');
    const parseToken = JSON.parse(userCookie);
    console.log(parseToken)
    fetch(`https://tms-server-demo.apps2go.tech/download/other/${file.url}`, {
      mode: "cors",
      headers: {
        'accept': '*/*',
        'Authorization': `Bearer ${parseToken.token}`
      } 
    }).then(function (respuesta) {
      return respuesta.blob(); 
    })
    .then(function (blob) {
      console.log(blob)
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // the filename you want
      a.download = `${file.id}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }

  private actionRenderer = (root: HTMLElement, _: any, item: any) => {
    render(
      html`
        <mwc-icon-button
          @click=${() => router.cambiarSeccion('terminal')}
          icon="visibility"
        ></mwc-icon-button>
        <mwc-icon-button
          icon="edit"
          @click="${() => {
            store.dispatch(editarTerminal(item.item));
            router.cambiarSeccion('edit-terminal');
          }}"
        ></mwc-icon-button>
        <mwc-icon-button
          icon="corporate_fare"
          @click="${() => {
            store.dispatch(editarTerminal(item.item));
            this.editOrganizationModal.open();
          }}"
        ></mwc-icon-button>
        <mwc-icon-button
          icon="delete"
          @click="${() => {

          }}"
        ></mwc-icon-button>
        <mwc-icon-button icon="more_vert"></mwc-icon-button>
      `,
      root
    );
  };

  edit(e: any) {
    store.dispatch(editarTerminal(e));
    router.cambiarSeccion('edit-terminal');
  }


  get pushConfigModal() {
    return document.getElementById('pushConfigModal')! as PushConfigModal;
  }


  async getApps() {
    store.dispatch(getApps());
    const { configConsole } = store.getState() as State;
    this.storeUnsuscriber = store.subscribe(() => {
      const { list, loading, listApps } = store.getState();
      console.log(loading); 
      console.log("list",{ listApps });
      this.apps = listApps as AppList[];
      const apps = listApps as AppList[];
    });
  }


  render() {
    return html`
      <main
        class="w-full h-full FondoColor flex  ${!this.loader
          ? ''
          : 'items-center justify-center'}"
      >
        ${this.loader !== true
          ? html`
              <section
                class="w-3/4 relative flex flex-wrap p-4 SectionList rounded-l-lg"
              >
              <select name="cars" class="w-[210px] mx-1 mb-3 bg-white border border-gray-300 rounded-md text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white" id="appSelect"
              @change=${(ev: CustomEvent) => {
                this.appId = this.appSelected.value;
                console.log(this.appSelected.value)
                localStorage.removeItem('fileState');
                localStorage.setItem('fileState', this.appId);
                this.getApp(this.appSelected.value,"")
                this.ObtenerFiles(this.appSelected.value) 

              }}>
              <option disabled selected>Aplicaciones</option>
                  ${this.apps.map((app:any,index:number)=> html`
                      <option value="${app.id}">${app.name}</option>
                  `)}
              </select> 
                </div>
                <div class="mx-2 TexField">
                  <!-- <input
                    type="search"
                    placeholder="Buscar"
                    id="gsearch"
                    style=":hover {background: white; border: 1px solid gainsboro;}"
                    class="w-full border-0 rounded-xl bg-gray-300 h-11"
                    name="gsearch"
                    @keyup="${async(ev: Event) => {
                      //this.handleChange(ev)
                    }}"
                  /> -->
                  <div class="flex items-center border-2 border-gray-200 rounded-full">
                    <mwc-icon-button icon="search" class="mr-3"></mwc-icon-button>
                    <input type="text" style=":hover {background: white; border: 1px solid gainsboro;}" class="px-4 bg-gray-300 py-2 w-full rounded-full focus:outline-none" placeholder="Buscar...">
                  </div>
                </div>
                <vaadin-grid
                  class="w-screen h-full"
                  .items="${this.afiliacionesList}"
                  .detailsOpenedItems="${this.detailsOpenedItem}"
                  @active-item-changed="${(
                    e: GridActiveItemChangedEvent<FileInfoList>
                  ) => { 
                    this.afiliacion = e.detail.value; 
                    this.detailsOpenedItem =[e.detail.value]; 
                    localStorage.removeItem('file');
                    const afiliacionString = JSON.stringify(this.afiliacion);
                    localStorage.setItem('file', afiliacionString);
                    if (!e.detail.value) return false;

                  }}"
                  .rowDetailsRenderer="${guard(
                    [],
                    () =>
                      (
                        root: HTMLElement,
                        _: Grid,
                        model: GridItemModel<FileInfoList>
                      ) => {

                        render(
                          html`<vaadin-form-layout
                            .responsiveSteps=" ${[
                              { minWidth: '0', columns: 3 },
                            ]}"
                          >
                            <div
                            style="background-color:#F0EFFF;"
                              class="w-full h-14 flex items-center mt-5 justify-center flex-row "
                            >
                               <div 
                                class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                                @click=${() => {
                                  router.cambiarSeccion('edit-file-page'); 
                                }}
                              >  
                                <div>  
                                  <mwc-icon-button icon="edit">
                                  </mwc-icon-button>
                                </div> 
                                <div> 
                                  <p class="text-center">Editar</p>
                                </div>
                              </div>  
                              <div 
                                class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                                @click=${() => {
                                  this.deleteFile(model.item.id)
                                }}
                              >  
                                <div> 
                                  <mwc-icon-button icon="delete">
                                  </mwc-icon-button>
                                </div> 
                                <div>
                                  <p class="text-center">Eliminar</p>
                                </div>
                              </div>  
                            <div 
                              class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                              @click=${() => {
                                this.cargarArchivoModal.open(model.item.id,this.appId)
                              }}
                            >  
                              <div> 
                                <mwc-icon-button icon="upload_file">
                                </mwc-icon-button>
                              </div> 
                              <div>
                                <p class="text-center">Cargar</p>
                              </div>
                            </div> 
                            ${
                              model.item.url.length > 0
                              ? html `
                              <div 
                              class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                              @click=${() => {
                                //console.log(model)
                                this.donwloadInstaller(model.item)
                              }}
                            >  
                              <div> 
                                <mwc-icon-button icon="sim_card_download">
                                </mwc-icon-button>
                              </div> 
                              <div>
                                <p class="text-center">Descargar</p>
                              </div>
                            </div> 
                              `
                              : html`
                              `
                            }

                            </div>
                          </vaadin-form-layout>`,
                          root
                        );
                      }
                  )}"
                > 
                  <vaadin-grid-selection-column></vaadin-grid-selection-column>
                    <vaadin-grid-filter-column
                    path="id"
                  ></vaadin-grid-filter-column>
                  <vaadin-grid-filter-column
                  header="Descripcion"
                    path="description" 
                  ></vaadin-grid-filter-column>
                  <vaadin-grid-filter-column
                  header="Tipo"
                    path="fileType" 
                  ></vaadin-grid-filter-column>
                  <vaadin-grid-filter-column
                    path="status"
                    header="Archivo"
                    .renderer="${guard(
                      [],
                      () => (root: HTMLElement, _: any, model: any) => {
                        const bg =
                        model.item.url.length > 0
                          ? `text-green-500`
                          : `text-red-500`;
                        render(
                          html`<p 
                            class="py-1 capitalize px-4 w-fit ${bg} rounded font-bold"
                          >
                            ${model.item.url.length > 0 ? `Cargado` : `Por Cargar`}
                          </p>`,
                          root
                        );
                      }
                    )}"
                  ></vaadin-grid-filter-column>
                <mwc-icon-button 
                  icon="autorenew"
                  class="absolute top-0 right-0"
                  @click=${() => {
                  }}
                ></mwc-icon-button>
                <edit-organization-modal
                  id="edit-organization-modal"
                ></edit-organization-modal>
              </section>
              <section class="w-1/4  SectionDetail justify-center scroll">
                ${
                  this.detail ? html`
                
                <div class="w-full flex justify-center">
                  <mwc-button
                    raised
                    class="rounded w-4/5 mx-auto mt-4 mb-2"
                    style="--mdc-theme-secundary: #2E3EAE; --mdc-theme-on-secundary: white;"
                    @click="${() => {
                      router.cambiarSeccion('create-file-page');
                    }}"
                  >
                  <mwc-icon>add_circle</mwc-icon>
                    Crear Archivo
                  </mwc-button>
                </div>` : html``}
                <div class=" pt-3 flex items-center ">
              <p class="text-base	text-blue-500	ml-3 "></p>
            </div>
              </section>
            `
          : html`
              <mwc-circular-progress
                indeterminate
                density=${20}
              ></mwc-circular-progress>
            `}
      </main>

      <ubicacion-terminal-mapa
        id="ubicacionTerminalMapa"
      ></ubicacion-terminal-mapa>
      <cargar-archivo-modal id="cargarArchivoModal"></cargar-archivo-modal>
      <crear-folio-afiliacion id="crearFolioAfiliacion"></crear-folio-afiliacion>
      <instalar-app-modal id="instalarAppModal"></instalar-app-modal>
      <push-config-modal id="pushConfigModal"></push-config-modal>
      <enviar-mensaje-modal id="enviarMensajeModal"></enviar-mensaje-modal>
      <crear-folio id="crearFolio"></crear-folio>
      <historial-comandos-modal
        id="historialComandosModal"
      ></historial-comandos-modal> 
      <descargar-instalador-modal
        id="descargarInstaladorModal"
      ></descargar-instalador-modal>
      <agregar-version-modal
        id="agregarVersionModal"
        @version-created=${this.handleVersionCreated}
      ></agregar-version-modal>
    `;
  }
}
