import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '@ui5/webcomponents/dist/Dialog.js';
import '@vaadin/vaadin-select';
import {
  CommunicationTerminalApi,
  CommunicationTerminalApiRequestCommandRequest,
  RequestCommandTypeCommandEnum,
  CommunicationTerminalApiRequestCommandGroupRequest,
  Terminal
} from '../../../api';
import { errorNotification } from '../../services/notifications';
import Swal from 'sweetalert2';
import { apisConfigurator } from '../../utils/axios';
import {DateTime}  from 'luxon';
import ct from 'countries-and-timezones';

@customElement('actualizar-hora-fecha')
export class UpdateHoraFecha extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private communicationApi: CommunicationTerminalApi;

  constructor() {
    super();

    this.communicationApi = new CommunicationTerminalApi(
      ...apisConfigurator('Comunicación')
    );
  }

  @property({ type: Object })
  terminal?: Terminal;

    @state()
  termi?: Terminal;

  @state()
  private fecha = '';

  @state()
  private time = '';

  @state()
  private zonaHoraria:any = '';

  @state()
  private operation:any = '';

  @state()
  person = { name: '', id: '' };

  @state()
  zonaHorario:any = [""];

  
  @state()
  private mensaje = '';

  private get dialog() {
    return document.getElementById('dialog-actualizar-hora-fecha')! as any;
  }

  protected firstUpdated(): void {
    this.zonaHorario = Object.values(ct.getAllTimezones());
    console.log(Object.values(ct.getAllTimezones()))
  }

  async getOrganizaciones() {}

  async open(terminal: any, operation:boolean, person: any) {
    this.termi = terminal;
    this.operation = operation;
    this.person = person;
    this.getOrganizaciones();
    this.dialog.open = true;
  }


  close() { 
    this.dialog.open = false;
    this.terminal = undefined;
  }

  private async handleSubmit(e: Event) {
    e.preventDefault();
    if(this.zonaHoraria===''){
      errorNotification("La zona horaria es requerida");
      return true
    }
    let fechaActual = new Date();
    if(this.fecha!==""){
      const fechaHoraCompletaString = `${this.fecha} ${this.time}`;
      fechaActual = new Date(fechaHoraCompletaString);
    }else{
      fechaActual = new Date();
    }
    const fecha = new Date(fechaActual);
    const [hoursString, minutesString] = this.time.split(':');
    const hours = parseInt(hoursString);
    const minutes = parseInt(minutesString);
    console.log(fecha.getMonth.toString())
    const dateTime = DateTime.fromObject({ year: parseInt(this.fecha.substr(0, 4)), month: parseInt(this.fecha.substr(5, 2)), day: parseInt(this.fecha.substr(8, 2)), hour: hours, minute: minutes }, { zone: this.zonaHoraria })
    console.log(dateTime.toString())
    const fechaISO8601 = dateTime.toString();
    if(this.operation) { 
      const request: CommunicationTerminalApiRequestCommandRequest = {
        brand: this.termi!.brand,
        serial: this.termi!.serial,
        requestCommand: {
          commandId: `updateDateTime-${this.termi!.serial}-${Date.now()}`,
          typeCommand: `UPDATE_DATETIME`,
          parameters: [fechaISO8601],
        },
      };
  
      console.log(request)
      const res = await this.communicationApi.requestCommand(request);
      console.log(res)
      if(res.status == 200) {
        Swal.fire(
          'Completado!',
          'El comando e ha sido enviado.',
          'success'
        )
      }
    }else{
      const requestCommand: CommunicationTerminalApiRequestCommandGroupRequest =
      {
        group: this.person?.id,
        requestCommand: {
          commandId: `updateDateTime-${this.person?.id}-${Date.now()}`,
          typeCommand: RequestCommandTypeCommandEnum.UpdateDatetime,
          parameters: [fechaISO8601],
        },
      };
      console.log(requestCommand)
    }
    this.close()
  }

  protected render() {
    return html` <ui5-dialog
      id="dialog-actualizar-hora-fecha"
      header-text="Actualizar Fecha y hora"
      class="w-3/12"
    >
      <form 
        class="px-4 flex flex-col items-center"
      >
        <div class="mx-2 w-full flex flex-col items-center">
          <mwc-textfield
            class="my-2 w-3/4"
            outlined
            label="Fecha"
            type="date"
            @change=${(e: any) => (this.fecha = e.target.value)}
            required
          ></mwc-textfield>
          <mwc-textfield
            class="my-2 w-3/4"
            outlined
            type="time"
            @change=${(e: any) => (this.time = e.target.value)}
            label="Hora"
          ></mwc-textfield>
          <div class="mx-2 TexField">
                     <select 
                        class="w-[210px] mx-1 mb-3"
                        id="appSelect"
                        @change=${(ev: any) => {
                          //this.getApp(ev.detail)
                          this.zonaHoraria = ev!.target.value;
                        }}
                      >
                      <option disabled selected>Uso Horario</option>
                      ${this.zonaHorario.map((app:any,index:number)=> html`
                          <option value="${app.name}">${app.name}</option>
                      `)}

                    </select>
              </div>

        </div>
        <div slot="footer" class="dialog-footer">
          <button
            class="text-red-600 p-4 rounded-md uppercase"
            id="closeDialogButton"
            @click=${() => {
              this.close();
            }}
          >
            Cerrar
          </button>
          <button
            class="text-blue-600 p-4 rounded-md uppercase"
            id="closeDialogButton"
            @click=${(e:any) => {
              this.handleSubmit(e)
            }}
          >
            Confirmar
          </button>
        </div>
      </form>
    </ui5-dialog>`;
  }
}
