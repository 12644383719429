/* tslint:disable */
/* eslint-disable */
/**
 * TMS Agnostiko
 * Tiene la definición de todos los servicios de TMS a Agnostic (para uso en la web o externo)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { Installer } from '../models';
// @ts-ignore
import { Message } from '../models';
// @ts-ignore
import { RequestIntaller } from '../models';
// @ts-ignore
import { RequestTerminalBatch } from '../models';
// @ts-ignore
import { RequestTerminalCreate } from '../models';
// @ts-ignore
import { RequestTerminalUpdate } from '../models';
// @ts-ignore
import { ResponseTerminalBatch } from '../models';
// @ts-ignore
import { Terminal } from '../models';
// @ts-ignore
import { TerminalList } from '../models';
/**
 * TerminalApi - axios parameter creator
 * @export
 */
export const TerminalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Realiza la creación de múltiples terminales a la vez
         * @param {RequestTerminalBatch} requestTerminalBatch 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminalBatch: async (requestTerminalBatch: RequestTerminalBatch, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestTerminalBatch' is not null or undefined
            assertParamExists('terminalBatch', 'requestTerminalBatch', requestTerminalBatch)
            const localVarPath = `/terminal/batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestTerminalBatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Realiza la creación de un terminal
         * @param {RequestTerminalCreate} requestTerminalCreate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminalCreate: async (requestTerminalCreate: RequestTerminalCreate, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestTerminalCreate' is not null or undefined
            assertParamExists('terminalCreate', 'requestTerminalCreate', requestTerminalCreate)
            const localVarPath = `/terminal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestTerminalCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary elimina un terminal
         * @param {string} brand 
         * @param {string} serial 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminalDelete: async (brand: string, serial: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brand' is not null or undefined
            assertParamExists('terminalDelete', 'brand', brand)
            // verify required parameter 'serial' is not null or undefined
            assertParamExists('terminalDelete', 'serial', serial)
            const localVarPath = `/terminal/{brand}/{serial}`
                .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
                .replace(`{${"serial"}}`, encodeURIComponent(String(serial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Obtener un terminal
         * @param {string} brand 
         * @param {string} serial 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminalGet: async (brand: string, serial: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brand' is not null or undefined
            assertParamExists('terminalGet', 'brand', brand)
            // verify required parameter 'serial' is not null or undefined
            assertParamExists('terminalGet', 'serial', serial)
            const localVarPath = `/terminal/list/{brand}/{serial}`
                .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
                .replace(`{${"serial"}}`, encodeURIComponent(String(serial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Obtener un terminal
         * @param {string} appId 
         * @param {string} brand 
         * @param {string} serial 
         * @param {RequestIntaller} requestIntaller 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminalGetIntaller: async (appId: string, brand: string, serial: string, requestIntaller: RequestIntaller, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('terminalGetIntaller', 'appId', appId)
            // verify required parameter 'brand' is not null or undefined
            assertParamExists('terminalGetIntaller', 'brand', brand)
            // verify required parameter 'serial' is not null or undefined
            assertParamExists('terminalGetIntaller', 'serial', serial)
            // verify required parameter 'requestIntaller' is not null or undefined
            assertParamExists('terminalGetIntaller', 'requestIntaller', requestIntaller)
            const localVarPath = `/terminal/installer/{appId}/{brand}/{serial}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
                .replace(`{${"serial"}}`, encodeURIComponent(String(serial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestIntaller, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Obtener la lista de terminales
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {string} [organization] organización para filtrar los terminales, si se omite devuelve todos
         * @param {boolean} [includeSons] define si debe incluir o no los hijos de la organización seleccionada, por defecto no se incluyen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminalList: async (acceptLanguage?: string, organization?: string, includeSons?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/terminal/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organization !== undefined) {
                localVarQueryParameter['organization'] = organization;
            }

            if (includeSons !== undefined) {
                localVarQueryParameter['includeSons'] = includeSons;
            }

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Obtener la lista de terminales con toda la informacion
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {string} [organization] organización para filtrar los terminales, si se omite devuelve todos
         * @param {boolean} [includeSons] define si debe incluir o no los hijos de la organización seleccionada, por defecto no se incluyen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminalListAll: async (acceptLanguage?: string, organization?: string, includeSons?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/terminal/list/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organization !== undefined) {
                localVarQueryParameter['organization'] = organization;
            }

            if (includeSons !== undefined) {
                localVarQueryParameter['includeSons'] = includeSons;
            }

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary actualiza un terminal (la organización a la que pertenece, su dueño) 
         * @param {string} brand 
         * @param {string} serial 
         * @param {RequestTerminalUpdate} requestTerminalUpdate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminalUpdate: async (brand: string, serial: string, requestTerminalUpdate: RequestTerminalUpdate, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brand' is not null or undefined
            assertParamExists('terminalUpdate', 'brand', brand)
            // verify required parameter 'serial' is not null or undefined
            assertParamExists('terminalUpdate', 'serial', serial)
            // verify required parameter 'requestTerminalUpdate' is not null or undefined
            assertParamExists('terminalUpdate', 'requestTerminalUpdate', requestTerminalUpdate)
            const localVarPath = `/terminal/{brand}/{serial}`
                .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
                .replace(`{${"serial"}}`, encodeURIComponent(String(serial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestTerminalUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TerminalApi - functional programming interface
 * @export
 */
export const TerminalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TerminalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Realiza la creación de múltiples terminales a la vez
         * @param {RequestTerminalBatch} requestTerminalBatch 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async terminalBatch(requestTerminalBatch: RequestTerminalBatch, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseTerminalBatch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.terminalBatch(requestTerminalBatch, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TerminalApi.terminalBatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Realiza la creación de un terminal
         * @param {RequestTerminalCreate} requestTerminalCreate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async terminalCreate(requestTerminalCreate: RequestTerminalCreate, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.terminalCreate(requestTerminalCreate, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TerminalApi.terminalCreate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary elimina un terminal
         * @param {string} brand 
         * @param {string} serial 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async terminalDelete(brand: string, serial: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.terminalDelete(brand, serial, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TerminalApi.terminalDelete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Obtener un terminal
         * @param {string} brand 
         * @param {string} serial 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async terminalGet(brand: string, serial: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Terminal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.terminalGet(brand, serial, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TerminalApi.terminalGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Obtener un terminal
         * @param {string} appId 
         * @param {string} brand 
         * @param {string} serial 
         * @param {RequestIntaller} requestIntaller 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async terminalGetIntaller(appId: string, brand: string, serial: string, requestIntaller: RequestIntaller, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Installer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.terminalGetIntaller(appId, brand, serial, requestIntaller, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TerminalApi.terminalGetIntaller']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Obtener la lista de terminales
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {string} [organization] organización para filtrar los terminales, si se omite devuelve todos
         * @param {boolean} [includeSons] define si debe incluir o no los hijos de la organización seleccionada, por defecto no se incluyen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async terminalList(acceptLanguage?: string, organization?: string, includeSons?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TerminalList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.terminalList(acceptLanguage, organization, includeSons, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TerminalApi.terminalList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Obtener la lista de terminales con toda la informacion
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {string} [organization] organización para filtrar los terminales, si se omite devuelve todos
         * @param {boolean} [includeSons] define si debe incluir o no los hijos de la organización seleccionada, por defecto no se incluyen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async terminalListAll(acceptLanguage?: string, organization?: string, includeSons?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Terminal>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.terminalListAll(acceptLanguage, organization, includeSons, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TerminalApi.terminalListAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary actualiza un terminal (la organización a la que pertenece, su dueño) 
         * @param {string} brand 
         * @param {string} serial 
         * @param {RequestTerminalUpdate} requestTerminalUpdate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async terminalUpdate(brand: string, serial: string, requestTerminalUpdate: RequestTerminalUpdate, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.terminalUpdate(brand, serial, requestTerminalUpdate, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TerminalApi.terminalUpdate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TerminalApi - factory interface
 * @export
 */
export const TerminalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TerminalApiFp(configuration)
    return {
        /**
         * 
         * @summary Realiza la creación de múltiples terminales a la vez
         * @param {TerminalApiTerminalBatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminalBatch(requestParameters: TerminalApiTerminalBatchRequest, options?: AxiosRequestConfig): AxiosPromise<ResponseTerminalBatch> {
            return localVarFp.terminalBatch(requestParameters.requestTerminalBatch, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Realiza la creación de un terminal
         * @param {TerminalApiTerminalCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminalCreate(requestParameters: TerminalApiTerminalCreateRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.terminalCreate(requestParameters.requestTerminalCreate, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary elimina un terminal
         * @param {TerminalApiTerminalDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminalDelete(requestParameters: TerminalApiTerminalDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.terminalDelete(requestParameters.brand, requestParameters.serial, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Obtener un terminal
         * @param {TerminalApiTerminalGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminalGet(requestParameters: TerminalApiTerminalGetRequest, options?: AxiosRequestConfig): AxiosPromise<Terminal> {
            return localVarFp.terminalGet(requestParameters.brand, requestParameters.serial, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Obtener un terminal
         * @param {TerminalApiTerminalGetIntallerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminalGetIntaller(requestParameters: TerminalApiTerminalGetIntallerRequest, options?: AxiosRequestConfig): AxiosPromise<Installer> {
            return localVarFp.terminalGetIntaller(requestParameters.appId, requestParameters.brand, requestParameters.serial, requestParameters.requestIntaller, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Obtener la lista de terminales
         * @param {TerminalApiTerminalListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminalList(requestParameters: TerminalApiTerminalListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<TerminalList>> {
            return localVarFp.terminalList(requestParameters.acceptLanguage, requestParameters.organization, requestParameters.includeSons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Obtener la lista de terminales con toda la informacion
         * @param {TerminalApiTerminalListAllRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminalListAll(requestParameters: TerminalApiTerminalListAllRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<Terminal>> {
            return localVarFp.terminalListAll(requestParameters.acceptLanguage, requestParameters.organization, requestParameters.includeSons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary actualiza un terminal (la organización a la que pertenece, su dueño) 
         * @param {TerminalApiTerminalUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminalUpdate(requestParameters: TerminalApiTerminalUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.terminalUpdate(requestParameters.brand, requestParameters.serial, requestParameters.requestTerminalUpdate, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for terminalBatch operation in TerminalApi.
 * @export
 * @interface TerminalApiTerminalBatchRequest
 */
export interface TerminalApiTerminalBatchRequest {
    /**
     * 
     * @type {RequestTerminalBatch}
     * @memberof TerminalApiTerminalBatch
     */
    readonly requestTerminalBatch: RequestTerminalBatch

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof TerminalApiTerminalBatch
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for terminalCreate operation in TerminalApi.
 * @export
 * @interface TerminalApiTerminalCreateRequest
 */
export interface TerminalApiTerminalCreateRequest {
    /**
     * 
     * @type {RequestTerminalCreate}
     * @memberof TerminalApiTerminalCreate
     */
    readonly requestTerminalCreate: RequestTerminalCreate

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof TerminalApiTerminalCreate
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for terminalDelete operation in TerminalApi.
 * @export
 * @interface TerminalApiTerminalDeleteRequest
 */
export interface TerminalApiTerminalDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiTerminalDelete
     */
    readonly brand: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiTerminalDelete
     */
    readonly serial: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof TerminalApiTerminalDelete
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for terminalGet operation in TerminalApi.
 * @export
 * @interface TerminalApiTerminalGetRequest
 */
export interface TerminalApiTerminalGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiTerminalGet
     */
    readonly brand: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiTerminalGet
     */
    readonly serial: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof TerminalApiTerminalGet
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for terminalGetIntaller operation in TerminalApi.
 * @export
 * @interface TerminalApiTerminalGetIntallerRequest
 */
export interface TerminalApiTerminalGetIntallerRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiTerminalGetIntaller
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiTerminalGetIntaller
     */
    readonly brand: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiTerminalGetIntaller
     */
    readonly serial: string

    /**
     * 
     * @type {RequestIntaller}
     * @memberof TerminalApiTerminalGetIntaller
     */
    readonly requestIntaller: RequestIntaller

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof TerminalApiTerminalGetIntaller
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for terminalList operation in TerminalApi.
 * @export
 * @interface TerminalApiTerminalListRequest
 */
export interface TerminalApiTerminalListRequest {
    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof TerminalApiTerminalList
     */
    readonly acceptLanguage?: string

    /**
     * organización para filtrar los terminales, si se omite devuelve todos
     * @type {string}
     * @memberof TerminalApiTerminalList
     */
    readonly organization?: string

    /**
     * define si debe incluir o no los hijos de la organización seleccionada, por defecto no se incluyen
     * @type {boolean}
     * @memberof TerminalApiTerminalList
     */
    readonly includeSons?: boolean
}

/**
 * Request parameters for terminalListAll operation in TerminalApi.
 * @export
 * @interface TerminalApiTerminalListAllRequest
 */
export interface TerminalApiTerminalListAllRequest {
    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof TerminalApiTerminalListAll
     */
    readonly acceptLanguage?: string

    /**
     * organización para filtrar los terminales, si se omite devuelve todos
     * @type {string}
     * @memberof TerminalApiTerminalListAll
     */
    readonly organization?: string

    /**
     * define si debe incluir o no los hijos de la organización seleccionada, por defecto no se incluyen
     * @type {boolean}
     * @memberof TerminalApiTerminalListAll
     */
    readonly includeSons?: boolean
}

/**
 * Request parameters for terminalUpdate operation in TerminalApi.
 * @export
 * @interface TerminalApiTerminalUpdateRequest
 */
export interface TerminalApiTerminalUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiTerminalUpdate
     */
    readonly brand: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiTerminalUpdate
     */
    readonly serial: string

    /**
     * 
     * @type {RequestTerminalUpdate}
     * @memberof TerminalApiTerminalUpdate
     */
    readonly requestTerminalUpdate: RequestTerminalUpdate

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof TerminalApiTerminalUpdate
     */
    readonly acceptLanguage?: string
}

/**
 * TerminalApi - object-oriented interface
 * @export
 * @class TerminalApi
 * @extends {BaseAPI}
 */
export class TerminalApi extends BaseAPI {
    /**
     * 
     * @summary Realiza la creación de múltiples terminales a la vez
     * @param {TerminalApiTerminalBatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public terminalBatch(requestParameters: TerminalApiTerminalBatchRequest, options?: AxiosRequestConfig) {
        return TerminalApiFp(this.configuration).terminalBatch(requestParameters.requestTerminalBatch, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Realiza la creación de un terminal
     * @param {TerminalApiTerminalCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public terminalCreate(requestParameters: TerminalApiTerminalCreateRequest, options?: AxiosRequestConfig) {
        return TerminalApiFp(this.configuration).terminalCreate(requestParameters.requestTerminalCreate, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary elimina un terminal
     * @param {TerminalApiTerminalDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public terminalDelete(requestParameters: TerminalApiTerminalDeleteRequest, options?: AxiosRequestConfig) {
        return TerminalApiFp(this.configuration).terminalDelete(requestParameters.brand, requestParameters.serial, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Obtener un terminal
     * @param {TerminalApiTerminalGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public terminalGet(requestParameters: TerminalApiTerminalGetRequest, options?: AxiosRequestConfig) {
        return TerminalApiFp(this.configuration).terminalGet(requestParameters.brand, requestParameters.serial, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Obtener un terminal
     * @param {TerminalApiTerminalGetIntallerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public terminalGetIntaller(requestParameters: TerminalApiTerminalGetIntallerRequest, options?: AxiosRequestConfig) {
        return TerminalApiFp(this.configuration).terminalGetIntaller(requestParameters.appId, requestParameters.brand, requestParameters.serial, requestParameters.requestIntaller, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Obtener la lista de terminales
     * @param {TerminalApiTerminalListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public terminalList(requestParameters: TerminalApiTerminalListRequest = {}, options?: AxiosRequestConfig) {
        return TerminalApiFp(this.configuration).terminalList(requestParameters.acceptLanguage, requestParameters.organization, requestParameters.includeSons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Obtener la lista de terminales con toda la informacion
     * @param {TerminalApiTerminalListAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public terminalListAll(requestParameters: TerminalApiTerminalListAllRequest = {}, options?: AxiosRequestConfig) {
        return TerminalApiFp(this.configuration).terminalListAll(requestParameters.acceptLanguage, requestParameters.organization, requestParameters.includeSons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary actualiza un terminal (la organización a la que pertenece, su dueño) 
     * @param {TerminalApiTerminalUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public terminalUpdate(requestParameters: TerminalApiTerminalUpdateRequest, options?: AxiosRequestConfig) {
        return TerminalApiFp(this.configuration).terminalUpdate(requestParameters.brand, requestParameters.serial, requestParameters.requestTerminalUpdate, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}

