import { put, takeLatest } from 'redux-saga/effects';
import { TerminalApi } from '../../../api';
import { apisConfigurator } from '../../utils/axios';
import { GET_TERMINALS, TERMINALS_RECEIVED } from '../actions/terminals';
import { getCookie } from './../../services/cookie';
  
export function* getTerminals(): Generator {
  try { 
    const userCookie:any = getCookie('tms_user');
    console.log("COOKIES",JSON.parse(userCookie));
    const terminalApi = new TerminalApi(...apisConfigurator(''));
    console.log('getTerminals');
    const data = yield terminalApi.terminalList().then(res => res.data);
  
    console.log({ data });
    yield put({ type: TERMINALS_RECEIVED, terminals: data });
  } catch (error) {
    console.log(error)
  }

}

export function* terminalsWatcher() {
  yield takeLatest(GET_TERMINALS, getTerminals);
}
