/* eslint-disable prettier/prettier */

import { LitElement, html } from 'lit';
import { customElement, property,state } from 'lit/decorators.js';
import '@ui5/webcomponents/dist/Dialog.js';
import '@vaadin/vaadin-select';
import { Command, Terminal, CommandApi } from '../../../api';
import { errorNotification } from '../../services/notifications';
import { apisConfigurator } from '../../utils/axios';
 
@customElement('informacion-configuracion')
export class InformacionConfiguracion extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private commandApi: CommandApi;

  @state()
  private app:any;

  constructor() {
    super();
    this.commandApi = new CommandApi(...apisConfigurator('Historial'));
  }

  @property({ type: Object })
  private terminal?: Terminal;

  @property({ type: Array })
  private comandos: Command[] = [];

  private get dialog() {
    return document.getElementById('dialog-historial-comandos')! as any;
  }

  protected firstUpdated(): void {}

  private async getCommands(terminal: Terminal) {
    const res = await this.commandApi.commandsListByTerminal({
      brand: terminal.brand,
      serial: terminal.serial,
    });
    console.log(res);
    this.comandos = res.data.sort((a, b) => b.requestDate! - a.requestDate!);
  }

  async open(app: any) {


    this.app = app;

    this.dialog.open = true;
  }

  close() {
    this.dialog.open = false;
    this.terminal = undefined;
    this.comandos = [];
  }

  private renderCommand(command: Command) {
    console.log('command', command);
    return html`<div class="flex flex-row justify-between border-y p-2">
      <div class="flex flex-col">
        <span class="text-gray-600 text-sm"
          ><b>Id:</b> ${command.commandID}</span
        >
        <span class="text-gray-600 text-sm"
          ><b>Tipo:</b> ${command.commandType}</span
        >
        <span class="text-gray-600 text-sm"
          ><b>Estatus:</b> ${command.status}</span
        >
        <span class="text-gray-600 text-sm"
          ><b>Enviado:</b> ${new Date(
            command.requestDate!
          ).toLocaleString()}</span
        >
        ${command.sentDate
          ? html` <span class="text-gray-600 text-sm"
              ><b>Recibido:</b> ${new Date(
                command.sentDate!
              ).toLocaleString()}</span
            >`
          : ''}
        ${command.responseDate
          ? html`<span class="text-gray-600 text-sm"
              ><b>Respuesta:</b> ${new Date(
                command.responseDate!
              ).toLocaleString()}</span
            >`
          : ''}
      </div>
      <div class="flex flex-col"></div>
    </div>`;
  }

  protected render() {
    return html` <ui5-dialog
      id="dialog-historial-comandos"
      header-text="Historial de Comandos de ${this.terminal?.brand} - ${this
        .terminal?.serial}"
      class="w-3/12"
    >
      <form
        class="px-4 flex flex-col items-center"
      >
        <div class="flex flex-col w-full overflow-y-auto max-h-80 mb-2">
          <!-- ${this.comandos?.map(command => this.renderCommand(command))} -->
            <span class="text-gray-600 text-sm"
            ><b>App:</b>information </span
            >
        </div>

        <div slot="footer" class="dialog-footer">
          <button
            class="text-red-600 p-4 rounded-md uppercase"
            id="closeDialogButton"
            @click=${() => {
              this.close();
            }}
          >
            Cerrar
          </button>
        </div>
      </form>
    </ui5-dialog>`;
  }
}
