/* eslint-disable prettier/prettier */

import { LitElement, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '@ui5/webcomponents/dist/Dialog.js';
import '@vaadin/vaadin-select';
import '../CustomSelect/CustomSelect';
import store from '../../redux/store';
import { Enumm } from '../../redux/actions';
import { JSONEditor } from '@json-editor/json-editor';

@customElement('demo-modal')
export class DemoModal extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  @property({ type: String })
  dialogTitle = 'Ingrese los valores predeterminados';

  @property({ type: String })
  dialogBody = '';

  @property({ type: String })
  acceptButtonMessage = 'Aceptar';

  @state()
  private index: any;

  @property({ type: String })
  cancelButtonMessage = 'Cancelar';

  @property()
  Schema?: Object;

  @state()
  editor?: any;

  private get collapse() {
    return document.querySelector(`.je-object__title`) as HTMLInputElement;
  }

  private get collapse2() {
    return document.querySelector(
      `.json-editor-btn-edit_properties`
    ) as HTMLInputElement;
  }

  private get collapse3() {
    return document.querySelector(`.json-editor-btn-edit`) as HTMLInputElement;
  }

  private get jeindented() {
    return document.querySelector(`.je-indented-panel`) as HTMLInputElement;
  }

  private get dialog() {
    return document.getElementById('demo-confirm')! as any;
  }

  private get prueba() {
    return document.getElementById('prueba') as any;
  }

  private get formData() {
    return document.getElementById('editorHolder') as any;
  }

  esconderDiv() {
    const collapse = this.collapse;
    const collapse2 = this.collapse2;
    const collapse3 = this.collapse3;
    collapse.style.display = 'none';
    collapse2.style.display = 'none';
    collapse3.style.display = 'none';
    const jeindented = this.jeindented;
    jeindented.setAttribute('style', 'border:none');
    const div: any = jeindented.querySelector('div');
    const div2 = div.querySelector('div');
    div2.setAttribute('class', 'flex flex-wrap my-4 mx-4');
  }

  input() {
    const element = this.formData;
    let properties: Object = this.Schema ? this.Schema : {};
    this.editor = new JSONEditor(element, {
      ajax: true,
      schema: {
        type: 'object',
        title: 'Car',
        properties,
        /*properties: {
          make: {
            type: "string",
            enum: [
              "Toyota",
              "BMW",
              "Honda",
              "Ford",
              "Chevy",
              "VW"
            ]
          },
          model: {
            type: "string"
          },
          year: {
            type: "integer",
            enum: [
              1995,1996,1997,1998,1999,
              2000,2001,2002,2003,2004,
              2005,2006,2007,2008,2009,
              2010,2011,2012,2013,2014
            ],
            maxLength:255,
            pattern:"",
            maximum:3,
            description:"hola",
            default: 2008
          },
          safety: {
            type: "integer",
            format: "rating",
            maximum: "5",
            exclusiveMaximum: false,
            readonly: false
          }
        }*/
      },
    });
  }

  protected async firstUpdated() {
  }

  async open(schema: Object) {
    this.dialog.open = true;
    await this.input();
    await setTimeout(function () {
      console.log('');
    }, 500);
    console.log('');

    this.esconderDiv();
  }

  close() {
    this.dialog.open = false;
  }

  private onAccept() {
    const prueba = this.prueba.value;
    let arr = prueba.split(',');
    console.log(arr);
    store.dispatch(
      Enumm({
        id: this.index,
        data: arr,
      })
    );
    this.close();
  }

  protected render() {
    return html` <ui5-dialog id="demo-confirm" header-text=${this.dialogTitle}>
      <!-- <div class="flex justify-center" style="height: 150px;"> -->
      <main class="w-full flex overflow-auto" style="height: 28rem;">
        <div class="w-full h-full px-5 py-4 flex flex-wrap items-start">
          <div id="editorHolder"></div>
        </div>
      </main>
      <!-- </div> -->
      <div class="dialog-footer mt-auto w-full text-center">
        <button
          class="text-red-600 p-4 rounded-md uppercase"
          id="closeDialogButton"
          @click=${() => {
            this.close();
          }}
        >
          ${this.cancelButtonMessage}
        </button>
        <button
          class="text-blue-600 p-4 rounded-md uppercase"
          id="closeDialogButton"
          @click=${() => {
            this.close();
          }}
        >
          ${this.acceptButtonMessage}
        </button>
      </div>
    </ui5-dialog>`;
  }

  static styles = css`
    .dialogBody {
      text-align: center;
    }

    #ui5-popup-header-text {
      font-weight: bold;
      margin-top: 2px;
    }
  `;
}
