/* eslint-disable prettier/prettier */
import { html, LitElement } from 'lit';
import { customElement, query, state } from 'lit/decorators.js';

import '@vaadin/vaadin-text-field';
import '@material/mwc-checkbox';
import {
  OrganizationName,
  OrganizationApi,
  RequestOrganizationCreate,
} from '../../../api';
import router from '../../router/router';
import { flatOrganizations } from '../../utils/functions';
import { apisConfigurator } from '../../utils/axios';
import '@vaadin/vaadin-text-field';
import { errorNotification } from '../../services/notifications';
import { connect } from 'pwa-helpers';
import store from '../../redux/store';
import { organizacionAdded } from '../../redux/actions/organizaciones';

@customElement('create-organization-page')
export class CrearOrganizacion extends connect(store)(LitElement) {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  @query('#name')
  nameInput!: HTMLInputElement;

  @query('#description')
  descriptionInput!: HTMLInputElement;

  @query('#organization')
  organizationSelect!: HTMLSelectElement;

  @state()
  organizaciones: OrganizationName[] = [];

  @state()
  loading = true;

  private organizationApi!: OrganizationApi;

  firstUpdated() {
    this.organizationApi = new OrganizationApi(
      ...apisConfigurator('Organización')
    );

    this.cargarOrganizaciones();
  }

  validateForm() {
    if (!this.nameInput.checkValidity()) {
      errorNotification('El nombre de la organizacion es requerido.');
      return false;
    }

    return true;
  }

  async createOrganization() {
    if (!this.validateForm()) return;

    const request: RequestOrganizationCreate = {
      name: this.nameInput.value,
    };

    if (this.organizationSelect.value != '')
      request.father = this.organizationSelect.value;

    try {
      console.log(request);
      const { data } = await this.organizationApi.organizationCreate({
        requestOrganizationCreate: request,
      });

      console.log({ data });

      store.dispatch(
        organizacionAdded({ name: request.name, id: data.id, sons: [] })
      );
    } catch (err) {}
  }

  async cargarOrganizaciones() {
    try {
      const res = await this.organizationApi.organizationList({});
      this.organizaciones = flatOrganizations(res.data);
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    return html`
      <main class="w-full h-full flex">
        <div class="w-full h-full px-5 py-4 flex flex-wrap items-start">
          <form @submit="${(e: any) => {
            e.preventDefault();
            this.createOrganization();
          }}" class="w-full h-full relative mb-2 SectionList rounded-md pt-5px-4 ">
            <div class="flex">
      
              <h1 class="text-2xl font-semibold text-blue-600">Crear Organización</h1>
      
            </div>
      
            <div class="flex flex-wrap my-4 mx-4">
              <div class="mx-2">
                <mwc-textfield required label="Nombre" id="name" minLength="3" class="mx-2"
                  validationMessage="El nombre debe tener al menos 3 caracteres" autoValidate outlined iconTrailing="close">
                </mwc-textfield>
      
              </div>
              <div class="mx-2">
      
                <mwc-select outlined label="Organización Padre" id="organization" class="mx-2">
  <mwc-list-item></mwc-list-item>
  ${this.organizaciones.map(
    org => html`<mwc-list-item value=${org.id}>${org.name}</mwc-list-item>`
  )}
  
                </mwc-select>
              </div>
            </div>
      
            <!-- <div class="my-4 ml-8">
              <p>O sube un archivo .xlm con la cantidad de terminales que desees</p>
            </div> -->
            <!-- <div class="w-full flex flex-row my-4 ml-8">
              <div class="CajonXlm w-3/5	">
              </div>
              <div class="w-2/5	ml-10 CrearTerminal normal-case 	flex flex-col">
                <div class="w-40	 min-w-full">
                  <mwc-button type="submit" class="rounded-3xl ml-4 mb-2 	 ">
                    <p class="normal-case text-xs">Explorar</p>
                  </mwc-button>
                </div>
                <div class="w-40	 min-w-full">
                  <mwc-button type="submit" class="rounded-3xl ml-4 mt-4  bg-gray-500 text-gray-700	normal-case	">
                    <p class="normal-case	 text-xs"> Eliminar</p>
                  </mwc-button>
                </div>
              </div>
            </div> -->
            <div class="w-full flex flex-row my-4">
              <div class="w-2/4 CrearTerminal	flex flex-row">
                <button @click=${() => {
                  router.cambiarSeccion('hierarchy');
                }}
                  class="rounded-3xl border border-indigo-500 text-blue-500 CancelarG hover:shadow-md hover:bg-slate-300
                  text-xs ">
                  Cancelar
                </button>
      
                <mwc-button type="submit" class="rounded-3xl ml-4 mb-2  normal-case		"
                  @click="${() => this.createOrganization()}">
                  <p class="normal-case	text-xs ">Crear Organización</p>
                </mwc-button>
      
              </div>
            </div>
      
          </form>
        </div>
        </div>
      </main>
    `;
  }
}
