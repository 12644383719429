import {
  AppList,
  GroupList,
  OrganizationName,
  TerminalList,
  FirmwareList,
  TemplateInfo,
  User,
} from '../../api';

export function isAppList(appList: any): appList is AppList {
  return (
    appList &&
    'id' in appList &&
    'packageName' in appList &&
    'name' in appList &&
    'briefDescription' in appList
  );
}

export function isTerminalList(
  terminalList: any
): terminalList is TerminalList {
  return (
    terminalList &&
    'serial' in terminalList &&
    'brand' in terminalList &&
    'model' in terminalList &&
    'organization' in terminalList
  );
}

export function isFirmwareList(
  firmwareList: any
): firmwareList is FirmwareList {
  return (
    firmwareList &&
    'name' in firmwareList &&
    'briefDescription' in firmwareList &&
    'description' in firmwareList &&
    'developer' in firmwareList &&
    'brand' in firmwareList &&
    'model' in firmwareList &&
    'os' in firmwareList &&
    'requirements' in firmwareList
  );
}

export function isTemplateList(
  templateList: any
): templateList is TemplateInfo {
  return (
    templateList &&
    'id' in templateList &&
    'name' in templateList &&
    'description' in templateList
  );
}

export function isUser(user: any): user is User {
  return user && 'email' in user;
}

export function isOrganizationName(org: any): org is OrganizationName {
  return org && 'id' in org && 'name' in org && 'sons' in org;
}

export function isGroupList(group: any): group is GroupList {
  return group && 'id' in group && 'name' in group && 'terminals' in group;
}
