/* eslint-disable prettier/prettier */
import { html, LitElement } from 'lit';
import { customElement, query, state } from 'lit/decorators.js';
import '@vaadin/vaadin-list-box';
import '@vaadin/vaadin-text-field';
import './CreateUser.css';
import {
  OrganizationApi,
  OrganizationName,
  RequestUserUpdate,
  RoleApi,
  RoleList,
  User,
  UserApi,
  UserApiUserUpdateRequest,
} from '../../../api';
import '@vaadin/vaadin-text-field';

import router from '../../router/router';
import { apisConfigurator } from '../../utils/axios';
import { flatOrganizations } from '../../utils/functions';
import store from '../../redux/store';
import { State } from '../../redux/types';
import { clearUsuario } from '../../redux/actions';
import { CustomMultiSelect } from '../../components/CustomMultiSelect/CustomMultiSelect';
import { errorNotification } from '../../services/notifications';
import { usuarioAdded } from '../../redux/actions/usuario';
@customElement('create-user-page')
export class CrearUsuario extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  @query('#name')
  nameInput!: HTMLInputElement;

  @query('#lastName')
  lastNameInput!: HTMLInputElement;

  @query('#email')
  emailInput!: HTMLInputElement;

  @query('#password')
  passwordInput!: HTMLInputElement;

  @query('#rol')
  rolSelect!: HTMLSelectElement;

  @query('#organization')
  organizationSelect!: CustomMultiSelect<string>;

  @state()
  loading = true;

  @state()
  editUser?: User;

  @state()
  roles: RoleList[] = [];

  @state()
  rolesUsuario: string[] = [];

  @state()
  organizationUsuario: string = '';

  @state()
  aux: string = '';

  @state()
  organizaciones: OrganizationName[] = [];

  @state()
  rol?: string = '';

  @state()
  organization: string[] = [];

  @state()
  organizationsNames: Array<string> = [''];

  private usersApi!: UserApi;

  private organizacionesApi!: OrganizationApi;
  private rolesApi!: RoleApi;

  private get loaderContainer() {
    return document.getElementById('loader-container') as any;
  }

  firstUpdated() {
    const loaderContainer = this.loaderContainer;
    loaderContainer.style.display = 'none';

    this.usersApi = new UserApi(...apisConfigurator('Editar Usuario'));
    this.organizacionesApi = new OrganizationApi(
      ...apisConfigurator('Usuario')
    );
    this.rolesApi = new RoleApi(...apisConfigurator('Editar Usuario'));

    this.cargarData();
  }

  async cargarData() {
    console.log('cargarData');
    this.verificarUserEdit();
    await this.cargarDatosSelect(this.editUser?.email);
    await this.cargarRoles();
    await this.cargarOrganizaciones();

    this.loading = false;
  }

  async verificarUserEdit() {
    const { usuarioEdit } = store.getState() as State;

    console.log('usuarioEdit', usuarioEdit);

    /*let email = usuarioEdit?.email;
    await this.getUsuario(usuarioEdit?.email);*/

    this.editUser = usuarioEdit;

    //this.getUsuario(usuarioEdit?.email);

    store.dispatch(clearUsuario());
  }

  async cargarDatosSelect(correo: any) {
    const usuario = {
      user: correo,
    };

    try {
      const res = await this.usersApi.userGet(usuario);
      this.rolesUsuario = res.data.organization;
      this.aux = res.data.role;
      this.organization = res.data.organization;
      console.log(res.data.organization);
    } catch (err) {
      console.log(err);
    }
  }

  async cargarOrganizaciones() {
    try {
      const res = await this.organizacionesApi.organizationList({});
      this.organizaciones = flatOrganizations(res.data);
      this.organizaciones.map(x => {
        this.organizationsNames.push(x.id);
      });
      this.organizationsNames.shift();
      console.log(this.organizationsNames);
    } catch (err) {
      console.log(err);
    }
  }

  async cargarRoles() {
    try {
      const res = await this.rolesApi.securityRolList({});
      this.roles = res.data;

      for (let x of this.roles) {
        if (x.id === this.aux) {
          this.organizationUsuario = x.name;
          this.rol = x.id;
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  validateForm() {
    if (!this.nameInput.checkValidity()) {
      errorNotification('El nombre del usuario es requerido.');
      return false;
    }

    if (!this.lastNameInput.checkValidity()) {
      errorNotification('El apellido del usuario es requerido.');
      return false;
    }

    if (!this.emailInput.checkValidity() && !this.editUser) {
      errorNotification('El email del usuario debe ser valido.');
      return false;
    }

    if (!this.passwordInput?.checkValidity() && !this.editUser) {
      errorNotification('La contraseña del usuario es requerida.');
      return false;
    }

    if (!this.rol) {
      errorNotification('El rol del usuario es requerido.');
      return false;
    }

    if (this.organization.length === 0) {
      errorNotification('El rol del usuario es requerido.');
      return false;
    }

    return true;
  }

  async UserCreateUpdate(operacion: any) {
    /* CREAR USUARIO 1 ACTUALIZAR USUARIO; 0 */
    this.loading = true;
    if (!this.validateForm()) {
      this.loading = false;
      return;
    }

    console.log(operacion);
    if (operacion) {
      let requestUserCreate = {
        email: this.emailInput.value,
        name: `${this.nameInput.value} ${this.lastNameInput.value}`,
        password: this.passwordInput.value,
        role: `${this.rol}`,
        //organization: [``],
        //role: "prueba",
        organization: this.organization,
      };
      console.log(requestUserCreate);

      try {
        const res = await this.usersApi.userCreate({ requestUserCreate });
        console.log(res.data);

        store.dispatch(
          usuarioAdded({
            email: requestUserCreate.email,
            name: requestUserCreate.name,
          })
        );
      } catch (err) {
      } finally {
        this.loading = false;
      }
    } else {
      const requestUserUpdate: RequestUserUpdate = {
        name: `${this.nameInput.value} ${this.lastNameInput.value}`,
        role: this.rol,
      };

      const requestUser = {
        user: `${this.emailInput.value}`,
        requestUserUpdate,
      };

      const aux = [''];

      this.organizaciones.map(x => {
        console.log(this.organization.indexOf(x.id));
        if (this.organization.indexOf(x.id) >= 0) {
          aux.push(x.name);
        }
      });
      aux.shift();

      const requestOrganization = {
        user: `${this.emailInput.value}`,
        requestUserUpdateOrganization: {
          organizationsToAdd: this.organization,
          organizationsToDelete: this.organizationsNames,
        },
      };

      console.log(requestOrganization);
      try {
        const res = await this.usersApi.userUpdate(requestUser);
        const resOrganization = await this.usersApi.userUpdateOrganization(
          requestOrganization
        );
        console.log(res.data);
        console.log(resOrganization.data);

        if (this.editUser) {
          store.dispatch(clearUsuario());
        }

        router.cambiarSeccion('users-list');
        //window.location.href = "/users-list";
      } catch (err) {
        console.log('bad');
      } finally {
        this.loading = false;
      }
    }
  }
  ver(e: any) {
    console.log(e);
  }

  extraerName(indice: any) {
    let subs: any = [];
    if (this.editUser?.name) {
      subs = this.editUser?.name.split(' ');
    } else {
      return '';
    }
    return subs[indice] == undefined ? '' : subs[indice];
  }

  render() {
    return html`
      <div id="loader-container">
        <div class="spinner"></div>
      </div>
      <main class="w-full h-full flex">
        <div class="w-full h-full px-5 py-4 flex flex-wrap items-start">
          <form
            class="w-full h-full relative mb-2 SectionList rounded-md pt-5px-4 "
          >
            <div class="flex justify-between">
              <h1 class="text-2xl font-semibold text-blue-600">
                ${this.editUser ? 'Editar Usuario' : 'Crear Usuario'}
              </h1>
              <!-- <mwc-button
                outlined
                class="rounded"
                @click=${() => {
                  router.cambiarSeccion('create-rol');
                }}
                ><mwc-icon>add_circle</mwc-icon> Crear Rol</mwc-button
              > -->
            </div>

            <div class="flex flex-wrap my-4 mx-4">
              <div class="mx-2 TexField">
                <mwc-textfield
                  required
                  validationMessage="Debe ser un correo valido."
                  label="Correo Electrónico"
                  class="mx-2"
                  id="email"
                  minLength="5"
                  type='email'
                  .autoValidate=${this.editUser !== undefined}
                  outlined iconTrailing="close"
                  .value=${this.editUser?.email ?? ''}
                  .readOnly="${this.editUser !== undefined}"
                >
                </mwc-textfield>
              </div>
              <div class="mx-2 TexField">
                <mwc-textfield
                  required
                  autoValidate outlined iconTrailing="close"
                  label="Nombre"
                  id="name"
                  minLength="3"
                  class="mx-2"
                  validationMessage="El nombre debe tener al menos 3 caracteres"
                  .value=${this.extraerName(0)}
                  .readOnly="${this.editUser !== undefined}"
                 
                >
                </mwc-textfield>
              </div>
              <div class="mx-2 TexField">
                <mwc-textfield
                  required
                  id="lastName"
                  validationMessage="El apellido debe tener al menos 3 caracteres"
                  label="Apellido"
                  class="mx-2"
                  minLength="3"
                  autoValidate outlined iconTrailing="close"
                  .value=${this.extraerName(1)}
                  .readOnly="${this.editUser !== undefined}"
                >
                </mwc-textfield>
              </div>
                ${
                  !this.editUser
                    ? html`
                        <div class="mx-2 TexField">
                          <mwc-textfield
                            required
                            id="password"
                            validationMessage="La contraseña debe tener al menos 6 caracteres"
                            label="Contraseña"
                            class="mx-2"
                            type="password"
                            minLength="6"
                            autoValidate
                            outlined
                            iconTrailing="close"
                          >
                          </mwc-textfield>
                        </div>
                      `
                    : ''
                }

              <div class="mx-2 TexField">
                <custom-select
                  label="${this.organizationUsuario}"
                  .options="${this.roles.map(r => ({
                    label: r.name,
                    value: r.id,
                  }))}"
                  id="rol"
                  @change=${(ev: CustomEvent) => {
                    this.rol = ev.detail;
                  }}
                ></custom-select>
                </vaadin-select>
              </div>
               <div class="mx-2 TexField">
                <custom-multi-select
                  label="Organización"
                  id="organization"
                  .organization="${this.organizaciones}"
                  .options="${this.organizaciones.map(org => ({
                    label: org.name,
                    value: org.id,
                  }))}"
                  .selected=${this.organization}
                  @change=${(ev: CustomEvent) => {
                    if (ev.detail != undefined) {
                      console.log(ev.detail);
                      this.organization = ev.detail;
                    }
                  }}
                >
                </custom-multi-select>
              </div>
             
            </div>

            ${
              this.editUser
                ? html`
                    <div class="w-full flex flex-row my-4 ml-8">
                      <div class="w-2/4	"></div>
                      <div class="w-2/4 CrearTerminal	flex flex-row">
                        <button
                          class="rounded-3xl border  border-indigo-500 text-blue-500  CancelarG  hover:shadow-md hover:bg-slate-300 text-xs	"
                          @click="${(e: Event) =>
                            router.cambiarSeccion('users-list')}"
                        >
                          Cancelar
                        </button>
                        <mwc-button
                          class="rounded-3xl ml-4 mb-2  normal-case		"
                          @click="${() => this.UserCreateUpdate(0)}"
                        >
                          <p class="normal-case	text-xs ">Actualizar usuario</p>
                        </mwc-button>
                      </div>
                    </div>
                  `
                : html`
              <div class="w-2/4 CrearTerminal	flex flex-row">
                <button
                  @click="${() => router.cambiarSeccion('users-list')}"
                  class="rounded-3xl border  border-indigo-500 text-blue-500  CancelarG   text-xs	">
                  Cancelar
                </button>

                        <mwc-button
                          class="rounded-3xl ml-4 mb-2  normal-case		"
                          @click="${() => this.UserCreateUpdate(1)}"
                        >
                          <p class="normal-case	text-xs ">Crear usuario</p>
                        </mwc-button>
                      </div>
                    </div>
                  `
            }
          </form>
        </div>
      </main>
    `;
  }
}
