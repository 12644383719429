/* eslint-disable prettier/prettier */
import { TemplateResult, html } from 'lit';
import { DataQueryStatistic, Widget } from '../../api';

/* eslint-disable prettier/prettier */
export function buildDashboardWidgets(widgets: Widget[]) {
  const res: TemplateResult<1>[] = widgets.map(
    w => html`
      <dashboard-widget-container
        .idWidget=${w.idData}
        .widgetType=${w.idWidget}
      ></dashboard-widget-container>
    `
  );

  return res;
}

export const buildWidgetByType = (
  type: string,
  values: number[],
  dataLabels: string[],
  data: DataQueryStatistic[]
) => {
  switch (type) {
    case VERTICAL_BAR:
      return buildVerticalBar(values, dataLabels);
    case HORIZONTAL_BAR:
      return buildHorizontalBar(data, dataLabels);
    case LINEAL:
      return buildLineal(values, dataLabels);
    case COUNTER:
      return buildCounter(values, dataLabels);
    case MAP:
      return buildMap(values, dataLabels);
    default:
      break;
  }
};

function buildVerticalBar(data: number[], dataLabels: string[]) {
  return html`
    <dashboard-graph-card
      class="flex items-center flex-wrap mb-4 mt-4"
      .classes="${['w-full', 'p-4']}"
      .firstComponent=${html`
        <vertical-bar-graph
          .data=${data}
          .dataLabels=${dataLabels}
        ></vertical-bar-graph>
      `}
    >
    </dashboard-graph-card>
  `;
}

function buildHorizontalBar(data: DataQueryStatistic[], dataLabels: string[]) {
  return html`
    <dashboard-graph-card
      class="flex  items-center flex-wrap mb-4 mt-4"
      .classes="${['w-full', 'p-4']}"
      .firstComponent=${html`
        <horizontal-bar-chart
          .data=${data}
          .dataLabels=${dataLabels}
        ></horizontal-bar-chart>
      `}
    >
    </dashboard-graph-card>
  `;
}

function buildCounter(data: number[], dataLabels: string[]) {
  return html`
    <dashboard-graph-card
      class="flex items-center flex-wrap mb-4 mt-4"
      .cardTitle=${'Estatus de Terminales'}
      .classes="${['w-full', 'p-4']}"
      .firstComponent=${html`
        <div class="flex items-center flex-wrap mb-4 mt-4">
          ${data.map(
            (v, index) => html`
              <mwc-icon-button
                icon="ad_units"
                class="${dataTextColors[index]}"
              ></mwc-icon-button>
              <p class="text-xl mr-3">
                <span class="mx-2 Number">${v}</span
                ><span class="Modelos">${dataLabels[index]}</span>
              </p>
            `
          )}
        </div>
      `}
      .secondComponent=${html`
        <dashboard-counter
          .data=${data}
          .dataLabels=${dataLabels}
        ></dashboard-counter>
      `}
    >
    </dashboard-graph-card>
  `;
}

function buildLineal(data: number[], dataLabels: string[]) {}

function buildMap(data: number[], dataLabels: string[]) {
  return html`
    <card-d
      .customSlot=${html` <dashboard-graph-card
        .firstComponent=${html` <custom-map></custom-map> `}
      >
      </dashboard-graph-card>`}
    >
    </card-d>
  `;
}

const dataColors = [
  '#2FB83D',
  '#B94332',
  'rgb(255, 205, 86)',
  'rgb(75, 192, 192)',
  'rgb(54, 162, 235)',
  ,
];

const dataTextColors = [
  'text-green-500',
  'text-red-500',
  'text-yellow-500',
  'text-blue-500',
  'text-orange-500',
  'text-grey-500',
];

const VERTICAL_BAR = 'VERTICAL_BAR';
const HORIZONTAL_BAR = 'HORIZONTAL_BAR';
const LINEAL = 'LINEAL';
const COUNTER = 'COUNTER';
const MAP = 'MAP';

export enum WIDGETS {
  'VERTICAL_BAR',
  'HORIZONTAL_BAR',
  'LINEAL',
  'COUNTER',
  'MAP',
}

export enum WIDGETS_SIZE {
  'A',
  'B',
  'C',
  'D',
}

class WidgetMap {
  points: MapPoint[];

  constructor(points: MapPoint[]) {
    this.points = points;
  }
}

class WidgetCounter {
  label: string;
  value: number;

  constructor(label: string, value: number) {
    this.label = label;
    this.value = value;
  }
}

class WidgetBar {
  dataLabels: string[];

  constructor(dataLabels: string[]) {
    this.dataLabels = dataLabels;
  }
}

class WidgetLineal {}

class WidgetHorizontalBar extends WidgetBar {}

class WidgetVerticalBar extends WidgetBar {}

class MapPoint {
  brand: string;
  latitude: number;
  longitude: number;
  model: string;
  owner: string;

  constructor(
    brand: string,
    latitude: number,
    longitude: number,
    model: string,
    owner: string
  ) {
    this.brand = brand;
    this.latitude = latitude;
    this.longitude = longitude;
    this.model = model;
    this.owner = owner;
  }
}
