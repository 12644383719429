/* eslint-disable prettier/prettier */

import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '@ui5/webcomponents/dist/Dialog.js';
import '@vaadin/vaadin-select';
import '../CustomSelect/CustomSelect';

@customElement('ejemplo-csv')
export class EjemploCsv extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  @property({ type: String })
  dialogTitle = '¿Estás seguro de realizar esta acción?';

  @property({ type: String })
  dialogBody = '';

  @property({ type: String })
  acceptButtonMessage = 'Aceptar';

  @property({ type: String })
  cancelButtonMessage = 'Cancelar';

  private get dialog() {
    return document.getElementById('dialog-confirm')! as any;
  }

  protected firstUpdated(): void {}

  async open() {
    this.dialog.open = true;
  }

  close() {
    this.dialog.open = false;
  }

  private onAccept() {
    const ev = new CustomEvent('confirm-onAccept');
    this.dispatchEvent(ev);

    this.close();
  }

  protected render() {
    return html` <ui5-dialog
      id="dialog-confirm"
      header-te.xt=${this.dialogTitle}
    >
    <div><a href="#"><img src="https://i.ibb.co/z46Cx07/Captura-de-pantalla-2023-12-12-14-33-57.png" alt="ejemplo" border="0"></a></div>

      <!-- <div class="flex justify-center">
        <p class="dialogBody">${this.dialogBody}</p>
      </div>
      <div class="dialog-footer mt-auto w-full text-center">
        <button
          class="text-red-600 p-4 rounded-md uppercase"
          id="closeDialogButton"
          @click=${() => {
            this.close();
          }}
        >
          ${this.cancelButtonMessage}
        </button>
        <button
          class="text-blue-600 p-4 rounded-md uppercase"
          id="closeDialogButton"
          @click=${() => {
            this.onAccept();
          }}
        >
          ${this.acceptButtonMessage}
        </button> -->
      </div>
      <button
          class="text-red-600 p-4 rounded-md uppercase"
          id="closeDialogButton"
          @click=${() => {
            this.close();
          }}
        >
          ${this.cancelButtonMessage}
        </button>

    </ui5-dialog>`;
  }

  static styles = css`
    .dialogBody {
      text-align: center;
    }

    #ui5-popup-header-text {
      font-weight: bold;
      margin-top: 2px;
    }
  `;
}
