/* eslint-disable prettier/prettier */
import { html, LitElement } from 'lit';
import { customElement, query, state, property } from 'lit/decorators.js';
import { connect } from 'pwa-helpers';
import { ObjectJson } from '../../redux/actions';
import Ajv from 'ajv';
import '@vaadin/vaadin-list-box';
import '@vaadin/vaadin-text-field';
import { AxiosResponse } from 'axios';
import {
  OrganizationName,
  RoleList,
  User,
  TemplateApi,
  ConfigurationApi,
  ConfigurationApiGetConfigurationRequest,
} from '../../../api';
import '@vaadin/vaadin-text-field';
import { apisConfigurator } from '../../../src/utils/axios';
import {
  typeDataObject,
  addDefault,
  searchCampObjectParameters,
} from '../../../src/utils/functions';
import store from '../../../src/redux/store';
import { State } from '../../../src/redux/types';
import router from '../../router/router';
import { agregarPlantilla, ConfigConsole } from '../../../src/redux/actions';
import { JSONEditor } from '@json-editor/json-editor';

@customElement('pre-configuracion-modals')
export class CrearPreConfiguracionModals extends connect(store)(LitElement) {
  private templateApi: TemplateApi;
  private configurationApi: ConfigurationApi;

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private constructor() {
    super();
    this.templateApi = new TemplateApi(...apisConfigurator(''));
    this.configurationApi = new ConfigurationApi(...apisConfigurator(''));
  }

  @state()
  plantillas: any = [
    {
      name: '',
      id: '12',
      description: '',
    },
  ];

  @query('#name')
  nameInput!: HTMLInputElement;

  @query('#lastName')
  lastNameInput!: HTMLInputElement;

  @state()
  private obj?: any;

  @state()
  DataJson: any = [];

  @state()
  loading = true;

  @state()
  editUser?: User;

  @state()
  private controlPlantilla: Object[] = [
    {
      name: ' ',
    },
  ];

  @state()
  token?: number;

  @state()
  roles: RoleList[] = [];

  @state()
  nombrePlantilla?: string = 'Nombre de la plantilla';

  @state()
  descripcionPlantilla?: string = '';

  @state()
  rolesUsuario: string[] = [];

  @state()
  organizationUsuario: string = '';

  @state()
  aux: string = '';

  @state()
  organizaciones: OrganizationName[] = [];

  @state()
  rol?: string = '';

  @state()
  checks: boolean[] = [];

  @state()
  JsonSchema?: any;

  @state()
  parameters?: Object;

  @state()
  existConfig?: boolean;

  @state()
  validError?: any;

  @state()
  editor?: any;

  @state()
  prueba?: object;

  @state()
  id_plantilla?: string;

  @state()
  organization!: Array<string>;

  private get loaderContainer() {
    return document.getElementById('loader-container') as any;
  }

  private get formData() {
    return document.getElementById('editorHolder') as any;
  }

  private get editorContainer() {
    return document.querySelector(`.je-object__container`) as HTMLInputElement;
  }

  private get collapse() {
    return document.querySelector(`.je-object__title`) as HTMLInputElement;
  }

  private get collapse2() {
    return document.querySelector(
      `.json-editor-btn-edit_properties`
    ) as HTMLInputElement;
  }

  private get collapse3() {
    return document.querySelector(`.json-editor-btn-edit`) as HTMLInputElement;
  }

  private get jeindented() {
    return document.querySelector(`.je-indented-panel`) as HTMLInputElement;
  }

  get jeFormInput() {
    return document.querySelector('.je-form-input-label') as HTMLInputElement;
  }

  idGet(atributo: string) {
    return document.getElementById(atributo) as HTMLInputElement;
  }

  validGet(atributo: string) {
    return document.querySelector(`.${atributo}`) as HTMLInputElement;
  }

  async open(id_plantilla: string) {
    this.id_plantilla = id_plantilla;
    await this.getTemplate(id_plantilla);
    await this.setConfiguration();
    await setTimeout(function () {
      console.log('');
    }, 500);
    console.log('setTimeout() Ejemplo...');

    await this.esconderDiv();
    this.dialog.open = true;
  }

  close() {
    const editorContainer = this.editorContainer;
    editorContainer.remove();
    this.dialog.open = false;
  }

  private get dialog() {
    return document.getElementById('dialog-pre-configuracion')! as any;
  }

  esconderDiv() {
    const collapse = this.collapse;
    const collapse2 = this.collapse2;
    const collapse3 = this.collapse3;
    collapse.style.display = 'none';
    collapse2.style.display = 'none';
    collapse3.style.display = 'none';
    const jeindented = this.jeindented;
    jeindented.setAttribute('style', 'border:none');
    const div: any = jeindented.querySelector('div');
    const div2 = div.querySelector('div');
    div2.setAttribute('class', 'flex flex-wrap my-4 mx-4');
  }

  async firstUpdated() {}

  async getTemplate(id: string) {
    var resTer: AxiosResponse;
    try {
      resTer = await this.templateApi.getTemplate({ id });
      this.plantillas = [
        {
          name: resTer.data.name,
          description: resTer.data.description,
          id,
        },
      ];
      this.JsonSchema = await resTer.data.schema;
      console.log(this.JsonSchema);
    } catch (error) {
    } finally {
      if (!this.plantillas)
        this.plantillas = [
          {
            name: 'plantilla-1',
            id: '12',
            description: 'prueba1',
          },
        ];
    }
  }

  async setConfiguration() {
    const editorContainer = this.editorContainer;
    if (editorContainer) {
      await editorContainer.remove();
    }
    const element = this.formData;
    let properties = this.JsonSchema.properties;
    this.editor = new JSONEditor(element, {
      ajax: true,
      schema: {
        type: 'object',
        title: 'Car',
        properties: properties,
        /*properties: {
          edad: {
            default:2,
            description: "descripcion",
            maximum: "100",
            type: "number"
          },
          genero: {
            default:true,
            description: "",
            type: "boolean"
          },
          nombre: {
            default:"nombre",
            description: "desscripcion",
            maxLength: 255,
            type: "string"
          },
          numero: {
            default:"nomnre",
            description: "",
            maxLength: 5,
            type: "string"
          }
        }*/
      },
    });
  }

  render() {
    return html`
      <ui5-dialog
        id="dialog-pre-configuracion"
        header-text=${'Pre-Configuracion'}
      >
        <main class="w-full flex overflow-auto" style="height: 28rem;">
          <div class="w-full h-full px-5 py-4 flex flex-wrap items-start">
            <div id="editorHolder"></div>
          </div>
          <div class="dialog-footer mt-auto w-full text-center">
            <button
              class="text-red-600 p-4 rounded-md uppercase"
              id="closeDialogButton"
              @click=${() => {
                this.close();
              }}
            >
              Cancelar
            </button>
            <button
              class="text-blue-600 p-4 rounded-md uppercase"
              id="closeDialogButton"
              @click=${() => {
                this.close();
              }}
            >
              Aceptar
            </button>
          </div>
        </main>
      </ui5-dialog>
    `;
  }
}
