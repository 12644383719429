/* eslint-disable prettier/prettier */
import { css, html, LitElement, render, TemplateResult } from 'lit';
import { guard } from 'lit/directives/guard.js';
import { customElement, query, state } from 'lit/decorators.js';

import '@ui5/webcomponents/dist/Tree.js';
import '@ui5/webcomponents/dist/Icon.js';
import '@ui5/webcomponents-icons/dist/it-instance.js';
import '@ui5/webcomponents-icons/dist/group-2.js';
import '@ui5/webcomponents-icons/dist/alert.js';
import '@ui5/webcomponents-icons/dist/cart.js';
import '@ui5/webcomponents-icons/dist/bell.js';
import '@material/mwc-icon';
import '@vaadin/vaadin-grid';

import {
  Grid,
  GridActiveItemChangedEvent,
  GridItemModel,
} from '@vaadin/vaadin-grid';
import router from '../../router/router.js';
import { GroupApi } from '../../../api/api.js';
import store from '../../redux/store.js';
import { Group, GroupList } from '../../../api/index.js';
import { editarGrupo } from '../../redux/actions/index.js';
import { DetallesTerminal } from '../../components/modals/DetallesTerminales.js';
import '../../components/modals/DetallesTerminales.js';

import { apisConfigurator } from '../../utils/axios.js';
@customElement('group-list-page')
export class GroupListPage extends LitElement {
  private groupApi: GroupApi;

  @state()
  private grupos: GroupList[] = [];

  constructor() {
    super();
    this.groupApi = new GroupApi(...apisConfigurator('Grupos'));
  }

  selectMultiple = false;
  delete = false;

  @query('#input-edit')
  editInput!: HTMLInputElement;

  @query('#input-select')
  iconInput!: HTMLSelectElement;

  @state()
  private detailsOpenedItem: GroupList[] = [];
  loader = false;
  protected firstUpdated(): void {
    this.getGroups();
  }
  get DetallesTerminal() {
    return document.getElementById('modal-Terminal') as DetallesTerminal;
  }
  private async getGroups() {
    this.loader = true;

    const res = await this.groupApi.groupList({});

    //console.log(res)
    this.grupos = res.data;
    if (res.data) {
      this.loader = false;
    }
  }
  private MostarTerminales(e: any) {
    //console.log(e)
    this.DetallesTerminal.open(e);
  }
  private async handleDeleteGroup(group: Group) {
    try {
      const res = await this.groupApi.groupDelete({ id: group.id });

      this.grupos = this.grupos.filter(t => t.id !== group.id);
    } catch (err) {}
  }

  private actionRenderer = (root: HTMLElement, _: any, item: any) => {
    ////console.log(item.item);

    render(
      html`
        <mwc-icon-button
          icon="visibility"
          @click="${() => {
            this.MostarTerminales(item.item.id);
          }}"
        ></mwc-icon-button>
        <mwc-icon-button
          icon="edit"
          @click="${() => {
            alert(item.item);
            store.dispatch(editarGrupo(item.item));
            router.cambiarSeccion('edit-group');
          }}"
        ></mwc-icon-button>
        <mwc-icon-button
          icon="delete"
          @click="${() => {
            this.handleDeleteGroup(item.item);
          }}"
        ></mwc-icon-button>
        <mwc-icon-button icon="more_vert"></mwc-icon-button>
      `,
      root
    );
  };

  render() {
    return html`
      <main
        class="w-full h-full flex ${!this.loader
          ? ''
          : 'items-center justify-center'}"
      >
        ${this.loader !== true
          ? html`
              <main class="w-full h-full  flex">
                <vaadin-grid
                  class="h-full"
                  .items="${this.grupos}"
                  .detailsOpenedItems="${this.detailsOpenedItem}"
                  @active-item-changed="${(
                    e: GridActiveItemChangedEvent<GroupList>
                  ) => (this.detailsOpenedItem = [e.detail.value])}"
                  .rowDetailsRenderer="${guard(
                    [],
                    () =>
                      (
                        root: HTMLElement,
                        _: Grid,
                        model: GridItemModel<GroupList>
                      ) => {
                        const person = model.item;

                        render(
                          html`<vaadin-form-layout
                            .responsiveSteps=" ${[
                              { minWidth: '0', columns: 3 },
                            ]}"
                          >
                            <vaadin-text-field
                              label="Grupo"
                              .value="${person.name}"
                              colspan="3"
                              readonly
                            ></vaadin-text-field>
                          </vaadin-form-layout>`,
                          root
                        );
                      }
                  )}"
                >
                  <vaadin-grid-selection-column> </vaadin-grid-selection-column>
                  <vaadin-grid-sort-column
                    path="name"
                    header="Grupo"
                  ></vaadin-grid-sort-column>
                  <vaadin-grid-sort-column
                    path="dueno"
                    header="Dueño"
                  ></vaadin-grid-sort-column>
                  <vaadin-grid-sort-column
                    text-align="center"
                    header="# de terminales"
                    .renderer="${(root: HTMLElement, _: any, item: any) => {
                      render(
                        html`<!-- <p>{item.item.terminales.length}</p> -->`,
                        root
                      );
                    }}"
                  ></vaadin-grid-sort-column>

                  <vaadin-grid-column
                    .headerRenderer="${(root: HTMLElement) => {
                      render(
                        html`<mwc-button
                          outlined
                          class=" rounded"
                          @click=${() => router.cambiarSeccion('create-group')}
                          ><mwc-icon>people</mwc-icon> Crear Grupo</mwc-button
                        >`,
                        root
                      );
                    }}"
                    text-align="end"
                    .renderer="${this.actionRenderer}"
                  >
                  </vaadin-grid-column>
                </vaadin-grid>
                <detalles-modal id="modal-Terminal"> </detalles-modal>
              </main>
            `
          : html`
              <mwc-circular-progress
                indeterminate
                density=${20}
              ></mwc-circular-progress>
            `}
      </main>
    `;
  }

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }
}
