import { ActionTypes } from '../types';
import router from '../../router/router';
import { AppList } from '../../../api';

export const APPP = 'APPP';
export const VER_APP = 'VER_APP';
export const EDITAR_APP = 'EDITAR_APP';
export const GET_APPS = 'GET_APPS';
export const APP_ADDED = 'APP_ADDED';
export const APPS_RECEIVED = 'APPS_RECEIVED';

export function Appp(appp: Object[]): ActionTypes {
  return { type: APPP, appp };
}

export function verApp(verAppId: string): ActionTypes {
  router.cambiarSeccion('view-app');
  return { type: VER_APP, verAppId };
}

export function editarApp(app: any): ActionTypes {
  return { type: EDITAR_APP, app };
}

export function getApps(): ActionTypes {
  return { type: GET_APPS, loading: true };
}

export function appAdded(newApp: AppList): ActionTypes {
  return { type: APP_ADDED, newApp };
}
