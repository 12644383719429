/* tslint:disable */
/* eslint-disable */
/**
 * TMS Agnostiko
 * Tiene la definición de todos los servicios de TMS a Agnostic (para uso en la web o externo)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { Message } from '../models';
// @ts-ignore
import { Widget } from '../models';
/**
 * WidgetApi - axios parameter creator
 * @export
 */
export const WidgetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Consultar los widgets activos
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWidget: async (acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/widget`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WidgetApi - functional programming interface
 * @export
 */
export const WidgetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WidgetApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Consultar los widgets activos
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllWidget(acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Widget>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllWidget(acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WidgetApi.getAllWidget']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * WidgetApi - factory interface
 * @export
 */
export const WidgetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WidgetApiFp(configuration)
    return {
        /**
         * 
         * @summary Consultar los widgets activos
         * @param {WidgetApiGetAllWidgetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWidget(requestParameters: WidgetApiGetAllWidgetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<Widget>> {
            return localVarFp.getAllWidget(requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAllWidget operation in WidgetApi.
 * @export
 * @interface WidgetApiGetAllWidgetRequest
 */
export interface WidgetApiGetAllWidgetRequest {
    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof WidgetApiGetAllWidget
     */
    readonly acceptLanguage?: string
}

/**
 * WidgetApi - object-oriented interface
 * @export
 * @class WidgetApi
 * @extends {BaseAPI}
 */
export class WidgetApi extends BaseAPI {
    /**
     * 
     * @summary Consultar los widgets activos
     * @param {WidgetApiGetAllWidgetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetApi
     */
    public getAllWidget(requestParameters: WidgetApiGetAllWidgetRequest = {}, options?: AxiosRequestConfig) {
        return WidgetApiFp(this.configuration).getAllWidget(requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}

