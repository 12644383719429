/* eslint-disable prettier/prettier */
import { html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';

import '@material/mwc-tab';
import '@material/mwc-tab-bar';
import '@material/mwc-list/mwc-list.js';
import '@material/mwc-list/mwc-list-item.js';
import '@material/mwc-slider';
import '@material/mwc-switch';
import '@material/mwc-select';
import '@material/mwc-circular-progress';

@customElement('terminal-page')
export class TerminalPage extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  // terminalApi = new TerminalApi();

  @state()
  private selectedTab = 0;

  @state()
  terminal?: any;
  loader = false;
  onTabChange(e: CustomEvent) {
    this.selectedTab = e.detail.index;
  }

  protected firstUpdated(): void {
    console.log('TerminalPage::firstUpdated');

    setTimeout(() => {
      this.terminal = {
        model: 'Terminal1-serial',
        brand: 'Terminal1-marca',
        serial: 'Terminal1-modelo',
        state: 'apps2go',
        owner: 'apps2go ',
      };
    }, 750);
    /* }); */
  }

  render() {
    return html`
      <main
        class="w-full min-h-full ${!this.terminal
          ? ''
          : 'flex items-center justify-center'}"
      >
        ${!this.terminal
          ? html`
              <div class="w-full px-5 py-4 flex flex-wrap items-start h-full">
                <section
                  class="w-full relative max-h-64 flex flex-wrap overflow-auto mb-2 bg-white shadow-md rounded-md pt-5 px-4 border border-solid border-slate-300"
                >
                  <div class="flex flex-col">
                    <mwc-icon class="icon-primary icon-terminal"
                      >point_of_sale</mwc-icon
                    >
                    <b class="text-lg">Terminal1</b>
                  </div>
                  <div class="flex flex-col flex-wrap justify-between ml-4">
                    <p class="my-1"><b>Serial: </b>${this.terminal?.serial}</p>
                    <p class="my-1"><b>Marca: </b>${this.terminal?.brand}</p>
                    <p class="my-1"><b>Modelo: </b>${this.terminal?.model}</p>
                    <p class="my-1">
                      <b>Organización: </b>${this.terminal?.owner}
                    </p>
                    <p class="my-1"><b>Dueño: </b>${this.terminal?.owner}</p>
                  </div>

                  <div
                    class="flex flex-col ml-auto mr-2 items-end justify-start"
                  >
                    <button
                      class="rounded-full text-white bg-yellow-400 p-2 px-3 flex items-center uppercase hover:shadow-md hover:bg-yellow-500"
                    >
                      <mwc-icon>update</mwc-icon>
                    </button>
                  </div>
                  <div class="w-full">
                    <mwc-tab-bar
                      activeIndex=${this.selectedTab}
                      @MDCTabBar:activated=${this.onTabChange}
                      class="flex"
                    >
                      <mwc-tab label="Información" icon="info"></mwc-tab>
                      <mwc-tab
                        label="Configuraciones"
                        icon="settings"
                      ></mwc-tab>
                      <mwc-tab label="" icon="camera"></mwc-tab>
                    </mwc-tab-bar>
                  </div>
                </section>
                ${this.selectedTab === 0
                  ? html`
                      <div class="w-full flex mb-2">
                        <section class="w-4/12 pr-4">
                          <div
                            class="w-full h-full bg-white shadow-md rounded-md py-5 px-4 border border-solid border-slate-300"
                          >
                            <h2
                              class="text-2xl text-blue-600 font-bold uppercase"
                            >
                              Información general
                            </h2>
                            <div
                              class="flex p-3 items-center justify-around terminal-stats flex-wrap"
                            >
                              <b class="flex items-center mx-2"
                                ><mwc-icon>battery_charging_full</mwc-icon
                                >80%</b
                              >
                              <b class="flex items-center mx-2"
                                ><mwc-icon>memory</mwc-icon>30%</b
                              >
                              <b class="flex items-center mx-2"
                                ><mwc-icon>laptop</mwc-icon>40%</b
                              >
                            </div>
                            <div>
                              <p><b>Version firmware:</b> 1.0.0</p>
                              <p><b>Version </b> 1.0.0</p>
                            </div>
                          </div>
                        </section>

                        <section class="w-4/12">
                          <div
                            class="w-full h-full bg-white shadow-md rounded-md py-5 px-4 border border-solid border-slate-300"
                          >
                            <h2
                              class="text-2xl text-blue-600 font-bold uppercase"
                            >
                              Ubicación
                            </h2>
                            <img
                              class="w-full mt-2"
                              src="https://media.wired.com/photos/59269cd37034dc5f91bec0f1/191:100/w_1280,c_limit/GoogleMapTA.jpg"
                            />
                          </div>
                        </section>

                        <section class="w-4/12 pl-4">
                          <div
                            class="w-full h-full bg-white shadow-md rounded-md py-5 px-4 border border-solid border-slate-300"
                          >
                            <h2
                              class="text-2xl uppercase font-bold text-blue-600"
                            >
                              Apps
                            </h2>
                            <ul class="list-none flex flex-wrap">
                              <li class="my-2 mx-3">
                                <img
                                  class="w-8"
                                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Google_Play_Arrow_logo.svg/1200px-Google_Play_Arrow_logo.svg.png"
                                />
                                <div class="flex flex-col">
                                  <b>Play Store</b>
                                  <p class="text-sm">Version: 4.5.2</p>
                                </div>
                              </li>
                              <li class="my-2 mx-3">
                                <img
                                  class="w-8"
                                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Google_Play_Arrow_logo.svg/1200px-Google_Play_Arrow_logo.svg.png"
                                />
                                <div class="flex flex-col">
                                  <b>Play Store</b>
                                  <p class="text-sm">Version: 4.5.2</p>
                                </div>
                              </li>
                              <li class="my-2 mx-3">
                                <img
                                  class="w-8"
                                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Google_Play_Arrow_logo.svg/1200px-Google_Play_Arrow_logo.svg.png"
                                />
                                <div class="flex flex-col">
                                  <b>Play Store</b>
                                  <p class="text-sm">Version: 4.5.2</p>
                                </div>
                              </li>
                              <li class="my-2 mx-3">
                                <img
                                  class="w-8"
                                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Google_Play_Arrow_logo.svg/1200px-Google_Play_Arrow_logo.svg.png"
                                />
                                <div class="flex flex-col">
                                  <b>Play Store</b>
                                  <p class="text-sm">Version: 4.5.2</p>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </section>
                      </div>
                    `
                  : null}
                ${this.selectedTab === 1
                  ? html`
                      <div class="w-full flex mb-2">
                        <section class="w-6/12 pr-2">
                          <div
                            class="w-full h-full bg-white shadow-md rounded-md py-5 px-4 border border-solid border-slate-300"
                          >
                            <h2
                              class="text-2xl text-blue-600 font-bold uppercase"
                            >
                              Configuraciones generales
                            </h2>
                            <div class="">
                              <mwc-list>
                                <mwc-list-item twoline hasMeta>
                                  <b>Ubicacion GPS</b>
                                  <small slot="secondary" class="text-sm"
                                    >Activar el GPS del terminal</small
                                  >
                                  <mwc-switch slot="meta"></mwc-switch>
                                </mwc-list-item>
                                <mwc-list-item
                                  twoline
                                  hasMeta
                                  class="w-full relative"
                                >
                                  <b>Brillo</b>
                                  <div
                                    class="w-40 absolute right-0"
                                    slot="meta"
                                  >
                                    <mwc-slider
                                      step="1"
                                      max="100"
                                      value="68"
                                    ></mwc-slider>
                                  </div>
                                  <small slot="secondary" class="text-sm"
                                    >Configurar brillo del terminal</small
                                  >
                                </mwc-list-item>
                                <mwc-list-item
                                  twoline
                                  hasMeta
                                  class="w-full relative"
                                >
                                  <b>Volumen</b>
                                  <div
                                    class="w-40 absolute right-0"
                                    slot="meta"
                                  >
                                    <mwc-slider
                                      step="1"
                                      max="100"
                                      value="68"
                                    ></mwc-slider>
                                  </div>
                                  <small slot="secondary" class="text-sm"
                                    >Configurar volumen del terminal</small
                                  >
                                </mwc-list-item>
                                <mwc-list-item
                                  twoline
                                  hasMeta
                                  class="w-full relative max-h-20"
                                >
                                  <b>Lenguaje</b>
                                  <div
                                    class="w-40 absolute right-0"
                                    slot="meta"
                                  >
                                    <select class="w-40 p-2">
                                      <option value="0">Español</option>
                                      <option value="1">English</option>
                                    </select>
                                  </div>
                                  <small slot="secondary" class="text-sm"
                                    >Configurar lenguaje del terminal</small
                                  >
                                </mwc-list-item>
                              </mwc-list>
                            </div>
                          </div>
                        </section>
                        <section class="w-6/12 pl-2">
                          <div
                            class="w-full h-full bg-white shadow-md rounded-md py-5 px-4 border border-solid border-slate-300"
                          >
                            <h2
                              class="text-2xl text-blue-600 font-bold uppercase"
                            >
                              Configuraciones de Hardware
                            </h2>
                            <div class="">
                              <mwc-list>
                                <mwc-list-item hasMeta graphic="avatar">
                                  <b>Impresora</b>
                                  <mwc-icon slot="graphic">printer</mwc-icon>
                                  <mwc-switch slot="meta"></mwc-switch>
                                </mwc-list-item>
                                <mwc-list-item hasMeta graphic="avatar">
                                  <b>Bluetooth</b>
                                  <mwc-icon slot="graphic">bluetooth</mwc-icon>
                                  <mwc-switch slot="meta"></mwc-switch>
                                </mwc-list-item>
                                <mwc-list-item hasMeta graphic="avatar">
                                  <b>Conexión Ethernet</b>
                                  <mwc-icon slot="graphic">cable</mwc-icon>
                                  <mwc-switch slot="meta"></mwc-switch>
                                </mwc-list-item>
                              </mwc-list>
                            </div>
                          </div>
                        </section>
                      </div>

                      <section class="w-4/12 pr-2 mt-2">
                        <div
                          class="w-full h-full bg-white shadow-md rounded-md py-5 px-4 border border-solid border-slate-300"
                        >
                          <h2
                            class="text-2xl text-blue-600 font-bold uppercase"
                          >
                            Acciones al terminal
                          </h2>

                          <div class="flex flex-wrap">
                            <button
                              class="rounded-md text-white bg-green-500 px-4 py-2 m-4 uppercase hover:shadow-md hover:bg-green-600"
                            >
                              Enviar Mensaje
                            </button>
                            <button
                              class="rounded-md text-white bg-yellow-500 px-4 py-2 m-4 uppercase hover:shadow-md hover:bg-yellow-600"
                            >
                              Instalar App
                            </button>
                            <button
                              class="rounded-md text-white bg-green-500 px-4 py-2 m-4 uppercase hover:shadow-md hover:bg-green-600"
                            >
                              Reiniciar
                            </button>
                            <button
                              class="rounded-md text-white bg-red-500 px-4 py-2 m-4 uppercase hover:shadow-md hover:bg-red-600"
                            >
                              Bloquear
                            </button>
                          </div>
                        </div>
                      </section>
                    `
                  : null}
              </div>
            `
          : html`
              <mwc-circular-progress
                indeterminate
                density=${20}
              ></mwc-circular-progress>
            `}
      </main>
    `;
  }
}
