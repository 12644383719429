/* eslint-disable prettier/prettier */
import { html, LitElement, PropertyValueMap } from 'lit';
import { customElement, query, state } from 'lit/decorators.js';

import '@material/mwc-tab';
import '@material/mwc-tab-bar';
import '@material/mwc-list/mwc-list.js';
import '@material/mwc-list/mwc-list-item.js';
import '@material/mwc-switch';
import '@material/mwc-textarea';
import '@material/mwc-button';
import '@material/mwc-select';
import { apisConfigurator } from '../../utils/axios';
import {
  AppApi, 
  AppApiCreateAppRequest,
  AppList,
  RequestAppCreate,
} from '../../../api';
import { errorNotification } from '../../services/notifications';
import router from '../../router/router';
import { TextField } from '@material/mwc-textfield';
import store from '../../redux/store';
import { appAdded } from '../../redux/actions/app';

@customElement('create-app-page')
export class CreateAppPage extends LitElement {
  private appApi: AppApi;

  @state()
  private loading = false;

  @state()
  private typeFolio:any = [] ;

  @state()
  private folioTypeId:any = "" ;

  get name() {
    return document.getElementById('name') as TextField;
  }

  get packageName() {
    return document.getElementById('packageName') as HTMLInputElement;
  }

  get descripcion() {
    return document.getElementById('description') as HTMLInputElement;
  }

  get briefDescription() {
    return document.getElementById('briefDescription') as HTMLInputElement;
  }

  get developer() {
    return document.getElementById('developer') as HTMLInputElement;
  }

  get emailDeveloper() {
    return document.getElementById('emailDeveloper') as HTMLInputElement;
  }

  constructor() {
    super();
    this.appApi = new AppApi(...apisConfigurator('Crear App'));
  }

  protected firstUpdated(){
    this.typeFolio = [
      {
        name:"Random"
      },
      {
        name:"Incremental"
      },
      {
        name:"External"
      }
    ]
  }

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  buildApp(): RequestAppCreate {
    return {
      name: this.name.value,
      packageName: this.packageName.value,
      description: this.descripcion.value,
      briefDescription: this.briefDescription.value,
      developer: this.developer.value,
      emailDeveloper: this.emailDeveloper.value,
      folioTypeId:this.folioTypeId
    };
  }

  async onSubmit(ev: Event) {
    ev.preventDefault();
    this.loading = true;

    if (!this.validateForm()) {
      this.loading = false;
      return;
    }

    const data: AppApiCreateAppRequest = {
      requestAppCreate: this.buildApp(),
    };
    console.log(data)
    try {
      const res = await this.appApi.createApp(data);

      const newApp: AppList = { ...data.requestAppCreate, id: res.data.id };
      store.dispatch(appAdded(newApp));
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  }

  validateForm() {
    let x = document.forms.namedItem('createAppForm')!;

    if (!x.checkValidity()) {
      errorNotification('Por favor, revise los datos.');
      return false;
    }

    if (!this.name.checkValidity()) {
      errorNotification('El nombre de la app es requerido.');
      return false;
    }

    if (!this.packageName.checkValidity()) {
      errorNotification('El nombre del paquete de la app es requerido.');
      return false;
    }

    if (!this.developer.checkValidity()) {
      errorNotification('El desarrollador de la app es requerido.');
      return false;
    }

    if (!this.emailDeveloper.checkValidity()) {
      errorNotification('El correo del desarrollador es requerido.');
      return false;
    }

    if (!this.briefDescription.checkValidity()) {
      errorNotification('Ingresa una descripcion corta valida.');
      return false;
    }

    if (!this.descripcion.checkValidity()) {
      errorNotification('Ingresa una descripcion valida.');
      return false;
    }

    return true;
  }

  render() { 
    return html`
      <main class="w-full min-h-full">
        <div class="w-full px-5 py-4 flex flex-wrap items-start">
          <form
            name="createAppForm"
            @submit=${(e: any) => this.onSubmit(e)}
            class="w-full relative flex flex-col flex-wrap overflow-auto mb-2 bg-white shadow-md rounded-md pt-5 px-4 border border-solid border-slate-300"
          >
            <h2 class="text-2xl text-blue-600 font-bold uppercase">
              Subir App
            </h2>
            <div class="flex flex-wrap my-4 mx-4">
              <div class="mx-2">
                <mwc-textfield
                  required
                  autoValidate
                  outlined
                  iconTrailing="close"
                  label="Nombre de la App"
                  id="name"
                  minLength="3"
                  class="mx-2"
                  validationMessage="El nombre debe tener al menos 3 caracteres"
                ></mwc-textfield>
              </div>
              <div class="mx-2">
                <mwc-textfield
                  required
                  autoValidate
                  outlined
                  iconTrailing="close"
                  label="Nombre del paquete"
                  id="packageName"
                  minLength="3"
                  class="mx-2"
                  validationMessage="El nombre debe tener al menos 3 caracteres"
                ></mwc-textfield>
              </div>
              <div class="mx-2">
                <mwc-textfield
                  required
                  autoValidate
                  outlined
                  iconTrailing="close"
                  id="developer"
                  label="Desarrollador"
                  min="3"
                  class="mx-2"
                  validationMessage="El nombre debe tener al menos 3 caracteres"
                ></mwc-textfield>
              </div>
              <div class="mx-2">
                <mwc-textfield
                  required
                  autoValidate
                  outlined
                  iconTrailing="close"
                  id="emailDeveloper"
                  label="Email desarrollador"
                  class="mx-2"
                  type="email"
                  validationMessage="El nombre debe tener al menos 3 caracteres"
                ></mwc-textfield>
              </div>

              <div class="mx-2 w-4/12">
                <mwc-textarea
                  required
                  class="w-full"
                  validationMessage="La descripcion corta debe tener al menos 4 caracteres"
                  label="Descripción Corta de la App"
                  id="briefDescription"
                  minLength="4"
                  autoValidate
                  outlined
                >
                </mwc-textarea>
              </div>
              <div class="mx-2 w-4/12">
                <mwc-textarea
                  required
                  class="w-full"
                  validationMessage="La descripcion debe tener al menos 12 caracteres"
                  label="Descripción de la App"
                  id="description"
                  minLength="12"
                  autoValidate
                  outlined
                > 
                </mwc-textarea>
              </div>
              <div class="mx-2 TexField">
                <custom-select
                  label="Tipo Folio"
                  id="folioTypeId"
                  .options=${this.typeFolio.map((m:any) => ({
                    label: m.name,
                    value: m.name,
                  }))}
                  @change=${(ev: CustomEvent) => {
                    //this.brandSelected = ev.detail;
                    //this.bandBatch= false;
                    this.folioTypeId = ev.detail;
                  }}
                ></custom-select>
              </div>
            </div>
            <div class="flex justify-start">
            <button
              @click="${() => router.cambiarSeccion('apps-list')}"
              id="cancelarCrearAPP"
              class="w-48 rounded-md border-none bg-red-600 text-white py-3 text-xl mt-4 mx-auto hover:bg-red-700 hover:shadow-lg mb-4">
              CANCELAR
            </button> 
            <button
              type="submit"
              id="guardarCrearAPP"
              class="w-48 rounded-md border-none bg-blue-600 text-white py-3 text-xl mt-4 mx-auto hover:bg-blue-700 hover:shadow-lg mb-4"
              .disabled=${this.loading}
            >
              Guardar App
            </button>

            </div>

          </form>
        </div>
      </main>
    `;
  }
}
