/* eslint-disable prettier/prettier */
import { html, LitElement, PropertyValueMap } from 'lit';
import { customElement, query, state } from 'lit/decorators.js';

import '@material/mwc-tab';
import '@material/mwc-tab-bar';
import '@material/mwc-list/mwc-list.js';
import '@material/mwc-list/mwc-list-item.js';
import '@material/mwc-switch';
import '@material/mwc-textarea';
import '@material/mwc-button';
import '@material/mwc-select';
import Swal from 'sweetalert2';
import { apisConfigurator } from '../../utils/axios';
import {
  AppApi, 
  FileApiCreateFileRequest,
  AppList,
  FileApi,
  RequestFileCreate,
  AffiliationApi,
  FileApiUpdateFileRequest,
  RequestAffiliationCreate,
  RequestFileUpdate
} from '../../../api';
import { errorNotification } from '../../services/notifications';
import router from '../../router/router';
import { TextField } from '@material/mwc-textfield';
import store from '../../redux/store';
import { appAdded } from '../../redux/actions/app';

@customElement('edit-file-page')
export class EditarFilePage extends LitElement {
  private fileApi: FileApi;

  @state()
  private loading = false;

  @state()
  private appId:string = "";

  @state()
  private typeFolio:any = [] ;

  @state()
  private preConfig: any[] = []

  @state()
  app?: any;

  @state()
  FileType?: any;

  @state()
  FileId?: any;

  @state()
  Description?: any;

  @state()
  private apps: AppList[] = [];

  @state()
  private folioTypeId:any = "" ;

  get fileId() {
    return document.getElementById('fileId') as TextField;
  }

  get businessId() {
    return document.getElementById('businessId') as HTMLInputElement;
  }

  get name() {
    return document.getElementById('name') as HTMLInputElement;
  }

  get description() {
    return document.getElementById('description') as HTMLInputElement;
  }

  get appSelected() {
    return document.getElementById('appSelect') as any;
  }

  get fileType() {
    return document.getElementById('fileType') as HTMLInputElement;
  }

  get contactPhone() {
    return document.getElementById('contactPhone') as HTMLInputElement;
  }

  get arf() {
    return document.getElementById('RFC') as HTMLInputElement;
  }

  constructor() {
    super();
    this.fileApi = new FileApi(...apisConfigurator('File'));
  }

  protected firstUpdated(){
    let appId:string = localStorage.getItem('appId') ?? "";
    let file:string = localStorage.getItem('file') ?? "";
    let data:any = JSON.parse(file);
    console.log(data)
    this.appId = appId
    this.Description = data.description;
    this.FileId = data.id;
    this.FileType = data.fileType;
    //this.getApps()
  }

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  buildApp(): RequestFileUpdate {
    return {
      description: this.description.value,
      fileType: this.fileType.value,
    };
  }

  async onSubmit(ev: Event) {
    ev.preventDefault();
    this.loading = true;

    const data: FileApiUpdateFileRequest = {
        fileId: this.fileId.value,
        appId:this.appId,
        requestFileUpdate: this.buildApp(),
    };

    console.log(data)
    try {
      const res = await this.fileApi.updateFile(data);
      if (res.status == 200) {
        await Swal.fire('Agregado!', 'El archivo ha sido Actualizado', 'success');
        router.cambiarSeccion('file-list');
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  }

  render() { 
    return html`
      <main class="w-full min-h-full">
        <div class="w-full px-5 py-4 flex flex-wrap items-start">
          <form
            name="createAppForm"
            @submit=${(e: any) => this.onSubmit(e)}
            class="w-full relative flex flex-col flex-wrap overflow-auto mb-2 bg-white shadow-md rounded-md pt-5 px-4 border border-solid border-slate-300"
          >
            <h2 class="text-2xl text-blue-600 font-bold uppercase">
              Editar Archivo
            </h2>
            <div class="flex flex-wrap my-4 mx-4">
              <div class="mx-2">
                <mwc-textfield
                  required
                  autoValidate
                  outlined
                  iconTrailing="close"
                  label="File"
                  id="fileId"
                  disabled
                  .value=${this.FileId ?? ''}
                  minLength="3"
                  class="mx-2"
                  validationMessage="El id debe tener al menos 3 caracteres"
                ></mwc-textfield>
              </div> 
              <div class="mx-2">
                <mwc-textfield
                  required
                  autoValidate
                  outlined
                  iconTrailing="close"
                  id="description"
                  .value=${this.Description ?? ''}
                  label="Descripcion"
                  min="3"
                  class="mx-2"
                  validationMessage="El nombre debe tener al menos 3 caracteres"
                ></mwc-textfield>
              </div>
              <div class="mx-2">
                <mwc-textfield
                  required
                  autoValidate
                  outlined
                  iconTrailing="close"
                  id="fileType"
                  .value=${this.FileType ?? ''}
                  label="Tipo"
                  class="mx-2"
                  validationMessage="El nombre debe tener al menos 3 caracteres"
                ></mwc-textfield>
              </div>

            </div>
            <div class="flex justify-start">
            <button
              @click="${() => router.cambiarSeccion('file-list')}"
              id="cancelarCrearAPP"
              class="w-48 rounded-md border-none bg-red-600 text-white py-3 text-xl mt-4 mx-auto hover:bg-red-700 hover:shadow-lg mb-4">
              CANCELAR
            </button> 
            <button
              type="submit"
              id="guardarCrearAPP"
              class="w-48 rounded-md border-none bg-blue-600 text-white py-3 text-xl mt-4 mx-auto hover:bg-blue-700 hover:shadow-lg mb-4"
              .disabled=${this.loading}
            >
              Guardar
            </button>

            </div>

          </form>
        </div>
      </main>
    `;
  }
}
