/* eslint-disable prettier/prettier */
import { html, LitElement, render } from 'lit';
import { customElement, query, state, property } from 'lit/decorators.js';
import Swal from 'sweetalert2';
//import  JSONEditor  from 'json-editor';
//import * as JSONEditor from "json-editor";
//import JSONEditor = require("json-editor");
import '@material/mwc-icon-button';
import '@material/mwc-list/mwc-list.js';
import '@material/mwc-list/mwc-list-item.js';
import '@material/mwc-list/mwc-list.css';
import '@material/mwc-list/mwc-list-item.css';
import '@vaadin/vaadin-grid';
import router from '../../router/router.js';
import { TemplateApi } from '../../../api/api.js';
import { CrearPreConfiguracionModals } from '../../components/modals/CrearPreConfiguracionModals';
import '../../components/modals/CrearPreConfiguracionModals';
import { AxiosResponse } from 'axios';
import { InsertarPropiedad } from '../../utils/functions';
import { idPlantilla } from '../../redux/actions';
import store from '../../../src/redux/store';
import { State } from '../../../src/redux/types';
import { apisConfigurator } from '../../utils/axios.js';
import { JSONEditor } from '@json-editor/json-editor';

@customElement('pre-configuracion')
export class PreConfiguracion extends LitElement {
  private templateApi: TemplateApi;

  private constructor() {
    super();
    this.templateApi = new TemplateApi(...apisConfigurator(''));
  }

  get preConfiguracionModal() {
    return document.getElementById(
      'preConfiguracionModal'
    ) as CrearPreConfiguracionModals;
  }

  get idCampo() {
    return document.querySelector('#campo')! as HTMLInputElement;
  }

  @state()
  private brandSelected?: string[] = ['string', 'integer', 'object'];

  @query('#campos')
  private campos!: HTMLInputElement;

  @query('#descripcion')
  private descripcion!: HTMLInputElement;

  @state()
  private obj?: any;

  @state()
  private camp?: string[] = [];

  @state()
  private tipo?: any;

  @state()
  private check?: boolean = false;

  @state()
  private activeButton?: boolean = false;

  @state()
  loader = true;

  @state()
  id_plantilla: string = '';

  @state()
  Plantill = { nombre: 'wladimir', uso: '12', descripcion: 'prueba1' };
  @state()
  plantillas: Object[] = [];

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  createSchema() {
    console.log(InsertarPropiedad(this.obj));
  }

  validateSchema() {
    /*const ajv = new Ajv() 

    let schema = InsertarPropiedad(this.obj)
    const schema = {
      type: "object",
      properties: {
        foo: {type: "integer"},
        bar: {type: "string"}
      }, 
      required: ["foo"],
      additionalProperties: false
    }
    const validate = ajv.compile(schema)

    const data = {
      name: 1
    }

    const valid = validate(data)
    if (!valid) console.log(validate.errors)

    console.log(valid)*/
    const schemaJson = {
      type: 'object',
      properties: {
        foo: { type: 'integer' },
        bar: { type: 'string' },
      },
      required: ['foo'],
      additionalProperties: false,
    };
    var element = this.idCampo;

    var editor = new JSONEditor(element, {
      //...
      schema: schemaJson,
    });

    editor.setValue({ foo: 2 });

    var value = editor.getValue();
    //console.log(value)
  }

  async insertar() {
    this.obj.properties.push({
      campo: this.campos.value,
      descripcion: this.descripcion.value,
      tipo: this.tipo,
    });
    if (this.check) this.obj.required.push(this.campos.value);

    Swal.fire('Agregado!', 'El tipo de dato registrado', 'success');
  }

  protected firstUpdated(): void {
    localStorage.removeItem('id_plantilla');
    localStorage.removeItem('id_saved_configuration');
    this.getTemplate();
    this.obj = {
      properties: [],
      required: [],
    };

    const arbol = {
      type: 'object',
      properties: {
        foo: { type: 'integer' },
        bar: { type: 'string' },
      },
      required: ['foo'],
      additionalProperties: false,
    };

    for (var clave in arbol.properties) {
      //console.log(clave)
      this.camp?.push(clave);
    }
  }

  async getTemplate() {
    this.loader = true;
    var resTer: AxiosResponse;
    try {
      resTer = await this.templateApi.listTemplate({});
      console.log(resTer);
      this.plantillas = resTer.data;
    } catch (error) {
    } finally {
      if (!this.plantillas)
        this.plantillas = [
          {
            name: 'plantilla-1',
            id: '12',
            description: 'prueba1',
          },
        ];
      //console.log(this.terminals);
      this.loader = false;
    }
  }

  render() {
    return html`
      <!-- <div id="loader-container">
        <div class="spinner"></div>
      </div> -->
      <main class="w-full h-full FondoColor flex ">
        <section class="w-3/4 flex flex-wrap p-4 SectionList rounded-l-lg">
          <vaadin-grid class="h-full" .items="${this.plantillas}">
            <vaadin-grid-sort-column
              .renderer="${(root: HTMLElement, _: any, item: any) => {
                render(
                  html`
                    <!-- <mwc-checkbox
                id="terminalBox"
                value="requerido"
                .checked="${false}"
              ></mwc-checkbox>   -->
                    <input
                      class="s-c bottom"
                      type="radio"
                      name="platform"
                      value=${true}
                      @change="${() => {
                        this.activeButton = true;
                        this.id_plantilla = item.item.id;
                        console.log(this.id_plantilla);
                        store.dispatch(idPlantilla(this.id_plantilla));
                      }}"
                    />
                  `,
                  root
                );
              }}"
            >
            </vaadin-grid-sort-column>
            <vaadin-grid-sort-column
              path="name"
              header="Nombre"
            ></vaadin-grid-sort-column>
            <vaadin-grid-sort-column
              header="En uso"
              .renderer="${(root: HTMLElement, _: any, item: any) => {
                render(html`####`, root);
              }}"
            ></vaadin-grid-sort-column>
            <vaadin-grid-sort-column
              path="description"
            ></vaadin-grid-sort-column>
          </vaadin-grid>
        </section>
        <section class="w-1/4  SectionDetail justify-center scroll">
          <div class="w-full flex justify-center">
            ${this.activeButton
              ? html`
                  <mwc-button
                    class="rounded w-4/5 mx-auto mt-4 mb-2"
                    style="--mdc-theme-primary: #2E3EAE;"
                    raised
                    icon="add_circle"
                    @click=${() => {
                      //console.log(this.id_plantilla)
                      //this.preConfiguracionModal.open(this.id_plantilla);
                      localStorage.setItem('id_plantilla', this.id_plantilla);
                      router.cambiarSeccion('create-pre-configuration');
                    }}
                  >
                    Crear Plantilla</mwc-button
                  >
                `
              : ``}
          </div>
        </section>
      </main>
      <pre-configuracion-modals
        id="preConfiguracionModal"
      ></pre-configuracion-modals>
    `;
  }
}
