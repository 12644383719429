/* eslint-disable prettier/prettier */

import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '@ui5/webcomponents/dist/Dialog.js';
import '@vaadin/vaadin-select';
import {
  CommunicationTerminalApi,
  CommunicationTerminalApiRequestCommandRequest,
  RequestCommandTypeCommandEnum,
  Terminal,
} from '../../../api';
import { errorNotification } from '../../services/notifications';
import { apisConfigurator } from '../../utils/axios';
import {DateTime}  from 'luxon';
import ct from 'countries-and-timezones';

@customElement('enviar-mensaje-modal')
export class EnviarMensajeModal extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private communicationApi: CommunicationTerminalApi;

  constructor() {
    super();

    this.communicationApi = new CommunicationTerminalApi(
      ...apisConfigurator('Comunicación')
    );
  }

  @property({ type: Object })
  terminal?: Terminal;

  @state()
  private titulo = '';

  @state()
  private mensaje = '';

  @state()
  private sourceId = '';

  @state()
  private fecha = '';

  @state()
  private time = '';

  @state()
  private source = true;

  @state()
  private type: Object[] = [{id:1,name:"text-popup"},{id:2,name:"Video"},{id:3,name:"Imagen"},{id:4,name:"text-notification"},{id:5,name:"printed"}];

  private get dialog() {
    return document.getElementById('dialog-enviar-mensaje')! as any;
  }

  get selectType() {
    return document.getElementById('select-type')! as any;
  }

  protected firstUpdated(): void {}

  async getOrganizaciones() {}

  async open(terminal: Terminal) {
    if (!terminal) {
      errorNotification('Error al editar terminal');
      return;
    }

    this.getOrganizaciones();

    this.terminal = terminal;
    this.dialog.open = true;
  }

  close() { 
    this.dialog.open = false;
    this.terminal = undefined;
  }

  private async handleSubmit(e: Event) {
    /*** datetime */
    let fechaActual = new Date();
    if(this.fecha!==""){
      const fechaHoraCompletaString = `${this.fecha} ${this.time}`;
      fechaActual = new Date(fechaHoraCompletaString);
    }else{
      fechaActual = new Date();
    }
    const fecha = new Date(fechaActual);
    const [hoursString, minutesString] = this.time.split(':');
    const hours = parseInt(hoursString);
    const minutes = parseInt(minutesString);
    console.log(fecha.getMonth.toString())
    const dateTime = DateTime.fromObject({ year: parseInt(this.fecha.substr(0, 4)), month: parseInt(this.fecha.substr(5, 2)), day: parseInt(this.fecha.substr(8, 2)), hour: hours, minute: minutes }).setZone('utc');
    console.log(dateTime.toString())
    const fechaISO8601 = dateTime.toString();
    /***  fin datetime */
    e.preventDefault();

    if (!this.terminal) return;
    const requestCommand: CommunicationTerminalApiRequestCommandRequest = {
      brand: this.terminal.brand,
      serial: this.terminal.serial,
      requestCommand: {
        commandId: `displayMessage-${this.terminal.serial}-${Date.now()}`,
        typeCommand: RequestCommandTypeCommandEnum.DisplayMessage,
        parameters: [
          this.titulo,
          this.mensaje ?? this.titulo,
          this.selectType.value,
          this.selectType.value!=="Video" || this.selectType.value!=="Imagen" ? this.sourceId : null,
          fechaISO8601
        ],
      },
    };
    try {
      this.close();
      const res = await this.communicationApi.requestCommand(requestCommand);
      //console.log('envio mensaje res', res);
    } catch (err: any) {
      console.log('envio mensaje err', err);
    }
  }

  protected render() {
    return html` <ui5-dialog
      id="dialog-enviar-mensaje"
      header-text="Enviar mensaje a ${this.terminal?.brand} - ${this.terminal
        ?.serial}"
      class="w-3/12"
    >
      <form
        class="px-4 flex flex-col items-center"
        @submit=${this.handleSubmit}
      >
        <div class="mx-2 w-full flex flex-col items-center">
          <mwc-textfield
            class="my-2 w-3/4"
            outlined
            label="Titulo"
            @change=${(e: any) => (this.titulo = e.target.value)}
            required
          ></mwc-textfield>
          <div class="flex justify-end">
          <select id="select-type" style="width: 100%"
          @change=${(ev: CustomEvent) => {
            if(this.selectType.value!=="Video" || this.selectType.value!=="Imagen"){
              console.log(this.selectType.value!)
              this.source=!this.source;
            }
          }}>
          <option disabled>Selecciona el Tipo de mensaje</option>
          ${this.type?.map(
            (camp: any, index: number) => html` 
              <option value="${camp.name}">${camp.name}</option>
            `
          )}
          </select> 
          </div>
          <mwc-textfield
          class="my-2 w-3/4"
          outlined
          .disabled=${this.source}
          @change=${(e: any) => console.log(this.sourceId = e.target.value)}
          label="sourceId"
        ></mwc-textfield> 
          <mwc-textfield
            class="my-2 w-3/4"
            outlined
            @change=${(e: any) => console.log(this.mensaje = e.target.value)}
            label="Mensaje"
          ></mwc-textfield> 
          <mwc-textfield
          class="my-2 w-3/4"
          outlined
          label="Fecha"
          type="date" 
          @change=${(e: any) => console.log(this.fecha = e.target.value)}
          required
        ></mwc-textfield>
        <mwc-textfield
          class="my-2 w-3/4"
          outlined
          type="time"
          @change=${(e: any) => console.log(this.time = e.target.value)}
          label="Hora"
        ></mwc-textfield>
        </div>

        <div slot="footer" class="dialog-footer">
          <button
            class="text-red-600 p-4 rounded-md uppercase"
            id="closeDialogButton"
            @click=${() => {
              this.close();
            }}
          >
            Cerrar
          </button>
          <button
            class="text-blue-600 p-4 rounded-md uppercase"
            id="closeDialogButton"
            type="submit"
          >
            Confirmar
          </button>
        </div>
      </form>
    </ui5-dialog>`;
  }
}
