import { OrganizationName } from '../../../api';
import { ActionTypes } from '../types';

export const GET_ORGANIZACIONES = 'GET_ORGANIZACIONES';
export const ORGANIZACIONES_RECEIVED = 'ORGANIZACIONES_RECEIVED';
export const ORGANIZACION_ADDED = 'ORGANIZACION_ADDED';

export function getOrganizaciones(): ActionTypes {
  return { type: GET_ORGANIZACIONES, loading: true };
}

export function organizacionAdded(
  newOrganizacion: OrganizationName
): ActionTypes {
  return { type: ORGANIZACION_ADDED, newOrganizacion };
}
