/* eslint-disable prettier/prettier */
import { html, LitElement, render } from 'lit';
import { guard } from 'lit/directives/guard.js';
import { customElement, state } from 'lit/decorators.js';
import Swal from 'sweetalert2';

import '@material/mwc-icon';
import '@vaadin/vaadin-grid';
import './UsersList.css';
import {
  Grid,
  GridActiveItemChangedEvent,
  GridItemModel,
} from '@vaadin/vaadin-grid';
import { connect } from 'pwa-helpers';

import router from '../../router/router.js';
import { UserApi, OrganizationApi, SecurityApi, RoleApi } from '../../../api/api.js';
import { User } from '../../../api/index.js';
import { apisConfigurator } from '../../utils/axios.js';
import store from '../../redux/store.js';
import { State } from '../../redux/types';
import { editarUsuario, getUsuarios } from '../../redux/actions/index.js';
import { Unsubscribe } from '@reduxjs/toolkit';
@customElement('users-list-page')
export class UsersListPage extends connect(store)(LitElement) {
  @state()
  private detailsOpenedItem: User[] = [];
  @state()
  detailUser = false;
  @state()
  User = { email: '', name: '' };
  @state()
  users: User[] = [];
  @state()
  organizations: any[] = [];
  @state()
  roles: any[] = [];
  @state()
  role: any = "";
  @state()
  organizaciones: any[] = [];

  private usersApi!: UserApi;
  private roleApi!: RoleApi;
  private securityApi!: SecurityApi;
  private organizationApi!: OrganizationApi;
  private storeUnsuscriber?: Unsubscribe;

  @state() private showModules?: string[] = [];

  constructor() {
    super();
    this.organizationApi = new OrganizationApi(
      ...apisConfigurator('Organización')
    );
    this.usersApi = new UserApi(
      ...apisConfigurator('Usuario')
    );
    this.roleApi = new RoleApi(
      ...apisConfigurator('Role')
    );
    this.securityApi = new SecurityApi(
      ...apisConfigurator('Seguridad')
    );
  }

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private actionRenderer = (root: HTMLElement, _: any, item: any) => {
    render(
      html`
        <mwc-icon-button icon="visibility"></mwc-icon-button>
        <mwc-icon-button
          icon="edit"
          @click="${() => {
            store.dispatch(editarUsuario(item.item));
            //console.log(item.item);
            router.cambiarSeccion('edit-user');
          }}"
        ></mwc-icon-button>
        <mwc-icon-button icon="delete"></mwc-icon-button>
        <mwc-icon-button icon="more_vert"></mwc-icon-button>
      `,
      root
    );
  };

  private get loaderContainer() {
    return document.getElementById('loader-container') as any;
  }

  protected firstUpdated(): void {
    const loaderContainer = this.loaderContainer;
    loaderContainer.style.display = 'none';
    this.ObtenerOrganizacion()
    this.ObtenerRoles()
    this.getUsers();
    const { authorizedFunctions } = store.getState() as State;

    this.showModules = authorizedFunctions;
    console.log("probando",this.showModules);
    console.log("probando2",this.showModules?.includes('MASTER_MODULE'));

    window.addEventListener('vaadin-router-location-changed', () => {
      const { authorizedFunctions } = store.getState() as State;

      this.showModules = authorizedFunctions;
      console.log("probando",this.showModules);
      console.log("probando2",this.showModules?.includes('MASTER_MODULE'));
    });
  }

  async getUsers() {
    store.dispatch(getUsuarios());
    this.storeUnsuscriber = store.subscribe(() => {
      const { list, loading, listApps } = store.getState();
      console.log(loading);
      console.log("LISTAPP",{ list });
      this.users = list as User[];
      // this.loading = loading;
    });
  }

  disconnectedCallback(): void {
    this.storeUnsuscriber && this.storeUnsuscriber();
  }

  async ObtenerOrganizacion() {
    await this.organizationApi
      .organizationList()
      .then(response => response)
      .then(data => {
          console.log("ORGANIZACIONES",data)
        this.organizaciones = data.data;
      });
  }

  async ObtenerRoles() {
    await this.roleApi
      .securityRolList()
      .then(response => response)
      .then(data => {
        //console.log("ORGANIZACIONES",data)
        this.roles = data.data;
      });
  }

  async ObtenerOrganizaciones(email: string) {
    await this.usersApi
      .userGet({ user: email }) 
      .then(response => {
        console.log("DETAIL USER",response.data)
        this.roles.map(role =>{
          if(!response.data.role.localeCompare(role.id)){
            this.role= role.name
          }
        })
        return response.data.organization
      })
      .then((data:any) => {
        let organizaciones:any=[];
        console.log(this.organizaciones)
        console.log(data)
        this.organizaciones.map(camp => {
          if(data.includes(camp.id)){
            organizaciones.push(camp.name)
            camp.sons.map((campo:any) => {
              if(data.includes(campo.id)){
                organizaciones.push(campo.name)
              }
            })
          }else{
            camp.sons.map((campo:any) => {
              if(data.includes(campo.id)){
                organizaciones.push(campo.name)
              }
            })
          }
        })
       return organizaciones;
      })
      .then((data:any) => {
        this.organizations = data
      });
  }

  async deleteUser(user: User) {
    const respuestaSwal = await Swal.fire({
      title: 'Estas Seguro?',
      text: '¡No podrás revertir esto!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si!',
    });
    //console.log({user:user.email})
    if (respuestaSwal.isConfirmed) {
      const res = await this.usersApi.userMasterDelete({ user: user.email });
      //console.log(res)
      this.users = this.users.filter(t => t.email !== user.email);
      Swal.fire('Eliminado!', 'El usuario ha sido eliminado.', 'success');
      //console.log("eliminado")
    }
  }

  private async handleDeleteUser(user: User) {
    try {
      /* const res = await this.usersApi.groupDelete(
        { id: user.id },
      ); */

      this.users = this.users.filter(t => t.email !== user.email);
    } catch (err) {}
  }

  render() {
    return html`
      <div id="loader-container">
        <div class="spinner"></div>
      </div>
      <main class="w-full h-full FondoColor flex ">
        <section class="w-3/4 flex flex-wrap p-4 SectionList rounded-l-lg">
          <vaadin-grid
            class="h-full"
            .items="${this.users}"
            .detailsOpenedItems="${this.detailsOpenedItem}"
            @active-item-changed="${(e: GridActiveItemChangedEvent<User>) =>
              (this.detailsOpenedItem = [e.detail.value])}"
            .rowDetailsRenderer="${guard(
              [],
              () => (root: HTMLElement, _: Grid, model: GridItemModel<User>) => {
                const user = model.item;
                this.ObtenerOrganizaciones(this.detailsOpenedItem[0].email);
                console.log(this.detailsOpenedItem[0]);
                this.User = {
                  email: this.detailsOpenedItem[0].email,
                  name: `${this.detailsOpenedItem[0].name} `,
                };
                if (this.detailUser === false) {
                  //console.log(this.detailUser);
                  this.detailUser = true;
                  this.User = {
                    email: this.detailsOpenedItem[0].email,
                    name: `${this.detailsOpenedItem[0].name}`,
                  };
                } else if (this.detailUser === true) {
                  //console.log(this.detailUser);

                  this.detailUser = false;
                }
                //console.log(user);
                render(
                  html`<vaadin-form-layout
                    .responsiveSteps=" ${[{ minWidth: '0', columns: 3 }]}"
                  >
                    <div class="w-full h-14 flex flex-row mt-5 ml-10">
                      <div
                        class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                      >
                        <div>
                          <mwc-icon-button icon="chat"> </mwc-icon-button>
                        </div>
                        <div>
                          <p class="text-center  ">Enviar mensaje</p>
                        </div>
                      </div>
                      <div
                        class="btnReset items-center content-center cursor-pointer flex  flex-row cursor-pointer"
                        @click=${() => {
                          //console.log(this.User)
                          store.dispatch(editarUsuario(this.User));
                          router.cambiarSeccion('create-user');
                        }}
                      >
                        <div>
                          <mwc-icon-button icon="autorenew"> </mwc-icon-button>
                        </div>
                        <div>
                          <p class="text-center  ">Editar</p>
                        </div>
                      </div>
                      <div
                        class="btnReset items-center content-center cursor-pointer flex  flex-row cursor-pointer"
                        @click="${() => this.deleteUser(this.User)}"
                      >
                        <div>
                          <mwc-icon-button icon="delete"> </mwc-icon-button>
                        </div>
                        <div>
                          <p class="text-center  ">Eliminar</p>
                        </div>
                      </div>
                    </div>
                  </vaadin-form-layout>`,
                  root
                );
              }
            )}"
          >
            <vaadin-grid-selection-column></vaadin-grid-selection-column>
            <vaadin-grid-sort-column
              path="name"
              header="Nombre"
            ></vaadin-grid-sort-column>
            <vaadin-grid-sort-column
              path="email"
              header="Correo"
            ></vaadin-grid-sort-column>
            <vaadin-grid-sort-column
              path="organizacion"
            ></vaadin-grid-sort-column>
            <vaadin-grid-sort-column
              text-align="center"
              path="id"
            ></vaadin-grid-sort-column>
            <!-- <vaadin-grid-column .headerRenderer="${(root: HTMLElement) => {
              render(
                html`<mwc-button outlined class=" rounded"
                  ><mwc-icon>add_circle</mwc-icon> Crear Usuario</mwc-button
                >`,
                root
              );
            }}"
                    text-align="end"
                    .renderer="${this.actionRenderer}"
                    >
                  </vaadin-grid-column> -->
          </vaadin-grid>
        </section>
        <section class="w-1/4  SectionDetail justify-center scroll">
          ${this.showModules?.includes('MASTER_MODULE') 
            ? html` <div class="w-full flex justify-center">
                <mwc-button
                  class="rounded w-4/5 mx-auto mt-4 mb-2"
                  style="--mdc-theme-primary: #2E3EAE;"
                  raised
                  icon="verified_user"
                  @click=${() => {
                    router.cambiarSeccion('masters-list');
                  }}
                >
                  Usuarios Master</mwc-button
                >
              </div>`
            : ''}
          <div class="w-full flex justify-center">
            <mwc-button
              class="rounded w-4/5 mx-auto mt-4 mb-2"
              style="--mdc-theme-primary: #2E3EAE;"
              raised
              icon="add_circle"
              @click=${() => {
                router.cambiarSeccion('create-user');
              }}
            >
              Crear Usuario</mwc-button
            >
          </div>
          <div class="w-full flex justify-center">
            <mwc-button
              class="rounded w-4/5 mx-auto mt-4 mb-2"
              style="--mdc-theme-primary: #2E3EAE;"
              raised
              icon="add_circle"
              @click=${() => {
                router.cambiarSeccion('role-list');
              }}
            >
              Roles</mwc-button
            >
          </div>
          ${this.detailUser === true
            ? html`
                <div class="w-full  mt-5 border-b-2 border-grey-100	">
                  <div>
                    <h1 class="text-2xl	text-blue-900 ml-3 mb-8	">
                      ${this.User.name}
                    </h1>
                  </div>
                </div>
                <div class=" pt-3 flex items-center ">
                  <p class="text-base	text-blue-500	ml-3 ">Roles:</p>
                  <p class="text-base	text-blue-500	ml-3 ">${this.role}</p>
                  <!-- <mwc-icon-button icon="settingsremoteoutlined">
                  </mwc-icon-button> -->
                </div>
                <div class=" pt-3 flex items-center ">
                  <p class="text-base	text-blue-500	ml-3 ">Organizaciones</p>
                  <!-- <mwc-icon-button icon="settingsremoteoutlined">
                  </mwc-icon-button> -->
                </div>
                <div class="pt-1 ml-5">
                  <ul class="list-disc pl-2">
                    ${this.organizations.map(
                      (app, index) => html`
                        <li>
                          <p class="text-base	text-blue-800	">${app}</p>
                        </li>
                      `
                    )}
                  </ul>
                </div>
              `
            : html``}
        </section>
      </main>
    `;
  }
}
