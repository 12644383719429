/* eslint-disable prettier/prettier */

import { LitElement, html, render } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
// import { guard } from 'lit/directives/guard.js';
import '@ui5/webcomponents/dist/Dialog.js';
import '@vaadin/vaadin-select';
import '@vaadin/vaadin-grid';
import Swal from 'sweetalert2'; 
import {
  succesNotification,
} from '../../services/notifications';
//import { TerminalApi, TerminalList } from '../../../api';
import {
  TerminalApi,
  TerminalList,
  CommunicationTerminalApi,
  CommunicationTerminalApiRequestCommandRequest,
  RequestCommandTypeCommandEnum,
  Terminal,
  AppApi
} from '../../../api';
import { errorNotification } from '../../services/notifications';
import { apisConfigurator } from '../../utils/axios';

@customElement('instalar-app-modal')
export class InstalarAppModal extends LitElement {
  private appApi: AppApi;
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private terminalApi: TerminalApi;
  private communicationApi: CommunicationTerminalApi;

  constructor() {
    super();
    this.appApi = new AppApi(...apisConfigurator('Aplicaciones'));
    this.terminalApi = new TerminalApi(...apisConfigurator('Terminales'));
    this.communicationApi = new CommunicationTerminalApi(
      ...apisConfigurator('Comunicación')
    );
  }

  @property({ type: Object })
  app?: any;

  @state()
  terminales: TerminalList[] = [];

  @state()
  private idApp = '';

  @state()
  private versions:any = [];

  @state()
  private red:any = ["Wifi","Mobile Data","Any"];

  @state()
  private detailsOpenedItem: TerminalList[] = [];

  private get dialog() {
    return document.getElementById('dialog-instalar-terminal')! as any;
  }

  protected firstUpdated(): void {}

  private async getTerminales() {
    const res = await this.terminalApi.terminalList({});
    console.log(res);
    this.terminales =
      /* [
      {
        brand: 'brand',
        model: 'model',
        organization: 'org',
        serial: '123456',
        owner: 'org-1',
        status: 'activo',
      },
    ]; */ res.data;
    this.detailsOpenedItem = this.terminales;
    console.log(this.terminales);
    this.requestUpdate();
  }

  async open(app: any, id: any) {
    if (!app) { 
      errorNotification('Error al instalar la app');
      return;
    }
    console.log(app)
    if(!app.version.length) {
      errorNotification('sin version, no se puede instalar la app');
      return;
    } 
    this.idApp = id;
    this.app = app;
    await this.getTerminales();
    this.dialog.open = true;
  }

  async getApp(appId: any) {

    try {
      const res = await this.appApi.getApp({ id: appId });
      this.app = res.data;
      let datos= {}
      this.app.version.map((camp:any,index:number)=> {
        Object.assign(datos, {
          [camp.version]: camp.version,
        });
      }) 
      this.versions = datos;
    } catch (err) {
      //errorNotification('Error al obtener la app');
      console.log(err);
    } finally {
      //this.loading = false;
    }
  }

  private async instalarApp(terminal: any) {
    console.log('app', this.app.version.length);
    console.log('terminal', this.app.id);
    if (!this.app) return; 
    await this.getApp(this.idApp)
    const { value: version } = await Swal.fire({
      title: 'Selecciona una version a instalar',
      input: 'select',
      inputOptions: this.versions,
      inputPlaceholder: 'Seleccionar',
      showCancelButton: true,
    })
    const { value: red } = await Swal.fire({
      title: 'Selecciona tipo de red',
      input: 'select',
      inputOptions: this.red,
      inputPlaceholder: 'Seleccionar',
      showCancelButton: true,
    })
    console.log(this.app)
    try {
      if(version) {
        const requestCommand: CommunicationTerminalApiRequestCommandRequest = {
          brand: terminal.item.brand, 
          serial: terminal.item.serial,
          requestCommand: {
            commandId: `installApp-${terminal.item.serial}-${Date.now()}`,
            typeCommand: `UPDATE_APP`,
            parameters: [this.idApp, version,this.red[red],this.app.packageName,this.app.packageName],
          },
        }; 
        const res = await this.communicationApi.requestCommand(requestCommand);
        console.log('envio mensaje res', res);
        if(res.status==200){
          succesNotification('Comando Enviado!')
        } 
        this.close();
      }

    } catch (err: any) {
      console.log('envio mensaje err', err);
      errorNotification(err);
    }
  }

  close() {
    this.dialog.open = false;
    this.app = undefined;
    this.terminales = [];
  }

  protected render() {
    return html` <ui5-dialog
      id="dialog-instalar-terminal"
      header-text="Instalar app "
      class="w-8/12" 
    > 
      <vaadin-grid class="mx-2" .items="${this.terminales}"
                .detailsOpenedItems="${this.detailsOpenedItem}"> 
        <vaadin-grid-sort-column path="brand"></vaadin-grid-sort-column>
        <vaadin-grid-sort-column path="model"></vaadin-grid-sort-column>
        <vaadin-grid-sort-column path="serial"></vaadin-grid-sort-column>
        <vaadin-grid-sort-column path="organization"></vaadin-grid-sort-column>
        <vaadin-grid-sort-column path="owner"></vaadin-grid-sort-column>
        <vaadin-grid-sort-column
          path="status"
          header="Estatus"
          .renderer="${(root: HTMLElement, _: any, model: any) => {
            const bg =
              model.item.status == `online` ? `bg-green-300` : `bg-red-300`;
            render(
              html`<p class="py-1 capitalize px-4 w-fit ${bg} rounded">
                ${model.item.status}
              </p>`,
              root
            );
          }}"
          "
        ></vaadin-grid-sort-column>
        <vaadin-grid-column
          header=""
          .renderer="${(root: HTMLElement, _: any, item: any) => {
            render(
              html`<mwc-button
                class="rounded"
                type="submit"
                @click=${() => {
                  console.log(item)
                  this.instalarApp(item);
                }}
                raised
                >Instalar</mwc-button
              >`,
              root
            ); 
          }}"
        ></vaadin-grid-column>
      </vaadin-grid>

      <div slot="footer" class="dialog-footer">
        <button
          class="text-red-600 p-4 mb-2 rounded-md uppercase"
          id="closeDialogButton"
          @click=${() => {
            this.close();
          }}
        >
          Cerrar
        </button>
      </div>
    </ui5-dialog>`;
  }
}
