import { ActionTypes } from '../types';
import { Firmware, FirmwareList } from '../../../api';

export const ADD_FIRMWARE = 'ADD_FIRMWARE';
export const CLEAN_FIRMWARE = 'CLEAN_FIRMWARE';
export const EDITAR_FIRMWARE = 'EDITAR_FIRMWARE';
export const GET_FIRMWARES = 'GET_FIRMWARE';
export const FIRMWARES_RECEIVED = 'FIRMWARES_RECEIVED';
export const FIRMWARE_ADDED = 'FIRMWARE_ADDED';

export function addFirmware(firmware: any): ActionTypes {
  return { type: ADD_FIRMWARE, firmware };
}

export function clearTerminal(): ActionTypes {
  return { type: CLEAN_FIRMWARE };
}
export function editarFirmware(firmware: Firmware): ActionTypes {
  return { type: EDITAR_FIRMWARE, firmware };
}

export function getFirmwares(): ActionTypes {
  return { type: GET_FIRMWARES, loading: true };
}

export function firmwareAdded(newFirmware: FirmwareList): ActionTypes {
  return { type: FIRMWARE_ADDED, newFirmware };
}
