/* eslint-disable prettier/prettier */
import { html, LitElement, render } from 'lit';
import { guard } from 'lit/directives/guard.js';
import { customElement, query, state, property } from 'lit/decorators.js';
import Swal from 'sweetalert2';
//import  JSONEditor  from 'json-editor';
//import * as JSONEditor from "json-editor";
//import JSONEditor = require("json-editor");
import '@material/mwc-icon-button';
import '@material/mwc-list/mwc-list.js';
import '@material/mwc-list/mwc-list-item.js';
import '@material/mwc-list/mwc-list.css';
import '@material/mwc-list/mwc-list-item.css';
import {
  Grid,
  GridActiveItemChangedEvent,
  GridItemModel,
} from '@vaadin/vaadin-grid';
import router from '../../router/router.js';
import { TemplateApi, SavedConfigurationApi, ConfigurationApi } from '../../../api/api.js';
import {   ResponseTemplateGet } from '../../../api';
import {
  TemplateInfo,
} from '../../../api/index.js';
import { AxiosResponse } from 'axios';
import { InsertarPropiedad,iterarCampoJson2, existenciaConfiguracionTemplate} from '../../utils/functions';
import store from '../../redux/store.js';
import { apisConfigurator } from '../../utils/axios.js';
import {
  agregarPlantilla,
  nombrePlantilla,
  descripcionPlantilla,
  idPlantilla,
  TypeJsonSchema
} from '../../redux/actions/index.js';
import { JSONEditor } from '@json-editor/json-editor';

import { getTemplates } from '../../redux/actions/plantilla';
import { Unsubscribe } from '@reduxjs/toolkit';


@customElement('plantilla-page')
export class Plantillas extends LitElement {
  private templateApi: TemplateApi;
  private savedConfigurationApi: SavedConfigurationApi;
  private configurationApi: ConfigurationApi;
  private storeUnsuscriber?: Unsubscribe;
  private constructor() {
    super();
    this.templateApi = new TemplateApi(...apisConfigurator(''));
    this.savedConfigurationApi = new SavedConfigurationApi(
      ...apisConfigurator('')
    );
    this.configurationApi = new ConfigurationApi(...apisConfigurator(''));
  }

  get idCampo() {
    return document.querySelector('#campo')! as HTMLInputElement;
  }

  @state()
  private brandSelected?: string[] = ['string', 'integer', 'object'];

  @query('#campos')
  private campos!: HTMLInputElement;

  @query('#descripcion')
  private descripcion!: HTMLInputElement;

  @state()
  private obj?: any;

  @state()
  private habilitarButton:boolean = false;

  private templates: TemplateInfo[] = [];

  @state()
  private camp?: string[] = [];

  @state()
  private tipo?: any;

  @state()
  private check?: boolean = false;

  @state()
  loader = true;

  @state()
  detail = false;

  @state()
  preConfigurations:any = [];

  @state()
  Plantill = { nombre: 'wladimir', uso: '12', descripcion: 'prueba1' };
  @state()
  plantillas: Object[] = [];

  @state()
  plantilla: TemplateInfo = {id:"",name:"",description:""};

  @state()
  private detailsOpenedItem: TemplateInfo[] = [];

  @state()
  private band?:number;

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  createSchema() {
    console.log(InsertarPropiedad(this.obj));
  }

  validateSchema() {
    /*const ajv = new Ajv()

    let schema = InsertarPropiedad(this.obj)
    const schema = {
      type: "object",
      properties: {
        foo: {type: "integer"},
        bar: {type: "string"}
      },
      required: ["foo"],
      additionalProperties: false
    }
    const validate = ajv.compile(schema)

    const data = {
      name: 1
    }

    const valid = validate(data)
    if (!valid) console.log(validate.errors)

    console.log(valid)*/
    const schemaJson = {
      type: 'object',
      properties: {
        foo: { type: 'integer' },
        bar: { type: 'string' },
      },
      required: ['foo'],
      additionalProperties: false,
    };
    var element = this.idCampo;

    var editor = new JSONEditor(element, {
      //...
      schema: schemaJson,
    });

    editor.setValue({ foo: 2 });

    var value = editor.getValue();
    //console.log(value)
  }

  async insertar() {
    this.obj.properties.push({
      campo: this.campos.value,
      descripcion: this.descripcion.value,
      tipo: this.tipo,
    });
    if (this.check) this.obj.required.push(this.campos.value);

    Swal.fire('Agregado!', 'El tipo de dato registrado', 'success');
  }

  protected firstUpdated(): void {
    localStorage.removeItem('id_plantilla');
    localStorage.removeItem('id_saved_configuration');
    this.getTemplates();
    this.obj = {
      properties: [],
      required: [],
    };

    const arbol = {
      type: 'object',
      properties: {
        foo: { type: 'integer' },
        bar: { type: 'string' },
      },
      required: ['foo'],
      additionalProperties: false,
    };

    for (var clave in arbol.properties) {
      //console.log(clave)
      this.camp?.push(clave);
    }
  }

  async getTemplates() {
    store.dispatch(getTemplates());
    this.storeUnsuscriber = store.subscribe(() => {
      const { list, loading, listApps } = store.getState();
      console.log(loading);
      console.log("list",{ list });
      this.templates = list as TemplateInfo[];
      this.loader = loading;
    });
  }

  async comprobarConfigurationsEditar(id:string) {

    try{
      const res = await this.configurationApi.listConfiguration();
      console.log("RES",res)
      const result = existenciaConfiguracionTemplate(
        res.data,
        id
      );
      console.log("RESULT",result)
      if(result) {
        Swal.fire(
          'No Permitido',
          'Esta plantilla contiene configuracion guardada',
          'question'
        )
      }else{
        await this.getTemplate(id);
        router.cambiarSeccion('edit-template');
      }

      
    } catch (error) {
      console.log(error);
    }
  }

  async comprobarConfigurations(id:string) {

    try{
      const res = await this.configurationApi.listConfiguration();
      console.log("RES",res)
      const result = existenciaConfiguracionTemplate(
        res.data,
        id
      );
      console.log("RESULT",result)
      if(result) {
        Swal.fire(
          'No Permitido',
          'Esta plantilla contiene configuracion guardada',
          'question'
        )
      }else{
        this.deleteTemplate(id)
      }

      
    } catch (error) {
      console.log(error);
    }
  }

  /*async getTemplates() {
    this.loader = true;
    var resTer: AxiosResponse;
    try {
      resTer = await this.templateApi.listTemplate({});
      console.log(resTer);
      this.templates = resTer.data;
    } catch (error) {
    } finally {
      if (!this.templates)
        this.templates = [
          {
            name: 'plantilla-1',
            id: '12',
            description: 'prueba1',
          },
        ];
      //console.log(this.terminals);
      this.loader = false;
    }
  }*/

  async getTemplate(id: any): Promise<ResponseTemplateGet | null> {
    this.loader = true;
    try {
      const resTer = await this.templateApi.getTemplate({ id });

      let res = iterarCampoJson2(
        resTer.data.schema.properties,
        resTer.data.schema.required
      );
      console.log(resTer.data.name);
      store.dispatch(agregarPlantilla(res));
      store.dispatch(nombrePlantilla(resTer.data.name));
      store.dispatch(idPlantilla(id));
      store.dispatch(TypeJsonSchema(resTer.data.schema.type));
      store.dispatch(descripcionPlantilla(resTer.data.description));

      this.loader = false;
      return resTer.data;
    } catch (error) {
      return null;
    }
  }

  async deleteTemplate(id: any) {
    this.loader = true;
    try {
      const resTer = await this.templateApi.deleteTemplate({ id });
      if(resTer.request.status==200){
        await Swal.fire('Eliminado!', 'Plantilla ha sido eliminada', 'success');
        location.reload()
      }

    } catch (error) {
      return null;
    }
  }

  private async handleDeleteSavedConfiguration(id: any) {
    try {
      const res = await this.savedConfigurationApi.deleteSavedConfiguration({
        id
      });

      if(res.request.status == 200) {
        await Swal.fire('Actualizado!', 'Pre Configuracion ha sido eliminado', 'success');
        location.reload()
      }

    } catch (err) {}
  }



  async ObtenerPreConfiguration(id: string) {
    await this.savedConfigurationApi
      .listSavedConfiguration()
      .then(response => response.data)
      .then(data => {
        let pre:any =[]
        data.map((camp:any, index:number) =>{
          if(camp.templateId.localeCompare(id)==0){
            pre.push(camp)
          }
        })
        console.log(pre)
        if(!pre.length){
          this.habilitarButton=false;
        }
        return pre;

      })
      .then(data => (this.preConfigurations = data));
  }

  render() {
    return html`
      <!-- <div id="loader-container">
        <div class="spinner"></div>
      </div> -->
      <main class="w-full h-full FondoColor flex ">
        <section class="w-3/4 flex flex-wrap p-4 SectionList rounded-l-lg">
          <vaadin-grid class="h-full" .items="${this.templates}"
          .detailsOpenedItems="${this.detailsOpenedItem}"
          @active-item-changed="${(
                    e: GridActiveItemChangedEvent<TemplateInfo>
                  ) => {
                    this.detailsOpenedItem = [e.detail.value];
                    //console.log(e.detail)
                    if (!e.detail.value) return false;
                    this.ObtenerPreConfiguration(e.detail.value.id)
                  }}"
        .rowDetailsRenderer="${guard(
                    [],
                    () =>
                      (
                        root: HTMLElement,
                        _: Grid,
                        model: GridItemModel<TemplateInfo>
                      ) => {
                        this.plantilla = model.item;
                        this.band = -1 ;
                        if (this.detail === false) {
                          this.detail = true;
                        } else if (this.detail === true) {
                          this.detail = false;
                        }
                        render(
                          html`<vaadin-form-layout
                            .responsiveSteps=" ${[
                              { minWidth: '0', columns: 3 },
                            ]}"
                          >
                            <div
                            style="background-color:#F0EFFF;"
                              class="w-full h-14 flex items-center mt-5 justify-center flex-row "
                            >
                              <div
                                class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                                @click=${ async () => {
                                  await this.getTemplate(this.plantilla.id);
                                  router.cambiarSeccion('edit-template');
                                }}
                              >
                                <div>
                                  <mwc-icon-button icon="edit">
                                  </mwc-icon-button>
                                </div>
                                <div>
                                  <p class="text-center  ">Editar</p>
                                </div>
                              </div>
                              <div
                                class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                                @click=${() => {
                                  //console.log('enviar mensaje', this.termi);
                                  //this.enviarMensajeModal.open(this.termi!);
                                  store.dispatch(idPlantilla(this.plantilla.id));
                                  localStorage.setItem('id_plantilla', this.plantilla.id);
                                  router.cambiarSeccion('create-pre-configuration');

                                }}
                              >
                                <div>
                                  <mwc-icon-button icon="save">
                                  </mwc-icon-button>
                                </div>
                                <div>
                                  <p class="text-center  ">Pre-Config</p>
                                </div>
                              </div>
                              <!-- <div
                                class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                                @click=${() => {
                                  //this.deleteTemplate(this.plantilla.id)
                                  this.comprobarConfigurations(this.plantilla.id)
                                }}
                              >
                                <div>
                                  <mwc-icon-button icon="delete">
                                  </mwc-icon-button>
                                </div>
                                <div> 
                                  <p class="text-center  ">Delete</p>
                                </div>
                              </div> -->

                              <!-- <div
                                class="btnMessage items-center content-center cursor-pointer flex  flex-row"
                                @click=${() => {
                                }}
                              >
                                <div>
                                  <mwc-icon-button icon="autorenew">
                                  </mwc-icon-button>
                                </div>
                                <div>
                                  <p class="text-center  ">Obtener Config</p>
                                </div>
                              </div> -->
                            </div>

                          </vaadin-form-layout>`,
                          root
                        );
                      }
                  )}">
            <vaadin-grid-selection-column></vaadin-grid-selection-column>
            <vaadin-grid-sort-column
              path="name"
              header="Nombre"
            ></vaadin-grid-sort-column>
            <vaadin-grid-sort-column
              path="id"
              header="En uso"
              .renderer="${(root: HTMLElement, _: any, item: any) => {
                render(html`####`, root);
              }}"
            ></vaadin-grid-sort-column>
            <vaadin-grid-sort-column
              path="description"
            ></vaadin-grid-sort-column>
          </vaadin-grid>
        </section>
        <section class="w-1/4  SectionDetail justify-center scroll">
          <div class="w-full flex justify-center">
            <mwc-button
              class="rounded w-4/5 mx-auto mt-4 mb-2"
              style="--mdc-theme-primary: #2E3EAE;"
              raised
              icon="add_circle"
              @click=${() => {
                const initial = [
                  {
                    id: 1,
                    nombre: '',
                    type: '',
                    Length: 0,
                    default: 0,
                    validator: '',
                    requerido: '2',
                  },
                ];
                store.dispatch(agregarPlantilla(initial));
                store.dispatch(nombrePlantilla('Nombre de la plantilla'));
                router.cambiarSeccion('create-template');
              }}
            >
              Crear Plantilla</mwc-button
            >
          </div>
          <div class=" pt-3 flex items-center ">
              <p class="text-base	text-blue-500	ml-3 ">Pre Configuraciones</p>
              <!-- <mwc-icon-button icon="settingsremoteoutlined">
              </mwc-icon-button> -->
            </div>
          <div class="pt-1 ml-5">
            <ul class="list-disc pl-2">
              ${this.preConfigurations.map(
                (app:any, index:number) => html`
                  <li
                    class="flex items-center  ml-2"
                  >
                    <p class="text-slate-500 hover:text-blue-600 text-lg ">${app.name}</p>
                    <mwc-icon-button
                      class="ml-1"
                      icon="edit"
                      ariaLabel="Actualizar"
                      @click=${() => {
                        localStorage.setItem('id_plantilla',app.templateId );
                        store.dispatch(idPlantilla(app.templateId)); 
                        localStorage.setItem('id_saved_configuration',app.id );
                        router.cambiarSeccion('create-pre-configuration');
                      }}
                    ></mwc-icon-button>
                    <mwc-icon-button
                      class="ml-1"
                      icon="delete"
                      ariaLabel="Eliminar" 
                      @click=${() => {
                        this.handleDeleteSavedConfiguration(app.id);
                      }}
                    ></mwc-icon-button>
                  </li>
                `
              )}
            </ul>
          </div>
          </div>
          ${this.detail === true 
                  ? html`
          <div
                        class="w-full mt-5 flex-col flex justify-center items-center"
                      >
                        <!-- <mwc-button
                          raised
                          style="--mdc-theme-primary: #DFE0FF; --mdc-theme-on-primary: #000C61"
                          class="mb-3 rounded w-3/4"
                          icon="settings_remote"
                          >Enlace remoto</mwc-button
                        > -->
                        <mwc-button
                          raised
                          style="--mdc-theme-primary: tomato; --mdc-theme-on-primary: white"
                          class="mb-2 rounded w-3/4"
                          icon="delete"
                          @click="${() => {
                            //this.handleDeleteTerminal(this.termi!);
                            this.comprobarConfigurations(this.plantilla.id)
                          }}"
                          >Eliminar</mwc-button
                        >
                      </div>
                      `
                  : html``}
        </section>
      </main>
    `;
  }
}
