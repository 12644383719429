import { all } from 'redux-saga/effects';
import { appsWatcher } from './appSaga';
import { terminalsWatcher } from './terminalSaga';
import { firmwaresWatcher } from './firmwareSaga';
import { templatesWatcher } from './templateSaga';
import { usuariosWatcher } from './usuarioSaga';
import { organizacionesWatcher } from './organizaciones';
import { gruposWatcher } from './gruposSaga';

export default function* rootSaga() {
  yield all([
    appsWatcher(),
    terminalsWatcher(),
    firmwaresWatcher(),
    templatesWatcher(),
    usuariosWatcher(),
    organizacionesWatcher(),
    gruposWatcher(),
  ]);
}
