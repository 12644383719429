/* tslint:disable */
/* eslint-disable */
/**
 * TMS Agnostiko
 * Tiene la definición de todos los servicios de TMS a Agnostic (para uso en la web o externo)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { Message } from '../models';
// @ts-ignore
import { RequestTemplateCreate } from '../models';
// @ts-ignore
import { RequestTemplateUpdate } from '../models';
// @ts-ignore
import { RequestTemplates } from '../models';
// @ts-ignore
import { ResponseTemplateCreate } from '../models';
// @ts-ignore
import { ResponseTemplateGet } from '../models';
// @ts-ignore
import { ResponseTemplateGetList } from '../models';
// @ts-ignore
import { TemplateInfo } from '../models';
/**
 * TemplateApi - axios parameter creator
 * @export
 */
export const TemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Realiza la creación de una plantilla de configuración
         * @param {RequestTemplateCreate} requestTemplateCreate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTemplate: async (requestTemplateCreate: RequestTemplateCreate, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestTemplateCreate' is not null or undefined
            assertParamExists('createTemplate', 'requestTemplateCreate', requestTemplateCreate)
            const localVarPath = `/template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestTemplateCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary elimina una plantilla de configuración
         * @param {string} id 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplate: async (id: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTemplate', 'id', id)
            const localVarPath = `/template/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary obtiene el detalle de una plantilla de configuración
         * @param {string} id 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplate: async (id: string, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTemplate', 'id', id)
            const localVarPath = `/template/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary obtiene el detalle de varias plantillas de configuración
         * @param {RequestTemplates} requestTemplates 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplates: async (requestTemplates: RequestTemplates, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestTemplates' is not null or undefined
            assertParamExists('getTemplates', 'requestTemplates', requestTemplates)
            const localVarPath = `/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestTemplates, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Obtener la lista de plantillas registradas
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTemplate: async (acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/template/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary actualiza una plantilla
         * @param {string} id 
         * @param {RequestTemplateUpdate} requestTemplateUpdate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplate: async (id: string, requestTemplateUpdate: RequestTemplateUpdate, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTemplate', 'id', id)
            // verify required parameter 'requestTemplateUpdate' is not null or undefined
            assertParamExists('updateTemplate', 'requestTemplateUpdate', requestTemplateUpdate)
            const localVarPath = `/template/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tsm_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestTemplateUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TemplateApi - functional programming interface
 * @export
 */
export const TemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Realiza la creación de una plantilla de configuración
         * @param {RequestTemplateCreate} requestTemplateCreate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTemplate(requestTemplateCreate: RequestTemplateCreate, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseTemplateCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTemplate(requestTemplateCreate, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TemplateApi.createTemplate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary elimina una plantilla de configuración
         * @param {string} id 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTemplate(id: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTemplate(id, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TemplateApi.deleteTemplate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary obtiene el detalle de una plantilla de configuración
         * @param {string} id 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplate(id: string, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseTemplateGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTemplate(id, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TemplateApi.getTemplate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary obtiene el detalle de varias plantillas de configuración
         * @param {RequestTemplates} requestTemplates 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplates(requestTemplates: RequestTemplates, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResponseTemplateGetList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTemplates(requestTemplates, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TemplateApi.getTemplates']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Obtener la lista de plantillas registradas
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTemplate(acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TemplateInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTemplate(acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TemplateApi.listTemplate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary actualiza una plantilla
         * @param {string} id 
         * @param {RequestTemplateUpdate} requestTemplateUpdate 
         * @param {string} [acceptLanguage] The locale to use when showing messages and numbers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTemplate(id: string, requestTemplateUpdate: RequestTemplateUpdate, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTemplate(id, requestTemplateUpdate, acceptLanguage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TemplateApi.updateTemplate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TemplateApi - factory interface
 * @export
 */
export const TemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TemplateApiFp(configuration)
    return {
        /**
         * 
         * @summary Realiza la creación de una plantilla de configuración
         * @param {TemplateApiCreateTemplateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTemplate(requestParameters: TemplateApiCreateTemplateRequest, options?: AxiosRequestConfig): AxiosPromise<ResponseTemplateCreate> {
            return localVarFp.createTemplate(requestParameters.requestTemplateCreate, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary elimina una plantilla de configuración
         * @param {TemplateApiDeleteTemplateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplate(requestParameters: TemplateApiDeleteTemplateRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.deleteTemplate(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary obtiene el detalle de una plantilla de configuración
         * @param {TemplateApiGetTemplateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplate(requestParameters: TemplateApiGetTemplateRequest, options?: AxiosRequestConfig): AxiosPromise<ResponseTemplateGet> {
            return localVarFp.getTemplate(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary obtiene el detalle de varias plantillas de configuración
         * @param {TemplateApiGetTemplatesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplates(requestParameters: TemplateApiGetTemplatesRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ResponseTemplateGetList>> {
            return localVarFp.getTemplates(requestParameters.requestTemplates, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Obtener la lista de plantillas registradas
         * @param {TemplateApiListTemplateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTemplate(requestParameters: TemplateApiListTemplateRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<TemplateInfo>> {
            return localVarFp.listTemplate(requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary actualiza una plantilla
         * @param {TemplateApiUpdateTemplateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplate(requestParameters: TemplateApiUpdateTemplateRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.updateTemplate(requestParameters.id, requestParameters.requestTemplateUpdate, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createTemplate operation in TemplateApi.
 * @export
 * @interface TemplateApiCreateTemplateRequest
 */
export interface TemplateApiCreateTemplateRequest {
    /**
     * 
     * @type {RequestTemplateCreate}
     * @memberof TemplateApiCreateTemplate
     */
    readonly requestTemplateCreate: RequestTemplateCreate

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof TemplateApiCreateTemplate
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteTemplate operation in TemplateApi.
 * @export
 * @interface TemplateApiDeleteTemplateRequest
 */
export interface TemplateApiDeleteTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof TemplateApiDeleteTemplate
     */
    readonly id: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof TemplateApiDeleteTemplate
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for getTemplate operation in TemplateApi.
 * @export
 * @interface TemplateApiGetTemplateRequest
 */
export interface TemplateApiGetTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof TemplateApiGetTemplate
     */
    readonly id: string

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof TemplateApiGetTemplate
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for getTemplates operation in TemplateApi.
 * @export
 * @interface TemplateApiGetTemplatesRequest
 */
export interface TemplateApiGetTemplatesRequest {
    /**
     * 
     * @type {RequestTemplates}
     * @memberof TemplateApiGetTemplates
     */
    readonly requestTemplates: RequestTemplates

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof TemplateApiGetTemplates
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for listTemplate operation in TemplateApi.
 * @export
 * @interface TemplateApiListTemplateRequest
 */
export interface TemplateApiListTemplateRequest {
    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof TemplateApiListTemplate
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateTemplate operation in TemplateApi.
 * @export
 * @interface TemplateApiUpdateTemplateRequest
 */
export interface TemplateApiUpdateTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof TemplateApiUpdateTemplate
     */
    readonly id: string

    /**
     * 
     * @type {RequestTemplateUpdate}
     * @memberof TemplateApiUpdateTemplate
     */
    readonly requestTemplateUpdate: RequestTemplateUpdate

    /**
     * The locale to use when showing messages and numbers.
     * @type {string}
     * @memberof TemplateApiUpdateTemplate
     */
    readonly acceptLanguage?: string
}

/**
 * TemplateApi - object-oriented interface
 * @export
 * @class TemplateApi
 * @extends {BaseAPI}
 */
export class TemplateApi extends BaseAPI {
    /**
     * 
     * @summary Realiza la creación de una plantilla de configuración
     * @param {TemplateApiCreateTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public createTemplate(requestParameters: TemplateApiCreateTemplateRequest, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).createTemplate(requestParameters.requestTemplateCreate, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary elimina una plantilla de configuración
     * @param {TemplateApiDeleteTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public deleteTemplate(requestParameters: TemplateApiDeleteTemplateRequest, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).deleteTemplate(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary obtiene el detalle de una plantilla de configuración
     * @param {TemplateApiGetTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public getTemplate(requestParameters: TemplateApiGetTemplateRequest, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).getTemplate(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary obtiene el detalle de varias plantillas de configuración
     * @param {TemplateApiGetTemplatesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public getTemplates(requestParameters: TemplateApiGetTemplatesRequest, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).getTemplates(requestParameters.requestTemplates, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Obtener la lista de plantillas registradas
     * @param {TemplateApiListTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public listTemplate(requestParameters: TemplateApiListTemplateRequest = {}, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).listTemplate(requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary actualiza una plantilla
     * @param {TemplateApiUpdateTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public updateTemplate(requestParameters: TemplateApiUpdateTemplateRequest, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).updateTemplate(requestParameters.id, requestParameters.requestTemplateUpdate, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}

