/* eslint-disable prettier/prettier */
import { LitElement, html, TemplateResult } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import './DashboardGrah.css';
@customElement('dashboard-graph-card')
export class DashboardGraphCard extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  @property({ type: Array })
  classes?: string[];

  @property({ type: String })
  cardTitle?: string;

  @property({ type: Object })
  firstComponent!: TemplateResult<1>;

  @property({ type: Object })
  secondComponent?: TemplateResult<1>;

  @state()
  renderComponent!: TemplateResult<1>;

  protected firstUpdated(): void {
    if (this.classes) this.classList.add(...this.classes);
    this.renderComponent = this.firstComponent;
    console.log(this.cardTitle);
    console.log(this.renderComponent);

    if (!this.secondComponent) this.secondComponent = this.firstComponent;
  }

  private handleClickCard() {
    if (this.renderComponent === this.firstComponent && this.secondComponent) {
      this.renderComponent = this.secondComponent;
      return;
    }
    this.renderComponent = this.firstComponent;
  }

  render() {
    return html`
      <div
        class="rounded-xl  w-full p-4  transition duration-500 ease-in-out FondoCard"
        @click="${() => {
          this.handleClickCard();
        }}"
      >
        <div class="flex justify-betwean">
          <p class="text-xl">${this.cardTitle}</p>
        </div>
        ${this.renderComponent}
      </div>
    `;
  }
}
