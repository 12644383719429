/* eslint-disable prettier/prettier */
import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

import { ResponseQueryStatistic, TerminalStatisticApi } from '../../../api';
import { apisConfigurator } from '../../utils/axios';
import { buildWidgetByType } from '../../utils/dashboard_widgets';

@customElement('dashboard-widget-container')
export class DashboardWidgetContainer extends LitElement {
  private widgetApi: TerminalStatisticApi;

  @property({ type: Array })
  classes?: string[];

  @property({ type: String })
  idWidget!: string;

  @property({ type: String })
  widgetType!: string;

  @state()
  private loading = true;

  @state()
  private data?: ResponseQueryStatistic;

  constructor() {
    super();
    this.widgetApi = new TerminalStatisticApi(...apisConfigurator('Widget'));
  }

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  protected firstUpdated(): void {
    this.classList.add('w-6/12', ...(this.classes ?? []));
    this.getWidgetData();
  }

  async getWidgetData() {
    this.loading = true;
    try {
      const res = await this.widgetApi.getTerminalXBrand({
        idData: this.idWidget,
      });

      this.data = res.data;

      this.requestUpdate('data');
    } catch (error: any) {
      console.log(error.code);
    } finally {
      this.loading = false;
    }
  }

  render() {
    return html`
      ${this.data === undefined
        ? html`
            <div class=" flex justify-center items-center">
              <mwc-circular-progress
                indeterminate
                density=${20}
              ></mwc-circular-progress>
            </div>
          `
        : buildWidgetByType(
            this.widgetType,
            this.data.values.map(d => parseInt(d)),
            this.data.labels,
            this.data.data
          )}
    `;
  }
}
