/* eslint-disable prettier/prettier */
import { Grid } from '@vaadin/vaadin-grid';
import { html, LitElement } from 'lit';
import { customElement, state, query } from 'lit/decorators.js';

import '@vaadin/vaadin-text-field';
import '@material/mwc-textfield';
import './CrearGrupo.css';
import '@vaadin/grid/vaadin-grid-selection-column.js';
import '@vaadin/grid/vaadin-grid-sort-column.js';
import '@vaadin/grid/vaadin-grid-filter-column.js';
import {
  GroupApi,
  RequestGroupCreate,
  TerminalApi,
  TerminalList,
} from '../../../api';
import { CrearGrupos } from '../../components/modals/CrearGrupos';
import '../../components/modals/CrearGrupos';
import router from '../../router/router';
import { apisConfigurator } from '../../utils/axios';
import { errorNotification } from '../../services/notifications';
import { connect } from 'pwa-helpers';
import store from '../../redux/store';
import { grupoAdded } from '../../redux/actions/groups';

@customElement('create-group-page')
export class CrearGrupo extends connect(store)(LitElement) {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private terminalApi: TerminalApi;
  private groupApi: GroupApi;

  constructor() {
    super();
    this.terminalApi = new TerminalApi(...apisConfigurator('Grupo'));
    this.groupApi = new GroupApi(...apisConfigurator('Grupo'));
  }

  get crearGrupos() {
    return document.getElementById('crearGrupos') as CrearGrupos;
  }

  private get nameInput() {
    return document.getElementById('group-name')! as HTMLInputElement;
  }

  @query('#grid1')
  private grid!: Grid;

  @query('#grid2')
  private grid2!: Grid;

  @state()
  loading = false;

  @state()
  cantRowsShow = 10;

  @state()
  cantRowsShow2 = 10;

  @state()
  page = 1;

  @state()
  page2 = 1;

  @state()
  private rowsToShow: TerminalList[] = [];

  @state()
  private rowsToShow2: TerminalList[] = [];

  @state()
  private grid1Items: TerminalList[] = [];

  @state()
  private grid2Items: TerminalList[] = [];

  async firstUpdated() {
    this.getTerminals();

    this.grid2Items = [];
  }

  private async getTerminals() {
    const res = await this.terminalApi.terminalList({});

    this.grid1Items = res.data;
    this.requestUpdate('grid1Items');

    this.setRowsToShow();
  }

  private onAddTerminals = () => {
    this.grid2Items = [
      ...this.grid2Items.filter(el => !this.grid.selectedItems.includes(el)),
      ...this.grid.selectedItems,
    ];

    this.grid.selectedItems = [];

    this.setRowsToShow2();
  };

  private onRemoveTerminals = () => {
    this.grid2Items = this.grid2Items.filter(
      el => !this.grid2.selectedItems.includes(el)
    );

    this.grid2.selectedItems = [];

    this.setRowsToShow2();
  };

  private onCantRowsChange = (e: any) => {
    this.cantRowsShow = parseInt(e.target?.value);
    this.page = 1;
    this.setRowsToShow();
  };

  private onCantRowsChange2 = (e: any) => {
    this.cantRowsShow2 = parseInt(e.target?.value);
    this.page2 = 1;
    this.setRowsToShow2();
  };

  setRowsToShow = () => {
    const initSlice = this.page === 1 ? 0 : this.cantRowsShow * (this.page - 1);
    this.rowsToShow = this.grid1Items.slice(
      initSlice,
      initSlice + this.cantRowsShow
    );
  };

  setRowsToShow2 = () => {
    const initSlice =
      this.page2 === 1 ? 0 : this.cantRowsShow2 * (this.page2 - 1);
    this.rowsToShow2 = this.grid2Items.slice(
      initSlice,
      initSlice + this.cantRowsShow2
    );
  };

  onPreviousPage = () => {
    if (this.page === 1) return;
    this.page = this.page - 1;

    this.requestUpdate('page');
    this.setRowsToShow();
  };

  onNextPage = () => {
    if (
      this.page === Math.ceil(this.grid1Items.length / this.cantRowsShow) ||
      Math.ceil(this.grid1Items.length / this.cantRowsShow) === 0
    )
      return;
    this.page = this.page + 1;
    this.requestUpdate('page');
    this.setRowsToShow();
  };

  onPreviousPage2 = () => {
    if (this.page2 === 1) return;
    this.page2 = this.page2 - 1;

    this.requestUpdate('page2');
    this.setRowsToShow2();
  };

  onNextPage2 = () => {
    if (
      this.page2 === Math.ceil(this.grid2Items.length / this.cantRowsShow2) ||
      Math.ceil(this.grid2Items.length / this.cantRowsShow2) === 0
    )
      return;
    this.page2 = this.page2 + 1;
    this.requestUpdate('page2');
    this.setRowsToShow2();
  };

  grid1Footer = () => {
    return html`<div class="flex items-center justify-between mt-0 px-2 pt-2 ">
      <select
        @change=${this.onCantRowsChange}
        .value=${this.cantRowsShow.toString()}
      >
        <option value=${5}>5</option>
        <option value=${10}>10</option>
        <option value=${20}>20</option>
        <option value=${50}>50</option>
      </select>
      <div class="flex items-center">
        <mwc-icon-button
          @click=${this.onPreviousPage}
          icon="chevron_left"
        ></mwc-icon-button>
        <p class="font-bold text-sm">Página ${this.page}</p>
        <mwc-icon-button
          @click=${this.onNextPage}
          icon="chevron_right"
        ></mwc-icon-button>
      </div>
    </div>`;
  };

  grid2Footer = () => {
    return html`<div class="flex items-center justify-between mt-0 px-2 pt-2 ">
      <select
        @change=${this.onCantRowsChange2}
        .value=${this.cantRowsShow.toString()}
      >
        <option value=${5}>5</option>
        <option value=${10}>10</option>
        <option value=${20}>20</option>
        <option value=${50}>50</option>
      </select>

      <div class="flex items-center">
        <mwc-icon-button
          @click=${this.onPreviousPage2}
          icon="chevron_left"
        ></mwc-icon-button>
        <p class="font-bold text-sm">Página ${this.page2}</p>
        <mwc-icon-button
          @click=${this.onNextPage2}
          icon="chevron_right"
        ></mwc-icon-button>
      </div>
    </div>`;
  };

  validateForm() {
    if (!this.nameInput.checkValidity()) {
      errorNotification('El nombre del grupo es requerido.');
      return false;
    }

    if (this.grid2Items.length === 0) {
      errorNotification('Por favor, agrege terminales al nuevo grupo.');
      return false;
    }

    return true;
  }

  private async handleSaveTerminal() {
    this.loading = true;
    if (!this.validateForm()) {
      this.loading = false;
      return;
    }

    const terminals = this.grid2Items.map(t => ({
      brand: t.brand,
      serial: t.serial,
    }));

    const requestGroupCreate: RequestGroupCreate = {
      name: this.nameInput.value,
      terminals,
    };

    console.log(requestGroupCreate);

    try {
      const res = await this.groupApi.groupCreate({ requestGroupCreate });

      store.dispatch(
        grupoAdded({
          name: requestGroupCreate.name,
          id: res.data.id,
          terminals: terminals.length,
        })
      );
    } catch (err) {
    } finally {
      this.loading = false;
    }
  }

  render() {
    return html`
      <main class="w-full FondoColor min-h-full flex">
        <div class="w-full  px-5 py-4 flex flex-wrap">
          <section
            class="w-full flex flex-col h-full overflow-auto Backgroup rounded-md py-5 px-4 "
          >
            <h1 class="text-2xl uppercase font-semibold text-blue-700 mb-4">
              Nuevo grupo
            </h1>
            <div class="flex mb-2  justify-between">
              <div class="w-4/12 TexField">
                <mwc-textfield
                  label="Nombre grupo"
                  class="w-full "
                  required
                  outlined
                  iconTrailing="close"
                  id="group-name"
                >
                </mwc-textfield>
              </div>
              <div class="ml-auto btnG mr-4 mt-8">
              <button
                  @click=${() => {
                    this.crearGrupos.open()
                  }}
                  class="rounded-3xl border border-indigo-500 text-blue-500 CancelarG hover:shadow-md hover:bg-slate-300"
                >
                  Crear Grupos
                </button>
                <button
                  @click=${() => {
                    router.cambiarSeccion('hierarchy');
                  }}
                  class="rounded-3xl border border-indigo-500 text-blue-500 CancelarG hover:shadow-md hover:bg-slate-300"
                >
                  Cancelar
                </button>

                <button
                  class="rounded-3xl	text-white bg-[#2e3eae] CrearG  hover:shadow-md hover:bg-blue-700"
                  @click="${this.handleSaveTerminal}"
                  .disabled=${this.loading}
                >
                  Crear grupo
                </button>
              </div>
            </div>
            
            <div class="flex w-full h-full">
              <div class="w-6/12 grids-container px-2 h-full">
                <div class="flex justify-between">
                  <h2>Seleccionar Terminales</h2>
                </div>
                <vaadin-grid
                  class="h-5/6"
                  .items="${this.rowsToShow}"
                  .itemIdPath="${'serial'}"
                  id="grid1"
                >
                  <vaadin-grid-selection-column></vaadin-grid-selection-column>
                  <vaadin-grid-filter-column
                    path="serial"
                    header="Serial"
                  ></vaadin-grid-filter-column>
                  <vaadin-grid-filter-column
                    path="owner"
                    header="Dueño"
                  ></vaadin-grid-filter-column>
                  <vaadin-grid-filter-column
                    path="brand"
                    header="Marca"
                  ></vaadin-grid-filter-column>
                  <vaadin-grid-filter-column
                    path="model"
                    header="Modelo"
                  ></vaadin-grid-filter-column>
                </vaadin-grid>
                ${this.grid1Footer()}
              </div>
              <div class="w-6/12 grids-container px-2 h-full">
                <div class="flex justify-between">
                  <h2>Terminales seleccionados</h2>
                </div>

                <vaadin-grid
                  class="h-5/6"
                  .items="${this.rowsToShow2}"
                  .itemIdPath="${'serial'}"
                  id="grid2"
                >
                  <vaadin-grid-selection-column></vaadin-grid-selection-column>
                  <vaadin-grid-filter-column
                    path="serial"
                    header="Serial"
                  ></vaadin-grid-filter-column>
                  <vaadin-grid-filter-column
                    path="owner"
                    header="Dueño"
                  ></vaadin-grid-filter-column>
                  <vaadin-grid-filter-column
                    path="brand"
                    header="Marca"
                  ></vaadin-grid-filter-column>
                  <vaadin-grid-filter-column
                    path="model"
                    header="Modelo"
                  ></vaadin-grid-filter-column>
                </vaadin-grid>
                ${this.grid2Footer()}
              </div>
            </div>
            <div class="flex w-full justify-around">
              <button
                @click=${this.onAddTerminals}
                class="rounded-3xl BtnAñadir w-1/4 text-white  px-4 py-2 uppercase hover:shadow-md hover:bg-blue-700"
              >
                Añadir grupos</button
              ><button
                @click=${this.onRemoveTerminals}
                class="rounded-3xl text-white w-1/4 bg-red-500 px-4 py-2 uppercase hover:shadow-md hover:bg-red-600"
              >
                Vaciar Grupo
              </button>
            </div>
          </section>
          <section>

          </section>
        </div>
      </main>
      <crear-grupos id="crearGrupos"></crear-grupos>
    `;
  }
}
