/* eslint-disable prettier/prettier */
export function setCookie(name: string, value: string, time: number) {
  let expires = '';
  if (time) { 
    const date = new Date();
    date.setTime(date.getTime() + time * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
}
export function getCookie(name: string) {
  const nameEQ = `${name}=`;

  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i += 1) {

    let c = cookies[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

/* setCookie('user_email', 'bobthegreat@gmail.com', 30); // set "user_email" cookie, expires in 30 days
const userEmail = getCookie('user_email'); // "bobthegreat@gmail.com" */
