/* eslint-disable prettier/prettier */
import { html, LitElement, render } from 'lit';
import { guard } from 'lit/directives/guard.js';
import { customElement, state } from 'lit/decorators.js';
import Swal from 'sweetalert2';
import { getCookie } from '../../services/cookie';
import '@ui5/webcomponents/dist/Tree.js';
import '@ui5/webcomponents/dist/Icon.js';
import '@ui5/webcomponents-icons/dist/it-instance.js';
import '@ui5/webcomponents-icons/dist/group-2.js';
import '@ui5/webcomponents-icons/dist/alert.js';
import '@ui5/webcomponents-icons/dist/cart.js';
import '@ui5/webcomponents-icons/dist/bell.js';
import '@material/mwc-icon';
import '@vaadin/vaadin-grid';
import './UsersList.css';
import {
  Grid,
  GridActiveItemChangedEvent,
  GridItemModel,
} from '@vaadin/vaadin-grid';

import {
  TokenApi,
  LicenseApi,
  LicenseApiGetLicenseByMasterRequest,
  TokenApiListTokenByLicenseRequest,
  TokenApiListTokenRequest,
  MasterApi,
  MasterApiMasterChangeStatusRequest,
} from '../../../api/api.js';
import { License, Master } from '../../../api/index.js';
import { apisConfigurator } from '../../utils/axios.js';
import { EnviarMensajeModal } from '../../components/modals/EnviarMensajeModal';
import { AgregarLicenciaModal } from '../../components/modals/AgregarLicencia';
import '../../components/modals/AgregarLicencia';
import '../../components/modals/EnviarMensajeModal';
import { VerLicenciaModal } from '../../components/modals/VerLicencia';
import '../../components/modals/VerLicencia';
import { TokenGenerados } from '../../components/modals/TokenGenerados';
import '../../components/modals/TokenGenerados';
import { delay } from '../../utils/functions';

@customElement('masters-list-page')
export class UsersListPage extends LitElement {
  @state()
  private detailsOpenedItem: Master[] = [];

  @state()
  private detailUser = false;

  @state()
  private bandToken?:number;

  @state()
  private count?:number;

  @state()
  private User?: Master;

  @state()
  private users: Master[] = [];

  @state()
  private licenses: License[] = [];

  private masterApi!: MasterApi;
  private tokenApi!: TokenApi;
  private licenseApi!: LicenseApi;

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  get enviarMensajeModal() {
    return document.getElementById('enviarMensajeModal') as EnviarMensajeModal;
  }

  get agregarLicenciaModal() {
    return document.getElementById(
      'agregarLicenciaModal'
    ) as AgregarLicenciaModal;
  }

  get verLicenciaModal() {
    return document.getElementById('verLicenciaModal') as VerLicenciaModal;
  }

  get tokenGenerados() {
    return document.getElementById('tokenGenerados') as TokenGenerados;
  }

  private get loaderContainer() {
    return document.getElementById('loader-container') as any;
  }

  protected firstUpdated(): void {
    const loaderContainer = this.loaderContainer;
    loaderContainer.style.display = 'none';

    this.masterApi = new MasterApi(...apisConfigurator('Usuarios Master'));
    this.licenseApi = new LicenseApi(...apisConfigurator('Licencias Master'));
    this.tokenApi = new TokenApi(...apisConfigurator('Tokens Master'));

    this.masterApi.masterList();

    this.getUsers();
  }

  async getUsers() {
    const loaderContainer = this.loaderContainer;
    loaderContainer.style.display = 'none';
    try {
      const res = await this.masterApi.masterList();

      this.users = res.data;
    } catch (err) {
      console.log(err);
    }
  }

  async deactivateUser(user: Master) {
    const respuestaSwal = await Swal.fire({
      title: 'Estas Seguro?',
      text: '¡No podrás revertir esto!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si!',
    });

    if (respuestaSwal.isConfirmed) {
      const newStatus =
        user.status?.toLowerCase() === 'active' ? 'blocked' : 'active';

      const request: MasterApiMasterChangeStatusRequest = {
        name: user.master,
        requestMasterChangeStatus: { newStatus: newStatus },
      };

      const res = await this.masterApi.masterChangeStatus(request);
      const msg = newStatus === 'active' ? 'activado' : 'desactivado';
      this.users = this.users.filter(t => t.master !== user.master);
      Swal.fire(
        `${msg.charAt(0).toUpperCase()}${msg.substring(1)}!`,
        `El usuario ha sido ${msg}.`,
        'success'
      );
    }
  }

  async onDeleteLicense(id: string) {
    const respuestaSwal = await Swal.fire({
      title: '¿Estás Seguro?',
      text: '¡No podrás revertir esto!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si!',
      cancelButtonText: 'Cancelar',
    });

    if (!respuestaSwal.isConfirmed) return;

    const res = await this.licenseApi.licenseDeleteById({ id });

    this.licenses = this.licenses.filter(li => li.id !== id);
  }

  async onUpdateLicense(li: License) {
    this.agregarLicenciaModal.updateLicense(li);
  } 

  async onDetailsLicense(li: License) {
    this.verLicenciaModal.open(li);
  }
 
  async getUserLicenses(id: string) {
    const body: LicenseApiGetLicenseByMasterRequest = {
      id,
    };

    const licenses = await this.licenseApi.getLicenseByMaster(body);

    this.licenses = licenses.data;
  }

  async getTokensLicense(li: any) {
    console.log("license",li)
    const body: TokenApiListTokenRequest = {
      master:li.idMaster.toUpperCase(),
      brand:li.brand,
      model:li.model,
      serial:li.serial
    };
    console.log(body)
    const tokens = await this.tokenApi.listToken(body);
    this.count =  tokens.data.length
    //this.licenses = licenses.data;
  }

  async onLicensesUpdated() {
    if (!this.User || !this.detailUser) return;
    await delay(1000);

    await this.getUserLicenses(this.User.master);
  }

  render() {
    return html`
      <div id="loader-container">
        <div class="spinner"></div>
      </div>
      <main class="w-full h-full FondoColor flex ">
        <section class="w-3/4 flex flex-wrap p-4 SectionList rounded-l-lg">
          <vaadin-grid
            class="h-full"
            .items="${this.users}"
            .detailsOpenedItems="${this.detailsOpenedItem}"
            @active-item-changed="${(e: GridActiveItemChangedEvent<Master>) =>
              {this.detailsOpenedItem = [e.detail.value]
              }
            }"
            .rowDetailsRenderer="${guard(
              [],
              () =>
                async (
                  root: HTMLElement,
                  _: Grid,
                  model: GridItemModel<Master>
                ) => { 
                  const user = model.item;
                  console.log(model.item)
                  this.User = user;
                  if (!this.detailUser) {
                    this.detailUser = true;
                  } else if (this.detailUser) {
                    this.detailUser = false;
                  }
                  if (this.detailUser) {
                    this.getUserLicenses(user.master);
                  }
                  render(
                    html`<vaadin-form-layout
                      .responsiveSteps=" ${[{ minWidth: '0', columns: 3 }]}"
                    >
                      <div class="w-full h-14 flex flex-row mt-5 ml-10">
                        <div
                          class="btnReset items-center content-center cursor-pointer flex  flex-row cursor-pointer"
                          @click="${() => this.deactivateUser(user)}"
                        >
                          <div>
                            <mwc-icon-button icon="block"> </mwc-icon-button>
                          </div>
                          <div>
                            <p class="text-center  ">Desactivar</p>
                          </div>
                        </div>
                      </div>
                    </vaadin-form-layout>`,
                    root
                  );
                }
            )}"
          >
            <vaadin-grid-selection-column></vaadin-grid-selection-column>
            <vaadin-grid-sort-column
              path="master"
              header="Nombre"
            ></vaadin-grid-sort-column>
            <vaadin-grid-sort-column
              path="user"
              header="Usuario"
            ></vaadin-grid-sort-column
            ><vaadin-grid-sort-column
              path="status"
              header="Estatus"
              .renderer="${guard(
                [],
                () =>
                  (root: HTMLElement, _: any, model: GridItemModel<Master>) => {
                    const bg =
                      model.item.status?.toLowerCase() == `active`
                        ? `bg-green-300`
                        : `bg-red-300`;
                    render(
                      html`<p class="py-1 capitalize px-4 w-fit ${bg} rounded">
                        ${model.item.status}
                      </p>`,
                      root
                    );
                  }
              )}"
            ></vaadin-grid-sort-column>
          </vaadin-grid>
        </section>
        <section class="w-1/4  SectionDetail justify-center scroll">
          ${this.detailUser === true
            ? html`
                <div class="w-full flex justify-center">
                  <mwc-button
                    class="rounded w-4/5 mx-auto mt-4 mb-2"
                    style="--mdc-theme-primary: #2E3EAE;"
                    raised
                    icon="add_circle"
                    @click=${() => {
                      this.agregarLicenciaModal.open(this.User);
                    }}
                  >
                    Agregar Licencia</mwc-button
                  >
                </div>
                <div class="w-full mt-5 border-b-2 border-grey-100	">
                  <div>
                    <h1 class="text-2xl	text-blue-900 ml-3 mb-8	">
                      ${this.User?.user}
                    </h1>
                  </div>
                </div>

                <div class="w-full mt-5 border-b-2 border-grey-100	">
                  <div>
                    <h1 class="text-xl text-blue-900 ml-3 mb-2	">Licencias</h1>
                  </div>

                  ${this.licenses
                    ? html`
                        <ul class="list-disc pl-2 ml-5">
                          ${this.licenses.map(
                            (li, index) => html`
                              <li class="flex items-center  ml-2">
                                <p>${li.description.length > 8 ? li.description.substr(0, 8)+`...` : li.description.substr(0, 8)}</p>
                                <mwc-icon-button
                                  class="ml-1" 
                                  icon="visibility"
                                  ariaLabel="Ver"
                                  @click=${() => {
                                    console.log("licencia:",li)
                                    
                                    this.onDetailsLicense(li);
                                  }}
                                ></mwc-icon-button>
                                <mwc-icon-button
                                  class="ml-1"
                                  icon="update"
                                  ariaLabel="Actualizar"
                                  @click=${() => {
                                    this.onUpdateLicense(li);
                                  }}
                                ></mwc-icon-button>
                                <mwc-icon-button
                                  class="ml-1"
                                  icon="delete"
                                  ariaLabel="Eliminar"
                                  @click=${() => {
                                    this.onDeleteLicense(li.id);
                                  }}
                                ></mwc-icon-button>
                                ${this.bandToken == index ? 
                                html `
                                    <p class="inline-block whitespace-nowrap rounded-full bg-danger-100 px-[0.65em] pb-[0.25em] pt-[0.35em] text-center align-baseline text-[0.75em] font-bold leading-none text-danger-700"
                                    >${this.count} token(s)</p>
                                  `  
                                : 
                                html`
                                  <mwc-icon-button
                                  class="ml-1" 
                                  icon="token"
                                  ariaLabel="Ver"
                                  @click=${() => {
                                    this.bandToken = index;
                                    this.getTokensLicense(li)
                                  }}
                                ></mwc-icon-button>
                                `}
                                <mwc-icon-button
                                  class="ml-1" 
                                  icon="send"
                                  ariaLabel="Ver"
                                  @click=${() => {
                                    this.tokenGenerados.open(li);
                                  }}
                                ></mwc-icon-button>
                              </li>
                            `
                          )}
                        </ul>
                      `
                    : ''}
                </div>
              `
            : html``}
        </section>
      </main>
      <agregar-licencia-modal
        @LicensesUpdated=${this.onLicensesUpdated}
        id="agregarLicenciaModal"
      ></agregar-licencia-modal>
      <ver-licencia-modal id="verLicenciaModal"></ver-licencia-modal>
      <token-generados id="tokenGenerados"></token-generados>
    `;
  }
}
