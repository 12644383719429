/* eslint-disable prettier/prettier */

import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '@ui5/webcomponents/dist/Dialog.js';
import '@vaadin/vaadin-select';
import {
  errorNotification,
  succesNotification,
} from '../../services/notifications';
import { apisConfigurator } from '../../utils/axios';
import '../CustomSelect/CustomSelect';
import {
  App,
  BrandList,
  ModelApi,
  VersionApi,
  VersionApiCreateVersionRequest,
} from '../../../api';
import store from '../../redux/store';
import { State } from '../../redux/types';

@customElement('agregar-version-modal')
export class AgregarVersionModal extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private versionApi: VersionApi;

  get version() {
    return document.getElementById('version') as HTMLInputElement;
  }

  get descripcion() {
    return document.getElementById('description') as HTMLInputElement;
  }

  get briefDescription() {
    return document.getElementById('briefDescription') as HTMLInputElement;
  }

  constructor() {
    super();
    this.versionApi = new VersionApi(...apisConfigurator('Version'));
  }

  @property({ type: Object })
  app?: any;

  private get dialog() {
    return document.getElementById('dialog-version-instalador')! as any;
  }

  protected firstUpdated(): void {}

  async open(app?: App) {
    if (!app) {
      errorNotification('Error al agregar version');
      return;
    }
    console.log("control de app",app)
    this.app = app;
    this.dialog.open = true;
  }

  close() {
    this.dialog.open = false;
    this.app = undefined;
  }

  private async handleSubmit(e: Event) {
    e.preventDefault();

    console.log("VERSION",this.app) 
    if (!this.app.length) {
      errorNotification('Error al crear version');
      return;
    }

    const body: VersionApiCreateVersionRequest = {
      id: this.app,
      requestVersionCreate: {
        version: this.version.value,
        briefDescription: this.briefDescription.value,
        description: this.descripcion.value,
      },
    };

    console.log(this.version);
    console.log(body);

    try {
      const res = await this.versionApi.createVersion(body);
      const e = new CustomEvent('version-created', {
        detail: body.requestVersionCreate,
      });
      this.dispatchEvent(e);
      if (res.status === 200) {
        succesNotification('Version creada con exito');
        this.close();
      }
    } catch (err) {}
  }

  protected render() {
    return html` <ui5-dialog
      id="dialog-version-instalador"
      header-text="Agregar version a ${this.app?.name}"
      class="w-4/12 h-80"
    >
      <form
        class="px-4 flex flex-col items-center h-full"
        @submit=${this.handleSubmit}
      >
        <div class="mx-2 w-full flex flex-col items-center relative">
          <div class="w-[210px] h-16 relative mx-2">
            <mwc-textfield
              required
              class="w-full"
              validationMessage="La version es requerida"
              label="Version"
              id="version"
              minLength="1"
              autoValidate
              outlined
            >
            </mwc-textfield>
          </div>
          <div class="flex">
            <div class="w-[210px] h-16 relative mx-2">
              <mwc-textarea
                required
                class="w-full"
                validationMessage="La descripcion corta debe tener al menos 4 caracteres"
                label="Descripción Corta de la App"
                id="briefDescription"
                minLength="4"
                autoValidate
                outlined
              >
              </mwc-textarea>
            </div>
            <div class="w-[210px] h-16 relative mx-2">
              <mwc-textarea
                required
                class="w-full"
                validationMessage="La descripcion debe tener al menos 12 caracteres"
                label="Descripción de la App"
                id="description"
                minLength="12"
                autoValidate
                outlined
              >
              </mwc-textarea>
            </div>
          </div>
        </div>

        <div slot="footer" class="dialog-footer mt-auto">
          <button
            class="text-red-600 p-4 rounded-md uppercase"
            id="closeDialogButton"
            @click=${() => {
              this.close();
            }}
          >
            Cerrar
          </button>
          <button
            class="text-blue-600 p-4 rounded-md uppercase"
            id="closeDialogButton"
            type="submit"
          >
            Guardar
          </button>
        </div>
      </form>
    </ui5-dialog>`;
  }
}
